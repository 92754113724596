import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

interface WidgetItemProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const WidgetItem = SortableElement((props: WidgetItemProps) => (
  <>{props.children}</>
));

export default WidgetItem;
