import React from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  styled,
  Box,
  makeStyles
} from '@material-ui/core';
import Button from '../../../button/Button';
import StarsIcon from '@material-ui/icons/Stars';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import lodash from 'lodash';
import colors from '../../../../styles/colors';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { userNavigateWithClassCode } from '../../../../utils/user-route-getter';
import { teacherRoutes } from '../../../../constants/routes';
import {
  assignmentSteps,
  assignmentTypes
} from '../../../../types/assignments/assignment-types';
import mixpanel from '../../../../utils/mixpanel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { show as showModal, hide } from 'redux-modal';
import AssignmentDescription from '../ModalAssignmentDescription';
import { AssignmentData } from '../ModalCreateAssignment';

interface SkillOrGenreProps {
  options: Array<any>;
  isPremiumUser?: boolean;
  setTitle: React.Dispatch<React.SetStateAction<any>>;
  setId: React.Dispatch<React.SetStateAction<any>>;
  setNextStep: React.Dispatch<React.SetStateAction<any>>;
  type: assignmentTypes;
  grade: number;
  setDecodablesAssignmentData: React.Dispatch<
    React.SetStateAction<AssignmentData | null>
  >;
}

export interface MixPanelProperties {
  type: assignmentTypes;
  topic?: string;
  gradeSelected: number;
}

const useStyles = makeStyles(theme => ({
  spacedTypography: {
    marginBottom: theme.spacing(2)
  }
}));

const CustomAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  '& .MuiAccordionDetails-root': { padding: 0 },
  '& .MuiAccordionSummary-root': { backgroundColor: '#EEEEEE' }
}));

const SkillOrGenreSelection: React.FC<SkillOrGenreProps> = (
  props: SkillOrGenreProps
) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const groupedData = lodash
    .chain(props.options)
    .groupBy('skillsDescription')
    .map((value, key) => ({ skill: key, data: value }))
    .sortBy(v => v.skill)
    .value();

  const handleAssignmentDescription = (data: AssignmentData) => {
    <AssignmentDescription selectedData={data} />;
    props.setNextStep(assignmentSteps.decodablesAssignmentDescription);
    props.setDecodablesAssignmentData(data);
  };

  const renderAssignButton = (assignment: any) => {
    const assignmentProperties: MixPanelProperties = {
      type: props.type,
      gradeSelected: props.grade
    };
    if (props.type === assignmentTypes.skills) {
      assignmentProperties.topic = assignment.skillsDescription;
    }
    if (
      props.isPremiumUser ||
      (!props.isPremiumUser && assignment.contentType === 'Core')
    ) {
      const title =
        props.type === assignmentTypes.genres
          ? assignment.title
          : `${assignment.skillsDescription} - ${assignment.title}`;
      return (
        <Button
          primary
          style={{ padding: 5 }}
          onClick={() => {
            props.setTitle(title);
            props.setId(assignment.id);
            props.setNextStep(assignmentSteps.selectStudents);
          }}
        >
          Assign
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            background: colors.appbarAlt,
            border: `1px solid ${colors.primaryHover}`,
            color: colors.primaryHover,
            padding: 5
          }}
          onClick={() => {
            mixpanel.track(
              'Unlock Premium Assignment Clicked',
              assignmentProperties
            );
            dispatch(hide('modal'));
            dispatch(
              push(
                userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass)
              )
            );
          }}
        >
          Unlock
        </Button>
      );
    }
  };

  const RenderList = () => {
    if (
      props.type === assignmentTypes.skills ||
      props.type === assignmentTypes.decodables
    ) {
      return (
        <>
          {props.type === assignmentTypes.decodables && (
            <>
              <Typography className={classes.spacedTypography}>
                Decodable texts support{' '}
                <Typography
                  component="span"
                  variant="inherit"
                  style={{ fontWeight: 'bold' }}
                >
                  phonics-based
                </Typography>{' '}
                instruction. They are texts that use only words that feature
                phonetically regular spellings.
              </Typography>

              <Typography className={classes.spacedTypography}>
                Each assignment focuses on a specific spelling pattern. Assign
                them in order to introduce the spelling patterns one-by-one,
                moving from common to more complex.
              </Typography>
            </>
          )}
          {groupedData.map(skillItem => (
            <Grid container spacing={4}>
              <Grid container item alignItems="center">
                <div style={{ width: '100%' }}>
                  <CustomAccordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{skillItem?.skill}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List
                        style={{
                          width: '100%',
                          padding: 0,
                          backgroundColor: 'background.paper'
                        }}
                      >
                        {skillItem.data.map(pack => (
                          <RenderListItems data={pack} />
                        ))}
                      </List>
                    </AccordionDetails>
                  </CustomAccordion>
                </div>
              </Grid>
            </Grid>
          ))}
        </>
      );
    } else {
      return (
        <div style={{ width: '600px', maxWidth: '100%' }}>
          <Grid container spacing={4}>
            <List
              style={{
                width: '100%',
                padding: 0,
                backgroundColor: 'background.paper'
              }}
            >
              {props.options
                .sort(
                  (a, b) =>
                    a.contentType.localeCompare(b.contentType) ||
                    a.title.localeCompare(b.title)
                )
                .map(pack => (
                  <RenderListItems data={pack} />
                ))}
            </List>
          </Grid>
        </div>
      );
    }
  };

  const RenderListItems = (item: { data: any }) => {
    return (
      <ListItem
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0px 10px'
        }}
        key={item.data.code}
      >
        <Grid container spacing={3} style={{ margin: 0 }} alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            {item.data.contentType !== 'Core' && (
              <ListItemIcon
                style={{
                  display: 'block',
                  minWidth: 0,
                  padding: '0 2px'
                }}
              >
                <StarsIcon
                  style={{
                    fill: '#f8bb00',
                    position: 'relative',
                    top: '4px'
                  }}
                />
              </ListItemIcon>
            )}
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText primary={item.data?.title} />

              {props.type === assignmentTypes.decodables && (
                <HelpOutlineIcon
                  style={{
                    marginLeft: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleAssignmentDescription(item.data)}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md="auto">
            <ListItemText
              disableTypography
              primary={
                <Typography
                  style={{
                    textAlign: 'left'
                  }}
                >
                  {item.data?._count?.questions} questions
                </Typography>
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md="auto"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            {renderAssignButton(item.data)}
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  return (
    <>
      <RenderList />
    </>
  );
};

export default SkillOrGenreSelection;
