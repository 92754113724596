import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AnnouncementDashboardProps } from '../../components/announcement/AnnouncementDashboard';
import { fetchApi } from '../actions/fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';

export const getData = createAsyncThunk(
  'announcement/getData',
  async (undefined, { dispatch }) => {
    try {
      const response = await dispatch(
        fetchApi({
          url: 'sw/announcement',
          method: APIMethods.GET
        })
      );

      const { title, content, link, media } = response.data;

      return { title, content, link, media };
    } catch (error) {
      Debug.log(error);
    }
  }
);

const initialState: AnnouncementDashboardProps = {
  title: 'Squiggle Park',
  content: {
    text: 'We are Squiggle Park.',
    size: 14
  },
  link: {
    label: 'Learn More',
    url: 'https://www.squigglepark.com/'
  }
};

const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const { title, content, link, media } = action.payload;

        state.title = title;
        state.content = content;
        state.link = link;
        state.media = media;
      }

      return state;
    });
  }
});

export default announcementSlice.reducer;
