import {
  USE_CANCELLATION_TOKEN,
  UPDATE_CANCELLATION_DATA
} from './cancellation-token-action-types';

export const cancellationTokenReducer = (
  state = [],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case USE_CANCELLATION_TOKEN:
      return payload;
    case UPDATE_CANCELLATION_DATA:
      return payload;
    default:
      return state;
  }
};
