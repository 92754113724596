export type SkillReportRequest = {
  type: string;
  grade?: number;
  to: string;
  from: string;
  userIds: Array<number>;
};

export enum Period {
  allTime,
  thisWeek,
  last7Days,
  last30Days,
  timeRange
}

export enum Skills {
  allSkills,
  comprehension,
  independent
}

export interface SkillResponse {
  userId: number;
  skillId: number;
  gradeId: number;
  skill: string;
  correct: number;
  incorrect: number;
  total: number;
  type: string;
  accuracy: number;
  [x: string]: any;
}
