import { USER_GENERATED_CONTENT_GET } from '../types/user-generated-content/user-generated-content-action-types';

const initialState: any = [];

export const userGeneratedContentReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case USER_GENERATED_CONTENT_GET:
      return payload;
    default:
      return state;
  }
};
