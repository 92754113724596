import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import useQueryParams from '../../hooks/UseQueryParams';
import { useDispatch } from 'react-redux';
import { loginUserWithToken } from '../../redux/login/login-actions';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

export const AutoAuthorize: React.FC = () => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const urlAccessToken = queryParams?.accessToken;
  const redirectTo = queryParams?.redirectTo;
  const urlClassCode = queryParams?.classCode;

  useEffect(() => {
    console.log('Auto Authorize', { urlAccessToken, redirectTo });
    if (urlAccessToken && redirectTo) {
      console.log(`Logging in using token: ${urlAccessToken}`);
      dispatch(
        loginUserWithToken(urlAccessToken, redirectTo, undefined, urlClassCode)
      );
    } else {
      dispatch(push('/login'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <CircularProgress style={{ marginBottom: '20px' }} />
      <Typography variant="h4" style={{ color: '#999' }}>
        Redirecting...
      </Typography>
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        If you are not automatically redirected, Please
        <Link to="/login" style={{ margin: '0 5px' }}>
          click here
        </Link>
        to login.
      </Typography>
    </Box>
  );
};
