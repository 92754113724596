import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const LazyLoaderScreen = () => {
  const [showLoading, setShowLoading] = useState(false);
  const waitBeforeShow = 50;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return (
    <>
      {showLoading && (
        <Box
          style={{ display: 'flex', flexDirection: 'column', padding: '35px' }}
        >
          <Skeleton
            animation="wave"
            variant="rect"
            style={{ height: '60px', marginBottom: '30px' }}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            height="calc(90vh - 200px)"
          />
        </Box>
      )}
    </>
  );
};

export default LazyLoaderScreen;
