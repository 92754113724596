import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import validator from 'validator';
import colors from '../../styles/colors';
import { CSVValidation } from '../../types/paste-csv/paste-csv-type';

const useStyles = makeStyles(() => ({
  rowCounter: {
    left: 0,
    listStyle: 'none',
    margin: '18px 0 0 16px',
    padding: '.8rem 0',
    position: 'absolute'
  },
  textArea: {
    border: `1px solid ${colors.primary}`,
    borderRadius: 5,
    minHeight: 200,
    fontSize: 16,
    lineHeight: 1.5,
    margin: '3% 3',
    overflow: 'hidden',
    padding: 10,
    resize: 'none',
    width: '100%'
  }
}));

interface PasteCSVProps {
  getErrors: (errors: Array<any>) => void;
  getRows: (content: Array<any>) => void;
  columnCount: number;
  textAreaPlaceholder?: string;
  validation?: Array<CSVValidation>;
  formatPlaceholder: Array<string>;
}

const PasteCSV: React.FC<PasteCSVProps> = (props: PasteCSVProps) => {
  const classes = useStyles();

  const [csvError, setCSVError] = useState<Array<any>>([]);
  const [textAreaContent, setTextAreaContent] = useState('');
  const [rowCount, setRowCount] = useState(1);

  const handleTextAreaChange = (event: any) => {
    let textAreaContent = event.target.value.replace(/\t/g, ', ');
    // remove all the text after props.columnCount comma
    const rows = textAreaContent.split('\n').map((row: any) => {
      return row
        .split(',')
        .slice(0, props.columnCount)
        .join(',');
    });

    props.getRows(rows);
    textAreaContent = rows.join('\n');
    setTextAreaContent(textAreaContent);
    setRowCount(rows.length);
    handleValidateContent(rows);
  };

  const handleValidateContent = (rows: Array<any>) => {
    if (!rows.length) {
      setCSVError([...csvError, 'Please, paste at least one row to continue']);
      props.getErrors([
        ...csvError,
        'Please, paste at least one row to continue'
      ]);
      return;
    }

    if (props.validation) {
      rows.forEach((row, rowIndex) => {
        const errors: any = [];
        //@ts-ignore
        props.validation.forEach(validation => {
          let currentRow = row.split(',');

          // if row is empty, fill the array with empty values based on props.columnCount
          if (currentRow.length !== props.columnCount) {
            for (let i = currentRow.length; i < props.columnCount; i++) {
              currentRow.push('');
            }
          }

          currentRow.forEach((column: string, colIndex: number) => {
            column = column.trim() || '';
            if (validation.column === colIndex) {
              if (validation.validationType.notEmpty) {
                if (column === '') {
                  errors.push(
                    `Row ${rowIndex + 1}: ${
                      validation.validationType.notEmpty
                    }.`
                  );
                }
              }

              if (validation.validationType.specificSpecialSymbols) {
                var regex = new RegExp("^[a-zA-Z0-9-'.]+$");
                if (!regex.test(column)) {
                  errors.push(
                    `Row ${rowIndex + 1}: ${
                      validation.validationType.specificSpecialSymbols
                    }.`
                  );
                }
              }

              if (validation.validationType.onlyLetters) {
                if (!column.match(/^[a-z]+$/i)) {
                  errors.push(
                    `Row ${rowIndex + 1}: ${
                      validation.validationType.onlyLetters
                    }.`
                  );
                }
              }

              if (validation.validationType.onlyNumbers) {
                if (!column.match(/^[0-9]+$/i)) {
                  errors.push(
                    `Row ${rowIndex + 1}: ${
                      validation.validationType.onlyNumbers
                    }.`
                  );
                }
              }

              if (validation.validationType.email) {
                if (!validator.isEmail(column)) {
                  errors.push(
                    `Row ${rowIndex + 1}: ${validation.validationType.email}.`
                  );
                }
              }

              if (validation.validationType.emailOptional) {
                if (column && !validator.isEmail(column)) {
                  errors.push(
                    `Row ${rowIndex + 1}: ${
                      validation.validationType.emailOptional
                    }.`
                  );
                }
              }

              if (validation.validationType.numbersBetween) {
                const {
                  max,
                  min,
                  message
                } = validation.validationType.numbersBetween;
                if (
                  isNaN(Number(column)) ||
                  Number(column) < min ||
                  Number(column) > max
                ) {
                  errors.push(`Row ${rowIndex + 1}: ${message}.`);
                }
              }

              let csvErrors = csvError;
              csvErrors[rowIndex] = errors;
              if (csvErrors.length > rows.length) {
                csvErrors = csvErrors.slice(0, rows.length);
              }
              setCSVError(csvErrors);
              props.getErrors(csvErrors);
            }
          });
        });
      });
    }
  };

  const rowCountArray = [];
  for (let i = 1; i <= rowCount; i++) {
    rowCountArray.push(<li>{i}</li>);
  }

  return (
    <>
      <div>
        {/*  <ul className={classes.rowCounter}  style={{verticalAlign:'top'}}>
          {rowCountArray.map(item => item)}
         </ul> */}
        <Typography style={{ paddingTop: 10 }}>Paste from CSV</Typography>
        <textarea
          autoFocus={true}
          className={classes.textArea}
          value={textAreaContent}
          placeholder={props.textAreaPlaceholder}
          // set height dinamically depending on the line count
          style={{ height: rowCount * 27.777 }}
          onChange={handleTextAreaChange}
        ></textarea>
      </div>
    </>
  );
};

export default PasteCSV;
