/* tslint:disable */
/*
 * This Component Utilizes hooks from https://github.com/tannerlinsley/react-table/blob/master/docs/examples.md
 * refer to the link to see examples of how these hooks are used. It does not have full official support and is in beta stage
 * for our use it is sufficient
 */

import React, { PropsWithChildren } from 'react';
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
  // @ts-ignore
  useTableState
} from 'react-table';
import {
  IconButton,
  makeStyles,
  Paper,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  withStyles
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import DefaultSortIcon from '../../assets/icons/arrows/arrows.png';
import DownSortIcon from '../../assets/icons/arrows/down-arrow.png';
import UpSortIcon from '../../assets/icons/arrows/up-arrow.png';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import colors from '../../styles/colors';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

interface TableClassNames {
  container?: string;
  table?: string;
  header?: string;
  headerCell?: string;
}

interface TableProps {
  columns: Array<any>;
  data: Array<any>;
  defaultSize?: number;
  hidePagination?: boolean;
  rowsPerPageOptions?: Array<number>;
  getRowProps?: any;
  classNames?: TableClassNames;
  cellPadding?: number;
  elevation?: number;
  height?: any;
  overflowX?: 'scroll' | 'none';
  overflowY?: 'scroll' | 'none';
  classMissionRowHighlight?: string;
}

// pagination styles
const useStylesPagination = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    justifyContent: 'flex-end',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

// Thead styled cell in table
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.table.header,
    color: theme.palette.common.white,
    textAlign: 'center'
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    display: 'table',
    width: '100%'
  },
  tableHeaderContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableRow: {
    display: 'table !important' as 'table',
    tableLayout: 'fixed',
    width: '100%'
  },
  classMissiontableRow: {
    background: '#D3D3D3',
    display: 'table !important' as 'table',
    tableLayout: 'fixed',
    width: '100%'
  },
  tableBody: {
    display: 'block',
    maxHeight: 1000
  },
  head: {
    fontSize: '1rem !important'
  },
  leftAlign: {
    display: 'flex',
    justifyContent: 'left'
  }
}));

const Table: React.FC<TableProps> = (props: PropsWithChildren<TableProps>) => {
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
  }: {
    column: any;
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const formattedData =
    props.data && Array.isArray(props.data) ? props.data : [];

  let hidden = [];
  for (let column of props.columns) {
    if (column.hasOwnProperty('show') && !column.show) {
      hidden.push(column.id);
    }
  }
  // Hooks for the tables using v7 of react-table
  const {
    state,
    getTableProps,
    headerGroups,
    rows,
    setPageSize,
    prepareRow,
    page,
    nextPage,
    gotoPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageCount
  }: any = useTable(
    {
      columns: props.columns || [],
      data: formattedData,
      initialState: {
        hiddenColumns: hidden
      },
      // state: tableState,
      // @ts-ignore
      defaultColumn
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const TablePaginationActions = () => {
    const classes = useStylesPagination();
    const theme = useTheme();
    function handleFirstPageButtonClick() {
      gotoPage(0);
    }

    function handleBackButtonClick() {
      previousPage();
    }

    function handleNextButtonClick() {
      nextPage();
    }

    function handleLastPageButtonClick() {
      gotoPage(pageCount - 1);
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={!canPreviousPage}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={!canPreviousPage}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          aria-label="next page"
          disabled={!canNextPage}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={!canNextPage}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  };
  function handleChangePage(event: any, newPage: any) {
    gotoPage(newPage);
  }

  function handleChangeRowsPerPage(event: any) {
    setPageSize(Number(event.target.value));
  }

  const renderPagination = () => {
    if (props.hidePagination) {
      return null;
    }
    const rowsPerPageOptions = props.rowsPerPageOptions || [
      5,
      10,
      25,
      50,
      75,
      100
    ];
    return (
      // @ts-ignore
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        colSpan={3}
        count={rows.length}
        rowsPerPage={state.pageSize || null}
        page={state.pageIndex}
        backIconButtonProps={{
          'aria-label': 'previous page'
        }}
        nextIconButtonProps={{
          'aria-label': 'next page'
        }}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        style={{ display: 'flex' }}
      />
    );
  };
  const classes = useStyles();
  // Table UI
  const { overflowX = 'scroll', overflowY = 'scroll' } = props;

  const table = () => {
    return (
      <Paper
        className={clsx(
          classes.root,
          'table-container',
          props.classNames && props.classNames.container
        )}
        elevation={props.elevation}
        style={{ height: props.height }}
      >
        <MaterialTable
          {...getTableProps()}
          className={clsx(props.classNames && props.classNames.table)}
        >
          <TableHead
            className={clsx(
              classes.head,
              props.classNames && props.classNames.header
            )}
          >
            {headerGroups.map((headerGroup: any) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                className={clsx(!isMobile && classes.tableRow)}
              >
                {headerGroup.headers.map(
                  (column: any, index: number, columns: any) => {
                    let sortIcon = null;
                    const renderSortIcon = (icon: any = DefaultSortIcon) => (
                      <div style={{ paddingLeft: '1rem' }}>
                        <img
                          alt="Sort"
                          src={icon}
                          style={{ width: 10, paddingTop: 6 }}
                        />
                      </div>
                    );
                    // keeps sortIcon on the first column if no other column is sorted
                    if (index === 0) {
                      const otherColumnsSorted = columns.filter(
                        (item: any, index: number) =>
                          index !== 0 && item.isSorted
                      );
                      if (otherColumnsSorted.length === 0) {
                        sortIcon = renderSortIcon(DefaultSortIcon);
                      }
                    }
                    if (
                      !column.disabledSorting &&
                      !column.disableSorting &&
                      column.canSort
                    ) {
                      if (column.isSorted) {
                        const direction = column.isSortedDesc
                          ? DownSortIcon
                          : UpSortIcon;
                        sortIcon = renderSortIcon(direction);
                      } else {
                        sortIcon = renderSortIcon(DefaultSortIcon);
                      }
                    }
                    return (
                      <StyledTableCell
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div
                          className={clsx(
                            index
                              ? classes.tableHeaderContent
                              : classes.leftAlign,
                            props.classNames && props.classNames.headerCell
                          )}
                        >
                          {column.render('Header')}
                          <span>{sortIcon}</span>
                        </div>
                      </StyledTableCell>
                    );
                  }
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            className={clsx(!isMobile && classes.tableBody)}
            // @ts-ignore
            style={isMobile ? { overflowX } : { overflowY }}
          >
            {page.map(
              (row: any) =>
                prepareRow(row) || (
                  <TableRow
                    {...row.getRowProps()}
                    {...props.getRowProps(row)}
                    className={clsx(!isMobile && classes.tableRow, {
                      [classes.classMissiontableRow]:
                        props.classMissionRowHighlight &&
                        row.cells[0].value == props.classMissionRowHighlight
                    })}
                  >
                    {row.cells.map((cell: any, index: 0) => {
                      return (
                        <TableCell
                          align={index ? 'center' : 'left'}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaterialTable>
        {renderPagination()}
        {props.children}
      </Paper>
    );
  };

  return table();
};

export default Table;

Table.defaultProps = {
  getRowProps: () => {}
};
