import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { userReducer } from './reducers/user-reducer';
import { loginReducer } from './login/login-reducer';
import { registrationReducer } from './reducers/register-reducer';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as modal } from 'redux-modal';
import { loadState, saveState } from './localStorage';
import { classReducer } from './reducers/class-reducer';
import { pdfReducer } from './reducers/pdf-reducer';
import { playerReducer } from './reducers/player-reducer';
import { playerEducationReducer } from './reducers/player-education-reducer';
import { membersReducer } from './reducers/members-reducer';
import { locationSelectionReducer } from './reducers/location-selection-reducer';
import { plannerReducer } from './reducers/planner-reducer';
import { cleverReducer } from './reducers/clever-reducer';
import { notificationReducer } from './reducers/notification-reducer';
import { referralsReducer } from './reducers/referrals-reducer';
import { adminReducer } from './reducers/user-admin-reducers';
import { realTimeReducer } from './reducers/realtime-reducer';
import { chuckReducer } from './reducers/chuck-reducer';
import { userGeneratedContentReducer } from './reducers/user-generated-content-reducer';
import { googleClassroomReducer } from './reducers/google-classroom-reducer';
import { jobQueueReducer } from './reducers/job-queue-reducer';
import AnnouncementReducer from './features/announcement';
import { cancellationTokenReducer } from './membership-cancellation/cancellation-token-reducer';
import reportToolReducer from './features/ReportTool';
import { adminVerificationReducer } from './reducers/admin-verification-reducer';
import { reportCardReducer } from './reducers/report-card-reducer';
import { USER_LOGOUT } from './types/user/user-action-type';
import { assignmentEditReducer } from '../features/assignment/pages/edit/state/assignment-edit.reducer';
import { resourceDataReducer } from '../features/resourceHub/pages/state/resourceHub.reducer';
import { AssignmentEditState } from '../features/assignment/pages/edit/state/assignment-edit.types';
import { ResourceState } from '../features/resourceHub/pages/state/resourceHub.types';
import {
  featureFlagReducer,
  FeatureFlagState
} from './feature-flags/feature-flag-reducer';
import editMiddleware from '../features/assignment/pages/edit/state/question/assignment-question.middleware';
import { loginMiddleware } from './login/login.middleware';
import redirectionMiddleware from './middlewares/redirectMiddleware';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistedState = loadState();
// const logger = createLogger({ collapsed: true });

// creates instance of browser history
export const history = createBrowserHistory();

export type AppDispatch = typeof store.dispatch;
export type RootState = {
  login: any;
  registration: any;
  userData: any;
  router: any;
  modal: any;
  class: any;
  pdf: any;
  player: any;
  playerEducationData: any;
  locationSelection: any;
  planner: any;
  members: any;
  clever: any;
  googleClassroom: any;
  notifications: any;
  referrals: any;
  admin: any;
  realTime: any;
  chuck: any;
  userGeneratedContent: any;
  announcement: any;
  cancellationToken: any;
  reportTool: any;
  featureFlag: FeatureFlagState;
  reportCard: any;
  jobQueue: any;
  adminVerification: any;
  editAssignment: AssignmentEditState;
  resources: ResourceState;
};

const appReducer = combineReducers<any>({
  login: loginReducer,
  featureFlag: featureFlagReducer,
  registration: registrationReducer,
  userData: userReducer,
  router: connectRouter(history),
  modal,
  class: classReducer,
  pdf: pdfReducer,
  player: playerReducer,
  playerEducationData: playerEducationReducer,
  locationSelection: locationSelectionReducer,
  planner: plannerReducer,
  members: membersReducer,
  clever: cleverReducer,
  googleClassroom: googleClassroomReducer,
  notifications: notificationReducer,
  referrals: referralsReducer,
  admin: adminReducer,
  realTime: realTimeReducer,
  chuck: chuckReducer,
  userGeneratedContent: userGeneratedContentReducer,
  announcement: AnnouncementReducer,
  cancellationToken: cancellationTokenReducer,
  reportTool: reportToolReducer,
  reportCard: reportCardReducer,
  jobQueue: jobQueueReducer,
  adminVerification: adminVerificationReducer,
  editAssignment: assignmentEditReducer,
  resources: resourceDataReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  // @ts-ignore
  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(
    applyMiddleware(
      thunk,
      routerMiddleware(history),
      editMiddleware,
      loginMiddleware,
      redirectionMiddleware
    )
  )
);

store.subscribe(() => {
  const data = { ...store.getState() };
  delete data.modal;
  saveState(data);
});

export default store;
