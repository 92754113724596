import { Box, Grid, MenuItem } from '@material-ui/core';
import React, { PropsWithRef, Ref, useState } from 'react';
import { SearchResult } from './SearchResultList';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../../styles/colors';
import {
  AssignmentQuestionAnswer,
  AssignmentQuestionType
} from '../../../../pages/edit/state/assignment-edit.types';
import { Add, Done } from '@material-ui/icons';
import { Button } from '../../../button/Button';
import QuestionRenderer from '../../../../pages/edit/editor/question-list/question-renderer/QuestionRenderer';
import { QuestionFormatNumber } from '../../../../../../enums/QuestionFormat';

const useStyles = makeStyles({
  root: {
    borderRadius: '4px',
    border: `1px solid ${colors.assignment.grey}`,
    marginBottom: '10px'
  },
  padding: {
    padding: '1.2rem'
  },
  header: {
    backgroundColor: colors.assignment.grey,
    display: 'flex',
    justifyContent: 'space-between'
  },
  list: {
    listStyle: 'none'
  },
  round: {
    marginRight: '10px',
    borderRadius: '50%',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    border: `1px solid ${colors.assignment.grey}`
  },
  textLine: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${colors.assignment.grey}`,
    lineHeight: '0.1em',
    margin: '10px 0 20px'
  },
  text: {
    background: '#fff',
    padding: '0 10px',
    fontSize: '12px',
    marginLeft: '20px',
    color: `${colors.assignment.grey}`
  }
});
interface SearchResultItemProps {
  result: SearchResult;
  skills: any[];
  genres: any[];
  handleAddQuestion(question: any, type?: AssignmentQuestionType): void;
  handleDeleteQuestion(question: any, type?: AssignmentQuestionType): void;
}
export const SearchResultItem = (
  props: PropsWithRef<SearchResultItemProps>
) => {
  const {
    result,
    skills,
    handleAddQuestion,
    genres,
    handleDeleteQuestion
  } = props;
  const classes = useStyles();
  if ('question' in result) {
    return (
      <QuestionItem
        skills={skills}
        result={result}
        handleDeleteQuestion={handleDeleteQuestion}
        handleAddQuestion={handleAddQuestion}
      />
    );
  } else {
    const genre = genres.find(genre => genre.id === result.genreId);
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={`${classes.padding} ${classes.header}`}>
          <Box textAlign={'left'}>
            <span>{result.id}</span>
            <b style={{ marginLeft: '10px' }}>Genre: {genre?.name}</b>
          </Box>
          <>
            {result.isAdded ? (
              <Button
                variant={'filled'}
                textColor={'white'}
                onClick={() =>
                  handleDeleteQuestion(result, AssignmentQuestionType.Passage)
                }
                color={colors.green}
              >
                Added
              </Button>
            ) : (
              <Button
                icon={Add}
                variant={'filled'}
                textColor={'white'}
                onClick={() =>
                  handleAddQuestion(result, AssignmentQuestionType.Passage)
                }
                color={colors.assignment.blue}
              >
                Add
              </Button>
            )}
          </>
        </Grid>
        <Grid item xs={12} className={classes.padding}>
          <span>{result.name}</span>
          <br />
          <span dangerouslySetInnerHTML={{ __html: result.text }} />
        </Grid>
        <Grid item xs={12} style={{ padding: '1rem' }}>
          {result.questions.map((questionData: any, index: number) => (
            <Box padding={'10px'} key={index}>
              <QuestionItem
                disableAdd={true}
                skills={skills}
                result={questionData}
                handleDeleteQuestion={handleDeleteQuestion}
                handleAddQuestion={handleAddQuestion}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    );
  }
};

const QuestionItem = ({
  skills,
  disableAdd,
  result,
  handleDeleteQuestion,
  handleAddQuestion
}: {
  skills: any;
  result: any;
  disableAdd?: boolean;
  handleDeleteQuestion(question: any): any;
  handleAddQuestion(question: any): any;
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const classes = useStyles();
  const skill = skills.find((skill: any) => skill.id === result.skillId);
  const correctAnswers = JSON.parse(result.correctAnswers);
  const potentialAnswers = JSON.parse(result.potentialAnswers);
  let answers = [];
  if (potentialAnswers) {
    if (result.questionTypeId === QuestionFormatNumber.DragAndDrop) {
      answers = potentialAnswers;
    } else {
      answers = potentialAnswers.map((answer: string) => {
        if (!answer) {
          return {
            text: answer,
            isCorrect: false
          };
        }
        if (correctAnswers.includes(answer)) {
          return {
            text: answer,
            isCorrect: true
          };
        }
        return {
          text: answer,
          isCorrect: false
        };
      });
    }
  }

  return (
    <Grid container className={classes.root}>
      <Grid className={`${classes.padding} ${classes.header}`} item xs={12}>
        <Box textAlign={'left'}>
          <span>{result.id}</span>{' '}
          <b style={{ marginLeft: '10px' }}>Skill: {skill?.name}</b>
        </Box>
        <Box>
          {!disableAdd && (
            <>
              {result.isAdded ? (
                <Button
                  variant={'filled'}
                  textColor={'white'}
                  onClick={() => handleDeleteQuestion(result)}
                  color={colors.green}
                >
                  Added
                </Button>
              ) : (
                <Button
                  icon={Add}
                  variant={'filled'}
                  textColor={'white'}
                  onClick={() => handleAddQuestion(result)}
                  color={colors.assignment.blue}
                >
                  Add
                </Button>
              )}
            </>
          )}
          <Button
            onClick={() => setShowDetails(!showDetails)}
            color={colors.assignment.blue}
          >
            {showDetails ? 'Hide' : 'Show'} details
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.padding}>
        <span dangerouslySetInnerHTML={{ __html: result.question }} />
      </Grid>
      {showDetails && (
        <Grid item xs={12} className={classes.padding}>
          <h2 className={classes.textLine}>
            <span className={classes.text}>answer choices</span>
          </h2>
          <ul className={classes.list}>
            {answers &&
              answers.map((answer: AssignmentQuestionAnswer, index: number) => {
                return (
                  <li
                    style={{
                      margin: '10px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    key={index}
                  >
                    <div className={classes.round}>
                      {result.questionTypeId === QuestionFormatNumber.DragAndDrop ? (
                        <span>{index + 1}</span>
                      ) : (
                        answer?.isCorrect && (
                          <Done style={{ color: colors.green }} />
                        )
                      )}
                    </div>
                    &nbsp;{answer?.text}
                  </li>
                );
              })}
          </ul>
        </Grid>
      )}

      {result.feedbackIncorrect && (
        <Grid item xs={12} className={classes.padding}>
          <h2 className={classes.textLine}>
            <span className={classes.text}>Feedback</span>
          </h2>
          <span>{result.feedbackIncorrect}</span>
        </Grid>
      )}
    </Grid>
  );
};
