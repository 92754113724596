import React, { useState } from 'react';
import { userTypes, userTypesDescription } from '../../../constants/users';
import {
  makeStyles,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import educator from '../../../assets/icons/registration/dreamscape_account_educators.png';
import parents from '../../../assets/icons/registration/dreamscape_account_parents.png';
import admin from '../../../assets/icons/registration/dreamscape_account_schools.png';
import Button from '../../../components/button/Button';
import clsx from 'clsx';
import colors from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  userTypeSelected: {
    backgroundColor: colors.lightBackground,
    opacity: '1 !important',

    '& *': {
      borderRadius: 0
    }
  },
  userTypeSelectedParent: {
    borderColor: `${colors.green} !important`
  },
  userTypeSelectedTeacher: {
    borderColor: `${colors.blue} !important`
  },
  userTypeSelectedAdmin: {
    borderColor: `${colors.orange} !important`
  },
  userTypeDefault: {
    border: '2px solid transparent',
    borderRadius: 5,
    cursor: 'pointer',
    opacity: 0.4,
    transition: '0.3s',

    '&:hover': {
      opacity: 0.9
    }
  },
  optionsWrapper: {
    marginBottom: 20,
    marginTop: 20
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20
  },
  accountDescription: {
    fontSize: 13,
    marginTop: 10
  }
}));

interface SelectUserTypeProps {
  handleRegistration: (userType: string) => void;
  handleBackClick: () => void;
}

const SelectUserType: React.FC<SelectUserTypeProps> = (
  props: SelectUserTypeProps
) => {
  const classes = useStyles();
  const [userType, setUserType] = useState('');

  const renderConfirmButton = () => {
    const disabled = !userType || userType === 'Admin';
    return (
      <Button
        primary
        disabled={disabled}
        onClick={() => props.handleRegistration(userType)}
      >
        Confirm
      </Button>
    );
  };

  const renderAdminSelection = () => {
    if (userType.includes('Admin')) {
      return (
        <>
          <Typography>Please, select what type of admin you are</Typography>
          <RadioGroup
            className={classes.radioGroup}
            value={userType}
            onChange={(event: any) => setUserType(event.target.value)}
          >
            <FormControlLabel
              value={userTypes.schoolAdmin}
              control={<Radio color="primary" />}
              label={userTypes.schoolAdmin}
            />
            <FormControlLabel
              value={userTypes.districtAdmin}
              control={<Radio color="primary" />}
              label={userTypes.districtAdmin}
            />
          </RadioGroup>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Typography variant="h5" component="h3" style={{ marginBottom: 20 }}>
        Please, confirm your user type:
      </Typography>

      <Typography>
        Select your user type so that we can link your Google account to the
        correct Dashboard.
      </Typography>

      <Grid container spacing={4} className={classes.optionsWrapper}>
        <Grid item md={4} onClick={() => setUserType(userTypes.parent)}>
          <div
            className={clsx(
              classes.userTypeDefault,
              userType === userTypes.parent && [
                classes.userTypeSelected,
                classes.userTypeSelectedParent
              ]
            )}
          >
            <img src={parents} alt="Parent" />
            <br />
            <Button fullWidth green>
              Parent
            </Button>
          </div>
          <Typography className={classes.accountDescription}>
            {userTypesDescription.parent}
          </Typography>
        </Grid>
        <Grid item md={4} onClick={() => setUserType('Teacher')}>
          <div
            className={clsx(
              classes.userTypeDefault,
              userType === 'Teacher' && [
                classes.userTypeSelected,
                classes.userTypeSelectedTeacher
              ]
            )}
          >
            <img src={educator} alt="Educator" />
            <br />
            <Button fullWidth blue>
              Educator
            </Button>
          </div>
          <Typography className={classes.accountDescription}>
            {userTypesDescription.teacher}
          </Typography>
        </Grid>
        <Grid item md={4} onClick={() => setUserType('Admin')}>
          <div
            className={clsx(
              classes.userTypeDefault,
              userType.includes('Admin') && [
                classes.userTypeSelected,
                classes.userTypeSelectedAdmin
              ]
            )}
          >
            <img src={admin} alt="Admin" />
            <br />
            <Button fullWidth orange>
              Admin
            </Button>
          </div>
          <Typography className={classes.accountDescription}>
            {userTypesDescription.admin}
          </Typography>
        </Grid>
      </Grid>

      {renderAdminSelection()}
      <Button onClick={props.handleBackClick} style={{ marginRight: 10 }}>
        Back
      </Button>
      {renderConfirmButton()}
    </>
  );
};
export default SelectUserType;
