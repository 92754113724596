export type ResourceState = {
    all: any[],
    selected: Resource
};
export type Resource = {
    _id: string,
    title:string,
    author: string,
    description:string,
    link: string,
    createdDate: string,
    thumbnail: string,
    type: string,
    userTypes: any[],
    playlist: any[]
}
export enum resourceTypes {
    download = 'download',
    video = 'video',
    readAloud = 'read aloud',
    linkRedirect = 'link redirect'
  }

export enum resourceHubActions {
    UPDATE_ALL_RESOURCES = `[Resource Hub Content]: RESOURCE UPDATE ALL RESOURCE`,
    UPDATE_RESOURCE = `[Resource Hub Content]: RESOURCE UPDATE SINGLE RESOURCE`,
  }