import DashboardIcon from '@material-ui/icons/Dashboard';
import { Create, Note } from '@material-ui/icons';
import WorkIcon from '@material-ui/icons/Work';
import BookIcon from '@material-ui/icons/MenuBook';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import { districtAdminRoutes, commonRoutes } from '../../../constants/routes';
import store from '../../../redux/store';
import { mobileSideItems } from './navbar-mobile';

const disableButton = () => {
  return (
    //@ts-ignore
    !store.getState().userData.allowedByAdmin ||
    //@ts-ignore
    store.getState().userData.allowedByAdmin.currentRule === false
  );
};

const userCanCreateContent = () => {
  const { userCanCreateContent }: any = store.getState().userData;
  return userCanCreateContent === true;
};

export default (path: string) => {
  if (
    [
      districtAdminRoutes.homeScreen,
      districtAdminRoutes.createAccounts,
      districtAdminRoutes.training,
      districtAdminRoutes.myAccount,
      districtAdminRoutes.resourceHub,
      districtAdminRoutes.resourceHubInfo
    ].includes(path)
  ) {
    return [
      {
        title: 'My Schools',
        icon: DashboardIcon,
        to: districtAdminRoutes.homeScreen,
        disabled: disableButton()
      },
      {
        title: 'Create Accounts',
        icon: Create,
        to: districtAdminRoutes.createAccounts,
        disabled: disableButton()
      },
      {
        title: 'Training',
        icon: WorkIcon,
        to: districtAdminRoutes.training,
        disabled: disableButton()
      },
      {
        title: 'Resource Hub',
        icon: BookIcon,
        to: districtAdminRoutes.resourceHub,
        disabled: disableButton()
      },
      {
        title: 'Create Content',
        icon: Note,
        to: commonRoutes.createContentRedirect,
        show: userCanCreateContent()
      },
      ...mobileSideItems
    ];
  }
  if (
    [
      districtAdminRoutes.teacherClass,
      districtAdminRoutes.teacherStudents,
      districtAdminRoutes.teacherPlanner,
      districtAdminRoutes.teacherReports,
      districtAdminRoutes.teacherReadingLevelReport,
      districtAdminRoutes.teacherPlannerReport,
      districtAdminRoutes.teacherSkillComprehensionReport,
      districtAdminRoutes.teacherAssignmentReport,
      districtAdminRoutes.teacherPlacementTestReport,
      districtAdminRoutes.classActivityReport,
      districtAdminRoutes.newLEClassActivityReport,
      districtAdminRoutes.leaderboardReport,
      districtAdminRoutes.newLEleaderboardReport
    ].includes(path)
  ) {
    return [
      {
        title: 'Back to Class List',
        icon: ArrowBackIcon,
        to: districtAdminRoutes.teacherClassList,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        to: districtAdminRoutes.teacherClass,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Players',
        icon: PersonIcon,
        to: districtAdminRoutes.teacherStudents,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Assignments',
        icon: CalendarTodayIcon,
        to: districtAdminRoutes.teacherPlanner,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Reports',
        icon: AssessmentIcon,
        to: districtAdminRoutes.teacherReports,
        disabled: disableButton()
      },
      ...mobileSideItems
    ];
  }
  if ([districtAdminRoutes.teachersList].includes(path)) {
    return [
      {
        title: 'Schools List',
        icon: ArrowBackIcon,
        to: districtAdminRoutes.homeScreen,
        params: true,
        disabled: disableButton()
      },
      ...mobileSideItems
    ];
  }
  if ([districtAdminRoutes.teacherClassList].includes(path)) {
    return [
      {
        title: "Teacher's List",
        icon: ArrowBackIcon,
        to: districtAdminRoutes.teachersList,
        params: true,
        disabled: disableButton()
      },
      ...mobileSideItems
    ];
  }
  return [];
};
