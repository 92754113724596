import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Grid,
  Tooltip,
  makeStyles,
  Typography,
  Button as MUIButton,
  Checkbox
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import DreamscapeBanner from '../../components/ds-banner/DreamscapeBanner';
import colors from '../../styles/colors';
import Table from '../../components/table/Table';
import { fetchMembershipByCancellationToken } from '../../redux/membership-cancellation/cancellation-token-actions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';

import { HighlightOff, Pause } from '@material-ui/icons';
import Button from '../../components/button/Button';
import { MODAL_CANCEL_SUBSCRIPTION } from '../../constants/modals';

const useStyles = makeStyles(() => ({
  alignment: {
    'align-content': 'start',
    'justify-content': 'space-around'
  }
}));

const CancellationView: React.FC<any> = (props: any) => {
  const membershipData = useSelector((state: any) => state.cancellationToken);
  const [tableState, setTableState] = useState<any>([]);
  const [selectedMemberships, setSelectedMemberships] = useState<any>([]);
  const [error, setError] = useState<any>({
    isError: false
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const [invalidRequest, setInvalidRequest] = useState(false);
  const token = props.match.params.token;

  useEffect(() => {
    getMembershipInformation();
  }, []);

  useEffect(() => {
    if (!membershipData || membershipData.length < 1) {
      setInvalidRequest(true);
    }
    setTableState(membershipData);
  }, [membershipData]);

  const getMembershipInformation = () => {
    dispatch(fetchMembershipByCancellationToken(token));
  };

  const handleMembershipSelection = (id: string) => {
    const currentMembershipData = [...tableState];
    currentMembershipData.forEach(membership => {
      if (membership._id === id) {
        membership.isSelected = !membership.isSelected;
      }
    });
    setSelectedMemberships(
      currentMembershipData.filter(membership => membership.isSelected)
    );
  };

  const handleCancelSubscription = () => {
    if (selectedMemberships.length > 0) {
      dispatch(
        show('modal', {
          type: MODAL_CANCEL_SUBSCRIPTION,
          membershipData: selectedMemberships,
          token,
          onSuccess: getMembershipInformation
        })
      );
      setError({
        isError: false
      });
    } else {
      //    no membership selected
      setError({
        isError: true
      });
    }
  };

  return (
    <Grid container className={classes.alignment}>
      <DreamscapeBanner title="Cancel Subscription" />
      {invalidRequest || !tableState ? (
        <div>
          <Typography
            component="p"
            style={{
              marginTop: '20px',
              maxWidth: '35rem'
            }}
          >
            This link has expired. Please check to make sure you are using the
            latest cancellation email link. If this error persists, please
            contact us at{' '}
            <a href="mailto:support@shoelacelearning.com">
              support@shoelacelearning.com
            </a>
            .
          </Typography>
        </div>
      ) : (
        <Grid container style={{ padding: '1rem' }}>
          <Grid item xs={12}>
            <SubscriptionTable
              data={tableState}
              handleMembershipSelection={handleMembershipSelection}
            />
          </Grid>
          {membershipData && membershipData.length > 0 && (
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'right',
                marginTop: '1rem',
                marginRight: '1rem'
              }}
            >
              <Button onClick={handleCancelSubscription} primary>
                Cancel Subscription
              </Button>
            </Grid>
          )}
          {error.isError && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <span style={{ color: 'red' }}>Need to select a membership.</span>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const SubscriptionTable = (props: any) => {
  const { data: tableState } = props;
  return (
    <Table
      columns={[
        {
          Header: 'Start Date',
          accessor: (row: any) => {
            return moment(row.startDate).format('ll');
          }
        },
        {
          Header: 'Type',
          accessor: 'type'
        },
        {
          Header: 'Users',
          accessor: (row: any) => {
            let list = row.playerNames.join(',').substring(0, 25);
            if (row.playerNames.join(',').length > 32) {
              list = list + '...';
            }
            if (row.playerNames.length <= 0) {
              list = 'Not Assigned';
            }
            return list;
          }
        },
        {
          Header: 'Quantity',
          accessor: 'quantity'
        },
        {
          Header: 'Next Billing Date',
          accessor: (row: any) => {
            return moment(row.expiryDate).format('ll');
          },
          Cell: (cell: any) => {
            const subMeta = cell.row.original;

            let color: any;
            let date = subMeta.expiryDate;
            let tooltip = 'Cancelled';
            let icon = <HighlightOff />;
            if (subMeta.paused && subMeta.paused.status) {
              tooltip = 'Paused';
              date = subMeta.paused.resumesAt;
              color = colors.orange;
              icon = <Pause style={{ color }} />;
            }
            date = moment(date).format('ll');

            if (
              subMeta.cancelled ||
              (subMeta.paused && subMeta.paused.status)
            ) {
              return (
                <Tooltip title={tooltip}>
                  <span>
                    <MUIButton style={{ color }} disabled startIcon={icon}>
                      {date}
                    </MUIButton>
                  </span>
                </Tooltip>
              );
            }

            return <MUIButton>{date}</MUIButton>;
          }
        },
        {
          Header: 'Actions',
          Cell: (cell: any) => {
            let membership = cell.row.original;
            const subMeta = membership;
            return (
              <>
                <Tooltip title="Cancel Subscription">
                  <Checkbox
                    checked={subMeta.isSelected}
                    onChange={() =>
                      props.handleMembershipSelection(subMeta._id)
                    }
                    color="primary"
                  />
                </Tooltip>
              </>
            );
          }
        }
      ]}
      data={tableState}
    />
  );
};

export default withRouter(CancellationView);
