import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React from 'react';
import ExternalMembershipIcon from '../../../../assets/checkout-page/membership_icon.png';

export interface ICartWithNoPlayersProps {
  planRecurrence: string;
  quantity: number;

  handleQuantity(quantity: number): void;
}

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      color: '#027F5F',
      fontWeight: 800,
      fontSize: '1.5rem',
      '& fieldset': {
        borderColor: '#027F5F'
      },
      '&:hover fieldset': {
        borderColor: '#027F5F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#027F5F'
      },
      '& input[type=number]': {
        '-moz-appearance': 'number',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          opacity: 1
        }
      }
    }
  }
})(TextField);

export const CartWithNoPlayers = (props: ICartWithNoPlayersProps) => {
  const { planRecurrence, quantity, handleQuantity } = props;
  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Grid
        item
        xs={12}
        sm={8}
        md={10}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <img
          src={ExternalMembershipIcon}
          height={'60px'}
          width={'60px'}
          alt="membership plan"
        />
        <Typography
          variant="body2"
          style={{
            color: '#027F5D',
            fontWeight: 'bold',
            padding: '0 10px',
            fontSize: '16px'
          }}
        >
          {planRecurrence.charAt(0).toUpperCase() + planRecurrence.slice(1)}{' '}
          Membership Plan
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} md={2}>
        <FormControl fullWidth={false}>
          <StyledTextField
            variant={'outlined'}
            size="small"
            value={quantity}
            fullWidth={false}
            onChange={evt => {
              handleQuantity(Number(evt.target.value));
            }}
            aria-valuemin={1}
            aria-valuemax={30}
            type="number"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
