import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { connectModal, InjectedProps } from 'redux-modal';
import colors from '../../styles/colors';
import borders from '../../styles/borders';

const useStyles = makeStyles(() => ({
  spinnerWrapper: {
    alignItems: 'center',
    background: colors.spinnerBackground,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 9999
  },
  spinner: {
    background: colors.white,
    borderRadius: borders.borderRadius,
    maxWidth: 320,
    padding: '20px 30px',
    textAlign: 'center'
  },
  text: {
    marginTop: 10
  }
}));

const Spinner: React.FC<InjectedProps> = (props: any) => {
  const text = props.text || 'Loading';
  const classes = useStyles();
  return (
    <div className={classes.spinnerWrapper}>
      <div className={classes.spinner}>
        <CircularProgress />
        <p className={classes.text}>{text}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return { spinner: state };
};

export default connect(mapStateToProps)(
  connectModal({ name: 'spinner' })(Spinner)
);
