// @ts-nocheck
import mixpanel from 'mixpanel-browser';

function checkEnv() {
  return ['production', 'development'].includes(process.env.NODE_ENV);
}

const utm_query = 'utm_source utm_medium utm_campaign utm_content utm_term';
const custom_query = 'referrer subName subTitle';
mixpanel.init(
  process.env.REACT_APP_MIXPANEL_TOKEN,
  {
    api_host: process.env.REACT_APP_MIXPANEL_HOST,
    batch_requests: false
  },
  'project_a'
);

const REACT_APP_MIXPANEL_DATA_WAREHOUSE_TOKEN =
  process.env.REACT_APP_MIXPANEL_DATA_WAREHOUSE_TOKEN || '';

mixpanel.init(
  REACT_APP_MIXPANEL_DATA_WAREHOUSE_TOKEN,
  {
    api_host: process.env.REACT_APP_MIXPANEL_HOST,
    batch_requests: false
  },
  'project_b'
);

function getQueryParam(url: string, param: any) {
  // Expects a raw URL
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  let regexS: any = '[?&]' + param + '=([^&#]*)',
    regex: any = new RegExp(regexS),
    results: any = regex.exec(url);
  if (
    results === null ||
    (results && typeof results[1] !== 'string' && results[1].length)
  ) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
}

function campaignParams() {
  let campaign_keywords = utm_query.split(' '),
    kw = '',
    params: any = {},
    first_params: any = {};
  let campaign_custom_keywords = custom_query.split(' ');

  let index;
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index]] = kw;
    }
  }
  // custom properties
  for (index = 0; index < campaign_custom_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_custom_keywords[index]);
    if (kw.length) {
      params[campaign_custom_keywords[index]] = kw;
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] = kw;
    }
  }

  if (params) {
    mixpanel.project_a.register(params);
    mixpanel.project_b.register(params);
  }
}

campaignParams();

const mixpanelProperties = {
  get_property(property_name: string, sendToB?: boolean): any {
    if (sendToB) {
      return mixpanel.project_b.get_property(property_name);
    }
    return mixpanel.project_a.get_property(property_name);
  },
  get_distinct_id(sendToB?: boolean) {
    if (sendToB) {
      return mixpanel.project_b.get_distinct_id();
    }
    return mixpanel.project_a.get_distinct_id();
  },
  reset() {
    return mixpanel.project_a.reset();
  },
  identify: (id: any) => {
    if (checkEnv()) {
      mixpanel.project_a.identify(id);
      mixpanel.project_b.identify(id);
    }
  },

  alias: (id: any) => {
    if (checkEnv()) {
      mixpanel.project_a.alias(id);
      mixpanel.project_b.alias(id);
    }
  },

  track: (
    name: string,
    props?: any,
    sendToB?: boolean,
    options?: { transport?: string; send_immediately?: boolean }
  ) => {
    mixpanel.project_a.identify();
    if (sendToB) {
      mixpanel.project_b.identify();
    }
    console.log(
      'DEBUG: event name and project a and b and distinct ids',
      name,
      mixpanel.project_a.get_distinct_id(),
      mixpanel.project_b.get_distinct_id()
    );
    if (checkEnv()) {
      try {
        props = props || {};
        props.source = 'Dreamscape Dashboard';

        const mixpanelResProjectA = mixpanel.project_a.track(
          `[Dash-DS] ${name}`,
          props,
          options,
          () => {
            console.log(`${name}, event fired for Project A inside callback`);
          }
        );
        console.log('mixpanelResa', mixpanelResProjectA);
        if (sendToB) {
          const mixpanelResProjectB = mixpanel.project_b.track(
            `[Dash-DS] ${name}`,
            props,
            options,
            () => {
              console.log(`${name}, event fired for Project B inside callback`);
            }
          );
          console.log('mixpanelResb', mixpanelResProjectB);
        }
      } catch (error) {
        console.log('error in track', error);
      }
    }
  },

  trackLink: (link, eventName: string) => {
    try {
      mixpanel.project_a.track_links(link, eventName);
    } catch (error) {
      console.log('error in trackLink', error);
    }
  },

  trackGame: (name: string, props: any) => {
    if (checkEnv()) {
      try {
        props = props || {};
        props.source = 'Dreamscape Dashboard Game';
        mixpanel.project_a.track(`[DS] ${name}`, props);
      } catch (error) {
        // nothing to do
      }
    }
  },

  people: {
    set: (props: any, value?: string | number) => {
      console.log('DEBUG:: props set people', props, value);
      if (checkEnv()) {
        try {
          if (value) {
            mixpanel.project_a.people.set(props, value);
            mixpanel.project_b.people.set(props, value);
          } else {
            mixpanel.project_a.people.set(props);
            mixpanel.project_b.people.set(props);
          }
        } catch (error) {
          // nothing to do
        }
      }
    },

    append: (property: string, value: string | number) => {
      if (checkEnv()) {
        try {
          mixpanel.project_a.people.append(property, value);
          mixpanel.project_b.people.append(property, value);
        } catch (error) {
          // nothing to do
        }
      }
    },

    increment: (prop: string, value = 1) => {
      if (checkEnv()) {
        try {
          mixpanel.project_a.identify(mixpanel.project_a.get_distinct_id());
          mixpanel.project_b.identify(mixpanel.project_b.get_distinct_id());
          mixpanel.project_a.people.increment(prop, value);
          mixpanel.project_b.people.increment(prop, value);
        } catch (error) {
          // nothing to do
        }
      }
    }
  }
};

export default mixpanelProperties;
