import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, IconButton, Link } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { show } from 'redux-modal';
import { MODAL_DEFAULT } from '../../constants/modals';
import { useAppDispatch } from '../../redux/hooks';
import { teacherRoutes } from '../../constants/routes';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: '20px 20px 40px',
    position: 'relative'
  },
  title: {
    marginBottom: 30
  },
  helpButton: {
    color: theme.palette.primary[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

interface ChartContainerProps {
  title: string;
  children?: any;
  show: any;
  // height?: number,
  helpText?: string | ReactNode;
  elevation?: number;
  style?: any;
}

const ChartContainer: React.FC<ChartContainerProps> = (
  props: ChartContainerProps
) => {
  const dispatch = useAppDispatch();
  const classes = useStyles(props);

  const renderHelpButton = () => {
    if (!props.helpText) {
      return null;
    }

    return (
      <IconButton
        aria-label="help"
        className={classes.helpButton}
        onClick={() =>
          props.show('modal', {
            type: MODAL_DEFAULT,
            children: () => (
              <Typography component="p">{props.helpText}</Typography>
            )
          })
        }
      >
        <HelpOutline />
      </IconButton>
    );
  };

  return (
    <Paper
      className={classes.container}
      elevation={props.elevation}
      style={props.style || {}}
    >
      <Typography component="p" className={classes.title}>
        {props.title}
      </Typography>
      {renderHelpButton()}
      {props.children}
    </Paper>
  );
};

const mapActionsToProps = {
  show
};

export default connect(
  null,
  mapActionsToProps
)(ChartContainer);
