import React, { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, makeStyles, IconButton } from '@material-ui/core';
import colors from '../../../styles/colors';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import MembershipAdvantages from '../../../assets/icons/report-card/membership-advantages.png';
import { hide } from 'redux-modal';
import { Close } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { trackLearnMoreParent, trackMembershipPopupParent } from '../../../redux/actions/mixpanel-actions';

const useStyles = makeStyles((theme: any) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

const ModalParentMembership: React.FC<any> = (props: any) => {
  const { show, handleHide } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(trackMembershipPopupParent(props.origin || "Origin not passed"))
  }, [])
  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        <span style={{ color: colors.primary, fontWeight: 700 }}>
          Memberships
        </span>{' '}
        for players, and parents!
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => dispatch(hide('modal'))}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ padding: 20, textAlign: 'center' }}>
        <DialogContentText>
          <Typography>
            Help your player earn more rewards, and get deeper access into the
            work they are doing on Dreamscape, by becoming a member! Learn more
            below:
          </Typography>
          <img
            src={MembershipAdvantages}
            alt="Membership Advantages"
            style={{ margin: '-40px 0', maxWidth: '100%' }}
          />
        </DialogContentText>
        <Button
          primary
          onClick={() => {
            dispatch(trackLearnMoreParent(props.origin));
            window.open(
              process.env.REACT_APP_MEMBERSHIP_ADVERTISEMENT_PAGE_URL,
              '_blank'
            )
          }
          }
        >
          Learn More
        </Button>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalParentMembership;
