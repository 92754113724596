import { lazy } from 'react';

export enum LazyLoadedModules {
  TeacherModule = 'TeacherModule',
  ClassModule = 'ClassModule',
  ParentModule = 'ParentModule',
  SchoolAdminModule = 'SchoolAdminModule',
  DistrictAdminModule = 'DistrictAdminModule'
}

const getModuleImport = (moduleName: LazyLoadedModules) => {
  switch (moduleName) {
    case LazyLoadedModules.ClassModule:
      return import('./modules/class-module');
    case LazyLoadedModules.ParentModule:
      return import('./modules/parent-module');
    case LazyLoadedModules.SchoolAdminModule:
      return import('./modules/school-admin-module');
    case LazyLoadedModules.DistrictAdminModule:
      return import('./modules/district-admin-module');
    case LazyLoadedModules.TeacherModule:
      return import('./modules/teacher-module');
    default:
      throw new Error(`No module found for name ${moduleName}`);
  }
};

export const lazyLoadModule = (moduleName: LazyLoadedModules) => (
  componentName: string
) =>
  // TODO: Add appropriate Types/Interfaces for this function
  lazy(() =>
    // @ts-ignore
    getModuleImport(moduleName).then(module => ({
      // @ts-ignore
      default: module[componentName]
    }))
  );
