import React from 'react';
import {
  makeStyles,
  DialogTitle,
  IconButton,
  DialogContent,
  Divider,
  Grid,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import StarsIcon from '@material-ui/icons/Stars';
import classMissionReport from '../../../assets/missions/class_mission_report_image.png';
import ModalAbstract from '../../../components/popups/modals/ModalAbstract';
import { teacherRoutes } from '../../../constants/routes';
import { userNavigateWithClassCode } from '../../../utils/user-route-getter';
import { trackUpgradeToPremiumClick } from '../../../redux/actions/mixpanel-actions';

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  button: {
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#3FD2D9',
    '&:hover': {
      backgroundColor: '#3FD2D9'
    }
  },
  alignButton: {
    paddingTop: '20px',
    paddingLeft: '190px',
    paddingBottom: '10px'
  },
  starIcon: {
    fill: '#f8bb00',
    paddingRight: '35px',
    paddingTop: '5px'
  },
  image: {
    height: '0.8%',
    width: '75%',
    paddingLeft: '145px',
    paddingTop: '20px'
  }
}));

type ModalNonPremiumClassMissionProps = {
  show: () => void;
  handleHide: () => void;
};

const ModalNonPremiumClassMission: React.FC<ModalNonPremiumClassMissionProps> = (
  props: ModalNonPremiumClassMissionProps
) => {
  const { show, handleHide } = props;
  const styles = useStyles();
  const dispatch = useDispatch();

  const Redirect = () => {
    dispatch(trackUpgradeToPremiumClick());
    dispatch(
      push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
    );
    handleHide();
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide} maxWidth="sm">
      <DialogTitle>
        <StarsIcon className={styles.starIcon}></StarsIcon>
        Unlock full Class Mission reports
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        The full report provides player statistics during Class Missions.
        Consider upgrading to Premium for full access to Class Mission reports
        and many more benefits!{' '}
        <Grid item sm={12}>
          <img
            className={styles.image}
            src={classMissionReport}
            alt="reportsLogo"
          />
        </Grid>
        <Grid className={styles.alignButton}>
          <Button className={styles.button} onClick={Redirect}>
            Upgrade to Premium
          </Button>
        </Grid>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalNonPremiumClassMission;
