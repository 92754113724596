import {
  AssignmentData,
  AssignmentQuestionFormat,
  AssignmentQuestionType
} from '../assignment-edit.types';
import uuid from 'uuid';
import {
  AssignmentEditAnswerActionTypes,
  AssignmentEditQuestionActionTypes,
  AssignmentEditQuestionBankActionTypes
} from './assignment-question-action.types';
import { QuestionFormatNumber } from '../../../../../../enums/QuestionFormat';

export class AssignmentQuestionActions {
  /**
   * Adds empty question to assignment
   */
  static addQuestion(rootId?: string) {
    return {
      type: AssignmentEditQuestionActionTypes.ADD_QUESTION,
      payload: {
        rootId: rootId
      }
    };
  }

  /**
   * Changes question answer format
   */
  static updateQuestionFormat(id: string, format: number, rootId?: string) {
    return {
      type: AssignmentEditQuestionActionTypes.UPDATE_QUESTION_FORMAT,
      payload: {
        id: id,
        format,
        rootId
      }
    };
  }

  /**
   * Updates question type
   */
  static updateQuestionType(id: string, type: AssignmentQuestionType) {
    return {
      type: AssignmentEditQuestionActionTypes.UPDATE_QUESTION_TYPE,
      payload: {
        type,
        id
      }
    };
  }

  /**
   * Updates any text field for a question
   * @param id
   * @param data
   */
  static updateQuestionText(
    id: string,
    data: Partial<AssignmentData>,
    rootId?: string
  ) {
    return {
      type: AssignmentEditQuestionActionTypes.UPDATE_QUESTION_TEXT,
      payload: {
        id,
        data,
        rootId
      }
    };
  }

  /**
   * Updates question answer data
   * @param id
   * @param data
   */
  static updateAnswer(
    questionId: string,
    answerId: string,
    data: object,
    type?: AssignmentQuestionType,
    rootId?: string
  ) {
    return {
      type: AssignmentEditAnswerActionTypes.UPDATE_ANSWER,
      payload: {
        questionId,
        answerId,
        data,
        type,
        rootId
      }
    };
  }

  /**
   * Deletes answer from question
   * @param rootId
   * @param answerId
   * @param type
   */
  static deleteAnswer(
    id: string,
    answerId: string,
    type?: AssignmentQuestionType,
    rootId?: string
  ) {
    return {
      type: AssignmentEditAnswerActionTypes.DELETE_ANSWER,
      payload: {
        id,
        answerId,
        type,
        rootId
      }
    };
  }

  /**
   * Deletes question from assignment or passage
   * @param id
   * @param rootId
   */
  static deleteQuestion(id: string, rootId?: string) {
    return {
      type: AssignmentEditQuestionActionTypes.DELETE_QUESTION,
      payload: {
        id: id,
        rootId: rootId
      }
    };
  }

  /**
   * Adds answer to question
   * @param rootId
   */
  static addAnswer(id: string, rootId?: string) {
    return {
      type: AssignmentEditAnswerActionTypes.ADD_ANSWER,
      payload: {
        id,
        rootId
      }
    };
  }

  /**
   * @description add pre made questions to assignment
   * @param questions
   */
  static addPreMadeQuestions(questions: any[]) {
    const mapQuestion = (question: any) => {
      let potentialAnswers = JSON.parse(question.potentialAnswers);
      let correctAnswers = JSON.parse(question.correctAnswers);
      let answers = [];
      if (question.questionTypeId === QuestionFormatNumber.DragAndDrop) {
        answers = potentialAnswers;
      } else {
        answers = potentialAnswers.map((answer: string) => {
          if (correctAnswers.includes(answer)) {
            return {
              id: uuid.v4(),
              text: answer,
              isCorrect: true
            };
          }
          return {
            id: uuid.v4(),
            text: answer,
            isCorrect: false
          };
        });
      }
      return {
        ...question,
        id: uuid.v4(),
        syncedId: question.id,
        answers,
        format: question.questionTypeId,
        isFromQuestionBank: true
      };
    };
    const mappedQuestions = questions.map((question: any) => {
      if (question.type === AssignmentQuestionType.Independent) {
        return mapQuestion(question);
      } else {
        return {
          ...question,
          id: uuid.v4(),
          syncedId: question.id,
          isFromQuestionBank: true,
          format: question.questionTypeId,
          passageTitle: question.name,
          passage: question.text,
          questions: question.questions.map((question: any) => {
            return mapQuestion(question);
          })
        };
      }
    });
    return {
      type: AssignmentEditQuestionBankActionTypes.ADD_QUESTION_BANK_QUESTIONS,
      payload: mappedQuestions
    };
  }

  /**
   * @description sets question to edit mode
   * @param questionId
   * @param rootId
   */
  static setToEditMode(questionId: string, rootId?: string) {
    return {
      type: AssignmentEditQuestionActionTypes.SET_EDIT_MODE,
      payload: {
        editMode: true,
        questionId: questionId,
        rootId: rootId
      }
    };
  }
}
