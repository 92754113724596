import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/table/Table';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';

import FirstPlace from '../../assets/icons/1st.png';
import SecondPlace from '../../assets/icons/2nd.png';
import ThirdPlace from '../../assets/icons/3rd.png';
import ReportToolLeaderboardIcon from '../../assets/icons/report/leaderboard-icon.png';
import { useAppSelector } from '../../redux/hooks';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from '@material-ui/icons';
import { show } from 'redux-modal';
import { MODAL_SELECT_DATE_RANGE } from '../../constants/modals';
import moment from 'moment';
import { groupBy } from 'lodash';

interface ReportToolLeaderboardProps {
  isWidget?: boolean;
}

const NewLELeaderboardReportView: React.FC<ReportToolLeaderboardProps> = ({
  isWidget
}: ReportToolLeaderboardProps) => {
  const { currentClass } = useSelector((state: any) => state.class);
  const features = useAppSelector(state => state.featureFlag);
  const [loading, setLoading] = React.useState(false);
  const [playerIds, setPlayerIds] = useState<number[]>([]);
  const [questionCount, setQuestionCount] = useState<
    {
      userId: number;
      totalNumberOfAnswers: number;
      totalNumberOfCorrectAnswers: number;
      totalNumberOfInCorrectAnswers: number;
    }[]
  >([]);
  const timeFrames: any = {
    last7: {
      label: 'Last 7 Days',
      from: moment()
        .subtract(7, 'days')
        .startOf('day')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    week: {
      label: 'This Week',
      from: moment()
        .startOf('week')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    month: {
      label: 'This Month',
      from: moment()
        .startOf('month')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    year: {
      label: 'This Year',
      from: moment()
        .startOf('year')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    custom: {
      label: 'Select Date Range'
    }
  };
  const [selectedOption, setSelectedOption] = useState(timeFrames.last7.label);
  const [prevSelection, setPrevSelection] = useState(timeFrames.last7);
  const [fromDate, setFromDate] = useState<Date | null>(prevSelection.from);
  const [toDate, setToDate] = useState<Date | null>(prevSelection.to);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setPlayerIds(currentClass.roster.map((user: any) => user.educationUserId));
  }, [currentClass?.code]);

  useEffect(() => {
    // Fetch question attempts when fromDate and toDate are both set
    if (fromDate && toDate) {
      // setPrevSelection(timeFrames.last7.label);
      setLoading(true);
      const formattedFromDate = new Date(
        moment(fromDate).format('MMM DD, YYYY')
      );
      const formattedToDate = new Date(moment(toDate).format('MMM DD, YYYY'));
      const selection = `${formattedFromDate} - ${formattedToDate}`;
      setPrevSelection(selection);
      fetchQuestionAttempts(playerIds, fromDate, toDate)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setLoading(true);
      fetchQuestionAttempts(playerIds, fromDate, toDate)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [playerIds, fromDate, toDate, prevSelection]);

  const handleSelectTimeFrame = (event: any) => {
    const selection = event.target.value;

    if (selection === timeFrames.custom.label) {
      // Open the modal to select date range
      setSelectedOption('');
      dispatch(
        show('modal', {
          type: MODAL_SELECT_DATE_RANGE,
          onConfirm: (from: Date, to: Date) => {
            setFromDate(from);
            setToDate(to);
            setLoading(true);
            fetchQuestionAttempts(playerIds, from, to)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          }
        })
      );
    } else {
      setSelectedOption(event.target.value as string);
      const timeFrame: any = Object.values(timeFrames).find(
        (timeFrame: any) => timeFrame.label === selection
      );
      if (timeFrame) {
        setPrevSelection(timeFrame);
        setFromDate(timeFrame.from);
        setToDate(timeFrame.to);
        setLoading(true);
        fetchQuestionAttempts(playerIds, timeFrame.from, timeFrame.to)
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  };

  async function fetchQuestionAttempts(
    learnerIds: number[],
    from: Date | null = null,
    to: Date | null = null
  ) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/learners/question-attempts`,
          method: APIMethods.POST,
          endpoint: APIEndpoints.LE_API,
          data: {
            learnerIds: learnerIds,
            from: from,
            to: to
          }
        })
      );
      const questionsData: any = response.data;

      // group questionsData by learnerId
      const groupedData: any = groupBy(questionsData, 'learnerId');

      // calculate question attempts for each learner
      const questionCount: any = Object.entries(groupedData).map(
        ([learnerId, data]: any) => {
          const totalNumberOfAnswers = data.length;
          const totalNumberOfCorrectAnswers = data.filter(
            (d: any) => d.isCorrectAnswer
          ).length;
          const accuracy = totalNumberOfAnswers
            ? (totalNumberOfCorrectAnswers / totalNumberOfAnswers) * 100
            : 0;
          const totalNumberOfInCorrectAnswers =
            totalNumberOfAnswers - totalNumberOfCorrectAnswers;
          return {
            userId: +learnerId,
            totalNumberOfAnswers,
            totalNumberOfCorrectAnswers,
            totalNumberOfInCorrectAnswers,
            accuracy
          };
        }
      );

      // Sort questionCount array based on accuracy in descending order
      questionCount.sort((a: any, b: any) => b.accuracy - a.accuracy);

      // Add rank based on accuracy
      questionCount.forEach((item: any, index: number) => {
        item.rank = index + 1;
      });
      //adding player names to result
      const getPlayerNames: any = questionCount.map((learner: any) => ({
        ...learner,
        fullName: currentClass.roster.find(
          (player: any) => player.educationUserId === learner.userId
        ).fullname
      }));
      setQuestionCount(getPlayerNames);
    } catch (err) {
      console.log('error', err);
    }
  }

  let columns = [
    {
      Header: 'Rank',
      accessor: 'rank',
      Cell: (row: any) => {
        const { rank } = row.row.original;
        let source;

        switch (rank) {
          case 1:
            source = FirstPlace;
            break;
          case 2:
            source = SecondPlace;
            break;
          case 3:
            source = ThirdPlace;
            break;
          default:
            source = '';
            break;
        }

        return (
          <Button
            disabled
            startIcon={
              rank <= 3 ? (
                <img src={source} alt="Trophy" height={20} />
              ) : (
                <div style={{ height: 20, width: 20 }} />
              )
            }
          >
            <Typography style={{ color: 'black' }}>{rank}</Typography>
          </Button>
        );
      }
    },
    {
      Header: 'Player',
      accessor: 'fullName'
    },
    {
      Header: 'Correct/Total',
      accessor: (row: any) => {
        return `${row.totalNumberOfCorrectAnswers} / ${row.totalNumberOfAnswers}`;
      }
    },
    {
      Header: 'Question Attempts',
      accessor: 'totalNumberOfAnswers'
    },
    {
      Header: 'Correct Answers',
      accessor: 'totalNumberOfCorrectAnswers'
    }
  ];

  return (
    <div style={{ paddingTop: '46px', padding: '25px' }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingBottom: '20px' }}
      >
        <Grid item xs={12}>
          <Button
            startIcon={<ChevronLeft />}
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h3">Leaderboard Report</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Select Date Range</InputLabel>
            <Select
              value={selectedOption}
              onChange={handleSelectTimeFrame}
              label="Select Date Range"
            >
              {Object.values(timeFrames).map((timeFrame: any) => (
                <MenuItem key={timeFrame.label} value={timeFrame.label}>
                  {timeFrame.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item style={{ height: '100%' }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh'
            }}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography>Loading data</Typography>
              </Grid>
            </Grid>
          </div>
        ) : (
          <>
            {features.newDashboardFlag && questionCount.length > 0 ? (
              <Table
                columns={columns}
                data={questionCount}
                elevation={0}
                overflowY={isWidget ? 'scroll' : undefined}
              />
            ) : (
              <>
                <div style={{ textAlign: 'center', paddingTop: '35px' }}>
                  <img
                    src={ReportToolLeaderboardIcon}
                    style={{ height: '120px', width: '120px' }}
                  ></img>
                </div>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '14px',
                    paddingTop: '35px'
                  }}
                >
                  {currentClass.roster.length
                    ? 'There is no data based on your selection! Change the time period to see more information.'
                    : 'Add a player to see reports'}
                </Typography>
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default NewLELeaderboardReportView;
