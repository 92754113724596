import React, { PropsWithChildren, useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';

interface ICodesProps {
  codes: string[];
}

export const Codes = (props: PropsWithChildren<ICodesProps>) => {
  const { codes } = props;
  const min = 0;
  const max = codes.length - 1;
  const [step, setStep] = useState(0);
  const showArrows = codes.length > 1;
  const currentCode = codes[step];
  return (
    <>
      <Grid container xs={12} alignItems={'center'} justifyContent={'center'}>
        {showArrows && (
          <IconButton disabled={step === min} onClick={() => setStep(step - 1)}>
            <ArrowBackIosIcon style={{ color: '#FFBB31' }} />
          </IconButton>
        )}

        <Grid
          item
          xs={8}
          md={6}
          style={{
            padding: '.4rem',
            border: '1px solid #fff',
            borderRadius: '40px'
          }}
        >
          <span
            style={{
              fontSize: '2rem',
              fontFamily: "'Archivo', sans-serif",
              fontStyle: 'italic',
              fontWeight: '900',
              color: '#fff'
            }}
          >
            {currentCode.replace(/(\w{3})(\w{3})(\w{4})/, '$1-$2-$3')}
          </span>
        </Grid>
        {showArrows && (
          <IconButton disabled={step === max} onClick={() => setStep(step + 1)}>
            <ArrowForwardIosIcon style={{ color: '#FFBB31' }} />
          </IconButton>
        )}
      </Grid>
      <Grid container xs={12} justifyContent={'center'}>
        <Typography variant="body2" style={{ color: '#fff', fontWeight: 700 }}>
          {step + 1}/{codes.length}
        </Typography>
      </Grid>
    </>
  );
};
