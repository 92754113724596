import { userTypes } from '../constants/users';
import { 
	teacherRoutes,
	parentRoutes,
	schoolAdminRoutes,
	districtAdminRoutes
} from '../constants/routes';

/**
 * This utility returns a route object while the other route utility returns a string
 *
 * @description This function returns the correct route object based on userType
 * The use case is for dynamic route changes without nested if else conditions.
 *
 * @param userType is the current user type based on the database values
 * */
export function userRouteObjGetter(userType: any) {
  switch (userType) {
    case userTypes.parent:
      return parentRoutes;
    case userTypes.teacher:
      return teacherRoutes;
    case userTypes.schoolAdmin:
      return schoolAdminRoutes;
    case userTypes.districtAdmin:
      return districtAdminRoutes;
    default:
      return parentRoutes;
  }
}
