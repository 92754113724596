import React, { useState } from 'react';
import colors from '../../../styles/colors';
import {
  makeStyles,
  Typography,
  Grid,
  FormControl,
  TextField
} from '@material-ui/core';
import Button from '../../../components/button/Button';
import GoogleSyncImage from '../../../assets/icons/google_sync_icon.png';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  formWrapper: {
    margin: '0 auto',
    maxWidth: '60%',
  },
  formWrapperMobile:{
    margin: '0 auto',
    maxWidth: '100%',
  },
  error: {
    color: colors.error,
    fontSize: 13,
    margin: '0 auto 20px'
  },
  topImage: {
    marginBottom: 20,
    width: 300
  }
}));

interface LinkAccountProps {
  handleLoginClick: (username: string, password: string) => void;
  handleBackClick: () => void;
  errorMessage?: string;
  emailPlaceholder: string;
  passwordPlaceholder: string;
  userExists: boolean;
  googleData?: any
}

const LinkAccount: React.FC<LinkAccountProps> = (props: LinkAccountProps) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  React.useEffect(() => {
    if (props.userExists) {
      const {googleData} = props;
      setUsername(googleData.email)
    }
  });

  return (
    <>
      <img src={GoogleSyncImage} className={classes.topImage} />

      <Typography variant="h5" component="h3">
        Enable Google sign-in
      </Typography>


      <Typography style={{ margin: '20px 0' }}>
        {props.userExists && "Looks like you already have an account with this email. " }
            Enter your {props.emailPlaceholder.toLowerCase()} and password to enable
            Google sign-in.
      </Typography>

      <Grid container spacing={4} className={isMobile ? classes.formWrapperMobile : classes.formWrapper }>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              type="email"
              value={username}
              variant="outlined"
              disabled={props.userExists}
              label={props.emailPlaceholder}
              autoComplete="dreamscape-username"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              label={props.passwordPlaceholder}
              autoComplete="dreamscape-password"
              onChange={(event: any) => setPassword(event.target.value)}
            />
          </FormControl>
        </Grid>

        <Typography className={classes.error}>{props.errorMessage}</Typography>
      </Grid>
      {
        !props.userExists &&
        <Button style={{ marginRight: 10 }} onClick={props.handleBackClick}>
          Back
        </Button>
      }
      <Button
        primary
        onClick={() => props.handleLoginClick(username, password)}
      >
        Continue
      </Button>
    </>
  );
};

export default LinkAccount;
