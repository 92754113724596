const namespacePlayer = '[Player]';

export const PLAYER_UDPATE = `${namespacePlayer} PLAYER UPDATE `;
export const PLAYER_UPDATE_FAILED = `${namespacePlayer} PLAYER UPDATE FAILED`;
export const PLAYER_REMOVE_FROM_LIST = `${namespacePlayer} PLAYER REMOVE FROM LIST`;
export const PLAYER_REMOVE_FROM_LIST_FAILED = `${namespacePlayer} PLAYER REMOVE FROM LIST FAILED`;
export const PLAYER_REGISTER = `${namespacePlayer} PLAYER REGISTER`;
export const PLAYER_REGISTER_FAILED = `${namespacePlayer} PLAYER REGISTER FAILED`;
export const PLAYER_SWITCH_MEMBERSHIP = `${namespacePlayer} PLAYER SWITCH MEMBERSHIP`;
export const PLAYER_SWITCH_MEMBERSHIP_FAILED = `${namespacePlayer} PLAYER SWITCH MEMBERSHIP FAILED`;
export const PLAYER_GET_REPORT = `${namespacePlayer} PLAYER GET REPORT`;
export const PLAYER_GET_SKILLS_REPORT = `${namespacePlayer} PLAYER GET SKILLS REPORT`;
