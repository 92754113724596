import { fetchApi } from './fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';
import { hide, show } from 'redux-modal';
import {
  CLASSROOM_CHANGE_CLASS,
  CLASSROOM_GET_CLASSES,
  CLASSROOM_RESET_LOAD_STATE
} from '../types/google-classroom/google-classroom-action-types';
import store, { RootState } from '../store';
import { GoogleClass } from '../../types/google/google-classroom-import-request-type';
import { trackGoogleClassImported } from './mixpanel-actions';
import {
  GoogleAuthenticatePayload,
  GoogleRegisterPayload
} from '../../types/google/google-authenticate-request-type';
import { USER_UPDATE_REGISTRATION_STEP } from '../types/user/user-action-type';
import { addJobToList, updateWatchOption } from './job-queue-actions';
import { MODAL_DEFAULT } from '../../constants/modals';
import { AxiosError } from 'axios';
import mixpanel from '../../utils/mixpanel';
import { getLocalStorage } from '../../utils/localStorage';

enum AuthorizationType {
  USER_ID = 'id',
  ACCESS_TOKEN = 'accessToken'
}

export const loginWithGoogle = (pathname?: string) => {
  if (pathname?.includes('/educator')) {
    mixpanel.track('Onboarding - Google Sign-up Clicked', {
      registrationFlow: getLocalStorage('newRegistrationFlow')
    });
  }
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sso?platform=web',
        endpoint: APIEndpoints.GOOGLE_CLASSROOM,
        method: APIMethods.GET,
        spinnerMessage: 'Redirecting',
        hideSpinnerAfter: true,
        errorMessage:
          'There was an internal problem. If the problem persists, please contact our support team'
      })
    )
      .then((response: any) => {
        window.location.href = response.data;
      })
      .catch((error: any) => Debug.log(error));
  };
};

export const authenticateGoogleAccount = (
  options: GoogleAuthenticatePayload
) => {
  return fetchApi({
    url: 'auth',
    endpoint: APIEndpoints.GOOGLE_CLASSROOM,
    method: APIMethods.POST,
    data: options,
    spinnerMessage: 'Loading',
    hideSpinnerAfter: true
  });
};

export const registerGoogleAccount = (options: GoogleRegisterPayload) => {
  return fetchApi({
    url: 'register',
    endpoint: APIEndpoints.GOOGLE_CLASSROOM,
    method: APIMethods.POST,
    data: options,
    spinnerMessage: 'Loading',
    hideSpinnerAfter: true,
    errorMessage:
      'There was a problem authenticating you. If the problem persists, please contact our support team'
  });
};

/**
 * @description calls Google Classroom auth and service to retrieve Google Classroom users
 * @param callback
 */
export const authorizeGoogleClassroom = (callback?: any) => {
  return async (dispatch: any) => {
    const { featureFlag } = store.getState() as RootState;
    dispatch(show('spinner', { text: 'Authenticating with Google' }));
    if (featureFlag.googleIdentityService) {
      return triggerGoogleIdentityService(callback);
    }
    return triggerGooglePlatformService(callback);
  };
};

/**
 * Legacy version of google client library will be decommissioned March 23, 2023
 * @param callback
 */
const triggerGooglePlatformService = (callback: any) => {
  const dispatch = store.dispatch;
  const scopes = [
    'email',
    'profile',
    'https://www.googleapis.com/auth/classroom.courses',
    'https://www.googleapis.com/auth/classroom.profile.emails',
    'https://www.googleapis.com/auth/classroom.rosters.readonly'
  ];
  const authOptions = {
    client_id: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID,
    scope: scopes.join(' '),
    prompt: 'select_account'
  };
  //@ts-ignore
  const { gapi } = window;
  gapi.load('auth2', async () => {
    try {
      const auth2 = await gapi.auth2.init(authOptions);
      const googleUser = await auth2.signIn();
      const { access_token: accessToken } = googleUser.getAuthResponse();
      dispatch(
        getGoogleClassroomClasses(
          accessToken,
          AuthorizationType.ACCESS_TOKEN,
          callback
        )
      );
    } catch (err) {
      dispatch(hide('spinner'));
      Debug.log(err);
    }
  });
};

/**
 * @description calls Google Classroom api using the Google identity service library
 * @param callback
 */
const triggerGoogleIdentityService = (callback: any) => {
  const dispatch = store.dispatch;
  const googleCallbackHandler = (tokenResponse: any) => {
    const { access_token } = tokenResponse;
    showClassesModal(access_token, callback);
  };
  const scopes =
    'email \
    profile \
    https://www.googleapis.com/auth/classroom.courses \
    https://www.googleapis.com/auth/classroom.profile.emails \
    https://www.googleapis.com/auth/classroom.rosters.readonly';
  //@ts-ignore
  const { google } = window;
  setTimeout(() => {
    try {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID,
        scope: scopes,
        callback: googleCallbackHandler
      });
      client.requestAccessToken();
    } catch (err) {
      dispatch(hide('spinner'));
      Debug.log('google', err);
    }
  });
};

const showClassesModal = (accessToken: string, callback: any) => {
  const dispatch = store.dispatch;
  return dispatch(
    getGoogleClassroomClasses(
      accessToken,
      AuthorizationType.ACCESS_TOKEN,
      callback
    )
  );
};

export const getGoogleClassroomClasses = (
  authorizationString: string,
  type: AuthorizationType,
  callback?: any
) => {
  return (dispatch: any) => {
    dispatch(resetGoogleClassroomState());
    const { featureFlag } = store.getState() as RootState;
    if (featureFlag.googleClassroomV2) {
      return dispatch(
        fetchApi({
          url: `google/classroom/students?${type}=${authorizationString}`,
          method: APIMethods.GET,
          spinnerMessage: 'Retrieving classes',
          hideSpinnerAfter: true,
          errorMessage:
            'There was an internal problem when retrieving your class list.'
        })
      )
        .then((response: any) => {
          const { classes } = response.data;
          dispatch({
            type: CLASSROOM_GET_CLASSES,
            payload: classes
          });
          if (callback) callback();
        })
        .catch((err: any) => Debug.log(err));
    }
    return dispatch(
      fetchApi({
        endpoint: APIEndpoints.GOOGLE_CLASSROOM,
        url: `get-students?${type}=${authorizationString}`,
        method: APIMethods.GET,
        spinnerMessage: 'Retrieving classes',
        hideSpinnerAfter: true,
        errorMessage:
          'There was an internal problem when retrieving your class list.'
      })
    )
      .then((response: any) => {
        const { data } = response;
        dispatch({
          type: CLASSROOM_GET_CLASSES,
          payload: data
        });
        if (callback) callback();
      })
      .catch((err: any) => Debug.log(err));
  };
};

export const changeGoogleClass = (id: string, key: string, value: any) => {
  return (dispatch: any) => {
    dispatch({
      type: CLASSROOM_CHANGE_CLASS,
      payload: { id, key, value }
    });
  };
};

export const resetGoogleClassroomState = () => {
  return (dispatch: any) => {
    dispatch({
      type: CLASSROOM_RESET_LOAD_STATE
    });
  };
};

export const importGoogleClasses = (
  data: Array<GoogleClass>,
  callback?: any
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/class/import',
        method: APIMethods.POST,
        data,
        spinnerMessage: 'Importing classes from Google Classroom',
        hideSpinnerAfter: true,
        errorMessage:
          'There was an error importing your classes. Please, contact our Support Team if the problem persists.'
      })
    )
      .then((response: any) => {
        const userData: any = store.getState().userData;
        dispatch(
          show('modal', {
            type: MODAL_DEFAULT,
            children:
              "We are syncing your classes. This process may take a while and you will be notified when it's completed"
          })
        );
        dispatch(trackGoogleClassImported({ quantity: data.length }));
        dispatch(addJobToList({ _id: response.data.jobId }));
        dispatch(updateWatchOption(true));
        if (userData.dreamscape && userData.dreamscape.registrationStep === 2) {
          dispatch({
            type: USER_UPDATE_REGISTRATION_STEP,
            payload: 3
          });
        }
        if (callback) callback();
      })
      .catch((error: AxiosError) => {
        if (error.response!.status === 409) {
          dispatch(
            show('modal', {
              type: MODAL_DEFAULT,
              children:
                'We are still processing your previous Sync. Please wait until it finishes to start a new one.'
            })
          );
        }
        Debug.log(error);
      });
  };
};
