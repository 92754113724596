import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';
import {
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_READ
} from '../types/notification/notification-action-types';

export const getNotifications = () => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/notification',
        method: APIMethods.GET
      })
    )
      .then((response: any) => {
        dispatch({
          type: NOTIFICATION_GET_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const readNotification = (notificationId: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/notification/exclude/${notificationId}`,
        method: APIMethods.PUT
      })
    )
      .then((response: any) => {
        dispatch({
          type: NOTIFICATION_READ,
          payload: {
            _id: notificationId
          }
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};
