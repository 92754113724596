import React from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import StarsIcon from '@material-ui/icons/Stars';

import { hide, show } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { skillsReportUpgradeButton } from '../../../redux/actions/mixpanel-actions';
import { push } from 'react-router-redux';
import { userNavigateWithClassCode } from '../../../utils/user-route-getter';
import { teacherRoutes } from '../../../constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: -280,
    top: 10
  }
}));

type ModalPlayerBreakdownUpgradeProps = {
  handleHide: () => void;
};

const ModalSkillsPlayerBreakdownUpgrade: React.FC<ModalPlayerBreakdownUpgradeProps> = (
  props: ModalPlayerBreakdownUpgradeProps
) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { currentClass } = useSelector((state: any) => state.class);
  const classCode = currentClass.code;
  const { handleHide } = props;

  const handleContinue = () => {
    dispatch(skillsReportUpgradeButton(classCode));
    dispatch(
      push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
    );
    handleHide();
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <StarsIcon
        fontSize={'large'}
        style={{
          fill: '#f8bb00',
          width: '3rem',
          position: 'relative',
          top: '20px',
          left: '10px'
        }}
      />
      <DialogTitle style={{ position: 'absolute', top: '5px', left: '40px' }}>
        View Player Breakdown
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '30px 20px' }}>
        <br />
        <Typography>
          Get access to individual player breakdown by skill with a premium
          membership.
        </Typography>
        <br />
        <br />

        <Button
          style={{
            color: 'white',
            textAlign: 'center',
            backgroundColor: '#3FD2D9',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '40%'
          }}
          onClick={handleContinue}
        >
          Upgrade to Premium
        </Button>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalSkillsPlayerBreakdownUpgrade;
