import mixpanel from './mixpanel';
import lodash from 'lodash';
export function getUtmProperties() {
  const utm_query = 'utm_source utm_medium utm_campaign utm_content utm_term';
  const properties = utm_query.split(' ');
  const utm: any = {};
  properties.forEach(prop => {
    const property = mixpanel.get_property(prop, true);
    utm[prop] = property;
  });
  if (lodash.isEmpty(utm)) {
    return null;
  }
  return utm;
}
