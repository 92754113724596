import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import {
  userTypes,
  userTypesDashboardRoute,
  userTypesLoginRoute
} from '../constants/users';
import { Routes } from '../router/routes';
import { isValidJwt } from '../utils/jwt';
import {
  districtAdminRoutes,
  parentRoutes,
  schoolAdminRoutes,
  teacherRoutes
} from '../constants/routes';
import useQueryParams from './UseQueryParams';
import { getClass } from '../redux/actions/class-actions';
import { useEffect } from 'react';

export const ProtectedRoutesPaths = [
  ...Object.values(teacherRoutes),
  ...Object.values(parentRoutes),
  ...Object.values(schoolAdminRoutes),
  ...Object.values(districtAdminRoutes)
];

export const getValidUserType = (route: Routes): userTypes | null => {
  if (Object.values(teacherRoutes).includes(route.path)) {
    return userTypes.teacher;
  } else if (Object.values(parentRoutes).includes(route.path)) {
    return userTypes.parent;
  } else if (Object.values(schoolAdminRoutes).includes(route.path)) {
    return userTypes.schoolAdmin;
  } else if (Object.values(districtAdminRoutes).includes(route.path)) {
    return userTypes.districtAdmin;
  } else {
    return null;
  }
};

const isProtectedRoute = (route: Routes): boolean =>
  ProtectedRoutesPaths.includes(route.path);

const isValidUserType = (userType: string, route: Routes): boolean => {
  return userType === getValidUserType(route);
};

export const getLoginRoute = (route: Routes): string => {
  const validUserType = getValidUserType(route);

  if (validUserType) {
    return userTypesLoginRoute[validUserType];
  } else {
    return '/login';
  }
};

export const isDynamicRoute = (path: string): boolean => path.includes(':');

const useAuthentication = (route: Routes): void => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams<{ classCode: string | undefined }>();
  const userData = useSelector((state: any) => state.userData);
  const accessToken = useSelector((state: any) => state.login?.token);
  const queryParams = useQueryParams();
  const urlAccessToken = queryParams?.accessToken;
  const referringURL = queryParams?.referring_url;

  useEffect(() => {
    if(referringURL) {
      localStorage.setItem('referringURL', decodeURIComponent(referringURL));
    }
  }, [referringURL]);

  if (isProtectedRoute(route)) {
    if (urlAccessToken && urlAccessToken !== accessToken) {
      dispatch(
        push({
          pathname: '/authorize',
          search: `?accessToken=${urlAccessToken}&redirectTo=${
            location.pathname
          }${params.classCode ? `&classCode=${params.classCode}` : ''}`
        })
      );
      return;
    } else if (urlAccessToken && params.classCode) {
      dispatch(getClass(params.classCode));
    }

    if (!userData.token || !isValidJwt(userData.token)) {
      console.log('Not Authenticated to access ');
      dispatch(
        push(getLoginRoute(route), {
          redirectTo: location.pathname,
          route: route.path
        })
      );
    } else if (!isValidUserType(userData.userType, route)) {
      console.log('Not Authorized to access ', location.pathname);
      dispatch(push(userTypesDashboardRoute[userData.userType]));
    }
  }
};

export default useAuthentication;
