import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { switchMembership } from '../../../redux/actions/player-actions';
import { playerNounLowerCase } from '../../../utils/userNoun';

const ModalAssignMembership: React.FC = (props: any) => {
  const {
    show,
    isSwitch,
    handleHide,
    currentPlayer,
    unassignedMembership,
    classCode
  } = props;
  let { unassignedPlayers } = props;
  const dispatch = useDispatch();
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
  const initalErrorState = {
    isError: false,
    isErrorSubscriptionId: false,
    errorTextSubscriptionId: '',
    errorText: ''
  };
  const [errors, setErrors] = useState(initalErrorState);
  if (classCode) {
    unassignedPlayers = unassignedPlayers.filter((e: any) => {
      return e.joinedWith.value === classCode;
    });
  }
  const handleChange = (evt: any) => setSelectedPlayer(evt.target.value);
  const assignMembership = (): any => {
    if (selectedPlayer.length === 0) {
      return setErrors({
        ...errors,
        isError: true,
        errorText: `Please select a ${playerNounLowerCase}`
      });
    }
    if (!isSwitch && selectedSubscriptionId.length === 0) {
      return setErrors({
        ...errors,
        isErrorSubscriptionId: true,
        errorTextSubscriptionId: `Please select a ${playerNounLowerCase}`
      });
    }
    if (errors.isError) {
      setErrors(initalErrorState);
    }

    let request = null;
    if (isSwitch) {
      request = {
        from: currentPlayer._id,
        subscriptionId: currentPlayer.subMeta._id,
        to: selectedPlayer
      };
    } else {
      const subscriptionId = unassignedMembership.filter(
        (item: any) => item.uuid === selectedSubscriptionId
      )[0].subMeta._id;
      request = {
        subscriptionId,
        to: selectedPlayer
      };
    }
    dispatch(switchMembership(request));
  };
  const handleSubscriptionSelection = (evt: any) => {
    setSelectedSubscriptionId(evt.target.value);
  };
  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Switch Subscription</DialogTitle>
      <DialogContent>
        <FormControl error={errors.isError} fullWidth>
          <FormLabel>
            Select the player account that you'd like to transfer this
            membership to.
          </FormLabel>
          <Select fullWidth value={selectedPlayer} onChange={handleChange}>
            {unassignedPlayers.map((data: any) => {
              return (
                <MenuItem value={data._id}>
                  {data.firstname} ({data.username})
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{errors.isError && errors.errorText}</FormHelperText>
        </FormControl>

        {unassignedMembership && (
          <>
            <FormLabel component="legend" style={{ marginBottom: 20 }}>
              Please, select one available membership:
            </FormLabel>
            <FormControl
              component="fieldset"
							style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: 200, width: '100%' }}
            >
              <RadioGroup
                aria-label="membership"
                value={selectedSubscriptionId}
                onChange={handleSubscriptionSelection}
              >
                {unassignedMembership.map((e: any) => {
                  return (
                    <FormControlLabel
                      value={e.uuid}
                      control={<Radio color="primary" />}
                      label={`Expires on ${moment(e.subMeta.expiryDate).format(
                        'll'
                      )}`}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Cancel</Button>
        <Button primary onClick={assignMembership}>
          Assign
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalAssignMembership;
