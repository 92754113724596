import React from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '../../components/button/Button';
import { ErrorOutlineOutlined } from '@material-ui/icons';
import TeacherFeatureFlagContext from '../../layouts/teacher/TeacherFeatureFlagContext';
import mixpanel from '../../utils/mixpanel';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#009DE0',
    padding: '1rem 2rem',
    position: 'relative',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    color: 'white',
    marginLeft: 10,
    marginRight: 10,
    fontSize: '0.9rem'
  }
}));

export const PriceIncreaseBanner: React.FC<any> = ({ handleUpgrade }) => {
  const styles = useStyles();
  const { flags } = React.useContext(TeacherFeatureFlagContext);
  if (!flags.isPriceChangePrep) {
    return null;
  }

  const onUpgrade = () => {
    mixpanel.track('Upgrade today clicked');
    handleUpgrade();
  };

  return (
    <Grid container className={styles.container}>
      <Grid item className={styles.content}>
        <ErrorOutlineOutlined style={{ color: 'white' }} />

        <span className={styles.text}>
          The price of the Premium plan is increasing. Lock in the current price
          by upgrading to a Premium plan before{' '}
          <strong style={{ fontSize: '1rem' }}>August 15, 2022</strong>.
        </span>

        <Button white fontSize="1rem" onClick={onUpgrade}>
          Upgrade today
        </Button>
      </Grid>
    </Grid>
  );
};
