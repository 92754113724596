import { parentRoutes } from "./routes";

export enum userTypes {
  parent = 'Parent',
  teacher = 'Teacher',
  schoolAdmin = 'School Admin',
  districtAdmin = 'District Admin',
  student = 'Student',
  player = 'Player'
}

export const userTypesDashboardRoute: any = {
  [userTypes.parent]: parentRoutes.homeScreen,
  [userTypes.teacher]: '/dashboard/teacher',
  [userTypes.schoolAdmin]: '/dashboard/school-admin',
  [userTypes.districtAdmin]: '/dashboard/district-admin'
};

export const userTypesLoginRoute: any = {
  [userTypes.parent]: '/login/parent',
  [userTypes.teacher]: '/login/educator',
  [userTypes.schoolAdmin]: '/login/school',
  [userTypes.districtAdmin]: '/login/school'
};

export const userAdmins = [userTypes.schoolAdmin, userTypes.districtAdmin];

export const adminSpecialties = [
  'Principal',
  'Vice Principal',
  'Department Head',
  'Curriculum Coordinator',
  'Executive Director',
  'Librarian',
  'Literacy Expert',
  'Technology Integrator'
];

export const teacherSpecialties = [
  'Classroom Teacher',
  'Title 1 Educator',
  ...adminSpecialties
];

export const referralOptions = [
  'Email',
  'Referral',
  'Social Media',
  'Conference',
  'Blog',
  'Web Search',
  'Teachers Pay Teachers',
  'Other'
];

export const userTypesDescription = {
  parent:
    'Parents can use their free Dreamscape dashboard to track player progress and reward play!',
  teacher:
    'Teachers can use their free Dreamscape dashboard to create and manage player accounts, track reading and skill progress, and assign content to align Dreamscape with classroom instruction!',
  admin:
    'Administrators can use their free Dreamscape dashboard to review school and classroom progress!',
  student:
    'Player Dreamscape accounts allow players to play Dreamscape and read amazing content!'
};
