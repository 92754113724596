import { PLAYER_GET_SKILLS_REPORT } from '../types/player/player-action-types';

export const playerEducationReducer = (
  state = [],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case PLAYER_GET_SKILLS_REPORT:
      return payload;
    default:
      return state;
  }
};
