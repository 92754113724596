import React, { useState } from 'react';
import ReportToolTemplate from './ReportToolTemplate';
import ReportToolPlacementTestIcon from '../../assets/icons/report/placement-test-icon.png';
import { trackViewPlacementTestReport } from '../../redux/actions/mixpanel-actions';
import PlacementTestPieWidget from './PlacementTestPieWidget';
import { ReportTools } from '../../redux/features/ReportTool';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { useDispatch, useSelector } from 'react-redux';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
interface ReportToolPlacementTestProps {
  isWidget?: boolean;
}

interface LearnerRclData {
  id: number;
  learnerId: number;
  contextType: string;
  newRCL: number;
  rclDate: Date;
}

const ReportToolPlacementTest: React.FC<ReportToolPlacementTestProps> = ({
  isWidget
}: ReportToolPlacementTestProps) => {
  const { currentClass } = useSelector((state: any) => state.class);
  const dispatch = useDispatch();
  const features = useAppSelector(state => state.featureFlag);
  const [placementTestUsers, setPlacementTestUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    fetchActivePlacementTests();
  }, []);

  const fetchActivePlacementTests = () => {
    const playerIds: number[] = currentClass.roster.map(
      (user: any) => user.educationUserId
    );
    if (playerIds && playerIds.length > 0) {
      if (features.newDashboardFlag) {
        setLoading(true);
        let url = `v1/reports/rcl-history?`;
        for (const playerId of playerIds) {
          url = url + `&learners[]=${playerId}`;
        }
        dispatch(
          fetchApi({
            url: url,
            method: APIMethods.GET,
            endpoint: APIEndpoints.LE_API,
          })
        )
          .then((response: AxiosResponse) => {
            const learnersRclData: LearnerRclData[] = response.data;

            const maxNewRcl = Object.values(
              learnersRclData
            ).map(({ newRCL, learnerId }) => ({ newRCL, learnerId }));
            if (maxNewRcl) {
              const addDefaultRCL = currentClass.roster.map((id: any) => {
                const match = maxNewRcl.find(
                  (learnerId: any) => learnerId.learnerId === id.educationUserId
                );
                if (match) {
                  return match;
                } else {
                  return { learnerId: id.educationUserId, newRCL: '' };
                }
              });
              if (addDefaultRCL) {
                const currentRclLearners: any = addDefaultRCL.map(
                  (learner: any) => ({
                    ...learner,
                    name: currentClass.roster.find(
                      (player: any) =>
                        player.educationUserId === learner.learnerId
                    ).fullname,
                    currentGrade: Math.floor(learner.newRCL)
                  })
                );

                let url = `v1/learners/assignment-attempts?assignmentType=PlacementTest&distinct=learnerId`;
                for (const playerId of playerIds) {
                  url = url + `&learnerIds[]=${playerId}`;
                }
                
                dispatch(
                  fetchApi({
                    url: url,
                    method: APIMethods.GET,
                    endpoint: APIEndpoints.LE_API,
                  })
                )
                  .then((response: AxiosResponse) => {
                    const assignmentAttempts: any = response.data;
                    const finalPlacementTestArray: any = [];
                    for (let i = 0; i < currentRclLearners.length; i++) {
                      const current = currentRclLearners[i];
                      const match = assignmentAttempts.find(
                        (d: any) => d.learnerId === current.learnerId
                      );
                      if (match) {
                        finalPlacementTestArray.push({
                          status: match.completionStatus,
                          updatedAt: match.updatedAt,
                          assignmentGivenId: match.assignmentGivenId,
                          currentGrade: current.currentGrade,
                          name: current.name,
                          readingLevel: current.newRCL,
                          learnerId: current.learnerId,
                          questionAttemptCount: match._count.questionAttempt
                        });
                      } else {
                        finalPlacementTestArray.push({
                          status: 'NotStarted',
                          updatedAt: '',
                          assignmentGivenId: '',
                          currentGrade: current.currentGrade,
                          name: current.name,
                          readingLevel: '',
                          learnerId: current.learnerId,
                          questionAttemptCount: 0
                        });
                      }
                    }
                    for (let i = 0; i < finalPlacementTestArray.length; i++) {
                      const item = finalPlacementTestArray[i];
                      if (
                        item.status === 'NotStarted' &&
                        item.questionAttemptCount &&
                        item.questionAttemptCount > 0
                      ) {
                        item.status = 'IN PROGRESS';
                      }
                    }
                    console.log('tysds', finalPlacementTestArray);
                    setPlacementTestUsers(finalPlacementTestArray);
                  })
                  .catch((err: AxiosError) => err);
              }
            }
          })
          .catch((err: AxiosError) => err);
        setLoading(false);
      } else if (!features.newDashboardFlag) {
        dispatch(
          fetchApi({
            url: `v2.1/assignment/placement-test?playerId=${playerIds.toString()}`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.GET
          })
        )
          .then((response: AxiosResponse) => {
            const { placementTestUsers } = response.data;
            if (placementTestUsers) {
              const mapPlacementTestUsers = placementTestUsers.map(
                (placementTestUser: any) => ({
                  ...placementTestUser,
                  name: currentClass.roster.find(
                    (player: any) =>
                      player.educationUserId === placementTestUser.userId
                  ).fullname
                })
              );
              setPlacementTestUsers(mapPlacementTestUsers);
            }
          })
          .catch((err: AxiosError) => err);
      }
    }
  };
  const mappedPieChartUser = placementTestUsers.map(
    (placementTestUser: any) => {
      return {
        currentGrade: placementTestUser.currentGrade,
        name: placementTestUser.name,
        passageLevel: placementTestUser.passageLevel,
        readingLevel: placementTestUser.readingLevel,
        status: placementTestUser.status
      };
    }
  );

  return (
    <ReportToolTemplate
      title="Placement Test"
      reportToolState={[ReportTools.PlacementTest]}
      subTitle=""
      tracker={trackViewPlacementTestReport}
      isWidget={isWidget}
      source={ReportToolPlacementTestIcon}
      body={
        !loading ? (
          mappedPieChartUser && mappedPieChartUser.length > 0 ? (
            <PlacementTestPieWidget
              height={270}
              hideContainer={true}
              legend={{ position: 'bottom' }}
              data={mappedPieChartUser}
            />
          ) : (
            <>
              <img
                src={ReportToolPlacementTestIcon}
                style={{ height: '120px', width: '120px' }}
              ></img>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px'
                }}
              >
                {currentClass.roster.length
                  ? 'Please assign placement test to players to view the status'
                  : 'Add a player to see reports'}
              </Typography>
            </>
          )
        ) : (
          <>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography>Loading data</Typography>
              </Grid>
            </Grid>
          </>
        )
      }
    />
  );
};

export default ReportToolPlacementTest;
