import React from 'react';
import ModalAbstract from '../../../../../components/popups/modals/ModalAbstract';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '../../button/Button';

interface DeleteAssignmentModalProps {
  show: boolean;
  handleHide(): void;
  handleDeleteAssignment(shouldDelete: boolean): void;
}
const DeleteAssignmentModal = (props: DeleteAssignmentModalProps) => {
  const { show, handleDeleteAssignment, handleHide } = props;
  return (
    <ModalAbstract show={show}>
      <DialogTitle>Delete Assignment</DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to delete this assignment ? Reports for this
          assignment will be disabled after deletion.
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant={'outline'} color={'black'} onClick={handleHide}>
          Cancel
        </Button>
        <Button
          variant={'filled'}
          color={'red'}
          textColor={'white'}
          onClick={() => {
            handleDeleteAssignment(true);
            handleHide();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default DeleteAssignmentModal;
