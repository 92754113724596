import {
  MEMBERS_GET_ALL,
  MEMBERS_GET_ALL_FAILED
} from '../types/members/members-action-types';

export const membersReducer = (
  state = [],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case MEMBERS_GET_ALL:
      return payload;
    case MEMBERS_GET_ALL_FAILED:
      return state;
    default:
      return state;
  }
};
