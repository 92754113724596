import React from 'react';
import { createStyles, InputBase, Theme, withStyles } from '@material-ui/core';
import colors from '../../../../styles/colors';

const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${colors.assignment.grey}`,
      fontSize: 16,
      padding: '6px 26px 6px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase);

export default CustomInput;
