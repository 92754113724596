import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../button/Button';
import { TextField } from '@material-ui/core';
import { sendReferrals } from '../../../../redux/actions/referral-actions';
import { isMobile } from 'react-device-detect';
import validator from 'validator';

const ChuckReferral: React.FC = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const [referralEmail, setReferralEmail] = useState('');
  const referralUrl: string = `${process.env.REACT_APP_DASHBOARD_URL}register/educator/?rf=${userData._id}`;

  return (
    <>
      <TextField
        variant="outlined"
        placeholder="Teacher e-mail"
        style={!isMobile ? { flex: 1, marginRight: 5 } : { width: '100%' }}
        inputProps={{ style: { padding: 10 } }}
        value={referralEmail}
        onChange={event => setReferralEmail(event.target.value)}
      />
      <Button
        primary
        disabled={!validator.isEmail(referralEmail)}
        style={isMobile ? { margin: '10px 0', width: '100%' } : {}}
        onClick={() => dispatch(sendReferrals([referralEmail], referralUrl))}
      >
        Send Referral
      </Button>
    </>
  );
};

export default ChuckReferral;
