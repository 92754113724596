import React from 'react';
import {
  makeStyles,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import ModalAbstract from './ModalAbstract';

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

type ModalPremiumPlusLegacyInfoProps = {
  show: () => void;
  handleHide: () => void;
  currentPlan: string;
  playerMemberships: number;
  annualPrice: string;
  renewsOn: string;
};

const ModalPremiumPlusLegacyInfo: React.FC<ModalPremiumPlusLegacyInfoProps> = (
  props: ModalPremiumPlusLegacyInfoProps
) => {
  const { show, handleHide } = props;
  const styles = useStyles();

  return (
    <ModalAbstract show={show} handleHide={handleHide} maxWidth="sm">
      <DialogTitle>
        Your plan details
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <Typography>
          Your current plan: <b>{props.currentPlan}</b>
        </Typography>
        <Typography>
          Player memberships included: <b>{props.playerMemberships}</b>
        </Typography>
        <Typography>
          Annual price: <b>{props.annualPrice}</b>
        </Typography>
        <Typography>
          Renews: <b>{props.renewsOn}</b>
        </Typography>
        <br />
        <Typography>Your plan benefits:</Typography>
        <Typography>
          Your legacy plan has the same benefits as the Premium plan without the
          ability to add more player memberships.
        </Typography>
        <br />
        <Typography>
          If you have any questions or require assistance please contact us at
          <a href="mailto:support@shoelacelearning.com">
            {' '}
            support@shoelacelearning.com
          </a>
        </Typography>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalPremiumPlusLegacyInfo;
