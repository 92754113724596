import React from 'react';
import Button from '../../button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useSelector, useDispatch } from 'react-redux';
import { readNotification } from '../../../redux/actions/notification-actions';
import { makeStyles } from '@material-ui/styles';
import { userTypes } from '../../../constants/users';

const useStyles = makeStyles(() => ({
  content: {
    color: '#464646',
    '& img': {
      margin: '10px 0',
      maxWidth: '100%'
    },
    '& h1, & h2, & h3, & h4': {
      marginBottom: 10
    },
    '& h1': {
      fontSize: 22
    },
    '& h2': {
      fontSize: 20
    },
    '& h3': {
      fontSize: 18
    },
    '& h4': {
      fontSize: 16
    },
    '& p': {
      fontSize: 16,
      margin: '10px 0'
    },
    '& ul': {
      paddingLeft: 20,
      margin: '20px 0'
    }
  }
}));

const ModalNotification: React.FC = (props: any) => {
  const notifications = useSelector((state: any) => state.notifications);
  const router = useSelector((state: any) => state.router);
  const userData = useSelector((state: any) => state.userData);
  const dispatch = useDispatch();
  const classes = useStyles();

  const isLoginScreen = () => {
    return router.location.pathname.indexOf('/login') !== -1;
  }

  if (
    isLoginScreen() || 
    !notifications.length ||
    (userData.userType === userTypes.teacher &&
      userData.dreamscape &&
      userData.dreamscape.registrationStep < 3) ||
    (userData.userType === userTypes.schoolAdmin &&
      userData.dreamscape &&
      userData.dreamscape.registrationStep < 2)
  ) {
    return null;
  }

  const { content, _id } = notifications[0];

  const handleCloseNotification = () => {
    dispatch(readNotification(_id));
  };

  return (
    <div>
      <Dialog
        open={notifications.length}
        onClose={props.handleHide}
        maxWidth="md"
      >
        {/* <DialogTitle>{title}</DialogTitle> */}
        <DialogContent style={{ padding: 20 }} dividers={true}>
          <DialogContentText
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: content }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotification} primary>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalNotification;
