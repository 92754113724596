import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import SearchBar from '../../../components/searchbar/SearchBar';
import { Skills, Period } from '../../../types/reports/skill-report-types';
import { grades } from '../../../constants/global-constants';
import { show } from 'redux-modal';
import {
  MODAL_SELECT_DATE_RANGE,
  MODAL_CREATE_ASSIGNMENT
} from '../../../constants/modals';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Button from '../../../components/button/Button';

export interface FilterState {
  grade: number;
  searchState: Array<any>;
  skills: Skills;
  period: Period;
  range: {
    from: moment.Moment;
    to: moment.Moment;
  };
}

export const initialFilterState = {
  grade: 0,
  searchState: [],
  skills: Skills.allSkills,
  period: Period.allTime,
  range: {
    from: moment('2018-01-01'),
    to: moment()
  }
};

interface SkillsFiltersProps {
  disabled?: boolean;
  originalData: Array<any>;
  onChange: (filter: FilterState) => void;
}

const SkillsFilters: React.FC<SkillsFiltersProps> = (
  props: SkillsFiltersProps
) => {
  const [filter, setFilter] = useState<FilterState>(initialFilterState);
  const [prevSelection, setPrevSelection] = useState(2);
  const dispatch = useDispatch();

  useEffect(() => {
    props.onChange(filter);
  }, [filter]);

  return (
    <Grid container spacing={4} style={{ marginBottom: 20 }}>
      <Grid item md={3}>
        <SearchBar
          searchKey="skill"
          disabled={props.disabled}
          placeholder="Search by Skill Name"
          originalState={props.originalData}
          onChange={(searchState: any) => setFilter({ ...filter, searchState })}
          getValue={(value: string) => {
            if (value === '') {
              setFilter({
                ...filter,
                skills: Skills.allSkills,
                searchState: props.originalData
              });
            }
          }}
          styles={{ container: { height: '100%' } }}
          applyFilterAfterUpdate={true}
        />
      </Grid>
      <Grid item md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Questions From</InputLabel>
          <Select
            fullWidth
            value={filter.grade}
            disabled={props.disabled}
            onChange={(event: any) =>
              setFilter({ ...filter, grade: event.target.value })
            }
          >
            <MenuItem value={0}>All Grades</MenuItem>
            {grades.map((grade: number) => (
              <MenuItem value={grade}>{`Grade ${
                grade
              }`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Time Period</InputLabel>
          <Select
            fullWidth
            value={filter.period}
            disabled={props.disabled}
            onClick={(event: any) => {
              var { value, textContent } = event.target;
              if (textContent) {
                value = prevSelection;
              }
              if (value === Period.timeRange) {   
                setPrevSelection(value);
                dispatch(
                  show('modal', {
                    type: MODAL_SELECT_DATE_RANGE,
                    onConfirm: (from: Date, to: Date) =>
                      setFilter({
                        ...filter,
                        period: Period.timeRange,
                        range: {
                          from: moment(from),
                          to: moment(to)
                        }
                      })
                  })
                );
              } else if (value != undefined) {
                let from = initialFilterState.range.from;
                let to = initialFilterState.range.to;
                setPrevSelection(value);
                switch (value) {
                  case Period.allTime:
                    from = moment('2018-01-01');
                    break;
                  case Period.last30Days:
                    from = moment().subtract(30, 'days');
                    break;
                  case Period.last7Days:
                    from = moment().subtract(7, 'days');
                    break;
                  case Period.thisWeek:
                    from = moment().startOf('isoWeek');
                    break;
                  default:
                    break;
                }
                setFilter({
                  ...filter,
                  period: value,
                  range: {
                    to,
                    from
                  }
                });
              }
              
            }}
          >
            <MenuItem value={Period.allTime}>All Time</MenuItem>
            <MenuItem value={Period.thisWeek}>This Week</MenuItem>
            <MenuItem value={Period.last7Days}>Last 7 Days</MenuItem>
            <MenuItem value={Period.last30Days}>Last 30 Days</MenuItem>
            <MenuItem value={Period.timeRange}>Select Time Range</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container item md={3} justify="flex-end">
        <Button
          primary
          disabled={props.disabled}
          style={{ height: '100%' }}
          onClick={() =>
            dispatch(show('modal', { type: MODAL_CREATE_ASSIGNMENT }))
          }
        >
          Create Assignment
        </Button>
      </Grid>
    </Grid>
  );
};

export default SkillsFilters;
