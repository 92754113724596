import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReportToolTemplate, { getTableOptions } from './ReportToolTemplate';
import Table from '../table/Table';
import {
  Tooltip,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import { trackViewAssignmentTool } from '../../redux/actions/mixpanel-actions';
import { push } from 'connected-react-router';
import { userTypesDashboardRoute } from '../../constants/users';
import ReportToolAssignmentIcon from '../../assets/icons/report/assignments-icon.png';
import { Statuses, ReportTools } from '../../redux/features/ReportTool';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { useAppSelector } from '../../redux/hooks';
import { teacherRoutes } from '../../constants/routes';

interface ReportToolAssignmentProps {
  isWidget?: boolean;
}

const ReportToolAssignment: React.FC<ReportToolAssignmentProps> = ({
  isWidget
}: ReportToolAssignmentProps) => {
  const { data, status } = useSelector(
    (state: any) => state.reportTool[ReportTools.AssignmentTool]
  );
  const features = useAppSelector(state => state.featureFlag);
  const userHasAccessToUserGeneratedContent =
    features.userGeneratedContent?.hasAccess;
  const { currentClass } = useAppSelector(state => state.class);
  const [loading, setLoading] = React.useState(
    !userHasAccessToUserGeneratedContent
  );

  const [assignmentsData, setAssignmentsData] = React.useState(
    userHasAccessToUserGeneratedContent ? [] : data
  );

  React.useEffect(() => {
    if (userHasAccessToUserGeneratedContent) {
      fetchAssignmentsReportByClassCode(currentClass.code)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, []);
  const userType = useSelector((state: any) => state.userData.userType);

  const dispatch = useDispatch();

  async function fetchAssignmentsReportByClassCode(classCode: string) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/user-generated/report/class/${classCode}`,
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.GET
        })
      );
      const { assignments } = response.data;
      if (assignments && assignments.length > 0) {
        setAssignmentsData(assignments);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const reportToolHasLoaded = userHasAccessToUserGeneratedContent
    ? !loading
    : status === Statuses.Success;

  const body = reportToolHasLoaded && (
    <>
      <Grid item>
        <Table
          columns={[
            {
              Header: 'Name',
              accessor: 'title',
              Cell: (row: any) => {
                const {
                  title,
                  fullTitle,
                  link,
                  _id,
                  id,
                  isUserGenerated
                } = row.row.original;
                const reportId = link || id || _id;
                return (
                  <Tooltip title={fullTitle}>
                    <Button
                      startIcon={
                        <div
                          style={{
                            borderRadius: '50%',
                            backgroundColor: row.row.original.color,
                            height: 20,
                            width: 20
                          }}
                        />
                      }
                      onClick={() =>
                        dispatch(
                          push(
                            `${userTypesDashboardRoute[userType]}/assignment-tool/report/${reportId}/${currentClass?.code}?isUserGenerated=${isUserGenerated}`
                          )
                        )
                      }
                    >
                      {title}
                    </Button>
                  </Tooltip>
                );
              }
            },
            {
              Header: 'End Date',
              accessor: 'end',
              Cell: (row: any) => {
                const { end, disabled } = row.row.original;

                return (
                  <Typography
                    style={{
                      color: disabled ? '#d3d3d3' : '#000',
                      fontSize: 14
                    }}
                  >
                    {moment(end).format('MMM DD YYYY')}
                  </Typography>
                );
              }
            }
          ]}
          data={assignmentsData}
          {...getTableOptions(isWidget)}
          elevation={0}
          height="300px"
          overflowY={isWidget ? 'scroll' : 'none'}
        />
      </Grid>
    </>
  );
  return loading && userHasAccessToUserGeneratedContent ? (
    <CircularProgress color={'primary'} />
  ) : (
    <ReportToolTemplate
      title="Assignment Reports"
      reportToolState={[ReportTools.AssignmentTool]}
      isWidget={isWidget}
      subTitle=""
      body={body}
      tracker={trackViewAssignmentTool}
      source={ReportToolAssignmentIcon}
      buttonText="View All"
      buttonLink={
        userHasAccessToUserGeneratedContent
          ? teacherRoutes.assignmentReportClassView.replace(
              ':classCode',
              currentClass.code
            )
          : undefined
      }
    />
  );
};

export default ReportToolAssignment;
