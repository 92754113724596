import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  Theme,
  TextField,
  Typography,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import {
  updateAssignment,
  updateAssignmentNewLE,
  updateAssignmentV2,
  updateUserGeneratedAssignment
} from '../../../redux/actions/planner-actions';
import { hide, show } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '../../button/Button';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import colors from '../../../styles/colors';

const ModalUpdateAssignment = ({
  id,
  title,
  startDate,
  endDate,
  assignmentType,
  isUserGenerated,
  groupReference,
  setComponentReady,
  hasAccessToUserGeneratedContent
}: any): JSX.Element => {
  const featureFlag = useSelector((state: any) => state.featureFlag);
  const [assignment, setAssignment] = useState({ title, startDate, endDate });
  const dispatch = useDispatch();
  const update = () => {
    if (featureFlag.newLeAssignments) {
      dispatch(
        updateAssignmentNewLE(
          id,
          assignment.title,
          assignment.startDate,
          assignment.endDate
        )
      );
    } else if (isUserGenerated) {
      dispatch(
        updateUserGeneratedAssignment(groupReference, assignment.endDate)
      );
    } else if (hasAccessToUserGeneratedContent) {
      dispatch(
        updateAssignmentV2(
          id,
          assignment.title,
          assignment.startDate,
          assignment.endDate
        )
      );
    } else {
      dispatch(
        updateAssignment(
          id,
          assignment.title,
          assignment.startDate,
          assignment.endDate
        )
      );
    }
    setComponentReady(false);
  };

  const useStyles = makeStyles((theme: Theme) => ({
    input: {
      margin: '10px 0'
    },
    closeButton: {
      color: theme.palette.grey[500],
      position: 'absolute',
      right: 10,
      top: 10
    },
    testPrepError: {
      color: colors.error,
      padding: '0px 15px'
    }
  }));

  const classes = useStyles();

  return (
    <ModalAbstract show={() => dispatch(show('modal'))}>
      <DialogTitle>
        Update Assignment
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => dispatch(hide('modal'))}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {!isUserGenerated && (
            <Grid container spacing={4}>
              <TextField
                fullWidth
                name="name"
                variant="outlined"
                label="Update your assignment name"
                className={classes.input}
                value={assignment.title}
                error={assignment.title === ''}
                helperText={
                  assignment.title === '' ? 'Title cannot be empty.' : ''
                }
                onChange={(event: any) =>
                  setAssignment({
                    ...assignment,
                    title: event.target.value
                  })
                }
              />
            </Grid>
          )}

          <Grid container spacing={4}>
            <Grid item xs={12} spacing={4}>
              <Typography component="p" style={{ marginTop: 10 }}>
                Select the date to start and end the assignment:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} spacing={4}>
              <Typography component="p">Start Date:</Typography>
              <DatePicker
                margin="normal"
                label="Start Date"
                format="MM/dd/yyyy"
                value={assignment.startDate}
                disabled={moment() >= moment(startDate)}
                shouldDisableDate={(day: any) => {
                  return (
                    moment(day).format('MM DD YYYY') <
                    moment().format('MM DD YYYY')
                  );
                }}
                allowKeyboardControl={false}
                onChange={(startDate: any) => {
                  setAssignment({ ...assignment, startDate });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} spacing={4}>
              <Typography component="p">End Date:</Typography>
              <DatePicker
                margin="normal"
                label="End Date"
                format="MM/dd/yyyy"
                value={assignment.endDate}
                minDate={moment()}
                allowKeyboardControl={false}
                minDateMessage="End Date must be greater or equal Start Date"
                onChange={(endDate: any) => {
                  setAssignment({ ...assignment, endDate });
                }}
              />
            </Grid>
            {moment(assignment.endDate).diff(
              moment(assignment.startDate),
              'week'
            ) < 4 &&
              assignmentType === 'TEST PREP' && (
                <Typography className={classes.testPrepError}>
                  Please note, 4 weeks will allow most players to complete all 4
                  test prep assessments. When players complete the assessment,
                  the game reverts players to our adaptive curriculum so they
                  will continue practicing in their zone of proximal
                  development. If reducing the end date of the assessment,
                  please allow for ample class time for players to complete the
                  assessment.
                </Typography>
              )}
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ marginRight: '5px' }}
          className="button--small"
          primary={true}
          onClick={() => update()}
          disabled={
            assignment.title === '' ||
            moment(assignment.startDate) > moment(assignment.endDate)
          }
        >
          {'Update'}
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalUpdateAssignment;
