import React from 'react';
import { FreeTrialWrapper } from './FreeTrialWrapper';
import classMissionIcon from '../../assets/membership/classMissionIcon.png';
import reportingIcon from '../../assets/membership/reporting.png';
import rewardIcon from '../../assets/membership/rewardIcon.png';
import testPrepIcon from '../../assets/membership/testPreptIcon.png';

const premiumPropertiesArray = [
  {
    imgUrl: reportingIcon,
    boldDescription: 'Comprehensive',
    description: 'player reporting'
  },
  {
    imgUrl: classMissionIcon,
    boldDescription: 'Unlimited',
    description: 'Class Missions'
  },
  {
    imgUrl: rewardIcon,
    boldDescription: 'In-game rewards',
    description: 'for your class'
  },
  {
    imgUrl: testPrepIcon,
    boldDescription: 'Premium',
    description: 'test prep and assignments'
  }
];
const UpgradeUpsellScreen: React.FC<any> = () => {
  return (
    <FreeTrialWrapper
      headerText="Upgrade to Premium"
      isMandatoryFreeTrial={false}
      isUpsellScreen={true}
      heading="$29"
      subHeading="Billed annually"
      buttonText="Upgrade To Premium"
      premiumPropertiesArray={premiumPropertiesArray}
    />
  );
};

export default UpgradeUpsellScreen;
