export const teacherRoutes = {
  homeScreen: '/dashboard/teacher',
  classStudent: '/dashboard/teacher/students/:classCode',
  rewardsPage: '/dashboard/teacher/rewards/:classCode',
  classDashboard: '/dashboard/teacher/class/:classCode',
  classReportsReadingLevel:
    '/dashboard/teacher/reports/reading-level/:classCode',
  classReportsSkills:
    '/dashboard/teacher/reports/skill-comprehension/:classCode',
  classReportsPlacementTest:
    '/dashboard/teacher/reports/placement-test/:classCode',
  classReportsAssignmentTool:
    '/dashboard/teacher/reports/assignment-reports/:classCode',
  classReports: '/dashboard/teacher/reports/:classCode',
  assignmentReportClassView:
    '/dashboard/teacher/assignments/reports/class/:classCode',
  assignmentReportView: '/dashboard/teacher/assignments/reports',
  assignmentRootReportView:
    '/dashboard/teacher/assignments/reports/view/:assignmentId',
  membershipsClass: '/dashboard/teacher/memberships/:classCode?',
  membershipsAll: '/dashboard/teacher/memberships/all',
  referForMembership: '/dashboard/teacher/refer-for-membership',
  classPlanner: '/dashboard/teacher/assignment-tool/:classCode',
  classAssignmentReport:
    '/dashboard/teacher/assignment-tool/report/:assignmentId/:classCode',
  leaderboardReport: '/dashboard/teacher/reports/leaderboard/:classCode',
  newLEleaderboardReport: '/dashboard/teacher/reports/LEleaderboard/:classCode',
  questionsAnsweredReport: '/dashboard/teacher/reports/questions/:classCode',
  realTimeDashboard: '/dashboard/teacher/reports/real-time/:classCode',
  myAccount: '/dashboard/teacher/my-account',
  classActivityReport: '/dashboard/teacher/reports/class-activity/:classCode',
  classMissionReport: '/dashboard/teacher/reports/class-mission/:classCode',
  userGeneratedAssignments: '/dashboard/teacher/assignments/feed',
  userGeneratedAssignmentsEdit: '/dashboard/teacher/assignments/edit/:id',
  userGeneratedAssignmentsEditNew: '/dashboard/teacher/assignments/new',
  resourceHub: '/dashboard/teacher/resource-hub',
  resourceHubInfo: '/dashboard/teacher/resource-hub/:resourceId',
  resourceHubClass: '/dashboard/teacher/resource-hub/class/:classCode',
  resourceHubInfoClass:
    '/dashboard/teacher/resource-hub/:resourceId/class/:classCode',
  teacherPremiumPlan: '/dashboard/teacher/premium',
  teacherPremiumPlanClass: '/dashboard/teacher/premium/:classCode',
  membershipPurchasedSuccess: '/dashboard/teacher/membership/purchased',
  classMissions: '/dashboard/teacher/class-missions/:classCode',
  classMissionsGame: '/dashboard/teacher/class-missions/:classCode/:missionId',
  membershipCheckout: '/dashboard/teacher/premium/membership/cart',
  newLEClassActivityReport:
    '/dashboard/teacher/reports/newLEClassActivity/:classCode'
};

export const parentRoutes = {
  homeScreen: '/dashboard/parent',
  reportCard: '/dashboard/parent/report-card',
  rewardsPage: '/dashboard/parent/rewards/:classCode',
  membershipsAll: '/dashboard/parent/memberships/all',
  reports: '/dashboard/parent/reports',
  readingLevelReport: '/dashboard/parent/reports/reading-level',
  skillComprehensionReport: '/dashboard/parent/reports/skill-comprehension',
  myAccount: '/dashboard/parent/my-account',
  planner: '/dashboard/parent/assignment-tool/:classCode',
  plannerReport: '/dashboard/parent/assignment-tool/report/:assignmentId/:classCode',
  updateLocation: '/dashboard/parent/update-location',
  questionsReport: '/dashboard/parent/reports/questions',
  classReportsAssignmentTool:
    '/dashboard/parent/reports/assignment-tool/:classCode',
  resourceHub: '/dashboard/parent/resource-hub',
  resourceHubInfo: '/dashboard/parent/resource-hub/:resourceId'
};

export const schoolAdminRoutes = {
  homeScreen: '/dashboard/school-admin',
  createAccounts: '/dashboard/school-admin/create-accounts',
  createContent: '/dashboard/school-admin/create-content',
  training: '/dashboard/school-admin/training',
  membershipsAll: '/dashboard/school-admin/memberships/all',
  myAccount: '/dashboard/school-admin/my-account',
  teacherClassList: '/dashboard/school-admin/teacher/:teacherId',
  teacherClass: '/dashboard/school-admin/class/:classCode',
  teacherStudents: '/dashboard/school-admin/students/:classCode',
  teacherPlanner: '/dashboard/school-admin/assignment-tool/:classCode',
  teacherReports: '/dashboard/school-admin/reports',
  teacherReadingLevelReport:
    '/dashboard/school-admin/reports/reading-level/:classCode',
  teacherPlacementTestReport:
    '/dashboard/school-admin/reports/placement-test/:classCode',
  teacherPlannerReport:
    '/dashboard/school-admin/reports/assignment-tool/:classCode',
  teacherSkillComprehensionReport:
    '/dashboard/school-admin/reports/skill-comprehension/:classCode',
  teacherAssignmentReport:
    '/dashboard/school-admin/assignment-tool/report/:assignmentId/:classCode',
  teacherRealTimeDashboard:
    '/dashboard/school-admin/reports/real-time/:classCode',
  leaderboardReport: '/dashboard/school-admin/reports/leaderboard/:classCode',
  newLEleaderboardReport:
    '/dashboard/school-admin/reports/LEleaderboard/:classCode',
  classActivityReport:
    '/dashboard/school-admin/reports/class-activity/:classCode',
  resourceHub: '/dashboard/school-admin/resource-hub',
  resourceHubInfo: '/dashboard/school-admin/resource-hub/:resourceId',
  newLEClassActivityReport:
    '/dashboard/school-admin/reports/newLEClassActivity/:classCode'
};

export const districtAdminRoutes = {
  homeScreen: '/dashboard/district-admin',
  myAccount: '/dashboard/district-admin/my-account',
  createAccounts: '/dashboard/district-admin/create-accounts',
  createContent: '/dashboard/district-admin/create-content',
  training: '/dashboard/district-admin/training',
  teachersList: '/dashboard/district-admin/school/:schoolId',
  teacherClassList: '/dashboard/district-admin/teacher/:teacherId',
  teacherClass: '/dashboard/district-admin/class/:classCode',
  teacherStudents: '/dashboard/district-admin/students/:classCode',
  teacherPlanner: '/dashboard/district-admin/assignment-tool/:classCode',
  teacherReports: '/dashboard/district-admin/reports',
  teacherReadingLevelReport:
    '/dashboard/district-admin/reports/reading-level/:classCode',
  teacherPlacementTestReport:
    '/dashboard/district-admin/reports/placement-test/:classCode',
  teacherPlannerReport:
    '/dashboard/district-admin/reports/assignment-tool/:classCode',
  teacherSkillComprehensionReport:
    '/dashboard/district-admin/reports/skill-comprehension/:classCode',
  teacherAssignmentReport:
    '/dashboard/district-admin/assignment-tool/report/:assignmentId/:classCode',
  teacherRealTimeDashboard:
    '/dashboard/district-admin/reports/real-time/:classCode',
  leaderboardReport: '/dashboard/district-admin/reports/leaderboard/:classCode',
  newLEleaderboardReport:
    '/dashboard/district-admin/reports/LEleaderboard/:classCode',
  classActivityReport:
    '/dashboard/district-admin/reports/class-activity/:classCode',
  resourceHub: '/dashboard/district-admin/resource-hub',
  resourceHubInfo: '/dashboard/district-admin/resource-hub/:resourceId',
  newLEClassActivityReport:
    '/dashboard/district-admin/reports/newLEClassActivity/:classCode'
};

export const commonRoutes = {
  billingPage: '/dashboard/billing',
  selectLocation: '/dashboard/select-location',
  getStarted: '/dashboard/get-started',
  adminVerification: '/dashboard/admin-verification',
  adminApproved: '/admin-approved',
  adminDenied: '/admin-denied',
  clever: '/clever',
  googleClassroom: '/gclassroom',
  testPreparationDescriptions: '/test-prep-descriptions',
  contentPackDescriptions: '/content-packs-descriptions',
  createContentRedirect: '/create-content',
  updateReferral: '/update-referral',
  termsAndConditions: '/terms-conditions',
  upgradeToPremium: '/dashboard/upgrade-premium',
  premiumFreeTrial: '/dashboard/free-trial',
  membershipCart: '/dashboard/upgrade-premium/cart'
};

export const loginRoutes = {
  login: '/login/:userType?',
  loginSelection: '/login-selection',
  logout: '/dashboard/logout',
  register: '/register',
  registerParent: '/register/parent',
  registerEducator: '/register/educator',
  registerSchool: '/register/school',
  forgotPassword: '/forgot-password/:token?',
  authorize: '/authorize'
};

export const subscriptionRoutes = {
  cancellationEmail: '/subscription/cancel/email',
  cancellation: '/cancel/:token'
};

export const checkoutRoutes = {
  checkout: '/checkout/memberships',
  success: '/checkout/memberships/success',
  membershipPurchase: '/dashboard/parent/membership/cart'
};
