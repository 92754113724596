import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';
import { push } from 'connected-react-router';
import { ChevronLeft } from '@material-ui/icons';
import View from '../view/View';
import Button from '../button/Button';
import moment, { Moment } from 'moment';
import { MODAL_SELECT_DATE_RANGE, MODAL_DEFAULT } from '../../constants/modals';
import {
  fetchReportToolData,
  Statuses,
  ReportTools
} from '../../redux/features/ReportTool';
import colours from '../../styles/colors';
import { userTypesDashboardRoute } from '../../constants/users';
import { Help } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';

/**
 *
 * ReportToolTemplate --> isWidget ? Render Widget[Section] : Render Report[Section]
 *
 * Head:
 *  WidgetHead -- Includes Button to View Report
 *  ReportHeader -- Includes Filter
 *
 * Body:
 *  WidgetBody
 *  ReportBody
 *
 * Footer:
 *  Null
 */

const dateFormat = 'YYYY-MM-DD';

export const timeFrames = {
  last7: 'Last 7 Days',
  week: 'This Week',
  month: 'This Month',
  year: 'This Year',
  custom: 'Select Date Range'
};

const ReportToolTemplateStyle = {
  padding: 12
};

const WidgetHeaderLinkStyle = {
  color: colours.primary,
  fontSize: 13,
  fontWeight: 600
};

interface WidgetHeaderProps {
  title: string;
  subTitle: string;
  buttonLink?: string;
  buttonText?: string;
  tracker: () => void;
  helperText?: string;
  reportURL?: string;
}

interface ReportHeaderProps {
  title: string;
  reportToolState: Array<ReportTools>;
}

interface ReportToolTemplateProps {
  title: string;
  subTitle: string;
  buttonText?: string;
  buttonLink?: string;
  tracker: () => void;
  body: any;
  isWidget?: boolean;
  source: any;
  helperText?: string;
  reportToolState: Array<ReportTools>;
  reportURL?: string;
  alwaysRequestData?: boolean;
}

interface LoadingReportToolSpinnerProps {
  message: string;
}

interface BodyContainerProps {
  title: string;
  body: any;
  isWidget?: boolean;
  source: any;
  isReady: boolean;
  reportToolState: Array<ReportTools>;
  alwaysRequestData?: boolean;
}

interface NoDataScreenProps {
  source: any;
  title: string;
  error: any;
}

export const getTableOptions = (isWidget?: boolean) => {
  if (isWidget) {
    return {
      hidePagination: true,
      rowsPerPageOptions: undefined,
      overflowX: 'none' as const,
      overflowY: 'none' as const
    };
  }

  return {
    overflowX: 'scroll' as const,
    overflowY: 'scroll' as const
  };
};

/**
 *
 * @param startDate: Defines the start date of the data. Can be undefined if selectedRange is defined.
 * @param endDate: Defines the end date of the data. Can be undefined if selectedRange is defined.
 * @param selectedRange: Defines the time period of the data. Can be undefined if startDate and endDate are defined.
 *
 */
const updateReportToolData = ({
  type,
  startDate,
  endDate,
  selectedRange = timeFrames.last7,
  isWidget = false
}: {
  type: string;
  startDate?: Moment;
  endDate?: Moment;
  selectedRange?: string;
  isWidget?: boolean;
}) => {
  return (dispatch: any) => {
    dispatch(
      fetchReportToolData({
        type,
        startDate,
        endDate,
        selectedRange,
        isWidget
      })
    );
  };
};

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  buttonText,
  subTitle,
  helperText,
  buttonLink,
  tracker,
  reportURL
}: WidgetHeaderProps) => {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.login.token);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const userType = useSelector((state: any) => state.userData.userType);
  const isPremium = useSelector(
    (state: any) => state.userData.premium.isPremium
  );
  const features = useAppSelector(state => state.featureFlag);
  const viewReport = () => {
    if (!reportURL) {
      reportURL = title.toLowerCase().replace(' ', '-');
    }
    return (dispatch: any) => {
      if (title === 'Skill Achievements' && features.newDashboardFlag) {
        window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${classCode}/reports/skills-report?accessToken=${token}`;
      } else {
        const path = `${userTypesDashboardRoute[userType]}/reports/${reportURL}/${classCode}`;
        tracker && dispatch(tracker());
        if (buttonLink) {
          return dispatch(push(buttonLink));
        }
        dispatch(push(path));
      }
    };
  };

  return (
    <>
      <Grid container item xs={6}>
        <Grid item xs={12}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ display: 'inline-block' }}>
            {subTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        justify="flex-end"
        alignItems={subTitle || helperText ? 'center' : 'stretch'}
      >
        <Grid item>
          <Link
            component="button"
            style={WidgetHeaderLinkStyle}
            onClick={() => {
              dispatch(viewReport());
            }}
          >
            {buttonText || 'Full Report'}
          </Link>
        </Grid>
        {helperText && (
          <Grid item>
            <IconButton
              aria-label="Helper-Button"
              onClick={() => {
                dispatch(
                  show('modal', {
                    type: MODAL_DEFAULT,
                    children: <Typography>{helperText}</Typography>
                  })
                );
              }}
            >
              <Help />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const ReportHeader: React.FC<ReportHeaderProps> = ({
  title,
  reportToolState
}: ReportHeaderProps) => {
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const userType = useSelector((state: any) => state.userData.userType);
  const [prevSelection, setPrevSelection] = useState('Last 7 Days');
  const { selectedRange } = useSelector(
    (state: any) => state.reportTool[title]
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSelectTimeFrame = (event: any) => {
    var { value, textContent } = event.target;

    if (textContent) {
      value = prevSelection;
    }

    if (value === timeFrames.custom) {
      setPrevSelection(value);
      dispatch(
        show('modal', {
          type: MODAL_SELECT_DATE_RANGE,
          onConfirm: (from: Date, to: Date) => {
            reportToolState.forEach((state: any) => {
              dispatch(
                updateReportToolData({
                  type: state,
                  startDate: moment(from),
                  endDate: moment(to),
                  selectedRange: timeFrames.custom
                })
              );
            });
          }
        })
      );
    } else if (value != undefined) {
      setPrevSelection(value);
      reportToolState.forEach((state: any) => {
        dispatch(updateReportToolData({ type: state, selectedRange: value }));
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Button
          startIcon={<ChevronLeft />}
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
      </Grid>
      <Grid item>
        <Typography variant="h3">{`${title} Report`}</Typography>
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Select Date Range</InputLabel>
          <Select value={selectedRange} onClick={handleSelectTimeFrame}>
            {Object.values(timeFrames).map((frame: string) => {
              return (
                <MenuItem value={frame} key={frame}>
                  {frame}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

const NoDataScreen: React.FC<NoDataScreenProps> = ({
  source,
  title,
  error
}: NoDataScreenProps) => {
  let message = '';
  // Changed to handle an array of errors
  if (Array.isArray(error) && error.length > 0) {
    let { data } = error[0];
    message = data;
  }

  return (
    <>
      <Grid item>
        <img src={source} alt={title} height="120px" />
      </Grid>
      <Grid item>
        <Typography align="center" style={{ fontSize: 14 }}>
          {message}
        </Typography>
      </Grid>
    </>
  );
};

const LoadingReportToolSpinner: React.FC<LoadingReportToolSpinnerProps> = ({
  message
}: LoadingReportToolSpinnerProps) => {
  return (
    <>
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item>
        <Typography>{message}</Typography>
      </Grid>
    </>
  );
};

const BodyContainer: React.FC<BodyContainerProps> = ({
  title,
  body,
  isWidget,
  source,
  isReady,
  reportToolState,
  alwaysRequestData
}: BodyContainerProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let shouldRequestData = isReady;
    if (alwaysRequestData && !isWidget) {
      shouldRequestData = true;
    }
    if (shouldRequestData) {
      reportToolState.forEach((state: any) =>
        dispatch(updateReportToolData({ isWidget, type: state }))
      );
    }
  }, [isReady]);

  const reportTool: any = useSelector((state: any) => state.reportTool);
  const currentReportTool = reportToolState.map(state => reportTool[state]);
  const status = currentReportTool.map(report => report.status);
  const successfulRequests = status.filter(item => item === Statuses.Success);
  const errors = currentReportTool.filter(
    item => item.status === Statuses.Error
  );

  let bodyView: any;
  if (successfulRequests.length === status.length) {
    bodyView = body;
  } else if (status.includes(Statuses.Pending)) {
    bodyView = <LoadingReportToolSpinner message="Loading data..." />;
  } else {
    bodyView = <NoDataScreen source={source} title={title} error={errors} />;
  }

  const bodyComponent = (
    <Grid
      container
      item={isWidget}
      alignItems="center"
      direction="column"
      style={{
        height: isWidget ? 300 : 'auto',
        justifyContent: 'space-evenly',
        marginTop: isWidget ? 0 : 20
      }}
    >
      {bodyView}
    </Grid>
  );

  return bodyComponent;
};

const ReportToolTemplate: React.FC<ReportToolTemplateProps> = ({
  title,
  body,
  isWidget,
  subTitle,
  tracker,
  source,
  buttonLink,
  buttonText,
  helperText,
  reportToolState,
  reportURL
}: ReportToolTemplateProps) => {
  const { currentClass } = useSelector((state: any) => state.class);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    setReady(currentClass.isLoading === false);
  }, [currentClass.isLoading]);

  const header = (
    <>
      {isWidget ? (
        <WidgetHeader
          helperText={helperText}
          title={title}
          tracker={tracker}
          subTitle={subTitle}
          buttonLink={buttonLink}
          buttonText={buttonText}
          reportURL={reportURL}
        />
      ) : (
        <ReportHeader title={title} reportToolState={reportToolState} />
      )}
    </>
  );

  const children = (
    <Grid container direction="column">
      <Grid container item justify="space-between">
        {header}
      </Grid>
      <Grid container={isWidget} item xs={12}>
        <BodyContainer
          source={source}
          title={title}
          body={body}
          isWidget={isWidget}
          isReady={isReady}
          alwaysRequestData
          reportToolState={reportToolState}
        />
      </Grid>
    </Grid>
  );

  if (isWidget) {
    return <Paper style={ReportToolTemplateStyle} children={children} />;
  } else {
    return <View>{children}</View>;
  }
};

export default ReportToolTemplate;
