import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import Button from '../../button/Button';
import ModalAbstract from './ModalAbstract';
import PasteCSV from '../../paste-csv/PasteCSV';
import { CSVValidation } from '../../../types/paste-csv/paste-csv-type';

interface ModalPasteCSVProps {
  show?: any;
  handleHide?: any;
  onSubmit: (rows: any) => void;
  validation: Array<CSVValidation>;
  formatPlaceholder: Array<string>;
}

const ModalPasteCSV: React.FC<any> = (props: ModalPasteCSVProps) => {
  const { show, handleHide, validation, formatPlaceholder } = props;
  const [errors, setErrors] = useState([]);
  const [rows, setRows] = useState([]);

  const hasErrors = (): Boolean => {
    return (
      errors.reduce((acc, value: Array<any>) => {
        return value.length;
      }, 0) > 0
    );
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Copy and Paste from Spreadsheet</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <PasteCSV
            columnCount={3}
            validation={validation}
            formatPlaceholder={formatPlaceholder}
            textAreaPlaceholder={formatPlaceholder.join(', ')}
            getRows={(rows: any) => setRows(rows)}
            getErrors={(errors: any) => setErrors(errors)}
          />
          {errors.map((item: any) => {
            return item.map((item: any) => <Typography>{item}</Typography>);
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button
          primary
          disabled={hasErrors() === true}
          onClick={() => {
            handleHide();
            props.onSubmit(rows.map((row: string) => row.split(',')));
          }}
        >
          Done
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalPasteCSV;
