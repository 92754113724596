import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalAbstract from './ModalAbstract';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

/**
 *
 * @param props
 * @var children should be a function that returns JSX
 * @var title is the title you want to render
 * @var dividers is a boolean value if you want dividers between the title and actions
 * @var actions should be a function that returns JSX
 * @var leftActions should be a function that returns JSX
 * @constructor
 */
const ModalRemovePlayer: React.FC<any> = (props: any) => {
  const {
    show,
    handleHide,
    children,
    actions,
    leftActions,
    handleTitleClose,
    title,
    dividers,
    closeText,
    closeButton,
    hideActions,
    fullWidth,
    showTitleClose,
    maxWidth,
    alignActions,
    hideCloseAction
  } = props;
  const content = typeof children === 'function' ? children() : children;
  return (
    <div>
      <ModalAbstract
        show={show}
        handleHide={handleHide}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="form-dialog-title">
          {showTitleClose && (
            <IconButton
              aria-label="close"
              style={{
                color: 'grey',
                position: 'absolute',
                right: 10,
                top: 10
              }}
              onClick={() => {
                if (handleTitleClose) {
                  return handleTitleClose();
                }
                handleHide();
              }}
            >
              <Close />
            </IconButton>
          )}

          {title}
        </DialogTitle>
        <DialogContent
          dividers={dividers || false}
          style={{
            padding: '20px'
          }}
        >
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        {!hideActions && (
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: alignActions || 'space-between'
            }}
          >
            {leftActions && leftActions(handleHide)}
            {!hideCloseAction && (
              <Grid container justify="flex-end">
                {(closeButton && closeButton(handleHide)) || (
                  <Button onClick={handleHide} color="primary">
                    {closeText ? closeText : 'No'}
                  </Button>
                )}
                {actions && actions(handleHide)}
              </Grid>
            )}
          </DialogActions>
        )}
      </ModalAbstract>
    </div>
  );
};

export default ModalRemovePlayer;
