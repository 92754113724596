export type GoogleAuthenticatePayload = {
  accessCode: string;
  userType: string;
};

export type GoogleRegisterPayload = {
  userType: string;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  newRegistrationFlow?: boolean;
};

export enum SSOState {
  PLAYER_APP = 'dreamscape-auth-player-app',
  PLAYER_WEB = 'dreamscape-auth-player-webgl',
  USER = 'dreamscape-auth-user-web'
}
