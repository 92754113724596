import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Clear, Done, Remove, Warning } from '@material-ui/icons';
import clsx from 'clsx';
import colors from '../../styles/colors';
import { useSelector, useDispatch } from 'react-redux';
import { userTypes } from '../../constants/users';
import { show } from 'redux-modal';
import { MODAL_PARENT_MEMBERSHIP } from '../../constants/modals';

const useStyles = makeStyles(() => ({
  tableRow: {
    display: 'flex'
  },
  tableCol: {
    alignItems: 'center',
    border: '1px solid #fff',
    display: 'flex',
    height: 45,
    justifyContent: 'center',
    position: 'relative',
    width: 45,
    '&:first-of-type': {
      justifyContent: 'left',
      width: '140px'
    }
  },
  tableColQuestion: {
    cursor: 'pointer',
    transition: '.3s',
    '&:hover': {
      backgroundColor: colors.lightBackground
    },
    '&:hover > div': {
      opacity: 1,
      visibility: 'visible'
    }
  },
  questionPopup: {
    background: '#fff',
    border: '1px solid #dcdcdc',
    boxShadow: '0 0 8px -4px',
    fontSize: '.7rem',
    left: '-7.5rem',
    opacity: 0,
    padding: 15,
    position: 'absolute',
    textAlign: 'left',
    top: 55,
    transition: '.3s',
    visibility: 'hidden',
    width: 280,
    zIndex: 9,
    '&:before': {
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #fff',
      content: "''",
      height: 0,
      left: 0,
      margin: '0 auto',
      position: 'absolute',
      top: -8,
      right: 0,
      width: 0
    }
  },
  icon: {
    color: colors.white
  },
  tableWrapper: {
    display: 'table',
    margin: 10,
    overflowX: 'scroll',
    padding: '25px 25px 60px'
  },
  questionText: {
    '& img': {
      maxWidth: '100%'
    }
  },
  blur: {
    cursor: 'pointer',
    filter: 'blur(5px)'
  },
  warningIcon: {
    color: colors.secondary,
    cursor: 'pointer',
    fontSize: 20,
    marginLeft: 10
  },
  studentName: {
    alignItems: 'center',
    display: 'flex'
  }
}));

const PlayerProgressChart: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userType } = useSelector((state: any) => state.userData);

  const getQuestionProps = (
    { answer, isMember }:
    { answer: Array<any>, isMember: boolean }
  ) => {
    let background = colors.error; // incorrect
    let icon = <Clear className={classes.icon} />;
    let className = '';
    if (userType === userTypes.parent && !isMember) {
      return {
        background: colors.neutral,
        className: classes.blur,
        icon: <Remove className={classes.icon} />
      }
    }

    if (!answer.length) {
      background = colors.neutral; // not answered
      icon = <Remove className={classes.icon} />;
    } else if (answer[0].isCorrect) {
      background = colors.success; // correct
      icon = <Done className={classes.icon} />;
    }
    return {
      background,
      className,
      icon
    }
  }

  const handleAnswerClick = (user: any) => {
    if (!user.isMember) {
      handleShowMembershipPopup();
    }
  }

  const handleShowMembershipPopup = () => {
    if (props.isParent) {
      dispatch(show('modal', {
        type: MODAL_PARENT_MEMBERSHIP,
        origin: "Assignment Report"
      }));
    }
  }

  const renderWarningIcon = (user: any) => {
    if (userType === userTypes.parent && !user.isMember) {
      return (
        <Tooltip
          title="This player is not a member. Click here to learn more"
          onClick={handleShowMembershipPopup}
        >
          <Warning className={classes.warningIcon} />
        </Tooltip>
      );
    }
    return null;
  }

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.tableRow}>
        <div className={classes.tableCol} />
        {props.data.questions.map((question: any, index: number) => (
          <div className={clsx(classes.tableCol, classes.tableColQuestion)}>
            <div className={classes.questionPopup}>
              <p
                style={{ marginBottom: 15 }}
                className={classes.questionText}
                dangerouslySetInnerHTML={{ __html: question.question }}
              ></p>
              {JSON.parse(question.potentialAnswers)?.length ? (
                <>
                  <ul style={{ listStyle: 'none' }}>
                    {JSON.parse(question.potentialAnswers).map(
                      (answer: any) => (
                        <li>{answer}</li>
                      )
                    )}
                  </ul>
                  <p style={{ marginTop: 15 }}>
                    <strong>Correct Answer: </strong>
                    {JSON.parse(question.correctAnswers).join(', ')}
                  </p>
                </>
              ) : null}
            </div>
            {index + 1}
          </div>
        ))}
      </div>
      {props.data.users
        .filter((user: any) => user !== null)
        .map((user: any) => {
          return (
            <div className={classes.tableRow}>
              <div className={classes.tableCol}>
                <span className={classes.studentName}>
                  {user.firstname} {user.lastname}
                  {renderWarningIcon(user)}
                </span>
              </div>
              {props.data.questions.map((question: any) => {
                const answer = question.answers.filter((answer: any) => {
                  return answer.userId === user.educationUserId;
                });
                const { background, icon, className } = getQuestionProps({
                  answer,
                  isMember: user.isMember
                });
                return (
                  <div
                    onClick={() => handleAnswerClick(user)}
                    className={clsx(classes.tableCol, className)}
                    style={{ background }}
                  >
                    {icon}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default PlayerProgressChart;
