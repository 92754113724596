const colors = {
  primary: '#40d2d9',
  primaryLight: '#9977ec',
  primaryHover: '#0BBBC4',
  primaryDark: '#6d50b7',

  secondary: '#da6398',
  third: '#63da8b',
  fourth: '#da8f63',
  fifth: '#efcc00',

  border: '#dcdcdc',
  lightBackground: '#f2f2f2',

  primaryText: '#1f1f1f',
  secondaryText: '#676767',

  white: '#fff',
  green: '#4caf50',
  greenHover: '#2e7d32',
  blue: '#00b0ff',
  blueHover: '#0288d1',
  blueDark: '#0471ad',
  orange: '#ffa000',
  orangeHover: '#ff6f00',
  red: '#f44336',
  redHover: '#d02a1e',
  black: '#000000',

  error: '#e74c3c',
  neutral: '#bdc3c7',
  success: '#2ecc71',
  successDark: '#218625',
  warning: '#fff3b5',
  warningDark: '#6b5a03',

  spinnerBackground: 'rgba(0, 0, 0, 0.7)',

  disabled: '#d6d6d6',
  assignment: {
    blue: '#107BF8',
    turquoise: '#4BD1D8',
    text: '#656565',
    lightgrey: '#737373',
    background: '#E5E5E5',
    grey: '#DDDDDD'
  },
  premium: {
    purple: 'rgb(156, 0, 156)',
    blue: '#007FB2'
  }
};

const components = {
  appbar: '#fff',
  appbarAlt: '#f8f8f8',
  table: {
    header: colors.primary
  }
};

export default {
  ...colors,
  ...components
};
