import React from 'react';
import { ErrorCrash } from './views/error/ErrorCrash';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      hasError: true
    });
  }

  render() {
    /*
     * If an unexpected error occurs in the application the default
     * fallback view will be rendered.
     * Additional views can be rendered based on error condition
     */
    if (this.state.hasError) {
      return <ErrorCrash />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
