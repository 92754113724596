import React, { useEffect } from 'react';
import View from '../../../../components/view/View';
import { useAppSelector } from '../../../../redux/hooks';
import { useDispatch } from 'react-redux';
import { PremiumPlansNew } from './PremiumPlansNew';
import { Premium } from './Premium';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../../types/fetch/fetch-types';
import { AxiosResponse } from 'axios';
import { Plan } from './Plan';
import { hide, show } from 'redux-modal';
import { trackMembershipPlanPageViewed } from '../../../../redux/actions/mixpanel-actions';
import { Grid, Paper, Typography } from '@material-ui/core';
import { trackBuyClickPremiumPlan } from '../../../../utils/google/google-analytics';
import { premiumPlusBreakDownData } from './premium-plus-breakdown-data';
import {
  getUser,
  updateUserPremiumData
} from '../../../../redux/actions/user-actions';
import { refreshToken } from '../../../../redux/login/login-actions';
import {
  MODAL_DEFAULT,
  MODAL_PREMIUM_PLUS_LEGACY_INFO
} from '../../../../constants/modals';
import Button from '../../../../components/button/Button';
import { hide as hideModal, show as showModal } from 'redux-modal';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from 'moment';
import { getAllMembers } from '../../../../redux/actions/members-actions';

const PremiumPlanPage = () => {
  const userData = useAppSelector(state => state.userData);
  const userClasses = useAppSelector(state => state.class);
  const { premium } = userData;
  const isPremiumUser = premium && premium.isPremium;
  const dispatch = useDispatch();
  const expiryDate = moment(premium.expiryDate).format('MMMM DD, YYYY');
  const { token } = useAppSelector(state => state.login);

  const activeStudents = userClasses.classes
    .filter((userClass: any) => !userClass.archived)
    .reduce((previousValue: any, currentValue: { roster: string | any[] }) => {
      return previousValue + currentValue.roster.length;
    }, 0);

  const [premiumPlusSelected, setPremiumPlusSelected] = React.useState({
    id: 1,
    memberships: 10,
    totalPlanCost: 80,
    premium: 19,
    totalCost: 99,
    savings: 0
  });

  useEffect(() => {
    dispatch(trackMembershipPlanPageViewed());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUser(token, false));
    dispatch(getAllMembers());
  }, [dispatch]);

  //Commented as upgrade is paused.

  // const redirectToPurchase = (membershipOptions: any) => {
  //   const { membershipType, totalPlanCost, memberships } = membershipOptions;
  //   const trackingName =
  //     membershipType === Plan.PremiumPlus
  //       ? 'Premium Plus Plan'
  //       : 'Premium Plan';
  //   trackBuyClickPremiumPlan(trackingName, totalPlanCost);
  //   dispatch(show('spinner'));
  //   dispatch(
  //     fetchApi({
  //       url: 'stripe/checkout',
  //       method: APIMethods.POST,
  //       data: {
  //         membershipType,
  //         memberships
  //       }
  //     })
  //   )
  //     .then((response: AxiosResponse) => {
  //       const { url } = response.data;
  //       window.location.href = url;
  //       dispatch(hide('spinner'));
  //     })
  //     .catch((err: any) => {
  //       console.log(err);
  //       console.log('error redirecting');
  //       dispatch(hide('spinner'));
  //     });
  // };

  // /**
  //  * @description handles upgrading user subscription
  //  * @param upgradeOptions
  //  */
  // const handleUpgrade = async (upgradeOptions: {
  //   memberships: number;
  //   membershipType: string;
  // }) => {
  //   const { membershipType, memberships } = upgradeOptions;

  //   await previewUpgrade(membershipType, memberships);
  // };

  const premiumPlusAnnualPrice = () => {
    if (premium.totalMemberships == 10) {
      return '99';
    } else if (premium.totalMemberships == 30) {
      return '149';
    } else if (premium.totalMemberships == 100) {
      return '299';
    } else if (premium.totalMemberships == 250) {
      return '499';
    }
  };
  const handlePremiumpPlus = () => {
    if (premium.isPremium == true) {
      if (premium.expiryDate < moment()) {
        return null;
      } else if (premium.type == 'Premium' || premium.type == 'Premium29') {
        return null;
      } else {
        return (
          <div>
            <Paper style={{ marginBottom: '40px', padding: '0 4px' }}>
              <ErrorOutlineIcon
                style={{
                  position: 'relative',
                  top: '6px',
                  color: '#9C009C'
                }}
              ></ErrorOutlineIcon>
              &nbsp; You currently have a legacy plan at a locked-in price. For
              more information about your plan benefits and changing plans,
              <Button
                white
                fontSize="0.9rem"
                style={{
                  paddingLeft: '2px',
                  position: 'relative',
                  bottom: '1.5px',
                  color: '#9C009C'
                }}
                onClick={() => handlePopUp()}
              >
                click here.
              </Button>
            </Paper>
          </div>
        );
      }
    }
  };
  const handlePopUp = () => {
    const sendProps: any = {
      type: MODAL_PREMIUM_PLUS_LEGACY_INFO,
      currentPlan: 'Premium+ ' + premium.totalMemberships + ' (legacy)',
      playerMemberships: premium.totalMemberships,
      annualPrice: premiumPlusAnnualPrice(),
      renewsOn: expiryDate
    };
    dispatch(showModal('modal', sendProps));
  };

  //Commented as upgrade is paused.

  // const showPreviewModal = (
  //   charges: { proratedCharge: number; charge: number; total: number },
  //   membershipType: string,
  //   memberships: number
  // ) => {
  //   const { total } = charges;
  //   dispatch(
  //     show('modal', {
  //       variant: MODAL_DEFAULT,
  //       title: 'You are about to upgrade to the Premium + plan.',
  //       children: (
  //         <Grid container>
  //           <Grid item xs={12}>
  //             <Typography>
  //               This will update your current expiration date to reflect your
  //               new plan's expiration date.
  //             </Typography>
  //             <br />
  //             <Typography>
  //               Because this is an upgrade you will be charged{' '}
  //               <strong>${total.toFixed(2)}</strong>
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       ),
  //       closeText: 'Cancel',
  //       actions: () => (
  //         <>
  //           <Button
  //             primary
  //             onClick={() => upgradeUser(membershipType, memberships)}
  //           >
  //             Confirm Purchase
  //           </Button>
  //         </>
  //       )
  //     })
  //   );
  // };

  // const previewUpgrade = (membershipType: string, memberships: number) => {
  //   dispatch(show('spinner', { text: 'Calculating upgrade...' }));
  //   dispatch(
  //     fetchApi({
  //       url: 'stripe/upgrade/preview',
  //       method: APIMethods.POST,
  //       data: {
  //         membershipType,
  //         memberships
  //       }
  //     })
  //   )
  //     .then((response: AxiosResponse) => {
  //       const { charges } = response.data;
  //       dispatch(hide('spinner'));
  //       const { proratedCharge, charge, total } = charges;
  //       showPreviewModal(
  //         { proratedCharge, total, charge },
  //         membershipType,
  //         memberships
  //       );
  //     })
  //     .catch((err: any) => {
  //       dispatch(hide('spinner'));
  //     });
  // };

  // const upgradeUser = (membershipType: string, memberships: number) => {
  //   dispatch(hide('modal'));
  //   dispatch(show('spinner'));
  //   dispatch(
  //     fetchApi({
  //       url: 'stripe/upgrade',
  //       method: APIMethods.POST,
  //       data: {
  //         membershipType,
  //         memberships
  //       }
  //     })
  //   )
  //     .then((response: AxiosResponse) => {
  //       dispatch(
  //         show('snackbar', {
  //           variant: 'success',
  //           message: 'Your plan has been upgraded to Premium+',
  //           autoHideDuration: 2700
  //         })
  //       );
  //       const { updatedUserPremiumData } = response.data;
  //       dispatch(refreshToken());
  //       dispatch(updateUserPremiumData(updatedUserPremiumData));
  //       dispatch(hide('spinner'));
  //     })
  //     .catch((err: any) => {
  //       console.log(err);
  //       dispatch(show('snackbar', { variant: 'error', message: err.message }));
  //       dispatch(hide('spinner'));
  //     });
  // };

  // const redirectToCheckout = (
  //   membershipType: string,
  //   breakdownId: number = 0
  // ) => {
  //   const { totalPlanCost, memberships } = premiumPlusBreakDownData[
  //     breakdownId
  //   ];
  //   /**
  //    * Check if a premium user is trying to upgrade and go through upgrade endpoint
  //    */
  //   if (
  //     isPremiumUser &&
  //     membershipType === Plan.PremiumPlus &&
  //     (premium.type === Plan.Premium || premium.type === Plan.Premium29)
  //   ) {
  //     return handleUpgrade({
  //       memberships,
  //       membershipType
  //     });
  //   }
  //   /**
  //    * Cannot purchase the same subscription
  //    */
  //   if (
  //     isPremiumUser &&
  //     membershipType === Plan.Premium &&
  //     (premium.type === Plan.Premium || premium.type === Plan.Premium29)
  //   ) {
  //     return;
  //   }
  //   redirectToPurchase({ totalPlanCost, memberships, membershipType });
  // };

  const mainView: any = React.createRef();
  const planTable: any = React.createRef();

  const currentMembershipType = premium ? premium.type : null;
  const expiry = premium
    ? new Date(premium.expiryDate).toLocaleDateString()
    : null;
  const isUserHavePremiumPlusSubscription =
    currentMembershipType === Plan.PremiumPlus;
  return (
    <View>
      {handlePremiumpPlus()}
      <Paper
        ref={mainView}
        style={{
          paddingBottom: '50px'
          // overflowY: 'scroll'
          // paddingBottom: isUserHavePremiumPlusSubscription ? '2rem' : 'inherit'
        }}
      >
        <Premium
          currentMembershipType={currentMembershipType}
          isPremiumUser={isPremiumUser}
          activeStudents={activeStudents}
          premiumExpiry={premium}
        />
        {/* <PremiumPlansNew
          // mainViewRef={mainView}
          currentMembershipType={currentMembershipType}
          isPremiumUser={isPremiumUser}
          activeStudents={activeStudents}
          premiumExpiry={premium}
        /> */}
      </Paper>
    </View>
  );
};

export default PremiumPlanPage;
