import { APIMethods } from '../../types/fetch/fetch-types';
import { fetchApi } from './fetch-actions';
import { RegistrationData } from '../../types/registration/registration-data-type';

export function registerUser(registrationData: RegistrationData) {
  const { email, userType } = registrationData;
  return fetchApi({
    url: 'user/registeruser',
    method: APIMethods.POST,
    data: {
      schools: [],
      ...registrationData,
      username: email,

      product: 'dreamscape',
      avatar: 'avatar-1',
      dreamscape: {},
      locationUpdated: true,
      unverifiedInfo: { organization: userType }
    },
    spinnerMessage: "We're creating your account"
  });
}
