import React, { useState } from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from '../button/Button';
import colors from '../../styles/colors';

interface ClassCodeCopyProps {
  classCode: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    background: colors.white,
    padding: '6px 10px',
    textAlign: 'center',
    border: `1px solid ${colors.border}`,
    borderRadius: 34
  }
}));

// @ts-ignore
const ClassCodeCopy: React.FC<ClassCodeCopyProps> = (
  props: ClassCodeCopyProps
) => {
  const classes = useStyles();
  const [copyClassTooltip, setCopyClassTooltip] = useState(false);

  return (
    <div className={classes.wrapper} style={props.style}>
      <strong>Class Code:</strong> {props.classCode || 'none'}
      <Tooltip
        open={copyClassTooltip}
        onClose={() => setCopyClassTooltip(false)}
        title="Copied to clipboard"
        leaveDelay={400}
      >
        <CopyToClipboard text={props.classCode}>
          <Button
            style={{
              textAlign: 'right',
              marginLeft: '10px'
            }}
            onClick={() => setCopyClassTooltip(true)}
            primary
            disabled={!props.classCode}
          >
            Copy
          </Button>
        </CopyToClipboard>
      </Tooltip>
    </div>
  );
};

export default ClassCodeCopy;
