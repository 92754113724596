import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  Radio,
  RadioGroup
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { hide as hideModal, show as showModal } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { createAssignment } from '../../../redux/actions/planner-actions';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { userTypes } from '../../../constants/users';
import { getUserGeneratedContent } from '../../../redux/actions/user-generated-content-actions';

const useStyles = makeStyles((theme: Theme) => ({
  rowGrid: {
    marginBottom: 20
  },
  footerInfo: {
    bottom: 18,
    fontSize: 13,
    left: 25,
    position: 'absolute'
  },
  footerInfoMobile: {
    bottom: 0,
    display: 'block',
    left: 0,
    padding: '10px 0 10px 20px',
    position: 'relative',
    textAlign: 'left',
    width: '100%'
  },
  selectAllButton: {
    margin: '10px 0'
  },
  input: {
    margin: '10px 0'
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

enum Steps {
  selectType,
  selectContentPack,
  selectStudents,
  selectDate,
  done
}

enum ContentTypes {
  MINE,
  SCHOOL,
  DISTRICT
}

interface NewAssignment {
  title: string;
  contentType: ContentTypes;
  selectedContentId: number;
}

interface ModalAssignMyContentProps {
  show: any;
  handleHide: any;
}

const ModalAssignMyContent: React.FC<ModalAssignMyContentProps> = (
  props: ModalAssignMyContentProps
) => {
  const classes = useStyles({});
  const { show, handleHide } = props;
  const { currentClass } = useSelector((state: any) => state.class);
  const userData = useSelector((state: any) => state.userData);
  const userGeneratedContent = useSelector(
    (state: any) => state.userGeneratedContent
  );
  const [newAssignment, setNewAssignment] = useState<NewAssignment>({
    title: '',
    selectedContentId: 0,
    contentType: ContentTypes.MINE
  });

  const [state, setState] = useState({
    loading: false,
    typeOptions: [{ id: 0, name: '', selected: false }],
    students: {
      data: currentClass.roster.map((student: any) => {
        student.selected = false;
        return student;
      }),
      allSelected: false,
      studentsLoaded: false
    },
    // DateRangerPicker state
    focusedInput: null,
    startDate: moment().toDate(),
    endDate: moment()
      .add(7, 'days')
      .toDate()
  });
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.selectType);
  const [error, setError] = useState({
    loading: '',
    assignmentData: '',
    contentSelection: '',
    studentSelection: ''
  });
  const dispatch = useDispatch();
  const footerStyle: React.CSSProperties = isMobile
    ? { width: '100%', textAlign: 'right' }
    : {};

  useEffect(() => {
    dispatch(getUserGeneratedContent());
    let contentType = ContentTypes.MINE;
    if (userData.userType === userTypes.districtAdmin) {
      contentType = ContentTypes.DISTRICT;
    } else if (userData.userType === userTypes.schoolAdmin) {
      contentType = ContentTypes.SCHOOL;
    }
    setNewAssignment({
      ...newAssignment,
      contentType
    });
  }, []);

  useEffect(() => {
    const selectedStudents = state.students.data.filter(
      (item: any) => item.selected === true
    );
    const selectedOptions = state.typeOptions.filter(
      item => item.selected === true
    );
    const errorState: any = {};
    if (selectedStudents.length) {
      errorState.studentSelection = '';
    }
    if (
      newAssignment !== null &&
      newAssignment.title.trim() !== '' &&
      moment(state.startDate).isValid() &&
      moment(state.endDate).isValid()
    ) {
      errorState.assignmentData = '';
    }
    if (
      error.contentSelection !== errorState.contentSelection ||
      error.studentSelection !== errorState.studentSelection ||
      error.assignmentData !== errorState.assignmentData
    ) {
      setError({
        ...error,
        ...errorState
      });
    }
  }, [state]);

  const handleCheckStudent = (_id: string, event: any) => {
    const data = state.students.data.map((student: any) => {
      if (student._id === _id) {
        student = {
          ...student,
          selected: event.target.checked
        };
      }
      return student;
    });
    const newState = {
      students: {
        ...state.students,
        data
      }
    };
    const unselected = state.students.data.filter(
      (student: any) => student.selected === false
    );
    if (event.target.checked === false) {
      newState.students.allSelected = false;
    } else if (!unselected.length) {
      newState.students.allSelected = true;
    }
    setState({
      ...state,
      ...newState
    });
  };

  const handleSetContent = () => {
    let contentSelectionError;
    if (!newAssignment.selectedContentId) {
      contentSelectionError = 'Please, select one option to assign';
    }
    if (contentSelectionError) {
      setError({
        ...error,
        contentSelection: contentSelectionError
      });
      return false;
    }
  };

  const handleSetStudents = () => {
    const selectedStudents = state.students.data.filter(
      (item: any) => item.selected === true
    );
    if (!selectedStudents.length) {
      setError({
        ...error,
        studentSelection: 'Please, select at least one player'
      });
      return false;
    }
  };

  const handleSetDate = async () => {
    let assignmentDataError;
    // const { title, type, grade } = newAssignment;
    const { title } = newAssignment;
    const { startDate, students, endDate } = state;
    if (!title.trim()) {
      assignmentDataError = 'Please, give a name to your assignment';
    }
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      assignmentDataError = 'Please, select a valid date range';
    }
    if (moment(endDate) < moment(startDate)) {
      assignmentDataError = 'End Date must be greater or equal Start Date';
    }
    if (assignmentDataError) {
      setError({
        ...error,
        assignmentData: assignmentDataError
      });
      return false;
    }
    const selectedUsers = students.data.filter((item: any) => item.selected);
    const userIds = selectedUsers.map((item: any) => item.educationUserId);

    const assignmentRequestData = {
      title,
      classCode: currentClass.code,
      startDate,
      endDate,
      userIds,
      content: {
        type: 'USER GENERATED CONTENT'
        // grade
      }
    };
    let assignmentData: Array<any> = [
      {
        ...assignmentRequestData,
        content: {
          ...assignmentRequestData.content,
          typeId: newAssignment.selectedContentId
        }
      }
    ];
    dispatch(createAssignment(assignmentData));
  };

  const handleSelectAllClick = () => {
    const { allSelected } = state.students;
    const data = state.students.data.map((student: any) => ({
      ...student,
      selected: !allSelected
    }));
    setState({
      ...state,
      students: {
        ...state.students,
        data,
        allSelected: !allSelected
      }
    });
  };

  const handleCheckOption = (event: any) => {
    const selectedContentId = parseInt(
      (event.target as HTMLInputElement).value
    );
    const content = state.typeOptions.filter(
      (item: any) => item.id === selectedContentId
    );
    const title = `${content[0].name} - Content Pack`;
    setNewAssignment({
      ...newAssignment,
      selectedContentId,
      title
    });
  };

  const handleLoadContent = async () => {
    let typeOptions = userGeneratedContent.teacher;
    if (newAssignment.contentType === ContentTypes.DISTRICT) {
      typeOptions = userGeneratedContent.district;
    } else if (newAssignment.contentType === ContentTypes.SCHOOL) {
      typeOptions = userGeneratedContent.school;
    }
    setState({ ...state, typeOptions });
    if (typeOptions.length) {
      setNewAssignment({
        ...newAssignment,
        title: `${typeOptions[0].name} - Content Pack`,
        selectedContentId: typeOptions[0].id
      });
    }
  };

  const handleSelecType = (event: any) => {
    const { value } = event.target;
    setNewAssignment({
      ...newAssignment,
      contentType: value
    });
  };

  const renderNextButton = (action: any, nextStep: Steps, text?: string) => {
    text = text || 'Next';
    if (state.loading) {
      return null;
    }
    return (
      <Button
        primary
        onClick={async () => {
          const response = await action();
          if (response !== false) {
            if (nextStep === Steps.done) {
              return dispatch(hideModal('modal'));
            }
            setCurrentStep(nextStep);
          }
        }}
      >
        {text}
      </Button>
    );
  };

  const renderBackButton = (previousStep: Steps) => {
    if (state.loading) {
      return null;
    }
    return (
      <Button
        onClick={() => setCurrentStep(previousStep)}
        style={{ marginRight: '5px' }}
        className="button--small"
      >
        Back
      </Button>
    );
  };

  const renderQuestionSelection = () => {
    if (!state.typeOptions.length) {
      let creatorDescription = `You haven't`;
      if (newAssignment.contentType === ContentTypes.DISTRICT) {
        creatorDescription = `Your District hasn't`;
      } else if (newAssignment.contentType === ContentTypes.SCHOOL) {
        creatorDescription = `Your School hasn't`;
      }
      return (
        <Typography>
          {creatorDescription} created any Content Packs yet. Please, select a
          different option.
        </Typography>
      );
    }
    const options = state.typeOptions.map((item: any) => {
      const containerStyle: React.CSSProperties = isMobile
        ? { marginTop: 10, borderBottom: '1px solid #dcdcdc' }
        : {};
      const checkBoxStyle: React.CSSProperties = isMobile
        ? { paddingBottom: 0 }
        : {};
      return (
        <>
          <Grid container spacing={4} style={containerStyle}>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              style={{ ...checkBoxStyle, marginTop: 5 }}
            >
              <RadioGroup
                value={newAssignment.selectedContentId}
                onChange={(event: any) => handleCheckOption(event)}
              >
                <FormControlLabel
                  control={<Radio value={item.id} color="primary" />}
                  label={
                    <>
                      <Typography>{item.name}</Typography>
                      <Typography style={{ fontSize: 13 }}>
                        ({item.numOfQuestions} questions available)
                      </Typography>
                    </>
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </>
      );
    });
    return (
      <>
        <Typography>
          Select the Content Pack you want to assign to your players:
        </Typography>
        {options}
      </>
    );
  };

  const renderStudentSelection = () => {
    const list = state.students.data.map((student: any) => {
      return (
        <>
          <Grid container spacing={1}>
            <Grid container item xs={12} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={student.selected}
                    onChange={(event: any) =>
                      handleCheckStudent(student._id, event)
                    }
                    value={student.selected}
                    color="primary"
                  />
                }
                label={`${student.firstname} ${student.lastname}`}
              />
            </Grid>
          </Grid>
        </>
      );
    });
    return <div>{list}</div>;
  };

  let content = null;
  let buttons = null;
  const { schoolName, districtName } = userData.mixpanel;
  switch (currentStep) {
    case Steps.selectType:
      content = (
        <>
          <Grid xs={12} className={classes.rowGrid}>
            <Typography component="h3">
              Select What Content You'd Like to Assign
            </Typography>
          </Grid>

          <Grid xs={12} className={classes.rowGrid}>
            <InputLabel>Content Type</InputLabel>
            <Select
              fullWidth
              name="type"
              onChange={handleSelecType}
              value={newAssignment.contentType}
            >
              {[userTypes.schoolAdmin, userTypes.teacher].includes(
                userData.userType
              ) && <MenuItem value={ContentTypes.MINE}>My Content</MenuItem>}

              {[userTypes.schoolAdmin, userTypes.teacher].includes(
                userData.userType
              ) && (
                <MenuItem value={ContentTypes.SCHOOL}>
                  {schoolName} Content
                </MenuItem>
              )}

              <MenuItem value={ContentTypes.DISTRICT}>
                {districtName} Content
              </MenuItem>
            </Select>
          </Grid>
        </>
      );
      buttons = (
        <div style={footerStyle}>
          {renderNextButton(handleLoadContent, Steps.selectContentPack)}
        </div>
      );
      break;
    case Steps.selectContentPack:
      content = (
        <div>
          <div className="dashboard__modal-option-wrapper">
            {renderQuestionSelection()}
          </div>
        </div>
      );
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          ></Typography>
          {renderBackButton(Steps.selectType)}
          {state.typeOptions.length
            ? renderNextButton(() => handleSetContent(), Steps.selectStudents)
            : null}
        </div>
      );
      break;
    case Steps.selectStudents:
      const studentsLength = state.students.data.length;
      if (!studentsLength) {
        content = (
          <div>
            <p>
              Your class doesn't have any players yet. Add players before
              creating your assignment.
            </p>
          </div>
        );
      } else {
        content = (
          <div>
            <p>Select the students who will receive this assigment:</p>
            <Button
              onClick={() => handleSelectAllClick()}
              className={classes.selectAllButton}
            >
              {state.students.allSelected ? 'Unselect All' : 'Select All'}
            </Button>
            <div className="dashboard__modal-option-wrapper">
              {renderStudentSelection()}
            </div>
          </div>
        );
        const selectedStudents = state.students.data.filter(
          (item: any) => item.selected === true
        );
        let info = `${selectedStudents.length} players selected`;
        if (error.studentSelection) {
          info = error.studentSelection;
        }
        buttons = (
          <div style={footerStyle}>
            <Typography
              component="span"
              className={clsx(
                classes.footerInfo,
                isMobile && classes.footerInfoMobile
              )}
            >
              {info}
            </Typography>
            {renderBackButton(Steps.selectContentPack)}
            {renderNextButton(handleSetStudents, Steps.selectDate)}
          </div>
        );
      }
      break;
    case Steps.selectDate:
      content = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid spacing={4}>
            <TextField
              fullWidth
              name="name"
              variant="outlined"
              label="Give a name to your assignment"
              value={newAssignment.title}
              className={classes.input}
              onChange={(event: any) =>
                setNewAssignment({
                  ...newAssignment,
                  title: event.target.value
                })
              }
            />
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} spacing={4}>
              <Typography component="p" style={{ marginTop: 10 }}>
                Select the date to start and end the assignment:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} spacing={4}>
              <Typography component="p">Start Date:</Typography>
              <DatePicker
                margin="normal"
                label="Start Date"
                format="MM/dd/yyyy"
                value={state.startDate}
                minDate={new Date()}
                allowKeyboardControl={false}
                onChange={(startDate: any) => setState({ ...state, startDate })}
              />
            </Grid>

            <Grid item xs={12} md={6} spacing={4}>
              <Typography component="p">End Date:</Typography>
              <DatePicker
                margin="normal"
                label="End Date"
                format="MM/dd/yyyy"
                value={state.endDate}
                minDate={state.startDate}
                allowKeyboardControl={false}
                minDateMessage="End Date must be greater or equal Start Date"
                onChange={(endDate: any) => setState({ ...state, endDate })}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      );
      let assignmentInfo = '';
      if (error.assignmentData) {
        assignmentInfo = error.assignmentData;
      }
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          >
            {assignmentInfo}
          </Typography>
          {renderBackButton(Steps.selectStudents)}
          {renderNextButton(handleSetDate, Steps.done, 'Done')}
        </div>
      );
      break;
    default:
      content = null;
  }

  let errorMessage = null;
  if (error.loading) {
    content = null;
    buttons = null;
    errorMessage = <p>{error.loading}</p>;
  }

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Assign My Content
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => dispatch(hideModal('modal'))}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {errorMessage}
        {buttons}
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalAssignMyContent;
