export const cancellationReasons = [
  'My Player is no longer using Dreamscape',
  "I don't see the value of the membership",
  "We've had technical issues that have made it difficult to access the game",
  "I don't want my membership to renew automatically"
];

export const premiumCancellationReasons = [
  "I don't want my membership to renew automatically",
  "I'm no longer in a teaching position/teaching reading comprehension",
  'My players have lost interest in Dreamscape',
  "We've had technical issues that have made it difficult to use the game",
  "I don't see the value of the membership",
  'I will be using these funds for another EdTech tool for reading comprehension'
];
