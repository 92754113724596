import { FEATURE_FLAG_SET } from './feature-flag-types';

const initialState: FeatureFlagState = {
  userGeneratedContent: null,
  googleIdentityService: false,
  test: false,
  googleClassroomV2: false,
  unlimitedRewards: false,
  premiumReporting: false,
  newRealtimeService: false,
  newDashboardFlag: false,
  newLearningEngine: false,
  newLeAssignments: false,
  newRegistrationFlow: false,
  newLeOptIn: false,
  ldFlagsInitialised: false,
  oldDashboardAccessConfig: {}
};

export interface FeatureFlagState {
  userGeneratedContent: null | UserGeneratedContent;
  googleIdentityService: boolean;
  test: boolean;
  googleClassroomV2: boolean;
  unlimitedRewards: boolean;
  premiumReporting: boolean;
  newRealtimeService: boolean;
  newDashboardFlag: boolean;
  newLearningEngine: boolean;
  newLeAssignments: boolean;
  newRegistrationFlow: boolean;
  newLeOptIn: boolean;
  ldFlagsInitialised: boolean;
  oldDashboardAccessConfig: {
    allowedSidebarItems?: string[];
    allowedReports?: string[];
    hiddenSidebarItems?: string[];
    hiddenAppBarItems?: string[];
    enableRedirectionMiddleware?: boolean;
    redirectAssigmentCreation?: boolean;
    redirectAssignmentReports?: boolean;
    restrictedUrls?: string[];
  };
}

interface UserGeneratedContent {
  dashboardUserId: number;
  acceptedTerms: boolean;
  hasAccess: boolean;
}

function updateObject(
  state: FeatureFlagState,
  newState: Partial<FeatureFlagState>
) {
  return Object.assign({}, state, newState);
}

export const featureFlagReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case FEATURE_FLAG_SET:
      return updateObject(state, { ...payload });
    default:
      return state;
  }
};
