import React, { useRef, useState } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isMobile } from "react-device-detect";
import IconButton from '@material-ui/core/IconButton';
import { AttachFile } from '@material-ui/icons';
import Button from '../button/Button';
import { makeStyles } from '@material-ui/styles';
import colors from '../../styles/colors';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { AxiosError, AxiosResponse } from 'axios';
import { push } from "connected-react-router";
import { userTypesDashboardRoute } from '../../constants/users';
import { useDispatch, useSelector } from 'react-redux';
import { USER_UPDATE_DATA } from '../../redux/types/user/user-action-type';


const useStyles = makeStyles(() => ({
  select: {
    margin: '30px auto',
    width: '60%'
  },
  fullWidth: {
    width: '100% !important'
  },
  textField: {
    margin: '30px auto 0',
    width: '60%'
  },
  input: {
    fontSize: 14,
    padding: 11
  },
  inputLabel: {
    fontSize: 14,
    transform: 'translate(14px, 12px) scale(1)'
  },
  anchor: {
    cursor: 'pointer',
    display: 'block',
    margin: '30px auto -25px auto',
    position: 'relative',
    textAlign: 'right',
    textDecoration: 'underline',
    width: '60%'
  },
  notOnTheList: {
    background: colors.lightBackground,
    color: colors.blueHover,
    padding: 10,
    position: 'absolute',
    width: '100%',

    '&>a': {
      margin: 0,
      textAlign: 'center',
      width: '100%'
    }
  },
  notOnTheListMobile: {
    bottom: 0,
    borderTop: '1px solid #dcdcdc',
    left: 0,
    position: 'fixed'
  },
  button: {
    display: 'block',
    margin: '30px auto 0'
  }
}));

interface AdminVerificationProps {
  selectionType: string;
}

export const AdminVerification: React.FC<AdminVerificationProps> = (props: AdminVerificationProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {selectionType} = props;

  const userData = useSelector((state: any) => state.userData);
  const adminVerification = useSelector((state: any) => state.adminVerification);

  const [schoolProfileLink, setSchoolProfileLink] = useState('');
  const [file, setFile] = useState<any>({
    data: null,
    name: ""
  });
  const [error, setError] = useState({
    text: "",
    isError: false
  });

  const fileRef = useRef<HTMLInputElement>(null);

  const handleChangeInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSchoolProfileLink(
      evt.target.value
    )
  }

  const handleFileUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    if (evt.target.files && evt.target.files.length > 0) {
      setFile({
        data: evt.target.files[0],
        name: evt.target.files[0].name
      });
    }
  }

  const handleFileClick = (evt: React.MouseEvent) => {
    if (fileRef && fileRef.current) {
      fileRef.current.click();
    }
  }

  const handleAdminVerification =  () => {
    const {schoolCode, province, school, district, country} = adminVerification;
    const data = {
      _id: userData._id,
      country,
      district,
      organizationName: school,
      province,
      schoolCode,
      schoolProfileLink
    };

    if (file && file.data || schoolProfileLink && schoolProfileLink !== "") {
      let adminVerificationRequest = new FormData();
      for (const [key, value] of Object.entries(data)) {
        adminVerificationRequest.append(key, value);
      }
      if (file && file.data) {
        adminVerificationRequest.append("data", file.data);
      }
      setError({text: "", isError: false});
      dispatch(fetchApi(
        {
          url: 'user/admin-verification',
          endpoint: APIEndpoints.DASHBOARD,
          headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
          },
          method: APIMethods.POST,
          data: adminVerificationRequest,
        }
      ))
        .then((data: AxiosResponse) => {
          dispatch({
            type: USER_UPDATE_DATA,
            payload: {...userData, adminProofSent: true}
          });
          dispatch(
            push(userTypesDashboardRoute[userData.userType])
          )
        })
        .catch((err: AxiosError) => {
          console.error(err);
          setError({text: "Error occurred please try again", isError: true});
        });
    } else {
       setError({text: "Please upload a file or add a link.", isError: true})
    }
  }

  return (
    <Grid container justify='center' alignItems='center' spacing={1}>
      <Grid item xs={12}>
        <TextField
          inputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.inputLabel }}
          className={clsx(classes.textField, isMobile && classes.fullWidth)}
          type="url"
          label={`Paste link to ${selectionType} webpage with staff profile. `}
          style={{ padding: '10px !important' }}
          name="schoolProfileLink"
          value={schoolProfileLink}
          onChange={handleChangeInput}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="p">
          <strong>
            OR
          </strong>
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography component="p">
            Attach a file with other form of proof.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <input id="file" accept="image/png, image/jpeg, application/pdf" ref={fileRef} style={{display: "none"}} type="file" name="file" onChange={handleFileUpload} />
          <IconButton onClick={handleFileClick}>
            <AttachFile/>
          </IconButton>
        </Grid>
        <Grid container item xs={12} justify="center" alignItems="center">
          <Grid item xs={12}>
            {
              file && file.name && <small>filename: <strong>{file.name}</strong></small>
            }
          </Grid>
          <Grid item xs={12}>
            <small>File types accepted: .jpeg, .png, .pdf</small>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="center" alignItems="center">
        <Grid container item xs={12} justify="center" alignItems="center">
          <Button
            primary
            onClick={handleAdminVerification}
            className={classes.button}
            style={{margin: 0}}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12}>
          {
            error.isError && <Typography style={{color: "red"}}>{error.text}</Typography>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}
