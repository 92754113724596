import {
  lazyLoadModule,
  LazyLoadedModules
} from './../../features/lazy-loading';
import { Features } from '../../feature-flag/Features';
import { schoolAdminRoutes } from '../../constants/routes';
import AppBarLayout from '../../layouts/AppBarLayout';

const lazyLoadComponent = lazyLoadModule(LazyLoadedModules.SchoolAdminModule);

const SchoolAdminLayout = lazyLoadComponent('SchoolAdminLayout');
const TeacherListView = lazyLoadComponent('TeacherListView');
const Home = lazyLoadComponent('Home');
const ClassView = lazyLoadComponent('ClassView');
const CreateAccounts = lazyLoadComponent('CreateAccounts');
const Training = lazyLoadComponent('Training');
const StudentsView = lazyLoadComponent('StudentsView');
const PlannerReportView = lazyLoadComponent('PlannerReportView');
const PlannerView = lazyLoadComponent('PlannerView');
const ReportsView = lazyLoadComponent('ReportsView');
const ReadingLevelReportView = lazyLoadComponent('ReadingLevelReportView');
const AssignmentReportView = lazyLoadComponent('AssignmentReportView');
const Account = lazyLoadComponent('Account');
const SkillComprehensionReportView = lazyLoadComponent(
  'SkillComprehensionReportView'
);
const PlacementTestReportView = lazyLoadComponent('PlacementTestReportView');
const ReportToolLeaderboard = lazyLoadComponent('ReportToolLeaderboard');
const LELeaderboardReportView = lazyLoadComponent('LELeaderboardReportView');
const ReportToolClassActivity = lazyLoadComponent('ReportToolClassActivity');
const ResourceView = lazyLoadComponent('ResourceView');
const ResourceInfoView = lazyLoadComponent('ResourceInfoView');
const RealTimeView = lazyLoadComponent('RealTimeWrapper');
const NewLEClassActivityReportView = lazyLoadComponent(
  'NewLEClassActivityReportView'
);

const resourceContent = (layout: any) => {
  return [
    {
      path: schoolAdminRoutes.resourceHub,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceView
    },
    {
      path: schoolAdminRoutes.resourceHubInfo,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceInfoView
    }
  ];
};

export default [
  {
    path: schoolAdminRoutes.homeScreen,
    layout: SchoolAdminLayout,
    component: TeacherListView
  },
  {
    path: schoolAdminRoutes.teacherClassList,
    layout: SchoolAdminLayout,
    component: Home
  },
  {
    path: schoolAdminRoutes.teacherClass,
    layout: SchoolAdminLayout,
    component: ClassView
  },
  {
    path: schoolAdminRoutes.createAccounts,
    layout: SchoolAdminLayout,
    component: CreateAccounts
  },
  {
    path: schoolAdminRoutes.training,
    layout: SchoolAdminLayout,
    component: Training
  },
  {
    path: schoolAdminRoutes.teacherStudents,
    layout: SchoolAdminLayout,
    component: StudentsView
  },
  {
    path: schoolAdminRoutes.teacherPlanner,
    layout: SchoolAdminLayout,
    component: PlannerView
  },
  {
    path: schoolAdminRoutes.teacherPlanner,
    layout: SchoolAdminLayout,
    component: PlannerView
  },
  {
    path: schoolAdminRoutes.teacherReports,
    layout: SchoolAdminLayout,
    component: ReportsView
  },
  // {
  //   path: '/dashboard/school-admin/memberships/:classCode',
  //   layout: SchoolAdminLayout,
  //   component: MembershipView
  // },
  {
    path: schoolAdminRoutes.teacherReadingLevelReport,
    layout: SchoolAdminLayout,
    component: ReadingLevelReportView
  },
  {
    path: schoolAdminRoutes.teacherPlacementTestReport,
    layout: SchoolAdminLayout,
    component: PlacementTestReportView
  },
  {
    path: schoolAdminRoutes.teacherPlannerReport,
    layout: SchoolAdminLayout,
    component: PlannerReportView
  },
  {
    path: schoolAdminRoutes.teacherSkillComprehensionReport,
    layout: SchoolAdminLayout,
    component: SkillComprehensionReportView
  },
  {
    path: schoolAdminRoutes.teacherAssignmentReport,
    layout: SchoolAdminLayout,
    component: AssignmentReportView
  },
  {
    path: schoolAdminRoutes.teacherRealTimeDashboard,
    layout: AppBarLayout,
    component: RealTimeView
  },
  {
    path: schoolAdminRoutes.leaderboardReport,
    layout: SchoolAdminLayout,
    component: ReportToolLeaderboard
  },
  {
    path: schoolAdminRoutes.newLEleaderboardReport,
    layout: schoolAdminRoutes,
    component: LELeaderboardReportView
  },
  {
    path: schoolAdminRoutes.classActivityReport,
    layout: SchoolAdminLayout,
    component: ReportToolClassActivity
  },
  {
    path: schoolAdminRoutes.newLEClassActivityReport,
    layout: SchoolAdminLayout,
    component: NewLEClassActivityReportView
  },
  {
    path: schoolAdminRoutes.myAccount,
    layout: SchoolAdminLayout,
    component: Account
  },
  ...resourceContent(SchoolAdminLayout)
];
