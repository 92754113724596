import React, { useState } from 'react';
import PlacementTestPieWidget from '../../components/widgets/PlacementTestPieWidget';
import ReportToolPlacementTestIcon from '../../assets/icons/report/placement-test-icon.png';
import View from '../../components/view/View';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Theme,
  Paper
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import BarChart from '../../components/chart/BarChart';
import ChartContainer from '../../components/chart/ChartContainer';
import { Bar, Cell, ResponsiveContainer } from 'recharts';
import colors from '../../styles/colors';
import Select from 'react-select';
import { grades } from '../../constants/global-constants';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button/Button';
import { show } from 'redux-modal/lib/actions';
import {
  MODAL_PREMIUM_UPGRADE_REDIRECT,
  MODAL_PLACEMENT_TEST_REASSIGN
} from '../../constants/modals';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { AxiosError, AxiosResponse } from 'axios';
import StarsIcon from '@material-ui/icons/Stars';
import store, { RootState } from '../../redux/store';

const useStyles = makeStyles((theme: Theme) => ({
  filterWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    position: 'absolute',
    right: 20,
    top: 20,
    width: 350
  },
  placementTestButton: {
    position: 'absolute',
    bottom: '10px'
  },
  container: {
    padding: '10px 10px 10px',
    position: 'relative',
    height: '100%'
  },
  containerFont: {
    marginBottom: 10,
    fontSize: '14px'
  },
  filterSelect: {
    flex: 1,
    marginLeft: 10
  },
  chartGrid: {
    marginBottom: 20
  },
  reassignTestTextbox: {
    [theme.breakpoints.down('xs')]: {
      height: 530
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: 200
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 300
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: 375
    },
    overflowY: 'auto',
    padding: '15px'
  }
}));

interface LearnerRclData {
  id: number;
  learnerId: number;
  contextType: string;
  newRCL: number;
  rclDate: Date;
}

const PlacementTestReportView: React.FC = () => {
  const currentClass = useSelector((state: any) => state.class.currentClass);
  const classCode = currentClass.code;
  const userData = useSelector((state: any) => state.userData);
  const isPremiumUser = userData.premium && userData.premium.isPremium;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { featureFlag } = store.getState() as RootState;
  const benchmarkOptions = grades.map(grade => ({
    label: grade,
    value: grade
  }));
  const [benchmark, setBenchmark] = useState(() => {
    const counts = currentClass.roster.reduce(
      (a: { [key: number]: number }, s: { grade: number }) => {
        a[s.grade] = (a[s.grade] || 0) + 1;
        return a;
      },
      {}
    );
    const majorityGrade = Object.keys(counts).reduce(
      (a, b) => (counts[a] > counts[b] ? a : b),
      ''
    );
    return (
      benchmarkOptions.find(
        benchmark => benchmark.label === Number(majorityGrade)
      ) || benchmarkOptions[4]
    );
  });

  const [placementTestUsers, setPlacementTestUsers] = useState([]);

  React.useEffect(() => {
    fetchActivePlacementTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classCode, featureFlag.newDashboardFlag]);

  const fetchActivePlacementTests = () => {
    const playerIds: number[] = currentClass.roster.map(
      (user: any) => user.educationUserId
    );
    if (playerIds && playerIds.length > 0) {
      if (featureFlag.newDashboardFlag) {
        let url = `v1/reports/rcl-history?`;
        for (const playerId of playerIds) {
          url = url + `&learners[]=${playerId}`;
        }
        dispatch(
          fetchApi({
            url: url,
            method: APIMethods.GET,
            endpoint: APIEndpoints.LE_API,
          })
        )
          .then((response: AxiosResponse) => {
            const learnersRclData: LearnerRclData[] = response.data;

            const maxNewRcl = Object.values(
              learnersRclData
            ).map(({ newRCL, learnerId }) => ({ newRCL, learnerId }));
            if (maxNewRcl) {
              const addDefaultRCL = currentClass.roster.map((id: any) => {
                const match = maxNewRcl.find(
                  (learnerId: any) => learnerId.learnerId === id.educationUserId
                );
                if (match) {
                  return match;
                } else {
                  return { learnerId: id.educationUserId, newRCL: '' };
                }
              });
              if (addDefaultRCL) {
                const currentRclLearners: any = addDefaultRCL.map(
                  (learner: any) => ({
                    ...learner,
                    name: currentClass.roster.find(
                      (player: any) =>
                        player.educationUserId === learner.learnerId
                    ).fullname,
                    currentGrade: Math.floor(learner.newRCL)
                  })
                );

                let url = `v1/learners/assignment-attempts?assignmentType=PlacementTest&distinct=learnerId`;
                for (const playerId of playerIds) {
                  url = url + `&learnerIds[]=${playerId}`;
                }
                
                dispatch(
                  fetchApi({
                    url: url,
                    method: APIMethods.GET,
                    endpoint: APIEndpoints.LE_API,
                  })
                )
                  .then((response: AxiosResponse) => {
                    const assignmentAttempts: any = response.data;
                    const finalPlacementTestArray: any = [];
                    for (let i = 0; i < currentRclLearners.length; i++) {
                      const current = currentRclLearners[i];
                      const match = assignmentAttempts.find(
                        (d: any) => d.learnerId === current.learnerId
                      );
                      if (match) {
                        finalPlacementTestArray.push({
                          status: match.completionStatus,
                          updatedAt: match.updatedAt,
                          assignmentGivenId: match.assignmentGivenId,
                          currentGrade: current.currentGrade,
                          name: current.name,
                          readingLevel: current.newRCL,
                          learnerId: current.learnerId,
                          questionAttemptCount: match._count.questionAttempt
                        });
                      } else {
                        finalPlacementTestArray.push({
                          status: 'NotStarted',
                          updatedAt: '',
                          assignmentGivenId: '',
                          currentGrade: current.currentGrade,
                          name: current.name,
                          readingLevel: '',
                          learnerId: current.learnerId,
                          questionAttemptCount: 0
                        });
                      }
                    }
                    for (let i = 0; i < finalPlacementTestArray.length; i++) {
                      const item = finalPlacementTestArray[i];
                      if (
                        item.status === 'NotStarted' &&
                        item.questionAttemptCount &&
                        item.questionAttemptCount > 0
                      ) {
                        item.status = 'IN PROGRESS';
                      }
                    }
                    setPlacementTestUsers(finalPlacementTestArray);
                  })
                  .catch((err: AxiosError) => err);
              }
            }
          })
          .catch((err: AxiosError) => err);
      } else if (!featureFlag.newDashboardFlag) {
        dispatch(
          fetchApi({
            url: `v2.1/assignment/placement-test?playerId=${playerIds.toString()}`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.GET
          })
        )
          .then((response: AxiosResponse) => {
            const { placementTestUsers } = response.data;
            if (placementTestUsers) {
              const mapPlacementTestUsers = placementTestUsers.map(
                (placementTestUser: any) => ({
                  ...placementTestUser,
                  name: currentClass.roster.find(
                    (player: any) =>
                      player.educationUserId === placementTestUser.userId
                  ).fullname
                })
              );
              setPlacementTestUsers(mapPlacementTestUsers);
            }
          })
          .catch((err: AxiosError) => err);
      }
    }
  };

  const inProgress = placementTestUsers.filter((test: any) =>
    featureFlag.newDashboardFlag
      ? test.status !== 'Done'
      : test.status !== 'COMPLETED'
  );

  let chartMapped = [];

  if (placementTestUsers && placementTestUsers.length > 0) {
    chartMapped = placementTestUsers
      .filter((player: any) =>
        featureFlag.newDashboardFlag
          ? player.status === 'Done'
          : player.status === 'COMPLETED'
      )
      .map((player: any) => {
        return {
          ...player,
          'Reading Level': player.readingLevel,
          Benchmark: benchmark.value
        };
      })
      .sort((a: any, b: any) => {
        if (a.readingLevel < b.readingLevel) return 1;
        if (a.readingLevel > b.readingLevel) return -1;
        return 0;
      });
  }
  const chartData = chartMapped;
  const handlePlacementTestReassignModal = () => {
    const mappedUsers = currentClass.roster.map((roster: any) => ({
      ...roster,
      isActive:
        inProgress.find((placementTest: any) =>
          featureFlag.newDashboardFlag
            ? placementTest.learnerId === roster.educationUserId
            : placementTest.userId === roster.educationUserId
        ) || false
    }));
    dispatch(
      show('modal', {
        type: MODAL_PLACEMENT_TEST_REASSIGN,
        users: mappedUsers,
        callback: fetchActivePlacementTests,
        classCode: currentClass.code
      })
    );
  };
  const mappedPieChartUser = placementTestUsers.map(
    (placementTestUser: any) => {
      return {
        currentGrade: placementTestUser.currentGrade,
        name: placementTestUser.name,
        passageLevel: placementTestUser.passageLevel,
        readingLevel: placementTestUser.readingLevel,
        status: placementTestUser.status
      };
    }
  );
  return (
    <View
      title="Placement Test Report"
      renderBackButton={() => (
        <Button onClick={() => history.goBack()} style={{ marginRight: 10 }}>
          <ChevronLeft /> Back
        </Button>
      )}
    >
      <Grid container spacing={2} className={classes.chartGrid}>
        <Grid item sm={8}>
          {mappedPieChartUser && mappedPieChartUser.length > 0 ? (
            <PlacementTestPieWidget
              height={150}
              legend={{ position: 'right' }}
              data={mappedPieChartUser}
            />
          ) : (
            <>
              <Paper style={{ paddingBottom: '120px' }}>
                <div style={{ textAlign: 'center', paddingTop: '100px' }}>
                  <img
                    src={ReportToolPlacementTestIcon}
                    style={{ height: '120px', width: '120px' }}
                  ></img>
                </div>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '80px'
                  }}
                >
                  {currentClass.roster.length
                    ? 'Please assign placement test to players to view the status'
                    : 'Add a player to see reports'}
                </Typography>
              </Paper>
            </>
          )}
        </Grid>
        <Grid item sm={4}>
          <Paper className={classes.container}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={classes.reassignTestTextbox}>
                <span style={{ fontSize: '20px' }}>
                  <Typography className={classes.containerFont}>
                    The placement test is used by the learning engine to level
                    the reading content within the game to the players reading
                    skills.
                  </Typography>
                  <Typography className={classes.containerFont}>
                    It starts immediately once players complete the tutorial and
                    continues discreetly in the background. It uses the same
                    question style as the regular Dreamscape experience so it
                    blends directly into the game experience.
                  </Typography>
                  <Typography className={classes.containerFont}>
                    As players continue to play, the learning engine continually
                    adapts and re-evaluates their reading level and stealthily
                    adjusts the content that is delivered.
                  </Typography>
                  <Typography className={classes.containerFont}>
                    We recommend reassigning the placement test if players have
                    had an extended break from playing (such as after the winter
                    holidays) or if you feel like the content has become
                    mismatched from the player’s ability. Premium members can
                    reassign it to individual players or to the whole class.
                  </Typography>
                </span>
              </div>
              {/* <Tooltip
                title={
                  !isPremiumUser
                    ? 'This feature is only available for premium accounts'
                    : ''
                }
              > */}
              <Box textAlign={'center'} className={classes.placementTestButton}>
                {isPremiumUser && featureFlag.premiumReporting ? (
                  <Button onClick={handlePlacementTestReassignModal} primary>
                    Reassign placement test
                  </Button>
                ) : (
                  ''
                )}
                {!isPremiumUser && featureFlag.premiumReporting ? (
                  <Button
                    onClick={() =>
                      dispatch(
                        show('modal', {
                          type: MODAL_PREMIUM_UPGRADE_REDIRECT,
                          children: () => (
                            <Typography component="p">
                              {
                                'Become a Premium member to reassign placement tests anytime.'
                              }
                            </Typography>
                          )
                        })
                      )
                    }
                    primary
                  >
                    {!userData.premium.isPremium ? (
                      <StarsIcon
                        fontSize={'large'}
                        style={{
                          position: 'relative',
                          right: '5px',
                          width: '30px'
                        }}
                      />
                    ) : (
                      ''
                    )}
                    Reassign placement test
                  </Button>
                ) : (
                  ''
                )}
                {!featureFlag.premiumReporting ? (
                  <Button onClick={handlePlacementTestReassignModal} primary>
                    Reassign placement test
                  </Button>
                ) : (
                  ''
                )}
              </Box>
              {/* </Tooltip> */}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid sm={12}>
          <ChartContainer title="Results for Completed Placement Tests">
            <div className={classes.filterWrapper}>
              <Typography>Benchmark:</Typography>
              <Select
                value={benchmark}
                options={benchmarkOptions}
                className={classes.filterSelect}
                onChange={(selection: any) => setBenchmark(selection)}
              />
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                xDataKey="name"
                data={chartData}
                yDataKey="Reading Level"
                line={{ dataKey: 'Benchmark', fill: colors.primary }}
                dataLabel="Reading Level"
                itemsPerPage={10}
                xAxisAngle={-45}
                xAxisHeight={80}
                yAxisRange={[0, 8]}
                renderBar={() => (
                  <Bar dataKey="Reading Level">
                    {chartData.map((item: any, index: number) => (
                      <Cell
                        fill={
                          item.readingLevel < benchmark.value
                            ? '#e17055'
                            : '#1abc9c'
                        }
                      />
                    ))}
                  </Bar>
                )}
              />
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>
      </Grid>
    </View>
  );
};

export default PlacementTestReportView;
