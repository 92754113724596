import React, { useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { grades } from '../../../constants/global-constants';
import { updatePlayer } from '../../../redux/actions/player-actions';
import { MODAL_TEACHER_ASSIGN_MEMBERSHIPS } from '../../../constants/modals';
import { hide, hide as hideModal, show as showModal } from 'redux-modal';
import { useAppSelector } from '../../../redux/hooks';
import StudentMembershipIcon from '../../../assets/membership/mIcon.png';
import { push } from 'react-router-redux';
import { userNavigateWithClassCode } from '../../../utils/user-route-getter';
import { teacherRoutes } from '../../../constants/routes';

const ModalEditStudent: React.FC = (props: any) => {
  const { show, handleHide, userData } = props;
  const userDetails = useAppSelector(state => state.userData);
  const membershipData = useAppSelector(state => state.members);
  const { nonMembers } = membershipData;
  const classData = useAppSelector(state => state.class);
  const { classes } = classData;
  const archivedClasses = classes.filter((a: any) => a.archived == true);
  const [grade, setGrade] = useState(userData.grade || '');
  const [user, setUser] = useState({
    password: ''
  });
  const [passwordError, setPasswordError] = useState(false);
  const dispatch = useDispatch();
  // let unarchivedNonMembersipPlayers: any = [];
  const [
    unarchivedNonMembersipPlayers,
    setUnarchivedNonMembersipPlayers
  ] = useState([]);

  useEffect(() => {
    // const getUnarchivedUsers = () => {
    const archivedUsers: string[] = [];
    if (archivedClasses) {
      const archivedUsersData = archivedClasses.map((item: any) => item.roster);
      archivedUsersData.forEach((element: any) => {
        element.forEach((item: any) => {
          archivedUsers.push(item.userId);
        });
      });
    }
    const unarchivedNonMembersips = nonMembers.filter(function(obj: any) {
      const testData = !archivedUsers.some(function(obj2: any) {
        return obj._id == obj2;
      });
      console.log('testData', testData);
      return testData;
    });
    setUnarchivedNonMembersipPlayers(unarchivedNonMembersips);
    // };
  }, [classes, membershipData]);

  const handleSelectGrade = (evt: any) => {
    setGrade(evt.target.value);
  };

  const handleUserData = (evt: any) => {
    setUser({
      ...user,
      [evt.target.name]: evt.target.value
    });
  };

  const handleUpdate = () => {
    setPasswordError(false);
    let updateData: any = { grade };
    if (user.password.trim() !== '') {
      updateData = {
        ...updateData,
        password: user.password,
        passwordVisible: userData.passwordVisible || true
      };
    }
    dispatch(updatePlayer(updateData, userData._id));
    return null;
  };

  const handleassignMemberships = () => {
    if (userDetails.premium.membershipsAvailable > 0) {
      const sendProps: any = {
        type: MODAL_TEACHER_ASSIGN_MEMBERSHIPS
      };
      dispatch(showModal('modal', sendProps));
    } else {
      dispatch(hide('modal'));
      dispatch(
        push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
      );
    }
  };

  const assignMemberships = () => {
    const selectedStudentData = unarchivedNonMembersipPlayers.filter(
      (a: any) => a._id == userData._id
    );

    if (selectedStudentData.length != 0) {
      return (
        <>
          <Button
            size="small"
            variant="contained"
            // className={classes.bottomGridButton}
            style={{
              marginTop: '14px',
              backgroundColor: '#00AFFE',
              color: 'white',
              position: 'relative',
              left: '40px'
            }}
            onClick={() => {
              // if (premiumExpiry.membershipsAvailable < 0) {
              handleassignMemberships();
              // }
            }}
            // disabled={true}
          >
            Assign memberships
          </Button>
          <Typography
            variant="body2"
            style={{
              fontSize: '10px',
              color: 'grey',
              marginTop: '5px',
              position: 'relative',
              left: '21px'
            }}
          >
            You have {userDetails.premium.membershipsAvailable} unassigned
            memberships
          </Typography>
        </>
      );
    } else {
      return '';
    }
  };

  const membershipImage = () => {
    const selectedStudentData = nonMembers.filter(
      (a: any) => a._id == userData._id
    );
    console.log('data', selectedStudentData);

    if (selectedStudentData.length != 0) {
      return '';
    } else {
      return (
        <img
          src={StudentMembershipIcon}
          height={'26px'}
          alt="membership icon"
        />
      );
    }
  };

  const membershipFieldValue = () => {
    const selectedStudentData = nonMembers.filter(
      (a: any) => a._id == userData._id
    );
    console.log('data', selectedStudentData);

    if (selectedStudentData.length == 1) {
      return (
        <TextField
          fullWidth
          disabled
          label="Membership Status"
          defaultValue="Not a member"
        ></TextField>
      );
    } else {
      return (
        <TextField
          fullWidth
          disabled
          label="Membership Status"
          defaultValue="Member"
        ></TextField>
      );
    }
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide} hideOnOutsideClick>
      <DialogTitle>Edit Player</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              onChange={handleUserData}
              name="firstname"
              label="First Name"
              defaultValue={userData.firstname}
              disabled
            />
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              onChange={handleUserData}
              label="Last Name"
              name="lastname"
              disabled
              defaultValue={userData.lastname}
            />
          </Grid>
          {userDetails.premium.isPremium ? (
            <Grid item xs={12} md={6}>
              <FormControl
                style={{
                  width: '100%'
                }}
                error={passwordError}
              >
                <TextField
                  onChange={handleUserData}
                  fullWidth
                  error={passwordError}
                  label={'Password'}
                  name="password"
                />
                <FormHelperText id="component-error-text">
                  {passwordError && 'Enter password'}
                </FormHelperText>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={12} md={12}>
              <FormControl
                style={{
                  width: '100%'
                }}
                error={passwordError}
              >
                <TextField
                  onChange={handleUserData}
                  fullWidth
                  error={passwordError}
                  label={'Password'}
                  name="password"
                />
                <FormHelperText id="component-error-text">
                  {passwordError && 'Enter password'}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          {userDetails.premium.isPremium ? (
            <>
              {' '}
              <Grid item xs={12} md={1}>
                {membershipImage()}
              </Grid>
              <Grid item xs={12} md={5}>
                {membershipFieldValue()}
                {assignMemberships()}
              </Grid>
            </>
          ) : (
            ''
          )}

          <Grid item xs={12}>
            <InputLabel>Grade</InputLabel>
            <Select
              fullWidth
              onChange={handleSelectGrade}
              name="grade"
              value={grade}
            >
              {grades.map((e: any) => {
                return <MenuItem value={e}>{e}</MenuItem>;
              })}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button primary onClick={handleUpdate}>
          Update
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalEditStudent;
