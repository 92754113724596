import {
  CLASSROOM_GET_CLASSES,
  CLASSROOM_CHANGE_CLASS,
  CLASSROOM_RESET_LOAD_STATE
} from '../types/google-classroom/google-classroom-action-types';
import {
  GoogleClass,
  GoogleStudent
} from '../../types/google/google-classroom-import-request-type';
import { cloneDeep } from 'lodash';

export const googleClassroomReducer = (
  state: any = {
    classes: [],
    _classesOriginal: [],
    inProgress: false
  },
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case CLASSROOM_GET_CLASSES:
      const classes = payload.map((item: GoogleClass) => {
        return {
          ...item,
          students: item.students.map((student: GoogleStudent) => ({
            ...student,
            grade: student.grade || 1,
            mergeWith: null
          })),
          grade: 1
        };
      });
      return {
        classes,
        _classesOriginal: classes
      };
    case CLASSROOM_CHANGE_CLASS:
      return {
        ...state,
        classes: cloneDeep(state.classes).map((item: any) => {
          if (item.id === payload.id) {
            item[payload.key] = payload.value;
          }
          return item;
        })
      };
    case CLASSROOM_RESET_LOAD_STATE:
      return {
        ...state,
        classes: state._classesOriginal
      };
    default:
      return state;
  }
};
