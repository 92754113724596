import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import ModalAbstract from '../../../../../components/popups/modals/ModalAbstract';
import Grid from '@material-ui/core/Grid';
import { fade, makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/colors';
import {
  Box,
  Checkbox,
  Chip,
  DialogContent,
  Divider,
  IconButton,
  InputBase,
  List,
  Theme
} from '@material-ui/core';
import { Button } from '../../button/Button';
import SearchIcon from '@material-ui/icons/Search';
import { Close, Delete } from '@material-ui/icons';
import { ordinalSuffix } from '../../../../../utils/grade';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../../../../../redux/actions/fetch-actions';
import {
  APIEndpoints,
  APIMethods
} from '../../../../../types/fetch/fetch-types';
import { AxiosResponse } from 'axios';
import {
  SearchResult,
  SearchResultList
} from './search-result/SearchResultList';
import FilterDropDown from './drop-down/FilterDropDown';
import { AssignmentQuestionType } from '../../../pages/edit/state/assignment-edit.types';
import { SearchResultItem } from './search-result/SearchResultItem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '80vh',
    width: '90vw',
    padding: '0'
  },
  filter: {
    backgroundColor: colors.assignment.grey,
    padding: '1rem',
    overflowY: 'auto'
  },
  search: {
    position: 'relative',
    border: '1px solid grey',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '36ch',
      '&:focus': {
        width: '50ch'
      }
    }
  },
  inputInputNoAnimation: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: '100%'
  },
  spaceBetween: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divider: {
    marginTop: '.8rem',
    paddingBottom: '.5rem'
  },
  addedQuestions: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  chips: {
    fontSize: '.7rem'
  }
}));

interface QuestionBankModalProps {
  assignmentId: number;
  show: boolean;
  handleHide(): void;
  skills: any[];
  groups: any[];
  grades: any[];
  genres: any[];
  handleSave(addedQuestions: any[]): void;
  alreadyAddedQuestions: any[];
}
interface SearchOptions {
  independentQuestions: boolean;
  passageQuestions: boolean;
  skillIds: any[];
  gradeIds: any[];
  searchText: string;
}
const QuestionBankModal = (
  props: PropsWithChildren<QuestionBankModalProps>
) => {
  const {
    show,
    handleHide,
    grades: defaultGrades,
    genres,
    skills,
    groups,
    handleSave,
    alreadyAddedQuestions
  } = props;
  const [loading, setLoading] = useState(false);
  let grades = defaultGrades.map((grade: number) => {
    return {
      id: grade,
      value: grade,
      isSelected: false
    };
  });
  const [attributeData, setAttributeData] = useState({
    skills,
    grades
  });
  const [searchPage, setSearchPage] = useState(1);
  const [searchData, setSearchData] = useState({
    total: 0,
    page: 0,
    result: []
  });
  const [addedQuestions, setAddedQuestions] = useState<any>([]);
  const [search, setSearch] = useState<SearchOptions>({
    independentQuestions: false,
    passageQuestions: false,
    skillIds: [],
    gradeIds: [],
    searchText: ''
  });
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSearchFilter = (evt: any) => {
    const newSearch = {
      ...search,
      [evt.target.name]: evt.target.value
    };
    setSearch(newSearch);
  };

  const handleSearchCheck = (evt: any) => {
    const newSearch = {
      ...search,
      [evt.target.name]: evt.target.checked
    };
    setSearch(newSearch);
  };

  const handleMultiFilters = (key: any, value: any) => {
    // @ts-ignore
    let filterOption = search[key];
    const exists = filterOption.find((item: any) => item.id === value.id);
    if (exists) {
      filterOption = filterOption.filter((item: any) => item.id !== value.id);
    } else {
      filterOption.push(value);
    }
    if (key === 'skillIds') {
      const mappedSkills = attributeData.skills.map(skill => {
        if (skill.id === value.id) {
          return {
            ...skill,
            isSelected: !skill.isSelected
          };
        }
        return {
          ...skill,
          isSelected: skill.isSelected || false
        };
      });
      setAttributeData({
        ...attributeData,
        skills: mappedSkills
      });
    } else if (key === 'gradeIds') {
      setAttributeData({
        ...attributeData,
        grades: attributeData.grades.map(grade => {
          if (grade.id === value.id) {
            return {
              ...grade,
              isSelected: !grade.isSelected
            };
          }
          return {
            ...grade
          };
        })
      });
    }
    setSearch({
      ...search,
      [key]: filterOption
    });
  };
  /**
   * @description render default data on load
   */
  useEffect(() => {
    handleSearch(search);
  }, []);
  /**
   * @description debounced searching for question bank
   */
  useEffect(() => {
    let delayDebounceFn: any;
    if (search) {
      setSearchPage(1);
      delayDebounceFn = setTimeout(() => {
        // Send Axios request here
        handleSearch(search);
      }, 150);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleAddQuestion = (question: any, type: AssignmentQuestionType) => {
    const questions = [...addedQuestions];
    const newQuestionType = type || AssignmentQuestionType.Independent;
    questions.push({
      ...question,
      type: newQuestionType
    });
    setAddedQuestions(questions);
    const result = [...searchData.result];

    result.forEach((resultQuestion: any) => {
      if (
        resultQuestion.id === question.id &&
        getQuestionType(resultQuestion) === newQuestionType
      ) {
        resultQuestion.isAdded = true;
      }
    });
    setSearchData({
      ...searchData,
      result
    });
  };

  const getQuestionType = (passage: any) => {
    if (passage.questions) {
      return AssignmentQuestionType.Passage;
    }
    return AssignmentQuestionType.Independent;
  };

  const handleDeleteQuestion = (
    question: any,
    type?: AssignmentQuestionType
  ) => {
    type = type || AssignmentQuestionType.Independent;
    // @TODO remove
    const newAddedQuestions = addedQuestions.filter((addedQuestion: any) => {
      return !(addedQuestion.id == question.id && addedQuestion.type == type);
    });
    const result = [...searchData.result];
    result.forEach((resultQuestion: any) => {
      if (
        resultQuestion.id === question.id &&
        type === getQuestionType(resultQuestion)
      ) {
        resultQuestion.isAdded = false;
      }
    });
    setAddedQuestions(newAddedQuestions);
    setSearchData({
      ...searchData,
      result
    });
  };

  /**
    Create helper function to deal with querying data
   */
  const generateQuery = (searchOptions: SearchOptions, page = 0) => {
    let searchString = `size: 20`;
    if (searchOptions.searchText) {
      searchString += `, text:"${searchOptions.searchText}"`;
    }
    if (searchOptions.independentQuestions && !searchOptions.passageQuestions) {
      searchString += `, includeIndependentQuestions:${searchOptions.independentQuestions}`;
    }
    if (!searchOptions.independentQuestions && searchOptions.passageQuestions) {
      searchString += `, includePassageQuestions:${searchOptions.passageQuestions}`;
    }

    if (searchOptions.skillIds.length > 0) {
      searchString += `, skill: ${JSON.stringify(
        searchOptions.skillIds.map(skill => skill.id)
      )}`;
    }
    if (searchOptions.gradeIds.length > 0) {
      searchString += `, grade: ${JSON.stringify(
        searchOptions.gradeIds.map(grade => grade.id)
      )}`;
    }
    if (page) {
      searchString += `, page: ${page}`;
    }
    const query = `
     query {
      search(${searchString}) {
        total,
        page,
        result {
          ...on Passage {id, genreId, name, text, questions {id, question, correctAnswers, potentialAnswers, skillId, questionTypeId}},
          ... on IndependentQuestion {id, question, correctAnswers, potentialAnswers, name, questionTypeId, skillId},
        }
      }
    }
  `;

    return query;
  };

  const handleSearch = (search: SearchOptions, page = 0, append = false) => {
    setLoading(true);
    dispatch(
      fetchApi({
        url: 'graphql',
        endpoint: APIEndpoints.SEARCH_API,
        method: APIMethods.POST,
        data: {
          query: generateQuery(search, page)
        }
      })
    )
      .then((response: AxiosResponse) => {
        const { data } = response.data;
        const { search: newSearchData } = data;
        if (newSearchData) {
          if (append) {
            handleSearchData({
              ...newSearchData,
              result: [...searchData.result, ...newSearchData.result]
            });
          } else {
            handleSearchData(newSearchData);
          }
        }
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };
  const handleSearchData = (newSearchData: any) => {
    setSearchData({
      ...newSearchData
    });
  };
  const dialogContainerRef = useRef(null);

  function handleSkillSearch(skillSearchText: string) {
    if (!skillSearchText || skillSearchText === '') {
      setAttributeData({
        ...attributeData,
        skills: skills
      });
    } else {
      const filteredSkills = skills.filter(skill => {
        return !skill.name.toUpperCase().indexOf(skillSearchText.toUpperCase());
      });
      setAttributeData({
        ...attributeData,
        skills: filteredSkills
      });
    }
  }
  const [questionViewer, setQuestionViewer] = useState<{
    isViewMode: boolean;
    question: SearchResult | null;
  }>({
    isViewMode: false,
    question: null
  });
  function viewQuestion(question: any) {
    setQuestionViewer({
      isViewMode: true,
      question
    });
  }

  /**
   * @description triggers next page for infinite scroll
   */
  function handleNextPage() {
    const newPage = searchPage + 1;
    setSearchPage(newPage);
    handleSearch(search, newPage, true);
  }

  const height: HTMLElement | any = dialogContainerRef?.current;
  return (
    <ModalAbstract
      handleHide={handleHide}
      maxWidth={'xl'}
      hideOnOutsideClick={true}
      show={show}
    >
      <DialogContent
        ref={dialogContainerRef}
        style={{ padding: 0, overflow: 'hidden' }}
        className={classes.root}
      >
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={5}
            sm={5}
            md={3}
            className={classes.filter}
            style={{ height: height?.clientHeight || '800px' }}
          >
            {/*<Grid item>*/}
            {/*  <Grid container>*/}
            {/*    <Grid item xs={12}>*/}
            {/*      <b>Question Source</b>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12}>*/}
            {/*      <Checkbox style={{ color: colors.assignment.blue }} />*/}
            {/*      <small>Dreamscape Question Bank</small>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12}>*/}
            {/*      <Checkbox style={{ color: colors.assignment.blue }} />*/}
            {/*      <small>My Questions</small>*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} style={{ margin: '10px 0' }}>*/}
            {/*  <Divider />*/}
            {/*</Grid>*/}
            <Grid item>
              <Grid item xs={12}>
                <b>Question Type</b>
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  value={search.independentQuestions}
                  onChange={handleSearchCheck}
                  name={'independentQuestions'}
                  style={{ color: colors.assignment.blue }}
                />
                <small>Syntax/Word Study</small>
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  value={search.passageQuestions}
                  onChange={handleSearchCheck}
                  name={'passageQuestions'}
                  style={{ color: colors.assignment.blue }}
                />
                <small>Reading Comprehension</small>
              </Grid>
            </Grid>
            <Grid item>
              <FilterDropDown
                title={'Grade'}
                showAll={
                  search.gradeIds.length === 0 ||
                  search.gradeIds.length === grades.length
                }
                renderChips={() => (
                  <>
                    {search.gradeIds.map(grade => (
                      <Chip
                        size="small"
                        deleteIcon={<Close />}
                        label={`${ordinalSuffix(grade.value)} grade`}
                        onDelete={() => handleMultiFilters('gradeIds', grade)}
                      />
                    ))}
                  </>
                )}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <List>
                      {attributeData.grades.map(grade => {
                        const stringGrade = ordinalSuffix(grade.value);
                        return (
                          <Box key={grade.id}>
                            <Checkbox
                              onClick={(evt: any) =>
                                handleMultiFilters(evt.target.name, grade)
                              }
                              name={'gradeIds'}
                              checked={grade.isSelected}
                              color={'primary'}
                            />
                            {stringGrade} grade
                          </Box>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </FilterDropDown>
            </Grid>
            <Grid item xs={12}>
              <FilterDropDown
                title={'Skills'}
                onClose={() => handleSkillSearch('')}
                showAll={
                  search.skillIds.length === 0 ||
                  search.skillIds.length === skills.length
                }
                renderChips={() => (
                  <>
                    {search.skillIds.map(skill => (
                      <Chip
                        size="small"
                        deleteIcon={<Close />}
                        className={classes.chips}
                        label={skill.name}
                        onDelete={() => handleMultiFilters('skillIds', skill)}
                      />
                    ))}
                  </>
                )}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      className={classes.search}
                      style={{ marginRight: '1rem', marginBottom: '1rem' }}
                    >
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInputNoAnimation
                        }}
                        name={'searchText'}
                        onChange={evt => handleSkillSearch(evt.target.value)}
                        inputProps={{ 'aria-label': 'search' }}
                      />
                    </div>
                    {Object.keys(groups).map((group: any) => {
                      return (
                        <>
                          <b>{group}</b><br></br>
                          {groups[group].map((groupSkill: any) => {
                            const skill = attributeData.skills.find(e => e.id == groupSkill.skillId);
                            if (skill) {
                              return (
                                <Box
                                  key={skill.id}
                                  style={{
                                    textOverflow: 'ellipsis',
                                      fontSize: '.8rem'
                                  }}
                                >
                                  <Checkbox
                                    name={'skillIds'}
                                    onClick={(evt: any) =>
                                      handleMultiFilters('skillIds', skill)
                                    }
                                    checked={Boolean(skill.isSelected)}
                                    color={'primary'}
                                  />
                                  {skill.name}
                                </Box>
                              );
                            }

                          })}
                        </>
                      )
                    })}
                  </Grid>
                </Grid>
              </FilterDropDown>
            </Grid>
            <Grid item className={classes.divider} xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} className={classes.spaceBetween}>
              <span>Added to Assignment</span>
              <span>{addedQuestions.length}</span>
            </Grid>
            {addedQuestions.length > 0 &&
              addedQuestions.map((question: any, index: number) => {
                return (
                  <Grid
                    key={index}
                    onClick={() => viewQuestion(question)}
                    item
                    xs={12}
                    className={`${classes.spaceBetween} ${classes.addedQuestions}`}
                  >
                    <span>
                      {question.type === AssignmentQuestionType.Independent
                        ? 'Question'
                        : 'Passage'}{' '}
                      {question.id}
                    </span>
                    <IconButton
                      onClick={(evt: any) => {
                        evt.stopPropagation();
                        handleDeleteQuestion(question, question.type);
                      }}
                    >
                      <Delete style={{ fontSize: '1rem' }} />
                    </IconButton>
                  </Grid>
                );
              })}
          </Grid>
          <Grid item xs={7} sm={7} md={9}>
            <Grid container>
              <Grid item xs={12} style={{ padding: '1rem' }}>
                <Box display={'flex'} alignItems={'center'}>
                  <h2>
                    <b>Question Bank</b>
                  </h2>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Search with Skill, Genre, Text keyword..."
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                      }}
                      name={'searchText'}
                      onChange={handleSearchFilter}
                      value={search.searchText}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                  <Box
                    marginLeft={'auto'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Button
                      variant={'outline'}
                      color={'grey'}
                      onClick={handleHide}
                      textColor={'black'}
                    >
                      Cancel
                    </Button>
                    &nbsp;
                    <Button
                      textColor={'white'}
                      variant={'filled'}
                      onClick={() => {
                        handleSave(addedQuestions);
                        handleHide();
                      }}
                      color={colors.assignment.blue}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {questionViewer.isViewMode && questionViewer.question ? (
                  <Grid
                    container
                    style={{
                      height: height?.clientHeight - 109 || '350px',
                      overflowY: 'auto',
                      padding: '1rem'
                    }}
                  >
                    <Grid item xs={12}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="back button"
                        onClick={() =>
                          setQuestionViewer({
                            question: null,
                            isViewMode: false
                          })
                        }
                      >
                        <ArrowBackIcon />
                      </IconButton>
                      <SearchResultItem
                        result={questionViewer?.question}
                        genres={genres}
                        skills={skills}
                        handleAddQuestion={handleAddQuestion}
                        handleDeleteQuestion={handleDeleteQuestion}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container style={{ padding: '1rem' }}>
                    <Grid item xs={12}>
                      <span style={{ color: colors.assignment.lightgrey }}>
                        {searchData.total | 0} results
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: height?.clientHeight - 109 || '350px',
                        overflowY: 'auto'
                      }}
                    >
                      <SearchResultList
                        total={searchData.total}
                        genres={genres}
                        nextPage={handleNextPage}
                        handleAddQuestion={handleAddQuestion}
                        handleDeleteQuestion={handleDeleteQuestion}
                        skills={skills}
                        loading={loading}
                        results={searchData.result}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </ModalAbstract>
  );
};

export default QuestionBankModal;
