import React, { useState, useEffect } from 'react';
import { userTypes, userTypesDashboardRoute } from '../../constants/users';
import { useSelector, useDispatch } from 'react-redux';
import { referralOptions } from "../../constants/users";
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

interface ReferrerSelectionProps {
  handleValueChange: (value: any) => any;
  showError?: any;
}

const ReferrerSelection: React.FC<ReferrerSelectionProps> = (props: ReferrerSelectionProps) => {
  const userData = useSelector((state: any) => state.userData);
  const dispatch = useDispatch();
  const [values, setValue] = useState({
    referrerType: "",
    referrerText: "",
    referrerTextChanged: false
  });
  
  enum ErrorMessages {
    selectSocialMedia =
      "Please select the correct social media option",
    notTyped = "Please fill in the textbox",
    notSelected = "Please select an option",
  }
  const [error, setError] = useState({
    message: '',
    messageDropDown: '',
    referrer: false,
    refDropDown:false,
  });

  useEffect(() => {
    if (props.showError.referrer === true){
      error.message = ErrorMessages.notSelected;
      error.referrer= true;
      setError( { ...error } );
    } else if ( props.showError.referrerDropDown === true){
      error.refDropDown = true;
      if (values.referrerType === "Social Media"){
        error.messageDropDown = ErrorMessages.selectSocialMedia
      } else {
        error.messageDropDown = ErrorMessages.notTyped
      }
      setError( { ...error } );
    } 
  }, [props.showError]);

  useEffect(() => {
    error.refDropDown = false;
    error.referrer = false;
    error.message = '';
    error.messageDropDown='';
    setError({ ...error });
  }, [values.referrerType, values.referrerText]);

  const [referrerDropdownIsValid, setReferrerDropdownIsValid] = useState(true);

  useEffect(() => {
    if (referrerDropdownIsValid) {
      props.handleValueChange(values);
    }
  }, [values]);

  const renderReferralOptions = () => {
    switch (values.referrerType) {
      case "Email":
      case "Other":
      case "Conference":
        const label = values.referrerType === "Conference"
          ? "Conference Name"
          : "Tell Us More?";
        return (
          <Grid item xs={12}>
            <TextField
              name="referrerText"
              label={label}
              value={values.referrerText}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              error={error.refDropDown}
              helperText={error.refDropDown
                ? error.messageDropDown
                : ""}
              autoComplete="new-referrer"
              fullWidth
            />
          </Grid>
        );
      case "Social Media":
        return (
          <Grid item xs={12}>
            <FormControl error={error.refDropDown} variant="outlined" fullWidth>
              <InputLabel>Select Social Network</InputLabel>
              <Select
                fullWidth
                value={values.referrerText}
                onChange={handleChange}
                inputProps={{ name: "referrerText" }}
              >
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="Twitter">Twitter</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
                <MenuItem value="TikTok">TikTok</MenuItem>
                <MenuItem value="Pinterest">Pinterest</MenuItem>
                <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                <MenuItem value="Youtube">Youtube</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              <FormHelperText>{error.messageDropDown}</FormHelperText>
            </FormControl>
          </Grid>
        );
      case "Referral":
        return (
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Referral</InputLabel>
              <Select
                fullWidth
                value={values.referrerText || "Teacher"}
                onChange={handleChange}
                inputProps={{ name: "referrerText" }}
              >
                <MenuItem value="Teacher">Teacher</MenuItem>
                <MenuItem value="Parent">Parent</MenuItem>
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="School Admin">School Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      default:
        return null;
    }
  };

  const referrerIsValid = (type: string, text: string) => {
    if (type === "Social Media") {
      return  !["", "- Select Social Network -"].includes(text);
    }
    return true;
  }

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    const newState = {
      ...values,
      [name]: value,
      [`${name}Changed`]: true,
    };
    if (name === "referrerType") {
      newState.referrerText = "";
    }
    if (values.referrerTextChanged && !referrerIsValid(newState.referrerType, newState.referrerText)) {
      setReferrerDropdownIsValid(false);
    } else {
      setReferrerDropdownIsValid(true);
    }
    setValue(newState);
  };

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <FormControl variant="outlined" fullWidth error={error.referrer}>
          <InputLabel>How did you hear about us?</InputLabel>
          <Select
            fullWidth
            value={values.referrerType}
            onChange={handleChange}
            inputProps={{ name: "referrerType" }}
          >
            {referralOptions.map((option, index: number) => (
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{error.message}</FormHelperText>
        </FormControl>
      </Grid>
      {renderReferralOptions()}							
    </>
  );
};

export default ReferrerSelection;
