import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import ClassActivityReportToolIcon from '../../assets/icons/report/class-activity-icon.png';
import { ChevronLeft } from '@material-ui/icons';
import moment from 'moment';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { useHistory } from 'react-router-dom';
import SearchBar from '../../components/searchbar/SearchBar';
import Table from '../../components/table/Table';
import { groupBy } from 'lodash';
import { MODAL_SELECT_DATE_RANGE } from '../../constants/modals';
import { show } from 'redux-modal';
import { ReportToolQuestionsGraph } from '../../components/widgets/ReportToolQuestions';

interface ReportToolClassActivityProps {
  isWidget?: boolean;
}

export const ClassActivityReportTable: React.FC<any> = (props: any) => {
  const [searchState, setSearchState] = useState<Array<any>>(props.data);

  useEffect(() => {
    setSearchState(props.data); // update searchState when props.data changes
  }, [props.data]);

  return (
    <Grid item>
      <SearchBar
        originalState={props.data}
        searchKey="fullName"
        placeholder="Search player by name"
        onChange={(result: Array<any>) => setSearchState(result)}
        styles={{ container: { marginBottom: 20 } }}
      />
      <Paper>
        <Table
          columns={[
            {
              Header: `Player Name`,
              accessor: 'fullName'
            },
            {
              Header: 'Questions Answered',
              accessor: 'totalNumberOfAnswers'
            },
            {
              Header: 'Questions Answered Correctly',
              accessor: 'totalNumberOfCorrectAnswers'
            },
            {
              Header: '% Correct',
              accessor: (row: any) => {
                const { roundedAccuracy } = row;

                return roundedAccuracy + ' %';
              }
            },
            {
              Header: 'Passages Read',
              accessor: 'passageCount'
            }
          ]}
          elevation={0}
          data={searchState}
        />
      </Paper>
    </Grid>
  );
};

const NewLEClassActivityReportView: React.FC<ReportToolClassActivityProps> = ({
  isWidget
}: ReportToolClassActivityProps) => {
  const { currentClass } = useSelector((state: any) => state.class);
  const [loading, setLoading] = React.useState(false);
  const [playerIds, setPlayerIds] = useState<number[]>([]);
  const [questionCount, setQuestionCount] = useState<
    {
      userId: number;
      totalNumberOfAnswers: number;
      totalNumberOfCorrectAnswers: number;
      totalNumberOfInCorrectAnswers: number;
    }[]
  >([]);
  const [dailyCount, setDailyCount] = useState<
    {
      totalNumberOfAnswers: number;
      totalNumberOfCorrectAnswers: number;
      date: string;
    }[]
  >([]);
  const timeFrames: any = {
    last7: {
      label: 'Last 7 Days',
      from: moment()
        .subtract(7, 'days')
        .startOf('day')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    week: {
      label: 'This Week',
      from: moment()
        .startOf('week')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    month: {
      label: 'This Month',
      from: moment()
        .startOf('month')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    year: {
      label: 'This Year',
      from: moment()
        .startOf('year')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    custom: {
      label: 'Select Date Range'
    }
  };
  const [selectedOption, setSelectedOption] = useState(timeFrames.last7.label);
  const [prevSelection, setPrevSelection] = useState(timeFrames.last7);
  const [fromDate, setFromDate] = useState<Date | null>(prevSelection.from);
  const [toDate, setToDate] = useState<Date | null>(prevSelection.to);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setPlayerIds(currentClass.roster.map((user: any) => user.educationUserId));
    // setPrevSelection(timeFrames.last7);
  }, [currentClass]);

  useEffect(() => {
    // Fetch question attempts when fromDate and toDate are both set
    if (fromDate && toDate) {
      // setPrevSelection(timeFrames.last7.label);
      setLoading(true);
      const formattedFromDate = new Date(
        moment(fromDate).format('MMM DD, YYYY')
      );
      const formattedToDate = new Date(moment(toDate).format('MMM DD, YYYY'));
      const selection = `${formattedFromDate} - ${formattedToDate}`;
      setPrevSelection(selection);
      fetchQuestionAttempts(playerIds, fromDate, toDate)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setLoading(true);
      fetchQuestionAttempts(playerIds, fromDate, toDate)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [currentClass, playerIds, fromDate, toDate, prevSelection]);

  const handleSelectTimeFrame = (event: any) => {
    const selection = event.target.value;
    if (selection === timeFrames.custom.label) {
      // Open the modal to select date range
      setSelectedOption('');
      dispatch(
        show('modal', {
          type: MODAL_SELECT_DATE_RANGE,
          onConfirm: (from: Date, to: Date) => {
            setFromDate(from);
            setToDate(to);
            setLoading(true);
            fetchQuestionAttempts(playerIds, from, to)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          }
        })
      );
    } else {
      setSelectedOption(event.target.value as string);
      const timeFrame: any = Object.values(timeFrames).find(
        (timeFrame: any) => timeFrame.label === selection
      );
      if (timeFrame) {
        setPrevSelection(timeFrame);
        setFromDate(timeFrame.from);
        setToDate(timeFrame.to);
        setLoading(true);
        fetchQuestionAttempts(playerIds, timeFrame.from, timeFrame.to)
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }
  };

  async function fetchQuestionAttempts(
    learnerIds: number[],
    from: Date | null = null,
    to: Date | null = null
  ) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/learners/question-attempts`,
          method: APIMethods.POST,
          endpoint: APIEndpoints.LE_API,
          data: {
            learnerIds: learnerIds,
            from: from,
            to: to
          }
        })
      );
      const questionsData: any = response.data;

      // group filteredData by date
      const groupedDataByDate: any = groupBy(questionsData, (data: any) => {
        return moment(data.attemptDate).format('YYYY-MM-DD');
      });

      const dailyData: any[] = Object.entries(groupedDataByDate).map(
        ([date, data]: any) => {
          const totalNumberOfAnswers = data.length;
          const totalNumberOfCorrectAnswers = data.filter(
            (d: any) => d.isCorrectAnswer
          ).length;
          return {
            date,
            totalNumberOfAnswers,
            totalNumberOfCorrectAnswers
          };
        }
      );

      // sort dailyData by date in ascending order
      dailyData.sort((a: any, b: any) => moment(a.date).diff(moment(b.date)));
      setDailyCount(dailyData);

      // group questionsData by learnerId
      const groupedData: any = groupBy(questionsData, 'learnerId');

      // calculate question attempts for each learner
      const questionCount: any = Object.entries(groupedData).map(
        ([learnerId, data]: any) => {
          const totalNumberOfAnswers = data.length;
          const totalNumberOfCorrectAnswers = data.filter(
            (d: any) => d.isCorrectAnswer
          ).length;
          const accuracy = totalNumberOfAnswers
            ? (totalNumberOfCorrectAnswers / totalNumberOfAnswers) * 100
            : 0;
          const roundedAccuracy = Math.round(accuracy);
          const totalNumberOfInCorrectAnswers =
            totalNumberOfAnswers - totalNumberOfCorrectAnswers;
          const passageAttemptIds = data.map((d: any) => d.passageAttemptId);
          const uniquePassageAttemptIds = new Set(passageAttemptIds);
          const passageCount = uniquePassageAttemptIds.size;
          return {
            userId: +learnerId,
            totalNumberOfAnswers,
            totalNumberOfCorrectAnswers,
            totalNumberOfInCorrectAnswers,
            roundedAccuracy,
            passageCount
          };
        }
      );

      // Sort questionCount array based on accuracy in descending order
      questionCount.sort(
        (a: any, b: any) => b.roundedAccuracy - a.roundedAccuracy
      );

      //adding player names to result
      const getPlayerNames: any = questionCount.map((learner: any) => ({
        ...learner,
        fullName: currentClass.roster.find(
          (player: any) => player.educationUserId === learner.userId
        ).fullname
      }));
      setQuestionCount(getPlayerNames);
    } catch (err) {
      console.log('error', err);
    }
  }

  return (
    <>
      <div style={{ paddingTop: '46px', padding: '25px' }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: '20px' }}
        >
          <Grid item xs={12}>
            <Button
              startIcon={<ChevronLeft />}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h3">Class Activity Report</Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Select Date Range</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleSelectTimeFrame}
                label="Select Date Range"
              >
                {Object.values(timeFrames).map((timeFrame: any) => (
                  <MenuItem key={timeFrame.label} value={timeFrame.label}>
                    {timeFrame.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {!loading ? (
          <>
            {questionCount.length &&
            !questionCount.every(item => item.totalNumberOfAnswers === 0) ? (
              <Grid item style={{ width: '100%' }}>
                <Paper>
                  <ReportToolQuestionsGraph data={dailyCount} />
                </Paper>

                <div style={{ marginTop: 20 }}>
                  <ClassActivityReportTable data={questionCount} />
                </div>
              </Grid>
            ) : (
              <>
                <div style={{ textAlign: 'center', paddingTop: '35px' }}>
                  <img
                    src={ClassActivityReportToolIcon}
                    style={{ height: '120px', width: '120px' }}
                  ></img>
                </div>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '14px',
                    paddingTop: '35px'
                  }}
                >
                  {currentClass.roster.length
                    ? 'There is no player data for this time period. Get your players to use Dreamscape in class or at home!'
                    : 'Add a player to see reports'}
                </Typography>
              </>
            )}
          </>
        ) : (
          <>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography>Loading data</Typography>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default NewLEClassActivityReportView;
