import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_UPDATE_TOKEN
} from './login-action-types';

export const loginReducer = (
  state: {
    token?: string;
    errorMessage?: string;
  } = {},
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        token: payload.token
      };
    case LOGIN_FAILED:
      return {
        ...state,
        ...payload
      };
    case LOGIN_UPDATE_TOKEN:
      return {
        ...state,
        token: payload.token
      };
    default:
      return state;
  }
};
