import React from "react";
import { Pie } from 'react-chartjs-2';
import ChartDataLabel from 'chartjs-plugin-datalabels';

const QuestionBreakDownPieChart = (props: any) => {
  const chartData = props.data;
  return (
    <Pie
      data={chartData}
      height={200}
      plugins={[ChartDataLabel]}
      options={{
        legend: {
          display: true,
          fullWidth: false,
          position: 'bottom',
        },
        plugins:
          {
            datalabels: {
              display: true,
              color: function() {
                return "#FFF";
              },
              font: function(context: any) {
                const width = context.chart.width;
                // @ts-ignore
                const size = Math.round(width / 28);
                return {
                  weight: 'bold',
                  size: size
                }
              },
              formatter: function(value: any, context: any) {
                const data = context.chart.data.datasets[0].data;
                const currentData = data[context.dataIndex]
                if (currentData == 0) {
                  return null;
                }
                let frequency = 0;
                data.forEach((currentVal: any) => {
                  if (currentVal > 0) {
                    frequency += currentVal;
                  }
                })

                const percentage = currentData / frequency * 360;
                return `${currentData} (${Math.floor(percentage/360 * 100)}%)`;
              }
            }
          }
      }}
    />
  );
}

export default QuestionBreakDownPieChart
