const namespace = '[Chuck]';

export const CHUCK_TOGGLE_GIFT = `${namespace} CHUCK_TOGGLE_GIFT`;
export const CHUCK_SHOW_BUBBLE = `${namespace} CHUCK_SHOW_BUBBLE`;
export const CHUCK_HIDE_BUBBLE = `${namespace} CHUCK_HIDE_BUBBLE`;
export const CHUCK_WAS_INTERACTED = `${namespace} CHUCK_WAS_INTERACTED`;
export const CHUCK_RESET_STATE = `${namespace} CHUCK_RESET_STATE`;
export const CHUCK_COMPLETE_TASK = `${namespace} CHUCK_COMPLETE_TASK`;
export const CHUCK_TASKS_COMPLETED = `${namespace} CHUCK_TASKS_COMPLETED`;
export const CHUCK_INTRODUCED = `${namespace} CHUCK_INTRODUCED`;
export const CHUCK_RESET_COMPLETED_TASKS = `${namespace} CHUCK_RESET_COMPLETED_TASKS`;
