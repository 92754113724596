import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { cancelMembership } from '../../../redux/actions/player-actions';
import moment from 'moment';

const cancellationReasons = [
  'My player is no longer using Dreamscape',
  "I don't see the value of the membership",
  "We've had technical issues that have made it difficult to access the game",
  "I don't want my membership to renew automatically"
];

interface ModalCancelMembershipProps {
  show: () => void;
  handleHide: () => void;
  isCancelPage: boolean;
  membershipData: any;
}

const ModalCancelMembership: React.FC<ModalCancelMembershipProps> = (
  props: ModalCancelMembershipProps
) => {
  const { show, handleHide, membershipData, isCancelPage } = props;
  const dispatch = useDispatch();
  const [cancellationReason, setCancellationReason] = useState(
    cancellationReasons[0]
  );
  const userData = useSelector((state: any) => state.userData);
  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Cancel Membership</DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 10 }}>
          Why are you cancelling this <em>Dreamscape</em> Membership?
        </Typography>
        <Select
          fullWidth
          variant="outlined"
          value={cancellationReason}
          onChange={(event: any) => setCancellationReason(event.target.value)}
        >
          {cancellationReasons.map((reason, index) => (
            <MenuItem value={reason} key={index}>
              {reason}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button
          primary
          onClick={() =>
            dispatch(
              cancelMembership(
                {
                  userId: userData._id,
                  cancellationReason,
                  subscriptionId: (isCancelPage) ? membershipData._id : membershipData.subMeta._id,
                  playerId: (isCancelPage) ? membershipData.playerIds : membershipData._id,
                  firstName: (isCancelPage) ? membershipData.createdBy.firstname : '',
                  email: (isCancelPage) ? membershipData.createdBy.email: '',
                  isCancelPage: (isCancelPage) ? true : false
                },
                moment((isCancelPage) ? membershipData.expiryDate : membershipData.subMeta.expiryDate).format('MMM Do YYYY')
              )
            )
          }
        >
          Cancel Membership
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalCancelMembership;
