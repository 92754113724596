import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { hide, show } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import 'react-dates/initialize';
import { hide as hideModal, show as showModal } from 'redux-modal';
import 'react-dates/lib/css/_datepicker.css';
import { useDispatch } from 'react-redux';
import membershipsIcon from '../../../assets/membership/assignMmebershipsIcon.png';
import { MODAL_MEMBERSHIP_PURCHASE_SUCCESS } from '../../../constants/modals';
import { updateUserPremiumData } from '../../../redux/actions/user-actions';
import { useAppSelector } from '../../../redux/hooks';
import { fetchApi } from '../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../types/fetch/fetch-types';
import { AxiosResponse } from 'axios';
import { refreshToken } from '../../../redux/login/login-actions';
import { trackEducatorMembershipPurchase } from '../../../redux/actions/mixpanel-actions';
import moment from 'moment';
import { push } from 'react-router-redux';
import { userNavigateWithClassCode } from '../../../utils/user-route-getter';
import { teacherRoutes } from '../../../constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

type ModalUpgradePreviewProps = {
  handleHide: () => void;
  memberhsipType: any;
  memberships: any;
  totalCostBeforeTax: any;
};

const ModalUpgradePreview: React.FC<ModalUpgradePreviewProps> = (
  props: ModalUpgradePreviewProps
) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const userData = useAppSelector(state => state.userData);

  const { handleHide } = props;
  const currentDate = moment().startOf('day');
  const planExpiry = moment(userData.premium.expiryDate);
  const daysLeft = moment
    .duration(planExpiry.diff(currentDate))
    .asDays()
    .toFixed(0);

  const daysLeftInPlan = () => {
    if (!userData.premium.isPremium) {
      return '365 days';
    } else {
      return Number(daysLeft) - 1 + ' days';
    }
  };

  const handleContinue = () => {
    trackEducatorMembershipPurchase(
      userData.premium.type,
      'Premium',
      props.memberships,
      daysLeftInPlan(),
      '$' + props.totalCostBeforeTax,
      userData.premium.totalMemberships
    );
    const membershipType = props.memberhsipType;
    props.handleHide();
    dispatch(hide('modal'));
    dispatch(show('spinner', { text: 'Processing purchase...' }));
    dispatch(
      fetchApi({
        url: 'stripe/upgrade',
        method: APIMethods.POST,
        data: {
          membershipType,
          memberships: props.memberships
        }
      })
    )
      .then((response: AxiosResponse) => {
        dispatch(
          push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
        );
        dispatch(
          show('snackbar', {
            variant: 'success',
            message: 'Membership purchase is successful',
            autoHideDuration: 1700
          })
        );
        const { updatedUserPremiumData } = response.data;
        dispatch(refreshToken());
        dispatch(updateUserPremiumData(updatedUserPremiumData));
        dispatch(hide('spinner'));
        const sendProps: any = {
          type: MODAL_MEMBERSHIP_PURCHASE_SUCCESS,
          newMembershipCount: props.memberships,
          existingMembershipCount: userData.premium.totalMemberships
        };
        dispatch(showModal('modal', sendProps));
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(show('snackbar', { variant: 'error', message: err.message }));
        dispatch(hide('spinner'));
      });
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Confirm your purchase of player memberships
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <img
          src={membershipsIcon}
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100px',
            height: '130px'
          }}
        ></img>
        <Divider></Divider>
        <br />
        <Typography>
          <b>Total after tax: ${props.totalCostBeforeTax}</b>
        </Typography>
        <br />
        <Typography>
          Note: By clicking Pay now ${props.totalCostBeforeTax} will be charged
          to the same card used for your previous purchase.
        </Typography>
        <Button
          style={{
            backgroundColor: '#3FD2D9',
            color: 'white',
            marginTop: '2px',
            fontSize: '1.1rem',
            position: 'relative',
            left: 420
          }}
          onClick={handleContinue}
        >
          Pay now
        </Button>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalUpgradePreview;
