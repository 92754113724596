import DashboardIcon from '@material-ui/icons/Dashboard';
import { Create, Note } from '@material-ui/icons';
import WorkIcon from '@material-ui/icons/Work';
import BookIcon from '@material-ui/icons/MenuBook';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import { schoolAdminRoutes, commonRoutes } from '../../../constants/routes';
import { SidebarItems } from '../../../types/sidebar/sidebar-type';
import store from '../../../redux/store';
import { mobileSideItems } from './navbar-mobile';

const disableButton = () => {
  return (
    //@ts-ignore
    !store.getState().userData.allowedByAdmin ||
    //@ts-ignore
    store.getState().userData.allowedByAdmin.currentRule === false
  );
};

const userCanCreateContent = () => {
  const { userCanCreateContent }: any = store.getState().userData;
  return userCanCreateContent === true;
};

export default (path: string): Array<SidebarItems> => {
  if (
    [
      schoolAdminRoutes.homeScreen,
      schoolAdminRoutes.createAccounts,
      schoolAdminRoutes.training,
      schoolAdminRoutes.membershipsAll,
      schoolAdminRoutes.myAccount,
      schoolAdminRoutes.resourceHub,
      schoolAdminRoutes.resourceHubInfo
    ].includes(path)
  ) {
    return [
      {
        title: "Teacher's List",
        icon: DashboardIcon,
        to: schoolAdminRoutes.homeScreen,
        disabled: disableButton()
      },
      {
        title: 'Create Accounts',
        icon: Create,
        to: schoolAdminRoutes.createAccounts,
        disabled: disableButton()
      },
      {
        title: 'Training',
        icon: WorkIcon,
        to: schoolAdminRoutes.training,
        disabled: disableButton()
      },
      {
        title: 'Resource Hub',
        icon: BookIcon,
        to: schoolAdminRoutes.resourceHub,
        disabled: disableButton()
      },
      {
        title: 'Create Content',
        icon: Note,
        to: commonRoutes.createContentRedirect,
        show: userCanCreateContent()
      },
      ...mobileSideItems
    ];
  }
  if (
    [
      schoolAdminRoutes.teacherClass,
      schoolAdminRoutes.teacherStudents,
      schoolAdminRoutes.teacherPlanner,
      schoolAdminRoutes.teacherReports,
      schoolAdminRoutes.teacherReadingLevelReport,
      schoolAdminRoutes.teacherPlannerReport,
      schoolAdminRoutes.teacherSkillComprehensionReport,
      schoolAdminRoutes.teacherAssignmentReport,
      schoolAdminRoutes.teacherPlacementTestReport,
      schoolAdminRoutes.classActivityReport,
      schoolAdminRoutes.newLEClassActivityReport,
      schoolAdminRoutes.leaderboardReport,
      schoolAdminRoutes.newLEleaderboardReport
    ].includes(path)
  ) {
    return [
      {
        title: 'Back to Class List',
        icon: ArrowBackIcon,
        to: schoolAdminRoutes.teacherClassList,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        to: schoolAdminRoutes.teacherClass,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Players',
        icon: PersonIcon,
        to: schoolAdminRoutes.teacherStudents,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Assignments',
        icon: CalendarTodayIcon,
        to: schoolAdminRoutes.teacherPlanner,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Reports',
        icon: AssessmentIcon,
        to: schoolAdminRoutes.teacherReports,
        disabled: disableButton()
      },
      ...mobileSideItems
    ];
  }
  if ([schoolAdminRoutes.teacherClassList].includes(path)) {
    return [
      {
        title: "Teacher's List",
        icon: ArrowBackIcon,
        to: schoolAdminRoutes.homeScreen,
        params: true,
        disabled: disableButton()
      },
      ...mobileSideItems
    ];
  }
  return [];
};
