import {
  USER_ADMIN_GET_TEACHERS,
  USER_ADMIN_SET_CURRENT_TEACHER,
  USER_ADMIN_GET_SCHOOLS,
  USER_ADMIN_SET_CURRENT_SCHOOL
} from '../types/user/user-action-type';

export const adminReducer = (
  state: any = {
    teachers: [],
    currentTeacher: {
      _id: ''
    },
    currentSchool: {
      _id: ''
    },
    schools: [],
    district: {}
  },
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case USER_ADMIN_GET_TEACHERS:
      return {
        ...state,
        teachers: payload
      };

    case USER_ADMIN_SET_CURRENT_TEACHER:
      return {
        ...state,
        currentTeacher: payload
      };

    case USER_ADMIN_GET_SCHOOLS:
      return {
        ...state,
        ...payload
      };

    case USER_ADMIN_SET_CURRENT_SCHOOL:
      return {
        ...state,
        currentSchool: payload
      };
    default:
      return state;
  }
};
