import React, { useState, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Select,
  MenuItem,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { pauseMembership } from '../../../redux/actions/members-actions';
import moment, { Moment } from 'moment';
import { Close } from '@material-ui/icons';
import { playerNounLowerCase } from '../../../utils/userNoun';

interface ModalPauseMembershipProps {
  show: () => void;
  handleHide: () => void;
  membershipData: any;
}

enum MembershipPauseDate {
  OneMonth = 1,
  TwoMonths = 2
}

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  text: {
    fontSize: '13px !important',
    marginTop: 10
  }
}));

const ModalPauseMembership: React.FC<ModalPauseMembershipProps> = (
  props: ModalPauseMembershipProps
) => {
  const { show, handleHide, membershipData } = props;
  const [date, setDate] = useState<MembershipPauseDate>(
    MembershipPauseDate.OneMonth
  );
  const [nextExpiryDate, setNextExpiryDate] = useState<Moment>(moment());
  const [resumeDate, setResumeDate] = useState<Moment>(moment());
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const expiryDate = moment(membershipData.subMeta.expiryDate);
    const _resumeDate = moment().add(date, 'months');
    const nextBillingDate = expiryDate.add(date, 'months');
    setNextExpiryDate(nextBillingDate);
    setResumeDate(_resumeDate);
  }, [date]);

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Membership Pause
        <IconButton className={classes.closeButton} onClick={handleHide}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          For how long would you like to pause this membership?
        </Typography>

        <Select
          fullWidth
          value={date}
          variant="outlined"
          onChange={(event: any) => setDate(event.target.value)}
          style={{ marginTop: 20 }}
        >
          <MenuItem value={MembershipPauseDate.OneMonth}>1 month</MenuItem>
          <MenuItem value={MembershipPauseDate.TwoMonths}>2 months</MenuItem>
        </Select>

        <Typography className={classes.text}>Details:</Typography>
        <Typography className={classes.text}>
          The membership will be removed from the {playerNounLowerCase}'s account today and
          will be automatically given back on {resumeDate.format('ll')}.
          Payments for the membership will resume on{' '}
          {nextExpiryDate.format('ll')} and your card will continue to be
          charged monthly.
        </Typography>
        <Typography className={classes.text}>
          At any time, you can cancel your membership on your dashboard. If you
          cancel, your membership will continue to be active for the period that
          you paid for. Refunds will not be provided
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          primary
          onClick={() => dispatch(pauseMembership(membershipData, date))}
        >
          Confirm
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalPauseMembership;
