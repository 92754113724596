import { USER_ADMIN_SET_ADMIN_VERIFICATION_DATA } from '../types/user/user-action-type';

export const adminVerificationReducer = (
  state: any = {},
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case USER_ADMIN_SET_ADMIN_VERIFICATION_DATA:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};