import NoLayout from '../layouts/NoLayout';
import ErrorNotFound from '../views/error/ErrorNotFound';

export default [
  {
    path: '*',
    layout: NoLayout,
    component: ErrorNotFound
  }
];
