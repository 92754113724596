import React, { useState } from 'react';
import Button from '../../button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Avatar, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const ModalViewAdmin: React.FC = (props: any) => {
  const {admins} = props;
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleHide}
        maxWidth="md"
      >
        <DialogContent style={{ padding: 20 }} dividers={true}>
          <Grid container justify={"center"}>
           <Grid item xs={12}>
             <h3>Admin emails</h3>
           </Grid>
            <Grid item xs={12}>
              {admins.length === 0 && <p>No emails.</p>}
              <List component="nav" aria-label="main mailbox folders">
                {admins.map((admin: any) => {
                  return (
                    <ListItem>
                      <ListItemIcon>
                        <Avatar>
                          {admin.email[0].toUpperCase()}
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText primary={admin.email} />
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
              <Button onClick={props.handleHide} primary>
                Close
              </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalViewAdmin;
