import React, { PropsWithChildren } from 'react';
import ModalAbstract from '../ModalAbstract';
import {
  Card,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '../../../../enums/UserType';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../../types/fetch/fetch-types';
import { AxiosResponse } from 'axios';
import { push } from 'react-router-redux';
import Button from '../../../button/Button';
import { Close, Lock } from '@material-ui/icons';
import { show as ReduxModal } from 'redux-modal';
import DialogActions from '@material-ui/core/DialogActions';
import colors from '../../../../styles/colors';

export interface ModalAssignRewardsProps {
  classCode: string;
  show?: boolean;
  callback(): any;
  playerId: string;
  handleHide?: any;
}

const useStyles = makeStyles(() => ({
  actions: {
    padding: '.7rem 1rem'
  },
  alignMiddle: {
    display: 'flex',
    alignItems: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  textfieldMinWidth: {
    minWidth: 550
  },
  rewardDefault: {
    padding: '1rem',
    textAlign: 'center',
    cursor: 'pointer',
    minHeight: 260
  },
  rewardSelected: {
    padding: '1rem',
    textAlign: 'center',
    backgroundColor: colors.primary,
    cursor: 'pointer',
    minHeight: 260
  }
}));

const ModalAssignRewards: React.FC<ModalAssignRewardsProps> = (
  props: PropsWithChildren<ModalAssignRewardsProps>
) => {
  const { show, playerId, classCode, callback, handleHide } = props;
  const dispatch = useDispatch();
  const [rewards, setRewards] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const userData = useSelector((state: any) => state.userData);
  const currentUserType = userData.userType;
  const [rewardsDisabled, setRewardsDisabled] = React.useState(false);
  const classes = useStyles();
  const [message, setMessage] = React.useState('');
  const [rewardSelected, setRewardSelected] = React.useState('');
  const [assignDisabled, setAssignDisabled] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    dispatch(
      fetchApi({
        url: 'rewards',
        method: APIMethods.GET
      })
    )
      .then((response: AxiosResponse) => {
        const { data } = response;
        const filtered_rewards = data.rewards.filter(
          (element: any) => element.isForDashboard === true
        );
        setRewards(filtered_rewards);
        setLoading(false);
      })
      .catch(() => {
        dispatch(
          push('snackbar', {
            variant: 'error',
            message: 'Error getting rewards'
          })
        );
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (rewardSelected !== '') {
      setAssignDisabled(false);
    } else {
      setAssignDisabled(true);
    }
  }, [rewardSelected]);

  const handleReward = (assetId: string) => {
    setRewardSelected(assetId);
  };

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleAssignReward = (assetId: string, message: string) => {
    const requestAssign = {
      rewardId: assetId,
      message: message,
      playerIds: Array.isArray(playerId) ? playerId : [playerId],
      classCode: classCode
    };

    dispatch(
      fetchApi({
        url: `rewards/assign/`,
        data: requestAssign,
        method: APIMethods.POST
      })
    )
      .then(() => {
        setRewardsDisabled(true);
        callback();
        dispatch(
          ReduxModal('snackbar', {
            variant: 'success',
            message: 'Reward assigned'
          })
        );
      })
      .catch(() => {
        dispatch(
          ReduxModal('snackbar', {
            variant: 'error',
            message: 'Error assigning reward'
          })
        );
      });
  };

  return (
    <ModalAbstract show={show}>
      <DialogTitle>
        Assign Rewards{' '}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleHide()}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {/*TODO - reward system*/}
        {loading ? (
          <Grid container justify={'center'}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {rewards.map((reward: any) => {
              return (
                <Tooltip
                  title={reward._id !== rewardSelected ? 'Select Reward' : ''}
                >
                  <Grid key={reward._id} item xs={12} sm={4}>
                    <AssignRewardCard
                      disabled={rewardsDisabled}
                      rewardSelected={rewardSelected}
                      handleAssign={handleReward}
                      reward={reward}
                    />
                  </Grid>
                </Tooltip>
              );
            })}
          </Grid>
        )}
        <Grid container style={{ paddingTop: 20 }}>
          <TextField
            label="Reward Message"
            placeholder={
              currentUserType === UserType.Teacher
                ? 'Your teacher just sent you this reward!'
                : 'Your parent just sent you this reward!'
            }
            multiline
            value={message}
            onChange={handleChange}
            inputProps={{
              maxLength: 140
            }}
            rows={1}
            rowsMax={3}
            className={classes.textfieldMinWidth}
          />
        </Grid>
        <DialogActions>
          <Tooltip
            title={
              assignDisabled
                ? 'Please Select one of the above threee rewards to assign.'
                : ''
            }
          >
            <Grid>
              <Button
                primary
                disabled={assignDisabled}
                onClick={() => {
                  handleAssignReward(rewardSelected, message);
                  handleHide();
                }}
              >
                Assign Reward
              </Button>
            </Grid>
          </Tooltip>
        </DialogActions>
      </DialogContent>
    </ModalAbstract>
  );
};

const AssignRewardCard = (props: any) => {
  const { name, _id: id, imgUrl } = props.reward;
  const { rewardSelected, handleAssign, disabled } = props;
  const classes = useStyles();
  return (
    <Card
      className={
        rewardSelected === id ? classes.rewardSelected : classes.rewardDefault
      }
      onClick={() => {
        handleAssign(id);
      }}
    >
      <Tooltip
        title={disabled ? 'You have already assigned a reward this month.' : ''}
      >
        <Grid container key={id} spacing={2}>
          <Grid item xs={12}>
            <img src={imgUrl} height={'100%'} width={'100%'} alt={name} />
          </Grid>
          <Grid item xs={12}>
            <em>{name}</em>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Tooltip>
    </Card>
  );
};

export default ModalAssignRewards;
