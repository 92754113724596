import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  TextField,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { userTypes } from '../../../constants/users';
import { sendTrainingEmail } from '../../../redux/actions/training-actions';

const ModalTraining: React.FC = (props: any) => {
  const { show, handleHide } = props;
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const [capacity, setCapacity] = useState(null);
  const initialError = {
    phoneError: false,
    capacityError: false
  };
  const [errors, setErrors] = useState(initialError);
  const handleFieldSelect = (event: any) => {
    setCapacity(event.target.value);
  };
  const [phoneNumber, setPhoneNumber] = useState('');
  const validatePhoneNumber = (phoneNum: any) => {
    return validator.isMobilePhone(phoneNum);
  };
  const handleChange = (event: any) => {
    if (!validatePhoneNumber(event.target.value)) {
      setErrors({
        ...errors,
        phoneError: true
      });
    } else {
      setErrors(initialError);
    }
    setPhoneNumber(event.target.value);
  };

  const handleSendEmail = () => {
    if (validatePhoneNumber(phoneNumber) && capacity) {
      setErrors(initialError);
      const recipient: any = {};
      if (capacity === 'tim') {
        recipient.to = 'James';
        recipient.toEmail = 'james@squigglepark.com';
      } else if (capacity === 'james') {
        recipient.to = 'James';
        recipient.toEmail = 'james@squigglepark.com';
      } else {
        recipient.to = 'Samantha';
        recipient.toEmail = 'samantha@squigglepark.com';
      }
      const emails = {
        districtName:
          // userData.userType === userTypes.districtAdmin
          //   ? district.district.name
          userData.schools[0].name,
        email: userData.email,
        name: `${userData.firstName} ${userData.lastName}`,
        phoneNumber,
        ...recipient
      };
      dispatch(sendTrainingEmail(emails));
    } else {
      if (!validatePhoneNumber(phoneNumber)) {
        setErrors({
          ...errors,
          phoneError: true
        });
      }
      if (!capacity) {
        setErrors({
          ...errors,
          capacityError: true
        });
      }
    }
  };

  const getOptions = () => {
    if (userData.userType === userTypes.schoolAdmin) {
      return [
        {
          label: 'More than 5',
          value: 'samantha'
        },
        {
          label: 'Less than 5',
          value: 'samantha5'
        }
      ];
    }
    return [
      {
        label: 'More than 25',
        value: 'james'
      },
      {
        label: 'Less than 25',
        value: 'tim'
      }
    ];
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Book Dreamscape Training</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              label="Name"
              defaultValue={`${userData.firstName} ${userData.lastName}`}
              style={{ marginBottom: 15 }}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <TextField
              label="Email"
              variant="outlined"
              defaultValue={userData.email}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl error={errors.phoneError} fullWidth>
              <TextField
                name="phoneNumber"
                onChange={handleChange}
                label="Phone Number"
                variant="outlined"
              />
              <FormHelperText>
                {errors.phoneError && 'Please enter valid phone number'}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={errors.capacityError}
              variant="outlined"
            >
              <InputLabel>Capacity</InputLabel>
              <Select
                placeholder="Capacity"
                value={capacity}
                onChange={handleFieldSelect}
                fullWidth
              >
                {getOptions().map(option => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors.capacityError && 'Please select capacity'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Cancel</Button>
        <Button primary onClick={handleSendEmail}>
          Book training
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalTraining;
