import React from 'react';
import { useState, useEffect } from 'react';
import {
  Grid,
  Divider,
  Typography,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField
} from '@material-ui/core';
import { Button, Paper } from '@material-ui/core';
import View from '../../../../components/view/View';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import { premiumPlusBreakDownData } from './premium-plus-breakdown-data';
import { useAppSelector } from '../../../../redux/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { hide, show } from 'redux-modal';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../../types/fetch/fetch-types';
import { AxiosError, AxiosResponse } from 'axios';
import { hide as hideModal, show as showModal } from 'redux-modal';
import { trackBuyClickPremiumPlan } from '../../../../utils/google/google-analytics';
import { trackStripePaymentClicked } from '../../../../redux/actions/mixpanel-actions';
import moment from 'moment';
import useQueryParams from '../../../../hooks/UseQueryParams';
import { MODAL_UPGRADE_PREVIEW } from '../../../../constants/modals';
import mixpanel from '../../../../utils/mixpanel';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  root: {
    paddingTop: '10px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    },

    [theme.breakpoints.down('xl')]: {
      paddingLeft: '4rem',
      paddingRight: '4rem'
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '2rem',
      paddingRight: '0.1rem'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '9rem',
      paddingRight: '5rem'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  gridRadio: {
    padding: '10px',
    paddingBottom: '20px'
  },
  paymentButton: {
    backgroundColor: '#3FD2D9',
    color: 'white',
    '&:hover': {
      background: '#3FD2D9'
    }
  },
  table: {
    minWidth: 400,
    minHeight: 250,
    borderCollapse: 'separate'
  },
  container: {
    paddingTop: '10px',
    marginRight: '25px',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
      display: 'block',
      overflowX: 'auto'
    }
  },

  tr: {
    backgroundColor: '#E5E4E2'
  },
  td: {
    height: '30px !important',
    padding: '1px !important',
    textalign: 'center !important',
    fontSize: '11px'
  },
  textBox: {
    borderRadius: '0',
    margin: '10px 0',
    width: '55px',
    height: '48px'
  },
  freeTextBox: {
    borderRadius: '0',
    margin: '10px 0',
    width: '55px',
    height: '30px',
    position: 'relative',
    top: '11px'
  },
  radioText: {
    display: 'inline',
    position: 'relative',
    top: '4px'
  },
  radioText1: {
    display: 'inline',
    position: 'relative',
    bottom: '8px'
  },
  radioText2: {
    display: 'inline',
    position: 'relative',
    top: '2px'
  },
  proratedTag: {
    fontWeight: 'bolder',
    textAlign: 'center',
    backgroundColor: '#CFFDFF',
    borderRadius: 10,
    fontSize: '0.8rem',
    alignItems: 'center',
    height: '19px',
    width: '80px',
    position: 'relative',
    left: '345px',
    bottom: '76px'
  }
}));

const MembershipCheckout = (props: { isOnboardingStep?: boolean }) => {
  const styles = useStyles({});
  const history = useHistory();
  const dispatch = useDispatch();
  function createData(memberships: any, price: any, total: any) {
    return { memberships, price, total };
  }
  const userData = useAppSelector(state => state.userData);
  const membershipData = useAppSelector(state => state.members);
  const { nonMembers } = membershipData;
  const classData = useAppSelector(state => state.class);
  const { classes } = classData;
  const { premium } = userData;
  const isPremiumUser = premium && premium?.isPremium;
  const membershipType = premium?.type;
  const [isFreeTrial, setFreeTrial] = useState(false);
  const featureFlag = useSelector((state: any) => state.featureFlag);

  const [premiumSelected, setPremiumSelected] = useState({
    id: 0,
    memberships: 0,
    totalPlanCost: 29,
    premium: 29,
    totalCost: 29,
    savings: 0
  });
  const [premiumUpgradeSelected, setPremiumUpgradeSelected] = useState({
    planCost: 0,
    savings: 0,
    total: 0
  });

  const currentDate = moment().startOf('day');
  const planExpiry = moment(premium?.expiryDate);
  const daysLeft = moment
    .duration(planExpiry.diff(currentDate))
    .asDays()
    .toFixed(0);
  const archivedClasses = classes.filter((a: any) => a.archived == true);

  const rows = [
    createData(1, 60, 60),
    createData(10, 10, 100),
    createData(20, 7, 140),
    createData(30, 6, 180),
    createData(40, 5, 200)
  ];

  let selectedValue = '';
  let savingsCost = 0;
  let planCost = 0;
  let unarchivedNonMembersips = [];
  const [enteredMembership, setEnteredMemberships] = useState('');
  const query = useQueryParams();

  useEffect(() => {
    if (featureFlag.newRegistrationFlow) {
      dispatch(
        fetchApi({
          url: 'stripe/billing',
          method: APIMethods.GET
        })
      )
        .then((result: AxiosResponse) => {
          const { billing } = result.data;
          const { activePlan } = billing;
          if (activePlan.price === 0 && featureFlag.newRegistrationFlow) {
            setFreeTrial(true);
          }
        })
        .catch((err: AxiosError) => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    const archivedUsers: string[] = [];
    if (archivedClasses) {
      const archivedUsersData = archivedClasses.map((item: any) => item.roster);
      archivedUsersData.forEach((element: any) => {
        element.forEach((item: any) => {
          archivedUsers.push(item.userId);
        });
      });
    }
    unarchivedNonMembersips = nonMembers?.filter(function(obj: any) {
      return !archivedUsers.some(function(obj2: any) {
        return obj._id == obj2;
      });
    });
    if (query.reDirect === 'plan') {
      setEnteredMemberships('');
    } else {
      if (unarchivedNonMembersips?.length) {
        setEnteredMemberships(unarchivedNonMembersips?.length.toString());
      } else {
        setEnteredMemberships('1');
      }
    }
  }, [classes, membershipData]);

  useEffect(() => {
    if (!premium?.isPremium) {
      if (parseInt(enteredMembership) <= 40) {
        const priceValues: any = premiumPlusBreakDownData.find(
          plan => plan.memberships === parseInt(enteredMembership)
        );
        if (priceValues) {
          setPremiumSelected(priceValues);
        }
      } else if (parseInt(enteredMembership) > 40) {
        const membershipCost = parseInt(enteredMembership) * 5;
        const totalCost = membershipCost + 29;
        const priceValues: any = {
          id: 41,
          memberships: enteredMembership,
          totalPlanCost: membershipCost,
          premium: 29,
          totalCost: totalCost,
          savings: 92
        };
        if (priceValues) {
          setPremiumSelected(priceValues);
        }
      }
    } else if (premium?.isPremium) {
      if (enteredMembership) {
        dispatch(show('spinner', { text: 'Processing request...' }));
        if (parseInt(enteredMembership) <= 40) {
          const priceValues: any = premiumPlusBreakDownData.find(
            plan => plan.memberships === parseInt(enteredMembership)
          );
          if (priceValues) {
            setPremiumSelected(priceValues);
          }
        } else if (parseInt(enteredMembership) > 40) {
          const membershipCost = parseInt(enteredMembership) * 5;
          const totalCost = membershipCost + 29;
          const priceValues: any = {
            id: 41,
            memberships: enteredMembership,
            totalPlanCost: membershipCost,
            premium: 0,
            totalCost: totalCost,
            savings: 0
          };
          if (priceValues) {
            setPremiumSelected(priceValues);
          }
        }
      }

      dispatch(
        fetchApi({
          url: 'stripe/upgrade/preview',
          method: APIMethods.POST,
          data: {
            membershipType,
            memberships: parseInt(enteredMembership)
          }
        })
      )
        .then((response: AxiosResponse) => {
          const { charges } = response.data;
          dispatch(hide('spinner'));
          setPremiumUpgradeSelected(charges);
          console.log(premiumUpgradeSelected);
        })
        .catch((err: any) => {
          dispatch(hide('spinner'));
        });
    }
  }, [enteredMembership]);

  const handleMemberships = (e: any) => {
    if (e.target.value === 'Memberships') {
      const archivedUsers: string[] = [];
      if (archivedClasses) {
        const archivedUsersData = archivedClasses.map(
          (item: any) => item.roster
        );
        archivedUsersData.forEach((element: any) => {
          element.forEach((item: any) => {
            archivedUsers.push(item.userId);
          });
        });
      }
      unarchivedNonMembersips = nonMembers?.filter(function(obj: any) {
        return !archivedUsers.some(function(obj2: any) {
          return obj._id == obj2;
        });
      });
      if (unarchivedNonMembersips?.length) {
        setEnteredMemberships(unarchivedNonMembersips?.length.toString());
      } else {
        setEnteredMemberships('');
      }
    } else {
      selectedValue = e.target.value;
    }

    if (selectedValue) {
      if (selectedValue == 'No memberships') {
        setEnteredMemberships('');
      } else if (selectedValue == 'Memberships') {
        setEnteredMemberships(e.target.value.replace(/\D/g, ''));
      }
    }
  };

  const handleInputChange = (e: any) => {
    setEnteredMemberships(e.target.value.replace(/\D/g, ''));
  };

  const selectedPlan = () => {
    if (
      (premium?.isPremium && membershipType === 'Premium') ||
      membershipType === 'Premium29'
    ) {
      return isFreeTrial ? '($29)' : '(current plan)';
    } else {
      return '($29)';
    }
  };
  const totalCost = () => {
    if (enteredMembership == '' && !isPremiumUser) {
      return (
        <>
          <Typography variant="h6">
            Total before tax: <b>$29</b>
          </Typography>
        </>
      );
    } else if (
      enteredMembership == '0' ||
      (enteredMembership == '' && isPremiumUser)
    ) {
      return (
        <div>
          {/* api call values */}
          <Typography variant="h6">Plan: $</Typography>
          <Typography variant="h6">Savings: $</Typography>
          <Typography variant="h6">
            Total before tax: $<b></b>
          </Typography>
        </div>
      );
    } else if (!isPremiumUser) {
      return addedMembershipCost();
    } else if (isPremiumUser) {
      return upgradeMembershipCost();
    }
  };

  const addedMembershipCost = () => {
    if (enteredMembership) {
      return (
        <div>
          <Typography variant="h6">
            Total before tax: <b>${premiumSelected.totalCost.toFixed(2)}</b>
          </Typography>
        </div>
      );
    }
  };
  const savingsCalculation = () => {
    let pricePaid = 0;
    let actualPriceToPay = 0;
    const totalMembershipsAfterPurchase =
      Number(enteredMembership) + premium?.totalMemberships;
    if (
      totalMembershipsAfterPurchase > 0 &&
      totalMembershipsAfterPurchase <= 40
    ) {
      const actualPrice: any = premiumPlusBreakDownData.find(
        plan => plan.memberships === totalMembershipsAfterPurchase
      );
      if (actualPrice) {
        if (premium?.type === 'Premium') {
          actualPriceToPay = actualPrice.totalPlanCost + 19;
        } else {
          actualPriceToPay = actualPrice.totalPlanCost + 29;
        }
      }

      const initialPricePaid: any = premiumPlusBreakDownData.find(
        plan => plan.memberships === premium?.totalMemberships
      );
      if (initialPricePaid) {
        if (premium?.type === 'Premium' && premium?.totalMemberships > 0) {
          pricePaid = initialPricePaid.totalPlanCost + 19;
        } else if (premium?.totalMemberships > 0) {
          pricePaid = initialPricePaid.totalPlanCost + 29;
        } else if (
          premium?.totalMemberships === 0 &&
          premium?.type === 'Premium'
        ) {
          pricePaid = initialPricePaid.totalPlanCost - 10;
        } else if (premium?.totalMemberships === 0) {
          pricePaid = initialPricePaid.totalPlanCost;
        }
      }
    } else if (totalMembershipsAfterPurchase > 40) {
      if (premium?.type === 'Premium') {
        actualPriceToPay = 5 * totalMembershipsAfterPurchase + 19;
        pricePaid = 5 * premium?.totalMemberships + 19;
      } else {
        actualPriceToPay = 5 * totalMembershipsAfterPurchase + 29;
        pricePaid = 5 * premium?.totalMemberships + 29;
      }
    }

    planCost = actualPriceToPay - pricePaid;

    savingsCost = planCost - premiumUpgradeSelected.total;
  };

  const upgradeMembershipCost = () => {
    savingsCalculation();
    return (
      <div>
        {featureFlag.newRegistrationFlow && !isFreeTrial && (
          <>
            <Typography variant="h6">
              Total Cost of New Plan: ${planCost.toFixed(2)}
            </Typography>
            <Typography variant="h6">
              Savings: ${savingsCost.toFixed(2)}
            </Typography>
          </>
        )}
        <Typography variant="h6">
          Total after tax: ${premiumUpgradeSelected.total.toFixed(2)}
          <b></b>
        </Typography>
      </div>
    );
  };

  const redirectToPurchase = () => {
    //removing the purchase data stored in local storage if the user comes back to checkout page from stripe without doing payment.
    localStorage.removeItem('purchaseData');
    const membershipType = 'Premium29';
    const oldPlanFinding = () => {
      if (!premium?.isPremium) {
        return 'Free';
      } else {
        return 'Premium';
      }
    };
    const enteredMems = () => {
      if (enteredMembership) {
        return enteredMembership;
      } else {
        return '0';
      }
    };
    const daysLeftInPlan = () => {
      if (!premium?.isPremium) {
        return '365 days';
      } else {
        return Number(daysLeft) - 1;
      }
    };

    const totalCostBeforeTax = () => {
      if (enteredMembership == '' && !isPremiumUser) {
        return 29.0;
      } else if (enteredMembership.length > 0) {
        return premiumSelected.totalCost.toFixed(2);
      }
    };
    trackStripePaymentClicked(oldPlanFinding());

    let educatorMembershipPurchase = {
      oldPlan: oldPlanFinding(),
      newPlan: 'Premium',
      membershipCount: enteredMems(),
      daysLeft: daysLeftInPlan(),
      totalCost: '$' + totalCostBeforeTax()
    };
    if (!premium?.isPremium)
      localStorage.setItem(
        'purchaseData',
        JSON.stringify(educatorMembershipPurchase)
      );
    if (props.isOnboardingStep) {
      mixpanel.track('Onboarding - Stripe Screen');
    }
    trackBuyClickPremiumPlan(membershipType, premiumSelected.totalCost);
    let memberships = 0;
    if (!premium?.isPremium) {
      if (enteredMembership.length > 0) {
        memberships = premiumSelected.memberships;
      } else if (enteredMembership == '') {
        memberships = 0;
      }

      dispatch(show('spinner'));
      dispatch(
        fetchApi({
          url: 'stripe/checkout',
          method: APIMethods.POST,
          data: {
            membershipType,
            memberships,
            isFreeTrial: props.isOnboardingStep ? true : false
          }
        })
      )
        .then((response: AxiosResponse) => {
          const { url } = response.data;
          window.location.href = url;
          dispatch(hide('spinner'));
        })
        .catch((err: any) => {
          console.log(err);
          console.log('error redirecting');
          dispatch(hide('spinner'));
        });
    } else if (premium?.isPremium) {
      if (
        featureFlag.newRegistrationFlow &&
        (enteredMembership === '' || Number(enteredMembership) === 0) &&
        isFreeTrial
      ) {
        console.log('isFreeTrial', isFreeTrial);
        dispatch(
          show('snackbar', {
            variant: 'error',
            message:
              'You are already on the Premium Plan through the 14 day free Premium trial.',
            autoHideDuration: 2700
          })
        );
      } else if (enteredMembership == '') {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Please enter atleast 1 membership to continue1',
            autoHideDuration: 2700
          })
        );
      } else if (Number(enteredMembership) > 0) {
        dispatch(hide('modal'));
        const sendProps: any = {
          type: MODAL_UPGRADE_PREVIEW,
          memberhsipType: membershipType,
          memberships: parseInt(enteredMembership),
          totalCostBeforeTax: premiumUpgradeSelected.total.toFixed(2)
        };
        dispatch(showModal('modal', sendProps));
      } else if (Number(enteredMembership) == 0) {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Please enter atleast 1 membership to continue2',
            autoHideDuration: 2700
          })
        );
      }
    }
  };

  const savingDetails = () => {
    savingsCalculation();
    const daysLeftJustCovertedPremium = 0;
    const daysLeftPremium = 365 - Number(daysLeft) + 1;
    if (
      userData.premium?.isPremium &&
      Number(enteredMembership) > 0 &&
      !isFreeTrial
    ) {
      return (
        <>
          <small
            style={{
              position: 'relative',
              left: '152px',
              bottom: '38px',
              color: 'grey'
            }}
          >
            ${savingsCost.toFixed(2)} in savings{' '}
            {Number(daysLeft) == 366
              ? daysLeftJustCovertedPremium
              : daysLeftPremium}{' '}
            days into your plan
          </small>
          <Typography className={styles.proratedTag}>Prorated</Typography>
        </>
      );
    }
  };

  return (
    <View
      renderBackButton={() => (
        <Button
          size="large"
          onClick={() => history.goBack()}
          style={{ marginRight: 10 }}
        >
          <ChevronLeft /> Back
        </Button>
      )}
    >
      <Paper className={styles.main}>
        <Typography variant="h4" style={{ padding: '8px' }}>
          Confirm your plan and add-ons
        </Typography>
        <Divider></Divider>
        <br />
        <Grid container spacing={2} className={styles.root}>
          <Grid item xl={7} lg={6} md={5}>
            <div className={styles.gridRadio}>
              <Typography variant="h6">Select a plan:</Typography>
              <br />
              <Radio
                checked={true}
                value="Premium plan"
                name="radio-buttons"
                style={{ margin: '0 30px', color: '#3FD2D9' }}
              />
              <Typography className={styles.radioText}>Premium plan</Typography>
              <Typography className={styles.radioText}>
                &nbsp;{selectedPlan()}
              </Typography>
            </div>
            <div className={styles.gridRadio}>
              <Typography variant="h6">Add player memberships:</Typography>
              {premium?.isPremium && premium?.totalMemberships > 0 ? (
                <small style={{ color: 'grey' }}>
                  You currently have: {premium?.totalMemberships}
                </small>
              ) : (
                ''
              )}
              <br />
              <Radio
                checked={enteredMembership == ''}
                onChange={e => handleMemberships(e)}
                value="No memberships"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                style={{ margin: '10px 30px', color: '#3FD2D9' }}
              />
              <Typography className={styles.radioText}>
                No memberships
              </Typography>
              <br />
              <Radio
                checked={enteredMembership.length > 0}
                onChange={e => handleMemberships(e)}
                value="Memberships"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
                style={{ margin: '23px 30px', color: '#3FD2D9' }}
              />
              {userData.premium?.isPremium ? (
                <TextField
                  variant="outlined"
                  value={enteredMembership}
                  onChange={e => handleInputChange(e)}
                  // disabled={selectedValue == 'No memberships'}
                  className={styles.textBox}
                ></TextField>
              ) : (
                <TextField
                  variant="outlined"
                  value={enteredMembership}
                  onChange={e => handleInputChange(e)}
                  // disabled={selectedValue == 'No memberships'}
                  className={styles.freeTextBox}
                ></TextField>
              )}
              &nbsp; &nbsp;
              {userData.premium?.isPremium && Number(enteredMembership) > 0 ? (
                <Typography className={styles.radioText1}>
                  Memberships{' '}
                  {isFreeTrial
                    ? `(+${Number(premiumSelected.totalCost.toFixed(2)) - 29})`
                    : `(+${premiumUpgradeSelected.total.toFixed(2)})`}
                </Typography>
              ) : (
                <>
                  {Number(enteredMembership) > 0 ? (
                    <Typography className={styles.radioText2}>
                      Memberships (+$
                      {Number(premiumSelected.totalCost.toFixed(2)) - 29})
                    </Typography>
                  ) : (
                    <Typography className={styles.radioText2}>
                      {' '}
                      Memberships
                    </Typography>
                  )}
                </>
              )}
              <br />
              {savingDetails()}
            </div>
          </Grid>
          <Grid item>
            <Paper className={styles.container}>
              <Typography style={{ paddingLeft: '6px' }}>
                Membership price guide
                <br />
                <small>All quantities available</small>
                <Divider></Divider>
              </Typography>
              <Table className={styles.table}>
                <colgroup>
                  <col width="20%" />
                  <col width="40%" />
                  <col width="20%" />
                </colgroup>

                <TableHead>
                  <TableRow className={styles.tr}>
                    <TableCell className={styles.td} align="center">
                      Memberships
                    </TableCell>
                    <TableCell className={styles.td} align="center">
                      Price per player per year &nbsp;
                    </TableCell>
                    <TableCell className={styles.td} align="center">
                      Total &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.memberships}>
                      <TableCell className={styles.td} align="center">
                        {row.memberships}
                      </TableCell>
                      <TableCell className={styles.td} align="center">
                        ${row.price}
                      </TableCell>
                      <TableCell className={styles.td} align="center">
                        ${row.total}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <br />
        <Typography variant="h6" style={{ padding: '5px' }}>
          For school or district admins looking to purchase a large number of
          memberships, contact us at{' '}
          <a href="mailto:sales@shoelacelearning.com">
            {' '}
            sales@shoelacelearning.com
          </a>
          .
        </Typography>
        <Divider style={{ margin: '10px 0' }}></Divider>
        <br />
        <Grid container spacing={2} className={styles.root}>
          <Grid item xl={9} lg={8}>
            {totalCost()}
          </Grid>
          <Grid item>
            <Button
              size="large"
              onClick={redirectToPurchase}
              className={styles.paymentButton}
            >
              Continue to payment
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </View>
  );
};

export default MembershipCheckout;
