import { MEMBERS_GET_ALL } from '../types/members/members-action-types';
import { Debug } from '../../utils/debug';
import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { show } from 'redux-modal';
import { MODAL_SUCCESS } from '../../constants/modals';
import {
  trackPauseMembership,
  trackResumeMembership
} from './mixpanel-actions';
import moment from 'moment';

export const getAllMembers = () => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/membership/members',
        method: APIMethods.GET
      })
    )
      .then((response: any) => {
        dispatch({
          type: MEMBERS_GET_ALL,
          payload: response.data
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const pauseMembership = (membershipData: any, months: number) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/membership/pause',
        method: APIMethods.POST,
        data: {
          subscriptionId: membershipData.subMeta._id,
          months
        },
        spinnerMessage: 'Pausing your membership...',
        hideSpinnerAfter: true
      })
    )
      .then((response: any) => {
        dispatch(getAllMembers());
        dispatch(trackPauseMembership());
        const resumesAt = moment(response.data.resumesAt).format('ll');
        const nextBillingDate = moment(response.data.nextBillingDate).format(
          'll'
        );
        dispatch(
          show('modal', {
            type: MODAL_SUCCESS,
            title: 'Membership paused',
            text: `Your membership was successfully paused! It will reactivate on ${resumesAt} and your next billing date is ${nextBillingDate} Dreamscape can continue to be played for free until then, and we'll remind you by email when your membership has been re-applied.`
          })
        );
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const resumeMembership = (subscriptionId: any) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/membership/resume',
        method: APIMethods.POST,
        data: { subscriptionId },
        spinnerMessage: 'Resuming your membership...',
        hideSpinnerAfter: true
      })
    )
      .then((response: any) => {
        dispatch(getAllMembers());
        dispatch(trackResumeMembership());
        dispatch(
          show('modal', {
            type: MODAL_SUCCESS,
            title: 'Membership resumed',
            text: `It's great to have you back! Your membership was successfully resumed and applied to your players again.`
          })
        );
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};
