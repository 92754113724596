import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Tooltip
} from '@material-ui/core';
import Button from '../../../button/Button';
import StarsIcon from '@material-ui/icons/Stars';

interface PackOptions {
  id: number;
  name?: string;
  description?: string;
  premium?: boolean;
  title?: string;
  disabled?: boolean;
  contentType?: string;
}

interface PackSelectionProps {
  disableOptions?: boolean;
  options: Array<PackOptions>;
  selectedValue?: number;
  handleCheckOption: (event: any) => void;
  descriptionURL: string;
  isPremiumUser?: boolean;
}

const PackSelection: React.FC<PackSelectionProps> = (
  props: PackSelectionProps
) => {
  const containerStyle: React.CSSProperties = isMobile
    ? { marginTop: 10, borderBottom: '1px solid #dcdcdc' }
    : {};
  const checkBoxStyle: React.CSSProperties = isMobile
    ? { paddingBottom: 0 }
    : {};
  return (
    <>
      {props.options.map(item => (
        <Grid container spacing={4} style={containerStyle}>
          <Grid
            container
            item
            md={props.descriptionURL ? 8 : 12}
            xs={12}
            alignItems="center"
            style={{ ...checkBoxStyle, marginTop: 5 }}
          >
            <RadioGroup
              value={props.selectedValue}
              onChange={(event: any) => props.handleCheckOption(event)}
            >
              <Tooltip
                title={
                  (item.premium && !props.isPremiumUser) ||
                  (item.contentType === 'Premium' && !props.isPremiumUser)
                    ? 'This feature is only available for premium accounts'
                    : ''
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      value={item.id}
                      color="primary"
                      disabled={
                        (item.premium && !props.isPremiumUser) ||
                        (item.contentType === 'Premium' &&
                          !props.isPremiumUser) ||
                        item.disabled
                      }
                    />
                  }
                  label={
                    <>
                      {item.name && item.premium && !props.isPremiumUser ? (
                        <Typography>
                          {item.name}{' '}
                          <StarsIcon
                            style={{
                              fill: '#f8bb00',
                              position: 'relative',
                              top: '6px'
                            }}
                          />
                        </Typography>
                      ) : (
                        <Typography>{item.name}</Typography>
                      )}

                      {item.title && item.contentType === 'Premium' ? (
                        <Typography>
                          {item.title}{' '}
                          <StarsIcon
                            style={{
                              fill: '#f8bb00',
                              position: 'relative',
                              top: '6px'
                            }}
                          />
                        </Typography>
                      ) : (
                        <Typography>{item.title}</Typography>
                      )}

                      {item.description && (
                        <Typography style={{ fontSize: 13 }}>
                          ({item.description})
                        </Typography>
                      )}
                    </>
                  }
                />
              </Tooltip>
            </RadioGroup>
          </Grid>
          {props.descriptionURL && (
            <Grid item md={4} xs={12} style={isMobile ? { paddingTop: 0 } : {}}>
              {item.id > 0 ? (
                <Button
                  primary
                  onClick={() =>
                    window.open(`${props.descriptionURL}${item.id}`, '_blank')
                  }
                >
                  Description
                </Button>
              ) : null}
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
};

export default PackSelection;
