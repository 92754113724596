export enum APIMethods {
  ANY = 'any',
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete'
}

export enum APIEndpoints {
  DASHBOARD,
  EDUCATION,
  REALTIME,
  GOOGLE_CLASSROOM,
  SEARCH_API,
  LEARNING_ENGINE,
  LE_API
}
