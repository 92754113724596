import { hide, show } from 'redux-modal';
import Axios, { AxiosRequestConfig, ResponseType } from 'axios';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { MODAL_DEFAULT, MODAL_SUCCESS } from '../../constants/modals';
import { push } from 'connected-react-router';
import { loginRoutes } from '../../constants/routes';

interface IFetchApi {
  data?: any;
  url: string;
  method: APIMethods;
  spinnerMessage?: string;
  hideSpinnerAfter?: boolean;
  errorMessage?: string;
  successMessage?: string;
  notificationSuccess?: string;
  notificationError?: string;
  endpoint?: APIEndpoints;
  responseType?: ResponseType;
  authorizationHeader?: string;
  headers?: object;
  ignore401?: boolean;
  autoHideDuration?: number;
}

export enum NotificationVariant {
  error = 'error',
  success = 'success'
}

export function fetchApi(payload: IFetchApi): any {
  return (dispatch: any, getState: any) => {
    if (payload.spinnerMessage) {
      dispatch(show('spinner', { text: payload.spinnerMessage }));
    }
    return new Promise((resolve, reject) => {
      let authorization: any = `${getState().login.token ||
        localStorage.getItem('user-token')}`;
      const token = authorization;
      let headers: any = {};
      if (payload.headers) {
        headers = { ...payload.headers };
      }
      let baseUrl = process.env.REACT_APP_DASHBOARD_API_URL;
      if (payload.endpoint) {
        switch (payload.endpoint) {
          case APIEndpoints.EDUCATION:
            baseUrl = process.env.REACT_APP_EDUCATION_API_URL;
            authorization = process.env.REACT_APP_EDUCATION_API_KEY;
            headers['token-auth'] = token;
            break;
          case APIEndpoints.REALTIME:
            baseUrl = process.env.REACT_APP_REALTIME_REST_ENDPOINT;
            authorization = process.env.REACT_APP_REALTIME_REST_KEY;
            break;
          case APIEndpoints.GOOGLE_CLASSROOM:
            baseUrl = process.env.REACT_APP_GOOGLE_CLASSROOM_SERVICE_ENDPOINT;
            authorization = '';
            break;
          case APIEndpoints.SEARCH_API:
            baseUrl = process.env.REACT_APP_SEARCH_API_URL as string;
            break;
          case APIEndpoints.LE_API:
            baseUrl = process.env.REACT_APP_NEW_LE_API_URL as string;
            break;
          default:
            break;
        }
      }
      if (payload.authorizationHeader) {
        authorization = payload.authorizationHeader;
      }

      const requestConfig: AxiosRequestConfig = {
        url: `${baseUrl}${payload.url}`,
        method: <any>payload.method,
        data: payload.data,
        headers: {
          authorization,
          ...headers
        },
        responseType: payload.responseType
      };
      Axios(requestConfig)
        .then((response: any) => {
          if (payload.successMessage) {
            dispatch(
              show('modal', {
                type: MODAL_SUCCESS,
                text: payload.successMessage
              })
            );
          }
          if (payload.notificationSuccess) {
            dispatch(
              show('snackbar', {
                variant: NotificationVariant.success,
                message: payload.notificationSuccess,
                vertical: 'top',
                horizontal: 'right',
                autoHideDuration: payload.autoHideDuration
                  ? payload.autoHideDuration
                  : 2000
              })
            );
          }
          return resolve(response);
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 401 && !payload.ignore401) {
              dispatch(push(loginRoutes.logout));
              return;
            }
          }
          if (payload.errorMessage) {
            dispatch(
              show('modal', {
                type: MODAL_DEFAULT,
                children: payload.errorMessage
              })
            );
          }
          if (payload.notificationError) {
            dispatch(
              show('snackbar', {
                variant: NotificationVariant.error,
                message: payload.notificationError,
                vertical: 'top',
                horizontal: 'right',
                autoHideDuration: 2000
              })
            );
          }
          return reject(error);
        })
        .finally(() => {
          if (payload.hideSpinnerAfter) {
            dispatch(hide('spinner'));
          }
        });
    });
  };
}
