import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import colors from '../../../styles/colors';
import Table from '../../../components/table/Table';
import { IClassRoster } from '../../../types/state/class-type';
import { useDispatch, useSelector } from 'react-redux';
import { playerNounUppercase } from '../../../utils/userNoun';

interface SkillsTableProps {
  data: Array<any>;
  actions?: any;
  classNames?: any;
  listType: string;
  roster?: Array<IClassRoster>;
  handlePlayerNameClick?: (row: any) => void;
}

const useStyles = makeStyles(() => ({
  rowError: {
    background: colors.secondary,
    color: colors.white
  },
  rowSuccess: {
    background: colors.third,
    color: colors.black
  },
  rowModerate: {
    background: colors.fifth,
    color: colors.black
  },
  center: { textAlign: 'center' },
  accuracy: {
    margin: '-16px 10px -16px 25px',
    padding: 30,
    textAlign: 'center'
  },
  tableContainer: {
    boxShadow: 'none'
  },
  playerSelect: {
    cursor: 'pointer',
    animation: '0.3s',
    '&:hover': {
      textDecoration: 'underline'
    },
    color: colors.blue
  }
}));

const SkillsTable: React.FC<SkillsTableProps> = (props: SkillsTableProps) => {
  const classes = useStyles();
  const userData = useSelector((state: any) => state.userData);

  const getAccuracyClassName = (accuracy: number) => {
    if (accuracy < 49) {
      return classes.rowError;
    } else if (accuracy > 70) {
      return classes.rowSuccess;
    } else {
      return classes.rowModerate;
    }
  };

  let participationTitle = '# of Players who Practiced Skill';
  if (userData.userType === 'Parent') {
    participationTitle = `${playerNounUppercase} Participation`;
  }

  const nameAccessor = props.listType === 'player' ? 'playerName' : 'skill';
  const columns = [
    {
      Header: props.listType === 'player' ? 'Player Name' : 'Skill Name',
      accessor: nameAccessor,
      Cell: (item: any) => {
        return (
          <div
            className={clsx(
              props.listType === 'player' && classes.playerSelect
            )}
            onClick={
              props.listType === 'player' && props.handlePlayerNameClick
                ? () => props.handlePlayerNameClick!(item.row.original)
                : undefined
            }
          >
            {item.row.original[nameAccessor]}
          </div>
        );
      }
    },
    {
      Header: () => <div className={classes.center}>Questions Correct</div>,
      accessor: 'correct',
      Cell: (item: any) => {
        const { correct, incorrect } = item.row.original;
        const total = correct + incorrect;
        return (
          <div className={classes.center}>
            {correct}/{total}
          </div>
        );
      }
    },
    {
      id: 'student-success',
      Header: () => <div className={classes.center}>{participationTitle}</div>,
      accessor: 'studentIds.length',
      show: props.roster && props.listType !== 'widget',
      Cell: (item: any) => {
        const { studentIds } = item.row.original;
        let fraction = studentIds.length;
        if (props.roster) {
          fraction = `${studentIds.length}/${props.roster!.length}`;
        }
        return <div className={classes.center}>{fraction}</div>;
      }
    },
    {
      Header: () => <div className={classes.center}>Accuracy</div>,
      accessor: 'accuracy',
      Cell: (item: any) => {
        const { accuracy } = item.row.original;
        return (
          <div
            className={clsx(getAccuracyClassName(accuracy), classes.accuracy)}
            style={props.listType === 'widget' ? { margin: -16 } : {}}
          >
            {Math.round(accuracy)}%
          </div>
        );
      }
    }
    // {
    //   id: 'assign-skill',
    //   Header: () => <div className={classes.center}>Assign Skill</div>,
    //   accessor: 'type',
    //   show: props.listType !== 'widget',
    //   Cell: (item: any) =>
    //     item.row.original.type === 'independent' ? (
    //       <p className={classes.center}>Not available</p>
    //     ) : (
    //       <div className={classes.center}>
    //         <Checkbox color="primary" />
    //       </div>
    //     )
    // }
  ];
  if (props.actions && props.listType !== 'widget') {
    props.actions.disableSorting = true;
    columns.push({ ...props.actions });
  }

  return (
    <Table
      defaultSize={25}
      columns={columns}
      data={props.data}
      hidePagination={props.listType === 'widget'}
      classNames={{ container: classes.tableContainer }}
    />
  );
};

export default SkillsTable;
