import React from 'react';
import Button from '../../../components/button/Button';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalAbstract from './ModalAbstract';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import { hide } from 'redux-modal';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { teacherRoutes } from '../../../constants/routes';
import { userNavigateWithClassCode } from '../../../utils/user-route-getter';
import { reassignPlacementTestUpgradeButtonClicked } from '../../../redux/actions/mixpanel-actions';

/**
 *
 * @param props
 * @var children should be a function that returns JSX
 * @var title is the title you want to render
 * @var dividers is a boolean value if you want dividers between the title and actions
 * @var actions should be a function that returns JSX
 * @var leftActions should be a function that returns JSX
 * @constructor
 */
const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

const ModalPremiumUpgradeRedirect: React.FC<any> = (props: any) => {
  const {
    show,
    handleHide,
    children,
    leftActions,
    handleTitleClose,
    title,
    dividers,
    hideActions,
    fullWidth,
    showTitleClose,
    maxWidth,
    alignActions
  } = props;
  const content = typeof children === 'function' ? children() : children;
  const dispatch = useDispatch();
  const currentClass = useSelector((state: any) => state.class.currentClass);
  const classCode = currentClass.code;
  const classes = useStyles();
  const redirect = () => {
    dispatch(hide('modal'));
    dispatch(reassignPlacementTestUpgradeButtonClicked(classCode));
    dispatch(
      push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
    );
  };
  return (
    <div>
      <ModalAbstract
        show={show}
        handleHide={handleHide}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="form-dialog-title">
          <span>
            <StarsIcon
              style={{
                fill: '#f8bb00',
                position: 'relative',
                top: '5px',
                right: '5px'
              }}
            />
            Reassign placement test
          </span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            handleHide();
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers={dividers}
          style={{
            padding: '20px'
          }}
        >
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        {!hideActions && (
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: alignActions || 'space-between'
            }}
          >
            {leftActions && leftActions(handleHide)}
            <Grid container justify="center">
              <Button onClick={redirect} primary>
                Upgrade to Premium
              </Button>
            </Grid>
          </DialogActions>
        )}
      </ModalAbstract>
    </div>
  );
};

export default ModalPremiumUpgradeRedirect;
