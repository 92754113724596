import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../../styles/colors';
import moment from 'moment';
import { getSkillReport } from '../../../redux/actions/report-actions';
import {
  SkillReportRequest,
  SkillResponse
} from '../../../types/reports/skill-report-types';
import SkillsFilters, {
  FilterState,
  initialFilterState
} from './SkillsFilters';
import SkillsTable from './SkillsTable';
import { buildReport } from './SkillReportHelpers';
import Select from 'react-select';
import { IClassRoster } from '../../../types/state/class-type';

const useStyles = makeStyles(() => ({
  rowError: {
    background: colors.red,
    color: colors.white
  },
  rowSuccess: {
    background: colors.success,
    color: colors.white
  },
  center: { textAlign: 'center' },
  select: {
    fontSize: 16,
    marginBottom: 20,
    position: 'relative',
    width: '100%',
    zIndex: 1001
  }
}));

const SkillsPlayerReport: React.FC<any> = (props: any) => {
  let initialRequest: SkillReportRequest = {
    grade: 0,
    type: 'all',
    to: moment().format('YYYY-MM-DD'),
    from: moment('2018-01-01').format('YYYY-MM-DD'),
    userIds: []
  };
  if (props.selectedUser) {
    initialRequest.userIds = [props.selectedUser.value];
  }
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentClass } = useSelector((state: any) => state.class);
  const [originalResponse, setOriginalResponse] = useState<Array<any>>([]);
  const [reportState, setReportState] = useState<Array<SkillResponse>>([]);
  const [tableState, setTableState] = useState(reportState || []);
  const [request, setRequest] = useState<SkillReportRequest>(initialRequest);
  const [filter, setFilter] = useState<FilterState>(initialFilterState);
  const [selectedStudent, setSelectedStudent] = useState(() => {
    if (currentClass.roster.length > 0){
      var firstStudent = currentClass.roster[0];
      setRequest({ ...request, userIds: [firstStudent.educationUserId] });
      return {
        label: `${firstStudent.firstname} ${firstStudent.lastname}. (${firstStudent.username})`,
        value: firstStudent.educationUserId
    }}});

  const isValidUserId = () => {
    const userIds = request.userIds.filter(userId => userId != null);
    return userIds.length > 0;
  };

  const fetchReport = async () => {
    if (isValidUserId()) {
      const response = await dispatch(getSkillReport(request));
      setOriginalResponse(response.data);
      const reportData = buildReport(response.data, { grade: filter.grade });
      setReportState(reportData);
    }
  };

  useEffect(() => {
    fetchReport();
  }, [request]);

  useEffect(() => {
    setTableState(reportState);
  }, [reportState]);

  useEffect(() => {
    const data = buildReport(originalResponse, { grade: filter.grade });
    setReportState(data);
  }, [filter.grade]);

  useEffect(() => {
    setTableState(filter.searchState);
  }, [filter.searchState]);

  useEffect(() => {
    const { to, from } = filter.range;
    setRequest({
      ...request,
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD')
    });
  }, [filter.range, filter.period]);

  return (
    <>
      <Select
        className={classes.select}
        defaultValue={selectedStudent}
        styles={{
          control: provided => ({ ...provided, padding: 5 })
        }}
        options={currentClass.roster.map((item: IClassRoster) => ({
          label: `${item.firstname} ${item.lastname}. (${item.username})`,
          value: item.educationUserId
        }))}
        onChange={(selection: any) => {
          setSelectedStudent(selection);
          setRequest({ ...request, userIds: [selection.value] });
        }}
      />
      <SkillsFilters
        originalData={reportState}
        onChange={(filter: FilterState) => setFilter(filter)}
      />
      <Grid item xs={12}>
        {!tableState.length && (
          <Typography
            className={classes.center}
            style={{ padding: 20, marginTop: 20 }}
          >
            There is no data based on your selections. Please select a different
            benchmark, skill category, or time frame.
          </Typography>
        )}
        {tableState.length > 0 && (
          <SkillsTable listType="skills" data={tableState} />
        )}
      </Grid>
    </>
  );
};

export default SkillsPlayerReport;
