import React from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import Table from '../../components/table/Table';
import View from '../../components/view/View';
import { userTypesDashboardRoute } from '../../constants/users';
import { ChevronLeft } from '@material-ui/icons';
import { trackViewAssignmentTool } from '../../redux/actions/mixpanel-actions';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import useQueryParams from '../../hooks/UseQueryParams';
import { teacherRoutes } from '../../constants/routes';

const useStyles = makeStyles(() => ({
  circle: {
    borderRadius: '50%',
    display: 'inline-block',
    height: 20,
    width: 20
  },
  link: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

const PlannerReportView: React.FC<any> = () => {
  const classes = useStyles();

  const userData = useSelector((state: any) => state.userData);
  const planner = useAppSelector(state => state.planner);
  const features = useAppSelector(state => state.featureFlag);
  const params = useParams<{ classCode: string }>();

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  let plannerData = planner;
  if (query.isUserGenerated) {
    plannerData = plannerData.filter(
      (assignment: any) =>
        assignment.educationAssignmentId == query.assignmentId
    );
  }
  let assignmentCreationLink = `${
    userTypesDashboardRoute[userData.userType]
  }/assignment-tool/${params.classCode}`;

  if (features?.userGeneratedContent?.hasAccess) {
    assignmentCreationLink = `${teacherRoutes.userGeneratedAssignments.replace(
      ':classCode',
      params.classCode
    )}`;
  }

  return (
    <View
      title="Assignment Reports"
      renderBackButton={() => (
        <Button onClick={() => history.goBack()} style={{ marginRight: 10 }}>
          <ChevronLeft /> Back
        </Button>
      )}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!planner.length ? (
            <Typography component="p">
              You don't have any assignments for this class,
              <NavLink
                className={classes.link}
                style={{ marginLeft: 5 }}
                to={assignmentCreationLink}
              >
                click here to create one.
              </NavLink>
            </Typography>
          ) : (
            <Table
              columns={[
                {
                  Header: 'Name',
                  accessor: 'title',
                  Cell: (row: any) => (
                    <a
                      className={classes.link}
                      onClick={() => {
                        if (row.row.original.isUserGenerated) {
                          history.push(
                            `${
                              userTypesDashboardRoute[userData.userType]
                            }/assignment-tool/report/${
                              row.row.original.id
                            }/${params.classCode}?isUserGenerated=true`
                          );
                        } else {
                          history.push(
                            `${
                              userTypesDashboardRoute[userData.userType]
                            }/assignment-tool/report/${row.row.original._id}/${params.classCode}`
                          );
                        }
                        dispatch(trackViewAssignmentTool());
                      }}
                    >
                      {row.row.original.title}
                    </a>
                  )
                },
                {
                  Header: 'Type',
                  accessor: 'type'
                },
                {
                  Header: 'Status',
                  accessor: 'status'
                },
                {
                  Header: 'Color',
                  Cell: (row: any) => (
                    <span
                      className={classes.circle}
                      style={{ backgroundColor: row.row.original.color }}
                    />
                  )
                }
              ]}
              defaultSize={10}
              data={plannerData}
            />
          )}
        </Grid>
      </Grid>
    </View>
  );
};

export default PlannerReportView;
