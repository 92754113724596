import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Plan } from './Plan';
import StarsIcon from '@material-ui/icons/Stars';
import { premiumPriceChangeStart } from '../../../../layouts/teacher/TeacherFeatureFlagContext';
import { IBillingDetail } from '../../../billing/interfaces/IBillingDetail';
import moment from 'moment';
import { HelpOutline } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import assignmentIcon from '../../../../assets/membership/assignmentsIcon.png';
import classMissionIcon from '../../../../assets/membership/classMissionIcon.png';
import gamePlayIcon from '../../../../assets/membership/gamePlay.png';
import placementIcon from '../../../../assets/membership/placementIcon.png';
import reportingIcon from '../../../../assets/membership/reporting.png';
import rewardIcon from '../../../../assets/membership/rewardIcon.png';
import testPrepIcon from '../../../../assets/membership/testPreptIcon.png';
import assignMmembershipIcon from '../../../../assets/membership/assignMmebershipsIcon.png';
import moreIcon from '../../../../assets/membership/moreIcon.png';
import seasonRewardsIcon from '../../../../assets/membership/seasonRewardsIcon.png';
import upgradeDwellIcon from '../../../../assets/membership/upgradeDwellIcon.png';
import upgradeTreasureIcon from '../../../../assets/membership/upgradeTreasuresIcon.png';
import {
  MODAL_DEFAULT,
  MODAL_MEMBERSHIP_PURCHASE_SUCCESS,
  MODAL_TEACHER_ASSIGN_MEMBERSHIPS,
  MODAL_UPGRADE_SUCCESS
} from '../../../../constants/modals';
import { show } from 'redux-modal';
import { teacherRoutes } from '../../../../constants/routes';
import { useAppSelector } from '../../../../redux/hooks';
import useQueryParams from '../../../../hooks/UseQueryParams';
import { hide as hideModal, show as showModal } from 'redux-modal';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../../types/fetch/fetch-types';
import { AxiosError, AxiosResponse } from 'axios';
import {
  premiumPagePurcahseButtonClicked,
  trackEducatorMembershipPurchase
} from '../../../../redux/actions/mixpanel-actions';
import { getUser } from '../../../../redux/actions/user-actions';
import { getAllMembers } from '../../../../redux/actions/members-actions';

const useStyles = makeStyles((theme: Theme) => ({
  premiumBox: {
    [theme.breakpoints.down('xl')]: {
      width: '200px',
      height: '175px',
      border: '1px solid #00AFFE',
      borderRadius: '8px',
      marginBottom: '2px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '165px',
      height: '175px',
      border: '1px solid #00AFFE',
      borderRadius: '8px',
      marginBottom: '2px',
      position: 'relative',
      left: '40px'
    }
  },
  heroBox: {
    [theme.breakpoints.down('xl')]: {
      width: '210px',
      height: '185px',
      border: '1px solid #00AFFE',
      borderRadius: '8px',
      marginBottom: '2px',
      position: 'relative',
      right: '40px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '150px',
      height: '165px',
      border: '1px solid #00AFFE',
      borderRadius: '8px',
      marginBottom: '2px'
    }
  },
  root: {
    padding: '10px 0',
    marginLeft: '11px',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: '4rem',
      paddingRight: '4rem'
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '3rem',
      paddingRight: '3rem'
    }
  },
  premiumBoxHead: {
    textAlign: 'center',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    backgroundColor: '#00AFFE',
    color: 'white'
  },
  heroBoxHead: {
    textAlign: 'center',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    backgroundColor: '#EC7100',
    color: 'white'
  },
  boxContent: {
    textAlign: 'center'
  },
  loyaltyBox: {
    backgroundColor: '#e1e1e1',
    margin: '5px 40px',
    borderRadius: 10,
    fontSize: '.5rem',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 18,
      top: 3,
      backgroundColor: '#e1e1e1',
      padding: 3,
      width: '60px',
      borderRadius: 10,
      fontSize: '.4rem'
    }
  },
  sameLine: {
    display: 'inline'
  },
  monthlyCharge: {
    fontSize: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '7px'
    }
  },
  yourPlanButton: {
    color: '#3FD3D9',
    borderColor: '#3FD3D9',
    '&:hover': {
      background: 'white'
    }
  },
  yourPlanFreeButton: {
    color: '#3FD3D9',
    borderColor: '#3FD3D9',
    margin: '9px 0',
    '&:hover': {
      background: 'white'
    },

    [theme.breakpoints.down('xl')]: {
      width: '22px',
      height: '32px',
      fontSize: '10px',
      position: 'relative',
      right: '30px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '18px',
      height: '30px',
      fontSize: '10px',
      position: 'relative',
      right: '10px'
    }
  },
  expiryDate: {
    textAlign: 'center',
    fontSize: '12px',
    color: 'grey',
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      left: '40px',
      fontSize: '11px',
      width: '160px'
    }
  },
  helpIcon: {
    color: 'grey',
    height: '24px',
    width: '24px',
    minWidth: '0'
  },
  featureList: {
    marginTop: '10px',
    paddingLeft: '80px',
    paddingRight: '100px',
    listStyleType: 'none'
  },
  icon: {
    height: '30px',
    width: '30px'
  },
  align: {
    display: 'inherit'
  },
  freeCheckList: {
    color: 'grey',
    height: '30px',
    width: '35px'
  },
  premiumCheckList: {
    color: '#00AFFE',
    height: '30px',
    width: '35px',
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      left: '55px'
    }
  },
  heroCheckList: {
    color: '#EC7100',
    height: '30px',
    width: '35px'
  },
  divider: {
    [theme.breakpoints.down('xl')]: {
      marginLeft: '40px',
      marginRight: '60px'
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '40px'
    }
  },
  freeTitle: {
    margin: '30px 0',
    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      right: '25px'
    },
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      right: '5px'
    }
  },
  freeTextExpiry: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',

    color: 'grey',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
      width: '100px',
      right: '27px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '8px',
      width: '60px',
      right: '2px'
    }
  },
  bottomGrid: {
    [theme.breakpoints.down('xl')]: {
      paddingRight: '10px'
    }
  },
  membershipBox: {
    height: '75px',
    border: '1px solid #3FD2D9',
    borderRadius: '4px',
    boxShadow: ' 0 8px 6px -6px #3FD2D9  ',
    [theme.breakpoints.down('xl')]: {
      marginRight: '80px'
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: '100px'
    }
  },
  verticalButton: {
    backgroundColor: '#3FD2D9',

    color: 'white',
    textAlign: 'center',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    width: '75px',
    height: '24px',
    transform: 'rotate(-90deg)',

    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      top: '1.6px',
      right: '44px'
    },
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      top: '1.4px',
      right: '20px'
    }
  },
  alignBottomGrid: {
    position: 'absolute',
    bottom: '19px',
    [theme.breakpoints.down('xl')]: {
      right: '-119px'
    },
    [theme.breakpoints.down('lg')]: {
      right: '-79px'
    }
  },
  addMembershipText: {
    display: 'flex',
    flexDirection: 'column-reverse',
    color: 'grey'
  },
  bottomGridTextalign: {
    display: 'inherit',
    position: 'relative',
    bottom: '5px',
    right: '26px'
  },
  bottomGridFreeCheckList: {
    color: 'grey',
    height: '25px',
    width: '30px',
    position: 'relative',
    right: '25px',
    [theme.breakpoints.down('xl')]: {
      left: '35px'
    },
    [theme.breakpoints.down('lg')]: {
      position: 'relative'
    }
  },
  bottomGridButton: {
    backgroundColor: '#00AFFE',
    color: 'white',
    '&:hover': {
      background: '#00AFFE'
    },
    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      right: '45px'
    },
    [theme.breakpoints.down('lg')]: {
      position: 'relative'
    }
  },
  proratedTag: {
    fontWeight: 'bolder',
    textAlign: 'center',
    backgroundColor: '#CFFDFF',
    // margin: '5px 4px',
    borderRadius: 10,
    fontSize: '0.8rem',

    alignItems: 'center',

    height: '19px',
    width: '80px',

    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      right: '1px',
      bottom: '35px'
    },
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      right: '60px',
      bottom: '35px'
    }
  }
}));

interface PremiumPlansProps {
  isPremiumUser: boolean;
  currentMembershipType: Plan;
  premiumExpiry: any;
  activeStudents: number;
}

export const Premium = (props: PremiumPlansProps) => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();
  const {
    isPremiumUser,
    currentMembershipType,
    premiumExpiry,
    activeStudents
  } = props;
  const [expiry, setExpiry] = React.useState<IBillingDetail>();
  const userData = useAppSelector(state => state.userData);
  const isPremium = userData.premium && userData.premium.isPremium;
  const isUserPremium =
    currentMembershipType === Plan.Premium ||
    currentMembershipType === Plan.Premium29;
  const isUserPremiumPlus = currentMembershipType === Plan.PremiumPlus;
  const { token } = useAppSelector(state => state.login);
  const membershipData = useAppSelector(state => state.members);
  const { nonMembers } = membershipData;
  const [unarchivedUsers, setUnarchivedUsers] = useState('');
  const classData = useAppSelector(state => state.class);
  const { classes } = classData;
  const archivedClasses = classes.filter((a: any) => a.archived == true);

  React.useEffect(() => {
    dispatch(
      fetchApi({
        url: 'stripe/billing',
        method: APIMethods.GET
      })
    )
      .then((result: AxiosResponse) => {
        const { billing } = result.data;
        const { activePlan, invoices } = billing;
        setExpiry({
          invoices,
          activePlan
        });
      })
      .catch((err: AxiosError) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const archivedUsers: string[] = [];
    if (archivedClasses) {
      const archivedUsersData = archivedClasses.map((item: any) => item.roster);
      archivedUsersData.forEach((element: any) => {
        element.forEach((item: any) => {
          archivedUsers.push(item.userId);
        });
      });
    }
    const unarchivedNonMembersips = nonMembers?.filter(function(obj: any) {
      return !archivedUsers.some(function(obj2: any) {
        return obj._id == obj2;
      });
    });
    setUnarchivedUsers(unarchivedNonMembersips);
  }, [classes, membershipData]);

  const findMembershipCount = () => {
    if (userData.premium.isPremium) {
      return userData.premium.totalMemberships;
    } else {
      return '0';
    }
  };

  if (query.reDirect === '1') {
    const membershipData = localStorage.getItem('purchaseData');
    if (membershipData) {
      const data = JSON.parse(membershipData);
      if (data.oldPlan == data.newPlan) {
        const sendProps: any = {
          type: MODAL_MEMBERSHIP_PURCHASE_SUCCESS,
          newMembershipCount: data.membershipCount,
          existingMembershipCount: userData.premium.totalMemberships
        };
        dispatch(showModal('modal', sendProps));
      } else {
        const sendProps: any = {
          type: MODAL_UPGRADE_SUCCESS,
          oldPlan: data.oldPlan,
          newPlan: data.newPlan,
          membershipCount: data.membershipCount
        };
        dispatch(showModal('modal', sendProps));
      }

      // sending the purchase success data to mixpanel event post payment.
      trackEducatorMembershipPurchase(
        data.oldPlan,
        data.newPlan,
        data.membershipCount,
        data.daysLeft,
        data.totalCost,
        findMembershipCount()
      );
    }
    localStorage.removeItem('purchaseData');
  }

  const placementTestHelpModal = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Reassign placement tests',
        children: () => (
          <>
            <Divider></Divider>
            <br />
            <Typography>
              The placement test is used by the learning engine to level the
              reading content within the game to the player’s reading skills. It
              is automatically assigned to all new players.
            </Typography>
            <br></br>
            <b>Free plan:</b>

            <Typography>
              All players are given 1 placement test when they start playing
              Dreamscape to assign them a reading level.
            </Typography>
            <br></br>
            <b>Premium plan: </b>
            <Typography>
              You can reassign the placement test for any of your players,
              anytime.
            </Typography>
            <br></br>
            <Typography>
              We recommend reassigning the placement test if players have had an
              extended break from playing (such as after the winter and summer
              holidays) or if you feel like the content has become mismatched
              from the player’s ability.
            </Typography>
          </>
        )
      })
    );
  };

  const assignmentsHelpModal = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Access to Premium assignments',
        children: () => (
          <>
            <Divider></Divider>
            <br />
            <Typography>
              Assignments are curated sets of passages and questions that focus
              on specific skills or genres.
            </Typography>
            <br></br>
            <b>Free plan:</b>
            <Typography>Access to our library of free assignments.</Typography>
            <br></br>
            <b>Premium plan: </b>
            <Typography>
              Access to premium assignments like Charts & Graphs.
            </Typography>
          </>
        )
      })
    );
  };

  const testPrepHelpModal = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Access to test prep',
        children: () => (
          <>
            <Divider></Divider>
            <br />
            <Typography>
              Our test prep assignments help prepare players for stressful
              testing seasons by providing catered content as they play
              Dreamscape.
            </Typography>
            <br></br>
            <b>Free plan:</b>
            <Typography>Test prep trial for grades 3 to 6.</Typography>
            <br></br>
            <b>Premium plan: </b>
            <Typography>
              Unlimited access to all test prep including Common Core aligned
              test prep as well as state specific assessments for Florida,
              California and Texas.
            </Typography>
          </>
        )
      })
    );
  };

  const rewardHelpModal = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Player rewards',
        children: () => (
          <>
            <Divider></Divider>
            <br />
            <Typography>
              Players love rewards! We offer educators the option to reward
              their players in Dreamscape.
            </Typography>
            <br></br>
            <b>Free plan:</b>
            <Typography>Give up to 3 rewards per month.</Typography>
            <br></br>
            <b>Premium plan: </b>
            <Typography>Reward all your players, every month.</Typography>
          </>
        )
      })
    );
  };

  const classMissionHelpModal = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Access to Class Missions',
        children: () => (
          <>
            <Divider></Divider>
            <br />
            <Typography>
              Class Missions are games for your entire class where everyone
              works together to defeat powerful monsters.
            </Typography>
            <br></br>
            <b>Free plan:</b>
            <Typography>Accessible each month during Fun Friday.</Typography>
            <br></br>
            <b>Premium plan: </b>
            <Typography>
              Unlimited play and access to all boss monsters.
            </Typography>
          </>
        )
      })
    );
  };

  const playerMembershipHelpModal = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Player memberships',
        children: () => (
          <>
            <Divider></Divider>

            <Typography style={{ marginTop: '10px' }}>
              With membership, your players unlock ALL features and benefits in
              Dreamscape for maximum fun and motivation to learn. Your players
              will gain:
            </Typography>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <img src={seasonRewardsIcon}></img>
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ display: 'inline' }}>
                  Access to all seasonal member rewards including exclusive
                  heroes, buildings and pets
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <img src={upgradeTreasureIcon}></img>
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ display: 'inline' }}>
                  Upgrades to rare treasure chests and reward boxes for
                  completing objectives
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <img src={upgradeDwellIcon}></img>
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ display: 'inline' }}>
                  More currency to upgrade their dwell and unlock special
                  rewards
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <img src={moreIcon}></img>
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ display: 'inline' }}>And more!</Typography>
              </Grid>
            </Grid>
          </>
        )
      })
    );
  };

  const features = [
    {
      image: `${gamePlayIcon}`,
      text: <Typography>Unlimited player accounts and gameplay</Typography>,
      appliesFree: <DoneIcon />,
      appliesPremium: <DoneIcon />
    },
    {
      image: `${reportingIcon}`,
      text: <Typography>Basic player reporting</Typography>,
      appliesFree: <DoneIcon />,
      appliesPremium: <DoneIcon />
    },
    {
      image: `${placementIcon}`,
      text: <Typography>Reassign placement tests</Typography>,
      help: (
        <Button className={styles.helpIcon} onClick={placementTestHelpModal}>
          <HelpOutline />
        </Button>
      ),
      appliesFree: <CloseIcon />,
      appliesPremium: <DoneIcon />
    },
    {
      image: `${assignmentIcon}`,
      text: <Typography>Access to all assignments</Typography>,
      help: (
        <Button className={styles.helpIcon} onClick={assignmentsHelpModal}>
          <HelpOutline />
        </Button>
      ),
      appliesFree: <CloseIcon />,
      appliesPremium: <DoneIcon />
    },
    {
      image: `${testPrepIcon}`,
      text: <Typography>Access to all test prep</Typography>,
      help: (
        <Button className={styles.helpIcon} onClick={testPrepHelpModal}>
          <HelpOutline />
        </Button>
      ),
      appliesFree: (
        <Typography
          variant="subtitle2"
          style={{ position: 'relative', right: '10px' }}
        >
          Limited
        </Typography>
      ),
      appliesPremium: <DoneIcon />
    },
    {
      image: `${rewardIcon}`,
      text: <Typography>Monthly reward for each player</Typography>,
      help: (
        <Button className={styles.helpIcon} onClick={rewardHelpModal}>
          <HelpOutline />
        </Button>
      ),
      appliesFree: <CloseIcon />,
      appliesPremium: <DoneIcon />
    },
    {
      image: `${classMissionIcon}`,
      text: <Typography>Unlimited access to Class Missions</Typography>,
      help: (
        <Button className={styles.helpIcon} onClick={classMissionHelpModal}>
          <HelpOutline />
        </Button>
      ),
      appliesFree: <CloseIcon />,
      appliesPremium: <DoneIcon />
    }
  ];

  const listItems = features.map((features, index) => (
    <List>
      <Grid container spacing={1}>
        <Grid item>
          <img
            className={styles.icon}
            key={index}
            src={`${features.image}`}
          ></img>
        </Grid>
        <Grid item xl={7} lg={6} className={styles.align}>
          <p>{features.text}</p>
          <span>{features.help}</span>
        </Grid>

        <Grid item xl={2} lg={2} className={styles.freeCheckList}>
          {features.appliesFree}
        </Grid>
        <Grid item xl={2} lg={3} className={styles.premiumCheckList}>
          {features.appliesPremium}
        </Grid>
        {/* <Grid item className={classes.heroCheckList}>
          {features.appliesPremium}
        </Grid> */}
      </Grid>
      <Divider className={styles.divider} />
    </List>
  ));
  const currentUserType = () => {
    if (premiumExpiry.isPremium) {
      return premiumExpiry.type;
    } else {
      return 'Free';
    }
  };
  const handleBuyPlan = () => {
    premiumPagePurcahseButtonClicked(
      currentUserType(),
      'Premium - Upgrade now'
    );
    history.push(teacherRoutes.membershipCheckout + `?reDirect=plan`);
  };

  const handleBuyMemberships = () => {
    premiumPagePurcahseButtonClicked(
      currentUserType(),
      'Premium - Add Memberships'
    );
    history.push(teacherRoutes.membershipCheckout + `?reDirect=memberships`);
  };

  const handleassignMemberships = () => {
    if (unarchivedUsers.length > 0) {
      dispatch(getUser(token, false));
      dispatch(getAllMembers());
      const sendProps: any = {
        type: MODAL_TEACHER_ASSIGN_MEMBERSHIPS
      };
      dispatch(showModal('modal', sendProps));
    } else {
      dispatch(
        show('snackbar', {
          variant: 'error',
          message:
            'Please add atleast one player to the class before assigning memberships'
        })
      );
    }
  };

  const bottomGridButton = () => {
    if (!isPremium || premiumExpiry.expiryDate > moment().startOf('day')) {
      return (
        <Button
          size="small"
          variant="contained"
          className={styles.bottomGridButton}
          onClick={() => {
            if (!premiumExpiry.isPremium || !isUserPremiumPlus) {
              handleBuyMemberships();
            }
          }}
        >
          Add memberships
        </Button>
      );
    } else if (
      premiumExpiry.type == 'Premium' ||
      premiumExpiry.type == 'Premium29'
    )
      if (premiumExpiry.membershipsAvailable > 0) {
        return (
          <>
            <Button
              size="small"
              variant="contained"
              className={styles.bottomGridButton}
              onClick={() => {
                if (
                  premiumExpiry.type == 'Premium' ||
                  premiumExpiry.type == 'Premium29'
                ) {
                  handleassignMemberships();
                }
              }}
            >
              Assign memberships
            </Button>
            <small
              style={{
                paddingTop: '5px',
                display: 'flex',
                fontSize: '9px',
                flexDirection: 'column-reverse',
                position: 'relative',
                right: '50px'
              }}
            >
              You have {premiumExpiry.membershipsAvailable} unassigned
              memberships
            </small>
          </>
        );
      } else if (premiumExpiry.type == 'PremiumPlus') {
        return (
          <>
            <Tooltip title="Currently, memberships are auto-assigned when you add players to your account. Manual assignment is coming soon.">
              <Button
                size="small"
                variant="contained"
                className={styles.bottomGridButton}
                onClick={() => {
                  if (
                    premiumExpiry.type == 'Premium' ||
                    premiumExpiry.type == 'Premium29'
                  ) {
                    handleassignMemberships();
                  }
                }}
              >
                Assign memberships
              </Button>
            </Tooltip>
            <small
              style={{
                paddingTop: '5px',
                display: 'flex',
                fontSize: '9px',
                flexDirection: 'column-reverse',
                position: 'relative',
                right: '50px'
              }}
            >
              You have {premiumExpiry.membershipsAvailable} unassigned
              memberships
            </small>
          </>
        );
      } else {
        return (
          <Button
            size="small"
            variant="contained"
            className={styles.bottomGridButton}
            onClick={() => {
              {
                handleBuyMemberships();
              }
            }}
          >
            Add memberships
          </Button>
        );
      }
    else if (isUserPremiumPlus) {
      return (
        <Tooltip title="For upgrades please contact us at sales@shoelacelearning.com">
          <Button
            size="small"
            variant="contained"
            className={styles.bottomGridButton}
            onClick={() => {
              if (!premiumExpiry.isPremium && !isUserPremiumPlus) {
                handleBuyMemberships();
              }
            }}
          >
            Add memberships
          </Button>
        </Tooltip>
      );
    }
  };
  const expiryDate = moment(premiumExpiry.expiryDate).format('MMMM DD, YYYY');
  const handleExpiry = () => {
    if (expiry?.activePlan.cancelAtPeriodEnd) {
      return (
        <Typography className={styles.expiryDate}>
          Expires: {expiryDate}
        </Typography>
      );
    } else {
      return (
        <Typography className={styles.expiryDate}>
          Renews: {expiryDate}
        </Typography>
      );
    }
  };

  const yourPlan = () => {
    if (!isPremiumUser) {
      return (
        <>
          <Button
            size="small"
            variant="outlined"
            className={styles.yourPlanFreeButton}
          >
            Your Plan
          </Button>
          <Typography className={styles.freeTextExpiry}>
            Expires: Never
          </Typography>
        </>
      );
    }
  };

  const premiumBoxContent = () => {
    if (premiumExpiry.isPremium && currentMembershipType === Plan.Premium) {
      return (
        <>
          <Tooltip title="As a loyalty user, your Premium subscription will continue to be $19/year while you are still subscribed. For new Premium users, the price is $29/year.">
            <Typography className={styles.loyaltyBox}>
              $19 Loyalty plan
            </Typography>
          </Tooltip>
          <small style={{ position: 'relative', bottom: '11px' }}>$</small>
          <Typography
            variant="h5"
            className={styles.sameLine}
            style={{ textDecoration: ' line-through' }}
          >
            29
          </Typography>
          <small> /year</small>
          <Typography variant="subtitle2" className={styles.monthlyCharge}>
            $1.59 monthly
          </Typography>
          <Button variant="outlined" className={styles.yourPlanButton}>
            Your Plan
          </Button>
        </>
      );
    } else if (
      premiumExpiry.isPremium &&
      currentMembershipType === Plan.Premium29
    ) {
      return (
        <>
          <small style={{ position: 'relative', bottom: '11px' }}>$</small>
          <Typography variant="h5" className={styles.sameLine}>
            29
          </Typography>
          <small> /year</small>
          <Typography variant="subtitle2" className={styles.monthlyCharge}>
            $2.42 monthly
          </Typography>
          <Button
            variant="outlined"
            style={{ marginTop: '15px' }}
            className={styles.yourPlanButton}
          >
            Your Plan
          </Button>
        </>
      );
    } else {
      return (
        <>
          <small style={{ position: 'relative', bottom: '11px' }}>$</small>
          <Typography variant="h5" className={styles.sameLine}>
            29
          </Typography>
          <small> /year</small>
          <Typography variant="subtitle2" className={styles.monthlyCharge}>
            $2.42 monthly
          </Typography>
          <br />
          {premiumExpiry.isPremium &&
          currentMembershipType === Plan.PremiumPlus ? (
            <Tooltip title="As you are already a Premium + user, this option isn't available.">
              <Button
                size="small"
                variant="contained"
                style={{
                  backgroundColor: '#00AFFE',
                  color: 'white'
                }}
                // disabled={true}
              >
                Upgrade now
              </Button>
            </Tooltip>
          ) : (
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#00AFFE',
                color: 'white'
              }}
              onClick={() => {
                handleBuyPlan();
              }}
            >
              Upgrade now
            </Button>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Grid container spacing={7} className={styles.root}>
        <Grid item xl={6} lg={5}>
          <Typography
            variant="h3"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Premium Plans
            <StarsIcon
              fontSize={'large'}
              style={{
                fill: '#f8bb00',
                width: '3rem'
              }}
            />
          </Typography>

          <Typography
            variant="subtitle2"
            style={{
              padding: '10px 0'
            }}
          >
            Reward yourself and your players with Premium features that support
            you in the classroom while keeping them motivated and excited to
            learn.
          </Typography>
        </Grid>
        <Grid item xl={1} lg={1}></Grid>
        <Grid item xl={1} lg={1}>
          <Typography variant="h5" className={styles.freeTitle}>
            Free
          </Typography>
          {yourPlan()}
        </Grid>
        <Grid item xl={2} lg={2}>
          <Box className={styles.premiumBox}>
            <Typography variant="subtitle2" className={styles.premiumBoxHead}>
              Annual plan
            </Typography>
            <Typography className={styles.boxContent}>
              <Typography variant="h5">Premium</Typography>
              {premiumBoxContent()}
            </Typography>
          </Box>
          <Typography>
            {premiumExpiry && isUserPremium ? handleExpiry() : ''}
          </Typography>
        </Grid>
        {/* <Grid item xl={1} lg={1}>
        <Box className={classes.heroBox}>
          <Typography variant="subtitle2" className={classes.heroBoxHead}>
            Annual plan
          </Typography>
        </Box>
      </Grid> */}
        <Grid item xl={12} lg={12}>
          <List className={styles.featureList}>{listItems}</List>
        </Grid>
        <Grid item xl={12} lg={12} className={styles.membershipBox}>
          {/* <Box> */}
          <Grid container className={styles.alignBottomGrid}>
            <Typography className={styles.verticalButton}>Add-on</Typography>

            {/* <Grid item xl={6}> */}
            <img
              style={{
                position: 'relative',
                bottom: '55px',
                right: '40px',
                width: '85px',
                height: '110px'
              }}
              src={`${assignMmembershipIcon}`}
            ></img>
            <Grid item xl={5} lg={4} className={styles.bottomGridTextalign}>
              <Typography style={{ fontWeight: 'bold', fontSize: '17px' }}>
                Add player memberships
                {isUserPremium && !isUserPremiumPlus ? (
                  <small className={styles.addMembershipText}>
                    Your memberships: {premiumExpiry.totalMemberships}
                  </small>
                ) : (
                  <small className={styles.addMembershipText}>Optional</small>
                )}
              </Typography>
              <Button
                className={styles.helpIcon}
                onClick={playerMembershipHelpModal}
              >
                <HelpOutline />
              </Button>
            </Grid>
            <Grid item xl={2} lg={2}>
              <CloseIcon className={styles.bottomGridFreeCheckList}></CloseIcon>
            </Grid>
            <Grid item>{bottomGridButton()}</Grid>
            {userData.premium.type == 'Premium' ||
            userData.premium.type == 'Premium29' ? (
              <Typography className={styles.proratedTag}>Prorated</Typography>
            ) : (
              ''
            )}
          </Grid>
          {/* </Box> */}
        </Grid>
      </Grid>

      <Typography
        variant="subtitle2"
        style={{ position: 'relative', top: '35px', left: '80px' }}
      >
        If you have any questions or require assistance, please contact us at
        <a href="mailto:support@shoelacelearning.com">
          {' '}
          support@shoelacelearning.com
        </a>
      </Typography>
    </>
  );
};
