import { CardGiftcard, SportsEsportsOutlined } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import StarsIcon from '@material-ui/icons/Stars';
import { isMobile } from 'react-device-detect';
import { teacherRoutes } from '../../../constants/routes';
import TimerIcon from '@material-ui/icons/Timer';
import { SidebarItems } from '../../../types/sidebar/sidebar-type';
import { mobileSideItems } from './navbar-mobile';
import { useContext } from 'react';
import TeacherFeatureFlagContext from '../../../layouts/teacher/TeacherFeatureFlagContext';
import { useAppSelector } from '../../../redux/hooks';
import { StyledBadge } from '../../badge/StyledBadge';
import colors from '../../../styles/colors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useShowSummerNotification from '../../../features/summer-notification/hooks/useShowSummerNotification';
import { TimerOutlined } from '@material-ui/icons';
import { useTheme } from '@material-ui/core';

// eslint-disable-next-line import/no-anonymous-default-export
export default (path: string, classCode: string): Array<SidebarItems> => {
  const theme = useTheme();
  const token = useAppSelector(state => state.login.token);
  const userData = useAppSelector(state => state.userData);
  const { flags } = useContext(TeacherFeatureFlagContext);
  useShowSummerNotification();
  const { currentClass } = useAppSelector((state: any) => state.class);

  const {
    isMayPromotionActive,
    classMissionTrials,
    classMissionTrialsExtended,
    summerNotification,
    isMissionMonday,
    isPriceChangePrep
  } = flags;

  const showClassMissionTrials =
    (classMissionTrials || classMissionTrialsExtended) &&
    !(userData.premium && userData.premium.isPremium);

  const promoBadge = isMayPromotionActive
    ? {
        active: isMayPromotionActive,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        badgeOverride: {
          background: 'rgba(255, 226, 73, 1)',
          marginBottom: '10px'
        },
        component: StyledBadge,
        text: 'Promotion'
      }
    : undefined;
  const priceIncreaseTimer = {
    active: isPriceChangePrep,
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top'
    },
    badgeOverride: {
      [theme.breakpoints.down('md')]: {
        right: '-15px'
      },
      background: '#40D2D9',
      color: colors.white,
      top: '3px',
      right: '-15px',
      height: 30,
      width: 30,
      borderRadius: '50%',
      '& svg': { fontSize: '20px' }
    },
    component: StyledBadge,
    text: TimerOutlined
  };

  const getRedirectURL = () => {
    const URL =
      localStorage.getItem('referringURL') ||
      `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${currentClass.code}/home`;
    return URL.includes('?')
      ? `${URL}&accessToken=${token}`
      : `${URL}?accessToken=${token}`;
  };

  const menuItemArray: any = [
    {
      title: 'Return',
      icon: ArrowBackIcon,
      onClick: () => {
        window.location.href = getRedirectURL();
      }
    },
    {
      title: 'My Classes',
      icon: DashboardIcon,
      to: teacherRoutes.homeScreen
    },
    // @TODO beta version
    // {
    //   title: 'Assignments',
    //   icon: AssignmentIcon,
    //   to: teacherRoutes.userGeneratedAssignments,
    //   featureName: Features.USER_GENERATED_CONTENT,
    //   params: true,
    //   beta: true,
    //   disabled: false
    // },
    // {
    //   title: 'Referrals',
    //   icon: RedeemIcon,
    //   to: teacherRoutes.referForMembership
    // },
    {
      title: 'Resource Hub',
      icon: BookIcon,
      to: teacherRoutes.resourceHub
    },
    // {
    //   title: 'Membership',
    //   icon: StarsIcon,
    //   to: teacherRoutes.membershipsAll,
    //   iconStyle: { fill: '#f8bb00' }
    // },
    {
      title: 'Premium Plans',
      icon: StarsIcon,
      to: teacherRoutes.teacherPremiumPlan,
      badge: priceIncreaseTimer,
      iconStyle: { fill: '#f8bb00' }
    },
    ...mobileSideItems
  ];

  if (
    [
      teacherRoutes.myAccount,
      teacherRoutes.homeScreen,
      teacherRoutes.membershipsAll,
      teacherRoutes.userGeneratedAssignments,
      teacherRoutes.userGeneratedAssignmentsEdit,
      teacherRoutes.userGeneratedAssignmentsEditNew,
      teacherRoutes.assignmentRootReportView,
      teacherRoutes.assignmentReportView,
      teacherRoutes.referForMembership,
      teacherRoutes.resourceHub,
      teacherRoutes.resourceHubInfo,
      teacherRoutes.teacherPremiumPlan,
      teacherRoutes.membershipCheckout
    ].includes(path)
  ) {
    return menuItemArray;
  }
  if (
    [
      teacherRoutes.classStudent,
      teacherRoutes.classDashboard,
      teacherRoutes.classReports,
      teacherRoutes.classReportsReadingLevel,
      teacherRoutes.classReportsSkills,
      teacherRoutes.classReportsAssignmentTool,
      teacherRoutes.membershipsClass,
      teacherRoutes.classPlanner,
      teacherRoutes.classAssignmentReport,
      teacherRoutes.rewardsPage,
      teacherRoutes.teacherPremiumPlanClass,
      teacherRoutes.leaderboardReport,
      teacherRoutes.newLEleaderboardReport,
      teacherRoutes.questionsAnsweredReport,
      teacherRoutes.classReportsPlacementTest,
      teacherRoutes.classActivityReport,
      teacherRoutes.classMissionReport,
      teacherRoutes.assignmentReportClassView,
      teacherRoutes.resourceHubClass,
      teacherRoutes.resourceHubInfoClass,
      teacherRoutes.classMissions,
      teacherRoutes.classMissionsGame,
      teacherRoutes.membershipCheckout,
      teacherRoutes.newLEClassActivityReport
    ].includes(path)
  ) {
    const disableButton = () => {
      return false;
      //@ts-ignore
      // return !store.getState().class.currentClass.roster.length;
    };

    const menuItems: any = [
      {
        title: 'Return',
        icon: ArrowBackIcon,
        onClick: () => {
          window.location.href = getRedirectURL();
        }
      },
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        to: teacherRoutes.classDashboard,
        params: true
      },
      {
        title: 'Players',
        icon: PersonIcon,
        to: teacherRoutes.classStudent,
        params: true,
        disabled: disableButton(),
        badge: {
          active: summerNotification.dot,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          color: 'default',
          badgeOverride: {
            color: colors.red,
            top: '8px',
            right: '-16px',
            '& svg': { fontSize: '15px' }
          },
          component: StyledBadge,
          text: FiberManualRecordIcon
        }
      },
      {
        title: 'Assignments Calendar',
        icon: CalendarTodayIcon,
        to: teacherRoutes.classPlanner,
        params: true,
        disabled: disableButton()
      },

      {
        title: 'Reports',
        icon: AssessmentIcon,
        to: teacherRoutes.classReports,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Give Rewards',
        icon: CardGiftcard,
        to: teacherRoutes.rewardsPage,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Resource Hub',
        icon: BookIcon,
        to: teacherRoutes.resourceHubClass,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Class Missions',
        icon: SportsEsportsOutlined,
        to: teacherRoutes.classMissions,
        params: true,
        beta: !showClassMissionTrials,
        badge: {
          active:
            isMissionMonday &&
            !(userData.premium && userData.premium.isPremium),
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          badgeOverride: {
            [theme.breakpoints.down('md')]: {
              right: '-20px'
            },
            top: '15px',
            right: '-23px',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            background: '#40d2d9',
            '& svg': { fontSize: '20px', color: '#fff' }
          },
          component: StyledBadge,
          text: TimerIcon
        },
        iconStyle: { height: '1.1em', width: '1.1em' }
      },
      {
        title: 'Premium Plans',
        icon: StarsIcon,
        to: teacherRoutes.teacherPremiumPlanClass,
        badge: priceIncreaseTimer,
        iconStyle: { fill: '#f8bb00' }
      },
      ...mobileSideItems
    ];
    if (isMobile) {
      menuItems.unshift({
        title: 'Back to Classes',
        icon: ArrowBackIcon,
        to: teacherRoutes.homeScreen
      });
    }
    return menuItems;
  }
  return [];
};
