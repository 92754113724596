import React from 'react';
import SignInImg from '../../assets/google/btn_google_signin_dark_normal_web@2x.png';

interface GoogleButtonProps {
  type: 'signup' | 'login';
  style?: any;
  imgWidth?: number;
  onClick: () => void;
}

const GoogleButton: React.FC<GoogleButtonProps> = (
  props: GoogleButtonProps
) => {
  let img = SignInImg;
  return (
    <a style={{ ...props.style, cursor: 'pointer' }} onClick={props.onClick}>
      <img
        alt="Google button"
        src={img}
        style={{ width: props.imgWidth ? props.imgWidth : 210 }}
      />
    </a>
  );
};

export default GoogleButton;
