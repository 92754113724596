import React from 'react';
import { checkUserTerms } from '../../redux/actions/user-actions';
import { useAppSelector } from '../../redux/hooks';
import { loginRoutes } from '../../constants/routes';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';

export const TermsAndConditionPage = () => {
  const userData = useAppSelector(state => state.userData);
  const dispatch = useDispatch();
  if (!userData) {
    dispatch(push(loginRoutes.login));
  }
  checkUserTerms(userData);
  return <span />;
};
