import { API_METHODS } from '../api-constants';
import { generateApi } from '../util/api-promisfy';
import {
  USER_GET_SUCCESS,
  USER_LOGOUT,
  USER_SET_SCHOOL_ID,
  USER_UPDATE_DATA
} from '../types/user/user-action-type';
import mixpanel from '../../utils/mixpanel';
import { push } from 'connected-react-router';
import {
  userTypesDashboardRoute,
  userTypes,
  userTypesLoginRoute
} from '../../constants/users';
import { commonRoutes, loginRoutes } from '../../constants/routes';
import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import {
  updateRegistrationEvents,
  trackInviteParentSuccessful,
  trackSetUserABVersion,
  updateReferrer,
  updateUTMData
} from './mixpanel-actions';
import { Debug } from '../../utils/debug';
import { CLASS_UPDATE_PLAYER } from '../types/class/class-action-types';
import { hide, show } from 'redux-modal';
import store from '../store';
import { updateTodoListItem } from './class-actions';
import { JOB_QUEUE_SHOULD_WATCH } from '../types/job-queue/job-queue-action-types';
import { setFeatureFlag } from '../feature-flags/feature-flag-actions';
import { MODAL_TERMS_AND_CONDITIONS } from '../../constants/modals';
import { TermsAndConditionModalProps } from '../../components/popups/modals/terms-and-conditions/TermsAndCondition.modal';
import { LocalStorage } from '../../enums/LocalStorage';
import { useDispatch } from 'react-redux';
import { isDynamicRoute } from '../../hooks/useAuthentication';

export function userLogout() {
  return (dispatch: any) => {
    const { userData, featureFlag }: any = store.getState();
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: JOB_QUEUE_SHOULD_WATCH, payload: false });
    dispatch(setFeatureFlag(featureFlag));
    localStorage.removeItem('persist:state');
    localStorage.removeItem('user-token');
    mixpanel.track('Logout');
    if (userData.userType) {
      return dispatch(push(`${userTypesLoginRoute[userData.userType]}`));
    } else {
      return dispatch(push(loginRoutes.login));
    }
  };
}

/**
 * @description checks if user should see terms and conditions
 */
export async function checkUserTerms(user: any) {
  const dispatch = store.dispatch;
  const response = await dispatch(
    fetchApi({
      url: 'user/terms',
      method: APIMethods.GET
    })
  );
  const { terms } = response.data;
  if (terms) {
    const termsAndConditionModalProps: TermsAndConditionModalProps = {
      title: 'Terms And Conditions',
      buttonText: 'Accept',
      content: terms.content,
      declineButton: true,
      onClick: didAccept => {
        updateUserTermsAndService(didAccept, user, terms, dispatch);
      }
    };
    dispatch(
      show('modal', {
        ...termsAndConditionModalProps,
        type: MODAL_TERMS_AND_CONDITIONS
      })
    );
  }
}

/**
 * @description checks and updates accepted terms of service for user
 * @param didAccept
 * @param user
 * @param terms
 * @param dispatch
 */
export function updateUserTermsAndService(
  didAccept: boolean,
  user: any,
  terms: any,
  dispatch: any
) {
  if (didAccept) {
    const acceptedTerms = [];
    acceptedTerms.push({
      version: terms.version,
      acceptedDate: new Date()
    });
    dispatch(
      updateUserInformation({
        ...user,
        termsAccepted: acceptedTerms
      })
    );
    dispatch(push(userTypesDashboardRoute[user.userType]));
  } else {
    dispatch(userLogout());
  }
  localStorage.removeItem(LocalStorage.TERMS);
}

function handleRegistrationFlowC(userData: any, dispatch: any) {
  const { featureFlag }: any = store.getState();

  if (
    featureFlag.newRegistrationFlow &&
    userData.dreamscape &&
    userData.newRegistrationFlow === 'C' &&
    !userData.premium.isPremium
  ) {
    dispatch(push(`${commonRoutes.premiumFreeTrial}?mandatory=true`));
  }
}

/**
 * @description gets user data
 * @param token
 * @param redirect
 */
export function getUser(
  token: string,
  redirect: boolean = false,
  spinnerMessage = 'Loading your Dreamscape Dashboard',
  showSpinner = true,
  redirectUrl?: string
) {
  return (dispatch: any, getState: any) => {
    dispatch(
      fetchApi({
        url: 'user/v2',
        method: APIMethods.GET,
        spinnerMessage: showSpinner ? spinnerMessage : undefined
      })
    )
      .then((response: any) => {
        const { user, terms } = response.data;

        let payload = {
          ...user,
          token
        };
        dispatch({
          type: USER_GET_SUCCESS,
          payload
        });

        handleRegistrationFlowC(user, dispatch);

        /**
         * In order for our components to easily restrict features, add it to the feature flag slice
         * this will allow components to check if a user has access to the feature.
         */
        if (payload.features) {
          const { featureFlag }: any = getState();
          const { features } = payload;
          if (featureFlag.newLearningEngine) {
            features.userGeneratedContent.hasAccess = true;
          }
          dispatch(setFeatureFlag({ ...features }));
        }

        if (payload.userType !== userTypes.parent) {
          dispatch(hide('spinner'));
        }

        const getRedirectPath = (redirectTo: string, currentRoute: string) => {
          if (currentRoute) {
            if (
              (payload.userType === userTypes.teacher ||
                payload.userType === userTypes.parent) &&
              redirectTo?.includes('assignment-tool')
            ) {
              const splittedPath = redirectTo.split('/assignment-tool/');
              const classes = getState().class.classes;
              const unArchievedClasses = classes.filter(
                (x: any) => x.archived === false
              );
              const currentClass = unArchievedClasses[0].code;
              return `${splittedPath[0]}/assignment-tool/${currentClass}`;
            }
            return !isDynamicRoute(currentRoute) ? redirectTo : null;
          }
          return redirectTo;
        };

        if (!user.newRegistrationFlow && !user.abVersion) {
          dispatch(setABVersion());
        }

        if (redirect) {
          if (terms) {
            dispatch(push(commonRoutes.termsAndConditions));
          } else {
            let redirectPath = null;
            const redirectTo = getState().router?.location?.state?.redirectTo;
            const currentRoute = getState().router?.location?.state?.route;

            redirectPath = getRedirectPath(redirectTo, currentRoute);
            dispatch(
              push(
                redirectUrl ||
                  redirectPath ||
                  userTypesDashboardRoute[user.userType]
              )
            );
          }
        }
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
}

export function updateUserPremiumData(updatedUserPremiumData: any) {
  return {
    type: USER_UPDATE_DATA,
    payload: { premium: updatedUserPremiumData }
  };
}

export function inviteParent(inviteData: any, classCode: string) {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'user/inviteParent',
        method: APIMethods.POST,
        spinnerMessage: `Sending invite to ${inviteData.email}`,
        hideSpinnerAfter: true,
        notificationSuccess: 'Invite Sent',
        notificationError:
          'There was an error sending the invite, please try again',
        data: inviteData
      })
    )
      .then((response: any) => {
        dispatch({
          type: CLASS_UPDATE_PLAYER,
          payload: {
            _id: inviteData.player._id,
            parentConnected: {
              ...inviteData.player.parentConnected,
              inviteSent: true
            }
          }
        });
        dispatch(updateTodoListItem('inviteParent'));
        dispatch(trackInviteParentSuccessful());
      })
      .catch((error: any) => {
        Debug.log(error);
      })
      .finally(() => dispatch(hide('modal')));
  };
}

export function triggerMixpanelEvent(
  eventName: string,
  userId: string,
  properties?: any
) {
  console.log('coming in triggermixpanle');
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'user/track',
        method: APIMethods.POST,
        data: {
          eventName,
          userId,
          properties
        }
      })
    )
      .then(() => {
        console.log('Event successfully delivered.');
      })
      .catch((err: any) => {
        console.log('Error in triggering event', err);
      });
  };
}

export function updateUserInformation(userData: any) {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `user/${userData._id}`,
        method: APIMethods.PUT,
        data: userData,
        spinnerMessage: 'Updating account information',
        hideSpinnerAfter: true,
        notificationSuccess: 'Information updated successfully',
        notificationError: 'Unable to update account information at this moment'
      })
    )
      .then((response: any) => {
        updateRegistrationEvents(userData);
        dispatch({
          type: USER_GET_SUCCESS,
          payload: {
            ...response.data
          }
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
}

export function updateUserMarketingInfo(userData: any) {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `user/${userData._id}`,
        method: APIMethods.PUT,
        data: userData
      })
    )
      .then((response: any) => {
        dispatch(updateReferrer(userData));
        dispatch(updateUTMData(userData));
        dispatch({
          type: USER_GET_SUCCESS,
          payload: {
            ...response.data
          }
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
}

export function userSetSchoolId(id: string) {
  return {
    type: USER_SET_SCHOOL_ID,
    payload: {
      id
    }
  };
}

export function sendResetEmail(email: string) {
  return generateApi({
    url: 'user/forgot',
    method: API_METHODS.POST,
    /**
     * for some reason the back end expects an array
     * @todo change this to just email field in the api
     * */
    data: [
      {
        username: email,
        product: 'dreamscape'
      }
    ]
  });
}

export function resetPassword(password: string, token: string) {
  return generateApi({
    url: `user/forgot/${token}`,
    method: API_METHODS.PUT,
    data: [
      {
        password
      }
    ]
  });
}

export function setABVersion() {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `user/abversion`,
        method: APIMethods.POST
      })
    )
      .then((response: any) => {
        const abVersion = response.data.version;
        dispatch({
          type: USER_UPDATE_DATA,
          payload: { abVersion }
        });
        dispatch(trackSetUserABVersion({ abVersion }));
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
}
