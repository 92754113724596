/**
 * @description beta or internal user validator
 * @param email
 */
export function isBetaUser(email: string) {
  return (
    email &&
    (email.includes('@squigglepark.com') || email.includes('@eyeread.co')) &&
    !email.includes('learning@squigglepark.com')
  );
}
