import React, { CSSProperties } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useMediaQuery, useTheme } from '@material-ui/core';

/**
 * This component is an abstract component allowing you to trigger a modal
 * you have to provide the UI as children of the component
 */
interface ModalAbstractProps {
  show: any;
  handleHide?: any;
  hideOnOutsideClick?: boolean;
  children: any;
  style?: CSSProperties;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  classes?: object;
  fullScreen?: boolean;
}

const ModalAbstract: React.FC<ModalAbstractProps> = (props: any) => {
  const {
    show,
    handleHide,
    children,
    hideOnOutsideClick,
    style,
    fullWidth,
    maxWidth,
    fullScreen,
    classes
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const showFullScreen = fullScreen || isSmallScreen;
  return (
    <Dialog
      open={show}
      onClose={hideOnOutsideClick ? handleHide : null}
      aria-labelledby="form-dialog-title"
      style={style}
      classes={classes}
      scroll={'paper'}
      fullWidth={fullWidth}
      fullScreen={showFullScreen}
      maxWidth={maxWidth}
    >
      {children}
    </Dialog>
  );
};

export default ModalAbstract;
