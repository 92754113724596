import React, { useState, useEffect, createRef } from 'react';
import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmail } from 'validator';
import { makeStyles } from '@material-ui/styles';
import Button from '../../components/button/Button';
import { registerUser } from '../../redux/actions/register-actions';
import colors from '../../styles/colors';
import _ from 'lodash';
import {
  referralOptions,
  adminSpecialties,
  teacherSpecialties,
  userTypes,
  userTypesDescription
} from '../../constants/users';
import CleverButton from '../../components/button/CleverButton';
import { RegistrationData } from '../../types/registration/registration-data-type';
import { loginUser } from '../../redux/login/login-actions';
import { show, hide } from 'redux-modal';
import { MODAL_DEFAULT } from '../../constants/modals';
import { isMobile } from 'react-device-detect';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import useQueryParams from '../../hooks/UseQueryParams';
import clsx from 'clsx';
import Spinner from '../../components/spinner/Spinner';
import { RegistrationUserTypes } from '../../types/registration/registration-user-types';
import GoogleButton from '../../components/button/GoogleButton';
import { loginWithGoogle } from '../../redux/actions/google-actions';
import { getUtmProperties } from '../../utils/utm';
import ReferrerSelection from '../../components/referrer-selection/ReferrerSelection';
import { links } from '../../constants/links';
import { trackRegister } from '../../utils/google/google-analytics';
import { AxiosResponse } from 'axios';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import mixpanel from '../../utils/mixpanel';
import { UserType } from '../../enums/UserType';
import { getLocalStorage } from '../../utils/localStorage';
import { getRedirectUrlForRegistrationFlows } from '../../utils/registrations/getRedirectUrlForRegistrationFlows';
import {
  adminRegistered,
  adminSignUpButtonClicked,
  adminSignUpPageViewed
} from '../../redux/actions/mixpanel-actions';

const useStyles = makeStyles(() => ({
  anchor: {
    color: colors.blueDark,
    fontWeight: 300,
    textDecoration: 'none'
  },
  paragraph: {
    fontSize: 13,
    marginBottom: 20
  },
  backButton: {
    color: colors.primary,
    display: 'flex',
    left: 30,
    marginTop: isMobile ? 20 : 0,
    position: isMobile ? 'initial' : 'absolute',
    textDecoration: 'none',
    top: 90
  },
  registerContainer: {
    background: '#fff',
    border: '1px solid #dcdcdc',
    borderRadius: 5,
    marginBottom: 20,
    padding: isMobile ? 20 : 40,
    position: 'relative',
    transition: '0.3s',

    '&:before': {
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      content: "''",
      height: 0,
      position: 'absolute',
      top: -11,
      transition: '0.3s',
      width: 0
    }
  },
  registerParent: {
    border: '2px solid #4baf50',
    '&:before': {
      borderBottom: '10px solid #4baf50',
      left: (props: any) => props.left
    }
  },
  registerTeacher: {
    border: '2px solid #00b0ff',
    '&:before': {
      borderBottom: '10px solid #00b0ff',
      left: 70
    }
  },
  registerAdmin: {
    border: '2px solid #ffa000',
    '&:before': {
      borderBottom: '10px solid #ffa000',
      left: 415
    }
  },
  registerStudent: {
    border: '2px solid #775bbd',
    height: 340,
    '&:before': {
      borderBottom: '10px solid #775bbd',
      left: 210
    }
  }
}));

enum EmailErrorMessages {
  alreadyExists = 'Looks like we already have a user registered with this email. Please, try a different one.',
  invalidEmail = 'Please, type a valid email'
}

interface RegisterFormProps {
  isMembershipFlow: boolean;
  defaultEmail: string;
  userType: RegistrationUserTypes;
  shouldUseNewRegistrationFlow: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = (
  props: RegisterFormProps
) => {
  const classes = useStyles(
    props.isMembershipFlow ? { left: 285 } : { left: 350 }
  );
  const { defaultEmail, shouldUseNewRegistrationFlow } = props;
  const match: any = useRouteMatch();
  const location: any = useLocation();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const formRef: any = createRef();
  const [specialties, setSpecialties] = useState(teacherSpecialties);
  const [refError, setRefError] = useState({
    referrer: false,
    referrerDropDown: false
  });
  const [values, setValue] = useState({
    firstName: '',
    firstNameChanged: false,
    lastName: '',
    lastNameChanged: false,
    email: defaultEmail || '',
    emailChanged: false,
    password: '',
    passwordChanged: false,
    position: specialties[0],
    userType: props.userType,
    referrerType: '',
    referrerText: '',
    referrerTextChanged: false,
    schoolProfileLink: ''
  });
  const [emailMessage, setEmailMessage] = useState(
    EmailErrorMessages.invalidEmail
  );
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [teacherReferral, setTeacherReferral] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const featureFlag = useSelector((state: any) => state.featureFlag);

  const firstNameError = () => !values.firstName && values.firstNameChanged;
  const lastNameError = () => !values.lastName && values.lastNameChanged;
  const emailError = () =>
    (!values.email && values.emailChanged) ||
    (!isEmail(values.email) && values.emailChanged) ||
    emailAlreadyExists;
  const passwordError = () =>
    (!values.password && values.passwordChanged) ||
    (values.password.length < 8 && values.passwordChanged);

  useEffect(() => {
    if (props.userType === 'School Admin') {
      dispatch(adminSignUpPageViewed);
    }
  }, []);
  useEffect(() => {
    if (queryParams.signUpEmail) {
      setValue({
        ...values,
        email: queryParams.signUpEmail,
        emailChanged: true
      });
    }
    if (queryParams.rf) {
      setTeacherReferral(queryParams.rf);
    }
  }, []);

  useEffect(() => {
    const newSpecialties =
      props.userType === userTypes.teacher
        ? teacherSpecialties
        : adminSpecialties;
    setSpecialties(newSpecialties);
    setValue({
      ...values,
      userType: props.userType,
      position: newSpecialties[0]
    });

    if (isMobile && formRef.current) {
      $('html, body').animate({ scrollTop: formRef.current.offsetTop });
    }
  }, [props.userType]);

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    if (name === 'email') {
      value = value.replace(/ /g, '');
      if (emailMessage === EmailErrorMessages.alreadyExists) {
        setEmailAlreadyExists(false);
        setEmailMessage(EmailErrorMessages.invalidEmail);
      }
    }
    const newState = {
      ...values,
      [name]: value,
      [`${name}Changed`]: true
    };
    setValue(newState);
  };

  const handleReferrerChange = (e: any) => {
    const newState = {
      ...values,
      referrerType: e.referrerType,
      referrerText: e.referrerText,
      referrerTextChanged: e.referrerTextChanged
    };
    setValue(newState);
  };

  const referrerIsValid = () => {
    if (values.referrerType === '') {
      setRefError({ referrer: true, referrerDropDown: false });
    } else if (
      (values.referrerType === 'Email' ||
        values.referrerType === 'Social Media' ||
        values.referrerType === 'Conference' ||
        values.referrerType === 'Other') &&
      values.referrerText === ''
    ) {
      setRefError({ referrer: false, referrerDropDown: true });
    } else {
      return true;
    }
  };

  const formIsValid = () => {
    if (props.userType === userTypes.teacher) {
      return (
        !emailError() &&
        !passwordError() &&
        values.emailChanged &&
        values.passwordChanged
      );
    }
    return (
      referrerIsValid() &&
      !firstNameError() &&
      !lastNameError() &&
      !emailError() &&
      !passwordError() &&
      values.emailChanged &&
      values.firstNameChanged &&
      values.lastNameChanged &&
      values.passwordChanged
    );
  };

  const handleSignUp = () => {
    if (
      location.pathname.includes('/educator') &&
      shouldUseNewRegistrationFlow &&
      getLocalStorage('newRegistrationFlow') !== undefined
    ) {
      mixpanel.track('Onboarding - Email Sign-up Clicked', {
        registrationFlow: getLocalStorage('newRegistrationFlow')
      });
    }
    if (!formIsValid()) {
      return setValue({
        ...values,
        emailChanged: true,
        passwordChanged: true,
        lastNameChanged: true,
        firstNameChanged: true
      });
    }
    let linkReferrer = {};
    if (queryParams.referrer) {
      linkReferrer = {
        linkReferrer: queryParams.referrer,
        linkSubName: queryParams.subName,
        linkSubTitle: queryParams.subTitle
      };
    }
    const newUser: RegistrationData = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      userType: values.userType,
      referrer: [{ type: values.referrerType, text: values.referrerText }],
      linkReferrer: linkReferrer,
      teacherReferralId: teacherReferral,
      schoolProfileLink: values.schoolProfileLink
    };

    const utmProperties = getUtmProperties();
    if (utmProperties) {
      newUser.utm = { ...utmProperties };
    }
    if (props.userType !== userTypes.parent) {
      newUser.specialty = values.position;
    }
    if (queryParams.chId) {
      newUser.childId = queryParams.chId;
    }
    if (
      shouldUseNewRegistrationFlow &&
      location.pathname.includes('/educator')
    ) {
      //TODO test this!!!
      newUser.newRegistrationFlow =
        getLocalStorage('newRegistrationFlow') ||
        'value not set in local storage';
    } else if (
      location.pathname.includes('/educator') &&
      !shouldUseNewRegistrationFlow
    ) {
      newUser.newRegistrationFlow = 'A';
    }
    setLoading(true);
    dispatch(registerUser(newUser))
      .then((response: AxiosResponse) => {
        try {
          const { data } = response;
          if (values.userType === 'School Admin') {
            dispatch(adminRegistered(false));
          }
          const redirectUrlParam = getRedirectUrlForRegistrationFlows(
            shouldUseNewRegistrationFlow,
            values.userType
          );

          dispatch(
            loginUser(values.email, values.password, redirectUrlParam, 'email')
          );
          trackRegister(data._id, data.userType);
        } catch (err) {
          console.error(err);
        }
      })
      .catch((error: any) => {
        dispatch(hide('spinner'));
        setLoading(false);
        if (error.toString().includes('Network Error')) {
          return dispatch(
            show('modal', {
              type: MODAL_DEFAULT,
              children:
                "Looks like we're facing some internal issue. Please, try again later."
            })
          );
        }
        if (error.toString().includes('400')) {
          setEmailAlreadyExists(true);
          setEmailMessage(EmailErrorMessages.alreadyExists);
        }
      });
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  if (props.userType === '') {
    return null;
  }

  let position: any = (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Job Title</InputLabel>
      <Select
        fullWidth
        value={values.position}
        onChange={handleChange}
        inputProps={{ name: 'position' }}
      >
        {specialties.map((item, index: number) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  if (props.userType === 'Parent') {
    position = null;
  } else {
    if (props.userType === 'Teacher') {
      if (shouldUseNewRegistrationFlow) {
        position = null;
      } else {
        position = (
          <Grid item xs={12}>
            {position}
          </Grid>
        );
      }
    } else {
      position = (
        <>
          <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <RadioGroup
              name="userType"
              value={values.userType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="School Admin"
                control={<Radio color="primary" />}
                label="School"
              />
              <FormControlLabel
                value="District Admin"
                control={<Radio color="primary" />}
                label="District"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            {position}
          </Grid>
        </>
      );
    }
  }

  const findContainerClassName = (userType: string) => {
    if (userType === userTypes.parent) return classes.registerParent;
    if (userType === userTypes.teacher) return classes.registerTeacher;
    if (userType === userTypes.student) return classes.registerStudent;
    return classes.registerAdmin;
  };

  const renderUserTypeDescription = (userType: string) => {
    if (userType === userTypes.parent) {
      return userTypesDescription.parent;
    }
    if (userType === userTypes.teacher) {
      return;
    }
    if (userType === userTypes.student) {
      return userTypesDescription.student;
    }
    return userTypesDescription.admin;
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (props.userType === userTypes.student) {
    return (
      <Container
        ref={formRef}
        maxWidth="xs"
        className={clsx(
          classes.registerContainer,
          !isMobile && findContainerClassName(props.userType)
        )}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid container spacing={1} justify="center">
          <Typography align="center" style={{ fontSize: '14px' }}>
            {renderUserTypeDescription(props.userType)}
          </Typography>
          <Typography
            style={{ fontSize: 20, marginTop: 20, textAlign: 'center' }}
          >
            Players create their accounts in-game
          </Typography>
          <Button
            primary
            fullWidth
            style={{ margin: 20 }}
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_WEBGL_LINK}`)
            }
          >
            Play Dreamscape now!
          </Button>
          <Typography>
            Already have an account?
            <a
              href="#"
              style={{
                color: colors.primary,
                marginLeft: 10,
                textDecoration: 'none'
              }}
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_WEBGL_LINK}`)
              }
            >
              Go Play!
            </a>
          </Typography>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <Container
        ref={formRef}
        maxWidth="xs"
        className={clsx(
          classes.registerContainer,
          !isMobile && findContainerClassName(props.userType)
        )}
      >
        <Grid container spacing={1} xs={12} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h4"
              style={{ marginBottom: 20 }}
            >
              Sign up as a {props.userType}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" style={{ fontSize: '14px' }}>
              {renderUserTypeDescription(props.userType)}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <GoogleButton
              type="signup"
              style={{
                marginBottom: isMobile ? 10 : 0,
                width: '190px'
              }}
              imgWidth={190}
              onClick={() => {
                dispatch(adminSignUpButtonClicked(true));
                dispatch(loginWithGoogle(location.pathname));
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '10px', textAlign: 'center' }}>
            {props.userType === userTypes.teacher ? (
              <CleverButton
                type="signup"
                style={{
                  marginBottom: isMobile ? 10 : 0,
                  width: '190px'
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ textAlign: 'center', color: colors.secondaryText }}
            >
              or
            </Typography>
          </Grid>
          {props.userType === userTypes.teacher &&
          shouldUseNewRegistrationFlow ? null : (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  error={firstNameError()}
                  helperText={
                    firstNameError() ? 'Please, type your first name' : ''
                  }
                  variant="outlined"
                  margin="normal"
                  autoComplete="new-firstName"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  error={lastNameError()}
                  helperText={
                    lastNameError() ? 'Please, type your last name' : ''
                  }
                  variant="outlined"
                  margin="normal"
                  autoComplete="new-lastName"
                  fullWidth
                />
              </Grid>
            </>
          )}
          {position}
          <Grid item xs={12}>
            <TextField
              type="email"
              name="email"
              label="Your Email"
              value={values.email}
              onChange={handleChange}
              error={emailError()}
              helperText={emailError() ? emailMessage : ''}
              variant="outlined"
              margin="normal"
              autoComplete="new-email"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Your Password"
              value={values.password}
              onChange={handleChange}
              error={passwordError()}
              helperText={
                passwordError()
                  ? 'Please type a safe password with at least 8 characters'
                  : ''
              }
              variant="outlined"
              // margin="normal"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      style={{ zIndex: 1, color: '#c4c4c4' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </Grid>
          {props.userType === userTypes.teacher &&
          shouldUseNewRegistrationFlow ? null : (
            <ReferrerSelection
              showError={refError}
              handleValueChange={handleReferrerChange}
            />
          )}

          <Grid item xs={12}>
            <Typography component="p" className={classes.paragraph}>
              By clicking Sign Up, you agree to {`the `}
              <a
                target="_blank"
                href={links.termsAndConditions}
                className={classes.anchor}
                rel="noopener noreferrer"
              >
                {`Terms of Service`}
              </a>
              {` and `}
              <a
                target="_blank"
                href={links.privacyPolicy}
                className={classes.anchor}
                rel="noopener noreferrer"
              >
                {`Privacy Policy`}
              </a>
              .
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              green
              loginpage={true}
              onClick={() => {
                if (!formIsValid()) {
                  return setValue({
                    ...values,
                    emailChanged: true,
                    passwordChanged: true,
                    lastNameChanged: true,
                    firstNameChanged: true
                  });
                }
                if (props.userType === 'School Admin') {
                  dispatch(adminSignUpButtonClicked(false));
                }
                handleSignUp();
              }}
              style={{
                margin: isMobile ? '20px 0' : 0,
                height: 45,
                paddingTop: 10,
                width: '190px'
              }}
            >
              Sign Up
            </Button>
          </Grid>
          {props.userType === userTypes.teacher && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography
                component="span"
                style={{ color: '#999', fontSize: '14px' }}
              >
                Already have an account? &nbsp;
                <Link
                  to={`${match.path.replace('register', 'login')}`}
                  title="Log in!"
                  style={{
                    color: colors.primary,
                    fontWeight: 600,
                    textDecoration: 'none'
                  }}
                >
                  Login.
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
      {props.userType !== userTypes.teacher && (
        <Grid item container justify="center" alignItems="flex-end">
          <span style={{ color: '#999', marginBottom: '40px' }}>
            Already have an account? &nbsp;
            <Link
              to={`${match.path.replace('register', 'login')}`}
              title="Log in!"
              style={{
                color: colors.primary,
                fontWeight: 600,
                textDecoration: 'none'
              }}
            >
              Login.
            </Link>
          </span>
        </Grid>
      )}
      {shouldUseNewRegistrationFlow && props.userType === userTypes.teacher && (
        <Grid
          item
          xs={12}
          style={{ textAlign: 'center', marginBottom: '40px' }}
        >
          <Typography
            component="span"
            style={{ color: '#999', fontSize: '14px' }}
          >
            Admins can &nbsp;
            <Link
              to="school"
              title="Log in!"
              style={{
                color: colors.primary,
                fontWeight: 600,
                textDecoration: 'none'
              }}
            >
              sign up here.
            </Link>
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default RegisterForm;
