import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { hide, show } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, Edit } from '@material-ui/icons';
import {
  Badge,
  Theme,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import { withRouter } from 'react-router';
import View from '../../components/view/View';
import SearchBar from '../../components/searchbar/SearchBar';
import Button from '../../components/button/Button';
import StudentMembershipIcon from '../../assets/membership/mIcon.png';
import {
  MODAL_ADD_STUDENT,
  MODAL_CLEVER,
  MODAL_REMOVE_PLAYER,
  MODAL_EDIT_STUDENT,
  MODAL_GOOGLE_ACCOUNT_INFO,
  MODAL_GOOGLE_CLASSROOM_SYNC,
  MODAL_SELECT_CLASS,
  MODAL_SEND_PARENT_INVITE
} from '../../constants/modals';
import Table from '../../components/table/Table';
import { removePlayerFromList } from '../../redux/actions/player-actions';
import { downloadPDF } from '../../redux/actions/class-actions';
import { userTypes } from '../../constants/users';
import ClassCodeCopy from '../../components/common/ClassCodeCopy';
import CleverIcon from '../../assets/icons/clever-icon.png';
import GoogleIcon from '../../assets/icons/google-icon.png';
import { isMobile } from 'react-device-detect';
import ChuckContainer from '../../components/chuck/ChuckContainer';
import { DownloadPlayerLoginsBanner } from '../../components/banner/DownloadPlayerLoginsBanner';
import { getPlayerLoginsInfo } from '../../utils/user';
import {
  trackClickInviteParent,
  trackSyncWithGoogleClick
} from '../../redux/actions/mixpanel-actions';
import mixpanel from '../../utils/mixpanel';
import { authorizeGoogleClassroom } from '../../redux/actions/google-actions';
import { playerNounLowerCase } from '../../utils/userNoun';
import { useAppSelector } from '../../redux/hooks';
import { UserType } from '../../enums/UserType';
import { getAllMembers } from '../../redux/actions/members-actions';
import { makeStyles } from '@material-ui/styles';
import { SummerNotification } from '../../features/summer-notification/components/SummerNotification';
import colors from '../../styles/colors';
import useShowSummerNotification from '../../features/summer-notification/hooks/useShowSummerNotification';
import { getUser } from '../../redux/actions/user-actions';

const useStyles = makeStyles((theme: Theme) => ({
  notificationBadge: {
    color: colors.red,
    '& .MuiBadge-badge': {
      top: '10px',
      right: '10px',
      '& svg': { fontSize: '15px' }
    }
  }
}));

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '15px'
  }
}))(Tooltip);

// @ts-ignore
const StudentsView: React.FC<any> = (props: any) => {
  const { match } = props;
  const currentClass = useSelector((state: any) => state.class.currentClass);
  const { members, nonMembers } = useAppSelector(state => state.members);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const userData = useSelector((state: any) => state.userData);
  const [tableState, setTableState] = useState(currentClass.roster || []);
  const dispatch = useDispatch();
  const isPremiumUser = userData.premium && userData.premium.isPremium;
  const classes = useStyles();
  const [showPlayerLoginsBanner, setShowPlayerLoginsBanner] = useState<boolean>(
    false
  );
  const { token } = useAppSelector(state => state.login);
  useEffect(() => {
    setTableState(currentClass.roster);
  }, [currentClass.roster]);

  useEffect(() => {
    dispatch(getAllMembers());
  }, [currentClass, dispatch]);

  useEffect(() => {
    const playerLoginsInfoString = getPlayerLoginsInfo();
    if (playerLoginsInfoString !== null) {
      const playerLoginsInfo = JSON.parse(playerLoginsInfoString);
      const classArr = Object.keys(playerLoginsInfo);

      const res = classArr.some((item: any) => {
        if (
          item === currentClass.code &&
          playerLoginsInfo[item].banner &&
          playerLoginsInfo[item].currentLogin === false
        ) {
          setShowPlayerLoginsBanner(true);
          return playerLoginsInfo[item].banner;
        }
        return false;
      });
      setShowPlayerLoginsBanner(res);
    }
  }, [currentClass.code]);

  if (
    !match.params.classCode &&
    !classCode &&
    !(userData.userType === userTypes.parent)
  ) {
    dispatch(
      show('modal', {
        type: MODAL_SELECT_CLASS
      })
    );
    return null;
  }

  const handleEditPlayer = (row: any) => {
    dispatch(getAllMembers());
    dispatch(getUser(token, false));
    dispatch(hide('spinner'));
    dispatch(
      show('modal', {
        type: MODAL_EDIT_STUDENT,
        userData: {
          ...row.row.original
        }
      })
    );
  };

  const handleRemovePlayerClick = (row: any) => {
    let isPremiumPlayer = false;
    const membership = members.find(
      (user: any) => user._id == row.row.original._id
    );
    if (membership) {
      if (membership._id == row.row.original._id) {
        isPremiumPlayer = true;
      }
    }
    dispatch(
      show('modal', {
        type: MODAL_REMOVE_PLAYER,
        title: 'Disconnect player',
        children: () => {
          return (
            <>
              <Typography component="p">
                Are you sure that you want to disconnect this{' '}
                {playerNounLowerCase} from your account?
              </Typography>
            </>
          );
        },
        actions: () => {
          return (
            <>
              <Button
                onClick={() => {
                  dispatch(
                    removePlayerFromList(
                      row.row.original._id,
                      classCode,
                      isPremiumPlayer
                    )
                  );
                  dispatch(getAllMembers());
                  dispatch(getUser(token, false));
                }}
                primary
              >
                Yes, disconnect this {playerNounLowerCase}
              </Button>
            </>
          );
        }
      })
    );
  };

  const handlePrintLogins = () => {
    dispatch(downloadPDF(userData, currentClass));
    mixpanel.track('Summer Download Click', {
      userEmail: userData.email,
      userId: userData._id,
      classCode: currentClass.code
    });
  };

  let columns = [
    {
      Header: 'Name',
      accessor: 'fullname',
      Cell: (row: any) => {
        const { fullname, clever, google, username, _id } = row.row.original;
        let cleverIcon = null;
        let googleIcon = null;
        if (clever) {
          cleverIcon = (
            <img
              src={CleverIcon}
              alt="Clever Account"
              style={{ margin: '2px 10px', width: 15, verticalAlign: 'top' }}
            />
          );
        }
        if (google) {
          googleIcon = (
            <img
              onClick={() =>
                dispatch(
                  show('modal', {
                    type: MODAL_GOOGLE_ACCOUNT_INFO,
                    ...google
                  })
                )
              }
              src={GoogleIcon}
              alt="Google Account"
              style={{
                margin: '2px 10px',
                width: 15,
                verticalAlign: 'top',
                cursor: 'pointer'
              }}
            />
          );
        }
        let membershipIcon = null;
        const isEducator = userData.userType !== UserType.Parent;
        const showMembershipInfo = isEducator && isPremiumUser;
        if (showMembershipInfo) {
          const membership = members.find((user: any) => user._id == _id);
          if (membership && membership.subMeta) {
            membershipIcon = (
              <Tooltip title={'Membership'}>
                <img
                  src={StudentMembershipIcon}
                  height={'26px'}
                  alt="membership icon"
                />
              </Tooltip>
            );
          }
        }
        return (
          <Tooltip
            title={
              showMembershipInfo && !membershipIcon
                ? 'Upgrade your plan to give this user a membership.'
                : ''
            }
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{fullname}</span>
              {cleverIcon}
              {googleIcon}
              &nbsp;
              {membershipIcon}
            </div>
          </Tooltip>
        );
      }
    },
    {
      Header: 'Username',
      accessor: 'username'
    },
    {
      id: 'invite-parent',
      Header: 'Invite Parent',
      show: userData.userType !== userTypes.parent,
      Cell: (row: any) => {
        const item = row.row.original;
        const { joinedWith, parentConnected } = item;
        let isJoinedWith = false;
        let parentConnectedText: any = (
          <Button
            primary
            onClick={() => {
              dispatch(
                show('modal', {
                  type: MODAL_SEND_PARENT_INVITE,
                  playerData: item
                })
              );
              dispatch(trackClickInviteParent());
            }}
          >
            Send Invite
          </Button>
        );
        if (joinedWith.type.includes('Parent')) {
          if (joinedWith.signedUp === true) {
            isJoinedWith = true;
            parentConnectedText = (
              <span
                style={{
                  color: 'green',
                  fontStyle: 'italic'
                }}
              >
                Parent connected
              </span>
            );
          } else {
            isJoinedWith = true;
            parentConnectedText = 'Invite Sent';
          }
        }

        if (!isJoinedWith) {
          if (parentConnected.isConnected) {
            parentConnectedText = (
              <span
                style={{
                  color: 'green',
                  fontStyle: 'italic'
                }}
              >
                Parent connected
              </span>
            );
          } else if (parentConnected.inviteSent) {
            parentConnectedText = 'Invite Sent';
          }
        }
        return <p>{parentConnectedText}</p>;
      }
    },
    {
      Header: 'Password',
      accessor: 'password',
      disableSorting: true,
      Cell: (row: any) => {
        const player = row.row.original;
        if (player.google) {
          if (player.password === '') {
            return (
              <span
                style={{
                  color: '#757575',
                  fontStyle: 'italic'
                }}
              >
                Owned By Player
              </span>
            );
          } else {
            return player.password;
          }
        }
        if (player.password) {
          return player.password;
        }
        return (
          <span
            style={{
              color: '#757575',
              fontStyle: 'italic'
            }}
          >
            Owned By Player
          </span>
        );
      }
    },
    {
      Header: () => <div style={{ textAlign: 'center' }}>Grade</div>,
      accessor: 'grade',
      Cell: (row: any) => (
        <div style={{ textAlign: 'center' }}>{row.row.original.grade}</div>
      )
    },
    {
      Header: () => <div style={{ textAlign: 'center' }}>Actions</div>,
      accessor: 'actions',
      disableSorting: true,
      Cell: (row: any) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Tooltip title="Edit Player">
              <Button onClick={() => handleEditPlayer(row)}>
                <Edit />
              </Button>
            </Tooltip>
            <Tooltip title="Remove Player from list">
              <Button onClick={() => handleRemovePlayerClick(row)}>
                <Delete />
              </Button>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  let title = 'Players';
  let studentType = 'Players';
  if (userData.userType === userTypes.parent) {
    title = 'My Players';
    studentType = 'Players';
  }

  return (
    <>
      {showPlayerLoginsBanner && (
        <DownloadPlayerLoginsBanner
          setBannerClose={setShowPlayerLoginsBanner}
        />
      )}
      <SummerNotification />
      <View title={title}>
        <ChuckContainer />
        <Grid container spacing={3}>
          <Grid item lg={3} sm={12} xs={12}>
            <SearchBar
              placeholder="Search by Player Name"
              searchKey="fullname"
              originalState={currentClass.roster}
              onChange={(searchState: any) => setTableState(searchState)}
            />
          </Grid>
          <Grid item lg={3} sm={5} xs={12}>
            {!(userData.userType === userTypes.parent) && (
              <ClassCodeCopy
                classCode={match.params.classCode}
                style={{ width: isMobile ? '100%' : 250 }}
              />
            )}
          </Grid>
          <Grid
            item
            container
            lg={6}
            sm={7}
            xs={12}
            style={{
              paddingTop: 20,
              paddingBottom: 20
            }}
            justify={isMobile ? 'center' : 'flex-end'}
          >
            {userData.clever ? (
              <Button
                onClick={() =>
                  dispatch(
                    show('modal', {
                      type: MODAL_CLEVER,
                      classCode: match.params.classCode
                    })
                  )
                }
              >
                Sync with Clever
              </Button>
            ) : null}
            <Tooltip
              title={
                userData.clever
                  ? 'Your account is synced with Clever. Try syncing from there instead.'
                  : ''
              }
            >
              <div>
                <Button
                  disabled={userData.clever}
                  onClick={() => {
                    dispatch(trackSyncWithGoogleClick());
                    dispatch(
                      authorizeGoogleClassroom(() =>
                        dispatch(
                          show('modal', {
                            type: MODAL_GOOGLE_CLASSROOM_SYNC,
                            classCode
                          })
                        )
                      )
                    );
                  }}
                >
                  Sync Google Classroom
                </Button>
              </div>
            </Tooltip>
            {userData.userType !== userTypes.parent ? (
              <Button marginLeft={10} onClick={handlePrintLogins}>
                Print logins
              </Button>
            ) : null}
            <Button
              primary
              marginLeft={10}
              onClick={() =>
                dispatch(
                  show('modal', {
                    type: MODAL_ADD_STUDENT
                  })
                )
              }
            >
              Add {studentType}
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid item lg={12}>
          <Table columns={columns} data={tableState} />
        </Grid>
      </View>
    </>
  );
};

export default React.memo(withRouter(StudentsView));
