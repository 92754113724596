import React, { useEffect, useState } from 'react';
import ReportToolTemplate, { getTableOptions } from './ReportToolTemplate';
import ReportToolSkillReportIcon from '../../assets/icons/report/skill-comprehension-icon.png';
import { trackViewSkillsReport } from '../../redux/actions/mixpanel-actions';
import { Grid, Typography } from '@material-ui/core';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { ReportTools, Statuses } from '../../redux/features/ReportTool';
import { useAppSelector } from '../../redux/hooks';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import Table from '../table/Table';
import moment from 'moment';

interface ReportToolSkillReportProps {
  isWidget?: boolean;
}

const ReportToolSkillReport: React.FC<ReportToolSkillReportProps> = ({
  isWidget
}: ReportToolSkillReportProps) => {
  let body;
  const { data, status } = useSelector(
    (state: any) => state.reportTool[ReportTools.SkillReport]
  );
  const features = useAppSelector(state => state.featureFlag);
  const { currentClass } = useSelector((state: any) => state.class);
  const playerIds: number[] = currentClass.roster.map(
    (user: any) => user.educationUserId
  );
  const [newLESkillData, setNewLESkillData] = useState([]);
  const [loading, setLoading] = React.useState(!features.newDashboardFlag);

  const dispatch = useDispatch();

  useEffect(() => {
    if (features.newDashboardFlag) {
      fetchSkillData(playerIds)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, []);

  async function fetchSkillData(learnerIds: number[]) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/reports/skills-report`,
          method: APIMethods.POST,
          endpoint: APIEndpoints.LE_API,
          data: {
            learnerIds: learnerIds
          }
        })
      );
      const reportsData: any = response.data;
      if (reportsData) {
        const sortedData = reportsData.sort((a: any, b: any) => {
          return (
            Date.parse(b.skillScoreCreatedAt) -
            Date.parse(a.skillScoreCreatedAt)
          );
        });
        const skillDataWithPlayerName = sortedData.map((learner: any) => ({
          ...learner,
          name: currentClass.roster.find(
            (player: any) => player.educationUserId === learner.learnerId
          ).fullname
        }));
        setNewLESkillData(skillDataWithPlayerName);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const hasReportToolLoaded =
    status === Statuses.Success || newLESkillData.length > 0;

  const getColorForTier = (tier: number) => {
    switch (tier) {
      case 1:
        return '#88E1C9';
      case 2:
        return '#23BA90';
      case 3:
        return '#027F5D';
      case 4:
        return '#10231E';
      default:
        return '#E0E0E0';
    }
  };
  if (hasReportToolLoaded) {
    body = (
      <>
        <Grid item>
          {features.newDashboardFlag ? (
            <>
              {' '}
              <Table
                columns={[
                  {
                    Header: 'Player',
                    accessor: 'name',
                    Cell: (row: any) => {
                      const { name } = row.row.original;

                      return (
                        <Typography style={{ fontSize: 14 }}>{name}</Typography>
                      );
                    }
                  },
                  {
                    Header: 'Skill',
                    accessor: 'skillName',
                    Cell: (row: any) => {
                      const { skillName, learnerTier } = row.row.original;

                      return (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              // alignItems: 'flex-start'
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Typography style={{ fontSize: 14 }}>
                                {skillName}
                              </Typography>
                            </div>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: 17,
                                  width: 17,
                                  borderRadius: '50%',
                                  marginRight: '8px',
                                  backgroundColor: getColorForTier(learnerTier)
                                }}
                              />
                              <Typography style={{ fontSize: 12 }}>
                                Tier {learnerTier}
                              </Typography>
                            </div>
                          </div>
                        </>
                      );
                    }
                  },
                  {
                    Header: 'Date',
                    accessor: 'skillScoreCreatedAt',
                    Cell: (row: any) => {
                      const { skillScoreCreatedAt } = row.row.original;

                      return (
                        <Typography style={{ fontSize: 14 }}>
                          {moment(skillScoreCreatedAt).format('MMM DD')}
                          <br />
                          <span style={{ textAlign: 'right' }}>
                            {moment(skillScoreCreatedAt).format('YYYY')}
                          </span>
                        </Typography>
                      );
                    }
                  }
                ]}
                data={newLESkillData}
                {...getTableOptions(isWidget)}
                elevation={0}
                height="300px"
                overflowY={isWidget ? 'scroll' : 'none'}
              />
            </>
          ) : (
            <>
              <BarChart
                width={290}
                height={290}
                data={data.slice(0, 4)}
                layout="vertical"
                margin={{
                  top: 10,
                  right: 10,
                  left: 10,
                  bottom: 10
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="Name" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Correct" stackId="a" fill="#63da8b" />
                <Bar dataKey="Incorrect" stackId="a" fill="#da6398" />
              </BarChart>
            </>
          )}
        </Grid>
      </>
    );
  } else {
    body = null;
  }

  return (
    <ReportToolTemplate
      title={features.newDashboardFlag ? 'Skill Achievements' : 'Skills'}
      reportURL="skill-comprehension"
      reportToolState={[ReportTools.SkillReport]}
      subTitle={features.newDashboardFlag ? '' : 'Top Skills In This Class'}
      tracker={trackViewSkillsReport}
      isWidget={isWidget}
      source={ReportToolSkillReportIcon}
      body={body}
    />
  );
};

export default ReportToolSkillReport;
