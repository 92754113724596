import {
  AssignmentEditState,
  AssignmentEditStatus,
  AssignmentQuestionType,
  DispatchProps
} from './assignment-edit.types';
import {
  validatePassage,
  validateQuestion
} from '../../../util/AssignmentValidation';
import { AssignmentEditActionTypes } from './assignment/assignment-action.types';
import { assignmentDataReducer } from './assignment-data.reducer';

export function assignmentEditReducer(
  state: AssignmentEditState,
  action: DispatchProps
): AssignmentEditState {
  const { type, payload } = action;
  switch (type) {
    /**
     * The three actions below also listen for whether the assignment edit state was edited and marks the objects
     * appropriately. The assignment-question.middleware.ts file is where this property is attached in order to determine
     * if an event that updated the assignment was triggered.
     */
    case AssignmentEditActionTypes.SAVED: {
      return updateObject(state, {
        edit: { saving: false },
        autoSave: action.autoSave
      });
    }
    case AssignmentEditActionTypes.AUTO_SAVE_STARTED: {
      return updateObject(state, {
        autoSave: { ...state.autoSave, saving: true }
      });
    }
    case AssignmentEditActionTypes.AUTO_SAVE_ENDED: {
      return updateObject(state, {
        autoSave: { ...state.autoSave, saving: false }
      });
    }
    case AssignmentEditActionTypes.RETRIEVED_ASSIGNMENT:
    case AssignmentEditActionTypes.UPDATE: {
      let newAssignment = { ...payload };
      if (newAssignment.data) {
        try {
          newAssignment = validateAssignment(newAssignment);
        } catch (err) {
          console.log(err);
        }
      }
      return updateObject(state, {
        ...newAssignment,
        autoSave: action.autoSave || state.autoSave
      });
    }
    case AssignmentEditActionTypes.SAVING: {
      return updateObject(state, { edit: { saving: true } });
    }
    case AssignmentEditActionTypes.GET_SKILLS: {
      const { skills } = payload;
      return updateObject(state, { skills });
    }
    case AssignmentEditActionTypes.GET_QUESTION_TYPE: {
      const { questionTypes } = payload;
      return updateObject(state, { questionTypes });
    }
    case AssignmentEditActionTypes.GET_GENRES: {
      const { genres } = payload;
      return updateObject(state, { genres });
    }
    case AssignmentEditActionTypes.RESET: {
      return updateObject(
        assignmentEditDefaultState(
          { ...initialState, data: [] } as AssignmentEditState,
          action
        ),
        {
          skills: state.skills,
          groups: state.groups,
          genres: state.genres,
          questionTypes: state.questionTypes
        }
      );
    }
    case AssignmentEditActionTypes.UPDATE_EDIT_DATA: {
      return updateObject(state, payload);
    }
    default: {
      return assignmentEditDefaultState(
        { ...state, autoSave: action.autoSave || state?.autoSave },
        action
      );
    }
  }
}

export const assignmentEditDefaultState = (
  state: AssignmentEditState,
  action: DispatchProps
): AssignmentEditState =>
  Object.assign({}, initialState, {
    ...state,
    data: assignmentDataReducer(state?.data, action)
  });

export const initialState: Partial<AssignmentEditState> = {
  name: '',
  grade: -1,
  isValid: false,
  description: '',
  shuffleQuestions: false,
  shuffleAnswers: false,
  skills: [],
  groups: [],
  genres: [],
  questionTypes: [],
  status: AssignmentEditStatus.Draft,
  startDate: new Date(),
  endDate: new Date(),
  updatedAt: new Date(),
  createdAt: new Date(),
  autoSave: {
    saving: false,
    isEdited: false
  },
  dashboardUserId: -1,
  isAssigned: false,
  edit: {
    saving: false
  }
};

/**
 * @description validates assignment
 * @param assignment
 */
export const validateAssignment = (
  assignment: AssignmentEditState
): AssignmentEditState => {
  const currentAssignment = { ...assignment };
  const { data: newData } = assignment;
  currentAssignment.isValid = true;
  for (let i = 0; i < newData.length; i++) {
    let data = newData[i];
    if (!data.isUserGenerated) {
      continue;
    }
    if (data.type === AssignmentQuestionType.Passage) {
      for (
        let passageQuestionIndex = 0;
        passageQuestionIndex < data.questions.length;
        passageQuestionIndex++
      ) {
        newData[i].questions[passageQuestionIndex].isEdited = true;
        const error = validateQuestion(
          newData[i].questions[passageQuestionIndex]
        );
        newData[i].questions[passageQuestionIndex].error = error;
        newData[i].isEdited = true;
        if (error.isError) {
          currentAssignment.isValid = false;
        }
      }
    } else {
      if (data.type === AssignmentQuestionType.Independent) {
        const error = validateQuestion(newData[i]);
        newData[i].error = error;
        if (error.isError) {
          currentAssignment.isValid = false;
        }
        newData[i].isEdited = true;
      } else if (data.type === AssignmentQuestionType.Passage) {
        const error = validatePassage(newData[i]);
        newData[i].error = error;
        if (error.isError) {
          currentAssignment.isValid = false;
        }
        newData[i].isEdited = true;
      }
    }
  }
  return currentAssignment;
};

function updateObject(
  state: AssignmentEditState,
  newState: Partial<AssignmentEditState>
) {
  return Object.assign({}, state, newState);
}
