import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from '../components/navbar/NavBar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh'
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  scrollAble: {
    height: 'calc(100vh - 64px)',
    overflowY: 'scroll'
  }
}));

const AppBarLayout: React.FC = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavBar drawer={false} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.scrollAble}>{props.children}</div>
      </main>
    </div>
  );
};

export default AppBarLayout;
