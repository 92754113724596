import {
  PDF_GET_CLASS_PASSWORDS,
  PDF_SEND_TEACHER_CERTIFICATE,
  PDF_SEND_TEACHER_CERTIFICATE_FAILED
} from '../types/pdf/pdf-action-types';

export const pdfReducer = (
  state = {},
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case PDF_GET_CLASS_PASSWORDS:
      return {
        ...state,
        classPasswordPDF: payload
      };
    case PDF_SEND_TEACHER_CERTIFICATE:
      return state;
    case PDF_SEND_TEACHER_CERTIFICATE_FAILED:
      return state;
    default:
      return state;
  }
};
