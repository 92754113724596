import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';

export const sendTrainingEmail = (emails: any) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'user/bookTraining',
        method: APIMethods.POST,
        data: { emails },
        spinnerMessage: 'Scheduling your training session',
        hideSpinnerAfter: true,
        successMessage:
          'Thank you for requesting your free Dreamscape Training! A member of our partnership team will be in touch shortly to help you get started',
        errorMessage:
          'There was a problem processing your request. Please, try again later'
      })
    );
  };
};

export const downloadCeritficate = (data: any) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'pdf/downloadCertificates',
        responseType: 'blob',
        method: APIMethods.POST,
        data,
        spinnerMessage: 'Downloading certificates',
        hideSpinnerAfter: true,
        errorMessage:
          'There was a problem processing your request. Please, try again later'
      })
    )
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `certificate.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};
