import store from './store';

export enum API_CONSTANTS {
  GET = '[API]: GET',
  DELETE = '[API]: DELETE',
  POST = '[API]: POST',
  PUT = '[API]: PUT'
}

export const API_GET = API_CONSTANTS.GET;
export const API_POST = API_CONSTANTS.POST;
export const API_PUT = API_CONSTANTS.PUT;
export const API_DELETE = API_CONSTANTS.DELETE;

export const API_ACTIONS = [API_GET, API_DELETE, API_POST, API_PUT];

export enum API_METHODS {
  POST = 'post',
  GET = 'get',
  DELETE = 'delete',
  PUT = 'put'
}

export const getMembershipPageUrl = () => {
  //@ts-ignore
  let userToken = (store.getState().login.token || '').replace('JWT ', '');
  const url = process.env.REACT_APP_MEMBERSHIP_PAGE_URL;
  return `${url}?userToken=${userToken}`;
};
