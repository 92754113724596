import React, { useEffect, useMemo } from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Link
} from '@material-ui/core';
import TeacherImg from '../../assets/onboarding/big-teacher_premium_plan_flipped.png';
import PremiumProgressBar from '../../assets/membership/premiumTrialProgressBar.png';
import StarsIcon from '@material-ui/icons/Stars';
import View from '../view/View';
import { useHistory } from 'react-router-dom';
import { userTypes, userTypesDashboardRoute } from '../../constants/users';
import mixpanel from '../../utils/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import {
  triggerMixpanelEvent,
  updateUserInformation
} from '../../redux/actions/user-actions';
import { getLocalStorage } from '../../utils/localStorage';
import useQueryParams from '../../hooks/UseQueryParams';
import moment from 'moment';
import FreeTrialStepper from './Stepper';

interface Iprops {
  headerText: string;
  isMandatoryFreeTrial: boolean;
  isUpsellScreen: boolean;
  heading: string;
  subHeading: string;
  buttonText: string;
  buttonClickHandler: () => void;
  premiumPropertiesArray: {
    id: number;
    imgUrl: string;
    boldDescription: string;
    description: string;
  }[];
}

export const FreeTrialWrapper: React.FC<any> = (props: Iprops) => {
  const theme = useTheme();
  const userData = useSelector((state: any) => state.userData);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallHeightScreen = useMediaQuery('(max-height:600px)');
  const isSmallWidthScreen = useMediaQuery('(min-width:821px)');
  const dispatch = useDispatch();
  const {
    headerText,
    isUpsellScreen,
    isMandatoryFreeTrial,
    heading,
    subHeading,
    buttonText,
    buttonClickHandler,
    premiumPropertiesArray
  } = props;

  const useStyles = makeStyles(theme => ({
    teacherImg: {
      display: isSmallScreen ? 'none' : 'block',
      width: isSmallHeightScreen ? '180px' : '220px'
      // maxWidth: isSmallHeightScreen ? '40%' : '50%'
    }
  }));

  const classes = useStyles();
  const history = useHistory();
  const query = useQueryParams();

  useEffect(() => {
    if (query.mandatory === 'false') {
      dispatch(
        triggerMixpanelEvent(
          'Onboarding - Optional Trial Screen',
          userData._id,
          {
            registrationFlow: getLocalStorage('newRegistrationFlow')
          }
        )
      );
    }
    if (query.mandatory === 'true') {
      dispatch(
        triggerMixpanelEvent(
          'Onboarding - Mandatory Trial Screen',
          userData._id,
          {
            registrationFlow: getLocalStorage('newRegistrationFlow')
          }
        )
      );
    }
  }, [query, userData._id]);

  useEffect(() => {
    if (
      userData.token &&
      (userData.clever || userData.google) &&
      !userData.newRegistrationFlow
    ) {
      const mixpanelEventProps = {
        userType: userData.userType,
        registrationFlow: getLocalStorage('newRegistrationFlow')
      };

      mixpanel.track('Onboarding - Register', mixpanelEventProps, true, {
        send_immediately: true
      });

      dispatch(
        updateUserInformation({
          ...userData,
          newRegistrationFlow: getLocalStorage('newRegistrationFlow')
        })
      );
    }
  }, [userData.newRegistrationFlow, userData.clever, userData.google]);

  const getTrialExpiryDate = useMemo(() => {
    return moment()
      .add(14, 'days')
      .format('DD MMMM');
  }, []);

  const handleFreeAccount = (event: any) => {
    event.preventDefault();
    dispatch(
      triggerMixpanelEvent(
        'Onboarding - Optional Trial Dismissed',
        userData._id,
        {
          registrationFlow: getLocalStorage('newRegistrationFlow')
        }
      )
    );
    history.push(userTypesDashboardRoute[userTypes.teacher]);
  };

  return (
    <View
      flex
      style={{
        maxHeight: '90vh',
        flexDirection: 'column',
        justifyContent: 'normal',
        padding: isSmallScreen || isSmallHeightScreen ? '10px' : '20px'
      }}
    >
      <Paper
        style={{
          border: '2px solid #009DE0',
          marginBottom: isSmallHeightScreen ? '10px' : '40px',
          width: isSmallScreen ? '100%' : '70%',
          maxWidth: '900px'
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            background: '#009DE0',
            padding: ' 14px 20px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <StarsIcon
            fontSize={'medium'}
            style={{
              fill: '#f8bb00',
              width: '3rem'
            }}
          ></StarsIcon>
          <Typography variant="h6" style={{ color: '#fff', fontWeight: '600' }}>
            {headerText}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={isSmallHeightScreen ? 0 : 1}
          style={{
            padding: isSmallHeightScreen ? '10px' : '20px'
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant={isSmallHeightScreen || !isUpsellScreen ? 'h4' : 'h3'}
              style={{ textAlign: 'center', color: '#444' }}
            >
              {heading}
              {isUpsellScreen && (
                <Typography
                  component="span"
                  style={{ fontSize: '14px', color: 'grey' }}
                >
                  USD
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'center', fontSize: '14px' }}>
              {subHeading}
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            sm={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px'
            }}
          >
            <img
              src={TeacherImg}
              alt="teacher"
              className={classes.teacherImg}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            style={{
              padding: isSmallScreen || isSmallHeightScreen ? '5px' : '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              maxHeight: isUpsellScreen ? '300px' : '500'
            }}
          >
            {premiumPropertiesArray.length &&
              premiumPropertiesArray.map(premiumProperties => {
                return (
                  <Grid
                    key={premiumProperties.id}
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding:
                        isSmallScreen || isSmallHeightScreen || !isUpsellScreen
                          ? '0'
                          : '10px 0'
                    }}
                  >
                    <img
                      src={premiumProperties.imgUrl}
                      alt="reportingIcon"
                      style={{ maxWidth: '25px' }}
                    />
                    <Typography
                      style={{ fontWeight: '600', marginLeft: '12px' }}
                    >
                      {premiumProperties.boldDescription} &nbsp;
                      <Typography component="span">
                        {premiumProperties.description}
                      </Typography>
                    </Typography>
                  </Grid>
                );
              })}
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: '10px 0'
              }}
            >
              <Typography>And MUCH more!</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={() => buttonClickHandler()}
              style={{
                padding: isSmallHeightScreen ? '10px' : '15px',
                background: '#009DE0'
              }}
              size="large"
            >
              <Typography style={{ color: '#fff', fontWeight: '600' }}>
                {buttonText}
              </Typography>
            </Button>
          </Grid>
          {!isUpsellScreen && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography
                style={{ textAlign: 'center', color: 'grey', fontSize: '14px' }}
              >
                Cancel anytime
              </Typography>
            </Grid>
          )}
          {!isUpsellScreen && (
            <>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'center',
                  marginTop: '20px'
                }}
              >
                <Typography variant="h5">How it works</Typography>
              </Grid>
              {!isSmallScreen && isSmallWidthScreen ? (
                <Grid
                  item
                  md={12}
                  style={{ marginLeft: 47, padding: '0 40px' }}
                >
                  <FreeTrialStepper trialExpiry={getTrialExpiryDate} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Today</Typography>
                    <Typography style={{ fontSize: '12px' }}>
                      Start using Shoelace in your classroom.
                    </Typography>
                    <Typography style={{ fontSize: '12px', fontWeight: '600' }}>
                      Free for 14 days.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">{getTrialExpiryDate}</Typography>
                    <Typography
                      style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                    >
                      You'll be charged for a year of Shoelace.
                    </Typography>
                    <Typography style={{ fontSize: '12px' }}>
                      Cancel anytime prior.
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Paper>
      {isMandatoryFreeTrial ? null : (
        <Link
          href="#"
          onClick={handleFreeAccount}
          color="inherit"
          variant="body1"
        >
          Continue with a free, limited account
        </Link>
      )}
    </View>
  );
};
