import { UserType } from '../../enums/UserType';
import { getLocalStorage } from '../localStorage';

export const getRedirectUrlForRegistrationFlows = (
  isExperimentRegFlowsEnabled: boolean,
  userType: string
) => {
  const registrationFlowValue =
    isExperimentRegFlowsEnabled &&
    userType === UserType.Teacher &&
    getLocalStorage('newRegistrationFlow');

  switch (registrationFlowValue) {
    case 'A': {
      return '/dashboard/select-location';
    }
    case 'B': {
      return '/dashboard/free-trial?mandatory=false';
    }
    case 'C': {
      return '/dashboard/free-trial?mandatory=true';
    }
    default: {
      return undefined;
    }
  }
};
