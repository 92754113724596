import { REFERRALS_GET_ALL } from '../types/referrals/referrals-action-types';

const initialState: any = [];

export const referralsReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case REFERRALS_GET_ALL:
      return payload;
    default:
      return state;
  }
};
