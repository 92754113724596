import {
  JOB_QUEUE_UPDATE_ACTIVE,
  JOB_QUEUE_SHOULD_WATCH,
  JOB_QUEUE_ADD,
  JOB_QUEUE_RESET_JOBS_PROCESSED
} from '../types/job-queue/job-queue-action-types';
import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';

export const refreshJobQueue = () => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/jobqueue/active',
        method: APIMethods.GET
      })
    )
      .then((response: any) => {
        dispatch({
          type: JOB_QUEUE_UPDATE_ACTIVE,
          payload: response.data
        });
      })
      .catch((err: any) => Debug.log(err));
  };
};

export const findJobsByIds = (jobIds: Array<string>) => {
  const queryParams = jobIds.map(jobId => `jobId=${jobId}`).join('&');
  return fetchApi({
    url: `sw/jobqueue/?${queryParams}`,
    method: APIMethods.GET
  });
};

export const updateWatchOption = (value: boolean) => {
  return (dispatch: any) => {
    dispatch({
      type: JOB_QUEUE_SHOULD_WATCH,
      payload: value
    });
  };
};

export const updateActiveJobList = (list: any) => {
  return (dispatch: any) => {
    dispatch({
      type: JOB_QUEUE_UPDATE_ACTIVE,
      payload: list
    });
  };
};

export const addJobToList = (job: any) => {
  return (dispatch: any) => {
    dispatch({
      type: JOB_QUEUE_ADD,
      payload: job
    });
  };
};

export const resetJobsProcessed = () => {
  return (dispatch: any) => {
    dispatch({
      type: JOB_QUEUE_RESET_JOBS_PROCESSED
    });
  };
};
