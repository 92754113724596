import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  Chip
} from '@material-ui/core';
import View from '../../components/view/View';
import { userTypes, userTypesDashboardRoute } from '../../constants/users';
import IconAssignments from '../../assets/icons/report/assignments-icon.png';
import IconSkillComprehension from '../../assets/icons/report/skill-comprehension-icon.png';
import IconReadingLevel from '../../assets/icons/report/reading-level-icon.png';
import IconRealTime from '../../assets/icons/report/weekly-activity-icon.png';
import IconPlacementTest from '../../assets/icons/report/placement-test-icon.png';
import IconLeaderboard from '../../assets/icons/report/leaderboard-icon.png';
import IconQuestions from '../../assets/icons/report/questions-icon.png';
import IconClassMission from '../../assets/icons/report/class-mission-report-icon.png';
import ChuckContainer from '../../components/chuck/ChuckContainer';
import { updateTodoListItem } from '../../redux/actions/class-actions';
import { show } from 'redux-modal';
import { MODAL_DEFAULT, MODAL_PARENT_MEMBERSHIP } from '../../constants/modals';
import {
  trackViewRealTime,
  trackViewReadingLevelReport,
  trackViewSkillsReport,
  trackViewPlacementTestReport,
  trackViewLeaderboardReport,
  trackViewQuestionsReport,
  trackViewAssignment,
  trackViewClassActivityReport,
  trackClassMissionReport
} from '../../redux/actions/mixpanel-actions';
import { playerNounLowerCase } from '../../utils/userNoun';
import { UserType } from '../../enums/UserType';
import { Lock } from '@material-ui/icons';
import { useAppSelector } from '../../redux/hooks';
import { teacherRoutes } from '../../constants/routes';
import { NewDashboardLaunchBanner } from '../../components/banner/NewDashboardLaunchBanner';
interface ReportMedia {
  title: string;
  image: string;
  userType?: UserType | null;
  hasMembers?: boolean;
  body: string;
  link?: string;
  skipOnParent?: boolean;
  additionalActions?: () => void;
  tracker?: () => void;
  push: (tracker: any) => void;
  isNewDashboard: boolean;
  classCode: string;
  isPremium: boolean;
  isYellowChipVisible: boolean;
}

const ReportMedia: React.FC<ReportMedia> = ({
  title,
  image,
  body,
  link,
  additionalActions,
  tracker,
  push,
  userType = null,
  hasMembers = false,
  isNewDashboard = false,
  classCode,
  isPremium,
  isYellowChipVisible = false
}: ReportMedia) => {
  const dispatch = useDispatch();
  const oldDashboardAccessConfig = useAppSelector(
    state => state.featureFlag.oldDashboardAccessConfig
  );
  const token = useSelector((state: any) => state.login.token);
  const user = useSelector((state: any) => state.userData.userType);
  const shouldApplyBorder =
    title === 'Reading Comprehension' ||
    title === 'Skill Comprehension Progress';

  const isParentCondition = () => {
    return (
      userType === UserType.Parent &&
      !hasMembers &&
      (title === 'Assignment Reports' || title === 'Questions Report')
    );
  };

  if (
    isNewDashboard &&
    title === 'Reading Level' // add condition to skip card with title 'Reading Level'
  ) {
    return null;
  }

  return (
    <Card
      style={{
        padding: 8,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        border: shouldApplyBorder ? '1px solid #40D2D9' : 'none',
        borderRadius: 4
      }}
      onClick={() => {
        if (isParentCondition()) {
          dispatch(
            show('modal', {
              type: MODAL_PARENT_MEMBERSHIP,
              origin:
                title === 'Assignment Tool'
                  ? 'Assignment Report'
                  : 'Questions Report'
            })
          );
        } else if (
          title === 'Class Activity Report' &&
          isNewDashboard &&
          UserType.Teacher
        ) {
          push(
            `${userTypesDashboardRoute[user]}/reports/newLEClassActivity/${classCode}`
          );
        } else if (
          title === 'Reading Comprehension' &&
          isNewDashboard &&
          UserType.Teacher
        ) {
          window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${classCode}/reports/reading-level?accessToken=${token}`;
        } else if (
          title === 'Skill Comprehension Progress' &&
          isNewDashboard &&
          UserType.Teacher
        ) {
          window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${classCode}/reports/skills-report?accessToken=${token}`;
        } else if (
          title === 'Leaderboard Report' &&
          isNewDashboard &&
          UserType.Teacher
        ) {
          push(
            `${userTypesDashboardRoute[user]}/reports/LEleaderboard/${classCode}`
          );
        } else if (
          title === 'Assignment Reports' &&
          oldDashboardAccessConfig.redirectAssignmentReports &&
          UserType.Teacher
        ) {
          window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${classCode}/assignments?accessToken=${token}`;
        } else {
          additionalActions && additionalActions();
          tracker && dispatch(tracker());
          link && push(link);
        }
      }}
    >
      <div>
        {isYellowChipVisible && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Chip
              label="NEW Shoelace dashboard"
              style={{
                backgroundColor: '#FDC43F',
                color: 'black'
              }}
            />
          </div>
        )}
        <CardMedia
          component="img"
          alt={`${title}-icon`}
          title={title}
          image={image}
          style={{
            maxHeight: 110,
            maxWidth: 110,
            objectFit: 'contain',
            display: 'grid',
            padding: 20,
            justifySelf: 'center',
            alignSelf: 'center'
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'space-between'
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {body}
          </Typography>
        </CardContent>
      </div>
      {isParentCondition() && <Lock />}
    </Card>
  );
};

const ReportsView: React.FC<any> = (props: any) => {
  const userData = useSelector((state: any) => state.userData);
  let { members } = useSelector((state: any) => state.members);
  const features = useAppSelector(state => state.featureFlag);
  const dispatch = useDispatch();
  const generateReportLink = (slug: string) => {
    const baseDashboardUrl = userTypesDashboardRoute[userData.userType];
    let link = `${baseDashboardUrl}/reports/${slug}`;
    if (
      userData.userType !== UserType.Parent ||
      slug === 'assignment-reports'
    ) {
      if (
        features?.userGeneratedContent?.hasAccess &&
        slug === 'assignment-reports' &&
        userData.userType !== UserType.Parent
      ) {
        link = `${teacherRoutes.assignmentReportClassView}`.replace(
          ':classCode',
          props.class.currentClass.code
        );
      } else {
        const linkPrefix =
          userData.userType === UserType.Parent
            ? link.replace('assignment-reports', 'assignment-tool')
            : link;
        link = `${linkPrefix}/${props.class.currentClass.code}`;
      }
    }
    return link;
  };

  let reports = [
    {
      title: 'Reading Level',
      body: `Track the reading level of your class and individual ${playerNounLowerCase}s over time.`,
      image: IconReadingLevel,
      link: generateReportLink('reading-level'),
      tracker: trackViewReadingLevelReport
    },
    {
      title: 'Reading Comprehension',
      body: 'Review reading comprehension levels (RCL) and growth over time.',
      image: IconReadingLevel,
      link: generateReportLink('reading-level'),
      tracker: trackViewReadingLevelReport,
      isYellowChipVisible: true
    },
    {
      title: 'Skill Comprehension Progress',
      body:
        'Follow your class skill comprehension progress with our tier system.',
      image: IconSkillComprehension,
      link: generateReportLink('skill-comprehension'),
      tracker: trackViewSkillsReport,
      isYellowChipVisible: true
    },
    {
      title: 'Skill Comprehension',
      body:
        'See how your players are progressing in each skill and which ones need more practice.',
      image: IconSkillComprehension,
      link: generateReportLink('skill-comprehension'),
      tracker: trackViewSkillsReport
    },
    {
      title: 'Placement Test',
      body: `View the results of the ${playerNounLowerCase} reading level placement test.`,
      image: IconPlacementTest,
      link: generateReportLink('placement-test'),
      tracker: trackViewPlacementTestReport,
      skipOnParent: true
    },
    {
      title: 'Assignment Reports',
      body:
        'See your players progress in your assignments and where they may be struggling.',
      image: IconAssignments,
      link: generateReportLink('assignment-reports'),
      tracker: trackViewAssignment,
      skipOnParent: false
    },
    {
      title: 'Real-Time Dashboard',
      body: 'See your players data in real-time as they play Dreamscape.',
      image: IconRealTime,
      link: generateReportLink('real-time'),
      additionalActions: () => {
        dispatch(updateTodoListItem('accessRealTimeData'));
      },
      tracker: trackViewRealTime,
      skipOnParent: true
    },
    {
      title: 'Leaderboard Report',
      body: `View the ranking of your players based on the amount they've been reading and answering questions correctly.`,
      image: IconLeaderboard,
      link: generateReportLink('leaderboard'),
      tracker: trackViewLeaderboardReport,
      skipOnParent: true
    },
    {
      title: 'Class Activity Report',
      body: `See how your players are progressing in Dreamscape!`,
      image: IconQuestions,
      link: generateReportLink('class-activity'),
      tracker: trackViewClassActivityReport,
      skipOnParent: true
    },
    {
      title: 'Class Mission Report',
      body: `Player data and results from Class Missions.`,
      image: IconClassMission,
      link: generateReportLink('class-mission'),
      tracker: trackClassMissionReport('Reports menu'),
      skipOnParent: true
    }
  ];

  if (userData.userType === userTypes.parent) {
    reports.push({
      title: 'Questions Report',
      body: `See how your players are progressing in Dreamscape!`,
      image: IconQuestions,
      link: generateReportLink('questions'),
      tracker: trackViewQuestionsReport
    });
  }

  if (features.oldDashboardAccessConfig.allowedReports?.length) {
    reports = reports.filter(i =>
      features.oldDashboardAccessConfig.allowedReports?.includes(i.title)
    );
  }

  return (
    <Paper style={{ width: '100%' }}>
      {/* {features.newLearningEngine && <NewDashboardLaunchBanner />} */}
      <View title="Reports">
        <ChuckContainer />
        <Grid container spacing={1} alignItems="stretch">
          {reports.map((report: any) => {
            if (report.skipOnParent && userData.userType === userTypes.parent) {
              return null;
            }
            if (
              (features.newDashboardFlag && report.title === 'Reading Level') ||
              (!features.newDashboardFlag &&
                (report.title === 'Reading Comprehension' ||
                  report.title === 'Skill Comprehension Progress'))
            ) {
              return null;
            }
            return (
              <>
                <Grid item lg={3} key={report.title}>
                  <ReportMedia
                    userType={userData.userType}
                    hasMembers={members && members.length > 0}
                    {...report}
                    push={props.push}
                    isNewDashboard={features.newDashboardFlag}
                    classCode={props.class.currentClass.code}
                    isPremium={userData.premium.isPremium}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </View>
    </Paper>
  );
};

const mapStateToProps = (state: any) => ({
  class: state.class
});

const mapActionsToProps = {
  push
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(ReportsView));
