import React, { useState } from 'react';
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  Divider,
  makeStyles,
  IconButton,
  Theme
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { addClass } from '../../../redux/actions/class-actions';
import { userTypes } from '../../../constants/users';
import GoogleClassroomIcon from '../../../assets/google/btn_classroom_32x32_yellow_stroke_icon@2x.png';
import { show as showModal, hide } from 'redux-modal';
import { Close } from '@material-ui/icons';
import { authorizeGoogleClassroom } from '../../../redux/actions/google-actions';
import { trackImportFromGoogleClick } from '../../../redux/actions/mixpanel-actions';
import { MODAL_GOOGLE_CLASSROOM_IMPORT } from '../../../constants/modals';

const useStyles = makeStyles((theme: Theme) => ({
  dividerWrapper: {
    alignItems: 'center',
    display: 'flex',
    margin: '20px 0px 0px 0px'
  },
  dividerItem: {
    flex: 1,
    height: 2
  },
  dividerText: {
    fontSize: 14,
    margin: '0 15px'
  },
  googleIcon: {
    marginRight: 10,
    width: 20
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  helpText: {
    color: theme.palette.grey[500]
  }
}));

const ModalAddClass: React.FC = (props: any) => {
  let { show, handleHide, hideCloseButton } = props;
  const [className, setClassName] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const userData = useSelector((state: any) => state.userData);
  const { currentTeacher } = useSelector((state: any) => state.admin);
  const { classes: allClasses } = useSelector((state: any) => state.class);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleAddClass = () => {
    if (className.length === 0) {
      setError(true);
      setErrorText('Please enter class name');
      return null;
    }
    handleHide();
    let userId = userData._id;
    let registrationStep = userData.dreamscape.registrationStep;
    if (
      [userTypes.schoolAdmin, userTypes.districtAdmin].includes(
        userData.userType
      )
    ) {
      userId = currentTeacher._id;
      registrationStep = currentTeacher.registrationStep;
    }
    dispatch(addClass(userId, className, registrationStep));
  };

  const handleOnChange = (evt: any) => {
    const { value } = evt.target;
    if (value.trim() !== '') {
      setError(false);
      setErrorText('');
    }
    setClassName(value);
  };

  const handleImportClick = () => {
    dispatch(trackImportFromGoogleClick());
    dispatch(
      authorizeGoogleClassroom(() => {
        dispatch(showModal('modal', { type: MODAL_GOOGLE_CLASSROOM_IMPORT }));
      })
    );
  };

  if (allClasses.length) hideCloseButton = false;
  const hideOnOutsideClick = !hideCloseButton;
  return (
    <ModalAbstract
      show={show}
      handleHide={handleHide}
      hideOnOutsideClick={hideOnOutsideClick}
    >
      <DialogTitle>
        Add Class
        {!hideCloseButton && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => dispatch(hide('modal'))}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <form onSubmit={handleAddClass}>
            <Typography component="p" style={{ marginBottom: 20 }}>
              Enter a name for the new class (e.g. Ms. Dreamscape's Grade 3).
            </Typography>
            <FormControl error={error} fullWidth>
              <TextField
                value={className}
                variant="outlined"
                error={error}
                onChange={handleOnChange}
                label="Class Name"
                fullWidth
              />
              <FormHelperText id="component-error-text">
                {errorText}
              </FormHelperText>
            </FormControl>
            <Button primary type="submit" fullWidth>
              Confirm
            </Button>
          </form>
        </div>
        <div className={classes.dividerWrapper}>
          <Divider className={classes.dividerItem} />
          <Typography className={classes.dividerText}>OR</Typography>
          <Divider className={classes.dividerItem} />
        </div>
        <Typography
          component="p"
          style={{ marginBottom: 20, textAlign: 'right', fontSize: '0.8rem' }}
        >
          <a
            className={classes.helpText}
            href="https://playdreamscape.zendesk.com/hc/en-us/articles/360062440033-How-does-Google-Sync-work-with-Dreamscape-"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HelpOutlineIcon
              style={{ marginBottom: -7, marginRight: 3, fontSize: '1.2rem' }}
            />
            How to Import/Sync Google class
          </a>
        </Typography>
        <Button variant="outlined" fullWidth onClick={handleImportClick}>
          <img
            src={GoogleClassroomIcon}
            className={classes.googleIcon}
            alt="Google Classroom"
          />
          Import from Google Classroom
        </Button>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalAddClass;
