import React, { useState, createRef } from 'react';
import {
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Fab,
  Typography,
  makeStyles,
  IconButton,
  Tooltip,
  Tabs,
  Tab
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { grades } from '../../../constants/global-constants';
import {
  registerPlayers,
  childLogin
} from '../../../redux/actions/player-actions';
import { userTypes } from '../../../constants/users';
import {
  PersonAdd,
  Link,
  KeyboardArrowLeft,
  Clear,
  FileCopy
} from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import PasteCSV from '../../paste-csv/PasteCSV';
import CSVimg from '../../../assets/icons/popups/form_design.png';
import ClassCodeBoard from '../../common/ClassCodeBoard';
import CopyToClipboard from 'react-copy-to-clipboard';
import ClassCodeCopy from '../../common/ClassCodeCopy';
import GamePlatformLinks from '../../common/GamePlatformLinks';
import colors from '../../../styles/colors';

const uniqueId = uuid;

const useStyles = makeStyles(() => ({
  optionSelect: {
    marginBottom: 20,
    width: '100%'
  },
  optionSelectMobile: {
    fontSize: 12,
    lineHeight: 1.2
  },
  buttonCopy: {
    display: 'block',
    margin: '20px auto 0'
  },
  studentList: {
    maxHeight: 330,
    overflowY: 'scroll'
  },
  formError: {
    fontSize: '0.8rem !important',
    marginBottom: 15
  }
}));

const ModalAddStudent: React.FC = (props: any) => {
  enum Steps {
    none,
    updateClassCode,
    selectAccountCreationType,
    addStudent,
    studentCreateAccount,
    childrenLogin,
    pasteFromCSV
  }

  enum TabSteps {
    createAccountsForm,
    copyLink,
    copyClassCode,
    syncWithGoogle
  }

  const { show, handleHide } = props;
  const userData = useSelector((state: any) => state.userData);
  const { code: classCode } = useSelector(
    (state: any) => state.class.currentClass
  );
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const classes = useStyles();
  const initialState = {
    classCode:
      userData.userType === userTypes.parent
        ? userData.dreamscape.classCode
        : classCode,
    currentStep: userData.dreamscape ? userData.dreamscape.registrationStep : 1,
    educationUserId: '',
    grade: 3,
    id: '',
    invalid: true,
    firstname: '',
    lastname: '',
    parentemail: '',
    userId: userData._id
  };
  const [errors, setErrors] = useState({
    firstname: false,
    lastname: false,
    formErrors: false,
    parentemail: false
  });
  const [accounts, pushNewAccounts] = useState<any>([]);
  const [existingAccount, setExistingAccount] = useState({
    username: '',
    password: ''
  });
  const initialErrors = {
    usernameError: false,
    passwordError: false
  };
  const [existingAccountError, setExistingAccountError] = useState(
    initialErrors
  );
  const [player, setPlayerData] = useState({
    classCode:
      userData.userType === userTypes.parent
        ? userData.dreamscape.classCode
        : classCode,
    currentStep: userData.dreamscape.registrationStep,
    educationUserId: '',
    grade: 3,
    id: '',
    invalid: true,
    firstname: '',
    lastname: '',
    parentemail: '',
    userId: userData._id
  });
  const [csvErrors, setCSVErrors] = useState([]);
  const [csvRows, setCSVRows] = useState([]);
  const [tooltipCopyOpen, setTooltipCopyOpen] = useState(false);
  const [createAccountTabValue, setCreateAccountTabValue] = useState(
    TabSteps.createAccountsForm
  );
  const studentListRef: any = createRef();

  let studentType = 'Players';
  let studentTypePlural = `players'`;
  if (userData.userType === userTypes.parent) {
    studentType = 'Player';
    studentTypePlural = 'Players';
  }

  const handleOnChange = (event: any) => {
    let { value } = event.target;
    if (event.target.name === 'firstname' || event.target.name === 'lastname') {
      var regex = new RegExp("^[a-zA-Z0-9-'.]+$");
      if (value && !regex.test(value)) {
        event.preventDefault();
        return false;
      }
    }
    if (event.target.name === 'lastname') {
      if (value) {
        value = value.toUpperCase();
      }
    }

    setPlayerData({
      ...player,
      [event.target.name]: value
    });
  };

  const handleExistingChange = (event: any) => {
    const { value, name } = event.target;
    setExistingAccountError({
      ...existingAccountError,
      [`${name}Error`]: false
    });
    setExistingAccount({
      ...existingAccount,
      [name]: value
    });
  };

  const handleRemove = (id: string) => {
    let newAccounts = [...accounts];
    newAccounts = newAccounts.filter((e: any) => {
      return e.id !== id;
    });
    pushNewAccounts(newAccounts);
  };

  const handleSelectGrade = (evt: any) => {
    setPlayerData({
      ...player,
      grade: evt.target.value
    });
  };
  const validateEmail = (emailString: string) => {
    if (emailString) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(emailString).toLowerCase());
    } else {
      return true;
    }
  };

  const validateForm = () => {
    setErrors({
      formErrors: false,
      firstname: false,
      lastname: false,
      parentemail: false
    });
    let valid = true;
    let firstname = false;
    let lastname = false;
    let parentemail = false;

    if (player.firstname.length === 0) {
      firstname = true;
      valid = false;
    }

    if (player.lastname.length === 0) {
      lastname = true;
      valid = false;
    }
    if (!validateEmail(player.parentemail)) {
      parentemail = true;
      valid = false;
    }
    if (!valid) {
      //  set errors
      setErrors({
        ...errors,
        firstname,
        lastname,
        parentemail
      });
    }
    return valid;
  };

  const handleAddStudent = () => {
    if (validateForm()) {
      const newAccounts = [...accounts];
      player.id = uniqueId.v4();
      newAccounts.push(player);
      pushNewAccounts(newAccounts);
      setPlayerData(initialState);
    }
  };

  const handleChildLogin = () => {
    let isError = false;
    const accountErrors = { ...existingAccountError };
    if (existingAccount.username.length === 0) {
      isError = true;
      accountErrors.usernameError = true;
    } else {
      accountErrors.usernameError = false;
    }
    if (existingAccount.password.length === 0) {
      accountErrors.passwordError = true;
      isError = true;
    } else {
      accountErrors.passwordError = false;
    }

    if (!isError) {
      setExistingAccountError(initialErrors);
      dispatch(childLogin(existingAccount.username, existingAccount.password));
    } else {
      setExistingAccountError(accountErrors);
    }
  };

  const handlePasteFromCSV = () => {
    const csvAccounts = csvRows
      .map((row: string) => row.split(','))
      .map(row => ({
        ...initialState,
        id: uniqueId.v4(),
        firstname: row[0],
        lastname: row[1].trim().slice(0, 1),
        grade: row[2].trim(),
        parentemail: row[3]
      }));
    pushNewAccounts([...accounts, ...csvAccounts]);
    setCurrentStep(Steps.selectAccountCreationType);
    setPlayerData(initialState);
    setErrors({
      firstname: false,
      lastname: false,
      formErrors: false,
      parentemail: false
    });
  };

  const csvHasErrors = (): Boolean => {
    return (
      csvErrors.reduce((acc, value: Array<any>) => {
        return value.length;
      }, 0) > 0
    );
  };

  const handleCreateStudents = (): any => {
    if (accounts.length === 0) {
      return setErrors({
        ...errors,
        formErrors: true
      });
    }
    dispatch(registerPlayers(accounts, classCode));

    setPlayerData(initialState);
    pushNewAccounts([]);
  };

  const renderCreateAccountForm = () => {
    return (
      <>
        <Grid container justify="center" spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs={12} md={4} lg={4}>
            <Typography
              component="div"
              style={{
                color: '#757575',
                fontSize: '14px',
                marginBottom: '5px'
              }}
            >
              Ex: "Shaya" + "L" becomes "ShayaL-4"
            </Typography>
            <FormControl error={errors.firstname} fullWidth>
              <TextField
                onChange={handleOnChange}
                variant="outlined"
                value={player.firstname}
                label="First Name"
                name="firstname"
                fullWidth
              />
              <FormHelperText className={classes.formError}>
                {errors.firstname && 'Enter first name'}
              </FormHelperText>
            </FormControl>

            <FormControl error={errors.lastname} fullWidth>
              <TextField
                onChange={handleOnChange}
                value={player.lastname}
                variant="outlined"
                label="Last Name initial"
                name="lastname"
                fullWidth
                inputProps={{
                  maxLength: 1
                }}
              />
              <FormHelperText className={classes.formError}>
                {errors.lastname && 'Enter last name initials'}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-grades">Grade</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                onChange={handleSelectGrade}
                inputProps={{
                  name: 'grade',
                  id: 'outlined-grades'
                }}
                value={player.grade}
              >
                {grades.map((e: any, index: number) => {
                  return (
                    <MenuItem key={index} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl
              error={errors.parentemail}
              style={{ marginTop: 20 }}
              fullWidth
            >
              <TextField
                onChange={handleOnChange}
                variant="outlined"
                value={player.parentemail}
                label="Parent Email (Optional)"
                name="parentemail"
                fullWidth
              />
              <FormHelperText className={classes.formError}>
                {errors.parentemail && 'Enter valid email'}
              </FormHelperText>
            </FormControl>

            <Button
              fullWidth
              primary
              style={{ marginTop: 20 }}
              onClick={handleAddStudent}
            >
              Add
            </Button>
            <Typography
              style={{ margin: '10px 0', textAlign: 'center', fontSize: 13 }}
            >
              OR
            </Typography>
            <Button
              blue
              fullWidth
              onClick={() => setCurrentStep(Steps.pasteFromCSV)}
            >
              <FileCopy style={{ marginRight: 10 }} />
              <Typography style={{ fontSize: 16 }}>
                Paste from Spreadsheet
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            ref={studentListRef}
            className={classes.studentList}
          >
            {!accounts.length && (
              <Typography
                style={{
                  color: '#757575',
                  textAlign: 'center',
                  fontStyle: 'italic'
                }}
              >
                Your {studentTypePlural.toLowerCase()} names will appear here
              </Typography>
            )}
            <Table>
              {accounts.map((e: any, index: any) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {e.firstname} {e.lastname}.
                      </TableCell>
                      <TableCell>Grade: {e.grade}</TableCell>
                      <TableCell>
                        Parent Email:{' '}
                        {e.parentemail === '' ? '' : e.parentemail}
                      </TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        <IconButton
                          onClick={() => handleRemove(e.id)}
                          style={{ color: '#000' }}
                        >
                          <Clear style={{ width: 20 }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </Table>
          </Grid>
        </Grid>
        {errors.formErrors && <span>Please Add a player</span>}
      </>
    );
  };

  const renderCreateAccountWithLink = () => {
    const link = `${process.env.REACT_APP_WEBGL_LINK}?classCode=${classCode}`;
    return (
      <>
        <Typography component="p" style={{ margin: '20px 0' }}>
          Players' can create their own Dreamscape accounts through accessing
          the link below on a computer.
        </Typography>

        <TextField
          fullWidth
          value={link}
          disabled={true}
          variant="outlined"
          inputProps={{
            style: {
              color: '#000',
              background: '#ececec',
              fontSize: '15px !important'
            }
          }}
        />

        <Tooltip
          open={tooltipCopyOpen}
          title="Copied to your clipboard"
          className={classes.buttonCopy}
        >
          <CopyToClipboard text={link.toString()}>
            <Button
              primary
              onClick={() => {
                setTooltipCopyOpen(true);
                setTimeout(() => setTooltipCopyOpen(false), 1000);
              }}
            >
              Copy Link
            </Button>
          </CopyToClipboard>
        </Tooltip>

        <Typography style={{ marginTop: 20 }}>
          They can start playing Dreamscape right away, and you can come back to
          your dashboard to see their progress or give them an assignment.
        </Typography>
      </>
    );
  };

  const renderCreateAccountWithClassCode = () => {
    return (
      <>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={12} md={5} lg={5}>
            <ClassCodeBoard classCode={classCode} />
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <Typography component="p" style={{ margin: '20px 0' }}>
              Using a computer, Android or iOS device, have your students open
              Dreamscape, select "Create an account" and enter your class code
            </Typography>
            <ClassCodeCopy classCode={classCode} style={{ border: 'none' }} />
            <GamePlatformLinks style={{ marginTop: 20 }} />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderCreateAccountWithGoogle = () => {
    return (
      <>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item>
            <Typography component="p" style={{ margin: '20px 0' }}>
              If you use Google Classroom, you can sync your class roster with
              Dreamscape so that students can log in using their Google account.
            </Typography>
            <Button primary>Sync with Google</Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderBackArrow = () => {
    if (currentStep !== Steps.none) {
      let nextStep = Steps.none;
      if (
        [Steps.addStudent, Steps.studentCreateAccount].includes(currentStep)
      ) {
        if (userData.userType !== userTypes.parent) {
          nextStep = Steps.selectAccountCreationType;
        }
      }
      if (currentStep === Steps.pasteFromCSV) {
        nextStep = Steps.selectAccountCreationType;
      }
      return (
        <IconButton
          aria-label="back"
          style={{ marginRight: 10 }}
          onClick={() => setCurrentStep(nextStep)}
        >
          <KeyboardArrowLeft />
        </IconButton>
      );
    }
    return null;
  };

  let renderView = null;
  if (currentStep === Steps.none) {
    renderView = (
      <>
        <Typography component="p" style={{ marginBottom: 20 }}>
          Do your {studentType.toLowerCase()} have <em>Dreamscape</em> accounts?
        </Typography>
        <Fab
          variant="extended"
          color="primary"
          className={clsx(
            classes.optionSelect,
            isMobile && classes.optionSelectMobile
          )}
          onClick={() => {
            const step =
              userData.userType === userTypes.parent
                ? Steps.childrenLogin
                : Steps.updateClassCode;
            setCurrentStep(step);
          }}
        >
          {!isMobile && <Link style={{ marginRight: 10 }} />}
          Yes, they already have accounts.
        </Fab>

        <Fab
          variant="extended"
          color="primary"
          className={clsx(
            classes.optionSelect,
            isMobile && classes.optionSelectMobile
          )}
          onClick={() => {
            const step =
              userData.userType === userTypes.parent
                ? Steps.addStudent
                : Steps.selectAccountCreationType;
            setCurrentStep(step);
          }}
        >
          {!isMobile && <PersonAdd style={{ marginRight: 10 }} />}
          No, they need new accounts.
        </Fab>
      </>
    );
  } else if (currentStep === Steps.addStudent) {
    renderView = renderCreateAccountForm();
  } else if (currentStep === Steps.updateClassCode) {
    renderView = (
      <Grid container spacing={4}>
        <Grid item lg={12}>
          <Typography component="p">
            Ask your students to follow the steps below to join your class using
            this class code {classCode}
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <ClassCodeBoard classCode={classCode} />
        </Grid>
        <Grid item lg={6}>
          <ol style={{ marginLeft: 20, fontSize: 16, lineHeight: 1.7 }}>
            <li>Have your players sign in to their Dreamscape account</li>
            <li>Select "Menu" at the top, and click "Update Class Code"</li>
          </ol>
          <ClassCodeCopy classCode={classCode} style={{ margin: '20px 0' }} />
          <GamePlatformLinks />
        </Grid>
      </Grid>
    );
  } else if (currentStep === Steps.selectAccountCreationType) {
    const tabs = (
      <>
        <Tabs
          value={createAccountTabValue}
          indicatorColor="primary"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          style={{ color: colors.blue }}
        >
          <Tab
            label="Create Manually"
            onClick={() =>
              setCreateAccountTabValue(TabSteps.createAccountsForm)
            }
          />
          <Tab
            label="Create Using Link"
            onClick={() => setCreateAccountTabValue(TabSteps.copyLink)}
          />
          <Tab
            label="Create Using Class Code"
            onClick={() => setCreateAccountTabValue(TabSteps.copyClassCode)}
          />
          {/* <Tab
            label="Sync with Google"
            onClick={() => setCreateAccountTabValue(TabSteps.syncWithGoogle)}
          /> */}
        </Tabs>
      </>
    );
    let tabContent: any = null;
    switch (createAccountTabValue) {
      case TabSteps.createAccountsForm:
        tabContent = renderCreateAccountForm();
        break;
      case TabSteps.copyLink:
        tabContent = renderCreateAccountWithLink();
        break;
      case TabSteps.copyClassCode:
        tabContent = renderCreateAccountWithClassCode();
        break;
      case TabSteps.syncWithGoogle:
        tabContent = renderCreateAccountWithGoogle();
        break;
      default:
        tabContent = null;
        break;
    }
    renderView = (
      <>
        {tabs}
        {tabContent}
      </>
    );
  } else if (currentStep === Steps.childrenLogin) {
    renderView = (
      <Grid container>
        <Grid item lg={12}>
          <Typography style={{ marginBottom: 20 }}>
            Ask your player for their username and password to link them to your
            account.
          </Typography>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <FormControl error={existingAccountError.usernameError} fullWidth>
              <TextField
                label="Username"
                name="username"
                variant="outlined"
                onChange={handleExistingChange}
                error={existingAccountError.usernameError}
                fullWidth
              />
              <FormHelperText>
                {existingAccountError.usernameError && 'Please Enter Username'}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl error={existingAccountError.passwordError} fullWidth>
              <TextField
                label="Password"
                name="password"
                type="password"
                variant="outlined"
                onChange={handleExistingChange}
                error={existingAccountError.passwordError}
                fullWidth
              />
              <FormHelperText>
                {existingAccountError.passwordError && 'Please Enter Password'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (currentStep === Steps.pasteFromCSV) {
    renderView = (
      <Grid container>
        <Grid item xs={12} alignContent={'center'}>
          <img
            src={CSVimg}
            style={{
              width: '100%',
              maxWidth: '634px',
              display: 'block',
              margin: 'auto',
              marginBottom: '20px'
            }}
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PasteCSV
              columnCount={4}
              formatPlaceholder={[
                'First Name',
                'Last Initial',
                'Grade',
                'Parent Email'
              ]}
              textAreaPlaceholder="First Name, Last Initial, Grade, Parent Email"
              validation={[
                {
                  column: 0,
                  validationType: {
                    notEmpty: 'First name cannot be empty',
                    specificSpecialSymbols: `First names may only contain letters and the symbols: period(.), apostrophe(') and hyphen(-)`
                  }
                },
                {
                  column: 1,
                  validationType: {
                    notEmpty: 'Last Initial cannot be empty',
                    onlyLetters: 'Only letters are allowed for last initial'
                  }
                },
                {
                  column: 2,
                  validationType: {
                    notEmpty: 'Grade cannot be empty',
                    numbersBetween: {
                      max: 8,
                      min: 1,
                      message: 'Grade must be between 1 and 8'
                    }
                  }
                },
                {
                  column: 3,
                  validationType: {
                    emailOptional: 'Email must be Valid'
                  }
                }
              ]}
              getRows={(rows: any) => setCSVRows(rows)}
              getErrors={(errors: any) => setCSVErrors(errors)}
            />
            {csvErrors.map((item: any) => {
              return item.map((item: any) => (
                <Typography style={{ color: colors.error }}>{item}</Typography>
              ));
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    renderView = null;
  }

  return (
    <ModalAbstract fullWidth maxWidth="md" show={show} handleHide={handleHide}>
      <DialogTitle>
        {renderBackArrow()}
        {userData.userType !== userTypes.parent &&
        currentStep === Steps.selectAccountCreationType
          ? 'Choose your method of creating player accounts'
          : `Add ${studentType}`}
      </DialogTitle>
      <DialogContent
        dividers
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            handleAddStudent();
          }
        }}
      >
        {renderView}
      </DialogContent>
      <DialogActions>
        {currentStep === Steps.pasteFromCSV && (
          <Button
            primary
            onClick={handlePasteFromCSV}
            disabled={csvRows.length === 0 || csvHasErrors() === true}
          >
            Add Players
          </Button>
        )}
        {[Steps.selectAccountCreationType, Steps.addStudent].includes(
          currentStep
        ) &&
          createAccountTabValue === TabSteps.createAccountsForm && (
            <Button
              primary
              disabled={!accounts.length}
              onClick={handleCreateStudents}
            >
              Create Accounts
            </Button>
          )}
        {currentStep === Steps.childrenLogin && (
          <Button primary onClick={handleChildLogin}>
            Add Player
          </Button>
        )}
        <Button onClick={handleHide}>Close</Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalAddStudent;
