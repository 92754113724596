import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import colors from '../styles/colors';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DSLogo from '../assets/shoelace-logo.png';
import AppBar from '@material-ui/core/AppBar/AppBar';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkoutRoutes, parentRoutes } from '../constants/routes';
import { useAppSelector } from '../redux/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh'
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  scrollAble: {
    height: 'calc(100vh - 64px)',
    overflowY: 'scroll'
  },
  toolbar: {
    paddingRight: 24,
    height: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  backButton: {
    marginRight: '1rem'
  }
}));

const EmptyNavBarLayout: React.FC = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const userData = useAppSelector(state => state.userData);
  const isUserLoggedIn = userData && userData._id;
  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, 'dashboard-header')}
        style={{
          backgroundColor: colors.appbar,
          marginBottom: '2rem'
        }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.backButton}
            onClick={() => {
              if ([checkoutRoutes.success].includes(location.pathname)) {
                history.push(
                  isUserLoggedIn
                    ? parentRoutes.membershipsAll
                    : checkoutRoutes.checkout
                );
              } else {
                history.goBack();
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <a
              href="https://www.playdreamscape.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={DSLogo}
                style={{
                  height: '49px',
                  width: 'auto',
                  marginTop: 10
                }}
                alt="logo"
              />
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.scrollAble}>{props.children}</div>
      </main>
    </div>
  );
};

export default EmptyNavBarLayout;
