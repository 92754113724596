import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Select,
  makeStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Fab,
  Theme,
  Tooltip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import {
  Sync,
  Add,
  Close,
  KeyboardArrowLeft,
  HelpOutline
} from '@material-ui/icons';
import { getClass } from '../../../redux/actions/class-actions';
import {
  changeGoogleClass,
  importGoogleClasses,
  resetGoogleClassroomState
} from '../../../redux/actions/google-actions';
import {
  GoogleClass,
  GoogleStudent
} from '../../../types/google/google-classroom-import-request-type';
import { IClassRoster } from '../../../types/state/class-type';
import { default as ReactSelect } from 'react-select';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme: Theme) => ({
  optionSelect: {
    marginBottom: 20,
    width: '100%'
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  columnTitle: {
    fontWeight: 500
  },
  classDivider: {
    height: 1,
    margin: '10px 0',
    width: '100%'
  },
  toolTipIcon: {
    color: '#404040',
    cursor: 'pointer',
    margin: '30px 0 10px 10px',
    width: 20
  },
  gradeWrapper: {
    flex: 1
  },
  helpText: {
    color: theme.palette.grey[500],
  }
}));

const ModalGoogleClassroomSync: React.FC = (props: any) => {
  enum Steps {
    none,
    classSelection,
    studentSelection,
    actionSelection,
    importStudents,
    mergeStudents,
    success
  }

  const { show, handleHide, classCode } = props;
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.classSelection);
  const googleClassroom = useSelector((state: any) => state.googleClassroom);
  const { currentClass } = useSelector((state: any) => state.class);
  const grades = [1, 2, 3, 4, 5, 6, 7, 8];
  const [selectedClassId, setSelectedClassId] = useState(
    googleClassroom.classes[0] ? googleClassroom.classes[0].id : null
  );
  const [selectedClassRoster, setSelectedClassRoster] = useState<
    Array<GoogleStudent>
  >([]);
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleChangeGoogleStudent = (id: string, key: string, value: any) => {
    const students: any = selectedClassRoster.map((student: any) => {
      if (student.googleClassroomId === id) {
        student[key] = value;
      }
      return student;
    });
    setSelectedClassRoster(students);
    dispatch(changeGoogleClass(selectedClassId, 'students', students));
  };

  const hasDuplicatedStudentsToMerge = (
    studentsToMerge: Array<GoogleStudent>
  ) => {
    const valueArr = studentsToMerge.map(item => item.mergeWith);
    const hasDuplicate = valueArr.some((item, index) => {
      return valueArr.indexOf(item) !== index;
    });
    return hasDuplicate;
  };

  const renderButtons = () => {
    switch (currentStep) {
      case Steps.classSelection:
        if (!googleClassroom.classes.length) {
          return null;
        }
        let selectedClass = googleClassroom.classes.filter(
          (item: GoogleClass) => item.id === selectedClassId
        );
        let disabled = true;
        if (selectedClass) {
          selectedClass = selectedClass[0];
          disabled = selectedClass.students.length === 0;
        }
        return (
          <Button
            primary
            disabled={disabled}
            onClick={() => {
              setCurrentStep(Steps.actionSelection);
              const googleClassroomIdsInClass = currentClass.roster
                .filter((student: IClassRoster) => student.google !== undefined)
                .map((student: IClassRoster) => student.google.classroomId);
              const availableStudentsToSync = selectedClass.students.filter(
                (student: GoogleStudent) =>
                  !googleClassroomIdsInClass.includes(student.googleClassroomId)
              );
              setSelectedClassRoster(availableStudentsToSync);
            }}
          >
            Next
          </Button>
        );
      case Steps.importStudents:
        const disabledButton =
          selectedClassRoster.filter(student => student.selected).length === 0;
        return (
          <Button
            primary
            disabled={disabledButton}
            onClick={async () => {
              const selectedClasses = googleClassroom.classes.filter(
                (item: GoogleClass) => item.id === selectedClassId
              );
              selectedClasses[0].students = selectedClassRoster.filter(student => student.selected); 
              dispatch(importGoogleClasses(selectedClasses));
            }}
          >
            Confirm
          </Button>
        );
      case Steps.mergeStudents:
        const accountsToMerge = selectedClassRoster.filter(
          (student: GoogleStudent) => student.mergeWith !== null
        );
        let error = null;
        const hasDuplicate = hasDuplicatedStudentsToMerge(accountsToMerge);
        if (hasDuplicate) {
          error = `A Dreamscape account must be attached to only one Google account.
          Please, remove the duplicated users from the dropdowns.`;
        }
        return (
          <>
            <span>{error}</span>
            <Button
              primary
              disabled={accountsToMerge.length === 0 || hasDuplicate}
              onClick={async () => {
                const selectedClasses = googleClassroom.classes.filter(
                  (item: GoogleClass) => item.id === selectedClassId
                );
                dispatch(
                  importGoogleClasses(selectedClasses, () =>
                    dispatch(getClass(classCode))
                  )
                );
              }}
            >
              Confirm
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const renderMergeDropdown = (student: GoogleStudent) => {
    if (!student.isImported) {
      const options = currentClass.roster
        .filter((classStudent: any) => !classStudent.google)
        .map((classStudent: any) => ({
          value: classStudent._id,
          label: `${classStudent.fullname} (${classStudent.username})`
        }));
      return (
        <ReactSelect
          onChange={(selection: any) =>
            handleChangeGoogleStudent(
              student.googleClassroomId,
              'mergeWith',
              selection.value
            )
          }
          placeholder="Select a Dreamscape account"
          options={[{ label: "Don't merge", value: null }, ...options]}
        />
      );
    }
    return (
      <Typography component="p" style={{ fontSize: 13 }}>
        This student already has a Dreamscape account associated with this
        Google account. Please use the Import mode to add them to your class.
      </Typography>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case Steps.classSelection:
        if (!googleClassroom.classes.length) {
          return (
            <Typography>
              No classes found for your Google account, please try with a
              different one.
            </Typography>
          );
        }
        return (
          <>
            <Typography style={{ marginBottom: 20 }}>
              Select the class you would like to sync:
            </Typography>
            {googleClassroom.classes.map((item: GoogleClass, index: number) => {
              const numOfStudents = (item.students.length || 'No').toString();
              const description = `${numOfStudents} ${
                numOfStudents === '1' ? 'student' : 'students'
              } found`;
              return (
                <>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12}>
                      <RadioGroup
                        value={selectedClassId}
                        onChange={(event: any) => {
                          const classId = (event.target as HTMLInputElement)
                            .value;
                          setSelectedClassId(classId);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio value={item.id} color="primary" />}
                          label={
                            <>
                              <Typography>
                                {item.name} ({description})
                              </Typography>
                            </>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  {index + 1 < googleClassroom.classes.length && (
                    <Divider className={classes.classDivider} />
                  )}
                </>
              );
            })}
          </>
        );
      case Steps.actionSelection:
        if (!selectedClassRoster.length) {
          return (
            <Typography>
              All students in the Google Classroom that you selected are already
              synced with this Dreamscape class.
            </Typography>
          );
        }
        return (
          <>
            <Typography component="p">
              We found {selectedClassRoster.length} students who are not synced
              with this class.
            </Typography>
            <Typography component="p" style={{ marginBottom: 20 }}>
              What would you like to do?
            </Typography>
 
            <Typography component="p" style={{ marginBottom: 20 }}>
              By importing students you will create new Dreamscape accounts linked to Google.
            </Typography>
            <Fab
              variant="extended"
              color="primary"
              className={classes.optionSelect}
              onClick={() => {
                dispatch(resetGoogleClassroomState());
                dispatch(
                  changeGoogleClass(selectedClassId, 'classCode', classCode)
                );
                setCurrentStep(Steps.importStudents);
              }}
            >
              <Add />
              Import my students from Google Classroom to Dreamscape
            </Fab>
 
            <Typography component="p" style={{ marginBottom: 20 }}>
              If your students already have a Dreamscape account 
              please select the merge option. You still will be able to add new students with merging.
            </Typography>
            <Fab
              variant="extended"
              color="primary"
              className={classes.optionSelect}
              onClick={() => {
                dispatch(resetGoogleClassroomState());
                dispatch(
                  changeGoogleClass(selectedClassId, 'classCode', classCode)
                );
                setCurrentStep(Steps.mergeStudents);
              }}
            >
              <Sync />
              Merge my students in existing Dreamscape accounts
            </Fab>
            <Typography component="p" style={{ marginBottom: 20, textAlign: "right", fontSize: "0.8rem" }}>
              <a className={classes.helpText} href="https://playdreamscape.zendesk.com/hc/en-us/articles/360062440033-How-does-Google-Sync-work-with-Dreamscape-" target="_blank" rel="noopener noreferrer">
                <HelpOutlineIcon style={{marginBottom: -7, marginRight: 3, fontSize: "1.2rem"}}/>
                How to Import/Sync Google class
              </a>
            </Typography>
          </>
        );
      case Steps.importStudents:
        return (
          <>
            <Typography style={{ marginBottom: 20 }}>
              Select the students you would like to import and pick their grade:
            </Typography>
            {selectedClassRoster.map(
              (student: GoogleStudent, index: number) => (
                <>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item md={8} lg={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={student.selected}
                            onChange={() =>
                              handleChangeGoogleStudent(
                                student.googleClassroomId,
                                'selected',
                                !student.selected
                              )
                            }
                            value={student.googleClassroomId}
                            color="primary"
                          />
                        }
                        label={`${student.firstname} ${student.lastname}`}
                      />
                    </Grid>
                    <Grid item container md={4} lg={4} alignItems="center">
                      <FormControl className={classes.gradeWrapper}>
                        <InputLabel>Grade</InputLabel>
                        <Select
                          fullWidth
                          placeholder="Grade"
                          onChange={(event: any) =>
                            handleChangeGoogleStudent(
                              student.googleClassroomId,
                              'grade',
                              event.target.value
                            )
                          }
                          value={student.grade}
                        >
                          {grades.map(grade => (
                            <MenuItem
                              key={grade}
                              selected={grade === student.grade}
                              value={grade}
                            >
                              Grade {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Tooltip
                        className={classes.toolTipIcon}
                        title="This grade will determine the starting reading level for the students in your class. The game is adaptive and it will automatically start moving students to their proper reading level. If you want to change a student’s level manually, you can do so after you create your class."
                      >
                        <HelpOutline />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {index + 1 < googleClassroom.classes.length && (
                    <Divider className={classes.classDivider} />
                  )}
                </>
              )
            )}
          </>
        );
      case Steps.mergeStudents:
        if (!currentClass.roster.length) {
          return (
            <Typography component="p">
              You don't have any students in your Dreamscape class to merge with
              your Google Classroom students.
            </Typography>
          );
        }
        return (
          <>
            <Typography component="p" style={{ marginBottom: 20 }}>
              Please, select the Dreamscape account that belongs to your Google
              students:
            </Typography>
            <Grid container spacing={4}>
              <Grid item md={6} xs={6}>
                <Typography component="p" className={classes.columnTitle}>
                  Google Account
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography component="p" className={classes.columnTitle}>
                  Dreamscape Account
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={4} style={{ marginBottom: 40 }}>
              {selectedClassRoster.map((student: GoogleStudent) => (
                <>
                  <Grid item md={6} xs={6}>
                    <Typography component="p">
                      {student.firstname} {student.lastname}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    {renderMergeDropdown(student)}
                  </Grid>
                </>
              ))}
            </Grid>
          </>
        );
      case Steps.success:
        return (
          <>
            <Typography component="p">
              Great. Your classes are synced!
            </Typography>
            <Typography component="p">
              Now your students can log in using their Google account
            </Typography>
          </>
        );
    }
    return null;
  };

  const renderBackArrow = () => {
    if (
      [
        Steps.importStudents,
        Steps.mergeStudents,
        Steps.actionSelection
      ].includes(currentStep)
    ) {
      return (
        <IconButton
          aria-label="back"
          style={{ marginRight: 10 }}
          onClick={() => setCurrentStep(Steps.classSelection)}
        >
          <KeyboardArrowLeft />
        </IconButton>
      );
    }
    return null;
  };

  if (googleClassroom.inProgress) {
    return null;
  }

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        {renderBackArrow()}
        Sync with Google Classroom
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleHide}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{renderStep()}</DialogContent>
      <DialogActions>{renderButtons()}</DialogActions>
    </ModalAbstract>
  );
};

export default ModalGoogleClassroomSync;
