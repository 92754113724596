import { createSlice, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { fetchApi } from '../actions/fetch-actions';
import { timeFrames } from '../../components/widgets/ReportToolTemplate';
import moment, { Moment } from 'moment';
import { playerNounLowerCase } from '../../utils/userNoun';
import { useAppSelector } from '../hooks';

export enum ReportTools {
  AssignmentTool = 'Assignment Tool',
  ClassActivity = 'Class Activity',
  Questions = 'Questions',
  Leaderboard = 'Leaderboard',
  SkillReport = 'Skill Report',
  PlacementTest = 'Placement Test'
}

export enum Statuses {
  Success = 'Success',
  Error = 'Error',
  Pending = 'Pending'
}
interface ReportToolState {
  [type: string]: {
    status: Statuses.Success | Statuses.Error | Statuses.Pending;
    data: Array<any> | string;
    selectedRange?: string;
    startDate?: Moment;
    endDate?: Moment;
    isWidget?: boolean;
  };
}

interface FetchReportToolDataPayload {
  type: string;
  selectedRange?: string;
  startDate?: Moment;
  endDate?: Moment;
  isWidget?: boolean;
}

interface Students {
  [key: string]: {
    firstname: string;
    lastname: string;
    username: string;
  };
}

interface FetchReportToolClassActivityDataPayload {
  startDate: Moment;
  endDate: Moment;
  isWidget: boolean;
  userIds: Array<number>;
  students: Students;
}

interface FetchReportToolLeaderboardDataPayload {
  startDate: Moment;
  endDate: Moment;
  isWidget: boolean;
  userIds: Array<number>;
  students: Students;
}

interface FetchReportToolQuestionsDataPayload {
  startDate: Moment;
  endDate: Moment;
  isWidget: boolean;
  userIds: Array<number>;
}

interface FetchAPIUrlRequest {
  type: string;
  startDate: Moment;
  endDate: Moment;
  userIds: Array<number>;
  isWidget: boolean;
  prefix?: string;
}

interface RejectedValuePayload {
  type: string;
  startDate: Moment;
  endDate: Moment;
  selectedRange: string;
  isWidget: boolean;
  data: string;
}

const dateFormat = 'YYYY-MM-DD';

const generateAPIUrl = ({
  type,
  startDate,
  endDate,
  userIds,
  isWidget,
  prefix = 'v2.1'
}: FetchAPIUrlRequest) => {
  let userIdQuery = '';
  for (const userId of userIds) {
    userIdQuery += `&userIds=${userId}`;
  }
  return `${prefix}/reportTool/${type.replace(
    ' ',
    '-'
  )}?startDate=${startDate.format(dateFormat)}&endDate=${endDate.format(
    dateFormat
  )}${userIdQuery}&isWidget=${isWidget}`;
};

const getData = ({
  type,
  isWidget,
  selectedRange,
  hasData
}: {
  type: string;
  isWidget?: boolean;
  selectedRange?: string;
  hasData?: boolean;
}) => {
  let data = '';

  if (!hasData) {
    switch (type) {
      case ReportTools.Questions:
      case ReportTools.Leaderboard:
        if (selectedRange === timeFrames.last7) {
          if (isWidget) {
            data =
              'There is no data from your class for the last 7 days! Select View Report to see data from other time periods.';
          } else {
            data = `We haven't collected any question data from this class yet! Have ${playerNounLowerCase}s log into the game and answer questions and check back later.`;
          }
        } else {
          data =
            'There is no data based on your selection! Change the time period to see more information.';
        }
        break;
      case ReportTools.AssignmentTool:
        data =
          "It's time to make an assignment! To give your players specific skill practice, preparation for standardized tests, or genres, explore the assignment tool.";
        break;
      case ReportTools.ClassActivity:
        data = `There is no ${playerNounLowerCase} data for this time period. Get your players to use Dreamscape in class or at home!`;
        break;
      case ReportTools.SkillReport:
        data = 'Not enough data.';
        break;
      default:
        data = 'No data exists currently!';
        break;
    }
  }

  return data;
};

const getDates = ({
  selectedRange,
  startDate,
  endDate
}: {
  selectedRange?: string;
  startDate?: Moment;
  endDate?: Moment;
}) => {
  let tempStartDate = moment().subtract(6, 'days');
  let tempEndDate = moment();

  if (selectedRange && selectedRange !== timeFrames.custom) {
    switch (selectedRange) {
      case timeFrames.week:
        tempStartDate = moment().startOf('isoWeek');
        break;
      case timeFrames.month:
        tempStartDate = moment().startOf('month');
        break;
      case timeFrames.year:
        tempStartDate = moment().startOf('year');
        break;
      default:
        break;
    }

    tempStartDate = tempStartDate.startOf('day');
    tempEndDate = tempEndDate.endOf('day');
  }

  return {
    startDate: (startDate && startDate.startOf('day')) || tempStartDate,
    endDate: (endDate && endDate.endOf('day')) || tempEndDate
  };
};

const fetchReportToolAssignmentToolData = createAsyncThunk(
  'reportTool/fetchReportToolAssignmentToolData',
  async (undefined, { getState }) => {
    try {
      const state: any = getState();
      const data = state.planner.filter((assignment: any) => {
        return moment(assignment.end).isSameOrAfter(moment());
      });

      return { data };
    } catch (error) {
      throw error;
    }
  }
);

const fetchReportToolClassActivityData = createAsyncThunk(
  'reportTool/fetchReportToolClassActivityData',
  async (payload: FetchReportToolClassActivityDataPayload, { dispatch }) => {
    try {
      const { isWidget, startDate, endDate, userIds, students } = payload;
      const prefix = isWidget ? 'v2.1' : 'sw';
      const endpoint = isWidget
        ? APIEndpoints.EDUCATION
        : APIEndpoints.DASHBOARD;

      const response = await dispatch(
        fetchApi({
          url: generateAPIUrl({
            type: ReportTools.ClassActivity,
            userIds,
            startDate,
            endDate,
            isWidget,
            prefix
          }),
          method: APIMethods.GET,
          endpoint
        })
      );

      const { data } = response;

      return { data, students, isWidget };
    } catch (error) {
      throw error;
    }
  }
);

const fetchReportToolLeaderboardData = createAsyncThunk(
  'reportTool/fetchReportToolLeaderboardData',
  async (payload: FetchReportToolLeaderboardDataPayload, { dispatch }) => {
    try {
      const { startDate, endDate, isWidget, userIds, students } = payload;

      const response = await dispatch(
        fetchApi({
          url: generateAPIUrl({
            type: ReportTools.Leaderboard,
            userIds,
            startDate,
            endDate,
            isWidget
          }),
          method: APIMethods.GET,
          endpoint: APIEndpoints.EDUCATION
        })
      );

      const { data } = response;
      return { data, students };
    } catch (error) {
      throw error;
    }
  }
);

const fetchReportToolQuestionsData = createAsyncThunk(
  'reportTool/fetchReportToolQuestionsData',
  async (payload: FetchReportToolQuestionsDataPayload, { dispatch }) => {
    const { startDate, endDate, isWidget, userIds } = payload;

    try {
      const response = await dispatch(
        fetchApi({
          url: generateAPIUrl({
            type: ReportTools.Questions,
            userIds,
            startDate,
            endDate,
            isWidget
          }),
          method: APIMethods.GET,
          endpoint: APIEndpoints.EDUCATION
        })
      );

      const { data } = response;
      return { data, isWidget };
    } catch (error) {
      throw error;
    }
  }
);

const fetchReportToolSkillsData = createAsyncThunk(
  'reportTool/fetchReportToolSkillsData',
  async (payload: FetchReportToolQuestionsDataPayload, { dispatch }) => {
    const { startDate, endDate, isWidget, userIds } = payload;

    try {
      const response = await dispatch(
        fetchApi({
          url: generateAPIUrl({
            type: ReportTools.SkillReport,
            userIds,
            startDate,
            endDate,
            isWidget
          }),
          method: APIMethods.GET,
          endpoint: APIEndpoints.EDUCATION
        })
      );

      const { data } = response;
      return { data };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchReportToolData = createAsyncThunk<
  FetchReportToolDataPayload,
  FetchReportToolDataPayload,
  { rejectValue: RejectedValuePayload }
>(
  'reportTool/fetchReportToolData',
  async (
    {
      type,
      selectedRange,
      startDate,
      endDate,
      isWidget = false
    }: FetchReportToolDataPayload,
    { dispatch, getState, rejectWithValue }
  ) => {
    const { startDate: _startDate, endDate: _endDate } = getDates({
      selectedRange,
      startDate,
      endDate
    });
    // const features = useAppSelector(state => state.featureFlag);
    const state: any = getState();
    const { roster } = state.class.currentClass;

    const userIds: Array<number> = [];
    const students: Students = {};

    roster.forEach((user: any) => {
      const { firstname, lastname, username, educationUserId: userId } = user;
      userIds.push(userId);
      students[userId] = {
        firstname,
        lastname,
        username
      };
    });

    let data: any = [];
    let payload: any;

    const reportToolPayload = {
      isWidget,
      userIds,
      students,
      startDate: _startDate,
      endDate: _endDate
    };

    const rejectedPayload = {
      type,
      startDate: _startDate,
      endDate: _endDate,
      selectedRange,
      isWidget
    };

    const isUserIdsAvailable = reportToolPayload.userIds.length > 0;
    switch (type) {
      case ReportTools.AssignmentTool:
        try {
          const response = await dispatch(fetchReportToolAssignmentToolData());
          payload = unwrapResult(response);
        } catch (error) {
          return rejectWithValue({
            ...rejectedPayload,
            data: error.message
          } as RejectedValuePayload);
        }
        break;
      case ReportTools.ClassActivity:
        try {
          if (isUserIdsAvailable) {
            const response = await dispatch(
              fetchReportToolClassActivityData(reportToolPayload)
            );
            payload = unwrapResult(response);
          } else {
            throw new Error('Add a player to see reports');
          }
        } catch (error) {
          return rejectWithValue({
            ...rejectedPayload,
            data: error.message
          } as RejectedValuePayload);
        }

        break;
      case ReportTools.Leaderboard:
        try {
          if (isUserIdsAvailable) {
            const response = await dispatch(
              fetchReportToolLeaderboardData(reportToolPayload)
            );
            payload = unwrapResult(response);
          } else {
            throw new Error('Add a player to see reports');
          }
        } catch (error) {
          return rejectWithValue({
            ...rejectedPayload,
            data: error.message
          } as RejectedValuePayload);
        }

        break;
      case ReportTools.Questions:
        try {
          if (isUserIdsAvailable) {
            const response = await dispatch(
              fetchReportToolQuestionsData(reportToolPayload)
            );
            payload = unwrapResult(response);
          } else {
            throw new Error('Add a player to see reports');
          }
        } catch (error) {
          return rejectWithValue({
            ...rejectedPayload,
            data: error.message
          } as RejectedValuePayload);
        }
        break;
      case ReportTools.SkillReport:
        try {
          if (isUserIdsAvailable) {
            const response = await dispatch(
              fetchReportToolSkillsData({
                ...reportToolPayload,
                startDate: moment('2018-10-01')
              })
            );
            payload = unwrapResult(response);
          } else {
            throw new Error('Add a player to see reports');
          }
        } catch (error) {
          return rejectWithValue({
            ...rejectedPayload,
            data: error.message
          } as RejectedValuePayload);
        }
        break;
      case ReportTools.PlacementTest:
        payload = [];
        break;
      default:
        return rejectWithValue({
          ...rejectedPayload,
          data: `${type} doesn't contain a action to dispatch...`
        } as RejectedValuePayload);
    }

    data = payload.data;

    if (Array.isArray(data) && !data.length) {
      const message = getData({
        type,
        selectedRange,
        isWidget,
        hasData: false
      });
      return rejectWithValue({
        ...rejectedPayload,
        data: message
      } as RejectedValuePayload);
    }

    return {
      isWidget,
      type,
      startDate: _startDate,
      endDate: _endDate,
      selectedRange
    };
  },
  {
    condition: (payload: FetchReportToolDataPayload, { getState }) => {
      const {
        type,
        startDate: _startDate_,
        endDate: _endDate_,
        selectedRange,
        isWidget
      } = payload;

      const state: any = getState();
      const currentReportTool = state.reportTool[type];

      if ('data' in currentReportTool || 'data' in currentReportTool) {
        const {
          _isWidget,
          _selectedRange,
          _startDate,
          _endDate
        } = currentReportTool;
        const { startDate, endDate } = getDates({
          selectedRange,
          startDate: _startDate_,
          endDate: _endDate_
        });

        if (
          startDate.isSame(_startDate) &&
          endDate.isSame(_endDate) &&
          isWidget === _isWidget &&
          selectedRange === _selectedRange
        ) {
          return false;
        }
      }

      return true;
    }
  }
);

const getInitialState = () => {
  const tempInitialState: ReportToolState = {};

  for (const tool of Object.values(ReportTools)) {
    tempInitialState[tool] = {
      status: Statuses.Pending,
      data: [],
      selectedRange: timeFrames.last7
    };
  }

  return tempInitialState;
};

const ReportToolSlice = createSlice({
  name: 'reportTool',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      fetchReportToolAssignmentToolData.fulfilled,
      (state, action) => {
        const { payload } = action;
        const { data } = payload;

        const sortedData = data
          .map((assignment: any) => {
            const {
              _id,
              title,
              end,
              color,
              id: UserGeneratedId,
              status,
              isUserGenerated
            } = assignment;
            const titleLength = 8;
            const id = isUserGenerated ? UserGeneratedId : _id;
            return {
              link: id,
              title: `${title.slice(0, titleLength)} ${
                title.length >= titleLength ? '...' : ''
              }`,
              fullTitle: title,
              end,
              isUserGenerated: isUserGenerated || false,
              color,
              disabled: status === 'Finished'
            };
          })
          .sort((assignmentOne: any, assignmentTwo: any) => {
            return moment(assignmentTwo.end).diff(
              moment(assignmentOne.end),
              'days'
            );
          });

        state[ReportTools.AssignmentTool].data = sortedData;
        state[ReportTools.AssignmentTool].status = Statuses.Pending;
      }
    );
    builder.addCase(
      fetchReportToolClassActivityData.fulfilled,
      (state, action) => {
        const { payload } = action;
        const { data, isWidget, students } = payload;

        if (isWidget) {
          const {
            totalNumberOfAnswersForCurrentWeek,
            totalNumberOfAnswersForPreviousWeek,
            totalNumberOfPassagesReadForCurrentWeek,
            totalNumberOfPassagesReadForPreviousWeek
          } = data;

          const dataWithTypeProperty = [
            {
              type: 'Questions Attempted',
              totalForCurrentWeek: totalNumberOfAnswersForCurrentWeek,
              totalForPreviousWeek: totalNumberOfAnswersForPreviousWeek
            },
            {
              type: 'Passages Read',
              totalForCurrentWeek: totalNumberOfPassagesReadForCurrentWeek,
              totalForPreviousWeek: totalNumberOfPassagesReadForPreviousWeek
            }
          ];

          state[ReportTools.ClassActivity].data = dataWithTypeProperty;
          state[ReportTools.ClassActivity].status = Statuses.Pending;
        } else {
          const dataWithUsersName = Object.values(data).map((user: any) => {
            const { userId } = user;
            const { firstname, lastname, username } = students[userId];
            const fullName = `${firstname} ${lastname} (${username})`;

            return {
              ...user,
              fullName
            };
          });

          state[ReportTools.ClassActivity].data = dataWithUsersName;
          state[ReportTools.ClassActivity].status = Statuses.Pending;
        }
      }
    );
    builder.addCase(
      fetchReportToolLeaderboardData.fulfilled,
      (state, action) => {
        const { payload } = action;
        const { data, students } = payload;
        const dataWithRanks: any = [];

        data.forEach((user: any, rank: number) => {
          const { userId } = user;
          const { firstname, lastname, username } = students[userId];
          const fullName = `${firstname} ${lastname} (${username})`;

          const tempUser = {
            ...user,
            fullName
          };

          if (rank) {
            const currentPlayerRatio =
              tempUser.totalNumberOfCorrectAnswers /
              tempUser.totalNumberOfAnswerers;
            const previousPlayerRatio =
              dataWithRanks[rank - 1].totalNumberOfCorrectAnswers /
              dataWithRanks[rank - 1].totalNumberOfAnswers;

            if (currentPlayerRatio === previousPlayerRatio) {
              tempUser.rank = dataWithRanks[rank - 1].rank;
            } else {
              tempUser.rank = rank + 1;
            }
          } else {
            tempUser.rank = 1; // Since data is sorted, first place will always receive gold.
          }

          dataWithRanks.push(tempUser);
        });

        state[ReportTools.Leaderboard].status = Statuses.Pending;
        state[ReportTools.Leaderboard].data = dataWithRanks;
      }
    );
    builder.addCase(
      fetchReportToolQuestionsData.fulfilled,
      (state: any, action: any) => {
        const { payload } = action;
        const { isWidget, data } = payload;
        const dataWithLocationOfAnswers = [...data];
        if (isWidget) {
          if (dataWithLocationOfAnswers.length === 1) {
            const statistic = data[0];
            const { locationWhereQuestionWasAnswered } = statistic;

            if (locationWhereQuestionWasAnswered === 'School') {
              dataWithLocationOfAnswers.push({
                locationWhereQuestionWasAnswered: 'Home'
              });
            } else {
              dataWithLocationOfAnswers.push({
                locationWhereQuestionWasAnswered: 'School'
              });
            }
          }
        }
        state[ReportTools.Questions].data = dataWithLocationOfAnswers;
        state[ReportTools.Questions].status = Statuses.Pending;
      }
    );
    builder.addCase(fetchReportToolSkillsData.fulfilled, (state, action) => {
      state[ReportTools.SkillReport].data = action.payload.data;
      state[ReportTools.SkillReport].status = Statuses.Pending;
    });
    builder.addCase(fetchReportToolData.fulfilled, (state, action) => {
      const { payload } = action;
      const { type, startDate, endDate, selectedRange, isWidget } = payload;
      state[type] = {
        data: state[type].data,
        status: Statuses.Success,
        startDate,
        endDate,
        selectedRange,
        isWidget
      };
    });
    builder.addCase(fetchReportToolData.rejected, (state, action) => {
      if (action.payload) {
        const {
          type,
          data,
          startDate,
          endDate,
          selectedRange,
          isWidget
        } = action.payload;

        state[type] = {
          status: Statuses.Error,
          data,
          startDate,
          endDate,
          selectedRange,
          isWidget
        };
      }
    });
    builder.addCase(fetchReportToolData.pending, (state, action) => {
      const { type } = action.meta.arg;
      state[type].status = Statuses.Pending;
    });
  }
});

export default ReportToolSlice.reducer;
