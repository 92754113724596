export const getRegistrationFlowValue = () => {
  return 'B';
  // const experimentalRegistrationFlows: { name: string; pct: number }[] = [
  //   { name: 'A', pct: 34 },
  //   { name: 'B', pct: 33 },
  //   { name: 'C', pct: 33 }
  // ];
  // const expanded = experimentalRegistrationFlows.flatMap(registrationFlow =>
  //   Array(registrationFlow.pct).fill(registrationFlow)
  // );
  // const chosenregistrationFlow =
  //   expanded[Math.floor(Math.random() * expanded.length)];

  // return chosenregistrationFlow.name;
};
