import {
  CHUCK_TOGGLE_GIFT,
  CHUCK_SHOW_BUBBLE,
  CHUCK_HIDE_BUBBLE,
  CHUCK_WAS_INTERACTED,
  CHUCK_RESET_STATE,
  CHUCK_TASKS_COMPLETED,
  CHUCK_COMPLETE_TASK,
  CHUCK_INTRODUCED,
  CHUCK_RESET_COMPLETED_TASKS
} from '../types/chuck/chuck-action-types';

export const chuckToggleGift = () => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_TOGGLE_GIFT });
  };
};

export const chuckShowBubble = (message: string) => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_SHOW_BUBBLE, payload: message });
    dispatch({ type: CHUCK_INTRODUCED, payload: true });
  };
};

export const chuckHideBubble = () => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_HIDE_BUBBLE });
  };
};

export const chuckWasInteracted = (value: Boolean) => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_WAS_INTERACTED, payload: value });
  };
};

export const chuckResetState = () => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_RESET_STATE });
  };
};

export const chuckCompleteTask = (task: string) => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_COMPLETE_TASK, payload: task });
  };
};

export const chuckCompleteAllTasks = (value: Boolean) => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_TASKS_COMPLETED, payload: value });
  };
};

export const chuckResetCompletedTasks = () => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_RESET_COMPLETED_TASKS });
  };
};

export const chuckWasIntroducedInThisSession = (value: Boolean) => {
  return (dispatch: any) => {
    dispatch({ type: CHUCK_INTRODUCED, payload: value });
  };
};
