import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { show, hide } from 'redux-modal';
import useQueryParams from '../../hooks/UseQueryParams';
import {
  authenticateGoogleAccount,
  registerGoogleAccount
} from '../../redux/actions/google-actions';
import { userTypes } from '../../constants/users';
import { MODAL_DEFAULT } from '../../constants/modals';
import {
  loginUserWithToken,
  authenticateUser,
  loginLoadInitialState
} from '../../redux/login/login-actions';
import { trackGoogleLogin } from '../../redux/actions/mixpanel-actions';
import View from '../../components/view/View';
import { makeStyles, Paper } from '@material-ui/core';
import { SSOState } from '../../types/google/google-authenticate-request-type';
import { push } from 'connected-react-router';
import HaveAccountPrompt from './GoogleRedirect/HaveAccountPrompt';
import SelectUserType from './GoogleRedirect/SelectUserType';
import LinkAccount from './GoogleRedirect/LinkAccount';
import {
  authenticatePlayer,
  updatePlayerData
} from '../../redux/actions/player-actions';
import {
  getUser,
  updateUserInformation
} from '../../redux/actions/user-actions';
import { LOGIN_SUCCESS } from '../../redux/login/login-action-types';
import {
  trackLoginGoogleAnalytics,
  trackRegister
} from '../../utils/google/google-analytics';
import { parseJwt } from '../../utils/jwt';
import mixpanel from '../../utils/mixpanel';
import { UserType } from '../../enums/UserType';
import _ from 'lodash';
import store from '../../redux/store';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';
import { getRegistrationFlowValue } from '../../utils/registrations/getRegistrationFlowValue';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
    position: 'relative',
    textAlign: 'center',
    flex: 'auto',
    maxWidth: 820,
    margin: 'auto'
  }
}));

enum Steps {
  None,
  HaveAccountPrompt,
  SelectUserType,
  LinkAccount
}

const GoogleRedirect: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const classes = useStyles();
  const userData = useSelector((state: any) => state.userData);
  const [state, setState] = useState({
    accessToken: '',
    refreshToken: '',
    userType: '',
    idToken: '',
    userExists: false,
    ssoState: '',
    currentStep: Steps.None
  });
  const [googleData, setGoogleData] = useState<any>({});
  const [loginError, setLoginError] = useState('');

  const featureFlag: any = store.getState().featureFlag;

  const handleAuthorization = async (
    accessCode: string,
    ssoState: SSOState
  ) => {
    const userType = ssoState === SSOState.USER ? '' : userTypes.player;
    try {
      const authResponse: any = await dispatch(
        authenticateGoogleAccount({
          accessCode: decodeURIComponent(accessCode),
          userType
        })
      );
      handleGoogleAccountSetup(authResponse, ssoState, userType);
    } catch (error) {
      if (error && error.response) {
        if (error.response.status === 422) {
          dispatch(push('/login-selection'));
          dispatch(
            show('modal', {
              type: MODAL_DEFAULT,
              children:
                'Google SSO is currently unavailable. We are currently looking into resolving this issue.'
            })
          );
        } else if (error.response.status === 409) {
          handleDuplicateEmailAccountSetup(error.response, ssoState, userType);
        } else if (error.response.status === 404) {
          handleGoogleAccountSetup(error.response, ssoState, userType);
        } else {
          goToLoginAndShowMessage();
        }
      } else {
        goToLoginAndShowMessage();
      }
    }
  };

  const goToLoginAndShowMessage = () => {
    dispatch(push('/login-selection'));
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        children:
          'There was an error processing your request. Please, try again later.'
      })
    );
  };

  const handleDuplicateEmailAccountSetup = (
    authResponse: any,
    ssoState: any,
    userType: any
  ) => {
    const {
      accessToken,
      refreshToken,
      idToken,
      googleData
    } = authResponse.data;
    const newState: any = {
      ...state,
      accessToken,
      refreshToken,
      idToken,
      ssoState,
      userType
    };
    setGoogleData(googleData);
    newState.userExists = true;
    newState.currentStep = Steps.LinkAccount;
    setState(newState);
  };

  const handleGoogleAccountSetup = (
    authResponse: any,
    ssoState: any,
    userType: any
  ) => {
    const {
      token,
      status,
      accessToken,
      refreshToken,
      idToken,
      googleData
    } = authResponse.data;
    const newState: any = {
      ...state,
      accessToken,
      refreshToken,
      idToken,
      ssoState,
      userType
    };
    setGoogleData(googleData);
    if (status === 200) {
      if (ssoState === SSOState.USER) {
        dispatch(loginUserWithToken(token));
        dispatch(trackGoogleLogin());
        const user = parseJwt(token);
        trackLoginGoogleAnalytics(user._id, user.userType);
      } else {
        handleOpenGame(authResponse.data);
      }
    } else {
      newState.userExists = false;
      newState.currentStep = Steps.HaveAccountPrompt;
    }
    setState(newState);
  };

  useEffect(() => {
    const { code, state: ssoState } = queryParams;
    handleAuthorization(code, ssoState);
  }, []);

  const handleUpdateUserInformation = (userId: string, updateData: any) => {
    dispatch(
      updateUserInformation({
        _id: userId,
        ...updateData
      })
    );
  };

  useEffect(() => {
    if (userData._id && state.currentStep === Steps.LinkAccount) {
      handleUpdateUserInformation(userData._id, { google: googleData });
    }
  }, [userData._id]);

  const handleOpenGame = (registerResponse: any) => {
    const { _id, educationUserId, token } = registerResponse;
    const authToken = btoa(JSON.stringify({ _id, educationUserId, token }));
    dispatch(show('spinner', { text: 'Loading the Dreamscape Game' }));
    localStorage.clear();
    setTimeout(() => {
      //@ts-ignore
      window.location.href = `${process.env.REACT_APP_WEBGL_LINK}?auth=${authToken}`;
    }, 500);
  };

  const handleRegistration = async (userType?: string) => {
    try {
      const registerResponse: any = await dispatch(
        registerGoogleAccount({
          userType: userType || state.userType,
          accessToken: state.accessToken,
          idToken: state.idToken,
          refreshToken: state.refreshToken
        })
      );
      const { token, status } = registerResponse.data;
      const user = parseJwt(token);

      trackRegister(user._id, user.userType);
      if (state.ssoState === SSOState.USER) {
        if (
          !getLocalStorage('newRegistrationFlow') &&
          user.userType === UserType.Teacher &&
          featureFlag.newRegistrationFlow
        ) {
          const registrationFlowValue = getRegistrationFlowValue();
          setLocalStorage('newRegistrationFlow', registrationFlowValue);

          if (!getLocalStorage('onboardingStartedFired')) {
            console.log('DEBUG: triggering Onboarding Started for Google');
            mixpanel.track('Onboarding Started ', undefined, true);
            setLocalStorage('onboardingStartedFired', 'true');
          }
        }
        if (
          getLocalStorage('newRegistrationFlow') === 'B' &&
          user.userType === UserType.Teacher &&
          featureFlag.newRegistrationFlow
        ) {
          dispatch(
            loginUserWithToken(
              token,
              '/dashboard/free-trial?mandatory=false',
              'google'
            )
          );
        } else if (
          getLocalStorage('newRegistrationFlow') === 'A' &&
          user.userType === UserType.Teacher &&
          featureFlag.newRegistrationFlow
        ) {
          dispatch(
            loginUserWithToken(token, '/dashboard/select-location', 'google')
          );
        } else if (
          getLocalStorage('newRegistrationFlow') === 'C' &&
          user.userType === UserType.Teacher &&
          featureFlag.newRegistrationFlow
        ) {
          dispatch(
            loginUserWithToken(
              token,
              '/dashboard/free-trial?mandatory=true',
              'google'
            )
          );
        } else {
          dispatch(loginUserWithToken(token));
        }

        dispatch(trackGoogleLogin());
      } else {
        handleOpenGame(registerResponse.data);
      }
      trackLoginGoogleAnalytics(user._id, user.userType);
    } catch (error) {
      console.log('error in registering google user', error);
      dispatch(push('/login-selection'));
      dispatch(
        show('modal', {
          type: MODAL_DEFAULT,
          children:
            'There was an error processing your registration. Please, try again later'
        })
      );
    }
  };

  const handleContinueClick = (selectedOption: any) => {
    if (selectedOption === 1) {
      if (state.ssoState === SSOState.USER) {
        return setState({
          ...state,
          currentStep: Steps.SelectUserType
        });
      } else {
        return handleRegistration();
      }
    }
    return setState({
      ...state,
      currentStep: Steps.LinkAccount
    });
  };

  const handleLoginClick = async (username: string, password: string) => {
    try {
      localStorage.clear();
      username = username.trim().toLowerCase();
      setLoginError('');
      dispatch(show('spinner', { text: 'Linking your account' }));
      const loginData = {
        username,
        password
        // googleAccessToken: state.accessToken
      };
      let promise = () => authenticatePlayer(loginData);
      if (state.ssoState === SSOState.USER) {
        promise = () => authenticateUser(loginData);
      }
      const authResponse = await dispatch(promise());
      const { token, _id } = authResponse.data;

      if (state.ssoState === SSOState.USER) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { token }
        });
        const redirect = true;
        dispatch(getUser(token, redirect));

        loginLoadInitialState(dispatch);
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { token }
        });
        await dispatch(
          updatePlayerData(
            {
              google: {
                ...googleData
              }
            },
            _id
          )
        );
        handleOpenGame(authResponse.data);
      }
      const user = parseJwt(token);
      trackLoginGoogleAnalytics(user._id, user.userType);
    } catch (error) {
      const { response } = error;
      if (response) {
        if (response.status === 404) {
          setLoginError(
            'Account not found. Please, make sure you typed your username correctly.'
          );
        } else if ([401, 403].includes(response.status)) {
          setLoginError('Incorrect password. Please, try again.');
        } else {
          setLoginError(
            'There was an error linking your account. Please, try again later.'
          );
        }
      } else {
        setLoginError('There was an internal error. Please, try again later.');
      }
    } finally {
      dispatch(hide('spinner'));
    }
  };

  const renderCurrentStep = () => {
    switch (state.currentStep) {
      case Steps.HaveAccountPrompt:
        return <HaveAccountPrompt handleContinueClick={handleContinueClick} />;
      case Steps.SelectUserType:
        return (
          <SelectUserType
            handleRegistration={(userType: string) =>
              handleRegistration(userType)
            }
            handleBackClick={() =>
              setState({ ...state, currentStep: Steps.HaveAccountPrompt })
            }
          />
        );
      case Steps.LinkAccount:
        return (
          <LinkAccount
            errorMessage={loginError}
            userExists={state.userExists}
            googleData={googleData}
            handleLoginClick={handleLoginClick}
            handleBackClick={() =>
              setState({ ...state, currentStep: Steps.HaveAccountPrompt })
            }
            emailPlaceholder={
              state.ssoState === SSOState.PLAYER_WEB
                ? 'Dreamscape Username'
                : 'Dashboard E-mail'
            }
            passwordPlaceholder="Dreamscape Password"
          />
        );
    }
  };

  if (state.currentStep === Steps.None) {
    return null;
  }

  return (
    <View flex style={{ height: '100vh' }}>
      <Paper className={classes.paper}>{renderCurrentStep()}</Paper>
    </View>
  );
};

export default GoogleRedirect;
