import {
  REPORT_CARD_GET,
  REPORT_CARD_GET_SKILLS,
  REPORT_CARD_SKILLS_SYNC_PROGRESS
} from '../types/report-card/report-card-action-types';

export const reportCardReducer = (
  state = {
    last10QuestionsAnswered: [],
    questionStats: {
      correct: 0,
      incorrect: 0,
      total: 0
    },
    passageStats: {
      mastered: 0,
      passed: 0,
      failed: 0,
      read: 0
    },
    gradeStats: {
      readingLevel: 0,
      gradeLevel: 0
    },
    regionStats: {
      readingLevel: 0,
      gradeLevel: 0
    },
    skillStats: {
      advanced: { correct: 0, incorrect: 0 },
      foundational: { correct: 0, incorrect: 0 },
      independent: { correct: 0, incorrect: 0 },
      isSyncInProgress: false
    },
    totalQuestionAttempts: 0
  },
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case REPORT_CARD_GET:
      return payload;
    case REPORT_CARD_GET_SKILLS:
      return {
        ...state,
        skillStats: payload
      };
    case REPORT_CARD_SKILLS_SYNC_PROGRESS:
      return {
        ...state,
        skillStats: {
          ...state.skillStats,
          isSyncInProgress: payload
        }
      };
    default:
      return state;
  }
};
