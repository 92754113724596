import { APIMethods } from '../../types/fetch/fetch-types';
import { LoginSuccessResponse } from '../../types/user/user-response-types';
import { fetchApi } from '../actions/fetch-actions';
import { getUser } from '../actions/user-actions';
import { AxiosResponse } from 'axios';
import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_UPDATE_TOKEN
} from './login-action-types';
import { getClasses } from '../actions/class-actions';
import { getNotifications } from '../actions/notification-actions';
import { hide, show } from 'redux-modal';
import { getAllMembers } from '../actions/members-actions';
import { chuckWasIntroducedInThisSession } from '../actions/chuck-actions';
import { Debug } from '../../utils/debug';
import { trackLoginGoogleAnalytics } from '../../utils/google/google-analytics';
import { parseJwt } from '../../utils/jwt';
import {
  setPlayerLoginsInfo,
  getPlayerLoginsInfo
} from '../../utils/user';
import mixpanel from '../../utils/mixpanel';
import { updateRegistrationEvents } from '../actions/mixpanel-actions';
import { UserType } from '../../enums/UserType';
import { getLocalStorage } from '../../utils/localStorage';

const setLoginsInLocalStorage = (classes: any) => {
  try {
    const filterClasses = () => {
      return classes.filter((e: any) => {
        return e.archived === false;
      });
    };
    const preExistingLogins = getPlayerLoginsInfo();
    let existingLogins: any = {};
    if (preExistingLogins !== null) {
      existingLogins = JSON.parse(preExistingLogins);
    }
    const classData = filterClasses();
    let playerLogins = {};
    classData.forEach((cls: any) => {
      playerLogins = {
        ...playerLogins,
        [cls.code]: {
          currentLogin: cls.roster.length ? false : true,
          banner:
            preExistingLogins !== null && !existingLogins[cls.code].currentLogin
              ? false
              : !!cls.roster.length,
          popup:
            preExistingLogins !== null && !existingLogins[cls.code].currentLogin
              ? false
              : !!cls.roster.length
        }
      };
    });
    setPlayerLoginsInfo(JSON.stringify(playerLogins));
  } catch (e) {
    console.log('Error in setting local storage', e);
  }
};

interface loginLoadInitialStateOptions {
  resetCurrentClass?: boolean;
  urlClassCode?: string;
}
export async function loginLoadInitialState(
  dispatch: any,
  options?: loginLoadInitialStateOptions
) {
  await dispatch(getNotifications());
  await dispatch(getAllMembers());
  await dispatch(chuckWasIntroducedInThisSession(false));
  await dispatch(
    getClasses({
      callbackFunction: setLoginsInLocalStorage,
      resetCurrentClass: options?.resetCurrentClass,
      urlClassCode: options?.urlClassCode
    })
  );
}

export function authenticateUser(data: any) {
  return fetchApi({
    url: 'user/authenticate',
    method: APIMethods.POST,
    data: {
      ...data,
      product: 'dreamscape'
    },
    ignore401: true
  });
}

const trackRegisterEvents = (
  registrationMethod: string | undefined,
  user: any
) => {
  updateRegistrationEvents(user);

  const mixpanelEventProps = {
    userType: user.userType,
    registrationFlow: getLocalStorage('newRegistrationFlow')
  };

  switch (registrationMethod) {
    case 'email': {
      if (user.userType === UserType.Teacher) {
        mixpanel.track(
          'Onboarding - Email Sign-up Success',
          mixpanelEventProps
        );
        mixpanel.track('Onboarding - Register', mixpanelEventProps, true, {
          send_immediately: true
        });
      }
      return;
    }
    case 'clever': {
      console.log('Debug: Case clever');
      mixpanel.track('Onboarding - Clever Sign-up Success', mixpanelEventProps);
      return;
    }
    case 'google': {
      if (user.userType === UserType.Teacher) {
        console.log('Debug: Case Google');
        mixpanel.track(
          'Onboarding - Google Sign-up Success',
          mixpanelEventProps
        );
      }
      return;
    }
    default: {
      return;
    }
  }
};

export function loginUser(
  username: string,
  password: string,
  redirectUrl?: string,
  registrationMethod?: string
) {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'user/authenticate',
        method: APIMethods.POST,
        data: {
          username,
          password,
          product: 'dreamscape'
        },
        spinnerMessage: 'Authenticating'
      })
    )
      .then(async (loginResponse: LoginSuccessResponse) => {
        const { token } = loginResponse.data;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { token }
        });
        const user = parseJwt(token);
        trackLoginGoogleAnalytics(user._id, user.userType);
        loginLoadInitialState(dispatch);
        trackRegisterEvents(registrationMethod, user);
        const redirect = true;
        dispatch(getUser(token, redirect, undefined, undefined, redirectUrl));
      })
      .catch((error: AxiosResponse) => {
        let errorMessage = 'Invalid e-mail or password.';
        if (error.status === 500) {
          errorMessage =
            "Looks like we're facing some internal issue. Please, try again later.";
        }
        dispatch(hide('spinner'));
        dispatch({
          type: LOGIN_FAILED,
          payload: {
            errorMessage
          }
        });
      });
  };
}

export const loginUserWithToken = (
  token: string,
  redirectUrl?: string,
  registrationMethod?: string,
  urlClassCode?: string
) => {
  return (dispatch: any) => {
    const redirect = true;
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token }
    });
    const user = parseJwt(token);
    dispatch(getUser(token, redirect, undefined, undefined, redirectUrl));
    loginLoadInitialState(dispatch, { resetCurrentClass: true, urlClassCode });
    trackRegisterEvents(registrationMethod, user);
  };
};

/**
 * @description refreshes token
 */
export function refreshToken() {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `user/authenticate`,
        method: APIMethods.GET
      })
    )
      .then((response: AxiosResponse) => {
        const { data } = response;
        localStorage.setItem('user-token', JSON.stringify(data.token));
        dispatch({
          type: LOGIN_UPDATE_TOKEN,
          payload: {
            token: data.token
          }
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
}
