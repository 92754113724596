import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import colors from '../../styles/colors';
import { Warning } from '@material-ui/icons';
import { show } from 'redux-modal/lib/actions';
import { MODAL_ADD_STUDENT } from '../../constants/modals';
import clsx from 'clsx';

type ViewProps = {
  title?: string;
  subtitle?: string;
  icon?: any;
  flex?: boolean;
  renderBackButton?: any;
  renderButtons?: any;
  positionSubtitle?: 'bottom' | 'right';
  style?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
  notificationTop: {
    background: colors.blueHover,
    color: colors.white,
    cursor: 'pointer',
    display: 'flex',
    margin: '-25px -25px 20px',
    padding: '20px 25px',
    transition: '0.3s',

    '&:hover': {
      background: colors.blueDark
    }
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  flexAlign: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const View: React.FC<ViewProps> = ({
  title,
  subtitle,
  icon,
  children,
  flex,
  positionSubtitle,
  style,
  renderBackButton,
  renderButtons
}) => {
  const { currentClass } = useSelector((state: any) => state.class);
  const userData = useSelector((state: any) => state.userData);
  const classes = useStyles();
  const dispatch = useDispatch();
  const match: any = useRouteMatch();

  const renderViewNotification = () => {
    if (match.params.classCode) {
      if (!currentClass.roster.length && userData.userType === 'Parent') {
        return (
          <div
            className={classes.notificationTop}
            onClick={() => dispatch(show('modal', { type: MODAL_ADD_STUDENT }))}
          >
            <Warning style={{ marginRight: 10 }} />
            <Typography component="span">
              Add players to your class to get started.
            </Typography>
          </div>
        );
      }
    }
    return null;
  };

  const viewTitle = (
    <Typography
      component="h1"
      variant="h4"
      style={{
        alignItems: 'center',
        display:
          subtitle && !isMobile && positionSubtitle !== 'bottom'
            ? 'flex'
            : 'block',
        marginBottom: 20
      }}
    >
      {renderBackButton && renderBackButton()}
      <div className={clsx(!isMobile && classes.flexWrapper)}>
        {icon ? (
          <div className={classes.flexAlign}>
            {icon}
            {title}
          </div>
        ) : (
          <>{title}</>
        )}{' '}
        {renderButtons && (
          <div className={clsx(!isMobile && classes.flexWrapper)}>
            {renderButtons()}
          </div>
        )}
      </div>
      {subtitle ? (
        <Typography
          component="h2"
          variant="h6"
          style={{ marginLeft: positionSubtitle === 'bottom' ? 0 : 10 }}
        >
          {subtitle}
        </Typography>
      ) : null}
    </Typography>
  );
  const flexStyle = flex
    ? {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        position: 'relative' as 'relative'
      }
    : {
        position: 'relative' as 'relative'
      };
  return (
    <div
      style={{
        // padding: isMobile ? 10 : 25,
        padding: 25,
        overflowX: 'hidden',
        width: '100%',
        ...flexStyle,
        ...style
      }}
    >
      {renderViewNotification()}
      {viewTitle}
      {children}
    </div>
  );
};

View.defaultProps = {
  title: '',
  flex: false
};

export default View;
