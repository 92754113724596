import { Box, Grid, List, MenuItem } from '@material-ui/core';
import React, { useCallback, useRef } from 'react';
import { SearchResultItem } from './SearchResultItem';
import { useVirtual, VirtualItem } from 'react-virtual';
import { Loader } from '../../../spinner/Loader';

interface SearchResultListProps {
  results: any[];
  skills: any[];
  handleAddQuestion: any;
  genres: any[];
  handleDeleteQuestion: any;
  nextPage: any;
  loading: boolean;
  total: number;
}

export const SearchResultList = (props: SearchResultListProps) => {
  const {
    results,
    skills,
    handleAddQuestion,
    genres,
    loading,
    handleDeleteQuestion,
    total,
    nextPage
  } = props;
  const parentRef = React.useRef();
  const rowVirtualizer = useVirtual({
    size: results.length,
    parentRef,
    overscan: 4
  });
  const observer = useRef<IntersectionObserver>();
  const hasMore = !(results.length >= total);
  const lastElementRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current?.disconnect();
      if (!hasMore) return;
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          nextPage();
        }
      });
      if (node) observer.current?.observe(node);
    },
    [loading, hasMore]
  );
  return (
    // @ts-ignore
    <List ref={parentRef}>
      {rowVirtualizer.virtualItems.map((result: VirtualItem, index: number) => {
        const currentResult = results[result.index];
        const isLastElement = result.index === results.length - 1;
        return (
          <div key={index} ref={isLastElement ? lastElementRef : undefined}>
            <SearchResultItem
              key={index}
              handleDeleteQuestion={handleDeleteQuestion}
              handleAddQuestion={handleAddQuestion}
              skills={skills}
              genres={genres}
              result={currentResult}
            />
          </div>
        );
      })}
      {loading && (
        <Grid container justify={'center'}>
          <Grid item xs={12}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Loader text={'loading'} textColor={'black'} color={'black'} />
            </Box>
          </Grid>
        </Grid>
      )}
    </List>
  );
};

export type SearchResult = IndependentQuestionResult | PassageQuestionResult;
interface IndependentQuestionResult {
  id: number;
  skillId: number;
  gradeId: number;
  clusterId: number;
  lessonId: number;
  educationToolUserId: number;
  educationToolUserGroupId: number;
  subjectId: number;
  questionTypeId: number;
  commonCoreIndependentId: number;
  name: string;
  question: string;
  potentialAnswers: string;
  correctAnswers: string;
  disabled: number;
  coreStandard: string;
  stem: string;
  hint: string;
  isAdded?: boolean;
  feedbackIncorrect: string;
  feedbackCorrect: string;
  createdAt: string;
  updatedAt: string;
  isUserGenerated: number;
}

interface PassageQuestionResult {
  id: number;
  genreId: number;
  gradeId: number;
  isAdded?: boolean;
  bookId: number;
  passageLengthId: number;
  readingLevel: number;
  fleschKincaidLevel: number;
  name: string;
  text: string;
  disabled: number;
  pending: number;
  updatedAt: string;
  createdAt: string;
  passageNumber: string;
  isDeleted: number;
  questions: any[];
}
