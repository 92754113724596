import {
  REAL_TIME_UPDATE_PLAYER,
  NEW_REAL_TIME_UPDATE_PLAYER,
  REAL_TIME_CONNECT,
  REAL_TIME_INITIALIZE_DATA,
  REAL_TIME_DISCONNECT
} from '../types/realtime/realtime-action-types';
import RealTimeState from '../../types/state/realtime-state-type';
import { RealTimeData } from '../../types/realtime/realtime-types';

const calculateCorrectPercentage = (player: RealTimeData) => {
  const totalQuestions = player.correct + player.incorrect;
  return (player.correct / totalQuestions) * 100;
};

const initialState: RealTimeState = {
  connection: {
    id: '',
    connectedAt: new Date()
  },
  metrics: {
    totalPlaying: 0,
    totalInactive: 0,
    totalQuestions: 0,
    totalStruggling: 0
  },
  players: []
};

export const realTimeReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case REAL_TIME_UPDATE_PLAYER: {
      const players = state.players.map(player => {
        if (player.userId === payload.userId) {
          player = {
            ...player,
            correct: payload.correctQuestions,
            incorrect: payload.incorrectQuestions,
            totalQuestions:
              payload.incorrectQuestions + payload.correctQuestions,
            isOnline: payload.isOnline
          };
          player.correctPercentage = calculateCorrectPercentage(player);
        }
        return player;
      });
      let totalPlaying = 0;
      let totalInactive = 0;
      let totalStruggling = 0;
      let totalQuestions = 0;
      players.forEach(player => {
        if (player.totalQuestions > 0) {
          totalPlaying++;
          totalQuestions += player.totalQuestions;
          if (player.correctPercentage < 50) {
            totalStruggling++;
          }
        } else {
          totalInactive++;
        }
      });
      return {
        ...state,
        players,
        metrics: {
          totalPlaying,
          totalInactive,
          totalStruggling,
          totalQuestions
        }
      };
    }
    case NEW_REAL_TIME_UPDATE_PLAYER:
      const players = state.players.map(player => {
        if (
          player.userId?.toString() === payload.playerInfo.userId?.toString()
        ) {
          player = {
            ...player,
            correct: payload.playerInfo.correctQuestions,
            incorrect: payload.playerInfo.incorrectQuestions,
            totalQuestions:
              payload.playerInfo.incorrectQuestions +
              payload.playerInfo.correctQuestions,
            isOnline: payload.playerInfo.isOnline
          };
          player.correctPercentage = calculateCorrectPercentage(player);
        }
        return player;
      });
      const metrics = {
        totalPlaying: 0,
        totalInactive: players.length,
        totalStruggling: 0,
        totalQuestions: 0
      };

      players.forEach(player => {
        metrics.totalQuestions += player.totalQuestions;
        if (player.correctPercentage < 50) {
          metrics.totalStruggling++;
        }
        if (player.isOnline) {
          metrics.totalPlaying++;
          metrics.totalInactive--;
        }
      });
      return {
        ...state,
        players,
        metrics
      };
    case REAL_TIME_INITIALIZE_DATA:
      return {
        ...state,
        players: payload,
        metrics: initialState.metrics
      };
    case REAL_TIME_CONNECT:
      return {
        ...state,
        connection: {
          id: payload,
          connectedAt: new Date()
        }
      };
    case REAL_TIME_DISCONNECT:
      return initialState;
    default:
      return state;
  }
};
