import React from 'react';
import { connectModal, InjectedProps } from 'redux-modal';
import { connect } from 'react-redux';
import ModalDefault from './ModalDefault';
import {
  MODAL_ADD_CLASS,
  MODAL_ADD_STUDENT,
  MODAL_ADD_ACCOUNT,
  MODAL_ASSIGN_MEMBERSHIP,
  MODAL_CREATE_ASSIGNMENT,
  MODAL_DEFAULT,
  MODAL_EDIT_CLASS,
  MODAL_EDIT_STUDENT,
  MODAL_QUESTION_BREAKDOWN,
  MODAL_SELECT_CLASS,
  MODAL_SEND_PARENT_INVITE,
  MODAL_SKILL_REPORT,
  MODAL_CHANGE_PASSWORD,
  MODAL_CLEVER,
  MODAL_SUCCESS,
  MODAL_TRAINING,
  MODAL_PASTE_CSV,
  MODAL_CHUCK_TASKS,
  MODAL_CANCEL_MEMBERSHIP,
  MODAL_CREATE_TEST_PREP,
  MODAL_TEST_PREP_FEEDBACK,
  MODAL_ASSIGN_MY_CONTENT,
  MODAL_SELECT_ASSIGNMENT_TYPE,
  MODAL_SELECT_DATE_RANGE,
  MODAL_GOOGLE_CLASSROOM_IMPORT,
  MODAL_GOOGLE_CLASSROOM_SYNC,
  MODAL_GOOGLE_ACCOUNT_INFO,
  MODAL_UPDATE_ASSIGNMENT,
  MODAL_ASSIGNMENT_TUTORIAL,
  MODAL_ASSIGNMENT_CREATED,
  MODAL_ASSIGNMENT_OPTIONS,
  MODAL_CLASS_MISSION_REPORT,
  MODAL_NONPREMIUM_CLASS_MISSION,
  MODAL_CHOOSE_CANCELLATION,
  MODAL_PAUSE_MEMBERSHIP,
  MODAL_SELECT_MONTH_YEAR,
  MODAL_PARENT_MEMBERSHIP,
  MODAL_INVITE_ADMIN,
  MODAL_VIEW_ADMINS,
  MODAL_TERMS_AND_CONDITIONS,
  MODAL_CANCEL_SUBSCRIPTION,
  MODAL_ASSIGN_USER_GENERATED_CONTENT,
  MODAL_QUESTION_BANK,
  MODAL_USER_GENERATED_ASSIGNMENT_EDIT,
  MODAL_USER_GENERATED_ASSIGNMENT_DELETE_PROMPT,
  MODAL_PLACEMENT_TEST_REASSIGN,
  MODAL_UGC_CREATE_TEST_PREP,
  MODAL_CREATE_PRE_MADE_ASSIGNMENT,
  MODAL_ASSIGN_REWARDS,
  MODAL_ARCHIVE_CLASSES,
  MODAL_PREMIUM_UPGRADE_REDIRECT,
  MODAL_REMOVE_PLAYER,
  MODAL_PREMIUM_PLUS_LEGACY_INFO,
  MODAL_TEACHER_ASSIGN_MEMBERSHIPS,
  MODAL_MEMBERSHIP_PURCHASE_SUCCESS,
  MODAL_UPGRADE_SUCCESS,
  MODAL_UPGRADE_PREVIEW,
  MODAL_PLAYER_BREAKDOWN_UPGRADE,
  MODAL_SKILLS_PLAYER_BREAKDOWN_UPGRADE,
  MODAL_NEW_LE_OPT_IN
} from '../../../constants/modals';
import ModalSelectClass from './ModalSelectClass';
import ModalAddStudent from './ModalAddStudent';
import ModalAddClass from './ModalAddClass';
import ModalEditStudent from './ModalEditStudent';
import ModalCreateAssignment from './ModalCreateAssignment';
import ModalQuestionBreakdown from './ModalQuestionBreakdown';
import ModalSendInviteParent from './ModalSendParentInvite';
import ModalEditClass from './ModalEditClass';
import ModalAssignMembership from './ModalAssignMembership';
import ModalAddAccount from './ModalAddAccount';
import ModalSkillReport from './ModalSkillReport';
import ModalPasswordChange from './ModalPasswordChange';
import ModalClever from './ModalClever';
import ModalSuccess from './ModalSuccess';
import ModalTraining from './ModalTraining';
import ModalPasteCSV from './ModalPasteCSV';
import ModalChuckTasks from './modal-chuck-tasks/ModalChuckTasks';
import ModalCancelMembership from './ModalCancelMembership';
import ModalCreateTestPrep from './ModalCreateTestPrep';
import ModalTestPrepFeedback from './ModalTestPrepFeedback';
import ModalAssignMyContent from './ModalAssignMyContent';
import ModalSelectAssignmentType from './ModalSelectAssignmentType';
import ModalSelectDateRange from './ModalSelectDateRange';
import ModalGoogleClassroomImport from './ModalGoogleClassroomImport';
import ModalGoogleClassroomSync from './ModalGoogleClassroomSync';
import ModalGoogleAccountInfo from './ModalGoogleAccountInfo';
import ModalUpdateAssignment from './ModalUpdateAssignment';
import ModalAssignmentTutorial from './ModalAssignmentTutorial';
import ModalAssignmentCreated from './ModalAssignmentCreated';
import ModalAssignmentOptions from './ModalAssignmentOptions';
import ModalChooseCancellation from './ModalChooseCancellation';
import ModalPauseMembership from './ModalPauseMembership';
import ModalSelectMonthYear from './ModalSelectMonthYear';
import ModalParentMembership from './ModalParentMembership';
import ModalInviteAdmin from './ModalInviteAdmin';
import ModalViewAdmin from './ModalAdminView';
import ModalPremiumUpgradeRedirect from './ModalPremiumUpgradeRedirect';
import TermsAndConditionModal from './terms-and-conditions/TermsAndCondition.modal';
import ModalCancelSubscription from './subscription-cancellation/ModalCancelSubscription';
import AssignAssignmentModal from '../../../features/assignment/common/modal/assign-assignment/AssignAssignment.modal';
import QuestionBankModal from '../../../features/assignment/common/modal/question-bank/QuestionBank.modal';
import AddAssignmentModal from '../../../features/assignment/common/modal/add-assignment/AddAssignment.modal';
import DeleteAssignmentModal from '../../../features/assignment/common/modal/delete-assignment/DeleteAssignment.modal';
import ModalPlacementTestReassignment from './ModalPlacementTestReassignment';
import CreateTestPrepModal from '../../../features/assignment/common/modal/create-test-prep/CreateTestPrep.modal';
import CreatePreMadeAssignmentModal from '../../../features/assignment/common/modal/create-pre-made-assignment/CreatePreMadeAssignment.modal';
import ModalAssignRewards from './assign-rewards/ModalAssignRewards';
import { ModalArchiveClasses } from './archive-classes/ModalArchiveClasses';
import ModalClassMissionReport from './ModalClassMissionReport';
import ModalNonPremiumClassMission from './ModalNonPremiumClassMission';
import ModalRemovePlayer from './ModalRemovePlayer';
import ModalPremiumPlusLegacyInfo from './ModalPremiumPlusLegacyInfo';
import ModalTeacherAssignMemberships from './ModalTeacherAssignMemberships';
import ModalMembershipPurchaseSuccess from './ModalMembershipPurchaseSuccess';
import ModalUpgradeSuccess from './ModalUpgradeSuccess';
import ModalUpgradePreview from './ModalUpgradePreview';
import ModalAssignmentPlayerBreakdownUpgrade from './ModalAssignmentPlayerBreakdownUpgrade';
import ModalSkillsPlayerBreakdownUpgrade from './ModalSkillsPlayerBreakdownUpgrade';
import ModalNewLEOptIn from './ModalNewLEOptIn';

const ModalSwitch: React.FC<InjectedProps> = (props: any) => {
  switch (props.type) {
    case MODAL_DEFAULT:
      return <ModalDefault {...props} />;
    case MODAL_SELECT_CLASS:
      return <ModalSelectClass {...props} />;
    case MODAL_ADD_STUDENT:
      return <ModalAddStudent {...props} />;
    case MODAL_ADD_CLASS:
      return <ModalAddClass {...props} />;
    case MODAL_EDIT_STUDENT:
      return <ModalEditStudent {...props} />;
    case MODAL_CREATE_ASSIGNMENT:
      return <ModalCreateAssignment {...props} />;
    case MODAL_ASSIGNMENT_OPTIONS:
      return <ModalAssignmentOptions {...props} />;
    case MODAL_CLASS_MISSION_REPORT:
      return <ModalClassMissionReport {...props} />;
    case MODAL_NONPREMIUM_CLASS_MISSION:
      return <ModalNonPremiumClassMission {...props} />;
    case MODAL_CREATE_TEST_PREP:
      return <ModalCreateTestPrep {...props} />;
    case MODAL_CREATE_PRE_MADE_ASSIGNMENT:
      return <CreatePreMadeAssignmentModal {...props} />;
    case MODAL_UGC_CREATE_TEST_PREP:
      return <CreateTestPrepModal {...props} />;
    case MODAL_QUESTION_BREAKDOWN:
      return <ModalQuestionBreakdown {...props} />;
    case MODAL_SEND_PARENT_INVITE:
      return <ModalSendInviteParent {...props} />;
    case MODAL_EDIT_CLASS:
      return <ModalEditClass {...props} />;
    case MODAL_ADD_ACCOUNT:
      return <ModalAddAccount {...props} />;
    case MODAL_ASSIGN_MEMBERSHIP:
      return <ModalAssignMembership {...props} />;
    case MODAL_CHANGE_PASSWORD:
      return <ModalPasswordChange {...props} />;
    case MODAL_SKILL_REPORT:
      return <ModalSkillReport {...props} />;
    case MODAL_CLEVER:
      return <ModalClever {...props} />;
    case MODAL_SUCCESS:
      return <ModalSuccess {...props} />;
    case MODAL_TRAINING:
      return <ModalTraining {...props} />;
    case MODAL_PASTE_CSV:
      return <ModalPasteCSV {...props} />;
    case MODAL_CHUCK_TASKS:
      return <ModalChuckTasks {...props} />;
    case MODAL_CANCEL_MEMBERSHIP:
      return <ModalCancelMembership {...props} />;
    case MODAL_TEST_PREP_FEEDBACK:
      return <ModalTestPrepFeedback {...props} />;
    case MODAL_ASSIGN_MY_CONTENT:
      return <ModalAssignMyContent {...props} />;
    case MODAL_SELECT_ASSIGNMENT_TYPE:
      return <ModalSelectAssignmentType {...props} />;
    case MODAL_SELECT_DATE_RANGE:
      return <ModalSelectDateRange {...props} />;
    case MODAL_SELECT_MONTH_YEAR:
      return <ModalSelectMonthYear {...props} />;
    case MODAL_GOOGLE_CLASSROOM_IMPORT:
      return <ModalGoogleClassroomImport {...props} />;
    case MODAL_GOOGLE_CLASSROOM_SYNC:
      return <ModalGoogleClassroomSync {...props} />;
    case MODAL_GOOGLE_ACCOUNT_INFO:
      return <ModalGoogleAccountInfo {...props} />;
    case MODAL_ASSIGN_REWARDS:
      return <ModalAssignRewards {...props} />;
    case MODAL_UPDATE_ASSIGNMENT:
      return <ModalUpdateAssignment {...props} />;
    case MODAL_ASSIGNMENT_TUTORIAL:
      return <ModalAssignmentTutorial {...props} />;
    case MODAL_ASSIGNMENT_CREATED:
      return <ModalAssignmentCreated {...props} />;
    case MODAL_CHOOSE_CANCELLATION:
      return <ModalChooseCancellation {...props} />;
    case MODAL_PAUSE_MEMBERSHIP:
      return <ModalPauseMembership {...props} />;
    case MODAL_PARENT_MEMBERSHIP:
      return <ModalParentMembership {...props} />;
    case MODAL_INVITE_ADMIN:
      return <ModalInviteAdmin {...props} />;
    case MODAL_VIEW_ADMINS:
      return <ModalViewAdmin {...props} />;
    case MODAL_TERMS_AND_CONDITIONS:
      return <TermsAndConditionModal {...props} />;
    case MODAL_CANCEL_SUBSCRIPTION:
      return <ModalCancelSubscription {...props} />;
    case MODAL_ASSIGN_USER_GENERATED_CONTENT:
      return <AssignAssignmentModal {...props} />;
    case MODAL_QUESTION_BANK:
      return <QuestionBankModal {...props} />;
    case MODAL_USER_GENERATED_ASSIGNMENT_EDIT:
      return <AddAssignmentModal {...props} />;
    case MODAL_USER_GENERATED_ASSIGNMENT_DELETE_PROMPT:
      return <DeleteAssignmentModal {...props} />;
    case MODAL_ARCHIVE_CLASSES:
      return <ModalArchiveClasses {...props} />;
    case MODAL_PLACEMENT_TEST_REASSIGN:
      return <ModalPlacementTestReassignment {...props} />;
    case MODAL_PREMIUM_UPGRADE_REDIRECT:
      return <ModalPremiumUpgradeRedirect {...props} />;
    case MODAL_REMOVE_PLAYER:
      return <ModalRemovePlayer {...props} />;
    case MODAL_PREMIUM_PLUS_LEGACY_INFO:
      return <ModalPremiumPlusLegacyInfo {...props} />;
    case MODAL_TEACHER_ASSIGN_MEMBERSHIPS:
      return <ModalTeacherAssignMemberships {...props} />;
    case MODAL_MEMBERSHIP_PURCHASE_SUCCESS:
      return <ModalMembershipPurchaseSuccess {...props} />;
    case MODAL_UPGRADE_SUCCESS:
      return <ModalUpgradeSuccess {...props} />;
    case MODAL_UPGRADE_PREVIEW:
      return <ModalUpgradePreview {...props} />;
    case MODAL_PLAYER_BREAKDOWN_UPGRADE:
      return <ModalAssignmentPlayerBreakdownUpgrade {...props} />;
    case MODAL_SKILLS_PLAYER_BREAKDOWN_UPGRADE:
      return <ModalSkillsPlayerBreakdownUpgrade {...props} />;
    case MODAL_NEW_LE_OPT_IN:
      return <ModalNewLEOptIn {...props} />;
    default:
      return <ModalDefault {...props} />;
  }
};
const mapStateToProps = (state: any) => {
  return {
    modal: state
  };
};

export default connect(mapStateToProps)(
  connectModal({ name: 'modal' })(ModalSwitch)
);
