import React, { CSSProperties } from 'react';
import { Badge, BadgeProps, styled } from '@material-ui/core';

interface OverrideProps {
  override?: CSSProperties;
}
export const StyledBadge = (props: BadgeProps | OverrideProps) => {
  let overrideProps: React.CSSProperties | undefined;
  if ('override' in props) {
    overrideProps = props.override;
  }
  const CustomStyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      ...overrideProps
    }
  }));
  const badgeProps = {
    override: undefined,
    ...props
  };
  return <CustomStyledBadge {...(badgeProps as BadgeProps)} />;
};
