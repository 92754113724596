import React, { useState } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { PriceCard } from './PriceCard';
import Button from '../../../../components/button/Button';
import useQueryParams from '../../../../hooks/UseQueryParams';
import { Cart } from './Cart';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../../types/fetch/fetch-types';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { show } from 'redux-modal';
import uuid from 'uuid';
import { GoogleTrackingCheckout } from './google-tracking';
import { useHistory } from 'react-router-dom';
import { checkoutRoutes } from '../../../../constants/routes';
import { useAppSelector } from '../../../../redux/hooks';
import { UserType } from '../../../../enums/UserType';
import FriendsIcon from '../../../../assets/checkout-page/friends.png';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles({
  spacing: {
    marginTop: '0.5rem'
  },
  card: {
    width: '100%',
    marginTop: '1rem',
    borderRadius: '40px',
    border: '1px solid #FFF',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const prices: any = {
  monthly: {
    recurrence: 'Monthly',
    price: 8.99,
    priceMonthly: 8.99,
    billingPeriod: 'Billed monthly'
  },
  yearly: {
    recurrence: 'Yearly',
    price: 59.99,
    priceMonthly: 4.99,
    discountPercentage: 44,
    showDiscount: true,
    billingPeriod: '$59.99 Billed Annually'
  }
};
enum Recurrence {
  Monthly = 'monthly',
  Yearly = 'yearly'
}
export const MembershipCheckoutView = () => {
  const classes = useStyles();
  const query = useQueryParams();
  const userData = useAppSelector(state => state.userData);
  const { playerIds, isOneBuyLink, source, screen } = query;
  const history = useHistory();
  const isLoggedInUser =
    history.location.pathname === checkoutRoutes.membershipPurchase &&
    userData &&
    userData._id &&
    userData.userType === UserType.Parent;
  const isBuyLink = Boolean(isOneBuyLink);
  const showLogin = !(
    history.location.pathname === checkoutRoutes.membershipPurchase ||
    (isBuyLink && playerIds)
  );
  const dispatch = useDispatch();
  const { nonMembers } = useAppSelector(state => state.members);

  const [players, setPlayers] = useState<{
    isLoading: boolean;
    usernames: { id: string; username: string; name: string }[];
  }>({
    isLoading: playerIds,
    usernames:
      (isLoggedInUser &&
        nonMembers &&
        nonMembers.map(
          (player: {
            firstname: any;
            lastname: any;
            _id: any;
            username: any;
          }) => ({
            name: `${player.firstname} ${player.lastname}`,
            username: player.username,
            id: player._id
          })
        )) ||
      []
  });
  const getQuantityInRange = (quantity: number) => {
    if (quantity > 30) {
      return 30;
    } else if (quantity < 0) {
      return 0;
    }
  };
  const [quantity, setQuantity] = React.useState(
    isLoggedInUser && players && players.usernames.length > 0
      ? 0
      : getQuantityInRange(query.quantity) || 1
  );
  const [error, setError] = useState({
    isOneBuyLinkError: false
  });

  React.useEffect(() => {
    GoogleTrackingCheckout.trackProductImpression();
    setSubtotal(getPrice(queryParamRecurrence));
    if (playerIds) {
      dispatch(
        fetchApi({
          url: `stripe/checkout/players`,
          method: APIMethods.POST,
          data: {
            playerIds: [playerIds]
          }
        })
      )
        .then((response: AxiosResponse) => {
          const { usernames } = response.data;
          setPlayers({
            isLoading: false,
            usernames
          });
          setSelectedPlayers(
            usernames.map(
              (player: { username: string; name: string; _id: string }) => ({
                checked: false,
                username: player.username,
                name: player.name,
                id: player._id
              })
            )
          );
          if (query.isOneBuyLink) {
            setError({
              isOneBuyLinkError: false
            });
          }
        })
        .catch((err: AxiosError) => {
          setPlayers({
            ...players,
            isLoading: false
          });
          let message = 'could not retrieve user(s)';
          if (query.isOneBuyLink) {
            message = 'unable to retrieve user associated with buy link';
            setError({
              isOneBuyLinkError: true
            });
          }
          dispatch(
            show('snackbar', {
              variant: 'error',
              message
            })
          );
        });
    }
  }, []);
  const queryParamRecurrence = query.recurrence || 'yearly';
  const [currentActivePlan, setCurrentActivePlan] = React.useState(
    queryParamRecurrence
  );
  const getPrice = (planRecurrence: Recurrence, newQuantity?: number) => {
    const currentQuantity =
      newQuantity !== undefined
        ? newQuantity
        : quantity ||
          (isLoggedInUser && players && players.usernames.length > 0 ? 0 : 1);
    const currentPrice = prices[planRecurrence]
      ? prices[planRecurrence].price * currentQuantity
      : 0;
    return currentPrice;
  };
  const [subtotal, setSubtotal] = React.useState(0);
  const [selectedPlayers, setSelectedPlayers] = React.useState<
    { id: string; checked: boolean; name: string; username: string }[]
  >(
    (isLoggedInUser &&
      nonMembers &&
      nonMembers.map(
        (player: {
          firstname: any;
          lastname: any;
          _id: any;
          username: any;
        }) => ({
          checked: false,
          username: player.username,
          name: `${player.firstname} ${player.lastname}`,
          id: player._id
        })
      )) ||
      []
  );
  const theme = useTheme();
  const sizeAboveMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [isCartValid, setIsCartValid] = React.useState(true);

  const dashboardLink = process.env.REACT_APP_DASHBOARD_URL;
  const checkoutLink = process.env.REACT_APP_CHECKOUT_LINK;
  const handleRedirectToCheckout = () => {
    if (quantity === 0 || String(quantity) == '') {
      dispatch(
        show('snackbar', {
          variant: 'error',
          message: 'membership quantity cannot be 0'
        })
      );
      return;
    }
    if (
      isLoggedInUser &&
      selectedPlayers.length === 0 &&
      players.usernames.length > 0
    ) {
      dispatch(
        show('snackbar', {
          variant: 'error',
          message: 'please select atleast one user'
        })
      );
      return;
    }
    let queryParams = '';
    if (
      query.isOneBuyLink &&
      query.playerIds &&
      !isLoggedInUser &&
      !error.isOneBuyLinkError
    ) {
      queryParams += `&isOneBuyLink=${query.isOneBuyLink}`;
      const ids = query.playerIds.split(',');
      ids.forEach((id: string) => {
        queryParams += `&players=${id}`;
      });
    }
    if (selectedPlayers) {
      selectedPlayers.forEach(player => {
        if (player.checked) {
          queryParams += `&players=${player.id}`;
        }
      });
    }
    if (isLoggedInUser) {
      queryParams += `&email=${encodeURIComponent(userData.email)}`;
    }
    if (query.source) {
      queryParams += `&source=${source}`;
    }
    if (query.screen) {
      queryParams += `&screen=${screen}`;
    }
    const distinctId = isLoggedInUser ? userData._id : uuid.v4();
    const link = `${checkoutLink}?recurrence=${currentActivePlan}&quantity=${quantity}${queryParams}&mixpanelId=${distinctId}`;
    GoogleTrackingCheckout.trackCheckout(currentActivePlan, subtotal, quantity);
    window.location.href = link;
  };

  const handlePlayerSelection = (id: string) => {
    const newSelectedPlayers = [...selectedPlayers];
    newSelectedPlayers.forEach(player => {
      if (player.id === id) {
        player.checked = !player.checked;
      }
    });
    setSelectedPlayers(newSelectedPlayers);
    const checkedPlayers = newSelectedPlayers.filter(player => player.checked);
    handleQuantityChange(checkedPlayers.length);
  };

  const handlePlanSelection = (selectedPlan: Recurrence) => {
    setCurrentActivePlan(selectedPlan);
    const price = getPrice(selectedPlan);
    setSubtotal(price);
    GoogleTrackingCheckout.trackCart(selectedPlan, price, quantity);
  };

  const handleQuantityChange = (selectedQuantity: number) => {
    if (selectedQuantity >= 0 && selectedQuantity <= 30) {
      setQuantity(selectedQuantity);
      const price = getPrice(currentActivePlan, selectedQuantity);
      GoogleTrackingCheckout.trackCart(
        currentActivePlan,
        price,
        selectedQuantity
      );
      setSubtotal(price);
    }
  };

  return (
    <Grid
      container
      style={{
        padding: sizeAboveMobileScreen ? '1rem' : 0,
        background: '#027F5F',
        minHeight: '100%',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <Grid
        item
        container
        className={classes.spacing}
        justifyContent="center"
        style={{
          marginBottom: 40,
          minHeight: '100%',
          width: sizeAboveMobileScreen ? '80%' : '95%',
          maxWidth: '996px',
          flexWrap: 'unset',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box style={{ textAlign: 'center' }}>
          <Typography
            variant={'h3'}
            style={{
              fontSize: sizeAboveMobileScreen ? '60px' : '40px',
              fontFamily: "'Archivo', sans-serif",
              fontStyle: 'italic',
              textTransform: 'uppercase',
              fontWeight: '900',
              color: '#FFBB31',
              margin: 0,
              padding: 0
            }}
          >
            Select
            <Typography
              variant={'h3'}
              component={'span'}
              style={{
                fontSize: sizeAboveMobileScreen ? '60px' : '40px',
                fontFamily: "'Archivo', sans-serif",
                fontWeight: '900',
                color: '#FDFEFE',
                margin: 0,
                padding: 0
              }}
            >
              &nbsp;a Membership Plan
            </Typography>
          </Typography>
          {showLogin && (
            <Typography
              variant="body1"
              style={{ color: '#FFF', fontWeight: 700 }}
            >
              if you have a Parent or Teacher account,{' '}
              <Link
                href={`${dashboardLink}login?redirectTo=membership`}
                style={{
                  color: '#FFF',
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                CLICK HERE
              </Link>
            </Typography>
          )}
        </Box>
        <Box className={classes.card} p={{ xs: 1, sm: 4, md: 6 }}>
          <Grid
            container
            justifyContent="center"
            style={{ marginBottom: '20px' }}
          >
            <Grid
              item
              container
              style={{ alignItems: 'flex-end' }}
              justifyContent="center"
            >
              <Grid item>
                <PriceCard
                  onClick={() => handlePlanSelection(Recurrence.Monthly)}
                  active={currentActivePlan === Recurrence.Monthly}
                  plan={prices.monthly}
                />
              </Grid>
              <Grid item>
                <PriceCard
                  onClick={() => handlePlanSelection(Recurrence.Yearly)}
                  active={currentActivePlan === Recurrence.Yearly}
                  plan={prices.yearly}
                />
              </Grid>
            </Grid>
            <Box
              maxWidth={{
                xs: '100%',
                sm: '100%',
                md: '698px',
                lg: '698px'
              }}
              width="100%"
              style={{
                padding: '1rem 1.5rem'
              }}
            >
              {players.isLoading && (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress />
                </Box>
              )}
              <Grid
                container
                style={{
                  background: '#FFF',
                  borderRadius: '26px',
                  padding: sizeAboveMobileScreen ? '1.2rem 2rem' : '1rem',
                  marginBottom: 4
                }}
              >
                {currentActivePlan && !players.isLoading && (
                  <Cart
                    quantity={quantity}
                    handlePlayerSelection={handlePlayerSelection}
                    isLoggedInUser={isLoggedInUser}
                    players={selectedPlayers}
                    isOneBuyLink={isBuyLink}
                    planRecurrence={currentActivePlan}
                    handleQuantity={selectedQuantity => {
                      handleQuantityChange(selectedQuantity);
                    }}
                  />
                )}
                <Divider
                  style={{
                    marginBottom: '20px',
                    background: '#027F5D',
                    width: '100%'
                  }}
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: '#027F5D',
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Subtotal:
                  </Typography>
                  &nbsp;
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <AttachMoneyIcon
                      fontSize="large"
                      style={{ color: '#027F5D' }}
                    />
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 800,
                        fontSize: sizeAboveMobileScreen ? '36px' : '24px',
                        color: '#027F5D'
                      }}
                    >
                      {subtotal > 0 ? subtotal.toFixed(2) : subtotal}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              position: 'absolute',
              bottom: '-1.4rem'
            }}
          >
            <Button
              onClick={handleRedirectToCheckout}
              disabled={!isCartValid}
              variant="contained"
              size="large"
              style={{
                background: '#FFBB31',
                color: '#FFF',
                boxShadow: '-6px 14px 6px 0px #0B5B47',
                fontWeight: 900,
                width: sizeAboveMobileScreen ? '360px' : '200px',
                fontSize: '18px'
              }}
            >
              Continue
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Box
        style={{
          position: 'absolute',
          bottom: '0',
          right: '0'
        }}
        display={{ xs: 'none', sm: 'none', lg: 'block', xl: 'block' }}
      >
        <img
          src={FriendsIcon}
          alt="friends"
          style={{
            width: '27vw',
            maxWidth: '514px',
            objectFit: 'cover'
          }}
        />
      </Box>
    </Grid>
  );
};
