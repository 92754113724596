import React, { useEffect } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core';
import WidgetItem from './WidgetItem';

const useStyles = makeStyles(() => ({
  widgetContainer: {
    display: 'grid',
    margin: '20px 0px',
    width: '100%'
  }
}));

interface WidgetContainerProps {
  spacing: number;
  itemsPerRow: number;
  items: Array<React.ReactNode>;
  onChange?: (items: Array<React.ReactNode>) => void;
}

const WidgetContainer = SortableContainer((props: WidgetContainerProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.onChange) {
      const items = props.items.map((item: any, index: number) => ({
        index,
        name: item.props.name
      }));
      props.onChange(items);
    }
  }, [props.items]);

  return (
    <div
      className={classes.widgetContainer}
      style={{
        gridGap: props.spacing,
        gridTemplateColumns: `repeat(${props.itemsPerRow}, 1fr)`
      }}
    >
      {props.items.map((children: React.ReactNode, index: number) => (
        <WidgetItem key={`item-${index}`} index={index}>
          {children}
        </WidgetItem>
      ))}
    </div>
  );
});

export default WidgetContainer;
