import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import borders from '../../styles/borders';
import colors from '../../styles/colors';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  bubble: {
    alignItems: 'center',
    background: colors.primary,
    borderRadius: borders.borderRadius,
    boxShadow: '0 0 8px 1px #dcdcdc',
    color: '#fff',
    display: 'flex',
    fontSize: 16,
    height: 130,
    justifyContent: 'center',
    left: 70,
    opacity: 0,
    padding: 10,
    position: 'relative',
    top: -160,
    transition: '.3s',
    visibility: 'hidden',
    width: 260,

    '&::after': {
      border: '20px solid transparent',
      borderBottom: 0,
      borderLeft: 0,
      borderTopColor: colors.primary,
      bottom: 0,
      content: '""',
      height: 0,
      left: '15%',
      position: 'absolute',
      marginBottom: -20,
      marginLeft: -10,
      width: 0
    }
  },
  active: {
    opacity: 1,
    visibility: 'visible'
  },
  closeButton: {
    background: '#bd5b7b',
    borderRadius: '50%',
    padding: 3,
    position: 'absolute',
    right: -10,
    top: -10,
    zIndex: 999
  }
}));

interface ChuckBubbleProps {
  text: string;
  show: Boolean;
  hideCloseButton?: Boolean;
  onClick?: () => void;
  onCloseClick?: () => void;
}

const ChuckBubble: React.FC<ChuckBubbleProps> = (props: ChuckBubbleProps) => {
  const classes = useStyles({});

  if (window.screen.height < 1100 || isMobile) {
    return null;
  }

  return (
    <div className={clsx(classes.bubble, props.show && classes.active)}>
      <Clear className={classes.closeButton} onClick={props.onCloseClick} />
      <Typography onClick={props.onClick}>{props.text}</Typography>
    </div>
  );
};

export default ChuckBubble;
