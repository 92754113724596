import {
  CLASS_ADD_CLASS,
  CLASS_ARCHIVE_CLASS,
  CLASS_FAILED,
  CLASS_GET_ALL,
  CLASS_GET_ALL_FAILED,
  CLASS_GET_ONE,
  CLASS_GET_ONE_FAILED,
  CLASS_PLAYER_ADDED,
  CLASS_UPDATE_CLASS,
  CLASS_UPDATE_CLASS_FAILED,
  CLASS_EDIT_CLASS,
  CLASS_UPDATE_PLAYER,
  CLASS_PLAYER_REMOVED,
  CLASS_GET_ONE_IN_PROGRESS,
  CLASS_GET_ONE_SUCCESS
} from '../types/class/class-action-types';
import ClassState from '../../types/state/class-type';

const initialState: ClassState = {
  classes: [],
  currentClass: {
    activePlayers: [],
    classReport: {
      skills: {
        total: [],
        struggling: [],
        strugglingPlayers: []
      },
      classQuestions: [],
      classReadingLevel: []
    },
    roster: [],
    code: '',
    isLoading: true
  }
};

export const classReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case CLASS_GET_ALL:
      return {
        ...state,
        classes: payload
      };

    case CLASS_GET_ONE_IN_PROGRESS:
      return {
        ...state,
        currentClass: {
          ...state.currentClass,
          isLoading: true
        }
      };

    // Set current class
    case CLASS_GET_ONE:
      return {
        ...state,
        currentClass: { ...payload }
      };
    case CLASS_GET_ONE_FAILED:
      return state;

    // Change class name in the classes state
    case CLASS_EDIT_CLASS:
      return {
        ...state,
        classes: state.classes.map((item: any) => {
          if (item._id === payload.id) {
            item.name = payload.name;
          }
          return item;
        })
      };
    case CLASS_GET_ALL_FAILED:
      return state;

    // Add new class to the classes array
    case CLASS_ADD_CLASS:
      return {
        ...state,
        classes: [...state.classes, payload]
      };

    // Set true or false for archived property
    case CLASS_ARCHIVE_CLASS:
      return {
        ...state,
        classes: state.classes.map((item: any) => {
          if (item._id === payload.id) {
            item.archived = payload.archived;
          }
          return item;
        })
      };

    // updates the class' registration step if needed
    case CLASS_PLAYER_ADDED:
      if (state.currentClass) {
        let registrationStep = state.currentClass.registrationStep;
        if (registrationStep === 1) {
          registrationStep = 2;
        }
        return {
          ...state,
          currentClass: {
            ...state.currentClass,
            registrationStep
          }
        };
      } else {
        return state;
      }

    case CLASS_UPDATE_PLAYER:
      //@ts-ignore
      const roster = state.currentClass.roster.map((student: any) => {
        if (student._id === payload._id) {
          student = {
            ...student,
            ...payload
          };
        }
        return student;
      });
      return {
        ...state,
        currentClass: {
          ...state.currentClass,
          roster
        }
      };

    case CLASS_PLAYER_REMOVED:
      return {
        ...state,
        currentClass: {
          ...state.currentClass,
          //@ts-ignore
          roster: state.currentClass.roster.filter((student: any) => {
            return student._id !== payload._id;
          })
        }
      };

    case CLASS_UPDATE_CLASS:
      return {
        classes: state.classes,
        currentClass: {
          ...state.currentClass,
          ...payload
        }
      };

    case CLASS_UPDATE_CLASS_FAILED:
      return state;
    case CLASS_FAILED:
      return state;

    case CLASS_GET_ONE_SUCCESS:
      const currentClass = state.currentClass;
      delete currentClass.isLoading;
      return {
        ...state,
        currentClass
      };
    default:
      return state;
  }
};
