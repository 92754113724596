import React, { useState, useEffect, createRef } from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import View from '../../components/view/View';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { useDispatch } from 'react-redux';
import borders from '../../styles/borders';
import Button from '../../components/button/Button';
import { show } from 'redux-modal';
import { MODAL_DEFAULT } from '../../constants/modals';
import useQueryParams from '../../hooks/UseQueryParams';
import ReactHtmlParser from 'react-html-parser';
import { useAppSelector } from '../../redux/hooks';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
    marginBottom: 20
  },
  listItem: {
    background: '#fcfcfc',
    border: '1px solid #dcdcdc',
    borderRadius: borders.borderRadius,
    marginBottom: 20,
    padding: 20
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 500,
    margin: '10px 0'
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: 20
  }
}));

interface DescriptionItem {
  id: number;
  name: string;
  testPrepType: string;
  description: string;
  summary: string;
  mostAppropriateFor: string;
  openEndedQuestionPrompt: string;
  estimatedCompletionTime: string;
  skillsDescription: string;
  standards: string;
  standardsName: string;
}

const TestPrepDecriptionsView: React.FC = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [descriptionData, setDescriptionData] = useState<DescriptionItem[]>([]);
  const elementRefs = descriptionData.reduce((acc: any, value: any) => {
    acc[value.id] = createRef();
    return acc;
  }, {});
  const currentClass = useAppSelector(state => state.class.currentClass);

  const scrollToElement = (ref: any) => {
    if (ref.current) {
      setTimeout(() => {
        $('html body div').animate({ scrollTop: ref.current.offsetTop });
      }, 500);
    }
  };

  useEffect(() => {
    if (queryParams.descriptionId && descriptionData.length > 0) {
      if (elementRefs[queryParams.descriptionId]) {
        scrollToElement(elementRefs[queryParams.descriptionId]);
      }
    }
  }, [elementRefs]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        fetchApi({
          spinnerMessage: 'Loading Data',
          hideSpinnerAfter: true,
          method: APIMethods.GET,
          endpoint: APIEndpoints.EDUCATION,
          url: `v2.1/assignment/test-prep-levels?classCode=${currentClass.code}`
        })
      );
      setDescriptionData(response.data);
    };
    fetchData();
  }, []);

  return (
    <View title="Test Prep Descriptions">
      {descriptionData.map(item => {
        return (
          <Paper className={classes.paper} ref={elementRefs[item.id]}>
            <Typography variant="h2" className={classes.title}>
              {item.name}
            </Typography>
            <Typography variant="h3" className={classes.subtitle}>
              <b>Summary</b>
            </Typography>
            <Typography>{item.summary}</Typography>
            <Typography variant="h3" className={classes.subtitle}>
              <b>Most Appropriate For</b>
            </Typography>
            <Typography>{item.mostAppropriateFor}</Typography>
            <Typography variant="h3" className={classes.subtitle}>
              <b>Estimated Completion Time</b>
            </Typography>
            <Typography>{item.estimatedCompletionTime}</Typography>
            <Typography variant="h3" className={classes.subtitle}>
              <b>Skills Covered</b>
            </Typography>
            <Typography>{item.skillsDescription}</Typography>
            {item.standards ? (
              <Button
                green
                marginTop={20}
                onClick={() =>
                  dispatch(
                    show('modal', {
                      type: MODAL_DEFAULT,
                      title: `${item.standardsName} Alignment`,
                      children: () => (
                        <>
                          <Typography>
                            {ReactHtmlParser(item.standards)}
                          </Typography>
                        </>
                      )
                    })
                  )
                }
              >
                {item.standardsName} Alignment
              </Button>
            ) : null}
          </Paper>
        );
      })}
    </View>
  );
};

export default TestPrepDecriptionsView;
