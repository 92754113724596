import {
  AssignmentData,
  AssignmentQuestion,
  AssignmentQuestionType,
  DispatchProps
} from './assignment-edit.types';
import {
  validatePassage,
  validateQuestion
} from '../../../util/AssignmentValidation';
import uuid from 'uuid';
import {
  AssignmentEditAnswerActionTypes,
  AssignmentEditQuestionActionTypes,
  AssignmentEditQuestionBankActionTypes
} from './question/assignment-question-action.types';

/**
 * @description reducer for data property for assignment editor
 * @param state
 * @param action
 */
export const assignmentDataReducer = (
  state: AssignmentData[] = [],
  action: any
): any => {
  const { type } = action;
  switch (type) {
    case AssignmentEditQuestionActionTypes.SET_EDIT_MODE: {
      return setEditMode(state, action);
    }
    case AssignmentEditQuestionActionTypes.ADD_QUESTION: {
      return addQuestion(state, action);
    }
    case AssignmentEditQuestionActionTypes.UPDATE_QUESTION: {
      return updateQuestion(state, action);
    }
    case AssignmentEditQuestionActionTypes.DELETE_QUESTION: {
      return deleteQuestion(state, action);
    }
    case AssignmentEditQuestionActionTypes.UPDATE_QUESTION_TEXT: {
      return updateQuestionText(state, action);
    }
    case AssignmentEditQuestionActionTypes.UPDATE_QUESTION_FORMAT: {
      return updateQuestionFormat(state, action);
    }
    case AssignmentEditQuestionActionTypes.UPDATE_QUESTION_TYPE: {
      return updateQuestionType(state, action);
    }
    case AssignmentEditAnswerActionTypes.UPDATE_ANSWER:
      return updateAnswer(state, action);
    case AssignmentEditAnswerActionTypes.DELETE_ANSWER: {
      return deleteAnswer(state, action);
    }
    case AssignmentEditQuestionBankActionTypes.ADD_QUESTION_BANK_QUESTIONS: {
      return addQuestions(state, action);
    }
    case AssignmentEditAnswerActionTypes.ADD_ANSWER: {
      return addAnswer(state, action);
    }
    default: {
      return state;
    }
  }
};
type QuestionFunction = (data: AssignmentQuestion) => AssignmentQuestion;

/**
 * @description add premade question
 */
function addQuestions(assignment: AssignmentData, action: DispatchProps) {
  return [...assignment, ...action.payload];
}

function handleDataLoop(
  state: AssignmentData,
  questionFunction: QuestionFunction,
  id: string,
  rootId: string
) {
  let newData = [...state];
  const useRootId = rootId != null;
  for (let i = 0; i < newData.length; i++) {
    let data = newData[i];
    if (useRootId) {
      if (data.id === rootId) {
        for (
          let passageQuestionIndex = 0;
          passageQuestionIndex < data.questions.length;
          passageQuestionIndex++
        ) {
          let questionData: AssignmentQuestion =
            data.questions[passageQuestionIndex];
          if (questionData.id === id) {
            newData[i].questions[passageQuestionIndex] = questionFunction(
              questionData
            );
            newData[i].questions[passageQuestionIndex].isEdited = true;
            newData[i].questions[passageQuestionIndex].error = validateQuestion(
              newData[i].questions[passageQuestionIndex]
            );
            newData[i].isEdited = true;
          }
        }
      }
    } else {
      if (data.id === id) {
        if (data.type === AssignmentQuestionType.Independent) {
          let questionData: AssignmentQuestion = data;
          newData[i] = questionFunction(questionData);
          newData[i].error = validateQuestion(newData[i]);
          newData[i].isEdited = true;
        } else if (data.type === AssignmentQuestionType.Passage) {
          let questionData: AssignmentQuestion = data;
          newData[i] = questionFunction(questionData);
          newData[i].error = validatePassage(newData[i]);
          newData[i].isEdited = true;
        }
      }
    }
  }
  return [...newData];
}

export function generateNewPassage(id: string) {
  return {
    passage: '',
    passageTitle: '',
    genreId: null,
    isUserGenerated: true,
    isEditMode: true,
    id: id || uuid.v4(),
    error: {
      isError: false,
      errors: new Map()
    },
    questions: [generateNewPassageQuestion()]
  };
}

export function generateNewPassageQuestion() {
  return {
    id: uuid.v4(),
    question: null,
    skillId: -1,
    isUserGenerated: true,
    feedbackIncorrect: null,
    isEditMode: true,
    error: {
      isError: false,
      errors: new Map()
    },
    answers: [generateNewAnswer(), generateNewAnswer()],
    format: 1
  };
}

export function generateNewQuestion(id: string) {
  return {
    id: id || uuid.v4(),
    question: null,
    isUserGenerated: true,
    isEditMode: true,
    error: {
      isError: false,
      errors: new Map()
    },
    feedbackIncorrect: null,
    type: AssignmentQuestionType.Independent,
    skillId: -1,
    answers: [generateNewAnswer(), generateNewAnswer()],
    format: 1
  };
}

function generateNewAnswer() {
  return { id: uuid.v4(), text: '', isCorrect: false };
}

function deleteQuestion(state: AssignmentData, action: DispatchProps) {
  const { id, rootId } = action.payload;
  const useRootId = rootId != null;
  let newData = [];
  newData = [...state];
  if (useRootId) {
    /**
     * Remove root passage
     */
    if (!id) {
      newData = state.map((questionData: AssignmentData) => {
        if (
          questionData.id === rootId &&
          questionData.type === AssignmentQuestionType.Passage
        ) {
          return {
            ...questionData,
            isDeleted: true,
            isEdited: true
          };
        }
        return {
          ...questionData
        };
      });
    } else {
      newData.forEach((question, index) => {
        if (question.id === rootId) {
          const questionLength = question.questions.length;
          if (questionLength > 1) {
            question.questions = question.questions
              .filter((questionData: AssignmentQuestion) => {
                /**
                 * If not synced remove from array
                 */
                if (questionData.id === id && !questionData.isSynced) {
                  return false;
                }
                return true;
              })
              .map((questionData: AssignmentQuestion) => {
                if (questionData.id === id) {
                  return {
                    ...questionData,
                    isDeleted: true,
                    isEdited: true
                  };
                }
                return {
                  ...questionData
                };
              });
          }
        }
      });
    }
  } else {
    newData = state.map((questionData: AssignmentData) => {
      if (
        questionData.id === id &&
        questionData.type === AssignmentQuestionType.Independent
      ) {
        return {
          ...questionData,
          isDeleted: true,
          isEdited: true
        };
      }
      return {
        ...questionData
      };
    });
  }
  return [...newData];
}

function setEditMode(state: AssignmentData[], action: any) {
  const { editMode, questionId, rootId } = action.payload;
  let newData = [...state];
  newData.forEach(question => {
    question.isEditMode = false;
  });
  return handleDataLoop(
    newData,
    question => {
      question.isEditMode = editMode;
      return question;
    },
    questionId,
    rootId
  );
}

function updateQuestion(state: AssignmentData, action: DispatchProps) {
  const { payload } = action;
  if ('id' in payload) {
    let foundQuestion = {};
    let foundIndex = -1;
    state.forEach((question: any, index: number) => {
      if (question.id === payload.id || question.id === payload.previousId) {
        foundQuestion = { ...question };
        foundIndex = index;
      }
    });
    const newQuestion = { ...foundQuestion, ...payload };
    const newQuestionsArray = [...state];
    // @ts-ignore
    if (foundQuestion.type !== newQuestion.type) {
      if (newQuestion.type === AssignmentQuestionType.Independent) {
        newQuestionsArray[foundIndex] = generateNewQuestion(newQuestion.type);
      } else {
        newQuestionsArray[foundIndex] = generateNewPassage(newQuestion.type);
      }
    } else {
      newQuestionsArray[foundIndex] = newQuestion;
      if (!newQuestionsArray[foundIndex].questions) {
        newQuestionsArray[foundIndex].error = validateQuestion(newQuestion);
      }
      newQuestionsArray[foundIndex].isEdited = true;
    }

    return [...newQuestionsArray];
  }
}

function addQuestion(state: AssignmentData, action: DispatchProps) {
  const { rootId } = action.payload;
  const useRootId = rootId != null;
  let newQuestion = [...state];
  newQuestion.forEach(question => {
    question.isEditMode = false;
  });
  if (useRootId) {
    for (let question of newQuestion) {
      if (question.id === rootId) {
        question.isEditMode = true;
        question.questions.push(generateNewPassageQuestion());
      }
    }
  } else {
    newQuestion.push(generateEmptyQuestion());
  }
  return [...newQuestion];
}

function generateEmptyQuestion() {
  return { type: null, id: uuid.v4() };
}

function updateQuestionType(state: AssignmentData, action: DispatchProps) {
  const data = [...state];
  const { type, id } = action.payload;
  for (let index = 0; index < data.length; index++) {
    let assignmentData: AssignmentData = data[index];
    if (assignmentData.id === id) {
      if (type === AssignmentQuestionType.Independent) {
        data[index] = generateNewQuestion(id);
      } else if (type == AssignmentQuestionType.Passage) {
        data[index] = generateNewPassage(id);
      }
      data[index].type = type;
    }
  }
  return [...data];
}

function updateQuestionFormat(state: AssignmentData, action: DispatchProps) {
  const { payload } = action;
  const { id, format, rootId } = payload;
  return handleDataLoop(
    state,
    (data: AssignmentQuestion) => {
      data.format = format;
      return data;
    },
    id,
    rootId
  );
}

function updateQuestionText(state: AssignmentData, action: DispatchProps) {
  const { id, data: newData, rootId } = action.payload;
  return handleDataLoop(
    state,
    (data: AssignmentQuestion) => {
      return { ...data, ...newData };
    },
    id,
    rootId
  );
}

function deleteAnswer(state: AssignmentData, action: DispatchProps) {
  const { id, rootId, answerId } = action.payload;
  return handleDataLoop(
    state,
    (data: AssignmentQuestion) => {
      if (data.answers.length > 1) {
        data.answers = data.answers.filter(e => e.id !== answerId);
      }
      return data;
    },
    id,
    rootId
  );
}

function updateAnswer(state: AssignmentData, action: DispatchProps) {
  const { questionId: id, answerId, data: newData, rootId } = action.payload;
  return handleDataLoop(
    state,
    (data: AssignmentQuestion) => {
      for (
        let questionAnswerIndex = 0;
        questionAnswerIndex < data.answers.length;
        questionAnswerIndex++
      ) {
        let answer = data.answers[questionAnswerIndex];
        if (answer.id === answerId) {
          answer = { ...answer, ...newData };
          data.answers[questionAnswerIndex] = answer;
        } else {
          /**
           * If its multiple choice default all answers to false
           */
          if (data.format === 1 && newData.isCorrect !== undefined) {
            data.answers[questionAnswerIndex].isCorrect = false;
          }
        }
      }
      return data;
    },
    id,
    rootId
  );
}

function addAnswer(state: AssignmentData, action: DispatchProps) {
  const { id, rootId } = action.payload;
  return handleDataLoop(
    state,
    (data: AssignmentQuestion) => {
      data.answers.push(generateNewAnswer());
      return data;
    },
    id,
    rootId
  );
}
