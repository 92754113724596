import { ComponentType, CSSProperties } from 'react';

export enum SideBarActions {
  Back,
  Forward,
  Logout,
  MyAccount,
  Support
}

export interface SidebarItems {
  title: string;
  icon: any;
  featureName?: string;
  beta?: boolean;
  to: string | SideBarActions;
  params?: boolean;
  generateLink?: (id: string) => void; // @todo change this prop for something easier to implement
  disabled?: boolean;
  show?: boolean;
  badge?: {
    active: boolean;
    anchorOrigin: {
      horizontal: string;
      vertical: string;
    };
    color?: string;
    badgeOverride: CSSProperties;
    component: any;
    text: string | ComponentType;
  };
  iconStyle?: Object;
}

export interface SidebarParams {
  classCode?: string;
}
