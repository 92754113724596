import React, { useState } from 'react';
import { Collapse, Grid, Paper, Theme, Typography } from '@material-ui/core';
import Button from '../button/Button';
import {
  MODAL_PLAYER_BREAKDOWN_UPGRADE,
  MODAL_QUESTION_BREAKDOWN
} from '../../constants/modals';
import {
  trackViewAssignmentPlayerBreakdown,
  trackViewStudentBreakdown
} from '../../redux/actions/mixpanel-actions';
import clsx from 'clsx';
import { show } from 'redux-modal';
import { makeStyles } from '@material-ui/styles';
import colors from '../../styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import DropDownArrow from '../dropdown-arrow/DropDownArrow';
import QuestionBreakDownPieChart from '../chart/QuestionBreakDownPieChart';
import { QuestionFormat } from '../../enums/QuestionFormat';
import { ArrowRightAlt } from '@material-ui/icons';
import { hide as hideModal, show as showModal } from 'redux-modal';
import StarsIcon from '@material-ui/icons/Stars';
import store, { RootState } from '../../redux/store';

const fontSize = 42;
const fontWeight = 800;
const useStyles = makeStyles((theme: Theme) => ({
  countUp: {
    fontSize,
    fontWeight
  },
  countUpIncorrect: {
    color: colors.error,
    fontSize,
    fontWeight
  },
  countUpCorrect: {
    color: colors.success,
    fontSize,
    fontWeight
  },
  reportBox: {
    padding: '10px 20px',
    width: '100%',
    textAlign: 'right'
  },
  assignmentDetails: {
    padding: '10px 20px',
    width: '100%',
    textAlign: 'left',
    marginTop: '1.5rem'
  },
  divider: {
    margin: '10px 0'
  },
  progressTitle: {
    background: colors.primary,
    borderRadius: 0,
    color: colors.white,
    padding: 15
  },
  button: {
    position: 'absolute',
    right: 30,
    top: 20
  },
  questionBreakdown: {
    padding: 20,
    marginBottom: 20,
    position: 'relative',
    width: '100%'
  },
  passageBreakdown: {
    padding: 20,
    marginBottom: 10,
    position: 'relative',
    width: '100%'
  },
  paragraph: {
    fontSize: '1.2rem'
  },
  dividerBorder: {
    borderLeft: '1px solid lightgrey',
    textAlign: 'center',
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid lightgrey',
      borderLeft: 'none'
    }
  },
  orderSquare: {
    border: '2px solid black',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    margin: '3px 8px',
    padding: '1px 5px',
    borderRadius: '3px',
    fontSize: '10px'
  },
  orderRound: {
    marginRight: '10px',
    borderRadius: '50%',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    border: `1px solid ${colors.assignment.grey}`
  },
  questionText: {
    '& img': {
      maxWidth: '100%'
    }
  },
  classroomIcon: {
    marginRight: 5,
    width: 24
  },
  answerFormat: {
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    textAlign: 'left'
  }
}));

interface QuestionBreakDownListProps {
  readonly report: any;
  assignmentType: any;
}

const QuestionBreakDownList: React.FC<QuestionBreakDownListProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openedPassages, setOpenedPassages] = useState<string[]>([]);
  const { report, assignmentType } = props;
  const userData = useSelector((state: any) => state.userData);
  const { featureFlag } = store.getState() as RootState;

  const passageQuestionsMap = new Map();
  let formattedQuestions: any = [];
  let formattedQuestionsForTestPrep: any = [];
  // to display passages and group the questions with the passages, the passage question are put into a map
  // Then the first question with the passage is marked so that the passage id displayed with the rest of the questions
  for (let i = 0; i < report.questions.length; i++) {
    let question = report.questions[i];
    if (question.passageTitle) {
      if (passageQuestionsMap.has(question.passageTitle)) {
        const passageQuestions = passageQuestionsMap.get(question.passageTitle);
        passageQuestions.push(question);
        passageQuestionsMap.set(question.passageTitle, passageQuestions);
      } else {
        passageQuestionsMap.set(question.passageTitle, [question]);
      }
    } else {
      if (assignmentType === 'Test prep') {
        formattedQuestionsForTestPrep.push(question);
      } else {
        formattedQuestions.push(question);
      }
    }
  }
  let index = 1;

  passageQuestionsMap.forEach(value => {
    value[0].displayPassage = true;
    value[0].passageNumber = index++;
    value[0].containedQuestions = value.length;
    value.forEach((question: any, index: number) => {
      question.questionNumber = index + 1;
    });
    formattedQuestions.push(...value);
  });

  if (assignmentType === 'Test prep') {
    formattedQuestions = [
      ...formattedQuestions,
      ...formattedQuestionsForTestPrep
    ];
  }
  const isOpen = (passageTitle: string): boolean => {
    return openedPassages.includes(passageTitle);
  };
  const setPassageOpen = (passageTitle: string) => {
    const alreadySet = openedPassages.includes(passageTitle);
    if (alreadySet) {
      const newOpenedPassages = openedPassages.filter(
        passage => passage !== passageTitle
      );
      setOpenedPassages(newOpenedPassages);
    } else {
      const newOpenedPassages = [...openedPassages];
      newOpenedPassages.push(passageTitle);
      setOpenedPassages(newOpenedPassages);
    }
  };
  return formattedQuestions.map((item: any) => {
    const correct = item.answers.filter((item: any) => item.isCorrect);
    const data: number[] = [
      item.answers.length - correct.length,
      correct.length,
      report.users.length - item.answers.length
    ];
    const labels: string[] = [
      'Incorrect Answers',
      'Correct Answers',
      'Not Answered'
    ];
    const backgroundColor: string[] = ['#D65745', '#65C97A', '#C4C4C4'];
    const chartData = {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          hoverBackgroundColor: backgroundColor
        }
      ]
    };

    const isPassageOpen = isOpen(item.passageTitle);
    const passageColor = '#4EADF8';
    const isCollapsibleOpen = isOpen(item.passageTitle);

    const playerBreakdownButton = () => {
      if (featureFlag.premiumReporting && userData.premium.isPremium) {
        return (
          <Button
            blue
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
            onClick={() => {
              handlePlayerBreakdown();
            }}
          >
            View Player Breakdown
          </Button>
        );
      } else if (featureFlag.premiumReporting && !userData.premium.isPremium) {
        return (
          <>
            <Button
              blue
              style={{
                marginTop: '2rem',
                marginBottom: '1rem'
              }}
              onClick={() => {
                dispatch(trackViewAssignmentPlayerBreakdown());
                handlePlayerBreakdown();
              }}
            >
              <StarsIcon
                fontSize={'large'}
                style={{
                  position: 'relative',
                  right: '5px',
                  width: '30px'
                }}
              />
              View Player Breakdown
            </Button>
          </>
        );
      } else if (!featureFlag.premiumReporting) {
        return (
          <Button
            blue
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
            onClick={() => {
              handlePlayerBreakdown();
            }}
          >
            View Player Breakdown
          </Button>
        );
      }
    };

    const handlePlayerBreakdown = () => {
      if (featureFlag.premiumReporting && userData.premium.isPremium) {
        dispatch(
          show('modal', {
            type: MODAL_QUESTION_BREAKDOWN,
            origin: 'Assignment Report',
            question: item,
            users: report.users
          })
        );
        dispatch(trackViewStudentBreakdown());
      } else if (featureFlag.premiumReporting && !userData.premium.isPremium) {
        const sendProps: any = {
          type: MODAL_PLAYER_BREAKDOWN_UPGRADE
        };
        dispatch(showModal('modal', sendProps));
      } else if (!featureFlag.premiumReporting) {
        dispatch(
          show('modal', {
            type: MODAL_QUESTION_BREAKDOWN,
            origin: 'Assignment Report',
            question: item,
            users: report.users
          })
        );
        dispatch(trackViewStudentBreakdown());
      }
    };
    return (
      <Grid container spacing={4}>
        {item.displayPassage && (
          <Grid
            item
            xs={12}
            style={{ cursor: 'pointer' }}
            onClick={() => setPassageOpen(item.passageTitle)}
          >
            <Paper className={classes.passageBreakdown}>
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  color: passageColor,
                  top: 2,
                  right: 2
                }}
              >
                <p style={{ color: passageColor }}>
                  {isPassageOpen ? 'Hide Passage' : 'View Passage'}
                </p>
                <DropDownArrow
                  style={{ color: passageColor }}
                  open={isPassageOpen}
                />
              </div>

              <h1>
                Passage {item.passageNumber} - {item.passageTitle}
              </h1>
              <p>
                Contained questions{' '}
                {item.containedQuestions === 1
                  ? 'Q1'
                  : `Q1 - Q${item.containedQuestions}`}
              </p>
              <Collapse in={isCollapsibleOpen} timeout="auto" unmountOnExit>
                <Grid item xs={12} style={{ marginTop: '.5rem' }}>
                  {item.imgUrl && (
                    <p>
                      <img
                        style={{
                          maxWidth: '30rem',
                          maxHeight: '30rem',
                          width: '100%'
                        }}
                        src={item.imgUrl}
                      />
                    </p>
                  )}
                  <span
                    className={classes.paragraph}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  ></span>
                </Grid>
              </Collapse>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.questionBreakdown}>
            {item.questionNumber && <b>{`Q${item.questionNumber}`}</b>}
            <Grid container>
              <Grid item xs={12} md={12} lg={7}>
                <Grid container>
                  <Grid item xs={12} md={7} lg={6}>
                    <QuestionBreakDownPieChart data={chartData} />
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={5}
                    lg={6}
                    style={{ position: 'relative', display: 'flex' }}
                  >
                    <Grid item xs={12}>
                      {item.skillName ? (
                        <Typography component="p" className={classes.paragraph}>
                          <strong>Skill:</strong> {item.skillName}
                        </Typography>
                      ) : null}
                      {item.passageTitle ? (
                        <Typography component="p" className={classes.paragraph}>
                          <strong>Passage Title:</strong> {item.passageTitle}
                        </Typography>
                      ) : null}
                      {item.genreName ? (
                        <Typography component="p" className={classes.paragraph}>
                          <strong>Passage Genre:</strong> {item.genreName}
                        </Typography>
                      ) : null}
                      <Typography component="p" className={classes.paragraph}>
                        <strong>Question Type:</strong> {item.questionType}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', alignItems: 'end' }}
                    >
                      {playerBreakdownButton()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={5}
                className={classes.dividerBorder}
              >
                <Typography
                  component="p"
                  className={clsx(classes.paragraph, classes.questionText)}
                  style={{ textAlign: 'left' }}
                  dangerouslySetInnerHTML={{ __html: item.question }}
                ></Typography>
                {item.potentialAnswers ? (
                  <>
                    <ul style={{ margin: '1rem', listStyle: 'none' }}>
                      {item.questionType === QuestionFormat.Pair &&
                        JSON.parse(item.correctAnswers).map(
                          (pair: any, index: number) => {
                            return (
                              <div className={classes.answerFormat}>
                                &nbsp;{pair[0]} &nbsp;{' '}
                                <ArrowRightAlt fontSize="large" /> &nbsp;{' '}
                                {pair[1]}
                              </div>
                            );
                          }
                        )}
                      {item.questionType === QuestionFormat.DragAndDrop &&
                        JSON.parse(item.correctAnswers).map(
                          (answer: any, index: number) => {
                            return (
                              <div className={classes.answerFormat}>
                                <span className={classes.orderSquare}>
                                  {index + 1}
                                </span>
                                &nbsp; {answer}
                              </div>
                            );
                          }
                        )}
                      {item.questionType === QuestionFormat.Order &&
                        JSON.parse(item.correctAnswers).map(
                          (answer: any, index: number) => {
                            return (
                              <div className={classes.answerFormat}>
                                <span className={classes.orderRound}>
                                  {index + 1}
                                </span>
                                &nbsp; {answer}
                              </div>
                            );
                          }
                        )}
                      {item.questionType !== QuestionFormat.DragAndDrop &&
                        item.questionType !== QuestionFormat.Pair &&
                        item.questionType !== QuestionFormat.Order &&
                        JSON.parse(item.potentialAnswers).map(
                          (answer: any, index: number) => {
                            const isCorrect = JSON.parse(
                              item.correctAnswers
                            ).includes(answer);
                            return (
                              <div className={classes.answerFormat}>
                                <Checkbox checked={isCorrect} color="primary" />
                                &nbsp;{answer}
                              </div>
                            );
                          }
                        )}
                    </ul>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  });
};

export default QuestionBreakDownList;
