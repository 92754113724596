import React from 'react';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const Forbidden: React.FC = () => {
  const theme = useTheme();
  const useStyles = makeStyles({
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    icon: {
      fontSize: '10vw',
      marginBottom: '20px',
      color: theme.palette.grey[100]
    }
  });
  const classes = useStyles();

  return (
    <Box m={2}>
      <Card>
        <CardContent className={classes.cardContent}>
          <WarningRoundedIcon className={classes.icon} />
          <Typography align="center">
            You don't have access to this page
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Forbidden;
