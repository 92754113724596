export enum AssignmentEditActionTypes {
  RETRIEVED_ASSIGNMENT = `[User Generated Content]: ASSIGNMENT EDIT RETRIEVED ASSIGNMENT`,
  UPDATE = `[User Generated Content]: ASSIGNMENT EDIT UPDATE`,
  GET_SKILLS = '[User Generated Content]: ASSIGNMENT GET SKILLS',
  GET_GENRES = '[User Generated Content]: ASSIGNMENT GET GENRES',
  GET_QUESTION_TYPE = '[User Generated Content]: ASSIGNMENT GET QUESTION TYPE',
  RESET = '[User Generated Content]: ASSIGNMENT EDIT RESET',
  SAVING = '[User Generated Content]: ASSIGNMENT SAVING',
  SAVED = '[User Generated Content]: ASSIGNMENT SAVED',
  UPDATE_EDIT_DATA = '[User Generated Content]: ASSIGNMENT UPDATE EDIT DATA',
  IS_EDITED = '[User Generated Content]: ASSIGNMENT IS EDITED',
  AUTO_SAVE_STARTED = '[User Generated Content]: ASSIGNMENT AUTOSAVE STARTED',
  AUTO_SAVE_ENDED = '[User Generated Content]: ASSIGNMENT AUTOSAVE STARTED'
}
