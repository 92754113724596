import React, { useState, useEffect } from 'react';
import View from '../../components/view/View';
import BackgroundImg from '../../assets/select-location-bg.png';
import { userTypes, userTypesDashboardRoute } from '../../constants/users';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { updateUserMarketingInfo } from '../../redux/actions/user-actions';
import { push } from 'connected-react-router';
import { commonRoutes } from '../../constants/routes';
import { referralOptions } from "../../constants/users";
import { userRouteObjGetter } from '../../utils/user-route-obj-getter';
import ReferrerSelection from '../../components/referrer-selection/ReferrerSelection'
import {
  makeStyles, 
  Paper, 
  Typography,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Button from "../../components/button/Button";
import { getUtmProperties } from '../../utils/utm';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '40px 20px 20px',
    position: 'relative',
    textAlign: 'center',
    width: 720
  },
  paperMobile: {
    padding: '40px 20px 20px',
    position: 'relative',
    textAlign: 'center',
    flex: 'auto',
    maxWidth: 720
  },
  title: {
    marginBottom: 10
  },
  topImage: {
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: -110,
    width: '80%'
  }
}));

const UpdateReferral: React.FC = () => {
  const userData = useSelector((state: any) => state.userData);
  const classes = useStyles();
  const dispatch = useDispatch();
  const[isDisabled , setIsDisabled] = useState(true);
  const [referrer, setReferrer] = useState({
    type: '',
    text: '',
  });

  const handleValueChange = (e: any) => {
    const utmProperties = getUtmProperties();
    dispatch(
      updateUserMarketingInfo({
        _id: userData._id,
        referrer: [{ type: e.referrerType, text: e.referrerText }],
        utm: utmProperties
      }),
      );
    const newState = {
      type: e.referrerType,
      text: e.referrerText,
    };
    setReferrer(newState);
  }

  const handleContinue = () => {
    dispatch(push(userRouteObjGetter(userData.userType).homeScreen));  
  }

  useEffect(() => {
    if (referrer.type === ''){
      setIsDisabled(true);
    } else if ((referrer.type === "Email" || 
    referrer.type === "Social Media" ||
    referrer.type === "Conference" || 
    referrer.type === "Other") && 
    (referrer.text === "")){
      setIsDisabled(true);
    } else{
      setIsDisabled(false);
    }
  }, [referrer]);


  return (
    <View flex style={{ height: '100vh' }}>
      <Paper className={isMobile ? classes.paperMobile : classes.paper}>
        {isMobile ? null : (
          <img
            src={BackgroundImg}
            alt="Squiggle Monsters"
            className={classes.topImage}
          />
        )}
        <ReferrerSelection
          showError={false}
          handleValueChange={handleValueChange}
        />
        <Button
          green
          loginpage={false}
          onClick={handleContinue}
          disabled= {isDisabled}
          style={{
            marginTop: 20,
            height: 45,
            paddingTop: 10,
          }}
        >
          Continue
        </Button>
      </Paper>
    </View>
  );
};

export default UpdateReferral;
