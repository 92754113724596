export enum QuestionFormat {
  OpenEnded = 'Open-Ended',
  SelectAll = 'Select All',
  DragAndDrop = 'Drag and Drop',
  MultipleChoice = 'Multiple Choice',
  Pair = 'Pair',
  Order = 'Order',
  TrueFalse = 'True/False',
}

export enum QuestionFormatNumber {
  MultipleChoice = 1,
  Highlight = 2,
  DragAndDrop = 3,
  SelectAll = 4,
  OpenEnded = 5,
  Pair = 6,
  Order = 7,
  TrueFalse = 8
}
