import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import ClassMissionsGameView from './ClassMissionsGameView';
import NewClassMissionsGameView from './NewClassMissionsGameView';

const ClassMissionsWrapper: React.FC<any> = (props: any) => {
  return <NewClassMissionsGameView />;
  // const features = useAppSelector(state => state.featureFlag);
  // return features.newRealtimeService ? <NewClassMissionsGameView /> : <ClassMissionsGameView />;
};

export default ClassMissionsWrapper;
