import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TeacherFeatureFlagContext from '../../layouts/teacher/TeacherFeatureFlagContext';
import missionMonday from '../../assets/missions/mission_monday.png';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#009DE0',
    position: 'relative',
    height: 70
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    color: 'white',
    marginLeft: 10,
    marginRight: 10,
    fontSize: '1.2rem'
  },
  missionMondayImg: {
    transform: 'rotate(-20deg)'
  },
  imgContainer: { marginTop: -35, marginBottom: -35 }
}));

export const NonMissionMondayBanner: React.FC<any> = () => {
  const styles = useStyles();
  const { flags } = React.useContext(TeacherFeatureFlagContext);
  let nextMissionDate = flags.isMissionMondayPrepRange;
  if (nextMissionDate === null) {
    return null;
  }

  return (
    <Grid container className={styles.container}>
      <Grid item className={styles.content}>
        <div className={styles.imgContainer}>
          {/* <img
            src={missionMonday}
            alt="mission monday"
            className={styles.missionMondayImg}
            height={150}
          /> */}
        </div>
        <span className={styles.text}>
          Get access to Class Missions for FREE on Fun Friday! The next one
          arrives on{' '}
          <strong style={{ fontSize: '1.2rem' }}>{nextMissionDate}</strong>.
        </span>
      </Grid>
    </Grid>
  );
};
