import React from 'react';
import ModalAbstract from '../ModalAbstract';
import { PropsWithChildren } from 'react';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { archiveClass } from '../../../../redux/actions/class-actions';
import { Archive, Close } from '@material-ui/icons';
import MuiTableCell from '@material-ui/core/TableCell';
import { useAppSelector } from '../../../../redux/hooks';

interface ModalArchiveClassesProps {
  show: boolean;
  handleHide: any;
  classes: any[];
}
const TableCell = withStyles({
  root: {
    borderBottom: 'none'
  }
})(MuiTableCell);
const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  }
});
export const ModalArchiveClasses = (
  props: PropsWithChildren<ModalArchiveClassesProps>
) => {
  const classes = useStyles();
  const { show, handleHide } = props;
  const { classes: userClasses } = useAppSelector(state => state.class);
  const unarchivedClasses = userClasses.filter(
    (userClass: any) => !userClass.archived
  );
  const dispatch = useDispatch();

  const handleArchiveClass = (id: string, archive: boolean) => {
    dispatch(archiveClass(id, archive));
  };

  return (
    <ModalAbstract
      show={show}
      handleHide={handleHide}
      hideOnOutsideClick={true}
    >
      <DialogTitle>
        Archive Classes{' '}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleHide}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ overflowY: 'scroll', maxHeight: '500px', minWidth: '400px' }}
      >
        {unarchivedClasses.length === 0 ? (
          <span>No classes to archive</span>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <MuiTableCell>Class Name</MuiTableCell>
                <MuiTableCell>Players</MuiTableCell>
                <MuiTableCell>Code</MuiTableCell>
                <MuiTableCell></MuiTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unarchivedClasses.map((currentClass: any) => {
                return (
                  <TableRow>
                    <TableCell>{currentClass.name}</TableCell>
                    <TableCell>{currentClass.roster.length}</TableCell>
                    <TableCell>{currentClass.code}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          handleArchiveClass(currentClass._id, true)
                        }
                      >
                        <Archive />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </DialogContent>
    </ModalAbstract>
  );
};
