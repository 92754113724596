import React, { useEffect, useState } from 'react';
import {
  Button as MUIButton,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';
import { withRouter } from 'react-router';
import moment from 'moment';
import View from '../../../components/view/View';
import SearchBar from '../../../components/searchbar/SearchBar';
import Button from '../../../components/button/Button';
import Table from '../../../components/table/Table';
import { getAllMembers } from '../../../redux/actions/members-actions';
import {
  MODAL_ASSIGN_MEMBERSHIP,
  MODAL_CANCEL_MEMBERSHIP,
  MODAL_CHOOSE_CANCELLATION
} from '../../../constants/modals';
import {
  Autorenew,
  Cancel,
  ChevronLeft,
  HighlightOff,
  Pause
} from '@material-ui/icons';
import { getMembershipPageUrl } from '../../../redux/api-constants';
import { push } from 'connected-react-router';
import { userTypes } from '../../../constants/users';
import colors from '../../../styles/colors';
import uuid from 'uuid';
import { useHistory } from 'react-router-dom';
import { checkoutRoutes } from '../../../constants/routes';
import { FeatureFlag } from '../../../feature-flag/featureFlag';
import { Features } from '../../../feature-flag/Features';

const useStyles = makeStyles(() => ({
  addMembershipContainer: {
    height: 'calc(100%)',
    top: 0
  },
  membershipActions: {
    marginBottom: '1rem'
  }
}));

const MembershipView: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const members = useSelector((state: any) => state.members);
  const userData = useSelector((state: any) => state.userData);
  const [addMembershipVisible, setAddMembershipVisible] = useState(false);
  const [tableState, setTableState] = useState<any>([]);
  const classes = useStyles();
  const { classCode } = props.match.params;
  let noMembers: boolean = false;
  useEffect(() => {
    dispatch(getAllMembers());
  }, []);
  useEffect(() => {
    setTableState(members.members);
  }, [members.members]);

  let data: Array<any> = [];
  if (!members.members) {
    noMembers = true;
  } else {
    noMembers = false;
    data = [...members.members];
  }
  // if class code exists filter by class code
  if (classCode) {
    data = data.filter(e => {
      return e.joinedWith.value === classCode;
    });
  }

  if (data.length === 0) {
    noMembers = true;
  } else {
    noMembers = false;
  }

  const assignMembership = () => {
    dispatch(
      show('modal', {
        type: MODAL_ASSIGN_MEMBERSHIP,
        unassignedPlayers: members.nonMembers,
        unassignedMembership: members.unassigned.map((item: any) => ({
          uuid: uuid.v4(),
          subMeta: item.subMeta
        }))
      })
    );
  };

  const redirectToBuyMembershipPage = () => {
    return history.push(checkoutRoutes.membershipPurchase);
  };

  const renderAddMembership = () => {
    return (
      <Drawer
        anchor="bottom"
        open={addMembershipVisible}
        onClose={() => setAddMembershipVisible(false)}
        classes={{ paper: classes.addMembershipContainer }}
      >
        <Grid container style={{ position: 'absolute', marginTop: 20 }}>
          <Grid item md={4}>
            <Button
              onClick={() => {
                dispatch(getAllMembers());
                setAddMembershipVisible(false);
              }}
            >
              <ChevronLeft />
              Back to Dashboard
            </Button>
          </Grid>
        </Grid>
        <iframe
          title="Membership"
          src={getMembershipPageUrl()}
          style={{ height: '100%' }}
        ></iframe>
      </Drawer>
    );
  };

  const renderViewAllMembership = () => {
    if (classCode) {
      return (
        <Button
          onClick={() => dispatch(push('/dashboard/teacher/memberships/all'))}
          style={{ marginRight: 10 }}
        >
          View All Memberships
        </Button>
      );
    }
    return null;
  };

  let title = 'All Memberships';
  if (classCode) {
    title = `Memberships for class ${classCode}`;
  }
  return (
    <>
      <View
        title={`${title} (${
          members.unassigned && Array.isArray(members.unassigned)
            ? members.unassigned.length
            : 'Not'
        } available)`}
      >
        {renderAddMembership()}
        <Grid
          container
          className={classes.membershipActions}
          spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} md={6} lg={4}>
            <SearchBar
              placeholder="Search by Player Name"
              originalState={data}
              searchKey="firstname"
              onChange={(searchState: any) => setTableState(searchState)}
            />
          </Grid>
          <Grid item container justify="flex-end" lg={8} md={6} xs={12}>
            {renderViewAllMembership()}
            {userData.userType === 'Parent' && (
              <Button
                style={{ marginRight: '10px', marginLeft: '10px' }}
                primary
                onClick={redirectToBuyMembershipPage}
              >
                Buy Membership
              </Button>
            )}
            {members.unassigned &&
              Array.isArray(members.unassigned) &&
              members.unassigned.length > 0 && (
                <Grid item>
                  <Button primary onClick={assignMembership}>
                    Assign Membership
                  </Button>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item lg={12}>
          {noMembers ? (
            <Typography
              component="p"
              style={{
                marginTop: '20px'
              }}
            >
              There are no current Members{' '}
              {classCode
                ? `found for this class, cick "View All Memberships" to see all your memberships or`
                : 'found, '}
              if you have a membership available, click "Assign Membership" to
              assign it to a player, if not, you can purchase memberships by
              selecting "Buy Membership".
            </Typography>
          ) : (
            <Table
              columns={[
                {
                  Header: 'Name',
                  accessor: (row: any) => {
                    return `${row.firstname} ${row.lastname}`;
                  }
                },
                {
                  Header: 'Username',
                  accessor: 'username'
                },
                {
                  id: 'class-code',
                  Header: 'Class Code',
                  accessor: 'joinedWith.value',
                  show: userData.userType !== userTypes.parent
                },
                {
                  Header: 'Next Billing Date',
                  accessor: (row: any) => {
                    return moment(row.subMeta.expiryDate).format('ll');
                  },
                  Cell: (cell: any) => {
                    const { subMeta } = cell.row.original;

                    let color: any;
                    let date = subMeta.expiryDate;
                    let tooltip = 'Cancelled';
                    let icon = <HighlightOff />;
                    if (subMeta.paused && subMeta.paused.status) {
                      tooltip = 'Paused';
                      date = subMeta.paused.resumesAt;
                      color = colors.orange;
                      icon = <Pause style={{ color }} />;
                    }
                    date = moment(date).format('ll');

                    if (
                      subMeta.cancelled ||
                      (subMeta.paused && subMeta.paused.status)
                    ) {
                      return (
                        <Tooltip title={tooltip}>
                          <span>
                            <MUIButton
                              style={{ color }}
                              disabled
                              startIcon={icon}
                            >
                              {date}
                            </MUIButton>
                          </span>
                        </Tooltip>
                      );
                    }

                    return <MUIButton>{date}</MUIButton>;
                  }
                },
                {
                  Header: 'Actions',
                  Cell: (cell: any) => {
                    const membership = cell.row.original;
                    const { subMeta } = membership;

                    const idVerified =
                      subMeta.createdBy &&
                      (subMeta.createdBy.email === userData.email ||
                        subMeta.createdBy.userId === userData._id);
                    return (
                      <>
                        <Tooltip title="Cancel Membership">
                          <span>
                            <IconButton
                              onClick={() => {
                                let type = MODAL_CHOOSE_CANCELLATION;
                                const currentCalendarYear = moment().format(
                                  'YYYY'
                                );
                                let lastTimePaused;
                                if (subMeta.paused.pausedAt) {
                                  lastTimePaused = moment(
                                    subMeta.paused.pausedAt
                                  ).format('YYYY');
                                }
                                if (
                                  (subMeta.paused && subMeta.paused.status) ||
                                  [
                                    'Dreamscape_Class',
                                    'Dreamscape_Yearly'
                                  ].includes(subMeta.type) ||
                                  currentCalendarYear === lastTimePaused
                                ) {
                                  type = MODAL_CANCEL_MEMBERSHIP;
                                }
                                dispatch(
                                  show('modal', {
                                    type,
                                    membershipData: membership
                                  })
                                );
                              }}
                              disabled={
                                subMeta.cancelled ||
                                !idVerified ||
                                subMeta.type === 'Dreamscape_Custom'
                              }
                            >
                              <Cancel />
                            </IconButton>
                          </span>
                        </Tooltip>
                        {!(subMeta.paused && subMeta.paused.status) && (
                          <Tooltip
                            onClick={() => {
                              dispatch(
                                show('modal', {
                                  type: MODAL_ASSIGN_MEMBERSHIP,
                                  unassignedPlayers: members.nonMembers,
                                  currentPlayer: membership,
                                  isSwitch: true,
                                  classCode
                                })
                              );
                            }}
                            title="Switch Membership"
                          >
                            <span>
                              <IconButton>
                                <Autorenew />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </>
                    );
                  }
                }
              ]}
              data={tableState}
            />
          )}
        </Grid>
      </View>
    </>
  );
};

export default withRouter(MembershipView);
