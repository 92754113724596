import React, { useState, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid
} from '@material-ui/core';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import colors from '../../../styles/colors';

interface ModalSelectDateRangeProps {
  show: any;
  handleHide: any;
  description?: string;
  onConfirm: (startDate: Date, endDate: Date) => void;
}

const ModalSelectDateRange: React.FC<ModalSelectDateRangeProps> = (
  props: ModalSelectDateRangeProps
) => {
  const { show, handleHide, onConfirm, description } = props;
  const [state, setState] = useState({
    error: '',
    startDate: moment()
      .subtract(7, 'days')
      .toDate(),
    endDate: moment().toDate()
  });

  useEffect(() => {
    let error = '';
    if (state.startDate > state.endDate) {
      error = `Start Date can't be greater than End Date`;
    }
    setState({
      ...state,
      error
    });
  }, [state.startDate, state.endDate]);

  const handleConfirm = () => {
    onConfirm(state.startDate, state.endDate);
    handleHide();
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Select Date Range</DialogTitle>
      <DialogContent style={{ overflowY: 'unset' }}>
        <DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={4}>
              {description && (
                <Grid item xs={12} spacing={4}>
                  <Typography component="p" style={{ marginTop: 10 }}>
                    {description}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6} spacing={4}>
                <DatePicker
                  margin="normal"
                  label="Start Date"
                  format="MM/dd/yyyy"
                  value={state.startDate}
                  maxDate={new Date()}
                  allowKeyboardControl={false}
                  onChange={(startDate: any) =>
                    setState({ ...state, startDate })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} spacing={4}>
                <DatePicker
                  margin="normal"
                  label="End Date"
                  format="MM/dd/yyyy"
                  value={state.endDate}
                  maxDate={new Date()}
                  allowKeyboardControl={false}
                  onChange={(endDate: any) => setState({ ...state, endDate })}
                />
              </Grid>
              <Typography
                component="span"
                style={{ color: colors.error, fontSize: 13, padding: '0 16px' }}
              >
                {state.error}
              </Typography>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button primary disabled={state.error !== ''} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalSelectDateRange;
