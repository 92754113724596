import {
  LazyLoadedModules,
  lazyLoadModule
} from './../../features/lazy-loading';
import { districtAdminRoutes } from '../../constants/routes';
import { Features } from '../../feature-flag/Features';
import AppBarLayout from '../../layouts/AppBarLayout';

const lazyLoadComponent = lazyLoadModule(LazyLoadedModules.DistrictAdminModule);

const DistrictAdminLayout = lazyLoadComponent('DistrictAdminLayout');
const MembershipView = lazyLoadComponent('MembershipView');
const Home = lazyLoadComponent('Home');
const ClassView = lazyLoadComponent('ClassView');
const CreateAccounts = lazyLoadComponent('CreateAccounts');
const Training = lazyLoadComponent('Training');
const StudentsView = lazyLoadComponent('StudentsView');
const PlannerReportView = lazyLoadComponent('PlannerReportView');
const PlannerView = lazyLoadComponent('PlannerView');
const ReportsView = lazyLoadComponent('ReportsView');
const ReadingLevelReportView = lazyLoadComponent('ReadingLevelReportView');
const AssignmentReportView = lazyLoadComponent('AssignmentReportView');
const SchoolListView = lazyLoadComponent('SchoolListView');
const TeacherListView = lazyLoadComponent('TeacherListView');
const Account = lazyLoadComponent('Account');
const SkillComprehensionReportView = lazyLoadComponent(
  'SkillComprehensionReportView'
);
const PlacementTestReportView = lazyLoadComponent('PlacementTestReportView');
const ReportToolLeaderboard = lazyLoadComponent('ReportToolLeaderboard');
const LELeaderboardReportView = lazyLoadComponent('LELeaderboardReportView');
const ReportToolClassActivity = lazyLoadComponent('ReportToolClassActivity');
const ResourceView = lazyLoadComponent('ResourceView');
const ResourceInfoView = lazyLoadComponent('ResourceInfoView');
const RealTimeView = lazyLoadComponent('RealTimeWrapper');
const NewLEClassActivityReportView = lazyLoadComponent(
  'NewLEClassActivityReportView'
);

const resourceContent = (layout: any) => {
  return [
    {
      path: districtAdminRoutes.resourceHub,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceView
    },
    {
      path: districtAdminRoutes.resourceHubInfo,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceInfoView
    }
  ];
};

export default [
  {
    path: '/dashboard/district-admin',
    layout: DistrictAdminLayout,
    component: SchoolListView
  },
  {
    path: '/dashboard/district-admin/memberships/all/:id',
    layout: DistrictAdminLayout,
    component: MembershipView
  },
  {
    path: districtAdminRoutes.teachersList,
    layout: DistrictAdminLayout,
    component: TeacherListView
  },
  {
    path: '/dashboard/district-admin/teacher/:teacherId',
    layout: DistrictAdminLayout,
    component: Home
  },
  {
    path: '/dashboard/district-admin/class/:classCode',
    layout: DistrictAdminLayout,
    component: ClassView
  },
  {
    path: '/dashboard/district-admin/create-accounts',
    layout: DistrictAdminLayout,
    component: CreateAccounts
  },
  {
    path: '/dashboard/district-admin/training',
    layout: DistrictAdminLayout,
    component: Training
  },
  {
    path: '/dashboard/district-admin/students/:classCode',
    layout: DistrictAdminLayout,
    component: StudentsView
  },
  {
    path: districtAdminRoutes.teacherPlannerReport,
    layout: DistrictAdminLayout,
    component: PlannerReportView
  },
  {
    path: districtAdminRoutes.teacherPlanner,
    layout: DistrictAdminLayout,
    component: PlannerView
  },
  {
    path: '/dashboard/district-admin/reports',
    layout: DistrictAdminLayout,
    component: ReportsView
  },
  {
    path: '/dashboard/district-admin/memberships/:classCode',
    layout: DistrictAdminLayout,
    component: MembershipView
  },
  {
    path: '/dashboard/district-admin/reports/reading-level/:classCode',
    layout: DistrictAdminLayout,
    component: ReadingLevelReportView
  },
  {
    path: districtAdminRoutes.teacherPlacementTestReport,
    layout: DistrictAdminLayout,
    component: PlacementTestReportView
  },
  {
    path: '/dashboard/district-admin/reports/assignment-tool/:classCode',
    layout: DistrictAdminLayout,
    component: PlannerReportView
  },
  {
    path: '/dashboard/district-admin/reports/skill-comprehension/:classCode',
    layout: DistrictAdminLayout,
    component: SkillComprehensionReportView
  },
  {
    path: '/dashboard/district-admin/assignment-tool/report/:assignmentId/:classCode',
    layout: DistrictAdminLayout,
    component: AssignmentReportView
  },
  {
    path: districtAdminRoutes.teacherRealTimeDashboard,
    layout: AppBarLayout,
    component: RealTimeView
  },
  {
    path: districtAdminRoutes.leaderboardReport,
    layout: DistrictAdminLayout,
    component: ReportToolLeaderboard
  },
  {
    path: districtAdminRoutes.newLEleaderboardReport,
    layout: DistrictAdminLayout,
    component: LELeaderboardReportView
  },
  {
    path: districtAdminRoutes.classActivityReport,
    layout: DistrictAdminLayout,
    component: ReportToolClassActivity
  },
  {
    path: districtAdminRoutes.newLEClassActivityReport,
    layout: DistrictAdminLayout,
    component: NewLEClassActivityReportView
  },
  {
    path: districtAdminRoutes.myAccount,
    layout: DistrictAdminLayout,
    component: Account
  },
  ...resourceContent(DistrictAdminLayout)
];
