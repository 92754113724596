import React from 'react';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar/AppBar';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath, NavLink } from 'react-router-dom';
import NavItems from './NavItems';
import { userLogout } from '../../redux/actions/user-actions';
import DSLogo from '../../assets/shoelace-logo.png';
import colors from '../../styles/colors';
import { menuTypes } from '../../redux/types/menu/menu-action-types';
import ClassSelectionDropdown from '../class-selection-dropdown/ClassSelectionDropdown';
import { userRouteGetter } from '../../utils/user-route-getter';
import { isMobile } from 'react-device-detect';
import { commonRoutes, teacherRoutes } from '../../constants/routes';
import { Help, Forum, SportsEsports } from '@material-ui/icons';
import { push } from 'connected-react-router';
import {
  trackSupportClick,
  trackHowToPlayClick
} from '../../redux/actions/mixpanel-actions';
import { borderRadius } from '../../themes/default';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { userTypes } from '../../constants/users';
import { ClassMissionService } from '../../features/class-missions/class-mission.service';
import { useAppSelector } from '../../redux/hooks';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderTop: '5px solid #333'
  },
  toolbar: {
    paddingRight: 24,
    height: '100%'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerMobile: {
    left: 0,
    // position: 'absolute',
    transition: '0.3s'
  },
  drawerMobileClose: {
    left: -130
  },
  drawerMobileDocked: {
    position: 'absolute',
    '&:after': {
      background: 'rgba(0,0,0,0.4)',
      content: '',
      height: '100%',
      position: 'absolute',
      right: '-100%',
      top: 0,
      width: '100%',
      zIndex: 9
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: 'inherit'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  drawerOverlay: {
    background: 'rgba(0,0,0,0.4)',
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 2
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  navBarButton: {
    borderRadius,
    color: '#333'
  },
  helpMenuIcon: {
    marginRight: 10
  }
}));

interface NavBarProps {
  drawer?: boolean | undefined;
  menuType?: menuTypes;
  logout?: () => void;
  userData?: any;
}

const NavBar = (props: NavBarProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(!isMobile);
  const [
    helpMenuAnchorElement,
    setHelpMenuAnchorElement
  ] = React.useState<null | HTMLElement>(null);
  const userData = useSelector((state: any) => state.userData);
  const oldDashboardAccessConfig = useAppSelector(
    state => state.featureFlag.oldDashboardAccessConfig
  );
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<any>();
  const dispatch = useDispatch();

  const handleToggleDrawer = () => {
    setOpen(!open);
  };
  const handleBackButton = () => {
    if (userData.userType === userTypes.teacher) {
      return history.push(teacherRoutes.homeScreen);
    }
  };

  const renderDrawer = () => {
    if (props.drawer === false) {
      return null;
    }
    let className = '';
    if (isMobile) {
      const body = document.querySelector('body');
      if (!open) {
        body!.classList.remove('menu-docked');
        className = clsx(classes.drawerMobile, classes.drawerMobileClose);
      } else {
        body!.classList.add('menu-docked');
        className = clsx(classes.drawerMobile);
      }
    } else if (!open) {
      className = classes.drawerPaperClose;
    }
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, className),
          root: className,
          docked: isMobile ? classes.drawerMobileDocked : ''
        }}
        style={{ height: '100%' }}
        open={open}
      >
        <List
          style={{ paddingTop: 65, position: isMobile ? 'fixed' : 'relative' }}
        >
          <NavItems menuType={props.menuType} isDrawerOpen={open} />
        </List>
      </Drawer>
    );
  };

  const shouldRenderBackButton = (): boolean => {
    if (oldDashboardAccessConfig.hiddenAppBarItems?.includes('Back')) {
      return false;
    }

    // @ts-ignore
    const path = match.path;
    return (
      userData.userType === userTypes.teacher &&
      !isMobile &&
      [
        teacherRoutes.classStudent,
        teacherRoutes.classDashboard,
        teacherRoutes.classReports,
        teacherRoutes.classReportsReadingLevel,
        teacherRoutes.classReportsSkills,
        teacherRoutes.classReportsAssignmentTool,
        teacherRoutes.membershipsClass,
        teacherRoutes.classPlanner,
        teacherRoutes.classAssignmentReport,
        teacherRoutes.leaderboardReport,
        teacherRoutes.newLEleaderboardReport,
        teacherRoutes.questionsAnsweredReport,
        teacherRoutes.classReportsPlacementTest,
        teacherRoutes.classActivityReport,
        teacherRoutes.classMissionReport,
        teacherRoutes.userGeneratedAssignments,
        teacherRoutes.userGeneratedAssignmentsEditNew,
        teacherRoutes.userGeneratedAssignmentsEdit,
        teacherRoutes.rewardsPage,
        teacherRoutes.resourceHubClass,
        teacherRoutes.classMissions,
        teacherRoutes.newLEClassActivityReport
      ].includes(path)
    );
  };

  const renderDrawerIcon = () => {
    if (props.drawer === false) {
      return null;
    } else if (shouldRenderBackButton()) {
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back button"
          onClick={handleBackButton}
          className={classes.menuButton}
        >
          <ArrowBackIcon />
        </IconButton>
      );
    }
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={handleToggleDrawer}
        className={classes.menuButton}
      >
        {open ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
    );
  };

  const renderMyAccountButton = () => {
    if (oldDashboardAccessConfig.hiddenAppBarItems?.includes('MyAccount')) {
      return null;
    }

    if (
      ![
        commonRoutes.selectLocation,
        commonRoutes.googleClassroom,
        commonRoutes.getStarted
      ].includes(location.pathname) &&
      !isMobile
    ) {
      return (
        <NavLink
          to={`/dashboard/${userRouteGetter(userData.userType)}/my-account`}
          style={{ textDecoration: 'none' }}
        >
          <IconButton className={classes.navBarButton}>
            <Typography noWrap className={classes.title}>
              My Account
            </Typography>
          </IconButton>
        </NavLink>
      );
    }
    return null;
  };

  const renderLogoutButton = () => {
    if (oldDashboardAccessConfig.hiddenAppBarItems?.includes('MyAccount')) {
      return null;
    }

    let logoutAction: any = () => dispatch(userLogout());
    if (matchPath(location.pathname, teacherRoutes.classMissionsGame)) {
      logoutAction = async () => {
        // await pauseClassMission();
        const missionId = match.params.missionId as number;
        if (missionId) {
          await ClassMissionService.pauseClassMission(missionId);
        }
        return dispatch(userLogout());
      };
    } else if ([location.pathname].includes(commonRoutes.googleClassroom)) {
      logoutAction = () => dispatch(push('/login'));
    }
    if (location.pathname === commonRoutes.selectLocation || !isMobile) {
      return (
        <IconButton onClick={logoutAction} className={classes.navBarButton}>
          <Typography noWrap className={classes.title}>
            Logout
          </Typography>
        </IconButton>
      );
    }
    return null;
  };

  const renderHelpButton = () => {
    if (oldDashboardAccessConfig.hiddenAppBarItems?.includes('Help')) {
      return null;
    }

    if (isMobile) return null;
    return (
      <>
        <IconButton
          onClick={(event: any) =>
            setHelpMenuAnchorElement(event.currentTarget)
          }
          className={classes.navBarButton}
        >
          <Help style={{ marginRight: 10 }} />
          <Typography noWrap className={classes.title}>
            Help
          </Typography>
        </IconButton>
        <Menu
          open={Boolean(helpMenuAnchorElement)}
          onClose={() => setHelpMenuAnchorElement(null)}
          anchorEl={helpMenuAnchorElement}
        >
          <MenuItem
            onClick={() => {
              window.open(process.env.REACT_APP_SUPPORT_LINK, '_blank');
              dispatch(trackSupportClick());
            }}
          >
            <Forum className={classes.helpMenuIcon} /> Support
          </MenuItem>
          {location.pathname !== commonRoutes.selectLocation && (
            <MenuItem
              onClick={() => {
                dispatch(push(commonRoutes.getStarted));
                dispatch(trackHowToPlayClick());
              }}
            >
              <SportsEsports className={classes.helpMenuIcon} /> How to Play
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };
  return (
    <div>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, 'dashboard-header')}
        style={{
          backgroundColor: colors.appbar,
          marginBottom: '2rem'
        }}
      >
        <Toolbar className={classes.toolbar}>
          {renderDrawerIcon()}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <a
              href="https://www.playdreamscape.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={DSLogo}
                style={{
                  height: '49px',
                  width: 'auto',
                  marginTop: 10
                }}
                alt="logo"
              />
            </a>

            <ClassSelectionDropdown />
          </Typography>
          {window.location.href.includes('free-trial') ? null : (
            <>
              {![commonRoutes.adminApproved, commonRoutes.adminDenied].includes(
                location.pathname
              ) ? (
                <>
                  {renderHelpButton()}
                  {renderMyAccountButton()}
                  {renderLogoutButton()}
                </>
              ) : null}
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderDrawer()}
      {isMobile && open ? (
        <div
          className={classes.drawerOverlay}
          onClick={() => setOpen(!open)}
        ></div>
      ) : null}
    </div>
  );
};

export default NavBar;
