import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import CheckEmptyIcon from '../../../../assets/checkout-page/check_empty.png';
import CheckMarkIcon from '../../../../assets/checkout-page/check_mark.png';
import PromoIcon from '../../../../assets/checkout-page/promo_image.png';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
interface IPriceCardProps {
  active: boolean;
  onClick(): void;
  plan: {
    recurrence: string;
    price: number;
    discountPercentage?: number;
    showDiscount?: boolean;
    priceMonthly: number;
    billingPeriod: string;
  };
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1rem 1.5rem',
    position: 'relative',
    cursor: 'pointer'
  },
  recurrence: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem'
  },
  price: {
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  billingPeriod: {
    fontSize: '1.2rem'
  },
  small: {
    color: 'lightgray'
  }
}));
export const PriceCard = (
  priceCardProps: PropsWithChildren<IPriceCardProps>
) => {
  const { active, plan, onClick } = priceCardProps;
  const theme = useTheme();
  const sizeAboveMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    recurrence,
    billingPeriod,
    priceMonthly,
    showDiscount,
    discountPercentage
  } = plan;
  const classes = useStyles({
    active
  });
  return (
    <Box
      style={{ marginBottom: '1rem' }}
      maxWidth={{
        xs: '100%'
      }}
    >
      <Box className={classes.root} onClick={onClick}>
        <Grid
          container
          style={{
            display: 'inline-block',
            borderRadius: showDiscount ? '32px' : '26px',
            backgroundColor: showDiscount ? '#FFF' : '#6EAD9A',
            boxShadow: showDiscount
              ? '-9px 8px 12px 0px rgba(0, 0, 0, 0.25)'
              : 'none'
          }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            style={{
              padding: '12px 20px',
              background: showDiscount ? '#FFBB31' : '#FFF',
              borderRadius: '26px 26px 0 0'
            }}
          >
            <Typography
              style={{
                color: showDiscount ? '#FFF' : '#027F5D',
                fontWeight: 800,
                fontSize: showDiscount
                  ? sizeAboveMobileScreen
                    ? '30px'
                    : '18px'
                  : sizeAboveMobileScreen
                  ? '24px'
                  : '16px'
              }}
            >
              {recurrence} Plan
            </Typography>
          </Grid>
          {showDiscount ? (
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                padding: ' 12px 30px'
              }}
            >
              <Typography
                style={{
                  textDecoration: 'line-through',
                  textDecorationColor: '#FF4E83',
                  fontFamily: "'Archivo', sans-serif",
                  fontStyle: 'italic',
                  color: '#C9C9C9',
                  fontSize: '33px',
                  textAlign: 'left',
                  lineHeight: 1.2
                }}
              >
                $ 107.99
              </Typography>
              <Typography component={'div'} style={{ color: '#027F5F' }}>
                <Typography component={'span'}>
                  <AttachMoneyIcon />
                </Typography>
                <Typography
                  component={'span'}
                  style={{
                    fontFamily: "'Archivo', sans-serif",
                    fontStyle: 'italic',
                    fontWeight: 900,
                    fontSize: '62px',
                    lineHeight: 1.2
                  }}
                >
                  {priceMonthly}
                </Typography>
                <Typography
                  variant={'body2'}
                  component={'span'}
                  style={{
                    fontSize: '20px',
                    fontWeight: 900,
                    fontFamily: "'Archivo', sans-serif",
                    fontStyle: 'italic'
                  }}
                >
                  &nbsp;per month
                </Typography>
              </Typography>
              <Typography
                variant={'body1'}
                style={{ color: '#027F5F', fontWeight: 'bold' }}
              >
                {billingPeriod}
              </Typography>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              style={{ textAlign: 'center', padding: ' 9px 20px' }}
            >
              <Typography
                style={{
                  color: '#B7FFED'
                }}
              >
                <Typography component={'span'}>
                  <AttachMoneyIcon fontSize="small" />
                </Typography>
                <Typography
                  component={'span'}
                  style={{
                    fontFamily: "'Archivo', sans-serif",
                    fontStyle: 'italic',
                    fontWeight: 900,
                    fontSize: '48px',
                    lineHeight: 1.2
                  }}
                >
                  {priceMonthly}
                </Typography>
                <Typography
                  variant={'body2'}
                  component={'span'}
                  style={{
                    fontWeight: 900,
                    fontSize: '20px',
                    fontStyle: 'italic'
                  }}
                >
                  &nbsp;per month
                </Typography>
              </Typography>
              <Typography variant={'body1'} style={{ color: '#B7FFED' }}>
                {billingPeriod}
              </Typography>
            </Grid>
          )}
        </Grid>
        {showDiscount ? (
          <Grid
            item
            xs={12}
            style={{
              position: 'absolute',
              top: '-30px',
              right: '-7px'
            }}
          >
            <Box
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img src={PromoIcon} alt="promo" style={{ width: '8rem' }} />
              <Box
                style={{
                  position: 'absolute',
                  top: '25px',
                  transform: 'rotate(16deg)'
                }}
              >
                <Typography
                  variant={'h5'}
                  style={{ color: '#FFF', fontWeight: 'bold' }}
                >
                  SAVE{' '}
                </Typography>
                <Typography
                  variant={'h4'}
                  style={{ color: '#FFF', fontWeight: 'bold' }}
                >
                  {discountPercentage}%
                </Typography>
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Box>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <IconButton onClick={() => onClick()} style={{ position: 'relative' }}>
          <img
            src={CheckEmptyIcon}
            alt=""
            style={{
              width: '1.5rem',
              height: '1.5rem',
              cursor: 'pointer'
            }}
          />
          {active ? (
            <img
              src={CheckMarkIcon}
              alt=""
              style={{
                width: '0.9rem',
                height: '0.9rem',
                cursor: 'pointer',
                position: 'absolute'
              }}
            />
          ) : null}
        </IconButton>
      </Grid>
    </Box>
  );
};
