import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import ReportToolTemplate from './ReportToolTemplate';
import { Statuses, ReportTools } from '../../redux/features/ReportTool';
import { trackViewClassActivityReport } from '../../redux/actions/mixpanel-actions';
import ClassActivityReportToolIcon from '../../assets/icons/report/class-activity-icon.png';
import colors from '../../styles/colors';
import { ArrowUpward, ArrowDownward, Remove } from '@material-ui/icons';
import Table from '../table/Table';
import moment from 'moment';
import SearchBar from '../searchbar/SearchBar';
import { ReportToolQuestionsGraph } from './ReportToolQuestions';
import { useAppSelector } from '../../redux/hooks';

interface ReportToolClassActivityProps {
  isWidget?: boolean;
}

export const ClassActivityReportTable: React.FC<any> = (props: any) => {
  const [searchState, setSearchState] = useState<Array<any>>(props.data);
  if (!props.data || !props.data.length) {
    return null;
  }
  return (
    <Grid item>
      <SearchBar
        originalState={props.data}
        searchKey="fullName"
        placeholder="Search player by name"
        onChange={(result: Array<any>) => setSearchState(result)}
        styles={{ container: { marginBottom: 20 } }}
      />
      <Paper>
        <Table
          columns={[
            {
              Header: `Player Name`,
              accessor: 'fullName'
            },
            {
              Header: 'Questions Answered',
              accessor: 'totalNumberOfAnswers'
            },
            {
              Header: 'Questions Answered Correctly',
              accessor: 'totalNumberOfCorrectAnswers'
            },
            {
              Header: '% Correct',
              accessor: (row: any) => {
                const {
                  totalNumberOfAnswers,
                  totalNumberOfCorrectAnswers
                } = row;

                return `${Math.round(
                  (totalNumberOfCorrectAnswers / (totalNumberOfAnswers || 1)) *
                    100
                )} %`;
              }
            },
            {
              Header: 'Passages Read',
              accessor: 'passageRead'
            },
            {
              Header: 'Time Played',
              accessor: (row: any) => {
                const { timePlayed } = row;

                return `${moment
                  .duration(timePlayed, 'seconds')
                  .hours()}h ${moment
                  .duration(timePlayed, 'seconds')
                  .minutes()}m`;
              }
            }
          ]}
          elevation={0}
          data={searchState}
        />
      </Paper>
    </Grid>
  );
};

const ReportToolClassActivity: React.FC<ReportToolClassActivityProps> = ({
  isWidget
}: ReportToolClassActivityProps) => {
  const { data: activityData, status } = useSelector(
    (state: any) => state.reportTool[ReportTools.ClassActivity]
  );
  const { data: questionsData } = useSelector(
    (state: any) => state.reportTool[ReportTools.Questions]
  );
  const reportToolHasLoaded = status === Statuses.Success;
  const features = useAppSelector(state => state.featureFlag);
  const body =
    reportToolHasLoaded &&
    (isWidget ? (
      <>
        {activityData.map((statistic: any, key: number) => {
          const { type, totalForCurrentWeek, totalForPreviousWeek } = statistic;

          let totalColor: string;

          if (type === 'Passages Read') {
            totalColor = colors.fourth;
          } else {
            totalColor = colors.primary;
          }

          let difference =
            ((totalForCurrentWeek - totalForPreviousWeek) /
              (totalForPreviousWeek || 1)) *
            100;
          let changeType: string;
          let startIcon: any;
          let color: string;

          if (difference > 0) {
            changeType = '+';
            color = colors.third;
            startIcon = <ArrowUpward />;
          } else if (difference < 0) {
            changeType = '-';
            color = colors.secondary;
            startIcon = <ArrowDownward />;
          } else {
            changeType = '';
            color = colors.neutral;
            startIcon = <Remove />;
          }

          if (Math.abs(difference) > 100) {
            difference = 100;
          }

          return (
            <Grid
              container
              item
              justify="space-around"
              direction="row"
              alignItems="center"
              key={`stat-${key}`}
            >
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                xs={6}
              >
                <Grid item>
                  <Typography
                    variant="h2"
                    style={{
                      color: totalColor,
                      fontSize: 68,
                      fontWeight: 800
                    }}
                  >
                    {totalForCurrentWeek}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                    {type}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justify="center" xs={6}>
                <Button
                  disabled
                  startIcon={startIcon}
                  style={{ color }}
                  size="large"
                >
                  <Typography>
                    {`${changeType}${Math.floor(Math.abs(difference))}%`}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </>
    ) : (
      <Grid item style={{ width: '100%' }}>
        <Paper>
          <ReportToolQuestionsGraph data={questionsData} />
        </Paper>

        <div style={{ marginTop: 20 }}>
          <ClassActivityReportTable data={activityData} />
        </div>
      </Grid>
    ));

  return (
    <ReportToolTemplate
      title="Class Activity"
      reportToolState={[ReportTools.ClassActivity, ReportTools.Questions]}
      isWidget={isWidget}
      subTitle={features.newDashboardFlag ? '' : 'Last 7 Days'}
      tracker={trackViewClassActivityReport}
      body={body}
      alwaysRequestData
      source={ClassActivityReportToolIcon}
      helperText={`Question Attempts include two groups of questions: Reading comprehension questions that are attached to reading passages, and word study and grammar questions that are asked independently of reading passages.

			To see the complete breakdown of skills being tested, please review the Skills Report`}
    />
  );
};

export default ReportToolClassActivity;
