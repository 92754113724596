import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { Check } from '@material-ui/icons';
import colors from '../../../styles/colors';
import { userTypes } from '../../../constants/users';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';

interface ModalAssignmentTutorialProps {
  show: () => void;
  handleHide: () => void;
  assignmentId: string;
  assignmentTitle: string;
}

const ModalAssignmentTutorial: React.FC<ModalAssignmentTutorialProps> = (
  props: ModalAssignmentTutorialProps
) => {
  const { show, handleHide } = props;
  const userData = useSelector((state: any) => state.userData);
  const studentType = 'players'
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Assignment created</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Check style={{ color: colors.success, fontSize: 80 }} />
        <Typography style={{ marginBottom: 10 }}>
          It's time to get started. Have your {studentType} login into
          Dreamscape.
        </Typography>

        <Button
          primary
          style={{ marginTop: 20 }}
          onClick={() => {
            handleHide();
            dispatch(push(location.pathname.replace('?showTutorial=true', '')));
          }}
        >
          View the content of the assignment
        </Button>
      </DialogContent>
      <DialogActions></DialogActions>
    </ModalAbstract>
  );
};

export default ModalAssignmentTutorial;
