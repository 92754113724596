import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { show, hide } from 'redux-modal';
import View from '../../components/view/View';
import { MODAL_ADD_STUDENT } from '../../constants/modals';
import { userTypes } from '../../constants/users';
import { getClass } from '../../redux/actions/class-actions';
import ChuckContainer from '../../components/chuck/ChuckContainer';
import { getPlanner } from '../../redux/actions/planner-actions';
import WidgetContainer from '../../components/widget/WidgetContainer';
import RegistrationProgress from '../../components/registration-progress/RegistrationProgress';
import ReportToolAssignmentTool from '../../components/widgets/ReportToolAssignmentTool';
import ReportToolLeaderboard from '../../components/widgets/ReportToolLeaderboard';
import ReportToolQuestions from '../../components/widgets/ReportToolQuestions';
import ReportToolNewLeaderboard from '../../components/widgets/ReportToolNewLELeaderboard';
import ReportToolClassActivity from '../../components/widgets/ReportToolClassActivity';
import ReportToolSkillReport from '../../components/widgets/ReportToolSkillReport';
import ReportToolPlacementTest from '../../components/widgets/ReportToolPlacementTest';
import ReportToolNewLEClassActivity from '../../components/widgets/ReportTollNewLEClassActivity';
import { isMobile } from 'react-device-detect';
import { push } from 'react-router-redux';
import { userNavigateWithClassCode } from '../../utils/user-route-getter';
import { teacherRoutes } from '../../constants/routes';
import { PriceIncreaseBanner } from '../../components/banner/PriceIncreaseBanner';
import { DownloadPlayerLoginsBanner } from '../../components/banner/DownloadPlayerLoginsBanner';
import { getPlayerLoginsInfo, setPlayerLoginsInfo } from '../../utils/user';
import { MODAL_DEFAULT } from '../../constants/modals';
import { Typography } from '@material-ui/core';
import playAtHomeImage from '../../assets/play-at-home.png';
import { useAppSelector } from '../../redux/hooks';

const ClassView: React.FC<any> = (props: any) => {
  const currentClass = useSelector((state: any) => state.class.currentClass);
  const userData = useSelector((state: any) => state.userData);
  const [currentClassCode, setCurrentClassCode] = useState<any>('');
  const [itemsPerRow, setItemsPerRow] = React.useState(2);
  const [showPlayerLoginsBanner, setShowPlayerLoginsBanner] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200 || isMobile) {
        setItemsPerRow(1);
      } else if (screenWidth < 1700 && screenWidth >= 1200) {
        setItemsPerRow(2);
      } else if (screenWidth >= 1700) {
        setItemsPerRow(3);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const hideSpinnerAfter = true;
    let classCode = userData.dreamscape.classCode;
    if (userData.userType !== userTypes.parent) {
      classCode = props.match.params.classCode;
    } else {
      dispatch(getPlanner(classCode));
    }
    setCurrentClassCode(classCode);
    dispatch(getClass(classCode, { hideSpinnerAfter }));
  }, [
    dispatch,
    props.match.params.classCode,
    userData.dreamscape.classCode,
    userData.userType
  ]);

  useEffect(() => {
    const playerLoginsInfoString = getPlayerLoginsInfo();
    if (playerLoginsInfoString !== null) {
      const playerLoginsInfo = JSON.parse(playerLoginsInfoString);
      const classArr = Object.keys(playerLoginsInfo);

      const res = classArr.some((item: any) => {
        if (
          item === props.match.params.classCode &&
          playerLoginsInfo[item].banner &&
          playerLoginsInfo[item].currentLogin === false
        ) {
          setShowPlayerLoginsBanner(true);
          return playerLoginsInfo[item].banner;
        }
        return false;
      });
      setShowPlayerLoginsBanner(res);
    }
  }, [props.match.params.classCode]);

  useEffect(() => {
    const playerLoginsInfoString = getPlayerLoginsInfo();

    const showPlayerLoginsPopup = () => {
      if (playerLoginsInfoString !== null) {
        const playerLoginsInfo = JSON.parse(playerLoginsInfoString);
        const classArr = Object.keys(playerLoginsInfo);

        return classArr.some((item: any) => {
          if (
            item === currentClass.code &&
            playerLoginsInfo[item].popup &&
            playerLoginsInfo[item].currentLogin === false
          ) {
            return playerLoginsInfo[item].popup;
          }
          return false;
        });
      }
    };

    const handlePopupClose = () => {
      dispatch(hide('modal'));
      if (playerLoginsInfoString !== null) {
        const playerLoginsInfo = JSON.parse(playerLoginsInfoString);
        const classArr = Object.keys(playerLoginsInfo);

        classArr.some((item: any) => {
          if (item === currentClass.code) {
            setPlayerLoginsInfo(
              JSON.stringify({
                ...playerLoginsInfo,
                [item]: {
                  ...playerLoginsInfo[item],
                  popup: false
                }
              })
            );
          }
          return false;
        });
      }
    };

    const props: any = {
      type: MODAL_DEFAULT,
      title: `Reward your players`,
      dividers: true,
      hideActions: true,
      hideCloseAction: true,
      showTitleClose: true,
      handleTitleClose: () => handlePopupClose(),
      children: () => {
        return (
          <Grid container>
            <Grid item md={12} xs={12} style={{ marginBottom: '10px' }}>
              <Typography component="p">
                Did you know that Dreamscape can be played at home? Send a copy
                of your player's logins home with them to continue the fun after
                school!
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <img src={playAtHomeImage} alt="Player Logins" />
            </Grid>
          </Grid>
        );
      }
    };
    if (showPlayerLoginsPopup()) {
      dispatch(show('modal', props));
    }
  }, [dispatch, currentClass.code]);
  const features = useAppSelector(state => state.featureFlag);
  const [widgets, setWidgets] = useState<Array<any>>([]);

  useEffect(() => {
    if (features.newDashboardFlag) {
      setWidgets([
        <ReportToolNewLEClassActivity isWidget={true} />,
        <ReportToolSkillReport isWidget={true} />,
        <ReportToolPlacementTest isWidget={true} />,
        <ReportToolAssignmentTool isWidget={true} />,
        <ReportToolNewLeaderboard isWidget={true} />
      ]);
    } else {
      setWidgets([
        <ReportToolClassActivity isWidget={true} />,
        <ReportToolSkillReport isWidget={true} />,
        <ReportToolPlacementTest isWidget={true} />,
        <ReportToolAssignmentTool isWidget={true} />,
        <ReportToolLeaderboard isWidget={true} />,
        <ReportToolQuestions isWidget={true} />
      ]);
    }
  }, [features.newDashboardFlag]);

  let title: any = `Weekly Dashboard for ${currentClass.name} : ${currentClass.code}`;
  if (userData.userType === userTypes.parent) {
    title = "My Player's Dashboard";
  }

  if (currentClass.isLoading) {
    return null;
  }

  if (!currentClass.roster.length && currentClass.code === currentClassCode) {
    dispatch(show('modal', { type: MODAL_ADD_STUDENT }));
  }
  const handleUpgrade = () => {
    dispatch(
      push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
    );
  };

  return (
    <>
      <PriceIncreaseBanner handleUpgrade={handleUpgrade} />
      {showPlayerLoginsBanner && (
        <DownloadPlayerLoginsBanner
          setBannerClose={setShowPlayerLoginsBanner}
        />
      )}

      <View title={title}>
        {(!currentClass.classReport ||
          !currentClass.classReport.skills ||
          currentClass.classReport?.skills?.total?.length <= 0) && (
          <Grid container spacing={4} alignItems="center">
            <Grid item lg={12}>
              {currentClass.registrationStep < 4 && (
                <RegistrationProgress currentClass={currentClass} />
              )}
            </Grid>
          </Grid>
        )}
        <WidgetContainer
          axis="xy"
          distance={10}
          spacing={40}
          itemsPerRow={itemsPerRow}
          // Stopped shuffle widgets.
          // onSortEnd={(event, mouseEvent) => {
          //   const { oldIndex, newIndex } = event;
          //   setWidgets(arrayMove(widgets, oldIndex, newIndex));
          // }}
          items={widgets}
        />
        {currentClass.roster.length > 0 && <ChuckContainer />}
      </View>
    </>
  );
};
export default React.memo(withRouter(ClassView));
