/**
 * @description parses jwt token
 * @param token
 */
import { UserType } from '../enums/UserType';

export function parseJwt(token: string): { _id: string; userType: UserType; exp: number, featureFlagTags: string} {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

export function isValidJwt(token: string): boolean {
  const parsed = parseJwt(token);
  return parsed ? parsed.exp > Date.now()/1000 : false;
}
