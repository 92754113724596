import { LocalStorage } from './../enums/LocalStorage';
import { UserType } from '../enums/UserType';
import moment from 'moment';

/**
 * Checks if user type is parent
 * @param userType
 */
export function isParent(userType: string) {
  return userType === UserType.Parent;
}

/**
 * Calculates whether current browser is in dates range
 */
export function isInSaleRange() {
  return moment(new Date()).isBetween('2021-05-03', '2021-05-16', 'days', '[]');
}

/**
 * Determines whether to show sales banner not
 * @param userType
 */
export function showSalesBanner(userType: string) {
  return (
    !isParent(userType) && isInSaleRange() && !getSalesBannerLocalStorage()
  );
}

/**
 * Gets sales banner seen from localstorage
 */
export function getSalesBannerLocalStorage() {
  return JSON.parse(<string>localStorage.getItem(LocalStorage.SALES_BANNER));
}

/**
 * @description returns true or false if class mission modal has been seen
 */
export function isUserSeenClassMission() {
  return localStorage.getItem(LocalStorage.CLASS_MISSIONS_TRIAL);
}

/**
 * @description sets class mission trial in local storage
 */
export function setClassMissionModalSeen() {
  localStorage.setItem(LocalStorage.CLASS_MISSIONS_TRIAL, 'true');
}

/**
 * @description returns true or false if class mission trial extended modal has been seen
 */
export function isUserSeenClassMissionTrialExtended() {
  return localStorage.getItem(LocalStorage.CLASS_MISSIONS_TRIAL_EXTENDED);
}

/**
 * @description sets class mission  trial extended in local storage
 */
export function setClassMissionTrialExtendedModalSeen() {
  localStorage.setItem(LocalStorage.CLASS_MISSIONS_TRIAL_EXTENDED, 'true');
}

/**
 * @description returns true or false if placement test warning modal has been seen
 */
export function hasUserSeenPlacementTestWarningModal() {
  return localStorage.getItem(LocalStorage.PLACEMENT_TEST_WARNING);
}

/**
 * @description sets placement test warning in local storage
 */
export function setPlacementTestWarningModalSeen() {
  localStorage.setItem(LocalStorage.PLACEMENT_TEST_WARNING, 'true');
}

export function setPlayerLoginsInfo(info: string) {
  localStorage.setItem(LocalStorage.PLAYER_LOGINS_INFO, info);
}

export function getPlayerLoginsInfo() {
  return localStorage.getItem(LocalStorage.PLAYER_LOGINS_INFO);
}

export function getHideNewLeOptInPopupLocalStorage() {
  return JSON.parse(localStorage.getItem(LocalStorage.HIDE_NEW_LE_OPT_IN_POPUP) as string);
}