import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { show } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../types/fetch/fetch-types';
import { Debug } from '../../../utils/debug';
import { getUser } from '../../../redux/actions/user-actions';
import { getAllMembers } from '../../../redux/actions/members-actions';
import { teacherRoutes } from '../../../constants/routes';
import { push } from 'connected-react-router';
import { userNavigateWithParam } from '../../../utils/user-route-getter';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

interface ModalCreateTestPrepProps {
  folderId?: number;
  handleHide: any;
  selectedTestPrepId?: number;
  assignmentType?: string;
}

const ModalTeacherAssignMemberships: React.FC<ModalCreateTestPrepProps> = (
  props: ModalCreateTestPrepProps
) => {
  const userData = useSelector((state: any) => state.userData);
  const { premium } = userData;
  const membershipData = useAppSelector(state => state.members);
  const classData = useAppSelector(state => state.class);
  const { classes } = classData;
  const { token } = useAppSelector(state => state.login);
  const [nonMembershipUsers, setNonMembershipUsers] = useState([]);
  const dispatch = useDispatch();
  const styles = useStyles();
  const { handleHide } = props;
  const [selected, setSelected] = useState<any>([]);

  useEffect(() => {
    const archivedClasses = classes.filter((a: any) => a.archived === true);
    const { nonMembers } = membershipData;
    const archivedUsers: string[] = [];
    if (archivedClasses) {
      const archivedUsersData = archivedClasses.map((item: any) => item.roster);
      archivedUsersData.forEach((element: any) => {
        element.forEach((item: any) => {
          archivedUsers.push(item.userId);
        });
      });
    }
    let sortedList = [];
    if (nonMembers) {
      sortedList = nonMembers.sort(function(a: any, b: any) {
        if (a.firstname < b.firstname) {
          return -1;
        }
        if (a.firstname > b.firstname) {
          return 1;
        }
        return 0;
      });
    }

    const unarchivedNonMembersipPlayers = sortedList.filter(function(obj: any) {
      return !archivedUsers.some(function(obj2: any) {
        return obj._id == obj2;
      });
    });
    setNonMembershipUsers(unarchivedNonMembersipPlayers);
    setSelected({
      students: {
        data: unarchivedNonMembersipPlayers.map((student: any) => {
          student.selected = false;
          return student;
        }),
        allSelected: false,
        selectedCheckBox: 0
      }
    });
  }, [classes, membershipData]);

  const handleCheckStudent = (_id: string, event: any) => {
    if (event.target.checked) {
      if (selected.students.selectedCheckBox == premium.membershipsAvailable) {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Please unselect any of the player to select others'
          })
        );
      }
    }
    const data = selected.students.data.map((student: any) => {
      if (event.target.checked) {
        if (
          student._id === _id &&
          selected.students.selectedCheckBox < premium.membershipsAvailable
        ) {
          student = {
            ...student,
            selected: event.target.checked
          };

          if (event.target.checked) {
            selected.students.selectedCheckBox++;
          }
        }
        return student;
      } else if (!event.target.checked) {
        if (student._id === _id) {
          student = {
            ...student,
            selected: event.target.checked
          };

          if (!event.target.checked) {
            selected.students.selectedCheckBox--;
          }
        }
        return student;
      }
    });

    const newState = {
      students: {
        ...selected.students,
        data
      }
    };
    const unselected = selected.students.data.filter(
      (student: any) => student.selected === false
    );
    if (event.target.checked === false) {
      newState.students.allSelected = false;
    } else if (!unselected.length) {
      newState.students.allSelected = true;
    }
    setSelected({
      ...selected,
      ...newState
    });
  };

  const handleSelectAllClick = () => {
    if (selected.students.data.length > premium.membershipsAvailable) {
      selected.students.selectedCheckBox = premium.membershipsAvailable;
    } else {
      selected.students.selectedCheckBox = selected.students.data.length;
    }

    const { allSelected } = selected.students;

    const data1 = selected.students.data
      .slice(0, premium.membershipsAvailable)
      .map((student: any) => ({
        ...student,
        selected: !allSelected
      }));
    const data2 = selected.students.data
      .slice(premium.membershipsAvailable, selected.students.data.length)
      .map((student: any) => ({
        ...student,
        selected: allSelected
      }));

    const data = data1.concat(data2);
    setSelected({
      ...selected,
      students: {
        ...selected.students,
        data
      }
    });
  };

  const addMemberships = (data: any) => {
    dispatch(getUser(token, false));
    props.handleHide();

    dispatch(getAllMembers());

    if (selected.students.data.length > 0) {
      return (dispatch: any) => {
        dispatch(
          fetchApi({
            url: 'subscriptions/assign-memberships',
            method: APIMethods.POST,
            spinnerMessage: 'Assigning membership',
            hideSpinnerAfter: true,
            notificationSuccess: 'Memberships assigned',
            errorMessage: `There was an error processing your request, please try again later.`,
            data
          })
        )
          .then((response: any) => {
            return response;
          })
          .catch((error: any) => {
            Debug.log(error);
          });
      };
    }
  };

  const addPlayerMemberships = () => {
    if (selected.students?.selectedCheckBox > 0) {
      const currentClass = selected.students.data.map(
        (item: any) => item.joinedWith.value
      );
      const player_ids = selected.students.data.filter(
        (item: any) => item.selected == true
      );
      const data = { playerIds: player_ids };
      dispatch(addMemberships(data));
      dispatch(getUser(token, false));
      dispatch(
        push(
          userNavigateWithParam(
            teacherRoutes.classStudent,
            'classCode',
            currentClass[0] as string
          )
        )
      );
    } else {
      dispatch(
        show('snackbar', {
          variant: 'error',
          message: 'Please select atleast one player'
        })
      );
    }
  };

  const listItems = selected.students?.data.map(
    (nonMembershipUsers: any, index: any) => (
      <List>
        <Grid container spacing={3}>
          <Checkbox
            checked={nonMembershipUsers.selected}
            color="primary"
            onChange={(event: any) =>
              handleCheckStudent(nonMembershipUsers._id, event)
            }
            value={nonMembershipUsers.selected}
          />
          <Grid item>
            <Typography>
              {nonMembershipUsers.firstname +
                ' ' +
                nonMembershipUsers.lastname +
                '. (' +
                nonMembershipUsers.username +
                ')'}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </List>
    )
  );

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Assign your memberships
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Button
          disabled={
            selected.students?.selectedCheckBox == premium.membershipsAvailable
          }
          variant="outlined"
          style={{
            margin: '0 auto',
            display: 'flex',
            color: '#3FD2D9',
            borderColor: '#3FD2D9'
          }}
          onClick={() => handleSelectAllClick()}
        >
          Assign all memberships
        </Button>
        <br />
        <ListItem dense>
          <ListItemText>{listItems}</ListItemText>
        </ListItem>
        <Typography>
          {selected.students?.selectedCheckBox}/{premium.membershipsAvailable}{' '}
          memberships have been assigned.
        </Typography>
        <Divider></Divider>
        <br />
      </DialogContent>
      <DialogActions>
        <Grid style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            style={{
              backgroundColor: '#3FD2D9',
              color: 'white'
            }}
            onClick={handleHide}
          >
            Not now
          </Button>

          <Button
            style={{
              backgroundColor: '#3FD2D9',
              color: 'white',
              margin: '0 5px'
            }}
            onClick={addPlayerMemberships}
            // disabled={selected.students?.selectedCheckBox == 0}
          >
            Continue
          </Button>
        </Grid>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalTeacherAssignMemberships;
