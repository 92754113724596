import { LocalStorage } from '../../../enums/LocalStorage';

export interface SummerNotificationState {
  [key: string]: {
    banner: boolean;
    dot: boolean;
  };
}

export function getSummerNotificationState(): SummerNotificationState {
  const stateString = localStorage.getItem(LocalStorage.SUMMER_NOTIFICATION);
  return !!stateString ? JSON.parse(stateString) : {};
}

export function setSummerNotificationState(
  state: SummerNotificationState
): void {
  localStorage.setItem(LocalStorage.SUMMER_NOTIFICATION, JSON.stringify(state));
}
