import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { CartWithNoPlayers } from './CartWithNoPlayers';
import { CartWithPlayers, useStyles } from './CartWithPlayers';

interface ICartProps {
  quantity: number;
  players: {
    username: string;
    name: string;
    checked: boolean;
    id: string;
  }[];
  planRecurrence: string;
  isLoggedInUser: boolean;
  isOneBuyLink: boolean;
  handleQuantity(quantity: number): void;
  handlePlayerSelection(id: string): void;
}
export const Cart = (props: ICartProps) => {
  const {
    planRecurrence,
    quantity,
    players,
    handleQuantity,
    isOneBuyLink,
    isLoggedInUser,
    handlePlayerSelection
  } = props;

  const showPlayerData =
    (isOneBuyLink && players.length === 1) ||
    (isLoggedInUser && players.length > 0);
  return (
    <Grid container style={{ marginBottom: '20px' }}>
      <Grid item xs={12}>
        {showPlayerData && players && (
          <CartWithPlayers
            isLoggedInUser={isLoggedInUser}
            planRecurrence={planRecurrence}
            players={players}
            handlePlayerSelection={handlePlayerSelection}
          />
        )}
        {!showPlayerData && (
          <CartWithNoPlayers
            handleQuantity={handleQuantity}
            quantity={quantity}
            planRecurrence={planRecurrence}
          />
        )}
      </Grid>
    </Grid>
  );
};
