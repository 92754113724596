import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { ChevronLeft } from '@material-ui/icons';
import useQueryParams from '../../../../hooks/UseQueryParams';
import { userTypesDashboardRoute } from '../../../../constants/users';
import { teacherRoutes } from '../../../../constants/routes';
import View from '../../../../components/view/View';
import Table from '../../../../components/table/Table';
import { trackViewAssignmentTool } from '../../../../redux/actions/mixpanel-actions';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../../../types/fetch/fetch-types';

const useStyles = makeStyles(() => ({
  circle: {
    borderRadius: '50%',
    display: 'inline-block',
    height: 20,
    width: 20
  },
  link: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

interface AssignmentReport {
  allDay: boolean;
  _id: number;
  type: string;
  title: string;
  color: string;
  name: string;
  end: string;
  classCode: string;
  start: string;
  status: string;
}

const UserGeneratedAssignmentReportsView: React.FC<any> = () => {
  const classes = useStyles();

  const userData = useSelector((state: any) => state.userData);
  const [assignmentReports, setAssignmentReports] = useState<
    AssignmentReport[]
  >([]);
  const [loading, setLoading] = useState(true);
  const params = useParams<{ classCode: string }>();
  const isAtClassLevel = params.classCode !== undefined;

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  useEffect(() => {
    if (query.assignmentId) {
      const { assignmentId, isUserGenerated } = query;
      setLoading(true);
      fetchAssignmentsReport(assignmentId, isUserGenerated, params.classCode)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else if (params.classCode) {
      setLoading(true);
      fetchAssignmentsReportByClassCode(params.classCode)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.classCode, query.assignmentId]);

  async function fetchAssignmentsReportByClassCode(classCode: string) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/user-generated/report/class/${classCode}`,
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.GET
        })
      );
      const { assignments } = response.data;
      if (Array.isArray(assignments)) {
        setAssignmentReports(assignments);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  async function fetchAssignmentsReport(
    assignmentId: number,
    isUserGenerated: boolean,
    classCode: string
  ) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/user-generated/report?assignmentId=${assignmentId}&isUserGenerated=${isUserGenerated}&classCode=${classCode}`,
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.GET
        })
      );
      const { assignments } = response.data;
      if (assignments && assignments.length > 0) {
        setAssignmentReports(assignments);
      }
    } catch (err) {
      console.log('error', err);
    }
  }
  let assignmentCreationLink = teacherRoutes.userGeneratedAssignments;
  return (
    <View
      title="Assignment Reports"
      renderBackButton={() => (
        <Button onClick={() => history.goBack()} style={{ marginRight: 10 }}>
          <ChevronLeft /> Back
        </Button>
      )}
    >
      <Grid container spacing={4}>
        {loading ? (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress color={'primary'} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            {!assignmentReports.length ? (
              <Typography component="p">
                You don't have any assignments for this class,
                <NavLink
                  className={classes.link}
                  style={{ marginLeft: 5 }}
                  to={assignmentCreationLink}
                >
                  click here to create one.
                </NavLink>
              </Typography>
            ) : (
              <Table
                columns={[
                  {
                    Header: 'Name',
                    accessor: 'title',
                    Cell: (row: any) => (
                      <a
                        className={classes.link}
                        onClick={() => {
                          const data = row.row.original;
                          if (isAtClassLevel) {
                            if (data.isUserGenerated) {
                              history.push(
                                `/dashboard/${userData.userType.toLowerCase()}/assignment-tool/report/${
                                  data.id
                                }/${params?.classCode}?isUserGenerated=true`
                              );
                            } else {
                              history.push(
                                `/dashboard/${userData.userType.toLowerCase()}/assignment-tool/report/${
                                  data._id}/${params?.classCode}`
                              );
                            }
                          } else {
                            if (data.isUserGenerated) {
                              history.push(
                                `${
                                  userTypesDashboardRoute[userData.userType]
                                }/assignments/reports/view/${
                                  data.id
                                }?isUserGenerated=true&classCode=${
                                  data.classCode
                                }`
                              );
                            } else {
                              history.push(
                                `${
                                  userTypesDashboardRoute[userData.userType]
                                }/assignments/reports/view/${
                                  data._id
                                }?classCode=${data.classCode}`
                              );
                            }
                          }
                          dispatch(trackViewAssignmentTool());
                        }}
                      >
                        {row.row.original.title}
                      </a>
                    )
                  },
                  {
                    Header: 'Type',
                    accessor: 'type'
                  },
                  {
                    Header: 'Class',
                    accessor: 'classCode',
                    Cell: (row: any) => {
                      return row.row.original.classCode || 'Unavailable';
                    }
                  },
                  {
                    Header: 'Status',
                    accessor: 'status'
                  },
                  {
                    Header: 'Color',
                    Cell: (row: any) => (
                      <span
                        className={classes.circle}
                        style={{ backgroundColor: row.row.original.color }}
                      />
                    )
                  }
                ]}
                defaultSize={10}
                data={assignmentReports}
              />
            )}
          </Grid>
        )}
      </Grid>
    </View>
  );
};

export default UserGeneratedAssignmentReportsView;
