import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import store from '../store';
import { Debug } from '../../utils/debug';
import { REFERRALS_GET_ALL } from '../types/referrals/referrals-action-types';
import { show } from 'redux-modal';
import { MODAL_DEFAULT } from '../../constants/modals';
import { updateTodoListItem } from './class-actions';

export function sendReferrals(emails: Array<string>, referralUrl: string) {
  return (dispatch: any) => {
    //@ts-ignore
    const userData: any = store.getState().userData;
    dispatch(
      fetchApi({
        url: 'referral/send',
        method: APIMethods.POST,
        spinnerMessage: 'Sending E-mails',
        notificationSuccess: 'All E-mails Sent!',
        hideSpinnerAfter: true,
        data: {
          emailList: emails,
          isDreamscape: true,
          referralUrl,
          referrerEmail: userData.email,
          referrerFirst: userData.firstName,
          referrerId: userData._id,
          referrerLast: userData.lastName
        }
      })
    )
      .then((response: any) => {
        // @todo this could be dispatched to the reducer
        dispatch(getReferrals(userData._id));
        dispatch(updateTodoListItem('referATeacher'));
      })
      .catch((error: any) => {
        // @todo in the back-end it should return a status different from 500 when a refered email already exists
        // 409 should be ok
        let errorMessage =
          'There was an error sending the e-mails, please try again later';
        if (error.response) {
          if (typeof error.response.data === 'string') {
            errorMessage = error.response.data;
          }
        }
        dispatch(
          show('modal', { type: MODAL_DEFAULT, children: errorMessage })
        );
        Debug.log(error);
      });
  };
}

export const getReferrals = (userId: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `referral/${userId}`,
        method: APIMethods.GET
      })
    )
      .then((response: any) => {
        //@todo this should be in a referral reducer
        dispatch({
          type: REFERRALS_GET_ALL,
          payload: response.data
        });
      })
      .catch((error: any) => Debug.log(error));
  };
};
