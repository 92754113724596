import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { hide as hideModal, show as showModal } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useDispatch } from 'react-redux';
import membershipsIcon from '../../../assets/membership/assignMmebershipsIcon.png';
import { MODAL_TEACHER_ASSIGN_MEMBERSHIPS } from '../../../constants/modals';
import { getUser } from '../../../redux/actions/user-actions';
import { useAppSelector } from '../../../redux/hooks';
import { getAllMembers } from '../../../redux/actions/members-actions';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

type ModalMembershipPurchaseSuccessProps = {
  show: () => void;
  handleHide: () => void;
  newMembershipCount: any;
  existingMembershipCount: any;
};

const ModalMembershipPurchaseSuccess: React.FC<ModalMembershipPurchaseSuccessProps> = (
  props: ModalMembershipPurchaseSuccessProps
) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { token } = useAppSelector(state => state.login);
  const membershipData = useAppSelector(state => state.members);
  const { nonMembers } = membershipData;
  const [unarchivedUsers, setUnarchivedUsers] = useState('');
  const classData = useAppSelector(state => state.class);
  const { classes } = classData;
  const archivedClasses = classes.filter((a: any) => a.archived == true);

  const { show, handleHide } = props;

  useEffect(() => {
    const archivedUsers: string[] = [];
    if (archivedClasses) {
      const archivedUsersData = archivedClasses.map((item: any) => item.roster);
      archivedUsersData.forEach((element: any) => {
        element.forEach((item: any) => {
          archivedUsers.push(item.userId);
        });
      });
    }
    const unarchivedNonMembersips = nonMembers.filter(function(obj: any) {
      return !archivedUsers.some(function(obj2: any) {
        return obj._id == obj2;
      });
    });
    setUnarchivedUsers(unarchivedNonMembersips);
  }, [classes, membershipData]);

  const handleContinue = () => {
    props.handleHide();
    dispatch(getUser(token, false));
    dispatch(getAllMembers());
    if (unarchivedUsers.length > 0) {
      const sendProps: any = {
        type: MODAL_TEACHER_ASSIGN_MEMBERSHIPS
      };
      dispatch(showModal('modal', sendProps));
    }
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Your purchase was successful!
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Congratulations! You now have {props.newMembershipCount} new
          memberships and a total of{' '}
          {Number(props.newMembershipCount) +
            Number(props.existingMembershipCount)}
          &nbsp; memberships.
        </Typography>
        <br />
        <img
          src={membershipsIcon}
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '110px',
            height: '125px'
          }}
        ></img>
        <Divider></Divider>
        <br />
        <Button
          style={{
            backgroundColor: '#3FD2D9',
            color: 'white',
            marginTop: '2px',
            fontSize: '1.1rem',
            position: 'relative',
            left: 420
          }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalMembershipPurchaseSuccess;
