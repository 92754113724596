import React, { useState, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Select,
  makeStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Divider,
  FormHelperText,
  Tooltip,
  FormControl,
  InputLabel,
  Theme
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { show as showModal, hide } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { Close, KeyboardArrowLeft, HelpOutline } from '@material-ui/icons';
import {
  changeGoogleClass,
  importGoogleClasses
} from '../../../redux/actions/google-actions';
import { MODAL_ADD_CLASS } from '../../../constants/modals';
import { GoogleClass } from '../../../types/google/google-classroom-import-request-type';

const useStyles = makeStyles((theme: Theme) => ({
  optionSelect: {
    marginBottom: 20,
    width: '100%'
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  columnTitle: {
    fontWeight: 500
  },
  classDivider: {
    height: 1,
    margin: '10px 0',
    width: '100%'
  },
  toolTipIcon: {
    color: '#404040',
    cursor: 'pointer',
    margin: '30px 0 10px 10px',
    width: 20
  },
  gradeWrapper: {
    flex: 1
  }
}));

const ModalGoogleClassroomImport: React.FC = (props: any) => {
  enum Steps {
    none,
    classSelection,
    importClasses,
    success
  }

  const { show } = props;
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.classSelection);
  const googleClassroom = useSelector((state: any) => state.googleClassroom);
  const { classes: allClasses } = useSelector((state: any) => state.class);
  const grades = [1, 2, 3, 4, 5, 6, 7, 8];
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleCloseModal = () => {
    const hideCloseButton = !allClasses.length;
    dispatch(
      showModal('modal', {
        type: MODAL_ADD_CLASS,
        title: 'Add Class',
        message: 'Please add class over here',
        hideCloseButton
      })
    );
  };

  const renderButtons = () => {
    switch (currentStep) {
      case Steps.classSelection:
        if (!googleClassroom.classes.length) {
          return null;
        }
        const selectedClasses = googleClassroom.classes.filter(
          (item: GoogleClass) => item.selected
        );
        return (
          <Button
            primary
            disabled={selectedClasses.length === 0}
            onClick={() => dispatch(importGoogleClasses(selectedClasses))}
          >
            {`Import ${selectedClasses.length} ${
              selectedClasses.length === 1 ? 'class' : 'classes'
            }`}
          </Button>
        );
      default:
        return null;
    }
  };

  const renderStep = () => {
    if (googleClassroom.error) {
      return (
        <Typography component="p">
          There was an error authenticating you with Google. Please try again.
        </Typography>
      );
    }

    switch (currentStep) {
      case Steps.classSelection:
        if (!googleClassroom.classes.length) {
          return (
            <Typography>
              No classes found for your Google account, please try with a
              different one.
            </Typography>
          );
        }
        return (
          <>
            <Typography style={{ marginBottom: 20 }}>
              Select one class, All players from google classroom will have new
              Dreamscape player accounts:
            </Typography>
            {googleClassroom.classes.map((item: GoogleClass, index: number) => {
              const numOfStudents = (item.students.length || 'No').toString();
              const description = `${numOfStudents} ${
                numOfStudents === '1' ? 'player' : 'players'
              } found`;
              return (
                <>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item md={8} lg={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.selected}
                            onChange={() =>
                              dispatch(
                                changeGoogleClass(
                                  item.id,
                                  'selected',
                                  !item.selected
                                )
                              )
                            }
                            value={item.id}
                            color="primary"
                          />
                        }
                        label={item.name}
                      />
                      <FormHelperText style={{ margin: 0 }}>
                        {description}
                      </FormHelperText>
                    </Grid>
                    <Grid item container md={4} lg={4} alignItems="center">
                      <FormControl className={classes.gradeWrapper}>
                        <InputLabel>Grade</InputLabel>
                        <Select
                          fullWidth
                          onChange={(event: any) =>
                            dispatch(
                              changeGoogleClass(
                                item.id,
                                'grade',
                                event.target.value
                              )
                            )
                          }
                          name="grade"
                          value={item.grade}
                        >
                          {grades.map(grade => (
                            <MenuItem
                              key={grade}
                              selected={grade === item.grade}
                              value={grade}
                            >
                              Grade {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Tooltip
                        className={classes.toolTipIcon}
                        title="This grade will determine the starting reading level for the players in your class. The game is adaptive and it will automatically start moving players to their proper reading level. If you want to change a player's level manually, you can do so after you create your class."
                      >
                        <HelpOutline />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {index + 1 < googleClassroom.classes.length && (
                    <Divider className={classes.classDivider} />
                  )}
                </>
              );
            })}
          </>
        );
      case Steps.success:
        return (
          <>
            <Typography component="p">
              Great. Your classes are synced!
            </Typography>
            <Typography component="p">
              Now your players can log in using their Google account
            </Typography>
          </>
        );
    }
    return null;
  };

  const renderBackArrow = () => {
    if ([Steps.importClasses].includes(currentStep)) {
      return (
        <IconButton
          aria-label="back"
          style={{ marginRight: 10 }}
          onClick={() => {
            // setMergeError(null);
            setCurrentStep(Steps.classSelection);
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
      );
    }
    return null;
  };

  if (googleClassroom.inProgress) {
    return null;
  }

  return (
    <ModalAbstract show={show} handleHide={handleCloseModal} maxWidth="lg">
      <DialogTitle>
        {renderBackArrow()}
        Import Classes
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {Array.isArray(googleClassroom.students) &&
        !googleClassroom.students.length ? (
          <Typography component="p">All players are synced.</Typography>
        ) : (
          renderStep()
        )}
      </DialogContent>
      <DialogActions>{renderButtons()}</DialogActions>
    </ModalAbstract>
  );
};

export default ModalGoogleClassroomImport;
