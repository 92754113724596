import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React, { useState } from 'react';

export const useStyles = makeStyles({
  root: {
    maxHeight: '300px',
    overflowY: 'auto',
    '& :last-child': {
      border: 'none'
    }
  },
  card: {
    borderRadius: '4px 4px 0 0',
    borderBottom: '1px solid #027f5f',
    padding: '0.5rem 0.8rem',
    marginTop: '10px'
  }
});

export interface ICartWithPlayers {
  players: {
    name: string;
    username: string;
    checked: boolean;
    id: string;
  }[];
  planRecurrence: string;
  isLoggedInUser: boolean;

  handlePlayerSelection(id: string): void;
}

export const CartWithPlayers = (props: ICartWithPlayers) => {
  const {
    players,
    planRecurrence,
    isLoggedInUser,
    handlePlayerSelection
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const sizeAboveMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

  function getProfileImage(index: number) {
    const randomIndex = index < 9 ? index + 1 : 1;
    const randomizedIndex =
      randomIndex > 9 ? `0${randomIndex % 10}` : `0${randomIndex}`;
    return `${process.env.REACT_APP_DREAMSCAPE_CDN}/regular_profile_pictures/regular_profile_${randomizedIndex}.png`;
  }
  const [radio, setRadio] = useState('');
  const handleSelectAll = (value: string) => {
    setRadio(value);
    if (value === '') {
      // should select all
      players.forEach(player => {
        if (player.checked) {
          handlePlayerSelection(player.id);
        }
      });
    } else {
      // should select all
      players.forEach(player => {
        if (!player.checked) {
          handlePlayerSelection(player.id);
        }
      });
    }
  };
  return (
    <Box>
      <>
        {players.length > 1 && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right'
            }}
          >
            <RadioGroup
              value={radio}
              onClick={evt => {
                if (radio !== '') {
                  handleSelectAll('');
                } else {
                  handleSelectAll('selectAll');
                }
              }}
            >
              <FormControlLabel
                control={
                  <Radio value={'selectAll'} style={{ color: '#027F5D' }} />
                }
                label={
                  <>
                    <Typography style={{ color: '#027F5D', fontWeight: '600' }}>
                      Select All
                    </Typography>
                  </>
                }
              />
            </RadioGroup>
          </Grid>
        )}

        <Box className={classes.root}>
          {players.map((player, index) => (
            <Grid
              container
              className={classes.card}
              justifyContent="center"
              alignItems="center"
            >
              <Grid container item xs={12} sm={7} md={6} alignItems="center">
                <Grid
                  item
                  // xs={5} sm={4}
                  md={3}
                >
                  <img
                    src={getProfileImage(index)}
                    height={'70px'}
                    width={'70px'}
                    alt="membership plan"
                    style={{ paddingRight: '5px' }}
                  />
                </Grid>
                <Grid item xs={7} sm={8} md={9}>
                  <Typography
                    variant="body1"
                    style={{
                      color: '#027F5D',
                      fontWeight: 800,
                      fontSize: '24px'
                    }}
                  >
                    {player.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#027F5D',
                      fontWeight: '600',
                      fontSize: '16px'
                    }}
                  >
                    username: {player.username}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={5}
                md={6}
                alignItems="center"
                justifyContent="space-between"
                style={{ flexWrap: 'nowrap' }}
              >
                <Grid
                  item
                  xs={8}
                  sm={10}
                  md={10}
                  style={{ textAlign: 'right' }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: '#027F5D',
                      fontWeight: '600',
                      fontSize: '16px',
                      textAlign: sizeAboveMobileScreen ? 'right' : 'start'
                    }}
                  >
                    {planRecurrence.charAt(0).toUpperCase() +
                      planRecurrence.slice(1)}{' '}
                    Membership Plan
                  </Typography>
                </Grid>
                {isLoggedInUser && (
                  <Checkbox
                    checked={player.checked}
                    onClick={() => handlePlayerSelection(player.id)}
                    style={{ color: '#027F5D' }}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Box>
      </>
    </Box>
  );
};
