import {
  AssignmentData,
  AssignmentEditState,
  AssignmentEditStatus,
  AssignmentQuestionType
} from '../assignment-edit.types';
import { validateAssignment } from '../assignment-edit.reducer';
import { AxiosResponse } from 'axios';
import { fetchApi } from '../../../../../../redux/actions/fetch-actions';
import {
  APIEndpoints,
  APIMethods
} from '../../../../../../types/fetch/fetch-types';
import { show } from 'redux-modal/lib/actions';
import store from '../../../../../../redux/store';
import { getPlanner } from '../../../../../../redux/actions/planner-actions';
import { AsyncPromiseHelper } from '../../../../../../utils/async-await-helper/AsyncPromiseHelper';
import { AssignmentActions } from './assignment.actions';
import { AssignmentQuestionActions } from '../question/assignment-question.actions';
import { AssignmentEditActionTypes } from './assignment-action.types';
import { trackUserGeneratedAssignmentCreated } from '../../../../../../redux/actions/mixpanel-actions';

export class AssignmentActionsAsync {
  static create(createAssignment: {
    name: string;
    grade: number;
    folderId?: number;
  }) {
    return async (dispatch: any) => {
      const result: AxiosResponse = await dispatch(
        fetchApi({
          url: 'v1/user-generated/assignment',
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.POST,
          data: createAssignment
        })
      );
      const { data: responseData } = result;
      if (responseData.assignment) {
        trackUserGeneratedAssignmentCreated(responseData.assignment);
      }
      return dispatch({
        type: AssignmentEditActionTypes.UPDATE,
        payload: {
          ...responseData.assignment
        }
      });
    };
  }
  /**
   * @description gets data needed for assignment
   */
  static fetchEditorData() {
    return async (dispatch: any) =>
      dispatch(
        fetchApi({
          url: `v1/user-generated/data`,
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.GET
        })
      ).then((response: AxiosResponse) => {
        const { data } = response;
        const { skills, groups, questionTypes, genres } = data;
        dispatch({
          type: AssignmentEditActionTypes.UPDATE_EDIT_DATA,
          payload: {
            skills,
            groups,
            questionTypes,
            genres
          }
        });
      });
  }

  /**
   * @description updates assignment
   * @param id
   * @param assignment
   * @param status
   */
  static updateAssignment(
    id: number,
    assignment: AssignmentEditState,
    status: AssignmentEditStatus
  ) {
    return async (dispatch: any) => {
      try {
        const { isValid } = validateAssignment(assignment);
        const requestData = {
          name: assignment.name,
          description: assignment.description,
          shuffleQuestions: assignment.shuffleQuestions,
          shuffleAnswers: assignment.shuffleAnswers,
          isValid: isValid,
          status: status
        };
        const result: AxiosResponse = await dispatch(
          fetchApi({
            url: `v1/user-generated/assignment/${id}`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.PATCH,
            data: requestData
          })
        );
        const { data } = result;
        dispatch({
          type: AssignmentEditActionTypes.UPDATE,
          payload: data
        });
      } catch (err) {}
    };
  }

  /**
   * @description duplicates assignment
   * @param id
   * @param name
   * @param grade
   */
  static duplicateAssignment(id: number, name: string, grade: number) {
    return async (dispatch: any) => {
      try {
        const response: AxiosResponse = await dispatch(
          fetchApi({
            url: `v1/user-generated/assignment/${id}/duplicate`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.POST,
            data: {
              name,
              grade
            }
          })
        );
        const { data } = response;
        dispatch(AssignmentActions.updateAssignment(data.assignment));
        dispatch(
          show('snackbar', {
            variant: 'success',
            message: 'Assignment Duplicated'
          })
        );
        dispatch(
          AssignmentActionsAsync.fetchAssignmentById(
            (data.assignment.id as unknown) as number
          )
        );
      } catch (err) {
        dispatch(
          show('snackbar', { variant: 'error', message: 'Error duplicating' })
        );
      }
    };
  }

  /**
   * @description assign assignment to users
   * @param assignmentData
   * @param isFromEdit
   */
  static assignAssignment(assignmentData: object, isFromEdit?: boolean) {
    return async (dispatch: any) => {
      try {
        const response: AxiosResponse = await dispatch(
          fetchApi({
            url: 'v1/user-generated/user/assign',
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.POST,
            data: assignmentData
          })
        );
        const { data } = response;
        if (isFromEdit) {
          dispatch({
            type: AssignmentEditActionTypes.UPDATE,
            payload: {
              status: data.status,
              isAssigned: data.isAssigned,
              id: data.assignmentId
            }
          });
        }

        const classCode = (store.getState().class as any).currentClass.code;
        /**
         * After assign get planner data
         */
        dispatch(getPlanner(classCode));
        dispatch(
          show('snackbar', {
            variant: 'success',
            message: 'Assigned content',
            vertical: 'top',
            horizontal: 'right',
            autoHideDuration: 2000
          })
        );
        return response;
      } catch (err) {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: err.message,
            vertical: 'top',
            horizontal: 'right',
            autoHideDuration: 2000
          })
        );
        return err;
      }
    };
  }

  /**
   * @description fetch assignment by id
   * @param assignmentId
   * @param isPremade
   * @param currentEditedQuestion
   */
  static fetchAssignmentById(
    assignmentId: number,
    isPremade: boolean | string = false,
    currentEditedQuestion?: any
  ) {
    let query = '';
    if (isPremade && isPremade !== 'false') {
      query = '?isPremade=true';
    }
    return async (dispatch: any) => {
      const [result, error] = await AsyncPromiseHelper<AxiosResponse>(
        dispatch(
          fetchApi({
            url: `v1/user-generated/assignment/${assignmentId}${query}`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.GET
          })
        )
      );
      if (error) {
        return Promise.reject(error);
      }
      const { data: assignmentData } = result;
      assignmentData.viewer = true;
      /**
       * This functionality maintains the edit state on the ui when a user edits something
       */
      if (currentEditedQuestion) {
        const { rootIndex, index } = currentEditedQuestion;
        if (
          currentEditedQuestion.isPassageQuestion &&
          assignmentData.data[rootIndex] &&
          assignmentData.data[rootIndex].questions[index]
        ) {
          assignmentData.data[rootIndex].questions[index].isEditMode = true;
        } else if (assignmentData.data[index]) {
          assignmentData.data[index].isEditMode = true;
        }
      }
      dispatch(AssignmentActions.retrieveAssignment(assignmentData));
      return Promise.resolve(result);
    };
  }
  /**
   * @description handles saving assignment
   * @param assignment
   * @param type
   * @param isPremade
   * @param autoSave
   */
  static saveAssignment(
    assignment: AssignmentEditState,
    type: AssignmentEditStatus,
    isPremade?: boolean,
    autoSave?: boolean
  ) {
    return async (dispatch: any) => {
      dispatch({
        type: autoSave
          ? AssignmentEditActionTypes.AUTO_SAVE_STARTED
          : AssignmentEditActionTypes.SAVING
      });
      const { isValid } = validateAssignment(assignment);
      const assignmentUpdateRequest = {
        name: assignment.name,
        description: assignment.description,
        shuffleQuestions: assignment.shuffleQuestions,
        shuffleAnswers: assignment.shuffleAnswers,
        grade: assignment.grade,
        isValid: isValid,
        status: type
      };
      const syncRequestObject: any = {
        assignment: assignmentUpdateRequest,
        syncQuestions: [],
        updateQuestions: [],
        removeQuestions: [],
        updatePassages: [],
        questionBankAdd: []
      };
      const editedQuestions = assignment.data.filter(
        assignmentData =>
          assignmentData.isEdited ||
          assignmentData.isFromQuestionBank ||
          !assignmentData.isUserGenerated
      );
      for (let i = 0; i < editedQuestions.length; i++) {
        let passageOrQuestion: AssignmentData = editedQuestions[i];
        /**
         * If question or passage marked for deletion add to delete array
         */
        if (passageOrQuestion.isDeleted) {
          if (!passageOrQuestion.isSynced) {
            dispatch(
              AssignmentQuestionActions.deleteQuestion(passageOrQuestion.id)
            );
          } else {
            syncRequestObject.removeQuestions.push({
              id: passageOrQuestion.id,
              isUserGenerated: passageOrQuestion.isUserGenerated,
              type: passageOrQuestion.type,
              isQuestion:
                passageOrQuestion.type === AssignmentQuestionType.Independent
            });
          }
        } else {
          if (passageOrQuestion.isFromQuestionBank) {
            if (passageOrQuestion.type === AssignmentQuestionType.Independent) {
              const questionRequest = {
                type: passageOrQuestion.type,
                questionId: passageOrQuestion.syncedId,
                assignmentId: assignment.id,
                passageId: undefined,
                isUserGenerated: passageOrQuestion.isUserGenerated || false
              };
              syncRequestObject.questionBankAdd.push(questionRequest);
            } else if (
              passageOrQuestion.type === AssignmentQuestionType.Passage
            ) {
              const questions = passageOrQuestion.questions.map(
                (question: any) => {
                  return {
                    type: AssignmentQuestionType.Passage,
                    questionId: question.syncedId,
                    assignmentId: assignment.id,
                    passageId: passageOrQuestion.syncedId,
                    isUserGenerated: question.isUserGenerated || false
                  };
                }
              );
              syncRequestObject.questionBankAdd = [
                ...syncRequestObject.questionBankAdd,
                ...questions
              ];
            }
          } else {
            if (passageOrQuestion.type === AssignmentQuestionType.Independent) {
              const requestData = {
                id: passageOrQuestion.id,
                question: passageOrQuestion.question,
                answers: passageOrQuestion.answers,
                questionTypeId: passageOrQuestion.format,
                skillId: passageOrQuestion.skillId,
                name: passageOrQuestion.name,
                dashboardUserId: assignment.dashboardUserId,
                isUserGenerated: true,
                assignmentId: assignment.id,
                feedbackIncorrect: passageOrQuestion.feedbackIncorrect,
                type: AssignmentQuestionType.Independent
              };
              if (passageOrQuestion.isSynced) {
                syncRequestObject.updateQuestions.push(requestData);
              } else {
                syncRequestObject.syncQuestions.push(requestData);
              }
            } else if (
              passageOrQuestion.type === AssignmentQuestionType.Passage
            ) {
              const passageRequest = {
                id: passageOrQuestion.id,
                name: passageOrQuestion.passageTitle,
                text: passageOrQuestion.passage,
                genreId: passageOrQuestion.genreId,
                isUserGenerated: true,
                isSynced: passageOrQuestion.isSynced,
                dashboardUserId: assignment.dashboardUserId
              };
              if (passageOrQuestion.isEdited) {
                syncRequestObject.updatePassages.push(passageRequest);
              }
              let passageId = passageOrQuestion.id;
              const syncPassageQuestions = [];
              const updatePassageQuestions = [];
              /**
               * Sync passage questions
               */
              for (
                let passageQuestionIndex = 0;
                passageQuestionIndex < passageOrQuestion.questions.length;
                passageQuestionIndex++
              ) {
                let passageQuestion =
                  passageOrQuestion.questions[passageQuestionIndex];
                if (passageQuestion.isDeleted) {
                  if (passageQuestion.isSynced) {
                    syncRequestObject.removeQuestions.push({
                      id: passageQuestion.id,
                      isUserGenerated: passageQuestion.isUserGenerated,
                      type: passageQuestion.type,
                      isQuestion: true
                    });
                  } else {
                    dispatch(
                      AssignmentQuestionActions.deleteQuestion(
                        passageQuestion.id,
                        passageId
                      )
                    );
                  }
                } else {
                  const requestData = {
                    question: passageQuestion.question,
                    answers: passageQuestion.answers,
                    passageId: passageId,
                    questionTypeId: passageQuestion.format,
                    skillId: passageQuestion.skillId,
                    name: passageQuestion.name,
                    id: passageQuestion.id,
                    isUserGenerated: true,
                    dashboardUserId: assignment.dashboardUserId,
                    assignmentId: assignment.id,
                    feedbackIncorrect: passageQuestion.feedbackIncorrect,
                    type: AssignmentQuestionType.Passage
                  };
                  if (passageOrQuestion.isSynced && passageQuestion.isSynced) {
                    updatePassageQuestions.push(requestData);
                  } else {
                    syncPassageQuestions.push(requestData);
                  }
                }
              }
              if (syncPassageQuestions.length > 0) {
                syncRequestObject.syncQuestions.push({
                  passage: passageRequest,
                  questions: syncPassageQuestions,
                  isSynced: passageOrQuestion.isSynced,
                  isUserGenerated: true,
                  type: AssignmentQuestionType.Passage
                });
              }
              if (updatePassageQuestions.length > 0) {
                syncRequestObject.updateQuestions = [
                  ...syncRequestObject.updateQuestions,
                  ...updatePassageQuestions
                ];
              }
            }
          }
        }
      }

      const response = await dispatch(
        isPremade
          ? AssignmentActionsAsync.syncPremadeAssignment(
              assignment.id as number,
              syncRequestObject
            )
          : AssignmentActionsAsync.syncAssignment(
              assignment.id as number,
              syncRequestObject
            )
      );
      /**
       * @description only refetch when the assignment was saved successfully
       */
      if (response.status && response.status === 200) {
        /**
         * After fetching get assignment data again
         */
        let autoSaveCurrentEditableQuestion = null;
        assignment.data.forEach((questionData, index) => {
          if (questionData.isEditMode) {
            autoSaveCurrentEditableQuestion = {
              type: questionData.type,
              index
            };
          } else if (questionData.type === AssignmentQuestionType.Passage) {
            questionData.questions.forEach(
              (
                passageQuestion: { isEditMode: any },
                passageQuestionIndex: any
              ) => {
                if (passageQuestion.isEditMode) {
                  autoSaveCurrentEditableQuestion = {
                    type: questionData.type,
                    rootIndex: index,
                    index: passageQuestionIndex,
                    isPassageQuestion: true
                  };
                }
              }
            );
          }
        });
        dispatch(
          AssignmentActionsAsync.fetchAssignmentById(
            (assignment.id as unknown) as number,
            isPremade,
            autoSaveCurrentEditableQuestion
          )
        );
      }
      dispatch({
        type: autoSave
          ? AssignmentEditActionTypes.AUTO_SAVE_ENDED
          : AssignmentEditActionTypes.SAVED
      });
    };
  }

  /**
   * @description deletes assignment
   * @param id
   */
  static deleteAssignment(id: number, isPremade: boolean) {
    let url = 'v1/user-generated/assignment/';
    if (isPremade) {
      url = 'v2.1/assignment/';
    }
    return async (dispatch: any) => {
      const [_, error] = await AsyncPromiseHelper(
        dispatch(
          fetchApi({
            url: `${url}${id}`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.DELETE
          })
        )
      );
      if (error) {
        return dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Error Deleting Assignment'
          })
        );
      }
      return dispatch(
        show('snackbar', {
          variant: 'success',
          message: 'Assignment Deleted'
        })
      );
    };
  }

  /**
   * @description syncs premade assignment
   * @param id
   * @param request
   * @private
   */
  private static syncPremadeAssignment(id: number, request: any) {
    return async (dispatch: any) => {
      const [result, error] = await AsyncPromiseHelper(
        dispatch(
          fetchApi({
            url: `v2.1/assignment/${id}`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.PUT,
            data: request
          })
        )
      );
      if (error) {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Error Syncing Assignment'
          })
        );
        return error;
      }
      return result;
    };
  }

  /**
   * @description syncs assignment question
   * @param id
   * @param request
   * @private
   */
  private static syncAssignment(id: number, request: any) {
    return async (dispatch: any) => {
      const [result, error] = await AsyncPromiseHelper(
        dispatch(
          fetchApi({
            url: `v1/user-generated/sync/assignment/${id}`,
            endpoint: APIEndpoints.EDUCATION,
            method: APIMethods.POST,
            data: request
          })
        )
      );
      if (error) {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Error Syncing Assignment'
          })
        );
        return error;
      }
      return result;
    };
  }
}
