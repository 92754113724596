/**
 * @deprecated This style component isn't using best practices, try to avoid using it
 */
import styled, { css } from 'styled-components';
import * as MaterialButton from '@material-ui/core/Button';
import colors from '../../styles/colors';

type ButtonProps = {
  primary?: boolean;
  green?: boolean;
  blue?: boolean;
  white?: boolean;
  target?: string;
  orange?: boolean;
  loginpage?: boolean;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  height?: number;
  width?: number | 'auto';
  fontSize?: string;
};

const Button = styled(MaterialButton.default)<ButtonProps>`
  && {
    ${props =>
      props.primary &&
      `
			background: ${colors.primary};
      color: ${colors.white};
      // box-shadow: 0 10px 34px -16px rgba(30, 79, 82, 0.8);

			&:hover {
        background: ${colors.primaryHover};
        box-shadow: inset 0 -1px 15px 0 rgba(30, 44, 45, 0.17);
			}
		`}
  }

  && {
    ${props =>
      props.green &&
      `
				background: ${colors.green};
				color: white;
		`}
  }

  && {
    ${props =>
      props.green &&
      css`
        &:hover {
          background: ${colors.greenHover} !important;
        }
      `}
  }

  && {
    ${props =>
      props.blue &&
      `
				background: ${colors.blue};
				color: white;
		`}
  }

  && {
    ${props =>
      props.blue &&
      css`
        &:hover {
          background: ${colors.blueHover} !important;
        }
      `}
  }

  && {
    ${props =>
      props.orange &&
      `
				background: ${colors.orange};
				color: white;
		`}
  }

  && {
    ${props =>
      props.orange &&
      css`
        &:hover {
          background: ${colors.orangeHover} !important;
        }
      `}
  }

  && {
    ${props =>
      props.white &&
      css`
        background: ${colors.white} !important;
      `}
  }

  && {
    ${props =>
      props.loginpage &&
      css`
			padding: 13px 25px
			margin-top: -7px
		`}
  }

  && {
    ${props =>
      props.disabled &&
      css`
        background-color: ${colors.disabled} !important;
      `}
  }

  && {
    ${props =>
      props.marginLeft &&
      css`
        margin-left: ${props.marginLeft}px;
      `}
  }

  && {
    ${props =>
      props.marginRight &&
      css`
        margin-right: ${props.marginRight}px;
      `}
  }

  && {
    ${props =>
      props.marginTop &&
      css`
        margin-top: ${props.marginTop}px;
      `}
  }

  && {
    ${props =>
      props.height &&
      css`
        height: ${props.height}px;
      `}
  }

  && {
    ${props =>
      props.width &&
      css`
        width: ${props.width === 'auto' ? 'auto' : `${props.width}px`};
      `}
  }

  && {
    font-size: 0.9rem;
  }

  && {
    ${props =>
      props.fontSize &&
      css`
        font-size: ${props.fontSize === 'auto' ? 'auto' : `${props.fontSize}`};
      `}
  }
`;

export default Button;
