/**
 * This component has some hardcoded labels specifically for Google Sync
 * But it can be used for any jobqueue in the database.
 *
 * @todo
 *  Add description to JobQueue Schema to identify which job it is in this component
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import {
  refreshJobQueue,
  updateWatchOption,
  findJobsByIds,
  resetJobsProcessed
} from '../../redux/actions/job-queue-actions';
import { makeStyles } from '@material-ui/styles';
import colors from '../../styles/colors';
import useInterval from 'react-useinterval';
import { show } from 'redux-modal';
import { getClasses } from '../../redux/actions/class-actions';
import JobQueueState, { Job } from '../../types/state/job-queue-state';
import { MODAL_DEFAULT, MODAL_SUCCESS } from '../../constants/modals';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    background: colors.white,
    borderRadius: 5,
    border: `1px solid ${colors.primary}`,
    bottom: 10,
    boxShadow: '0 0 9px -5px #000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    position: 'fixed',
    right: 10,
    zIndex: 9999
  },
  text: {
    fontSize: 13,
    marginLeft: 10
  }
}));

const JobQueueNotification: React.FC = (props: any) => {
  const jobQueue: JobQueueState = useSelector((state: any) => state.jobQueue);
  const dispatch = useDispatch();
  const classes = useStyles();

  const isJobQueueEmpty = () => {
    return !Array.isArray(jobQueue.activeJobs) || !jobQueue.activeJobs.length;
  };

  const isActiveJobCompleted = () => {
    return !jobQueue.activeJobs.length && jobQueue.jobsProcessed.length;
  };

  useEffect(() => {
    if (isActiveJobCompleted()) {
      dispatch(findJobsByIds(jobQueue.jobsProcessed))
        .then((response: any) => {
          const success = [];
          const fail = [];
          const processedJobs = response.data.data;
          let modal = MODAL_SUCCESS;
          let modalProps: any = {
            text:
              'Google Classes sucessfully synced. Please, refresh your classes to see your data.'
          };
          processedJobs.forEach((job: Job) => {
            if (job.status === 'success') {
              success.push(job);
            } else {
              fail.push(job);
            }
          });
          if (fail.length) {
            modal = MODAL_DEFAULT;
            modalProps = {
              children:
                'There was an error syncing one of your classes. Please, contact support if the problem persists'
            };
          }
          dispatch(show('modal', { type: modal, ...modalProps }));
        })
        .catch((err: any) => {})
        .finally(() => {
          dispatch(getClasses({ hideSpinner: true }));
          dispatch(updateWatchOption(false));
          dispatch(resetJobsProcessed());
        });
    }
  }, [jobQueue.activeJobs]);

  const interval = parseInt(
    process.env.REACT_APP_JOB_QUEUE_UPDATE_ACTIVE_INTERVAL
  );
  useInterval(() => {
    if (jobQueue.shouldWatchForUpdates) {
      dispatch(refreshJobQueue());
    }
  }, interval);

  if (!jobQueue.shouldWatchForUpdates || isJobQueueEmpty()) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CircularProgress size={20} />{' '}
      <Typography className={classes.text}>Syncing Google Classes</Typography>
    </div>
  );
};

export default JobQueueNotification;
