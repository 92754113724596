import moment from 'moment';
import {
  PLAYER_GET_REPORT,
  PLAYER_GET_SKILLS_REPORT,
  PLAYER_REGISTER_FAILED
} from '../types/player/player-action-types';
import {
  CLASS_UPDATE_PLAYER,
  CLASS_UPDATE_PLAYER_FAILED,
  CLASS_PLAYER_REMOVED,
  CLASS_UPDATE_CLASS
} from '../types/class/class-action-types';
import { getClass, getClasses } from './class-actions';
import { getAllMembers } from './members-actions';
import { updateCancellationData } from '../membership-cancellation/cancellation-token-actions';
import {
  trackRemovePlayer,
  trackPlayerRegistration,
  trackPlayerAdded,
  trackEditGrade,
  trackViewNewAssignmentPopup
} from './mixpanel-actions';
import { fetchApi, NotificationVariant } from './fetch-actions';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { CLASS_PLAYER_ADDED } from '../types/class/class-action-types';
import { show, hide } from 'redux-modal';
import store from '../store';
import {
  MODAL_DEFAULT,
  MODAL_ASSIGNMENT_OPTIONS,
  MODAL_SUCCESS
} from '../../constants/modals';
import { Debug } from '../../utils/debug';
import { userTypes } from '../../constants/users';
import { asyncForEach } from '../../utils/async';
import { playerNounLowerCase, playerNounUppercase } from '../../utils/userNoun';
import { UserType } from '../../enums/UserType';
import { refreshToken } from '../login/login-actions';
import { getUser } from './user-actions';
import { Plan } from '../../features/premium/pages/premium-plans/Plan';
import { inviteParent } from '../../redux/actions/user-actions';

export const updatePlayerData = (updateData: any, playerId: string) => {
  return fetchApi({
    url: `sw/player/id/${playerId}`,
    method: APIMethods.PUT,
    spinnerMessage: `Updating ${playerNounLowerCase} information`,
    hideSpinnerAfter: true,
    errorMessage: `There was an error updating the ${playerNounLowerCase} information, please try again later.`,
    data: { data: { ...updateData } }
  });
};

export const updatePlayer = (updateData: any, playerId: string) => {
  return (dispatch: any) => {
    dispatch(updatePlayerData(updateData, playerId))
      .then((response: any) => {
        dispatch(hide('modal'));
        dispatch({
          type: CLASS_UPDATE_PLAYER,
          payload: {
            _id: playerId,
            ...updateData
          }
        });
        dispatch(trackEditGrade());
        // dispatch(getClass(store.getState().class.currentClass.code));
      })
      .catch((error: any) => {
        dispatch({ type: CLASS_UPDATE_PLAYER_FAILED });
      });
  };
};

export const getPlayerReport = (playerId: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/playermetrics/dashboard/${playerId}`,
        method: APIMethods.GET,
        spinnerMessage: `Loading ${playerNounUppercase} Reading Level`,
        hideSpinnerAfter: true,
        errorMessage: `There was an error retrieving the report for this ${playerNounLowerCase}, please try again later.`
      })
    )
      .then((response: any) => {
        dispatch({
          type: PLAYER_GET_REPORT,
          payload: response.data
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const getPlayerSkills = (skillId: string, playerEducationId: number) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `report/skills/${skillId}/user/${playerEducationId}`,
        method: APIMethods.GET,
        spinnerMessage: `Loading ${playerNounUppercase} Skills`,
        hideSpinnerAfter: true,
        endpoint: APIEndpoints.EDUCATION,
        errorMessage: `There was an error retrieving the report for this ${playerNounLowerCase}, please try again later.`
      })
    )
      .then((response: any) => {
        dispatch({
          type: PLAYER_GET_SKILLS_REPORT,
          payload: response.data
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const getPlayerSkillOverTime = (
  dateStart: string | moment.Moment,
  dateEnd: string | moment.Moment,
  skillId: number,
  educationUserId: number | string
) => {
  return fetchApi({
    url: 'report/skills',
    method: APIMethods.POST,
    spinnerMessage: 'Loading data',
    hideSpinnerAfter: true,
    endpoint: APIEndpoints.EDUCATION,
    errorMessage: `There was an error retrieving the report for this ${playerNounLowerCase}, please try again later.`,
    data: {
      dateEnd,
      dateStart,
      skillsList: [skillId],
      type: 'Daily',
      userIds: [educationUserId]
    }
  });
};

const validateEmail = (emailString: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailString).toLowerCase());
};

export const registerPlayers = (playerAccounts: any, classCode = '') => {
  return async (dispatch: any) => {
    const { userData, class: classState, login }: any = store.getState();
    try {
      dispatch(show('spinner', { text: 'Creating accounts...' }));
      let successMessage = 'Player created';
      if (playerAccounts.length > 1) {
        successMessage = 'Players created';
      }
      await asyncForEach(playerAccounts, async (account: any) => {
        const parentemail = account.parentemail;
        delete account.parentemail;
        const educationUser = await dispatch(
          fetchApi({
            url: 'v2.1/user',
            method: APIMethods.POST,
            data: { grade: account.grade, featureFlagTags: userData?.featureFlagTags },
            notificationSuccess: successMessage,
            endpoint: APIEndpoints.EDUCATION
          })
        );
        account.educationUserId = educationUser.data.userId;
        account.source = 'Dreamscape Dashboard';
        if (userData.utm) {
          account.utm = { ...userData.utm };
        }

        const playerObj = await dispatch(
          fetchApi({
            url: 'sw/player/registeruser',
            method: APIMethods.POST,
            data: account
          })
        );
        dispatch({
          type: CLASS_PLAYER_ADDED,
          payload: playerObj.data
        });
        playerObj.data.source = 'Dreamscape Dashboard';
        dispatch(trackPlayerRegistration(playerObj.data));
        dispatch(trackPlayerAdded());
        if (validateEmail(parentemail) && classCode != '') {
          const playerData = playerObj.data;
          const inviteData = {
            email: parentemail,
            teacher: {
              firstName: userData.firstName,
              lastName: userData.lastName,
              email: userData.email
            },
            player: {
              parentConnected: playerData.parentConnected,
              joinedWith: playerData.joinedWith,
              educationUserId: playerData.educationUserId,
              firstname: playerData.firstname,
              lastname: playerData.lastname,
              fullname: playerData.firstname + ' ' + playerData.lastname,
              username: playerData.username,
              grade: playerData.grade,
              scene: {
                tutorialStep: playerData.tutorialStep
              },
              _id: playerData._id
            }
          };
          dispatch(inviteParent(inviteData, classCode));
        }
      });
      dispatch(hide('modal'));
      dispatch(hide('spinner'));
      /**
       * For teachers with premium plans refresh token and update user
       */
      if (
        userData.premium &&
        userData.premium.isPremium &&
        userData.premium.type === Plan.PremiumPlus &&
        userData.userType === UserType.Teacher
      ) {
        dispatch(refreshToken());
        dispatch(getUser(login.token, false));
      }
      if (
        ![userTypes.districtAdmin, userTypes.schoolAdmin].includes(
          userData.userType
        )
      ) {
        dispatch(getClasses({ hideSpinner: true }));
      }
      if (
        userData.abVersion === 'B' &&
        userData.userType === userTypes.teacher &&
        !classState.currentClass.roster.length
      ) {
        const props: any = {
          type: MODAL_SUCCESS,
          title: `Congratulations, your ${playerNounLowerCase}s have been created`,
          text: `You can customzie the passages and questions your ${playerNounLowerCase}s will receive in the game by selecting an assignment for them.`,
          hideCloseButton: true,
          confirmButton: {
            handleClick: () => {
              dispatch(show('modal', { type: MODAL_ASSIGNMENT_OPTIONS }));
              dispatch(trackViewNewAssignmentPopup());
            },
            label: 'Next'
          }
        };
        dispatch(show('modal', props));
      }
      dispatch(getClass(classState.currentClass.code, { showSpinner: false }));
    } catch (error) {
      Debug.log(error);
      dispatch(hide('spinner'));
      dispatch(
        show('modal', {
          type: MODAL_DEFAULT,
          children: 'There was an error creating the accounts, please try again'
        })
      );
    }
  };
};

export const authenticatePlayer = (data: any) => {
  return fetchApi({
    url: 'sw/player/authenticate',
    method: APIMethods.POST,
    data,
    ignore401: true
  });
};

export const childLogin = (username: string, password: string) => {
  /**
   * all of this should be just one call and the rest should be handled in the back-end
   * @todo change back-end to handle this in just one api call
   */
  return (dispatch: any) => {
    const userData: any = store.getState().userData;
    //@ts-ignore
    const currentClass = store.getState().class.currentClass;
    dispatch(
      fetchApi({
        url: 'sw/player/authenticate',
        method: APIMethods.POST,
        spinnerMessage: 'Adding your player',
        data: {
          username,
          password
        }
      })
    )
      .then((authenticationResponse: any) => {
        return dispatch(
          fetchApi({
            url: 'sw/player',
            method: APIMethods.GET,
            authorizationHeader: authenticationResponse.data.token
          })
        );
      })
      .then((playerResponse: any) => {
        if (!currentClass.roster.length) {
          dispatch({
            type: CLASS_UPDATE_CLASS,
            payload: {
              registrationStep: 2
            }
          });
        }
        dispatch({
          type: CLASS_PLAYER_ADDED,
          payload: playerResponse.data
        });
        return dispatch(
          fetchApi({
            url: `sw/class/${currentClass.code}`,
            method: APIMethods.PUT,
            data: {
              email: userData.email,
              userType: userTypes.parent,
              userId: playerResponse.data._id
            }
          })
        );
      })
      .then(() => {
        dispatch(hide('modal'));
        const hideSpinnerAfter = true;
        dispatch(getClass(currentClass.code, { hideSpinnerAfter }));
      })
      .catch((error: any) => {
        let message = 'There was an internal error. Please, try again later';
        if (error.response) {
          if (error.response.status === 409) {
            message = 'This player already is in your account';
          } else if (error.response.status < 500) {
            message = 'Incorrect username or password';
          }
        }
        dispatch(
          show('snackbar', {
            variant: NotificationVariant.error,
            message
          })
        );
        Debug.log(error);
      })
      .finally(() => dispatch(hide('spinner')));
  };
};

export const removePlayerFromList = (
  playerId: string,
  classCode: string,
  playerPremiumStatus: boolean
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/class/removePlayer',
        method: APIMethods.POST,
        spinnerMessage: `Removing ${playerNounUppercase}`,
        hideSpinnerAfter: true,
        notificationSuccess: `${playerNounUppercase} removed`,
        errorMessage: `There was an error removing this ${playerNounLowerCase}, please try again later.`,
        data: {
          playerId,
          classCode,
          playerPremiumStatus
        }
      })
    )
      .then((response: any) => {
        dispatch({
          type: CLASS_PLAYER_REMOVED,
          payload: {
            _id: playerId
          }
        });
        dispatch(hide('modal'));
        dispatch(getClasses());
        dispatch(trackRemovePlayer());
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const switchMembership = (data: any) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'subscriptions/dreamscape/switch',
        method: APIMethods.POST,
        spinnerMessage: 'Transfering membership',
        hideSpinnerAfter: true,
        notificationSuccess: 'Membership transfered',
        errorMessage: `There was an error processing your request, please try again later.`,
        data
      })
    )
      .then((response: any) => {
        dispatch(getAllMembers());
        dispatch(hide('modal'));
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const cancelMembership = (data: any, endDate: any) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'subscriptions/dreamscape/cancelMembership',
        method: APIMethods.POST,
        spinnerMessage: 'Cancelling Membership',
        hideSpinnerAfter: true,
        successMessage: `The membership subscription has been cancelled. Your player will continue to have access to the Dreamscape membership until ${endDate}`,
        errorMessage: `There was an error processing your request, please try again later.`,
        data,
        autoHideDuration: 5000
      })
    )
      .then((response: any) => {
        if (data.isCancelPage) {
          dispatch(updateCancellationData(data.subscriptionId));
        } else {
          dispatch(getAllMembers());
        }
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};
