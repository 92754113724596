import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from '@material-ui/core';
import validator from 'validator';
import Button from '../../button/Button';
import ModalAbstract from './ModalAbstract';

const ModalAddAccount: React.FC = (props: any) => {
  const { show, handleHide, AddAccount, title } = props;
  const accountDetailsInitialState = {
    _id: '',
    firstName: '',
    lastName: '',
    email: ''
  };

  const [accountDetails, setAccountDetails] = useState(
    accountDetailsInitialState
  );
  const initialErrors = {
    firstName: false,
    lastName: false,
    email: false
  };
  const [accountDetailsError, setAccountDetailsError] = useState(initialErrors);
  function handleChange(evt: any) {
    setAccountDetails({
      ...accountDetails,
      [evt.target.name]: evt.target.value
    });
  }
  function handleValidation() {
    let isError = false;
    const errors = { ...accountDetailsError };
    if (validator.isEmpty(accountDetails.firstName)) {
      isError = true;
      errors.firstName = true;
    }
    if (validator.isEmpty(accountDetails.lastName)) {
      isError = true;
      errors.lastName = true;
    }

    if (!validator.isEmail(accountDetails.email)) {
      isError = true;
      errors.email = true;
    }
    if (isError) {
      setAccountDetailsError({
        ...accountDetailsError,
        ...errors
      });
    }
    if (!isError) {
      setAccountDetailsError(initialErrors);
      AddAccount(accountDetails);
      setAccountDetails(accountDetailsInitialState);
      handleHide();
    }
  }
  let renderView = null;
    renderView =  ( <Grid container justify="center" alignItems="center" spacing={4}>
      <Grid item lg={6} xs={12}>
        <FormControl fullWidth error={accountDetailsError.firstName}>
          <TextField
            value={accountDetails.firstName}
            label="First Name"
            name="firstName"
            onChange={handleChange}
          />
          <FormHelperText>
            {accountDetailsError.firstName && 'Please enter the first name'}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item lg={6} xs={12}>
        <FormControl fullWidth error={accountDetailsError.lastName}>
          <TextField
            value={accountDetails.lastName}
            label="Last Name"
            name="lastName"
            onChange={handleChange}
          />
          <FormHelperText>
            {accountDetailsError.lastName && 'Please enter the last name'}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={accountDetailsError.email}>
          <TextField
            value={accountDetails.email}
            label="Email"
            name="email"
            onChange={handleChange}
          />
          <FormHelperText>
            {accountDetailsError.email && 'Email is not valid'}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>);

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>{title || 'Add Teacher'}</DialogTitle>
      <DialogContent
        style={{
          padding: '20px'
        }}
      >
        {renderView}
     </DialogContent>
      <DialogActions>
        <Button onClick={handleValidation}>Add</Button>
        <Button onClick={handleHide} primary>
          Close
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalAddAccount;
