import React, { useState } from 'react';
import { Grid, Container, TextField, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import DreamscapeBanner from '../../components/ds-banner/DreamscapeBanner';
import colors from '../../styles/colors';
import Button from '../../components/button/Button';
import {
  sendResetEmail,
  resetPassword
} from '../../redux/actions/user-actions';
import { useDispatch } from 'react-redux';
import { show, hide } from 'redux-modal';
import { MODAL_DEFAULT } from '../../constants/modals';
import validator from 'validator';
import zxcvbn from 'zxcvbn';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  message: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    width: '100%'
  },
  success: {
    color: colors.successDark
  },
  error: {
    color: colors.error
  }
}));

const ForgotPassword: React.FC<any> = (props: any) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const showModal = (msg: string) => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        children: () => <Typography component="p">{msg}</Typography>
      })
    );
  };

  const handleSendResetEmailClick = async () => {
    if (!validator.isEmail(email)) {
      showModal('Please, type a valid email.');
      return;
    }
    dispatch(show('spinner', { text: 'Sending the reset email' }));
    try {
      await sendResetEmail(email);
      dispatch(hide('spinner'));
      setSent(true);
    } catch (error) {
      dispatch(hide('spinner'));
      showModal(`No accounts found for ${email}`);
    }
  };

  const handleSubmitPasswordReset = async () => {
    if (password !== confirmPassword) {
      showModal('The passwords do not match');
      return;
    }
    const passwordStrenght = zxcvbn(password);
    if (passwordStrenght.score === 0) {
      showModal(`
				${passwordStrenght.feedback.warning}.
				${passwordStrenght.feedback.suggestions[0]}
			`);
      return;
    }
    dispatch(show('spinner'));
    try {
      await resetPassword(password, props.match.params.token);
      dispatch(hide('spinner'));
      setSent(true);
    } catch (error) {
      dispatch(hide('spinner'));
      setError(true);
    }
  };

  const renderRequestEmail = () => {
    if (sent) {
      return (
        <>
          <Typography
            component="h3"
            className={clsx(classes.success, classes.message)}
          >
            An email with a reset link has been sent to you.
          </Typography>
          <Typography
            component="h3"
            className={clsx(classes.success, classes.message)}
          >
            Please check your inbox.
          </Typography>
        </>
      );
    }
    return (
      <>
        <Grid item xs={12}>
          <TextField
            name="email"
            type="email"
            onChange={(e: any) => setEmail(e.target.value)}
            label="Your Email"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item container justify="center">
          <Button green marginTop={20} onClick={handleSendResetEmailClick}>
            Send Reset Email
          </Button>
        </Grid>
      </>
    );
  };

  const renderResetPassword = () => {
    if (sent) {
      return (
        <>
          <Typography
            component="h3"
            className={clsx(classes.success, classes.message)}
          >
            Your password has been reset.
          </Typography>
          <Button green marginTop={20} onClick={() => dispatch(push('/login'))}>
            Login
          </Button>
        </>
      );
    } else if (error) {
      return (
        <>
          <Typography
            component="h3"
            className={clsx(classes.error, classes.message)}
          >
            Oops! Looks like this reset link is either expired or invalid.
          </Typography>
          <Button
            green
            marginTop={20}
            onClick={() => dispatch(push('/forgot-password'))}
          >
            Request a New Link
          </Button>
        </>
      );
    }
    return (
      <>
        <Grid item xs={12}>
          <TextField
            name="password"
            type="password"
            onChange={(e: any) => setPassword(e.target.value)}
            label="Enter a new password"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <TextField
            name="confirmPassword"
            type="password"
            onChange={(e: any) => setConfirmPassword(e.target.value)}
            label="Confirm password"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item container justify="center">
          <Button green marginTop={20} onClick={handleSubmitPasswordReset}>
            Submit Password Reset
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Grid container>
      <DreamscapeBanner title="Reset your password" />
      <Container
        maxWidth="sm"
        style={isMobile ? { marginTop: 60, height: '50%' } : {}}
      >
        <Grid container justify="center">
          {props.match.params.token
            ? renderResetPassword()
            : renderRequestEmail()}
        </Grid>
      </Container>
      <Grid item container justify={'center'} alignItems={'flex-end'}>
        <span style={{ color: '#999', marginBottom: '40px' }}>
          Don't have an account? &nbsp;
          <Link
            to="register"
            title="Sign Up!"
            style={{
              color: colors.primary,
              fontWeight: 600,
              textDecoration: 'none'
            }}
          >
            Sign Up.
          </Link>
        </span>
      </Grid>
    </Grid>
  );
};

export default withRouter(ForgotPassword);
