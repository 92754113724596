export const MODAL_DEFAULT = 'MODAL DEFAULT';
export const MODAL_SELECT_CLASS = 'MODAL SELECT CLASS';
export const MODAL_ADD_STUDENT = 'MODAL ADD STUDENT';
export const MODAL_ADD_CLASS = 'MODAL ADD CLASS';
export const MODAL_EDIT_STUDENT = 'MODAL EDIT STUDENT';
export const MODAL_CLEVER = 'MODAL CLEVER';
export const MODAL_GOOGLE_CLASSROOM_IMPORT = 'MODAL GOOGLE CLASSROOM IMPORT';
export const MODAL_GOOGLE_CLASSROOM_SYNC = 'MODAL GOOGLE CLASSROOM SYNC';
export const MODAL_GOOGLE_ACCOUNT_INFO = 'MODAL GOOGLE ACCOUNT INFO';
export const MODAL_ADD_ACCOUNT = 'MODAL ADD ACCOUNT';
export const MODAL_SUCCESS = 'MODAL SUCCESS';
export const MODAL_TRAINING = 'MODAL TRAINING';
export const MODAL_PASTE_CSV = 'MODAL PASTE CSV';
export const MODAL_INVITE_ADMIN = 'MODAL INVITE ADMIN';
export const MODAL_VIEW_ADMINS = 'MODAL_VIEW_ADMINS';
export const MODAL_REMOVE_PLAYER = 'MODAL_REMOVE_PLAYER';

export const MODAL_CHUCK_TASKS = 'MODAL CHUCK TASKS';

export const MODAL_CREATE_ASSIGNMENT = 'MODAL CREATE ASSIGNMENT';
export const MODAL_ASSIGNMENT_OPTIONS = 'MODAL ASSIGNMENT OPTIONS';
export const MODAL_CLASS_MISSION_REPORT = 'MODAL_CLASS_MISSION_REPORT';
export const MODAL_NONPREMIUM_CLASS_MISSION = 'MODAL_NONPREMIUM_CLASS_MISSION';
export const MODAL_ASSIGNMENT_TUTORIAL = 'MODAL ASSIGNMENT TUTORIAL';
export const MODAL_ASSIGNMENT_CREATED = 'MODAL ASSIGNMENT CREATED';
export const MODAL_SELECT_ASSIGNMENT_TYPE = 'MODAL SELECT ASSIGNMENT TYPE';
export const MODAL_CREATE_TEST_PREP = 'MODAL CREATE TEST PREP';
export const MODAL_ASSIGN_MY_CONTENT = 'MODAL ASSIGN MY CONTENT';
export const MODAL_TEST_PREP_FEEDBACK = 'MODAL TEST PREP FEEDBACK';
export const MODAL_QUESTION_BREAKDOWN = 'MODAL QUESTION BREAKDOWN';
export const MODAL_ARCHIVE_CLASSES = 'MODAL ARCHIVE CLASSES';
export const MODAL_EDIT_CLASS = 'MODAL EDIT CLASS';
export const MODAL_SEND_PARENT_INVITE = 'MODAL SEND PARENT INVITE';
export const MODAL_ASSIGN_REWARDS = 'MODAL ASSIGN REWARDS';

export const MODAL_ASSIGN_MEMBERSHIP = 'MODAL ASSIGN MEMBERSHIP';
export const MODAL_CANCEL_MEMBERSHIP = 'MODAL CANCEL MEMBERSHIP';
export const MODAL_CHOOSE_CANCELLATION = 'MODAL CHOOSE CANCELLATION';
export const MODAL_PAUSE_MEMBERSHIP = 'MODAL PAUSE MEMBERSHIP';

export const MODAL_SKILL_REPORT = 'MODAL SKILL REPORT';
export const MODAL_CHANGE_PASSWORD = 'MODAL CHANGE PASSWORD';

export const MODAL_NOTIFICATION = 'MODAL NOTIFICATION';

export const MODAL_SELECT_DATE_RANGE = 'MODAL SELECT DATE RANGE';
export const MODAL_SELECT_MONTH_YEAR = 'MODAL SELECT MONTH YEAR';
export const MODAL_UPDATE_ASSIGNMENT = 'MODAL UPDATE ASSIGNMENT';

export const MODAL_PARENT_MEMBERSHIP = 'MODAL PARENT MEMBERSHIP';

export const MODAL_TERMS_AND_CONDITIONS = 'MODAL TERMS AND CONDITIONS';

export const MODAL_CANCEL_SUBSCRIPTION = 'MODAL CANCEL SUBSCRIPTION';
export const MODAL_PREMIUM_UPGRADE_REDIRECT = 'MODAL PREMIUM UPGRADE REDIRECT';
export const MODAL_PREMIUM_PLUS_LEGACY_INFO = 'MODAL PREMIUM PLUS LEGACY INFO';
export const MODAL_TEACHER_ASSIGN_MEMBERSHIPS =
  'MODAL TEACHER ASSIGN MEMBERSHIPS';
export const MODAL_MEMBERSHIP_PURCHASE_SUCCESS =
  'MODAL MEMBERSHIP PURCHASE SUCCESS';
export const MODAL_UPGRADE_SUCCESS = 'MODAL UPGRADE SUCCESS';
export const MODAL_UPGRADE_PREVIEW = 'MODAL UPGRADE PREVIEW';
export const MODAL_PLAYER_BREAKDOWN_UPGRADE = 'MODAL PLAYER BREAKDOWN UPGRADE';
export const MODAL_SKILLS_PLAYER_BREAKDOWN_UPGRADE =
  'MODAL SKILLS PLAYER BREAKDOWN UPGRADE';
/**
 * User Generated Content
 */
export const MODAL_ASSIGN_USER_GENERATED_CONTENT =
  'MODAL ASSIGN USER GENERATED CONTENT';
export const MODAL_CREATE_PRE_MADE_ASSIGNMENT =
  'MODAL CREATE PRE MADE ASSIGNMENT';
export const MODAL_UGC_CREATE_TEST_PREP = 'MODAL UGC CREATE TEST PREP';
export const MODAL_QUESTION_BANK = 'MODAL QUESTION BANK';
export const MODAL_USER_GENERATED_ASSIGNMENT_EDIT =
  'MODAL USER GENERATED ASSIGNMENT EDIT';
export const MODAL_USER_GENERATED_ASSIGNMENT_DELETE_PROMPT =
  'MODAL USER GENERATED ASSIGNMENT DELETE PROMPT';
export const MODAL_PLACEMENT_TEST_REASSIGN = 'MODAL PLACEMENT TEST REASSIGN';

export const MODAL_NEW_LE_OPT_IN = 'MODAL NEW LE OPT IN';
