import React, { PropsWithChildren, useState } from 'react';
import ModalAbstract from '../ModalAbstract';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from '../../../../features/assignment/common/button/Button';
import colors from '../../../../styles/colors';
import { links } from '../../../../constants/links';

export interface TermsAndConditionModalProps {
  show?: boolean;
  content?: string;
  agreementText?: string;
  buttonText?: string;
  title?: string;
  handleHide?: any;
  declineButton?: boolean;
  onClick: (...args: any) => any;
}

const useStyles = makeStyles(() => ({
  actions: {
    padding: '.7rem 1rem'
  },
  alignMiddle: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const TermsAndConditionModal: React.FC<TermsAndConditionModalProps> = (
  props: PropsWithChildren<TermsAndConditionModalProps>
) => {
  const {
    show,
    content,
    title,
    buttonText,
    agreementText,
    declineButton,
    handleHide
  } = props;
  const classes = useStyles();

  const [isAgreed, setIsAgreed] = useState<boolean>(false);

  function handleActions(isAccepted: boolean) {
    props.onClick(isAccepted);
    if (handleHide) {
      handleHide();
    }
  }
  const { blue } = colors.assignment;
  return (
    <ModalAbstract show={show}>
      <DialogTitle>{title || 'Terms of Use'}</DialogTitle>
      <DialogContent dividers>
        {content ? (
          <span
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : (
          <>
            <p>
              By submitting your content to Eyeread, you acknowledge that you
              agree to Eyeread’s Terms of Service and Community Guidelines.
            </p>
            <p>
              Please be sure not to violate others’ copyright or privacy rights.{' '}
              <a target={'_blank'} href={links.termsAndConditions}>
                Learn more
              </a>
            </p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container className={classes.actions} justify={'space-between'}>
          <div className={classes.alignMiddle}>
            <input
              onChange={() => setIsAgreed(!isAgreed)}
              checked={isAgreed}
              type={'checkbox'}
            />
            &nbsp;
            <span>
              {agreementText || 'I agree with the terms and conditions'}
            </span>
          </div>
          <div className={classes.alignMiddle}>
            <Button
              onClick={() => handleActions(true)}
              disabled={!isAgreed}
              variant="filled"
              textColor="white"
              color={blue}
            >
              {buttonText || 'Continue'}
            </Button>
            &nbsp;
            {declineButton && (
              <Button
                onClick={() => handleActions(false)}
                variant="filled"
                textColor="white"
                color={'red'}
              >
                {'Decline'}
              </Button>
            )}
          </div>
        </Grid>
      </DialogActions>
    </ModalAbstract>
  );
};

export default TermsAndConditionModal;
