import React from 'react';
import {
  Theme,
  DialogContent,
  DialogTitle,
  Typography,
  Button as MaterialButton,
  makeStyles,
  Grid,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { show as showModal } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import AdaptiveIcon from '../../../assets/icons/assignment/adaptive_icon.png';
import SkillIcon from '../../../assets/icons/assignment/skill_icon.png';
import TestIcon from '../../../assets/icons/assignment/test_icon.png';
import colors from '../../../styles/colors';
import { assignmentTypes } from '../../../types/assignments/assignment-types';
import {
  MODAL_CREATE_ASSIGNMENT,
  MODAL_CREATE_TEST_PREP,
  MODAL_ASSIGNMENT_OPTIONS
} from '../../../constants/modals';
import { isMobile } from 'react-device-detect';
import { playerNounLowerCase } from '../../../utils/userNoun';

interface ModalAssignmentOptionsProps {
  show: () => void;
  handleHide: () => void;
  assignmentId: string;
  assignmentTitle: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    marginBottom: 30,
    paddingRight: 0
  },
  buttonIcon: {
    maxWidth: '80%'
  },
  buttonTitle: {
    fontSize: '18px !important',
    marginLeft: 15,
    textTransform: 'none'
  },
  buttonDescription: {
    color: '#404040',
    fontSize: '15px !important',
    marginTop: 10,
    textTransform: 'none'
  },
  buttonGridIcon: {
    padding: '10px 0'
  },
  buttonGridText: {
    padding: '10px 20px 10px 0',
    textAlign: 'left'
  },
  tag: {
    borderRadius: 5,
    color: colors.white,
    fontSize: '13px !important',
    padding: 10,
    position: 'absolute',
    right: -10,
    textTransform: 'none',
    top: -20,
    zIndex: 99
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  instructions: {
    fontSize: '20px !important',
    marginBottom: 20,
    textAlign: 'center'
  }
}));

const ModalAssignmentOptions: React.FC<ModalAssignmentOptionsProps> = (
  props: ModalAssignmentOptionsProps
) => {
  const { show, handleHide } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpenModal = (assignmentType?: assignmentTypes) => {
    const props: any = { type: MODAL_CREATE_ASSIGNMENT, assignmentType };
    if (assignmentType === assignmentTypes.testPrep) {
      props.type = MODAL_CREATE_TEST_PREP;
    }
    dispatch(showModal('modal', props));
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide} maxWidth="md">
      <DialogTitle>
        Create Assignment
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Typography className={classes.instructions}>
          How would you like your players to learn?
        </Typography>

        <MaterialButton
          size="large"
          variant="outlined"
          className={classes.buttonWrapper}
          onClick={handleHide}
        >
          {!isMobile && (
            <div className={classes.tag} style={{ background: colors.blue }}>
              Easiest way to get started
            </div>
          )}
          <Grid container>
            <Grid item md={1} className={classes.buttonGridIcon}>
              <img
                alt="Genres"
                src={AdaptiveIcon}
                className={classes.buttonIcon}
              />
            </Grid>
            <Grid
              item
              md={11}
              container
              alignItems="center"
              className={classes.buttonGridText}
            >
              <Typography className={classes.buttonTitle}>
                Let the adaptive game give {playerNounLowerCase}s content based on their
                reading level
              </Typography>
            </Grid>
          </Grid>
        </MaterialButton>

        <MaterialButton
          size="large"
          variant="outlined"
          className={classes.buttonWrapper}
          onClick={() => handleOpenModal(assignmentTypes.skills)}
        >
          <Grid container>
            <Grid item md={1} className={classes.buttonGridIcon}>
              <img
                alt="Skills"
                src={SkillIcon}
                className={classes.buttonIcon}
              />
            </Grid>
            <Grid
              item
              md={11}
              container
              alignItems="center"
              className={classes.buttonGridText}
            >
              <Typography className={classes.buttonTitle}>
                Choose a skill-based assignment for your class to practice what
                they've been learning
              </Typography>
            </Grid>
          </Grid>
        </MaterialButton>

        <MaterialButton
          size="large"
          variant="outlined"
          className={classes.buttonWrapper}
          onClick={() => handleOpenModal(assignmentTypes.testPrep)}
        >
          <Grid container>
            <Grid item md={1} className={classes.buttonGridIcon}>
              <img
                alt="Test Prep"
                src={TestIcon}
                className={classes.buttonIcon}
              />
            </Grid>
            <Grid
              item
              md={11}
              container
              alignItems="center"
              className={classes.buttonGridText}
            >
              <Typography className={classes.buttonTitle}>
                Choose a test prep assignment to give them content aligned to a
                standardized reading test
              </Typography>
            </Grid>
          </Grid>
        </MaterialButton>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalAssignmentOptions;
