import React, { useState, useEffect, createRef } from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import View from '../../components/view/View';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { useDispatch, useSelector } from 'react-redux';
import borders from '../../styles/borders';
import useQueryParams from '../../hooks/UseQueryParams';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
    marginBottom: 20
  },
  listItem: {
    background: '#fcfcfc',
    border: '1px solid #dcdcdc',
    borderRadius: borders.borderRadius,
    marginBottom: 20,
    padding: 20
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 500,
    margin: '10px 0'
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: 20
  }
}));

interface DescriptionItem {
  id: number;
  name: string;
  description: string;
  teacherDescription: string;
  grades: string;
}

const ContentPackDecriptionsView: React.FC = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [descriptionData, setDescriptionData] = useState<DescriptionItem[]>([]);
  const userData = useSelector((state: any) => state.userData);
  const elementRefs = descriptionData.reduce((acc: any, value: any) => {
    acc[value.id] = createRef();
    return acc;
  }, {});

  const scrollToElement = (ref: any) => {
    if (ref.current) {
      setTimeout(() => {
        $('main').animate({ scrollTop: ref.current.offsetTop });
      }, 500);
    }
  };

  useEffect(() => {
    if (queryParams.descriptionId && descriptionData.length > 0) {
      if (elementRefs[queryParams.descriptionId]) {
        scrollToElement(elementRefs[queryParams.descriptionId]);
      }
    }
  }, [elementRefs]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        fetchApi({
          spinnerMessage: 'Loading Data',
          hideSpinnerAfter: true,
          method: APIMethods.GET,
          endpoint: APIEndpoints.EDUCATION,
          url: `v2.1/assignment/content-pack-descriptions?region=${userData.location.region}`
        })
      );
      setDescriptionData(response.data);
    };
    fetchData();
  }, []);

  return (
    <View title="Content Pack Descriptions">
      {descriptionData.map(item => {
        return (
          <Paper className={classes.paper} ref={elementRefs[item.id]}>
            <Typography variant="h2" className={classes.title}>
              {item.name} (Grades {item.grades})
            </Typography>
            {item.teacherDescription && (
              <>
                <Typography variant="h3" className={classes.subtitle}>
                  Description
                </Typography>
                <Typography>
                  {ReactHtmlParser(item.teacherDescription)}
                </Typography>
              </>
            )}
          </Paper>
        );
      })}
    </View>
  );
};

export default ContentPackDecriptionsView;
