import { useDispatch } from 'react-redux';
import moment from 'moment';
import React, { useState } from 'react';
import { show as snackbar } from 'redux-modal/lib/actions';
import { AssignmentActionsAsync } from '../pages/edit/state/assignment/assignment-actions.async';
import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  Select
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import colors from '../../../styles/colors';
import CustomInput from './input/CustomInput';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const ClassSelection = (props: any) => {
  const { userClasses, handleState } = props;

  const mappedClasses = [
    ...userClasses.map((userClass: any) => {
      return {
        ...userClass,
        roster: [
          ...userClass.roster.map((player: any) => {
            return { ...player, isSelected: false };
          })
        ],
        isSelected: false,
        startDate: new Date(),
        endDate: moment(new Date())
          .add(7, 'days')
          .toDate()
      };
    })
  ];
  const [state, setState] = useState({
    classes: [...mappedClasses],
    allStudents: false,
    allClasses: false,
    sameTime: false
  });

  React.useEffect(() => {
    handleState(state);
  }, [state]);

  function handleDateChange(id: string, property: string, evt: any) {
    const newClassData = [...state.classes];
    newClassData.forEach(currentClass => {
      if (state.sameTime) {
        currentClass[property] = evt;
      } else if (currentClass._id === id) {
        currentClass[property] = evt;
      }
    });
    setState({ ...state, classes: [...newClassData] });
  }

  function handleRadioChange(evt: React.ChangeEvent<any>) {
    let newClassData = [...state.classes];
    // @ts-ignore
    if (evt.target.name === 'allClasses') {
      newClassData.forEach(currentClass => {
        // @ts-ignore
        currentClass.isSelected = !state[evt.target.name];
      });
    }
    // @ts-ignore
    if (evt.target.name === 'allStudents') {
      newClassData.forEach(currentClass => {
        // @ts-ignore
        currentClass.isSelectAllPlayers = !state[evt.target.name];
        currentClass.roster.forEach((player: any) => {
          // @ts-ignore
          player.isSelected = !state[evt.target.name];
        });
      });
    }
    setState({
      ...state,
      classes: newClassData,
      // @ts-ignore
      [evt.target.name as string]: !state[evt.target.name]
    });
  }

  /**
   * Handles selection of players within a class
   * @param classId - id of class
   * @param playerId - all indicates all players in the class need to be selected
   * @param evt - check event
   */
  function handlePlayerSelection(classId: string, playerId: string, evt: any) {
    const newData = [...state.classes];
    let isAllStudentsSelected = true;
    newData.forEach(currentClass => {
      if (currentClass._id === classId) {
        if (playerId === 'all') {
          currentClass.isSelectAllPlayers = evt.target.checked;
        } else {
          currentClass.isSelectAllPlayers = false;
        }
        let isAllCurrentClassStudentsSelected = true;
        currentClass.roster.forEach((player: any) => {
          if (playerId === 'all') {
            player.isSelected = evt.target.checked;
          } else if (player.userId === playerId) {
            player.isSelected = evt.target.checked;
          }
          if (!player.isSelected) {
            isAllCurrentClassStudentsSelected = false;
            isAllStudentsSelected = false;
          }
        });
        currentClass.isSelectAllPlayers = isAllCurrentClassStudentsSelected;
      } else {
        currentClass.roster.forEach((player: any) => {
          if (!player.isSelected) {
            isAllStudentsSelected = false;
          }
        });
      }
    });

    setState({
      ...state,
      classes: [...newData],
      allStudents: isAllStudentsSelected
    });
  }

  function handleClassSelection(
    id: string,
    evt: React.ChangeEvent<HTMLInputElement>
  ) {
    const newClassData = [...state.classes];
    let isAllClassesSelected = true;
    newClassData.forEach(currentClass => {
      if (currentClass._id === id) {
        currentClass.isSelected = evt.target.checked;
      }
      if (!currentClass.isSelected) {
        isAllClassesSelected = false;
      }
    });
    setState({
      ...state,
      classes: [...newClassData],
      allClasses: isAllClassesSelected
    });
  }

  return (
    <div>
      <div>
        {userClasses.length === 0 ? (
          <Grid container>
            <Grid item xs={12}>
              <b>No classes to assign to...</b>
            </Grid>
          </Grid>
        ) : (
          <Grid container style={{ color: colors.assignment.text }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <b>Send to</b>
                  <Grid container style={{ margin: '1em 0' }}>
                    <Grid item xs={3}>
                      <Radio
                        checked={state.allClasses}
                        color={'primary'}
                        readOnly={true}
                        onClick={handleRadioChange}
                        name={'allClasses'}
                      />
                      &nbsp;
                      <span>All Active Classes</span>
                    </Grid>
                    <Grid item xs={9}>
                      <Radio
                        name={'allStudents'}
                        readOnly={true}
                        onClick={handleRadioChange}
                        checked={state.allStudents}
                        color={'primary'}
                      />
                      &nbsp;
                      <span>All Players</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container alignItems={'center'}>
                    <Grid item xs={6}>
                      <b>Start Date</b>
                    </Grid>
                    <Grid item xs={6}>
                      <b>End Date</b>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '1em 0' }}>
                      <Radio
                        name={'sameTime'}
                        checked={state.sameTime}
                        readOnly={true}
                        onClick={handleRadioChange}
                        color={'primary'}
                      />
                      &nbsp;
                      <span>Same Time</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems={'center'}
                spacing={2}
                justify={'space-between'}
              >
                {state.classes.map((currentClass: any) => {
                  return (
                    <React.Fragment key={currentClass._id}>
                      <Grid item xs={6}>
                        <Grid container alignItems={'center'} spacing={2}>
                          <Grid item xs={3} style={{ marginTop: '25px' }}>
                            <Checkbox
                              color={'primary'}
                              checked={currentClass.isSelected}
                              onChange={evt =>
                                handleClassSelection(currentClass._id, evt)
                              }
                              id={'className'}
                            />
                            <span style={{ marginLeft: '1em' }}>
                              {currentClass.name}
                            </span>
                          </Grid>
                          <Grid item xs={9} style={{ marginTop: '25px' }}>
                            <Select
                              multiple
                              disabled={!currentClass.isSelected}
                              value={currentClass.roster.filter(
                                (player: any) => player.isSelected
                              )}
                              renderValue={(selected: any) => {
                                return selected.map(
                                  (player: any, index: number) => {
                                    const name =
                                      player.firstname + ' ' + player.lastname;
                                    const playerName =
                                      selected.length - 1 === index
                                        ? name
                                        : name + ',';
                                    return (
                                      <span key={player.userId}>
                                        {playerName}
                                      </span>
                                    );
                                  }
                                );
                              }}
                              fullWidth={true}
                              input={<CustomInput />}
                            >
                              <MenuItem value={'none'}>
                                Select Players (
                                {parseInt(currentClass.roster.length)})
                              </MenuItem>
                              <MenuItem value={'all'}>
                                <Checkbox
                                  checked={currentClass.isSelectAllPlayers}
                                  onChange={evt =>
                                    handlePlayerSelection(
                                      currentClass._id,
                                      'all',
                                      evt
                                    )
                                  }
                                  color={'primary'}
                                />
                                <ListItemText primary={'Select All'} />
                              </MenuItem>
                              {currentClass.roster.map((player: any) => {
                                return (
                                  <MenuItem
                                    key={player.educationUserId}
                                    value={player.educationUserId}
                                  >
                                    <Checkbox
                                      value={player.isSelected}
                                      checked={player.isSelected}
                                      onChange={evt =>
                                        handlePlayerSelection(
                                          currentClass._id,
                                          player.userId,
                                          evt
                                        )
                                      }
                                      color={'primary'}
                                    />
                                    <ListItemText primary={player.username} />
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems={'center'} spacing={2}>
                          <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                label="Start Date"
                                format="MM/dd/yyyy"
                                minDate={new Date()}
                                disabled={!currentClass.isSelected}
                                value={currentClass.startDate}
                                onChange={evt => {
                                  handleDateChange(
                                    currentClass._id,
                                    'startDate',
                                    evt
                                  );
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time'
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disabled={!currentClass.isSelected}
                                margin="normal"
                                label="End Date"
                                format="MM/dd/yyyy"
                                value={currentClass.endDate}
                                minDate={currentClass.startDate}
                                allowKeyboardControl={false}
                                onChange={evt =>
                                  handleDateChange(
                                    currentClass._id,
                                    'endDate',
                                    evt
                                  )
                                }
                                KeyboardButtonProps={{
                                  'aria-label': 'change time'
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default ClassSelection;
