import React, { useState, useEffect } from 'react';
import Table from '../../../components/table/Table';
import {
  makeStyles,
  DialogTitle,
  IconButton,
  DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AxiosError, AxiosResponse } from 'axios';
import { fetchApi } from '../../../redux/actions/fetch-actions';
import { useDispatch } from 'react-redux';
import { APIMethods, APIEndpoints } from '../../../types/fetch/fetch-types';
import ModalAbstract from '../../../components/popups/modals/ModalAbstract';

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

interface ClassMissionFullReportI {
  playerName: string;
  questionsAnswered: number;
  correct: number;
  incorrect: number;
  accuracy: number;
}

type ModalClassMissionReportProps = {
  flag?: Boolean;
  show: () => void;
  handleHide: () => void;
  currentClassMissionId: number;
  date: string;
};

const ModalClassMissionReport: React.FC<ModalClassMissionReportProps> = (
  props: ModalClassMissionReportProps
) => {
  const { show, handleHide } = props;
  const styles = useStyles();
  const [rowHighlight, setRowHighlight] = useState(String);
  const [classMissionFullReport, setClassMissionFullReport] = useState<
    ClassMissionFullReportI[]
  >([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.currentClassMissionId) {
      fetchClassFullMissionReport(props.currentClassMissionId);
    }
  }, [props.currentClassMissionId]);

  function fetchClassFullMissionReport(currentClassMissionId: number) {
    dispatch(
      fetchApi({
        url: `class-missions/${currentClassMissionId}/player-report`,
        endpoint: APIEndpoints.DASHBOARD,
        method: APIMethods.GET
      })
    )
      .then((result: AxiosResponse) => {
        let reportTotal = result.data.data.allPlayersTotal;
        reportTotal['playerName'] = 'All Players';
        let totalReportData = [reportTotal, ...result.data.data.playersReport];
        setClassMissionFullReport(totalReportData);
      })
      .catch((err: AxiosError) => {
        console.error(err);
      });
  }

  return (
    <ModalAbstract show={show} handleHide={handleHide} maxWidth="lg">
      <DialogTitle>
        Full report for Class Mission - {props.date}
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table
          classMissionRowHighlight={rowHighlight}
          columns={[
            {
              Header: 'Player',
              accessor: 'playerName',

              Cell: (row: { value: string }) => {
                if (row.value == 'All Players') {
                  setRowHighlight('All Players');
                }
                return <span> {row.value} </span>;
              }
            },
            {
              Header: 'Questions answered',
              accessor: 'questionsAnswered'
            },
            {
              Header: 'Correct Answers',
              accessor: 'correct'
            },
            {
              Header: 'Incorrect Answers',
              accessor: 'incorrect'
            },
            {
              Header: 'Accuracy',
              accessor: 'accuracy',
              Cell: (props: { value: number }) => {
                if (props.value > 0) {
                  return <span>{props.value + '%'}</span>;
                } else {
                  return <span>-</span>;
                }
              }
            }
          ]}
          data={classMissionFullReport}
        />
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalClassMissionReport;
