import {
  CLEVER_GET_STUDENTS,
  CLEVER_SELECT_STUDENT,
  CLEVER_GET_STUDENTS_IN_PROGRESS
} from '../types/clever/clever-action-types';
import { CleverImportStudentPayload } from '../../types/clever/clever-import-request-type';
import { CleverMergeStudentPayload } from '../../types/clever/clever-merge-request-type';
import { fetchApi } from './fetch-actions';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';

export const getCleverStudents = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEVER_GET_STUDENTS_IN_PROGRESS });
    dispatch(
      fetchApi({
        url: 'clever/students',
        method: APIMethods.GET,
        spinnerMessage: 'Retrieving your students from Clever',
        hideSpinnerAfter: true,
        errorMessage:
          'There was a problem retrieving your students list. If the problem persists, please contact our support team'
      })
    )
      .then((response: any) => {
        dispatch({
          type: CLEVER_GET_STUDENTS,
          payload: response.data
        });
      })
      .catch((error: any) => Debug.log(error));
  };
};

export const selectCleverStudent = (studentId: string) => {
  return {
    type: CLEVER_SELECT_STUDENT,
    payload: {
      id: studentId
    }
  };
};

export const importCleverStudent = (account: CleverImportStudentPayload) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      return dispatch(
        fetchApi({
          url: 'v2.1/user',
          method: APIMethods.POST,
          data: { grade: account.grade, classCode: account.classCode },
          endpoint: APIEndpoints.EDUCATION
        })
      )
        .then((educationUser: any) => {
          account.educationUserId = educationUser.data.userId;
          return dispatch(
            fetchApi({
              url: 'clever/import',
              method: APIMethods.POST,
              data: account
            })
          );
        })
        .then(() => resolve({}))
        .catch((error: any) => reject(error));
    });
  };
};

export const mergeCleverStudent = (account: CleverMergeStudentPayload) => {
  return (dispatch: any) => {
    return dispatch(
      fetchApi({
        url: 'clever/merge',
        method: APIMethods.POST,
        data: account
      })
    );
  };
};
