import { Middleware } from 'redux';
import { RootState } from '../store';
import { teacherRoutes } from '../../constants/routes';

const redirectionMiddlewareMap: Record<string, string | Function> = {
  // 'OLD URL': 'NEW URL',
  [teacherRoutes.teacherPremiumPlan]: (state: RootState) => {
    window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/premium?accessToken=${state.login.token}`;
  }
};

const getNewRoute = (path: string) => {
  let newRoute = redirectionMiddlewareMap[path];
  if (newRoute) return newRoute;

  newRoute =
    redirectionMiddlewareMap[
      Object.keys(redirectionMiddlewareMap).find(p => path.includes(p)) || ''
    ];

  return newRoute;
};

const redirectionMiddleware: Middleware = api => next => action => {
  const state = api.getState();
  const enableRedirectionMiddleware =
    state.featureFlag?.oldDashboardAccessConfig?.enableRedirectionMiddleware;
  const path = action.payload?.location?.pathname;
  const newRoute = path ? getNewRoute(path) : null;

  if (
    enableRedirectionMiddleware &&
    action.type === '@@router/LOCATION_CHANGE' &&
    newRoute
  ) {
    console.log(`[redirectionMiddleware] Redirecting ${path} to ${newRoute}`);

    if (typeof newRoute === 'string') {
      window.location.href = newRoute;
      return;
    }

    if (typeof newRoute === 'function') {
      return newRoute(api.getState());
    }
  }

  next(action);
};

export default redirectionMiddleware;
