import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { fetchApi } from './fetch-actions';
import { SkillReportRequest } from '../../types/reports/skill-report-types';

export function getSkillReport(request: SkillReportRequest) {
  return fetchApi({
    url: 'report/skills',
    endpoint: APIEndpoints.EDUCATION,
    method: APIMethods.POST,
    data: request,
    spinnerMessage: 'Loading data',
    hideSpinnerAfter: true
  });
}

export function getSkillReportBySkillId(
  skillId: number,
  request: SkillReportRequest
) {
  return fetchApi({
    url: `report/skills/${skillId}`,
    endpoint: APIEndpoints.EDUCATION,
    method: APIMethods.POST,
    data: request,
    spinnerMessage: 'Loading data',
    hideSpinnerAfter: true
  });
}
