import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { IClassRoster } from '../../types/state/class-type';
import {
  chuckToggleGift,
  chuckHideBubble,
  chuckShowBubble,
  chuckWasInteracted
} from '../../redux/actions/chuck-actions';
import ChuckIcon from './ChuckIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateDateStart,
  completeTodoList,
  updateTodoListItem
} from '../../redux/actions/class-actions';
import { userTypes } from '../../constants/users';
import { withRouter } from 'react-router';
import { show } from 'redux-modal';
import { MODAL_CHUCK_TASKS } from '../../constants/modals';
import ChuckBubble from './ChuckBubble';
import ChuckState from '../../types/state/chuck-state';
import { isMobile, isIOS } from 'react-device-detect';
import clsx from 'clsx';

import ChuckIdle from '../../assets/chuck/chuck-web-idle.gif';
import chuckGift from '../../assets/chuck/chuck-web-gift.gif';

const chuckImages = {
  idle: ChuckIdle,
  gift: chuckGift
};

const useStyles = makeStyles(() => ({
  wrapper: {
    background: '#f2f2f2',
    border: '1px solid #dcdcdc',
    borderRadius: '50%',
    bottom: 40,
    boxShadow: '0 0 10px -4px #000',
    cursor: 'pointer',
    height: 130,
    left: 40,
    position: 'fixed',
    width: 130,
    zIndex: 1300
  },
  wrapperMobile: {
    bottom: 0,
    height: 50,
    left: 'auto',
    right: 10,
    top: 95,
    width: 50
  },
  wrapperIOS: {
    position: 'fixed',
    top: 70
  }
}));

const ChuckContainer: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { currentClass } = useSelector((state: any) => state.class);
  const userData = useSelector((state: any) => state.userData);
  const chuck: ChuckState = useSelector((state: any) => state.chuck);
  const [chuckImage, setChuckImage] = useState(chuckImages.idle);

  const handleShowChuckModal = (hideBubble = false) => {
    if (!chuck.userHasInteracted) {
      dispatch(chuckWasInteracted(true));
    }
    if (hideBubble) {
      dispatch(chuckHideBubble());
    }
    let handleAfterCloseModal = () => {};
    if (chuck.allTasksCompleted) {
      handleAfterCloseModal = () => dispatch(completeTodoList(currentClass));
    }
    dispatch(show('modal', { type: MODAL_CHUCK_TASKS, handleAfterCloseModal }));
  };

  const completedTutorial = currentClass.roster.some((player: IClassRoster) => {
    if (!player.scene) {
      return false;
    }
    return player.scene.tutorialStep < 0;
  });

  useEffect(() => {
    if (userData.userType === userTypes.parent) {
      return;
    }

    if (
      !chuck.showBubble &&
      !chuck.userHasInteracted &&
      !chuck.tasksCompleted.length &&
      !chuck.chuckWasIntroducedInThisSession
    ) {
      dispatch(
        chuckShowBubble(
          `Hi ${userData.firstName}, I'm Chuck, your Dreamscape guide! My job is to help you, and your students, get started.`
        )
      );
    }

    if (completedTutorial && !currentClass.todoList.studentCompletedTutorial) {
      dispatch(updateTodoListItem('studentCompletedTutorial'));
    }

    if (chuck.showGift) {
      setChuckImage(chuckImages.gift);
      dispatch(chuckToggleGift());
      setTimeout(() => {
        setChuckImage(chuckImages.idle);
        handleShowChuckModal();
      }, 980);
    }
  }, [chuck]);

  if (
    userData.userType === userTypes.parent ||
    !props.match.path.includes(':classCode')
  ) {
    return null;
  }

  if (!currentClass.todoList || !currentClass.todoList.dateStart) {
    dispatch(updateDateStart(currentClass));
  }

  if (chuck.allTasksCompleted && currentClass.todoList.dateComplete) {
    return null;
  }

  return (
    <div
      className={clsx(
        classes.wrapper,
        (window.screen.height < 1100 || isMobile) && classes.wrapperMobile,
        isIOS && classes.wrapperIOS
      )}
    >
      <ChuckBubble
        show={chuck.showBubble}
        text={chuck.bubbleText}
        onClick={() => handleShowChuckModal(true)}
        onCloseClick={() => {
          dispatch(chuckWasInteracted(true));
          dispatch(chuckHideBubble());
        }}
      />
      <ChuckIcon
        image={chuckImage}
        onClick={() => handleShowChuckModal(true)}
      />
    </div>
  );
};

export default withRouter(ChuckContainer);
