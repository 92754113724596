import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Check } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import colors from '../../../styles/colors';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';

interface ModalSuccessProps {
  show: any;
  handleHide: any;
  title: string;
  text: string;
  hideCloseButton?: boolean;
  confirmButton?: {
    handleClick: () => void;
    label: string;
  };
}

const ModalSuccess: React.FC<ModalSuccessProps> = (
  props: ModalSuccessProps
) => {
  const {
    show,
    handleHide,
    text,
    title,
    hideCloseButton,
    confirmButton
  } = props;
  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers style={{ padding: 20, textAlign: 'center' }}>
        <DialogContentText>
          <Check style={{ color: colors.success, fontSize: 80 }} />
          <Typography>{text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {confirmButton && (
          <Button primary onClick={confirmButton.handleClick}>
            {confirmButton.label}
          </Button>
        )}
        {!hideCloseButton && <Button onClick={handleHide}>Close</Button>}
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalSuccess;
