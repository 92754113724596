import { FEATURE_FLAG_SET } from './feature-flag-types';

/**
 * @description sets feature flag on redux store
 * @param feature
 */
export function setFeatureFlag(feature: object) {
  return {
    type: FEATURE_FLAG_SET,
    payload: {
      ...feature
    }
  };
}
