import {
  USER_CONFIRM_ACCOUNTS,
  USER_CONFIRM_ACCOUNTS_FAILED,
  USER_EDIT_INFORMATION,
  USER_EDIT_INFORMATION_FAILED,
  USER_GET_REFERRALS_FAILED,
  USER_LOGIN_FAILED,
  USER_LOGOUT,
  USER_SET_SCHOOL_ID,
  USER_SET_TEACHER_ID,
  USER_GET_SUCCESS,
  USER_UPDATE_REGISTRATION_STEP,
  USER_UPDATE_DATA,
  USER_EDIT_FEATURES
} from '../types/user/user-action-type';
import { userTypes } from '../../constants/users';

export const userReducer = (
  state: any = {},
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case USER_LOGOUT:
      return {};
    case USER_GET_SUCCESS: {
      let location;
      if (payload.userType === userTypes.parent) {
        location = payload.parentLocation;
      } else if (payload.mixpanel) {
        location = {
          region: payload.mixpanel.districtRegion,
          country: payload.mixpanel.districtCountry
        };
      }
      let referrer = payload.referrer;
      return {
        ...state,
        ...payload,
        location,
        referrer
      };
    }
    case USER_UPDATE_REGISTRATION_STEP: {
      return {
        ...state,
        dreamscape: {
          ...state.dreamscape,
          registrationStep: payload
        }
      };
    }
    case USER_EDIT_INFORMATION: {
      return { ...state, ...payload };
    }
    case USER_EDIT_INFORMATION_FAILED: {
      return state;
    }
    case USER_EDIT_FEATURES: {
      const { featureName, featureData } = payload;
      return {
        ...state,
        features: {
          [featureName]: featureData
        }
      };
    }
    case USER_UPDATE_DATA: {
      return {
        ...state,
        ...payload
      };
    }

    case USER_SET_SCHOOL_ID:
      return {
        ...state,
        currentSchoolId: payload.id
      };
    case USER_GET_REFERRALS_FAILED:
      return state;
    case USER_LOGIN_FAILED:
      return state;
    case USER_CONFIRM_ACCOUNTS:
      return {
        ...state,
        createAccounts: {
          checkAccounts: payload.data.data
        }
      };
    case USER_SET_TEACHER_ID:
      return {
        ...state,
        teacherId: payload.id
      };
    case USER_CONFIRM_ACCOUNTS_FAILED:
      return state;
    default:
      return state;
  }
};
