import {
  CHUCK_TOGGLE_GIFT,
  CHUCK_SHOW_BUBBLE,
  CHUCK_HIDE_BUBBLE,
  CHUCK_WAS_INTERACTED,
  CHUCK_RESET_STATE,
  CHUCK_TASKS_COMPLETED,
  CHUCK_COMPLETE_TASK,
  CHUCK_RESET_COMPLETED_TASKS,
  CHUCK_INTRODUCED
} from '../types/chuck/chuck-action-types';
import ChuckState from '../../types/state/chuck-state';

const initialState: ChuckState = {
  userHasInteracted: false,
  showGift: false,
  showBubble: false,
  bubbleText: '',
  allTasksCompleted: false,
  tasksCompleted: [],
  chuckWasIntroducedInThisSession: false
};

export const chuckReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case CHUCK_TOGGLE_GIFT:
      return {
        ...state,
        showGift: !state.showGift
      };
    case CHUCK_SHOW_BUBBLE:
      return {
        ...state,
        showBubble: true,
        bubbleText: payload
      };
    case CHUCK_HIDE_BUBBLE:
      return {
        ...state,
        showBubble: false,
        bubbleText: ''
      };
    case CHUCK_WAS_INTERACTED:
      return {
        ...state,
        userHasInteracted: payload
      };
    case CHUCK_TASKS_COMPLETED:
      return {
        ...state,
        allTasksCompleted: payload
      };
    case CHUCK_COMPLETE_TASK:
      return {
        ...state,
        tasksCompleted: [...state.tasksCompleted, payload]
      };
    case CHUCK_RESET_COMPLETED_TASKS:
      return {
        ...state,
        showBubble: false,
        tasksCompleted: []
      };
    case CHUCK_RESET_STATE:
      return {
        ...initialState,
        chuckWasIntroducedInThisSession: state.chuckWasIntroducedInThisSession
      };
    case CHUCK_INTRODUCED:
      return {
        ...state,
        chuckWasIntroducedInThisSession: payload
      };
    default:
      return state;
  }
};
