export enum assignmentTypes {
  skills = 'SKILLS',
  genres = 'GENRES',
  packs = 'PACKS',
  testPrep = 'TEST PREP',
  premium = 'PREMIUM',
  decodables = 'DECODABLES'
}

export const assignmentSteps = {
  selectType: 'SELECT TYPE',
  decodablesAssignmentDescription: 'DECODABLES ASSIGNMENT DESCRIPTION',
  selectQuestions: 'SELECT QUESTIONS',
  selectPack: 'SELECT PACK',
  selectStudents: 'SELECT STUDENTS',
  selectDate: 'SELECT DATE',
  done: 'DONE',
  selectSkillsOrGenres: 'SELECT SKILLS OR GENRES'
};
