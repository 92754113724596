import moment from 'moment';
import {
  PLANNER_SET,
  PLANNER_UPDATE,
  PLANNER_REMOVE,
  PLANNER_MODIFY,
  PLANNER_UPDATE_MULTIPLE
} from '../types/planner/planner-action-types';

export const plannerReducer = (
  state: Array<any> = [],
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case PLANNER_SET:
      const assignments = payload.data.assignments.map((assignment: any) => {
        if (assignment.isUserGenerated) {
          return {
            allDay: true,
            type: formatAssignmentType(assignment),
            id: assignment.groupReference,
            title: assignment.title,
            isUserGenerated: true,
            color: assignment.color,
            end: assignment.endDate,
            start: assignment.startDate,
            educationAssignmentId: assignment.assignmentId,
            status: getAssignmentStatus(assignment)
          };
        }
        return {
          allDay: true,
          _id: assignment._id,
          type: formatAssignmentType(assignment),
          title: assignment.title,
          color: assignment.color,
          end: assignment.endDate,
          start: assignment.startDate,
          educationAssignmentId: assignment.educationAssignmentId,
          status: getAssignmentStatus(assignment)
        };
      });
      return assignments;
    case PLANNER_UPDATE:
      const newAssignment = payload.data;
      newAssignment.allDay = true;
      newAssignment.end = newAssignment.endDate;
      newAssignment.start = newAssignment.startDate;
      newAssignment.status = getAssignmentStatus(newAssignment);
      return [...state, { ...newAssignment }];
    case PLANNER_REMOVE:
      const result = state.filter((assignment: any) => {
        if (payload.isUserGenerated) {
          return assignment.id !== payload.groupReference;
        } else {
          return assignment._id !== payload._id;
        }
      });
      return result;
      break;
    case PLANNER_UPDATE_MULTIPLE:
      const updatedAssignments: any = [];
      payload.forEach((assignment: any) => {
        const newAssignment = {
          allDay: true,
          end: assignment.endDate,
          start: assignment.startDate,
          status: getAssignmentStatus(assignment)
        };
        updatedAssignments.push(newAssignment);
      });

      return [...state, ...updatedAssignments];
      break;
    case PLANNER_MODIFY:
      if (Array.isArray(state) && state.length) {
        const {
          id,
          startDate: start,
          endDate: end,
          title,
          isUserGenerated
        } = payload;
        return state.map((plan): any => {
          if (isUserGenerated) {
            if (plan.id === id) {
              return {
                ...plan,
                end
              };
            }
          } else if (plan._id === id) {
            return {
              ...plan,
              start,
              end,
              title
            };
          }
          return plan;
        });
      }

      return state;

    default:
      return state;
  }
};

function getAssignmentStatus(assignment: any) {
  const today = new Date();
  const endDate = new Date(assignment.endDate);
  const startDate = new Date(assignment.startDate);
  if (startDate > today) {
    return `Starts at ${moment(startDate).format('MMM Do YYYY')}`;
  }
  if (startDate < today && endDate > today) {
    return 'Active';
  }
  return 'Finished';
}

function formatAssignmentType(assignment: any) {
  let { type } = assignment;
  type = type.toLowerCase();
  return `${type.charAt(0).toUpperCase()}${type.substring(1, type.length)}`;
}
