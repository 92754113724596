export enum AssignmentEditQuestionActionTypes {
  ADD_QUESTION = '[User Generated Content Question]: ADD QUESTION',
  DELETE_QUESTION = '[User Generated Content Question]: DELETE QUESTION',
  UPDATE_QUESTION = '[User Generated Content Question]: UPDATE QUESTION',
  UPDATE_QUESTION_TYPE = '[User Generated Content Question]: UPDATE QUESTION TYPE',
  UPDATE_QUESTION_FORMAT = '[User Generated Content Question]: UPDATE QUESTION FORMAT',
  UPDATE_QUESTION_TEXT = '[User Generated Content Question]: UPDATE QUESTION TEXT',
  SET_EDIT_MODE = '[User Generated Content Question]: SET EDIT MODE'
}

export enum AssignmentEditQuestionBankActionTypes {
  ADD_QUESTION_BANK_QUESTIONS = '[User Generated Content]: ADD QUESTION BANK QUESTIONS'
}

export enum AssignmentEditAnswerActionTypes {
  ADD_ANSWER = '[User Generated Content Answer]: ADD ANSWER',
  DELETE_ANSWER = '[User Generated Content Answer]: DELETE ANSWER',
  UPDATE_ANSWER = '[User Generated Content Answer]: UPDATE ANSWER'
}
