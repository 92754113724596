import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { show as showModal, hide } from 'redux-modal';
import {
  MODAL_CANCEL_MEMBERSHIP,
  MODAL_PAUSE_MEMBERSHIP
} from '../../../constants/modals';
import { Close } from '@material-ui/icons';
import { Debug } from '../../../utils/debug';
import { playerNounUppercase } from '../../../utils/userNoun';

interface ModalChooseCancellationProps {
  show: () => void;
  handleHide: () => void;
  isCancelPage: boolean;
  membershipData: any;
}

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 20,
    width: 300
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

const ModalChooseCancellation: React.FC<ModalChooseCancellationProps> = (
  props: ModalChooseCancellationProps
) => {
  const { show, handleHide, membershipData, isCancelPage } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const openModal = (type: string) => {
    dispatch(
      showModal('modal', {
        type,
        membershipData,
        isCancelPage
      })
    );
  };


  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Membership Cancellation
        <IconButton
          className={classes.closeButton}
          onClick={() => dispatch(hide('modal'))}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Typography style={{ marginBottom: 10 }}>
          <strong>
						{playerNounUppercase}s with Dreamscape memberships read 6x more passages
          </strong>
        </Typography>
        <Typography>
          If your player wants to keep their membership for the new school year,
          you are able to pause the membership, so you aren't charged for the
          next one or two months.
        </Typography>

        <Button
          primary
          className={classes.buttons}
          onClick={() => openModal(MODAL_PAUSE_MEMBERSHIP)}
        >
          Pause membership
        </Button>

        <br />

        <Button
          className={classes.buttons}
          onClick={() => openModal(MODAL_CANCEL_MEMBERSHIP)}
        >
          Cancel membership
        </Button>
      </DialogContent>
      <DialogActions></DialogActions>
    </ModalAbstract>
  );
};

export default ModalChooseCancellation;
