/**
 * @description prefixes grade with word equivalent i.e - 1st, 2nd
 * @param number
 */
export const ordinalSuffix = (number: number): string => {
  if (!number) return ""
  const j = number % 10;
  const k = number % 100;
  const stringNumber = number.toString();
  if (j == 1 && k !== 11) {
    return stringNumber + 'st';
  }
  if (j == 2 && k !== 12) {
    return stringNumber + 'nd';
  }
  if (j == 3 && k !== 13) {
    return stringNumber + 'rd';
  }
  return stringNumber + 'th';
};
