import { AssignmentEditActionTypes } from './assignment-action.types';

export class AssignmentActions {
  /**
   * Updates assignment in editor view
   * @param updatedAssignment
   */
  static updateAssignment(updatedAssignment: any) {
    return {
      type: AssignmentEditActionTypes.UPDATE,
      payload: updatedAssignment
    };
  }

  /**
   * @description adds retrieved assignment to state
   * @param assignment
   */
  static retrieveAssignment(assignment: any) {
    return {
      type: AssignmentEditActionTypes.RETRIEVED_ASSIGNMENT,
      payload: assignment
    };
  }
  /**
   * resets edit state
   */
  static reset() {
    return {
      type: AssignmentEditActionTypes.RESET
    };
  }
}
