import React from 'react';
import View from '../../components/view/View';
import { Paper, Typography } from '@material-ui/core';
import Button from '../../components/button/Button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const AdminDeniedView = () => {
  const dispatch = useDispatch();
  return (
    <View flex style={{ height: '100vh', textAlign: 'center' }}>
      <Paper style={{ padding: 20 }}>
        <Typography component="h1" variant="h4">
          The administrator was denied
        </Typography>
        <Typography component="p" style={{ margin: 20 }}>
          We sent an e-mail to the administrator with further instructions.
        </Typography>
        <Button primary onClick={() => dispatch(push('/'))}>
          Go to the dashboard
        </Button>
      </Paper>
    </View>
  );
};

export default AdminDeniedView;
