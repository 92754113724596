export const grades = [1, 2, 3, 4, 5, 6, 7, 8];
export const skills = {
  foundational: [
    'Parts of Speech',
    'Punctuation',
    'Prefixes & Suffixes',
    'Figurative Language',
    'Vocabulary',
    'Root Words',
    'Synonyms & Anthonyms'
  ],
  advanced: [
    'Character Identification',
    `Asking and Answering One's Own Questions`,
    'Close Reading',
    'Conflict',
    'Main Idea',
    'Previewing Text',
    'Sequencing',
    'Setting',
    'Summarizing',
    'Synthesizing',
    'Visualizing'
  ],
  independent: [
    'Symbolism',
    'Alliteration',
    'Homographs',
    'Homophone',
    'Hyperbole',
    'Idiom',
    'Imagery',
    'Metaphor',
    'Onomatopoeia',
    'Personification',
    'Punctuation',
    'Simile',
    'Synecdoche',
    'Synonyms',
    'Antonyms',
    'Homonyms',
    'Other Commonly Confused Words',
    'Suffixes',
    'Prefixes',
    'Root words',
    'Oxymoron',
    'Metonymy',
    'Connotation',
    'Nouns',
    'Capitalization',
    'Collective Noun',
    'Pronouns',
    'Sentences',
    'Verbs',
    'Word Parts',
    'Prefix Decomposition',
    'Prefix Meaning',
    'Suffix Decomposition',
    'Morphology Word Formation',
    'Comparatives/Superlatives'
  ]
};

export const classMissionDates = [
  '2024-01-19',
  '2024-02-16',
  '2024-04-04',
  '2024-04-05'
];

export const classMissionDateRange = {
  from: '2024-04-15',
  to: '2024-06-30'
}

export const newLETag = 'NEW_LE_USER';
