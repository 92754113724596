import React, { useEffect, useState } from 'react';
import ChartContainer from '../chart/ChartContainer';
import DoughnutChart from '../chart/DoughnutChart';
import colors from '../../styles/colors';
import { push } from 'connected-react-router';
import { makeStyles, Typography, Link } from '@material-ui/core';
import {
  PlacementTestTooltip,
  PlacementTestPieWidgetProps,
  PlacementTestCategories
} from '../../types/reports/placement-test-pie-types';
import { hide } from 'redux-modal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { teacherRoutes } from '../../constants/routes';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  tooltip: {
    background: 'rgba(0, 0, 0, 0.7)',
    border: `1px solid ${colors.white}`,
    borderRadius: 3,
    color: colors.white,
    padding: 10,
    position: 'absolute',

    '& ul': {
      listStyle: 'none'
    },

    '& h4': {
      marginBottom: 10
    }
  }
}));

const PlacementTestPieWidget: React.FC<PlacementTestPieWidgetProps> = (
  props: PlacementTestPieWidgetProps
) => {
  const token = useSelector((state: any) => state.login.token);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const initialPlacementDataState = {
    notStarted: [],
    inProgress: []
  };
  const initialTooltipState = {
    title: '',
    data: [],
    visible: false,
    position: { x: 0, y: 0 }
  };
  const [placementCategories, setPlacementCategories] = useState<
    PlacementTestCategories
  >(initialPlacementDataState);
  const [tooltip, setTooltip] = useState<PlacementTestTooltip>(
    initialTooltipState
  );
  const features = useAppSelector(state => state.featureFlag);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const isPremium = useSelector(
    (state: any) => state.userData.premium.isPremium
  );

  useEffect(() => {
    const categories: PlacementTestCategories = initialPlacementDataState;
    const data = props.data || [];
    data.forEach((player: any) => {
      if (player.status === 'COMPLETED' || player.status === 'Done') {
        if (categories[player.currentGrade]) {
          categories[player.currentGrade].push(player);
        } else {
          categories[player.currentGrade] = [player];
        }
      } else if (
        player.status === 'IN PROGRESS' ||
        player.status === 'InProgress'
      ) {
        categories.inProgress.push(player);
      } else {
        categories.notStarted.push(player);
      }
    });
    setPlacementCategories({ ...categories });
  }, [props.data]);

  const getColorByGrade = (grade: number) => {
    type Colors = { [x: number]: string };
    const colors: Colors = {
      1: '#b0dfe5',
      2: '#95c8d8',
      3: '#89cff0',
      4: '#57a0a3',
      5: '#3457b4',
      6: '#0d2452',
      7: '#003152',
      8: '#000000'
      // '#2d3436',
      // '#e17055'
    };
    return colors[grade];
  };

  const getChartProps = () => {
    const gradeLabels: Array<string> = [];
    const gradeValues: Array<number> = [];
    const gradeColors: Array<string> = [];
    const keys: Array<string> = ['notStarted', 'inProgress'];
    Object.keys(placementCategories)
      .filter((key: any) => !isNaN(key))
      .forEach((key: any) => {
        keys.push(key);
        gradeLabels.push(`Reading level ${key}`);
        gradeValues.push(placementCategories[key].length);
        gradeColors.push(getColorByGrade(key));
      });
    const labels = ['Not Started', 'In Progress', ...gradeLabels];
    const values = [
      placementCategories.notStarted.length,
      placementCategories.inProgress.length,
      ...gradeValues
    ];
    const background = ['#d95352', '#ffed72', ...gradeColors];
    return {
      keys,
      labels,
      values,
      background
    };
  };

  const renderToolTip = () => {
    if (!tooltip.visible) return null;
    return (
      <div
        className={classes.tooltip}
        style={{
          left: tooltip.position.x,
          top: tooltip.position.y
        }}
      >
        <h4>{tooltip.title}</h4>
        <ul>
          {tooltip?.data?.map((item: any, index) => (
            <li key={index}>{item.name}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderChart = () => {
    let content = (
      <Typography style={{ textAlign: 'center' }}>No data to show</Typography>
    );
    const chartProps = getChartProps();
    if (chartProps.values.length >= 2) {
      content = (
        <DoughnutChart
          labels={chartProps.labels}
          values={chartProps.values}
          background={chartProps.background}
          legend={props.legend}
          height={props.height}
          handleTooltip={(visible: boolean, tooltipModel: any) => {
            let data: any = [];
            let title = '';
            const chartProps = getChartProps();
            if (tooltipModel.dataPoints) {
              const { index } = tooltipModel.dataPoints[0];
              const placementTestKey = chartProps.keys[index];
              //@ts-ignore
              data = placementCategories[placementTestKey];
              title = chartProps.labels[index];
            }
            setTooltip({
              title,
              visible,
              position: {
                x: tooltipModel.x,
                y: tooltipModel.y
              },
              data
            });
          }}
        />
      );
    }
    return content;
  };

  const HelpText = () => {
    return (
      <>
        <Typography>
          This chart shows the status and results for the most recent placement
          test assigned to your players. It is a reflection of their approximate
          reading level when they completed the test.
        </Typography>
        <Typography style={{ marginTop: '10px' }}>
          For a current assessment of your players’ reading levels, click here
          to review your &nbsp;
          {
            <Link
              component="button"
              variant="body1"
              underline="none"
              onClick={() => {
                dispatch(hide('modal'));
                if (features.newDashboardFlag) {
                  window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${classCode}/reports/reading-level?accessToken=${token}`;
                } else {
                  dispatch(push(teacherRoutes.classReportsReadingLevel));
                }
              }}
            >
              Reading Level Report
            </Link>
          }
        </Typography>
      </>
    );
  };

  if (props.hideContainer) {
    return (
      <div style={{ position: 'relative' }}>
        {renderToolTip()}
        {renderChart()}
      </div>
    );
  }

  const readingLevelReportRedirect = () => {
    return (
      <>
        <span>
          <Typography
            style={{
              fontSize: '13px',
              position: 'relative',
              marginTop: '30px',
              marginBottom: '-30px'
            }}
          >
            For a current assessment of your player's reading levels, click here
            to review your&nbsp;
            {
              <Link
                component="button"
                variant="body1"
                underline="none"
                onClick={() => {
                  dispatch(hide('modal'));
                  if (features.newDashboardFlag) {
                    window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${classCode}/reports/reading-level?accessToken=${token}`;
                  } else {
                    dispatch(push(teacherRoutes.classReportsReadingLevel));
                  }
                }}
              >
                Reading Level Report
              </Link>
            }
          </Typography>
        </span>
      </>
    );
  };

  return (
    <ChartContainer title="Placement Test results" helpText={<HelpText />}>
      {renderToolTip()}
      {renderChart()}
      {readingLevelReportRedirect()}
    </ChartContainer>
  );
};

export default PlacementTestPieWidget;
