import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Typography,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider
} from '@material-ui/core';
import ModalAbstract from '../ModalAbstract';
import { useSelector, useDispatch } from 'react-redux';
import { chuckHideBubble } from '../../../../redux/actions/chuck-actions';
import ChuckReferral from './ChuckReferral';
import Button from '../../../button/Button';
import { isMobile } from 'react-device-detect';
import ChuckState from '../../../../types/state/chuck-state';

const ModalChuckTasks: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const { show, handleHide } = props;
  const { todoList } = useSelector((state: any) => state.class.currentClass);
  const chuck: ChuckState = useSelector((state: any) => state.chuck);

  const taskList = [
    {
      label: 'Have a player complete the in-game tutorial',
      instructions:
        'Have at least one player login in the game and complete the Tutorial.',
      done: todoList.studentCompletedTutorial,
      group: 1
    },
    {
      label: 'Create an assignment',
      instructions:
        'Go to the "Assignments" tab on the left, and create a new assignment.',
      done: todoList.createAssignment,
      group: 1
    },
    {
      label: 'Invite a parent',
      instructions:
        'Go to the "Players" tab on the left, and click "Invite Parent" for one of your players.',
      done: todoList.inviteParent,
      group: 1
    },
    {
      label: 'Refer a teacher',
      instructions:
        'Type a teacher e-mail below to send a referral. You can always track your referrals in the main screen.',
      render: () => <ChuckReferral />,
      done: todoList.referATeacher,
      group: 2
    },
    {
      label: 'Access Real-Time Dashboard',
      instructions:
        'Go to the "Reports" tab on the left, and access the Real-Time report.',
      done: todoList.accessRealTimeData,
      group: 2
    }
  ];

  let currentTaskGroup = [1];
  let taskGroup1 = taskList.filter(
    task => currentTaskGroup.includes(task.group) && task.done === false
  );
  if (!taskGroup1.length) {
    currentTaskGroup = [1, 2];
  }

  return (
    <div>
      <ModalAbstract
        show={show}
        fullWidth
        handleHide={handleHide}
        hideOnOutsideClick
      >
        <DialogTitle>
          {isMobile && chuck.showBubble ? (
            <>
              <Typography style={{ marginBottom: 10 }}>
                {chuck.bubbleText}
              </Typography>
            </>
          ) : null}
          Complete these tasks to reward your players in-game:
        </DialogTitle>
        <DialogContent dividers style={{ padding: 20, textAlign: 'center' }}>
          <DialogContentText>
            {taskList
              .filter(task => currentTaskGroup.includes(task.group))
              .sort((a: any, b: any) =>
                a.done === b.done ? 0 : a.done ? 1 : -1
              ) // moves done tasks to the end
              .map((task, taskIndex) => (
                <>
                  <ListItem dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={task.done}
                        color="primary"
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body1"
                            color="textPrimary"
                            style={{
                              textDecoration: task.done ? 'line-through' : ''
                            }}
                          >
                            {task.label}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            style={{
                              textDecoration: task.done ? 'line-through' : ''
                            }}
                          >
                            {task.instructions}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {task.render && !task.done ? (
                    <div
                      style={
                        !isMobile
                          ? { display: 'flex', margin: '0 0 15px 70px' }
                          : {}
                      }
                    >
                      {task.render()}
                    </div>
                  ) : null}

                  {taskIndex < taskList.length - 1 ? (
                    <Divider variant="inset" component="div" />
                  ) : null}
                </>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(chuckHideBubble());
              handleHide();
              if (props.handleAfterCloseModal) {
                props.handleAfterCloseModal();
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </ModalAbstract>
    </div>
  );
};

export default ModalChuckTasks;
