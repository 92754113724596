import { userTypes } from '../constants/users';
import store from '../redux/store';
/**
 * @description This function returns the correct route value based on userType
 * The use case is for dynamic route changes without nested if else conditions.
 * @param userType is the current user type based on the database values
 * */
export function userRouteGetter(userType: any) {
  switch (userType) {
    case userTypes.parent:
      return 'parent';
    case userTypes.teacher:
      return 'teacher';
    case userTypes.schoolAdmin:
      return 'school-admin';
    case userTypes.districtAdmin:
      return 'district-admin';
    default:
      return '';
  }
}

/**
 * Uses router to push route to specific route with current class code
 * @param route
 * @param router
 */
export function userNavigateWithClassCode(route: string) {
  const state: any = store.getState();
  const currentClass = state?.class?.currentClass?.code;
  const replacedRoute = route.replace(':classCode', currentClass);
  return replacedRoute;
}

/**
 * Uses router to push route to specific route with current class code
 * @param route
 * @param param
 * @param paramValue
 */
export function userNavigateWithParam(
  route: string,
  param: string,
  paramValue: string
) {
  const replacedRoute = route.replace(`:${param}`, paramValue);
  return replacedRoute;
}

export function teacherNavigateToPremiumPlanPage(route: string) {
  return userNavigateWithClassCode(route);
}
