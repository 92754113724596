import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { Check } from '@material-ui/icons';
import colors from '../../../styles/colors';
import { downloadPDF } from '../../../redux/actions/class-actions';
import { userTypes } from '../../../constants/users';

interface ModalAssignmentCreatedProps {
  show: () => void;
  handleHide: () => void;
  assignmentId: string;
  assignmentTitle: string;
}

const ModalAssignmentCreated: React.FC<ModalAssignmentCreatedProps> = (
  props: ModalAssignmentCreatedProps
) => {
  const { show, handleHide } = props;
  const userData = useSelector((state: any) => state.userData);
  const { currentClass } = useSelector((state: any) => state.class);
  const studentType = 'players'
  const dispatch = useDispatch();
  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Congratulations - Your assignment has been created
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Check style={{ color: colors.success, fontSize: 80 }} />
        <Typography style={{ marginBottom: 10 }}>
          It's time for your {studentType} to log into Dreamscape.
        </Typography>

        <Button
          primary
          style={{ marginTop: 20 }}
          onClick={() => {
            handleHide();
            dispatch(downloadPDF(userData, currentClass));
          }}
        >
          View and print your {studentType} login codes
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalAssignmentCreated;
