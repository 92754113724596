import React, { useEffect, useState, createRef } from 'react';
import View from '../../components/view/View';
import {
  Divider,
  Grid,
  Paper,
  Typography,
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import colors from '../../styles/colors';
import Button from '../../components/button/Button';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getPlanner,
  getPlannerReport,
  deleteAssignment,
  printReport,
  deleteUserGeneratedAssignment,
  getPlannerReportV2,
  deleteAssignmentV2,
  deleteAssignmentNewLE
} from '../../redux/actions/planner-actions';
import { withRouter, useLocation } from 'react-router-dom';
import { hide, show } from 'redux-modal';
import { push } from 'connected-react-router';
import CountUp from 'react-countup';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import PlayerProgressChart from '../../components/chart/PlayerProgressChart';
import {
  MODAL_DEFAULT,
  MODAL_UPDATE_ASSIGNMENT,
  MODAL_ASSIGNMENT_TUTORIAL,
  MODAL_PARENT_MEMBERSHIP
} from '../../constants/modals';
import { isMobile } from 'react-device-detect';
import { MoreVert, Print, ChevronLeft } from '@material-ui/icons';
import { userTypesDashboardRoute, userTypes } from '../../constants/users';
import { trackShareAssignmentInGoogle } from '../../redux/actions/mixpanel-actions';
import GoogleClassroomIcon from '../../assets/google/btn_classroom_32x32_yellow_stroke_icon@2x.png';
import useQueryParams from '../../hooks/UseQueryParams';
import { playerNounUppercase } from '../../utils/userNoun';
import { useHistory } from 'react-router-dom';
import { UserType } from '../../enums/UserType';
import QuestionBreakDownList from '../../components/question/QuestionBreakDownList';
import ChartDataLabel from 'chartjs-plugin-datalabels';
import { useAppSelector } from '../../redux/hooks';
import { teacherRoutes } from '../../constants/routes';
import ReportsView from './ReportsView';

const fontSize = 42;
const fontWeight = 800;

const useStyles = makeStyles(() => ({
  countUp: {
    fontSize,
    fontWeight
  },
  countUpIncorrect: {
    color: colors.error,
    fontSize,
    fontWeight
  },
  countUpCorrect: {
    color: colors.success,
    fontSize,
    fontWeight
  },
  reportBox: {
    padding: '10px 20px',
    width: '100%',
    textAlign: 'right'
  },
  assignmentDetails: {
    padding: '10px 20px',
    width: '100%',
    textAlign: 'left',
    marginTop: '1.5rem'
  },
  divider: {
    margin: '10px 0'
  },
  progressTitle: {
    background: colors.primary,
    borderRadius: 0,
    color: colors.white,
    padding: 15
  },
  button: {
    position: 'absolute',
    right: 30,
    top: 20
  },
  questionBreakdown: {
    padding: 20,
    marginBottom: 20,
    position: 'relative',
    width: '100%'
  },
  paragraph: {
    fontSize: 13
  },
  questionText: {
    '& img': {
      maxWidth: '100%'
    }
  },
  classroomIcon: {
    marginRight: 5,
    width: 24
  }
}));

const AssignmentReportView: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const [
    moreOptionsAnchorEl,
    setMoreOptionsAnchorEl
  ] = React.useState<null | HTMLElement>(null);
  const [componentReady, setComponentReady] = useState(false);
  const features = useAppSelector(state => state.featureFlag);
  const hasAccessToUserGeneratedContent =
    features.userGeneratedContent?.hasAccess;
  const [report, setReport] = useState<{
    usersProgress: any[];
    users: any[];
    questions: any[];
    details: any;
    answers: any;
    showBreakdown: boolean;
  }>({
    usersProgress: [],
    users: [],
    questions: [],
    details: {
      type: ''
    },
    answers: {
      correct: 0,
      incorrect: 0,
      inCorrect: 0
    },
    showBreakdown: false
  });
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const isPremiumUser = userData.premium && userData.premium.isPremium;
  let { members } = useSelector((state: any) => state.members);
  const { currentClass } = useSelector((state: any) => state.class);
  const refs: any = {
    questionBreakdownRef: createRef(),
    playerChartsRef: createRef()
  };
  const queryParams = useQueryParams();
  const history = useHistory();
  const isUserGenerated = queryParams.isUserGenerated == 'true';
  const classCode = props.match.params.classCode || queryParams.classCode || currentClass.code;
  const featureFlag = useSelector((state: any) => state.featureFlag);
  
  useEffect(() => {
    const fetchPlanner = async () => {
      const reportResponse = await dispatch(
        hasAccessToUserGeneratedContent
          ? getPlannerReportV2(
              props.match.params.assignmentId,
              isUserGenerated,
              classCode
            )
          : getPlannerReport(props.match.params.assignmentId, isUserGenerated)
      );
      if (reportResponse.data.details.disabled) {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Assignment not available'
          })
        );
        return goToPlannerView();
      }
      const membersIds = members
        ? members.map((member: any) => member._id)
        : [];
      const users = reportResponse.data.users
        .filter((user: any) => user)
        .map((user: any) => {
          const isMember = membersIds.includes(user._id);
          return {
            ...user,
            isMember
          };
        });

      const hasNonMembers = users.filter((user: any) => !user.isMember);
      const hasMembers = users.find((user: any) => user.isMember);
      setReport({
        ...reportResponse.data,
        users,
        showBreakdown:
          userData.userType === UserType.Parent
            ? hasMembers !== undefined
            : true
      });
      if (userData.userType === UserType.Parent && hasNonMembers.length > 0) {
        dispatch(
          show('modal', {
            type: MODAL_PARENT_MEMBERSHIP,
            origin: 'Assignment Report'
          })
        );
      }
    };
    if (!componentReady) {
      setComponentReady(true);
      fetchPlanner();
    }
  }, [componentReady]);

  useEffect(() => {
    if (queryParams.showTutorial) {
      dispatch(
        show('modal', {
          type: MODAL_ASSIGNMENT_TUTORIAL
        })
      );
    }
  }, []);

  const isTestPrep = (): Boolean => {
    if (report.details.content) {
      return 'TEST PREP' === report.details.content.type;
    }
    return false;
  };
  const showModalMembership = () => {
    dispatch(
      show('modal', {
        type: MODAL_PARENT_MEMBERSHIP,
        origin: 'Assignment Report'
      })
    );
  };
  const handleChangeStep = (destination: string) => {
    const element = document.getElementById(destination);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const data: number[] = [];
  const labels: string[] = [];
  const backgroundColor: string[] = [];

  report.usersProgress.forEach((item: any) => {
    data.push(item.value);
    labels.push(item.title);
    backgroundColor.push(item.color);
  });

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
        hoverBackgroundColor: backgroundColor
      }
    ]
  };

  const goToPlannerView = () => {
    if (features.userGeneratedContent?.hasAccess) {
      return dispatch(
        push(
          `${teacherRoutes.userGeneratedAssignments.replace(
            ':classCode',
            currentClass.code
          )}`
        )
      );
    }
    dispatch(
      push(
        `${userTypesDashboardRoute[userData.userType]}/assignment-tool/${
          currentClass.code
        }`
      )
    );
  };

  const handleShareClassroom = () => {
    const params = {
      url: process.env.REACT_APP_WEBGL_LINK,
      body:
        'Hello class, a new assignment is available in Dreamscape. Click the link below to log in.',
      itemtype: 'assignment',
      title: report.details.title
    };
    const queryParams = new URLSearchParams(params);
    window.open(
      `https://classroom.google.com/u/0/share?${queryParams.toString()}`,
      '_blank'
    );
    dispatch(trackShareAssignmentInGoogle());
  };
  const handleEditAssignment = () => {
    return dispatch(
      show('modal', {
        type: MODAL_UPDATE_ASSIGNMENT,
        id:
          report.details.assignmentsGivenId ||
          report.details.assignmentId ||
          report.details._id ||
          report.details.id,
        title: report.details.title,
        startDate: report.details.startDate,
        endDate: report.details.endDate,
        assignmentType: report.details.type,
        isUserGenerated: report.details.isUserGenerated,
        groupReference: report.details.groupReference,
        isTitleEditable: !report.details.isUserGenerated,
        setComponentReady: setComponentReady,
        hasAccessToUserGeneratedContent
      })
    );
    setMoreOptionsAnchorEl(null);
  };

  const handleDeleteAssignment = () => {
    return dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Delete Assignment',
        children:
          'Are you sure you want to delete this assignment? You will not be able to see these reports anymore.',
        actions: () => (
          <Button
            primary
            onClick={() => {
              const id = isUserGenerated
                ? report.details.groupReference
                : report.details._id || report.details.id;
              if (
                featureFlag.newLeAssignments &&
                report.details.assignmentsGivenId
              ) {
                dispatch(
                  deleteAssignmentNewLE(report.details.assignmentsGivenId)
                );
              } else {
                if (isUserGenerated) {
                  dispatch(deleteUserGeneratedAssignment(id));
                } else if (hasAccessToUserGeneratedContent) {
                  dispatch(deleteAssignmentV2(id));
                } else {
                  dispatch(deleteAssignment(id));
                }
              }
              history.goBack();
              history.replace(
                `${teacherRoutes.classDashboard.replace(
                  ':classCode',
                  currentClass.code
                )}`
              );
              dispatch(hide('modal'));
            }}
          >
            Yes
          </Button>
        )
      })
    );
  };

  const renderPlayerProgress = () => {
    if (report.questions.length) {
      return (
        <PlayerProgressChart
          isParent={userData.userType === UserType.Parent}
          data={report}
        />
      );
    }
    return null;
  };

  const setAssignmentType = () => {
    if (isUserGenerated) {
      return 'USER GENERATED CONTENT';
    } else if (
      report.details.type === 'PACKS' ||
      report.details.type === 'Packs'
    ) {
      return 'Themed Content';
    } else if (report.details.type == 'Premium') {
      return 'Charts and Graphs';
    } else if (!featureFlag.newLeAssignments && report.details.type.toLowerCase() === 'skills') {
      return 'Word Study/Vocab Skills';
    }  else if (featureFlag.newLeAssignments && report.details.type.toLowerCase() === 'skills') {
      return 'Skill Practice';
    } else if (report.details.type === 'Genres') {
      return 'Genre Passages';
    } else {
      return report.details.type;
    }
  };

  const renderPlayerCharts = () => (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Paper className={classes.reportBox}>
              <h2>
                Total Players <strong>{report.users.length}</strong>
              </h2>
              <Pie
                data={chartData}
                plugins={[ChartDataLabel]}
                options={{
                  responsive: true,
                  legend: {
                    position: 'right'
                  },
                  plugins: {
                    datalabels: {
                      color: function(ctx) {
                        return '#FFF';
                      },
                      anchor: 'center',
                      align: 'center',
                      font: function(context) {
                        const width = context.chart.width;
                        // @ts-ignore
                        const size = Math.round(width / 48);
                        return {
                          weight: 'bold',
                          size: size
                        };
                      },
                      formatter: function(value: any, context: any) {
                        const data = context.chart.data.datasets[0].data;
                        const currentData = data[context.dataIndex];
                        if (currentData == 0) {
                          return null;
                        }
                        let frequency = 0;
                        data.forEach((currentVal: any) => {
                          if (currentVal > 0) {
                            frequency += currentVal;
                          }
                        });

                        const percentage = (currentData / frequency) * 360;
                        return `${currentData} (${Math.floor(
                          (percentage / 360) * 100
                        )}%)`;
                      }
                    }
                  }
                }}
              />
              <Typography component="h4">
                {isTestPrep() ? 'Test Prep' : 'Assignment'} Progress
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Paper className={classes.reportBox}>
                <CountUp
                  end={report.answers.correct}
                  className={classes.countUpCorrect}
                />
                <Typography component="h4">Correct Answers</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.reportBox}>
                <CountUp
                  end={report.answers.incorrect || report.answers.inCorrect}
                  className={classes.countUpIncorrect}
                />
                <Typography component="h4">Incorrect Answers</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Paper className={classes.assignmentDetails}>
              <Typography component="p">
                <strong>Title: </strong>{' '}
                <span>{report.details.title || report.details.name}</span>
              </Typography>
              <Divider className={classes.divider} />
              <Typography component="p">
                <strong>Assignment Type: </strong>{' '}
                <span>
                  {setAssignmentType()}
                  {/* Changing type packs to themed content as schools are re-opening. */}
                  {/* {isUserGenerated
                    ? 'USER GENERATED CONTENT'
                    : report.details.type} */}
                </span>
              </Typography>
              <Divider className={classes.divider} />
              <Typography component="p">
                <strong>Created At: </strong>{' '}
                <span>
                  {moment(
                    report.details.createdDate || report.details.createdAt
                  ).format('MMM Do YYYY')}
                </span>
              </Typography>
              <Divider className={classes.divider} />
              <Typography component="p">
                <strong>Start Date: </strong>{' '}
                <span>
                  {moment(report.details.startDate).format('MMM Do YYYY')}
                </span>
              </Typography>
              <Divider className={classes.divider} />
              <Typography component="p">
                <strong>End Date: </strong>{' '}
                <span>
                  {moment(report.details.endDate).format('MMM Do YYYY')}
                </span>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Paper>
            {report.showBreakdown ? (
              <>
                <Paper className={classes.progressTitle}>
                  <Typography component="h3">Players' progress</Typography>
                </Paper>
                <div style={{ overflowX: 'scroll' }}>
                  {renderPlayerProgress()}
                </div>
              </>
            ) : (
              <Typography
                style={{ padding: '1.2rem', cursor: 'pointer' }}
                onClick={showModalMembership}
              >
                <u>
                  {userData.userType === UserType.Parent &&
                    'For full access to assignment reports add members today!'}
                  {userData.userType === UserType.Teacher &&
                    'For full access to assignment reports become a premium member today!'}
                </u>
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );

  const renderPrintButton = () => {
    return null;
    // return (
    //   <Button
    //     primary
    //     style={{ marginLeft: 5 }}
    //     onClick={() => {
    //       const id = report.details.isUserGenerated
    //         ? report.details.groupReference
    //         : report.details._id;
    //       dispatch(
    //         printReport(
    //           id,
    //           report.details.title,
    //           report.details.isUserGenerated
    //         )
    //       );
    //     }}
    //   >
    //     <Print style={{ marginRight: 5 }} />
    //     Print Report
    //   </Button>
    // );
  };

  let title = 'Assignment';
  if (report.details.content) {
    if (isTestPrep()) {
      title = 'Test Prep';
    }
  }
  return (
    <View
      title={`${title} Report`}
      renderBackButton={() => (
        <Button onClick={() => history.goBack()} style={{ marginRight: 10 }}>
          <ChevronLeft /> Back
        </Button>
      )}
      renderButtons={() => (
        <>
          <Button onClick={() => handleShareClassroom()}>
            <img
              src={GoogleClassroomIcon}
              alt="Google Classroom"
              className={classes.classroomIcon}
            />
            Share to Google Classroom
          </Button>
          <Button
            primary
            style={{ marginLeft: 10 }}
            onClick={() => handleChangeStep('question-breakdown')}
          >
            View Question Breakdown
          </Button>
          {renderPrintButton()}
          <IconButton
            onClick={(event: any) =>
              setMoreOptionsAnchorEl(event.currentTarget)
            }
            style={{ marginLeft: 10, padding: '5px 8px' }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            open={Boolean(moreOptionsAnchorEl)}
            onClose={() => setMoreOptionsAnchorEl(null)}
            anchorEl={moreOptionsAnchorEl}
          >
            <MenuItem onClick={handleEditAssignment}>
              Edit Name or Dates
            </MenuItem>
            <MenuItem
              onClick={handleDeleteAssignment}
              style={{ color: colors.error }}
            >
              Delete Assignment
            </MenuItem>
          </Menu>
        </>
      )}
    >
      <div
        id="player-charts"
        style={
          isMobile
            ? { position: 'relative', top: 0, left: 0, marginBottom: 20 }
            : {}
        }
        ref={refs.playerChartsRef}
      >
        {renderPlayerCharts()}
      </div>
      {report.showBreakdown && (
        <div id="question-breakdown">
          <Typography
            component="h1"
            variant="h4"
            style={{ margin: '20px 0', position: 'relative' }}
          >
            Question Breakdown
            <div className={classes.button} style={{ top: 0 }}>
              <Button primary onClick={() => handleChangeStep('player-charts')}>
                View {playerNounUppercase} Charts
              </Button>
              {renderPrintButton()}
            </div>
          </Typography>
          <QuestionBreakDownList
            report={report}
            assignmentType={setAssignmentType()}
          />
        </div>
      )}
    </View>
  );
};

const mapStateToProps = (state: any) => ({
  planner: state.planner,
  class: state.class
});

const mapActionsToProps = {
  getPlanner,
  show,
  hide,
  push
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(AssignmentReportView));
