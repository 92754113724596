import React from 'react';
import useReactRouter from 'use-react-router';

const useQueryParams = () => {
  const { location } = useReactRouter();
  const query = !location.search
    ? {}
    : JSON.parse(
        '{"' +
          decodeURI(location.search)
            .replace('?', '')
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
  return query;
};

export default useQueryParams;
