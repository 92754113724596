import { User } from '../../types/user/user-type';
import { MixPanelUser } from '../../types/user/mixpanel-user-type';
import store from '../store';
import { getDayOfWeek, getTimeOfDay } from '../../utils/time';
import mixpanel from '../../utils/mixpanel';
import moment from 'moment';
import { playerNounUppercase } from '../../utils/userNoun';
import { userTypes } from '../../constants/users';

export function updateRegistrationEvents(user: User) {
  let usingDreamscape = true;
  let usingSquigglePark = true;
  if (user.products) {
    usingSquigglePark = user.products.squigglePark;
    usingDreamscape = user.products.dreamscape;
  }
  let userObj: MixPanelUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    userType: user.userType,
    email: user.email,
    // $email is a mixpanel special property
    $email: user.email,
    usingSquigglePark,
    usingDreamscape,
    registrationDate: user.createdDate
  };
  if (user.newRegistrationFlow) {
    userObj.experimentRegistrationFlow = user.newRegistrationFlow;
  }
  if (user.createdByAdmin === 'True') {
    userObj.createdByAdmin = true;
  }
  if (user.mixpanel) {
    userObj = {
      ...userObj,
      ...user.mixpanel
    };
  }
  if (user.referrer) {
    if (user.referrer.length) {
      userObj.referrer = user.referrer[0]?.type;
      userObj.subreferrer = user.referrer[0]?.text;
    }
  }
  userObj.cleverUser = false;
  if (user.clever) {
    userObj.cleverUser = true;
  }
  userObj.googleUser = '';
  if (userObj.google) {
    userObj.googleUser = userObj.google.email;
  }
  if (user.allowedByAdmin) {
    userObj.internallyApproved = user.allowedByAdmin.currentRule;
  }
  if (user.userType !== userTypes.player) {
    userObj.dashboardUser = true;
  }
  mixpanel.identify(user._id);
  mixpanel.people.set(userObj);
  // Send the registration event for this user if they just registered
  if (
    user.loginLogs &&
    user.loginLogs.length === 1 &&
    moment(user.createdDate).diff(new Date(), 'days') === 0
  ) {
    if (usingDreamscape) {
      //@ts-ignore
      if (window.ga) {
        //@ts-ignore
        window.ga('send', 'event', 'Registration', 'Sign Up Complete');
        //@ts-ignore
        window.ga(
          'send',
          'event',
          'Registration',
          `Sign Up Complete - ${user.userType}`
        );
      }
    }
    // if (!userObj.createdByAdmin) {
    //   let props;
    //   if (user.clever) {
    //     props = { cleverSignup: true };
    //   }
    //   mixpanel.track("Register", props);
    // }
  } else {
    mixpanel.track('Log In', undefined, true);
  }
}

export function trackPlayerRegistration(player: any) {
  return (dispatch: any) => {
    //@ts-ignore
    const teacher: any = store.getState().userData;
    // add a new player to Mixpanel
    const { _id, grade, joinedWith, username } = player;
    // identify with player id
    mixpanel.identify(_id);
    // set basic properties
    let playerMixpanelProperties = {
      grade,
      username,
      userType: 'Player',
      joinedWithType: joinedWith.type,
      joinedWithValue: joinedWith.value,
      platformCreation: 'Dashboard',
      districtName: teacher.mixpanel.districtName,
      districtRegion: teacher.mixpanel.districtRegion,
      districtCountry: teacher.mixpanel.districtCountry,
      schoolName: teacher.mixpanel.schoolName,
      schoolCode: teacher.mixpanel.schoolCode,
      schoolCountry: teacher.mixpanel.schoolCountry,
      teacherEmail: teacher.email,
      teacherFirstName: teacher.firstName,
      teacherLastName: teacher.lastName
    };
    if (player.utm) {
      playerMixpanelProperties = { ...playerMixpanelProperties, ...player.utm };
    }
    mixpanel.people.set(playerMixpanelProperties);
  };
}

export function trackRemovePlayer() {
  return (dispatch: any) => {
    mixpanel.people.increment('totalStudents', -1);
  };
}

export function trackViewReport() {
  return (dispatch: any) => {
    mixpanel.people.increment('viewdReports');
  };
}

export function trackClassAdded() {
  return (dispatch: any) => {
    mixpanel.people.increment('classesAdded');
  };
}

export function trackPlayerAdded() {
  return (dispatch: any) => {
    //@ts-ignore
    mixpanel.identify(store.getState().userData._id);
    mixpanel.people.increment('totalStudents');
  };
}

export function trackCleverSync() {
  //@ts-ignore
  mixpanel.identify(store.getState().userData._id);
  mixpanel.people.set({ cleverSync: true });
}

export function trackUserGeneratedAssignmentCreated(assignment: any) {
  mixpanel.track(`User Generated Assignment Created`, {
    ...assignment
  });
}

export function trackAssignmentCreated(data: any, selectedOption?: any) {
  const eventProps: any = {
    title: data.title,
    type: data.content.type,
    //@ts-ignore
    userEmail: store.getState().userData.email,
    classCode: data.classCode,
    studentCount: data.userIds.length,
    contentGrade: data.content.grade,
    startDate: moment(data.startDate).format('YYYY-MM-DD'),
    endDate: moment(data.endDate).format('YYYY-MM-DD'),
    contentCount:
      selectedOption?.totalQuestions || selectedOption?._count?.questions
  };
  if (!selectedOption) {
    eventProps.contentCount = data.content.options.reduce(
      (acc: number, item: { quantity: string }) => {
        return acc + parseInt(item.quantity, 10);
      },
      0
    );
  }
  mixpanel.track(`Assignment Created`, eventProps);
  mixpanel.people.increment('assignmentsCreated');
}

export function trackAssignmentDeleted() {
  return (dispatch: any) => mixpanel.track('Assignment Deleted');
}
export function trackTestPrepCreated(
  data: any,
  testPrepOptions: any,
  region: any,
  allTestPrepsSelected: any
) {
  const state: any = store.getState();
  const eventProps: any = {
    title: data.title,
    type: data.content.type,
    userEmail: state.userData.email,
    classCode: data.classCode,
    studentCount: data.userIds.length,
    originalTitle: testPrepOptions.find(
      (option: any) => option.id === data.content.typeId
    ).name,
    isAllTestPrepSelected: allTestPrepsSelected,
    testPrepRegion: region,
    contentGrade: data.content.grade,
    startDate: moment(data.startDate).format('YYYY-MM-DD'),
    endDate: moment(data.endDate).format('YYYY-MM-DD')
  };
  mixpanel.track(`Test Prep Created`, eventProps);
  mixpanel.people.increment('testPrepsCreated');
}
export function trackClassArchived(status: Boolean) {
  return (dispatch: any) => {
    const state: any = store.getState();
    const eventProps = {
      teacherEmail: state.userData.email,
      classCode: state.class.currentClass.code,
      districtName: state.userData.mixpanel.districtName,
      schoolName: state.userData.mixpanel.schoolName
    };
    const archiveStatus = status ? 'Archived' : 'Unarchived';
    mixpanel.track(`Class ${archiveStatus}`, eventProps);
  };
}

export function trackChuckTaskCompleted(challengeName: string) {
  return (dispatch: any) => {
    const state: any = store.getState();
    const eventProps = {
      challengeName,
      teacherEmail: state.userData.email,
      classCode: state.class.currentClass.code
    };
    mixpanel.track('Chuck Challenge Completed', eventProps);
  };
}

export function trackTestPrepFeedback(value: string) {
  return (dispatch: any) =>
    mixpanel.track('Test Prep Feedback', { submitValue: value });
}

export function trackAccountsCreated(props: any) {
  return (dispatch: any) => {
    mixpanel.track('Accounts Created by Admin', props);
  };
}

export function trackCertificatesSent(props: any) {
  return (dispatch: any) => {
    mixpanel.track('Certificates Sent', props);
  };
}

export function trackExportCSV() {
  return (dispatch: any) => mixpanel.track('Export CSV');
}

export function trackEditGrade() {
  return (dispatch: any) => mixpanel.track('Edit Grade');
}

export function trackViewAssignment() {
  return (dispatch: any) => mixpanel.track('View Assignment');
}

export function trackPrintAssignment() {
  return (dispatch: any) => mixpanel.track('Print Assignment');
}

export function trackViewStudentBreakdown() {
  return (dispatch: any) => mixpanel.track('View Student Breakdown');
}

export function trackSelectPlayerReadingLevel() {
  return (dispatch: any) =>
    mixpanel.track(`Select ${playerNounUppercase} - Reading Level Report`);
}

export function trackSelectPlayerSkillComprehension() {
  return (dispatch: any) =>
    mixpanel.track(
      `Select ${playerNounUppercase} - Skill Comprehension Report`
    );
}

export function trackViewPlanner() {
  return (dispatch: any) => mixpanel.track('View Planner');
}

export function trackViewRealTime() {
  return (dispatch: any) => mixpanel.track('View Real Time');
}

export function trackViewReferATeacher() {
  return (dispatch: any) => mixpanel.track('View Refer a Teacher');
}

export function trackReferATeacherSuccessful() {
  return (dispatch: any) => mixpanel.track('Refer a Teacher Successful');
}

export function trackClickInviteParent() {
  return (dispatch: any) => mixpanel.track('Click Invite Parent');
}

export function trackInviteParentSuccessful() {
  return (dispatch: any) => mixpanel.track('Invite Parent Successful');
}

export function trackViewReadingLevelReport() {
  return (dispatch: any) => mixpanel.track('View Reading Level Report');
}

export function trackViewSkillsReport() {
  return (dispatch: any) => mixpanel.track('View Skills Report');
}

export function trackViewPlacementTestReport() {
  return (dispatch: any) => mixpanel.track('View Placement Test Report');
}

export function trackGoogleClassImported(props: any) {
  return (dispatch: any) => mixpanel.track('Google Class Imported', props);
}

export function trackGoogleLogin() {
  return (dispatch: any) => mixpanel.track('Login with Google');
}

export function trackImportFromGoogleClick() {
  return (dispatch: any) => mixpanel.track('Click Import Classes from Google');
}

export function trackSyncWithGoogleClick() {
  return (dispatch: any) => mixpanel.track('Click Sync Classes from Google');
}

export function trackGoogleAccountImported() {
  return (dispatch: any) => mixpanel.track('Google Account imported');
}

export function trackShareAssignmentInGoogle() {
  return (dispatch: any) => mixpanel.track('Assignment Shared in Classroom');
}

export function trackShowMeMoreClick() {
  return (dispatch: any) => mixpanel.track('Show me more - Click');
}

export function trackCompleteGameTutorial() {
  return (dispatch: any) => mixpanel.track('Complete Tutorial');
}

export function trackSkipGameTutorial() {
  return (dispatch: any) => mixpanel.track('Skip Tutorial');
}

export function trackSetUserABVersion(props: any) {
  return (dispatch: any) => mixpanel.track('Set AB Test', props);
}

export function trackViewNewAssignmentPopup() {
  return (dispatch: any) => mixpanel.track('View new assignment popup');
}

export function trackSupportClick() {
  return (dispatch: any) => mixpanel.track('Help - Support - Click');
}

export function trackHowToPlayClick() {
  return (dispatch: any) => mixpanel.track('Help - How to Play - Click');
}

export function trackPlatformLinkClick(props: any) {
  return (dispatch: any) => mixpanel.track('Platform link - Click');
}

export function trackPauseMembership() {
  return (dispatch: any) => mixpanel.track('Membership Paused');
}

export function trackResumeMembership() {
  return (dispatch: any) => mixpanel.track('Membership Resumed');
}

export function trackViewLeaderboardReport() {
  return (dispatch: any) =>
    mixpanel.track('View Leaderboard Report From Dashboard Widget');
}

export function trackViewQuestionsReport() {
  return (dispatch: any) =>
    mixpanel.track('View Questions Report From Dashboard Widget');
}

export function trackViewAssignmentTool() {
  return (dispatch: any) => {
    mixpanel.track('View Assignment Tool From Dashboard Widget');
  };
}

export function trackViewClassActivityReport() {
  return (dispatch: any) => {
    mixpanel.track('View Class Activity Report From Dashboard Widget');
  };
}

export function trackClassMissionReport(locationAccessed: any) {
  const props: any = { locationClicked: locationAccessed };
  mixpanel.track('Class Mission Reports clicked', props);
}

export function trackClassMissionFullReportView(subscriptionType: any) {
  const props: any = { subscriptionType: subscriptionType };
  mixpanel.track('Click CM Report Breakdown', props);
}

export function trackUpgradeToPremiumClick() {
  return (dispatch: any) => {
    mixpanel.track('CM Report Breakdown Upgrade Button');
  };
}

export function trackRewardComingSoon() {
  return (dispatch: any) => {
    mixpanel.track('Reward Coming Soon');
  };
}

export function trackStateAverageLearnMore() {
  return (dispatch: any) => {
    mixpanel.track('Learn More - State Average');
  };
}

export function trackMembershipPopupParent(origin: string) {
  return (dispatch: any) => {
    mixpanel.track('Memberships Popup - Parents', { origin });
  };
}

export function trackLearnMoreParent(origin: string) {
  return (dispatch: any) => {
    mixpanel.track('Learn More', { origin });
  };
}

export function trackViewFullReportLastTenWidget() {
  return (dispatch: any) => {
    mixpanel.track('View Full Report (Last 10 Questions Widget)');
  };
}

export function trackViewFullQuestionLastTenWidget() {
  return (dispatch: any) => {
    mixpanel.track('View Full Question (Last 10 Questions Widget)');
  };
}

export function trackRewardPageViewed() {
  return (dispatch: any) => {
    mixpanel.track('Reward Page Viewed');
  };
}

export function trackMembershipPlanPageViewed() {
  return (dispatch: any) => {
    mixpanel.track('Premium Plan Page Viewed');
  };
}

export function trackRewardUpgradeMembershipButtonClicked() {
  return (dispatch: any) => {
    mixpanel.track('Reward Upgrade Button');
  };
}

export function updateReferrer(props: any) {
  return (dispatch: any) => {
    mixpanel.identify(props._id);
    const referrer = props?.referrer?.[0]?.type ?? '';
    const subreferrer = props?.referrer?.[0]?.text ?? '';
    mixpanel.people.set({
      referrer: referrer,
      subreferrer: subreferrer
    });
  };
}

export function updateUTMData(props: any) {
  return (dispatch: any) => {
    mixpanel.identify(props._id);
    mixpanel.people.set({
      ...props.utm
    });
  };
}

export function trackViewFullQuestionFromQuestionReport() {
  return (dispatch: any) => {
    mixpanel.track('View Full Question (Question Report)');
  };
}

export function trackNavBarClassMissionsItemClicked(code: string) {
  return (dispatch: any) => {
    mixpanel.track('Class Missions Item from Nav Bar clicked', {
      classCode: code
    });
  };
}

export function trackClassMissionLaunched(code: string) {
  return (dispatch: any) => {
    mixpanel.track('Class Mission Launched', { classCode: code });
  };
}

export function trackClassMissionStarted(code: string, data: any) {
  return (dispatch: any) => {
    mixpanel.track('Class Mission Started', { classCode: code, ...data });
  };
}

export function trackClassMissionReplay(code: string) {
  return (dispatch: any) => {
    mixpanel.track('Retrying Class Mission', { classCode: code });
  };
}

export function trackClassMissionNewMission(code: string) {
  return (dispatch: any) => {
    mixpanel.track('New Class Mission', { classCode: code });
  };
}

export function classMissionUpgradeButtonClicked(code: string) {
  return (dispatch: any) => {
    mixpanel.track('Class Mission Upgrade Button', { classCode: code });
  };
}

export function reassignPlacementTestUpgradeButtonClicked(code: string) {
  return (dispatch: any) => {
    mixpanel.track('Placement Test Upgrade Button', { classCode: code });
  };
}

export function parentSentReward(id: string) {
  return (dispatch: any) => {
    mixpanel.track('Parent Sent Rewards', { playerId: id });
  };
}

export function parentViewedRewardsPage() {
  return (dispatch: any) => {
    mixpanel.track('Parent View Rewards');
  };
}

export function testPrepPremiumUpgrade() {
  return (dispatch: any) => {
    mixpanel.track('Test Prep Upgrade Button');
  };
}

export function parentRedirectToMembershipBuyPage(id: string) {
  return (dispatch: any) => {
    mixpanel.track('Parent To Buy Membership From Reward Page', {
      playerId: id
    });
  };
}

export function trackEducatorMembershipPurchase(
  oldPlan: any,
  newPlan: any,
  memberships: any,
  daysLeftInPlan: any,
  cost: any,
  oldMembershipCount: any
) {
  const props: any = {
    oldPlan: oldPlan,
    newPlan: newPlan,
    memberships: memberships,
    daysLeftInPlan: daysLeftInPlan,
    cost: cost
  };
  mixpanel.people.set({
    'Active Memberships': Number(memberships) + Number(oldMembershipCount)
  });
  mixpanel.track('Educator Purchase', props);
}
export function premiumPagePurcahseButtonClicked(
  currentPlan: any,
  button: any
) {
  const props: any = { currentPlan: currentPlan, buttonClicked: button };
  mixpanel.track('Premium Plans page purchase button', props);
}
export function trackStripePaymentClicked(currentPlan: any) {
  const props: any = { currentPlan: currentPlan };
  mixpanel.track('Continue to stripe button', props);
}

export function trackViewAssignmentPlayerBreakdown() {
  return (dispatch: any) => mixpanel.track('Assignment View Player Breakdown');
}

export function assignmentReportUpgradeButton(code: string) {
  return (dispatch: any) =>
    mixpanel.track('Assignment Report Upgrade Button', { classCode: code });
}

export function trackViewSkillsPlayerBreakdown() {
  return (dispatch: any) => mixpanel.track('Skills View Player Breakdown');
}

export function skillsReportUpgradeButton(code: string) {
  return (dispatch: any) =>
    mixpanel.track('Skills Report Upgrade Button', { classCode: code });
}

export function adminSignUpPageViewed() {
  return (dispatch: any) =>
    mixpanel.track('Page Viewed', {
      Page: 'Registration Page',
      'User Type': 'Admin'
    });
}

export function adminSignUpButtonClicked(isGoogle?: boolean) {
  return (dispatch: any) =>
    mixpanel.track('Button clicked', {
      Button: isGoogle ? 'Sign up with Google' : 'Sign up (Email)',
      'User Type': 'Admin'
    });
}

export function adminRegistered(isGoogle?: boolean) {
  return (dispatch: any) =>
    mixpanel.track('Register', {
      'User Type': 'Admin',
      Method: isGoogle ? 'Google' : 'Email'
    });
}
