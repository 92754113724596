import { API_GET, API_METHODS, API_PUT } from '../api-constants';
import {
  CLASS_ADD_CLASS,
  CLASS_ADD_CLASS_FAILED,
  CLASS_ARCHIVE_CLASS,
  CLASS_EDIT_CLASS,
  CLASS_EDIT_CLASS_FAILED,
  CLASS_GET_ALL,
  CLASS_GET_ALL_FAILED,
  CLASS_GET_ONE,
  CLASS_GET_ONE_FAILED,
  CLASS_UPDATE_CLASS,
  CLASS_UPDATE_CLASS_FAILED,
  CLASS_GET_ONE_IN_PROGRESS,
  CLASS_GET_ONE_SUCCESS
} from '../types/class/class-action-types';
import { generateApi } from '../util/api-promisfy';
import {
  trackClassAdded,
  trackClassArchived,
  trackChuckTaskCompleted
} from './mixpanel-actions';
import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { push } from 'connected-react-router';
import { userTypesDashboardRoute, userTypes } from '../../constants/users';
import { USER_UPDATE_REGISTRATION_STEP } from '../types/user/user-action-type';
import { Debug } from '../../utils/debug';
import store from '../store';
import { IClass, IClassRoster } from '../../types/state/class-type';
import {
  chuckToggleGift,
  chuckShowBubble,
  chuckWasInteracted,
  chuckCompleteAllTasks,
  chuckCompleteTask,
  chuckResetCompletedTasks
} from './chuck-actions';

interface IGetClassesOptions {
  hideSpinner?: boolean;
  callbackFunction?: (P: any) => void;
  resetCurrentClass?: boolean;
  urlClassCode?: string;
}

export const getClasses = (options?: IGetClassesOptions) => {
  let spinnerMessage: any = 'Loading your dashboard';
  if (options && options.hideSpinner) {
    spinnerMessage = false;
  }
  return async (dispatch: any, getState: any) => {
    await dispatch(
      fetchApi({
        url: 'sw/class',
        method: APIMethods.GET,
        spinnerMessage,
        hideSpinnerAfter: true,
        errorMessage:
          'There was an error loading your classes, please try again later'
      })
    )
      .then((response: any) => {
        dispatch({
          type: CLASS_GET_ALL,
          payload: response.data
        });

        // If Classes are present
        // Fetch data about the first Class
        // And Set it as the current class
        if (
          options?.resetCurrentClass ||
          (!getState().class?.currentClass?.code && response.data?.length)
        ) {
          const unArchievedClasses = response.data.filter((e: any) => {
            return e.archived === false;
          });
          const firstClass = unArchievedClasses[0];
          dispatch(getClass(options?.urlClassCode || firstClass.code));
        }

        if (options && typeof options?.callbackFunction === 'function') {
          options?.callbackFunction(response.data);
        }
      })
      .catch((error: any) => {
        dispatch({
          type: CLASS_GET_ALL_FAILED
        });
      });
  };
};

interface IGetClassOptions {
  showSpinner?: boolean;
  hideSpinnerAfter?: boolean;
}

export const getClass = (
  classCode: string,
  getClassOptions?: IGetClassOptions
) => {
  //@ts-ignore
  const { userType }: any = store.getState().userData;
  let spinnerMessage: any =
    userType === userTypes.parent
      ? 'Loading players data'
      : 'Loading class data';
  let hideSpinnerAfter: any;
  if (getClassOptions) {
    if (getClassOptions.showSpinner === false) {
      spinnerMessage = undefined;
    }
    if (getClassOptions.hideSpinnerAfter) {
      hideSpinnerAfter = getClassOptions.hideSpinnerAfter;
    }
  }
  return (dispatch: any) => {
    dispatch(chuckResetCompletedTasks());
    return new Promise((resolve, reject) => {
      dispatch({ type: CLASS_GET_ONE_IN_PROGRESS });
      dispatch(
        fetchApi({
          url: `sw/class/data/${classCode}`,
          method: APIMethods.GET,
          spinnerMessage,
          hideSpinnerAfter: true,
          errorMessage: `There was an error loading the class ${classCode}, please contact our support team if the problem persists.`
        })
      )
        .then((response: any) => {
          dispatch({
            type: CLASS_GET_ONE,
            payload: {
              ...response.data,
              isLoading: false
            }
          });
          setTimeout(() => dispatch({ type: CLASS_GET_ONE_SUCCESS }), 500);
          dispatch(checkTodoListIsComplete());
          resolve();
        })
        .catch((error: any) => {
          dispatch({ type: CLASS_GET_ONE_FAILED });
          //@ts-ignore
          const { userType } = store.getState().userData;
          dispatch(push(userTypesDashboardRoute[userType]));
        });
    });
  };
};

export const downloadPDF = (
  userData: any,
  currentClass: any,
  onSuccess?: () => void
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'pdf/passwords',
        method: APIMethods.POST,
        responseType: 'blob',
        data: {
          teacherName: `${userData.firstName} ${userData.lastName}`,
          classCode: currentClass.code,
          className: currentClass.name,
          players: JSON.stringify(currentClass.roster)
        },
        hideSpinnerAfter: true,
        spinnerMessage: 'Generating your PDF file',
        errorMessage:
          'There was a problem generating your PDF file. Please, try again later'
      })
    )
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `class-${currentClass.code}-passwords.pdf`
        );
        document.body.appendChild(link);
        link.click();

        typeof onSuccess === 'function' && onSuccess();
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const updateClassRegistationStep = (
  registrationStep: number,
  classCode: string
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/class/${classCode}/step`,
        method: APIMethods.PUT,
        data: {
          step: registrationStep
        }
      })
    )
      .then((response: any) => {
        dispatch({
          type: CLASS_UPDATE_CLASS,
          payload: {
            registrationStep
          }
        });
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const getClassByUserId = (userId: string) => {
  return {
    type: API_GET,
    payload: {
      url: `sw/class/user/${userId}`,
      method: API_METHODS.GET
    },
    handler: CLASS_GET_ALL,
    onFail: CLASS_GET_ALL_FAILED,
    hideLoader: true
  };
};

export const getClassRoster = (classCode: string, promisify?: boolean) => {
  const action = {
    type: API_GET,
    payload: {
      url: `sw/class/${classCode}`,
      method: API_METHODS.GET
    },
    handler: CLASS_GET_ONE,
    onFail: CLASS_GET_ONE_FAILED
  };
  if (promisify) {
    return generateApi({ ...action.payload });
  }
  return action;
};

export const addClass = (
  userId: string,
  className: string,
  currentStep: number
) => {
  const { userData }: any = store.getState();

  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/class/add/${userId}`,
        method: APIMethods.POST,
        spinnerMessage: 'Creating class',
        hideSpinnerAfter: true,
        notificationSuccess: 'Class created',
        errorMessage:
          'There was an error saving your class, please try again later.',
        data: {
          className,
          currentStep
        }
      })
    )
      .then((response: any) => {
        if (currentStep === 2 && !userData?.newRegistrationFlow) {
          dispatch({
            type: USER_UPDATE_REGISTRATION_STEP,
            payload: 3
          });
        } else {
          if (currentStep === 1 && userData?.newRegistrationFlow) {
            dispatch({
              type: USER_UPDATE_REGISTRATION_STEP,
              payload: 2
            });
          }
        }
        dispatch({
          type: CLASS_ADD_CLASS,
          payload: response.data
        });
        dispatch(trackClassAdded());
      })
      .catch((error: any) => {
        dispatch({ type: CLASS_ADD_CLASS_FAILED });
      });
  };
};

export const editClass = (classData: any, userId?: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/class/id/${classData.id}`,
        method: APIMethods.PUT,
        spinnerMessage: 'Saving Class Name',
        hideSpinnerAfter: true,
        notificationSuccess: 'Class saved',
        errorMessage: `There was an error saving your class, please try again later.`,
        data: classData
      })
    )
      .then((response: any) => {
        const { name } = classData.data;
        dispatch({
          type: CLASS_EDIT_CLASS,
          payload: {
            name,
            id: classData.id
          }
        });
      })
      .catch((error: any) => {
        dispatch({ type: CLASS_EDIT_CLASS_FAILED });
      });
  };
};

export const updateClass = (classCode: string, data: any) => {
  return {
    type: API_PUT,
    payload: {
      url: 'sw/class',
      method: API_METHODS.PUT,
      data: {
        ...data
      }
    },
    handler: CLASS_UPDATE_CLASS,
    onFail: CLASS_UPDATE_CLASS_FAILED,
    dispatchAdditionalActions: getClass(classCode)
  };
};

export const archiveClass = (id: string, archive: boolean) => {
  return (dispatch: any) => {
    let spinnerMessage = 'Archiving';
    let notificationMessage = 'Archived';
    if (!archive) {
      spinnerMessage = 'Unarchiving';
      notificationMessage = 'Unarchived';
    }
    dispatch(
      fetchApi({
        url: `sw/class/id/${id}`,
        method: APIMethods.PUT,
        spinnerMessage: `${spinnerMessage} class`,
        hideSpinnerAfter: true,
        notificationSuccess: `Class ${notificationMessage}`,
        errorMessage: `There was an error saving your class, please try again later.`,
        data: {
          id,
          data: {
            archived: archive
          }
        }
      })
    )
      .then((response: any) => {
        dispatch({
          type: CLASS_ARCHIVE_CLASS,
          payload: {
            id,
            archived: archive
          }
        });
        dispatch(trackClassArchived(archive));
      })
      .catch((error: any) => {
        // dispatch({ type: CLASS_ARCHIVE_CLASS_FAILED });
      });
  };
};

export const updateDateStart = (currentClass: IClass) => {
  return (dispatch: any) => {
    // @todo api call here
    dispatch({
      type: CLASS_UPDATE_CLASS,
      payload: {
        todoList: {
          ...currentClass.todoList,
          dateStart: new Date()
        }
      }
    });
  };
};

export const updateTodoListItem = (key: string) => {
  return (dispatch: any) => {
    //@ts-ignore
    const { currentClass } = store.getState().class;
    const { todoList } = currentClass;

    if (todoList.dateStart && todoList[key] === false) {
      dispatch({
        type: CLASS_UPDATE_CLASS,
        payload: {
          todoList: {
            ...todoList,
            [key]: true
          }
        }
      });
      dispatch(chuckCompleteTask(key));
      dispatch(
        fetchApi({
          url: `sw/classchallenge/${currentClass.todoList.challengeProgressId}`,
          method: APIMethods.PUT,
          data: {
            key,
            value: true,
            classId: currentClass._id
          }
        })
      )
        .then(() => {
          const { firstName }: any = store.getState().userData;
          let message = `Great job ${firstName}!
            I just sent an in-game reward to every player in this class. Complete more items on the list to send more rewards to your players!`;
          // redeclare currentClass to get the updated state
          const { currentClass }: any = store.getState().class;
          const incompleteTasks = Object.keys(currentClass.todoList).filter(
            (key: any) => currentClass.todoList[key] === false
          );
          if (!incompleteTasks.length) {
            dispatch(chuckCompleteAllTasks(true));
            message = `You're a star, ${firstName}! One more reward was sent to your players. That's it for now. See you in your next class! 👋`;
          }
          dispatch(chuckToggleGift());
          dispatch(chuckShowBubble(message));
          dispatch(trackChuckTaskCompleted(key));
        })
        .catch((error: any) => Debug.log(error));
    }
  };
};

export const completeTodoList = (currentClass: IClass) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/classchallenge/${currentClass.todoList.challengeProgressId}/complete`,
        method: APIMethods.POST
      })
    )
      .then(() => {
        const value = new Date();
        const key = 'dateComplete';
        dispatch({
          type: CLASS_UPDATE_CLASS,
          payload: {
            todoList: {
              ...currentClass.todoList,
              [key]: value
            }
          }
        });
        dispatch(chuckCompleteAllTasks(true));
      })
      .catch((error: any) => Debug.log(error));
  };
};

export const checkTodoListIsComplete = () => {
  return (dispatch: any) => {
    const { currentClass }: any = store.getState().class;
    const completeTasks: Array<any> = [];
    const incompleteTasks: Array<any> = [];
    Object.keys(currentClass.todoList).forEach((key: any) => {
      const task = currentClass.todoList[key];
      if (task === false) {
        incompleteTasks.push(task);
      } else if (task === true) {
        completeTasks.push(task);
        dispatch(chuckCompleteTask(key));
      }
    });
    if (!incompleteTasks.length) {
      dispatch(chuckCompleteAllTasks(true));
    } else {
      dispatch(chuckWasInteracted(false));
      dispatch(chuckCompleteAllTasks(false));
    }
  };
};
