import React, { useEffect } from 'react';
import { MembershipCheckout } from '../../features/lazy-loading/modules/class-module';
import mixpanel from '../../utils/mixpanel';

const MembershipCart = (props: any) => {
  useEffect(() => {
    mixpanel.track('Onboarding - Plan Confirmation Screen');
  });
  return <MembershipCheckout isOnboardingStep={true} />;
};

export default MembershipCart;
