import React from 'react';
import { connect } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Redirect } from 'react-router';
import ModalAbstract from './ModalAbstract';

const ModalSelectClass: React.FC = (props: any) => {
  const { show, handleHide, classData } = props;
  const menuItems = classData.map((e: any) => {
    return <MenuItem value={e.code}>{e.name}</MenuItem>;
  });

  const [currentClass, setClass] = React.useState({
    classCode: ''
  });
  const handleSelectClass = (evt: any) => {
    setClass({ classCode: evt.target.value });
  };
  if (currentClass.classCode) {
    // redirect
    handleHide();
    return (
      <Redirect to={`/dashboard/teacher/students/${currentClass.classCode}`} />
    );
  }
  return (
    <ModalAbstract
      show={show}
      handleHide={handleHide}
      hideOnOutsideClick={false}
    >
      <DialogTitle id="form-dialog-title">Select Class</DialogTitle>
      <DialogContent dividers={props.dividers || false}>
        <DialogContentText>
          <FormControl
            style={{
              width: '100%'
            }}
          >
            <InputLabel>Class</InputLabel>
            <Select
              name="classCode"
              value={currentClass.classCode}
              onChange={handleSelectClass}
            >
              {menuItems}
            </Select>
          </FormControl>
        </DialogContentText>
      </DialogContent>
    </ModalAbstract>
  );
};

const mapStateToProps = (state: any) => {
  return {
    classData: state.class.classes
  };
};

export default connect(mapStateToProps)(ModalSelectClass);
