import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import educator from '../../assets/icons/registration/dreamscape_account_educators.png';
import student from '../../assets/icons/registration/dreamscape_account_students.png';
import parents from '../../assets/icons/registration/dreamscape_account_parents.png';
import admin from '../../assets/icons/registration/dreamscape_account_schools.png';
import Button from '../../components/button/Button';
import { isMobile } from 'react-device-detect';
import { RegistrationUserTypes } from '../../types/registration/registration-user-types';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import mixpanel from '../../utils/mixpanel';

interface RegisterSelectionProps {
  handleSelectUserType: (userType: RegistrationUserTypes) => void;
  showOthers: boolean;
  selectedUserType: RegistrationUserTypes;
  shouldUseNewRegistrationFlow?: boolean;
}

const RegisterSelection: React.FC<RegisterSelectionProps> = (
  props: RegisterSelectionProps
) => {
  const location: any = useLocation();

  const registerOptions: any = [
    {
      props: { blue: true },
      userType: 'Teacher',
      image: educator,
      label: 'Educator',
      shouldVisible: true
    },
    {
      props: { primary: true },
      userType: 'Student',
      image: student,
      label: 'Player',
      shouldVisible: true
    },
    {
      props: { green: true },
      userType: 'Parent',
      image: parents,
      label: 'Parent',
      shouldVisible: true
    },
    {
      props: { orange: true },
      userType: 'School Admin',
      image: admin,
      label: 'Admin',
      shouldVisible:
        !props.shouldUseNewRegistrationFlow ||
        location.pathname.includes('/school')
          ? true
          : false
    }
  ];
  const gridStyle: React.CSSProperties = {
    textAlign: 'center',
    margin: isMobile ? '30px auto' : 0,
    padding: isMobile ? '0 8px ' : '0 20px'
  };
  const { selectedUserType, showOthers } = props;

  let filteredOptions = showOthers
    ? registerOptions.filter((options: any) => options.shouldVisible)
    : registerOptions.filter(
        (options: any) =>
          options.userType === selectedUserType && options.shouldVisible
      );

  return (
    <Container style={{ margin: '40px auto' }} maxWidth="lg">
      <Grid
        container
        style={{ flexWrap: 'nowrap', margin: 'auto' }}
        md={8}
        justifyContent="center"
        alignItems="center"
      >
        {/* <Grid item md={1} lg={2}></Grid> */}
        {filteredOptions.map((option: any, index: number) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={3}
            md={2}
            lg={2}
            style={gridStyle}
            className={clsx(
              props.selectedUserType !== '' &&
                props.selectedUserType !== option.userType &&
                'black-white'
            )}
          >
            <img
              src={option.image}
              alt={option.label}
              style={{ maxWidth: '100%', width: isMobile ? '70px' : '130px' }}
            />
            <br />
            <br />
            <Button
              loginpage
              {...option.props}
              onClick={() => props.handleSelectUserType(option.userType)}
              disabled={
                location.pathname.includes('/school') &&
                option.userType !== 'School Admin'
              }
            >
              {option.label}
            </Button>
          </Grid>
        ))}
        {/* <Grid item md={2} lg={2}></Grid> */}
      </Grid>
    </Container>
  );
};

export default RegisterSelection;
