import { CircularProgress } from '@material-ui/core';
import React from 'react';

interface LoaderProps {
  text: string;
  color?: string;
  textColor?: string;
}
export function Loader(props: LoaderProps) {
  const { text, color, textColor } = props;
  return (
    <>
      <CircularProgress style={{ color: color || 'white' }} />
      <span style={{ color: textColor || 'white' }}>{text}...</span>
    </>
  );
}
