import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Button from '../../../components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../../styles/colors';
import moment from 'moment';
import { getSkillReport } from '../../../redux/actions/report-actions';
import {
  SkillReportRequest,
  SkillResponse
} from '../../../types/reports/skill-report-types';
import SkillsFilters, {
  FilterState,
  initialFilterState
} from './SkillsFilters';
import SkillsTable from './SkillsTable';
import { show } from 'redux-modal';
import {
  MODAL_SKILLS_PLAYER_BREAKDOWN_UPGRADE,
  MODAL_SKILL_REPORT
} from '../../../constants/modals';
import { ChevronRight, MoreHoriz } from '@material-ui/icons';
import { buildReport } from './SkillReportHelpers';
import { Period } from '../../../types/reports/skill-report-types';
import { playerNounLowerCase } from '../../../utils/userNoun';
import { hide as hideModal, show as showModal } from 'redux-modal';
import StarsIcon from '@material-ui/icons/Stars';
import { trackViewSkillsPlayerBreakdown } from '../../../redux/actions/mixpanel-actions';
import store, { RootState } from '../../../redux/store';

const useStyles = makeStyles(() => ({
  rowError: {
    background: colors.red,
    color: colors.white
  },
  rowSuccess: {
    background: colors.success,
    color: colors.white
  },
  center: {
    display: 'flex',
    height: `${(9 / 16) * 100}%`,
    alignItems: 'center'
  },
  accuracy: {
    margin: '-16px 10px',
    padding: 30,
    textAlign: 'center'
  }
}));

interface SkillsClassReportProps {
  listType?: string;
  hideFilter?: boolean;
  handlePlayerNameClick?: (row: any) => void;
}

const SkillsClassReport: React.FC<SkillsClassReportProps> = (
  props: SkillsClassReportProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentClass } = useSelector((state: any) => state.class);
  const userData = useSelector((state: any) => state.userData);
  const [originalResponse, setOriginalResponse] = useState<Array<any>>([]);
  const [reportState, setReportState] = useState<Array<SkillResponse>>([]);
  const [tableState, setTableState] = useState(reportState || []);
  const { featureFlag } = store.getState() as RootState;
  const [request, setRequest] = useState<SkillReportRequest>({
    grade: 0,
    type: 'all',
    to: moment().format('YYYY-MM-DD'),
    from: moment('2018-01-01').format('YYYY-MM-DD'),
    userIds: currentClass.roster.map((player: any) => player.educationUserId)
  });
  const [filter, setFilter] = useState<FilterState>(initialFilterState);

  const handlePlayerBreakdown = (row: any) => {
    if (featureFlag.premiumReporting && userData.premium.isPremium) {
      dispatch(
        show('modal', {
          filter,
          type: MODAL_SKILL_REPORT,
          data: originalResponse,
          skillId: row.row.original.skillId,
          classRoster: currentClass.roster,
          handlePlayerNameClick: props.handlePlayerNameClick
        })
      );
    } else if (featureFlag.premiumReporting && !userData.premium.isPremium) {
      dispatch(trackViewSkillsPlayerBreakdown());
      const sendProps: any = {
        type: MODAL_SKILLS_PLAYER_BREAKDOWN_UPGRADE
      };
      dispatch(showModal('modal', sendProps));
    } else if (!featureFlag.premiumReporting) {
      dispatch(
        show('modal', {
          filter,
          type: MODAL_SKILL_REPORT,
          data: originalResponse,
          skillId: row.row.original.skillId,
          classRoster: currentClass.roster,
          handlePlayerNameClick: props.handlePlayerNameClick
        })
      );
    }
  };

  const fetchReport = async () => {
    const response = await dispatch(getSkillReport(request));
    setOriginalResponse(response.data);
    let reportData = buildReport(response.data);
    if (props.listType === 'widget') {
      if (reportData.length > 6) {
        const firstElements = reportData.slice(0, 3);
        const lastElements = reportData.slice(
          Math.max(reportData.length - 3, 0)
        );
        reportData = [...firstElements, ...lastElements];
      }
    }
    setReportState(reportData);
  };

  useEffect(() => {
    fetchReport();
  }, []);

  useEffect(() => {
    fetchReport();
  }, [request]);

  useEffect(() => {
    setTableState(reportState);
  }, [reportState]);

  useEffect(() => {
    const data = buildReport(originalResponse, { grade: filter.grade });
    setReportState(data);
  }, [filter.grade]);

  useEffect(() => {
    setTableState(filter.searchState);
  }, [filter.searchState]);

  useEffect(() => {
    const { to, from } = filter.range;
    setRequest({
      ...request,
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD')
    });
  }, [filter.range, filter.period]);

  let errorMessage: string;

  if (props.hideFilter) {
    errorMessage = `There is no data from your class for the last 7 days! Select View Report to see data from other time periods.`;
  } else if (filter.period === Period.allTime) {
    errorMessage = `We haven't collected any skill data from this class yet! Have ${playerNounLowerCase}s log into the game and answer questions and check back later.`;
  } else {
    errorMessage = `There is no data based on your selection! Change the time period or grade to see more information.`;
  }

  let detailsTitle = 'Player Details';
  if (userData.userType === 'Parent') {
    detailsTitle = 'Player Details';
  }

  return (
    <>
      {!props.hideFilter && (
        <SkillsFilters
          originalData={reportState}
          onChange={(filter: FilterState) => setFilter(filter)}
        />
      )}
      <Grid item xs={12} className={classes.center}>
        {!tableState.length && (
          <Typography style={{ padding: 12 }}>{errorMessage}</Typography>
        )}
        {tableState.length > 0 && (
          <SkillsTable
            listType={props.listType || 'skills'}
            data={tableState}
            roster={currentClass.roster}
            handlePlayerNameClick={props.handlePlayerNameClick}
            actions={{
              Header: () => <div className={classes.center}>Action</div>,
              accessor: 'actions',
              Cell: (row: any) => (
                <div className={classes.center}>
                  <Button
                    primary
                    onClick={() => {
                      handlePlayerBreakdown(row);
                    }}
                  >
                    {featureFlag.premiumReporting &&
                    !userData.premium.isPremium ? (
                      <>
                        <StarsIcon
                          fontSize={'large'}
                          style={{
                            position: 'relative',
                            right: '5px'
                          }}
                        />
                        Player Details
                      </>
                    ) : (
                      <>
                        <MoreHoriz style={{ marginRight: 5 }} /> {detailsTitle}
                      </>
                    )}
                  </Button>
                </div>
              )
            }}
          />
        )}
      </Grid>
    </>
  );
};

export default SkillsClassReport;
