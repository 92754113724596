// TODO: This was created by duplicating RealTimeView.tsx
// unused code should be removed
// duplicate code should be consolidated
import { makeStyles, Typography } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { hide, show } from 'redux-modal';
import Button from '../../components/button/Button';
import View from '../../components/view/View';
import { MODAL_DEFAULT } from '../../constants/modals';
import { fetchApi } from '../../redux/actions/fetch-actions';
import {
  initialzeRealTimeData,
  newUpdateRealTimeData
} from '../../redux/actions/realtime-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { NewServerMessage } from '../../types/realtime/realtime-types';
import {
  trackClassMissionNewMission,
  trackClassMissionReplay,
  trackClassMissionStarted
} from '../../redux/actions/mixpanel-actions';
import { push } from 'react-router-redux';
import { userNavigateWithClassCode, userNavigateWithParam } from '../../utils/user-route-getter';
import { teacherRoutes } from '../../constants/routes';
import { ClassMissionService } from './class-mission.service';
import { userTypesLoginRoute } from '../../constants/users';
import { UserType } from '../../enums/UserType';
import TeacherFeatureFlagContext from '../../layouts/teacher/TeacherFeatureFlagContext';
import { useAppSelector } from '../../redux/hooks';
import { io, Socket } from 'socket.io-client';

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    right: 30,
    top: 20
  },
  closeButtonMobile: {
    left: 0,
    marginBottom: 30,
    position: 'relative',
    top: 0
  },
  helpRow: {
    alignItems: 'center',
    borderTop: '1px solid #dcdcdc',
    display: 'flex',
    marginTop: 10,
    paddingTop: 10
  },
  helpIcon: {
    marginRight: 10
  }
}));

type PlayCanvasMessage = {
  success?: boolean;
  action: string;
  missionState?: {
    activePlayers: any;
    playerCount: any;
    correct: any;
    incorrect: any;
    questionGoal: any;
    durationMin: any;
    difficulty: any;
    startTime: any;
    elapsedTime: any;
    inProgress: any;
    selectedMonsterSkin: any;
  };
  userId?: string;
  rewardId?: string;
  message?: string;
  classCode?: string;
};

const NewClassMissionsGameView: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const userData = useAppSelector(state => state.userData);
  let isClassMissionTrialUser = false;
  let isPremiumUser = userData.premium && userData.premium.isPremium;
  const { flags } = React.useContext(TeacherFeatureFlagContext);
  if (
    (flags.isMissionMonday || flags.classMissionTrialsExtended) &&
    !isPremiumUser
  ) {
    isClassMissionTrialUser = true;
  }
  const { connection, metrics, players } = useSelector(
    (state: any) => state.realTime
  );
  const gamePlayers = React.useRef(players);
  const setGamePlayers = () => {
    gamePlayers.current = players;
  };
  const dispatch = useDispatch();
  const [isConnected, setConnected] = useState(false);
  const [isEventListenerInitialized, setIsEventListenerInitialized] = useState(
    false
  );
  // const [isMissionFinished, setMissionFinished] = useState(false);
  const currentClass = useSelector((state: any) => state.class.currentClass);
  /**
   * We use refs to be able to class and access state from inside event listener callbacks
   */
  const currentClassMissionRef = React.useRef(currentClass);
  const classMissionFinished = React.useRef({ isMissionFinished: false });
  const setCurrentClassRef = (data: any) => {
    currentClassMissionRef.current = data;
    setCurrentClassMission(data);
  };

  const rewardRef = React.useRef<any>(null);
  const setRewardRef = (data: any) => {
    rewardRef.current = data;
  };

  const missionStarted = React.useRef(false);
  const setMissionStarted = (isMissionSet: boolean) => {
    missionStarted.current = isMissionSet;
  };
  const [currentClassMission, setCurrentClassMission] = useState<{
    id: number;
    status: string;
    missionStarted: boolean;
  } | null>(null);
  const params = useParams<{ missionId: string }>();
  const history = useHistory();
  const playcanvas: React.MutableRefObject<any> = React.useRef(null);
  const { login } = useSelector((state: any) => state);
  const questionWindowSocket = useRef<
    Socket<DefaultEventsMap, DefaultEventsMap>
  >();
  const notificationSocket = useRef<
    Socket<DefaultEventsMap, DefaultEventsMap>
  >();

  useEffect(() => {
    const missionId = params?.missionId;
    if (missionId === 'new') {
      initializeClassMission(null, null);
    } else {
      dispatch(
        fetchApi({
          url: `class-missions/${params?.missionId}`,
          method: APIMethods.GET,
          endpoint: APIEndpoints.EDUCATION
        })
      )
        .then((response: AxiosResponse) => {
          const { classMission } = response.data;
          initializeClassMission(classMission.id, classMission.status);
        })
        .catch(() => {
          history.goBack();
          dispatch(
            show('snackbar', {
              variant: 'error',
              message: 'error retrieving class mission',
              autoHideDuration: 4000
            })
          );
        });
    }
    return () => {
      if (currentClassMissionRef.current && currentClassMissionRef.current.id) {
        /**
         * This handles the case when a user logs out, we don't want to pause it
         * since the logout function in NavBar.tsx will handle it
         */
        console.log('isMissionFinished', classMissionFinished.current);
        const val = classMissionFinished.current?.isMissionFinished;
        if (
          // eslint-disable-next-line no-restricted-globals
          location.pathname != userTypesLoginRoute[UserType.Teacher] &&
          !val
        ) {
          ClassMissionService.pauseClassMission(
            currentClassMissionRef.current.id
          );
        }
      }
    };
  }, []);

  /**
   * @description initalizes class mission
   * @param id
   * @param status
   */
  const initializeClassMission = (id: number | null, status: string | null) => {
    if (status === 'SUCCESS' || status === 'FAILED') {
      createClassMission();
      dispatch(initialzeRealTimeData(currentClass.roster));
    } else {
      if (id) {
        ClassMissionService.pauseClassMission(id);
      }
      createClassMission();
      dispatch(initialzeRealTimeData(currentClass.roster));
    }
  };

  /**
   * @description updates class mission with mission start params
   * @param classMissionParamsData
   */
  const classMissionStart = (classMissionParamsData: any) => {
    if (currentClassMissionRef.current) {
      const classMission = currentClassMissionRef.current;
      dispatch(
        fetchApi({
          url: `class-missions/${classMission.id}`,
          endpoint: APIEndpoints.EDUCATION,
          data: classMissionParamsData,
          method: APIMethods.PATCH
        })
      )
        .then((response: AxiosResponse) => {
          const { classMission } = response.data;
          setCurrentClassRef(classMission);
          setMissionStarted(true);
        })
        .catch(() => {
          dispatch(
            show('snackbar', {
              message: 'error updating class mission',
              variant: 'error',
              autoHideDuration: 4000
            })
          );
        });
    }
  };
  /**
   * @description creates class mission in education api and routes to class mission game
   */
  const createClassMission = () => {
    dispatch(
      fetchApi({
        url: 'class-missions',
        endpoint: APIEndpoints.EDUCATION,
        method: APIMethods.POST,
        data: {
          classCode: currentClass.code
        }
      })
    )
      .then((response: AxiosResponse) => {
        const { classMission } = response.data;
        setCurrentClassRef(classMission);
        dispatch(
          push(
            userNavigateWithParam(
              userNavigateWithClassCode(teacherRoutes.classMissionsGame),
              'missionId',
              classMission.id as string
            )
          )
        );
      })
      .catch(() => {
        dispatch(
          show('snackbar', {
            variant: 'error',
            message: 'Error creating class mission',
            autoHideDuration: 4000
          })
        );
      });
  };
  /**
   * @description creates active records of players
   */
  const instantiatePlayers = (activePlayers: string[]) => {
    const missionId = currentClassMissionRef.current.id;
    dispatch(
      fetchApi({
        url: 'class-missions/players',
        endpoint: APIEndpoints.EDUCATION,
        data: {
          missionId,
          users: activePlayers.map((userId: string) => {
            return {
              userId,
              missionId,
              joinedAt: new Date().toISOString()
            };
          })
        },
        method: APIMethods.POST
      })
    ).catch(() => {
      dispatch(
        show('snackbar', {
          variant: 'error',
          message: 'Error creating player records',
          autoHideDuration: 4000
        })
      );
    });
  };
  /**
   * Handle messages sent from the PlayCanvas game
   * @param msg - Data from the game
   */
  const onPlayCanvasMessage = async (msg: { data: PlayCanvasMessage }) => {
    console.log('onPlayCanvasMessage()', msg.data.action);
    switch (msg.data.action) {
      case 'Message:PlayerOnline': {
        const playerId = msg.data.userId as string;
        if (playerId && missionStarted.current) {
          instantiatePlayers([playerId as string]);
        }
        break;
      }
      case 'Message:MissionStarted':
        const { missionState } = msg.data;
        classMissionFinished.current = { isMissionFinished: true };
        const classMissionParamsData = {
          missionLength: missionState?.durationMin,
          missionDifficulty: missionState?.difficulty,
          numberOfQuestions: missionState?.questionGoal,
          status: 'IN PROGRESS',
          missionStartedAt: new Date().toISOString(),
          selectedMonsterSkin: missionState?.selectedMonsterSkin
        };
        const numberOfPlayers = Object.keys(missionState?.activePlayers).length;
        await notificationSocket.current?.emit('class-mission-event', {
          classCode: currentClass.code,
          event: 'started'
        });
        dispatch(
          trackClassMissionStarted(currentClass.code, {
            ...classMissionParamsData,
            numberOfPlayers
          })
        );
        classMissionStart(classMissionParamsData);
        if (numberOfPlayers > 0) {
          const players = Object.keys(missionState?.activePlayers);
          instantiatePlayers(players as string[]);
        }
        break;
      case 'Message:GetData': {
        getReward();
        break;
      }
      case 'Message:ReplayMission':
        dispatch(trackClassMissionReplay(currentClass.code));
        // create new class mission
        createClassMission();
        break;
      case 'Message:NewMission':
        dispatch(trackClassMissionNewMission(currentClass.code));
        // create new class mission
        createClassMission();
        break;
      case 'Message:ToggleFullscreen': {
        toggleFullScreen();
        break;
      }
      case 'Message:MissionFinished': {
        const isSuccess = msg.data.success;
        const classMission = currentClassMissionRef.current;
        await notificationSocket.current?.emit('class-mission-event', {
          classCode: currentClass.code,
          event: 'ended'
        });
        dispatch(
          fetchApi({
            url: `class-missions/${classMission.id}`,
            endpoint: APIEndpoints.EDUCATION,
            data: {
              status: isSuccess ? 'SUCCESS' : 'FAILED',
              missionEndedAt: new Date().toISOString(),
              isMissionEnd: true,
              reward: isSuccess && rewardRef?.current[0].name,
              rewardId: isSuccess && rewardRef?.current[0]._id
            },
            method: APIMethods.PATCH
          })
        ).catch(() =>
          dispatch(
            show('snackbar', {
              variant: 'error',
              message: 'error updating class mission',
              autoHideDuration: 4000
            })
          )
        );
        break;
      }
      case 'Message:SendReward': {
        dispatch(
          fetchApi({
            url: `class-missions/rewards/assign/${msg.data.userId}`,
            data: {
              rewardId: msg.data.rewardId,
              message: msg.data.message,
              classCode: msg.data.classCode
            },
            method: APIMethods.POST
          })
        );
        break;
      }
      case 'Message:StartWaitingRoom': {
        dispatch(
          show('modal', {
            type: MODAL_DEFAULT,
            children:
              'Welcome to the Waiting Room. You can now share or project your screen with your class.'
          })
        );
        break;
      }
      case 'Message:GoBack': {
        handleLeaveClassMissions();
        break;
      }
      case 'Message:LeavePrompt': {
        showLeavePrompt();
        break;
      }
      case 'Message:ShowSetupHelp': {
        showSetupHelp();
        break;
      }
      case 'Message:ShowWaitingRoomHelp': {
        showWaitingRoomHelp();
        break;
      }
      case 'Message:ShowMissionHelp': {
        showMissionHelp();
        break;
      }
      case 'Message:ShowResultsHelp': {
        showResultsHelp();
        break;
      }
    }
  };

  useEffect(() => {
    if (players.length > 0) {
      /**
       * Handle the state of the PlayCanvas game changing. The UI updates to stay in sync.
       * @param msg - Data from the game
       */
      setGamePlayers();
      if (!rewardRef.current) {
        getReward();
      }
    }
  }, [players]);

  const getReward = () => {
    dispatch(fetchApi({ url: 'rewards', method: APIMethods.GET })).then(
      (response: AxiosResponse) => {
        const { data } = response;
        setRewardRef(data.rewards);
        console.log('gamePlayers::', gamePlayers);
        sendPlayCanvasMessage('Message:InitializationData', {
          currentClass,
          metrics,
          players: gamePlayers.current,
          token: login.token,
          rewards: data.rewards,
          isClassMissionTrialUser: isClassMissionTrialUser
        });
      }
    );
  };

  const toggleFullScreen = () => {
    const iframe: any = document.getElementById('frame');
    if (iframe) {
      iframe.requestFullScreen =
        iframe.requestFullScreen ||
        iframe.mozRequestFullScreen ||
        iframe.webkitRequestFullScreen;
    }
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    } else {
      iframe.requestFullScreen();
    }
  };

  /**
   * Send data to the game through postMessage to its iframe window
   * @param data - Data to be read by the game
   */
  const sendPlayCanvasMessage = (action: string, data: any = null) => {
    data.userId = +data.userId;

    if (playcanvas?.current?.contentWindow) {
      playcanvas.current.contentWindow.postMessage({ ...data, action }, '*');
    }
  };

  useEffect(() => {
    const socketOptions = {
      path: '/realtime/socket.io',
      query: {
        classCode: currentClass.code
      },
      withCredentials: true
    };

    questionWindowSocket.current = io(
      `${process.env.REACT_APP_REALTIME_WEBSOCKET_ENDPOINT_NEW}questionWindow`,
      socketOptions
    );

    notificationSocket.current = io(
      `${process.env.REACT_APP_REALTIME_WEBSOCKET_ENDPOINT_NEW}notification`,
      socketOptions
    );

    questionWindowSocket.current.on('connect', () => {
      console.log('Question window namespace connected');
      dispatch(hide('spinner'));
      dispatch(show('snackbar', { variant: 'success', message: 'Connected!' }));
    });

    notificationSocket.current.on('connect', () => {
      console.log('Notification namespace connected');
    });

    questionWindowSocket.current.on('disconnect', () => {
      console.log('Question window namespace disconnected');
    });

    notificationSocket.current.on('disconnect', () => {
      console.log('Notification namespace disconnected');
    });

    questionWindowSocket.current.on('error', (err: any) => {
      console.log('Question Window namespace error', err);
    });

    notificationSocket.current.on('error', (err: any) => {
      console.log('Notification namespace error', err);
    });

    questionWindowSocket.current.on(
      'player-info',
      (playerData: NewServerMessage) => {
        const message: NewServerMessage = playerData;
        // console.log('player-info Message::', message);
        dispatch(newUpdateRealTimeData(message));
        switch (playerData.event) {
          case 'initial-data':
            break;
          case 'question-answered':
            sendPlayCanvasMessage('Message:PlayerOnline', message.playerInfo);
            sendPlayCanvasMessage(
              'Message:QuestionAnswered',
              message.playerInfo
            );

            break;
          case 'player-login':
            sendPlayCanvasMessage('Message:PlayerOnline', message.playerInfo);
            break;
          case 'player-logout':
            break;
          case 'player-timeout':
            break;
        }
      }
    );

    return () => {
      questionWindowSocket.current?.disconnect();
      questionWindowSocket.current?.off('connect');
      questionWindowSocket.current?.off('disconnect');
      questionWindowSocket.current?.off('pong');
      notificationSocket.current?.disconnect();
    };
  }, [currentClass.code, dispatch]);

  useEffect(() => {
    if (!isConnected) {
      if (!isEventListenerInitialized) {
        /**
         * Remove listener just in case its defined
         */
        window.removeEventListener('message', onPlayCanvasMessage);
        window.addEventListener('message', onPlayCanvasMessage);
        setIsEventListenerInitialized(true);
      }
      dispatch(show('spinner', { text: 'Connecting to New Real-Time data' }));
      setConnected(true);
    }
    return () => {
      try {
        window.removeEventListener('message', onPlayCanvasMessage);
      } catch (err) {}
    };
  }, []);

  const handleLeaveClassMissions = () => {
    dispatch(push(teacherRoutes.classMissions + `?goBack=1`));
    dispatch(hide('modal'));
  };

  const handleBackFromMission = () => {
    sendPlayCanvasMessage('Message:GoToSetup');
    dispatch(hide('modal'));
    const classMissionId = currentClassMissionRef.current.id;
    if (classMissionId) {
      ClassMissionService.pauseClassMission(classMissionId);
    }
    createClassMission();
  };

  /**
   * Shown when click "back" within the game while a mission is in-progress
   */
  const showLeavePrompt = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Leave Class Mission',
        children: () => (
          <Typography>
            Are you sure you want to stop the Class Mission? Any ongoing
            progress will not be saved and players will not receive any rewards.
          </Typography>
        ),
        actions: () => {
          return (
            <>
              <Button primary={true} onClick={handleBackFromMission}>
                Yes
              </Button>
            </>
          );
        }
      })
    );
  };

  /**
   * Info popup triggered by clicking the ? on the setup UI
   */
  const showSetupHelp = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Mission Setup',
        children: () => (
          <>
            <Typography>
              <b>Length of Mission: </b>
              How long players have to answer questions. The timer only starts
              once you exit the waiting room.
            </Typography>
            <br></br>
            <Typography>
              <b>Mission Difficulty: </b>
              Determines the number of questions per minute set for each player
              as a goal.
            </Typography>
            <br></br>
            <Typography>
              <b>Questions per Player: </b>
              Calculated based on the selected difficulty and length of the
              mission. As the goal is shared, players may answer greater or
              fewer questions than this.
            </Typography>
            <br></br>
            <Typography>
              <b>Example: </b>6 questions per player for 10 players will create
              a mission with a 60 question goal. As long as 60 questions are
              answered correctly before the timer runs out it will be
              successful. Regardless of whether or not individual players
              answered exactly 6 correct.
            </Typography>
          </>
        )
      })
    );
  };

  /**
   * Info popup triggered by clicking the ? on the waiting room UI
   */
  const showWaitingRoomHelp = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Waiting Room',
        children: (
          <>
            <Typography>
              See your players log into Dreamscape and join the mission. Click
              “Start Mission” when you are ready to begin.
            </Typography>
            <br></br>
            <Typography>
              <b>Screen-Share: </b>
              It is recommended to start screen sharing with your class before
              the mission begins.
            </Typography>
            <br></br>
            <Typography>
              <b>Late Arrivals: </b>
              Players can still participate if they log in while the mission is
              in-progress.
            </Typography>
          </>
        )
      })
    );
  };

  /**
   * Info popup triggered by clicking the ? during a mission
   */
  const showMissionHelp = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Mission In-Progress',
        children: (
          <Typography>
            Cheer on your class as they focus on answering questions to defeat
            the creature! Any questions that your players answer correctly count
            towards mission progress.
          </Typography>
        )
      })
    );
  };

  /**
   * Info popup triggered by clicking the ? on the results UI
   */
  const showResultsHelp = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'Mission Results',
        children: (
          <>
            <Typography>
              Share your mission statistics with your class.
            </Typography>
            <br></br>
            <Typography>
              <b>Rewards: </b>
              If your class completes a mission, each player is rewarded in-game
              with the item shown on-screen. If your class was unable to
              complete the mission, consider lowering the difficulty.
            </Typography>
            <br></br>
            <Typography>
              <b>Play Again: </b>
              Creates a mission with the same parameters and takes you back to
              the Waiting Room.
            </Typography>
            <br></br>
            <Typography>
              <b>New Mission: </b>
              Takes you back to the Mission Setup screen where you can change
              the parameters for a new mission.
            </Typography>
          </>
        )
      })
    );
  };

  return (
    <View title="New Class Mission">
      <iframe
        ref={playcanvas}
        title="Game"
        id="frame"
        src={`${process.env.REACT_APP_CLASSMISSIONS_URL}`}
        style={{ height: '90%', width: '100%', border: 'hidden' }}
      ></iframe>
      <div
        className={clsx(
          classes.closeButton,
          isMobile && classes.closeButtonMobile
        )}
      ></div>
    </View>
  );
};

export default NewClassMissionsGameView;
