import { premiumPriceChangeStart } from '../../../../layouts/teacher/TeacherFeatureFlagContext';

// export const premiumPlusBreakDownData = premiumPriceChangeStart()
//   ? [
//       {
//         id: 0,
//         memberships: 0,
//         totalPlanCost: 29,
//         premium: 29,
//         totalCost: 29,
//         savings: 0
//       },
//       {
//         id: 1,
//         memberships: 10,
//         totalPlanCost: 70,
//         premium: 29,
//         totalCost: 99,
//         savings: 0
//       },
//       {
//         id: 2,
//         memberships: 30,
//         totalPlanCost: 120,
//         premium: 29,
//         totalCost: 149,
//         savings: 50
//       }
//       // TODO: COMPLETELY REMOVE WHEN REMOVING PREMIUM PLUS PLAN
//       // {
//       //   id: 3,
//       //   memberships: 100,
//       //   totalPlanCost: 270,
//       //   premium: 29,
//       //   totalCost: 299,
//       //   savings: 70
//       // },
//       // {
//       //   id: 4,
//       //   memberships: 250,
//       //   totalPlanCost: 470,
//       //   premium: 29,
//       //   totalCost: 499,
//       //   savings: 80
//       // }
//     ]
//   : [
//       {
//         id: 0,
//         memberships: 0,
//         totalPlanCost: 19,
//         premium: 19,
//         totalCost: 19,
//         savings: 0
//       },
//       {
//         id: 1,
//         memberships: 10,
//         totalPlanCost: 80,
//         premium: 19,
//         totalCost: 99,
//         savings: 0
//       },
//       {
//         id: 2,
//         memberships: 30,
//         totalPlanCost: 130,
//         premium: 19,
//         totalCost: 149,
//         savings: 50
//       }
//       // TODO: COMPLETELY REMOVE WHEN REMOVING PREMIUM PLUS PLAN
//       // {
//       //   id: 3,
//       //   memberships: 100,
//       //   totalPlanCost: 280,
//       //   premium: 19,
//       //   totalCost: 299,
//       //   savings: 70
//       // },
//       // {
//       //   id: 4,
//       //   memberships: 250,
//       //   totalPlanCost: 480,
//       //   premium: 19,
//       //   totalCost: 499,
//       //   savings: 80
//       // }
//     ];

export const premiumPlusBreakDownData = premiumPriceChangeStart()
  ? [
      {
        id: 0,
        memberships: 0,
        totalPlanCost: 29,
        premium: 29,
        totalCost: 29,
        savings: 0
      },
      {
        id: 1,
        memberships: 1,
        totalPlanCost: 60,
        premium: 29,
        totalCost: 89,
        savings: 0
      },
      {
        id: 2,
        memberships: 2,
        totalPlanCost: 84,
        premium: 29,
        totalCost: 113,
        savings: 30
      },
      {
        id: 3,
        memberships: 3,
        totalPlanCost: 85.5,
        premium: 29,
        totalCost: 114.5,
        savings: 53
      },
      {
        id: 4,
        memberships: 4,
        totalPlanCost: 86,
        premium: 29,
        totalCost: 115,
        savings: 64
      },
      {
        id: 5,
        memberships: 5,
        totalPlanCost: 87.5,
        premium: 29,
        totalCost: 116.5,
        savings: 71
      },
      {
        id: 6,
        memberships: 6,
        totalPlanCost: 88.5,
        premium: 29,
        totalCost: 117.5,
        savings: 75
      },
      {
        id: 7,
        memberships: 7,
        totalPlanCost: 89.25,
        premium: 29,
        totalCost: 118.25,
        savings: 79
      },
      {
        id: 8,
        memberships: 8,
        totalPlanCost: 92,
        premium: 29,
        totalCost: 121,
        savings: 81
      },
      {
        id: 9,
        memberships: 9,
        totalPlanCost: 94.5,
        premium: 29,
        totalCost: 123.5,
        savings: 83
      },
      {
        id: 10,
        memberships: 10,
        totalPlanCost: 100,
        premium: 29,
        totalCost: 129,
        savings: 83
      },
      {
        id: 11,
        memberships: 11,
        totalPlanCost: 106.7,
        premium: 29,
        totalCost: 135.7,
        savings: 84
      },
      {
        id: 12,
        memberships: 12,
        totalPlanCost: 112.8,
        premium: 29,
        totalCost: 141.8,
        savings: 84
      },
      {
        id: 13,
        memberships: 13,
        totalPlanCost: 118.3,
        premium: 29,
        totalCost: 147.3,
        savings: 85
      },
      {
        id: 14,
        memberships: 14,
        totalPlanCost: 123.2,
        premium: 29,
        totalCost: 152.2,
        savings: 85
      },
      {
        id: 15,
        memberships: 15,
        totalPlanCost: 127.5,
        premium: 29,
        totalCost: 156.5,
        savings: 86
      },
      {
        id: 16,
        memberships: 16,
        totalPlanCost: 131.2,
        premium: 29,
        totalCost: 160.2,
        savings: 86
      },
      {
        id: 17,
        memberships: 17,
        totalPlanCost: 134.3,
        premium: 29,
        totalCost: 163.3,
        savings: 87
      },
      {
        id: 18,
        memberships: 18,
        totalPlanCost: 136.8,
        premium: 29,
        totalCost: 165.8,
        savings: 87
      },
      {
        id: 19,
        memberships: 19,
        totalPlanCost: 138.7,
        premium: 29,
        totalCost: 167.7,
        savings: 88
      },
      {
        id: 20,
        memberships: 20,
        totalPlanCost: 140,
        premium: 29,
        totalCost: 169,
        savings: 88
      },
      {
        id: 21,
        memberships: 21,
        totalPlanCost: 144.9,
        premium: 29,
        totalCost: 173.9,
        savings: 89
      },
      {
        id: 22,
        memberships: 22,
        totalPlanCost: 149.6,
        premium: 29,
        totalCost: 178.6,
        savings: 89
      },
      {
        id: 23,
        memberships: 23,
        totalPlanCost: 154.1,
        premium: 29,
        totalCost: 183.1,
        savings: 89
      },
      {
        id: 24,
        memberships: 24,
        totalPlanCost: 158.4,
        premium: 29,
        totalCost: 187.4,
        savings: 89
      },
      {
        id: 25,
        memberships: 25,
        totalPlanCost: 162.5,
        premium: 29,
        totalCost: 191.5,
        savings: 89
      },
      {
        id: 26,
        memberships: 26,
        totalPlanCost: 166.4,
        premium: 29,
        totalCost: 195.4,
        savings: 89
      },
      {
        id: 27,
        memberships: 27,
        totalPlanCost: 170.1,
        premium: 29,
        totalCost: 199.1,
        savings: 90
      },
      {
        id: 28,
        memberships: 28,
        totalPlanCost: 173.6,
        premium: 29,
        totalCost: 202.6,
        savings: 90
      },
      {
        id: 29,
        memberships: 29,
        totalPlanCost: 176.9,
        premium: 29,
        totalCost: 209,
        savings: 90
      },
      {
        id: 30,
        memberships: 30,
        totalPlanCost: 180,
        premium: 29,
        totalCost: 209,
        savings: 90
      },
      {
        id: 31,
        memberships: 31,
        totalPlanCost: 182.9,
        premium: 29,
        totalCost: 211.9,
        savings: 90
      },
      {
        id: 32,
        memberships: 32,
        totalPlanCost: 185.6,
        premium: 29,
        totalCost: 214.6,
        savings: 90
      },
      {
        id: 33,
        memberships: 33,
        totalPlanCost: 188.1,
        premium: 29,
        totalCost: 217.1,
        savings: 91
      },
      {
        id: 34,
        memberships: 34,
        totalPlanCost: 190.4,
        premium: 29,
        totalCost: 219.4,
        savings: 91
      },
      {
        id: 35,
        memberships: 35,
        totalPlanCost: 192.5,
        premium: 29,
        totalCost: 221.5,
        savings: 91
      },
      {
        id: 36,
        memberships: 36,
        totalPlanCost: 194.4,
        premium: 29,
        totalCost: 223.4,
        savings: 91
      },
      {
        id: 37,
        memberships: 37,
        totalPlanCost: 196.1,
        premium: 29,
        totalCost: 225.1,
        savings: 91
      },
      {
        id: 38,
        memberships: 38,
        totalPlanCost: 197.6,
        premium: 29,
        totalCost: 226.6,
        savings: 91
      },
      {
        id: 39,
        memberships: 39,
        totalPlanCost: 198.9,
        premium: 29,
        totalCost: 227.9,
        savings: 92
      },
      {
        id: 40,
        memberships: 40,
        totalPlanCost: 200,
        premium: 29,
        totalCost: 229,
        savings: 92
      }
    ]
  : [
      {
        id: 0,
        memberships: 0,
        totalPlanCost: 29,
        premium: 19,
        totalCost: 19,
        savings: 0
      },
      {
        id: 1,
        memberships: 1,
        totalPlanCost: 60,
        premium: 19,
        totalCost: 79,
        savings: 0
      },
      {
        id: 2,
        memberships: 2,
        totalPlanCost: 84,
        premium: 19,
        totalCost: 103,
        savings: 30
      },
      {
        id: 3,
        memberships: 3,
        totalPlanCost: 85.5,
        premium: 19,
        totalCost: 104.5,
        savings: 53
      },
      {
        id: 4,
        memberships: 4,
        totalPlanCost: 86,
        premium: 19,
        totalCost: 105,
        savings: 64
      },
      {
        id: 5,
        memberships: 5,
        totalPlanCost: 87.5,
        premium: 19,
        totalCost: 106.5,
        savings: 71
      },
      {
        id: 6,
        memberships: 6,
        totalPlanCost: 88.5,
        premium: 19,
        totalCost: 107.5,
        savings: 75
      },
      {
        id: 7,
        memberships: 7,
        totalPlanCost: 89.25,
        premium: 19,
        totalCost: 108.25,
        savings: 79
      },
      {
        id: 8,
        memberships: 8,
        totalPlanCost: 92,
        premium: 19,
        totalCost: 111,
        savings: 81
      },
      {
        id: 9,
        memberships: 9,
        totalPlanCost: 94.5,
        premium: 19,
        totalCost: 113.5,
        savings: 83
      },
      {
        id: 10,
        memberships: 10,
        totalPlanCost: 100,
        premium: 19,
        totalCost: 119,
        savings: 83
      },
      {
        id: 11,
        memberships: 11,
        totalPlanCost: 106.7,
        premium: 19,
        totalCost: 125.7,
        savings: 84
      },
      {
        id: 12,
        memberships: 12,
        totalPlanCost: 112.8,
        premium: 19,
        totalCost: 131.8,
        savings: 84
      },
      {
        id: 13,
        memberships: 13,
        totalPlanCost: 118.3,
        premium: 19,
        totalCost: 137.3,
        savings: 85
      },
      {
        id: 14,
        memberships: 14,
        totalPlanCost: 123.2,
        premium: 19,
        totalCost: 142.2,
        savings: 85
      },
      {
        id: 15,
        memberships: 15,
        totalPlanCost: 127.5,
        premium: 19,
        totalCost: 146.5,
        savings: 86
      },
      {
        id: 16,
        memberships: 16,
        totalPlanCost: 131.2,
        premium: 19,
        totalCost: 150.2,
        savings: 86
      },
      {
        id: 17,
        memberships: 17,
        totalPlanCost: 134.3,
        premium: 19,
        totalCost: 153.3,
        savings: 87
      },
      {
        id: 18,
        memberships: 18,
        totalPlanCost: 136.8,
        premium: 19,
        totalCost: 155.8,
        savings: 87
      },
      {
        id: 19,
        memberships: 19,
        totalPlanCost: 138.7,
        premium: 19,
        totalCost: 157.7,
        savings: 88
      },
      {
        id: 20,
        memberships: 20,
        totalPlanCost: 140,
        premium: 19,
        totalCost: 159,
        savings: 88
      },
      {
        id: 21,
        memberships: 21,
        totalPlanCost: 144.9,
        premium: 19,
        totalCost: 163.9,
        savings: 89
      },
      {
        id: 22,
        memberships: 22,
        totalPlanCost: 149.6,
        premium: 19,
        totalCost: 168.6,
        savings: 89
      },
      {
        id: 23,
        memberships: 23,
        totalPlanCost: 154.1,
        premium: 19,
        totalCost: 173.1,
        savings: 89
      },
      {
        id: 24,
        memberships: 24,
        totalPlanCost: 158.4,
        premium: 19,
        totalCost: 177.4,
        savings: 89
      },
      {
        id: 25,
        memberships: 25,
        totalPlanCost: 162.5,
        premium: 19,
        totalCost: 181.5,
        savings: 89
      },
      {
        id: 26,
        memberships: 26,
        totalPlanCost: 166.4,
        premium: 19,
        totalCost: 185.4,
        savings: 89
      },
      {
        id: 27,
        memberships: 27,
        totalPlanCost: 170.1,
        premium: 19,
        totalCost: 189.1,
        savings: 90
      },
      {
        id: 28,
        memberships: 28,
        totalPlanCost: 173.6,
        premium: 19,
        totalCost: 192.6,
        savings: 90
      },
      {
        id: 29,
        memberships: 29,
        totalPlanCost: 176.9,
        premium: 19,
        totalCost: 195.9,
        savings: 90
      },
      {
        id: 30,
        memberships: 30,
        totalPlanCost: 180,
        premium: 19,
        totalCost: 199,
        savings: 90
      },
      {
        id: 31,
        memberships: 31,
        totalPlanCost: 182.9,
        premium: 19,
        totalCost: 201.9,
        savings: 90
      },
      {
        id: 32,
        memberships: 32,
        totalPlanCost: 185.6,
        premium: 19,
        totalCost: 204.6,
        savings: 90
      },
      {
        id: 33,
        memberships: 33,
        totalPlanCost: 188.1,
        premium: 19,
        totalCost: 207.1,
        savings: 91
      },
      {
        id: 34,
        memberships: 34,
        totalPlanCost: 190.4,
        premium: 19,
        totalCost: 209.4,
        savings: 91
      },
      {
        id: 35,
        memberships: 35,
        totalPlanCost: 192.5,
        premium: 19,
        totalCost: 211.5,
        savings: 91
      },
      {
        id: 36,
        memberships: 36,
        totalPlanCost: 194.4,
        premium: 19,
        totalCost: 213.4,
        savings: 91
      },
      {
        id: 37,
        memberships: 37,
        totalPlanCost: 196.1,
        premium: 19,
        totalCost: 215.1,
        savings: 91
      },
      {
        id: 38,
        memberships: 38,
        totalPlanCost: 197.6,
        premium: 19,
        totalCost: 216.6,
        savings: 91
      },
      {
        id: 39,
        memberships: 39,
        totalPlanCost: 198.9,
        premium: 19,
        totalCost: 217.9,
        savings: 92
      },
      {
        id: 40,
        memberships: 40,
        totalPlanCost: 200,
        premium: 19,
        totalCost: 219,
        savings: 92
      }
    ];
