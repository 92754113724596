import React from 'react';
import { makeStyles } from '@material-ui/core';
import ClassCodeImg from '../../assets/icons/popups/onboarding_imgs_01.png';
import colors from '../../styles/colors';
import { isMobile } from 'react-device-detect';

interface ClassCodeBoardProps {
  classCode: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    margin: '0 auto',
    width: 350
  },
  image: {
    display: 'block',
    margin: '0 auto',
    width: '100%'
  },
  classCode: {
    color: colors.white,
    display: 'block',
    fontSize: '2.3rem !important',
    fontWeight: 500,
    left: '40px',
    letterSpacing: 7,
    position: 'absolute',
    top: '85px',
    zIndex: 9
  }
}));

const ClassCodeBoard: React.FC<ClassCodeBoardProps> = (
  props: ClassCodeBoardProps
) => {
  const classes = useStyles();

  if (isMobile) {
    return null;
  }

  return (
    <div className={classes.wrapper} style={props.style}>
      <span className={classes.classCode}>{props.classCode}</span>
      <img src={ClassCodeImg} className={classes.image} />
    </div>
  );
};

export default ClassCodeBoard;
