import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Icon,
  Tooltip,
  makeStyles,
  Link,
  CircularProgress
} from '@material-ui/core';
import ClassActivityReportToolIcon from '../../assets/icons/report/class-activity-icon.png';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import colours from '../../styles/colors';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { userTypesDashboardRoute } from '../../constants/users';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { push } from 'connected-react-router';

interface ReportToolClassActivityProps {
  isWidget?: boolean;
}

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '0.1px solid #000'
  }
});

const ReportToolNewLEClassActivity: React.FC<ReportToolClassActivityProps> = ({
  isWidget
}: ReportToolClassActivityProps) => {
  const { currentClass } = useSelector((state: any) => state.class);
  const [questionAttempts, setQuestionAttempts] = useState<
    {
      week: string;
      Total: number;
      Correct: number;
      Incorrect: number;
      Accuracy: number;
    }[]
  >([]);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const userType = useSelector((state: any) => state.userData.userType);
  const playerIds: number[] = currentClass.roster.map(
    (user: any) => user.educationUserId
  );
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  useEffect(() => {
    setLoading(true);
    fetchQuestionAttempts(playerIds)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  async function fetchQuestionAttempts(learnerIds: number[]) {
    try {
      const today = new Date();
      const startOfFirstWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 5 * 7
      );
      const response = await dispatch(
        fetchApi({
          url: `v1/learners/question-attempts`,
          method: APIMethods.POST,
          endpoint: APIEndpoints.LE_API,
          data: {
            learnerIds: learnerIds,
            from: startOfFirstWeek.toISOString(),
            to: today.toISOString()
          }
        })
      );
      const questionsData: any = response.data;
      if (questionsData) {
        const now = new Date();
        const dayOfWeek = now.getDay();
        const startOfWeek = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1),
          0,
          0,
          0
        );
        const endOfWeek = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : 7),
          23,
          59,
          59
        );

        const weeks: { start: Date; end: Date }[] = [];

        for (let i = 0; i < 5; i++) {
          const start = new Date(startOfWeek.getTime() - 7 * i * 86400000);
          const end = new Date(endOfWeek.getTime() - 7 * i * 86400000);
          weeks.unshift({ start, end });
        }
        weeks.sort((a, b) => b.start.getTime() - a.start.getTime());
        const questionAttemptsByWeek: any = weeks.map(week => {
          const weekStart = week.start.toISOString();
          const weekEnd = week.end.toISOString();
          const Correct = questionsData.filter(
            (attempt: any) =>
              attempt.isCorrectAnswer &&
              attempt.attemptDate >= weekStart &&
              attempt.attemptDate <= weekEnd
          ).length;
          const Incorrect = questionsData.filter(
            (attempt: any) =>
              !attempt.isCorrectAnswer &&
              attempt.attemptDate >= weekStart &&
              attempt.attemptDate <= weekEnd
          ).length;
          const Total = Correct + Incorrect;
          const Accuracy =
            Total > 0 ? Math.round(((Correct / Total) * 100) / 5) * 5 : 0;
          const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ];
          const formattedWeek =
            week.start.getTime() === startOfWeek.getTime() &&
            week.end.getTime() === endOfWeek.getTime()
              ? 'This week'
              : week.start.getTime() ===
                  new Date(startOfWeek.getTime() - 7 * 86400000).getTime() &&
                week.end.getTime() ===
                  new Date(endOfWeek.getTime() - 7 * 86400000).getTime()
              ? 'Previous week'
              : monthNames[week.start.getMonth()].slice(0, 3);

          const actualWeek = `${
            monthNames[week.start.getMonth()]
          } ${week.start.getDate()} - ${
            monthNames[week.end.getMonth()]
          } ${week.end.getDate()}`;

          return {
            week: formattedWeek,
            actualWeek,
            Total,
            Correct,
            Incorrect,
            Accuracy
          };
        });
        setQuestionAttempts(questionAttemptsByWeek);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: '#fff', padding: '10px' }}
        >
          <Typography
            style={{ fontSize: '11px' }}
          >{`Week: ${data.actualWeek}`}</Typography>
          <Typography
            style={{ fontSize: '11px' }}
          >{`Total: ${data.Total}`}</Typography>
          <Typography
            style={{ fontSize: '11px', color: '#63da8b' }}
          >{`Correct: ${data.Correct}`}</Typography>
          <Typography
            style={{ fontSize: '11px', color: '#da6398' }}
          >{`Incorrect: ${data.Incorrect}`}</Typography>
          <Typography
            style={{ fontSize: '11px' }}
          >{`Accuracy: ${data.Accuracy}%`}</Typography>
        </div>
      );
    }
    return null;
  };
  const viewReport = () => {
    return (dispatch: any) => {
      const path = `${userTypesDashboardRoute[userType]}/reports/newLEClassActivity/${classCode}`;
      dispatch(push(path));
    };
  };

  return (
    <>
      <Paper style={{ padding: 12, position: 'relative', height: 380 }}>
        <Grid container item xs={6} style={{ paddingBottom: '15px' }}>
          <Grid item xs={12}>
            <Typography variant="h6">Class Activity</Typography>
          </Grid>
        </Grid>
        {!loading ? (
          <>
            {questionAttempts.length >= 5 &&
            !questionAttempts.every(item => item.Total === 0) ? (
              <Grid container>
                <Grid item xs={5} alignItems="center">
                  <Grid container justify="flex-start">
                    <Typography
                      variant="subtitle1"
                      style={{ paddingBottom: '35px' }}
                    >
                      This week
                    </Typography>
                  </Grid>

                  <Grid container justify="space-around" direction="row">
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      xs={6}
                    >
                      <Grid item style={{ position: 'relative' }}>
                        <Typography
                          variant="h2"
                          style={{
                            fontSize: 30,
                            fontWeight: 700,
                            width: '80px',
                            textAlign: 'center'
                          }}
                        >
                          {questionAttempts[0].Total}
                        </Typography>

                        {questionAttempts[0].Total - questionAttempts[1].Total >
                        0 ? (
                          <Icon
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-35px'
                            }}
                          >
                            <ArrowUpward
                              fontSize="small"
                              style={{ color: 'green' }}
                            />
                          </Icon>
                        ) : (
                          <Icon
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-35px'
                            }}
                          >
                            <ArrowDownward
                              fontSize="small"
                              style={{ color: 'red' }}
                            />
                          </Icon>
                        )}
                        <Tooltip
                          title={`Last week: ${questionAttempts[1].Total}`}
                          classes={{
                            tooltip: styles.customTooltip
                          }}
                        >
                          <Typography
                            style={{
                              position: 'absolute',
                              top: '-6px',
                              right: '-60px',
                              fontSize: 16,
                              color:
                                questionAttempts[0].Total -
                                  questionAttempts[1].Total >
                                0
                                  ? 'green'
                                  : 'red'
                            }}
                          >
                            {Math.abs(
                              questionAttempts[0].Total -
                                questionAttempts[1].Total
                            )}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: 11,
                            fontWeight: 600,
                            marginTop: '5px'
                          }}
                        >
                          Total answered
                        </Typography>
                      </Grid>
                      <br />
                      <br />
                      <Grid item style={{ position: 'relative' }}>
                        <Typography
                          variant="h2"
                          style={{
                            fontSize: 30,
                            fontWeight: 700,
                            width: '80px',
                            textAlign: 'center'
                          }}
                        >
                          {questionAttempts[0].Accuracy}%
                        </Typography>
                        {questionAttempts[0].Accuracy -
                          questionAttempts[1].Accuracy >
                        0 ? (
                          <Icon
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-35px'
                            }}
                          >
                            <ArrowUpward
                              fontSize="small"
                              style={{ color: 'green' }}
                            />
                          </Icon>
                        ) : (
                          <Icon
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-35px'
                            }}
                          >
                            <ArrowDownward
                              fontSize="small"
                              style={{ color: 'red' }}
                            />
                          </Icon>
                        )}
                        <Tooltip
                          title={`Last week: ${questionAttempts[1].Accuracy}`}
                          classes={{
                            tooltip: styles.customTooltip
                          }}
                        >
                          <Typography
                            style={{
                              position: 'absolute',
                              top: '-6px',
                              right: '-72px',
                              fontSize: 16,
                              color:
                                questionAttempts[0].Accuracy -
                                  questionAttempts[1].Accuracy >
                                0
                                  ? 'green'
                                  : 'red'
                            }}
                          >
                            {Math.abs(
                              questionAttempts[0].Accuracy -
                                questionAttempts[1].Accuracy
                            )}
                            %
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: 11,
                            fontWeight: 600,
                            marginTop: '5px'
                          }}
                        >
                          Accuracy
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ marginLeft: '22px' }}
                />
                <Grid item alignItems="center" xs={6}>
                  <Typography
                    variant="subtitle1"
                    style={{ paddingLeft: '7px' }}
                  >
                    Week-by-week
                  </Typography>

                  <BarChart
                    width={230}
                    height={220}
                    data={questionAttempts}
                    layout="vertical"
                    margin={{
                      top: 10,
                      right: 10,
                      left: 10,
                      bottom: -10
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      type="number"
                      label={{
                        value: 'Questions answered',
                        dy: 12,
                        fontSize: 10.5
                      }}
                      tick={{
                        fontSize: 11
                      }}
                    />
                    <YAxis
                      type="category"
                      dataKey="week"
                      tick={{
                        fontSize: 11
                      }}
                    />
                    <RechartsTooltip content={<CustomTooltip />} />
                    <Legend
                      iconSize={12}
                      height={1}
                      wrapperStyle={{ marginTop: 10, marginBottom: -20 }}
                    />
                    <Bar dataKey="Correct" stackId="a" fill="#63da8b" />
                    <Bar dataKey="Incorrect" stackId="a" fill="#da6398" />
                  </BarChart>
                </Grid>
              </Grid>
            ) : (
              <>
                <div style={{ textAlign: 'center', paddingTop: '35px' }}>
                  <img
                    src={ClassActivityReportToolIcon}
                    style={{ height: '120px', width: '120px' }}
                  ></img>
                </div>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '14px',
                    paddingTop: '35px',
                    textAlign: 'center'
                  }}
                >
                  {currentClass.roster.length
                    ? 'There is no player data for this time period. Get your players to use Dreamscape in class or at home!'
                    : 'Add a player to see reports'}
                </Typography>
              </>
            )}
          </>
        ) : (
          <>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography>Loading data</Typography>
              </Grid>
            </Grid>
          </>
        )}

        <div style={{ position: 'absolute', top: 12, right: 12 }}>
          <Link
            component="button"
            style={{ color: colours.primary, fontSize: 13, fontWeight: 600 }}
            onClick={() => {
              dispatch(viewReport());
            }}
          >
            Full Report
          </Link>
        </div>
      </Paper>
    </>
  );
};

export default ReportToolNewLEClassActivity;
