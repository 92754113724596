import React, { PropsWithChildren, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
const useStyles = makeStyles({
  header: {
    cursor: 'pointer'
  },
  divider: {
    marginTop: '.8rem',
    paddingBottom: '.5rem'
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 700
  }
});

interface FilterDropDownProps {
  title: string;
  renderChips?: any;
  onClose?: any;
  showAll?: boolean;
}
const FilterDropDown = (props: PropsWithChildren<FilterDropDownProps>) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const { title, renderChips, onClose, showAll } = props;
  const RenderChips = renderChips;
  const handleShow = () => {
    setShow(!show);
    if (!show && onClose) {
      onClose(!show);
    }
  };
  return (
    <Grid container>
      <Grid className={classes.divider} item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} className={classes.header} onClick={handleShow}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <b>{title}</b>
          <div>{show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</div>
        </Box>
        {showAll && <span>All</span>}
      </Grid>
      {renderChips && <RenderChips />}
      {show && (
        <Grid item xs={12} style={{ marginTop: '.5rem' }}>
          {props.children}
        </Grid>
      )}
    </Grid>
  );
};
export default FilterDropDown;
