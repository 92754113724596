import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { IPardotPayload } from '../../types/pardot/pardot-payload-type';

export const submitRegistrationToPardot = (data: IPardotPayload) => {
  data.product = 'dreamscape';
  try {
    return (dispatch: any) => {
      dispatch(
        fetchApi({
          url: 'form',
          method: APIMethods.POST,
          data
        })
      );
  };
  } catch (err) {
    console.error(err);
  }
};
