import ExitToApp from '@material-ui/icons/ExitToApp';
import AccountBox from '@material-ui/icons/AccountBox';
import { isMobile } from 'react-device-detect';
import { SideBarActions } from '../../../types/sidebar/sidebar-type';
import { Help } from '@material-ui/icons';

export const mobileSideItems = isMobile
  ? [
      {
        title: 'My Account',
        icon: AccountBox,
        to: SideBarActions.MyAccount
      },
      {
        title: 'Help',
        icon: Help,
        to: SideBarActions.Support
      },
      {
        title: 'Logout',
        icon: ExitToApp,
        to: SideBarActions.Logout
      }
    ]
  : [];
