const namespaceUser = '[User]';

// authentication
export const USER_LOGIN = `${namespaceUser}: LOGIN USER`;
export const USER_LOGIN_FAILED = `${namespaceUser}: LOGIN USER FAILED`;
export const USER_LOGOUT = `${namespaceUser}: LOGOUT USER`;
// user information
export const USER_EDIT_INFORMATION = `${namespaceUser}: EDIT USER INFORMATION`;
export const USER_EDIT_FEATURES = `${namespaceUser}: EDIT USER FEATURES`;
export const USER_UPDATE_DATA = `${namespaceUser}: USER UPDATE DATA`;
export const USER_EDIT_INFORMATION_FAILED = `${namespaceUser}: EDIT USER INFORMATION FAILED`;
export const USER_UPDATE_REGISTRATION_STEP = `${namespaceUser}: UPDATE REGISTRATION STEP`;
export const USER_GET_SUCCESS = `${namespaceUser}: GET SUCCESS`;
export const USER_SET_TOKEN = `${namespaceUser}: SET TOKEN`;
export const USER_GET_INFORMATION_FAILED = `${namespaceUser}: GET USER INFORMATION FAILED`;
export const USER_SET_INFORMATION = `${namespaceUser}: SET USER INFORMATION`;
export const USER_GET_REFERRALS = `${namespaceUser}: USER GET REFERRALS `;
export const USER_GET_REFERRALS_FAILED = `${namespaceUser}: USER GET REFERRALS FAILED`;
// create account
export const USER_CREATE_ACCOUNT = `${namespaceUser}: USER CREATE ACCOUNT`;
export const USER_CREATE_ACCOUNT_FAILED = `${namespaceUser}: USER CREATE ACCOUNT FAILED`;
// teacher
export const USER_INVITE_PARENT = `${namespaceUser}: USER INVITE PARENT`;
export const USER_INVITE_PARENT_FAILED = `${namespaceUser}: USER INVITE PARENT FAILED`;
// admins
export const USER_ADMIN_SET_ADMIN_VERIFICATION_DATA = `${namespaceUser}: USER ADMIN SET ADMIN VERIFICATION DATA`;
export const USER_ADMIN_GET_TEACHERS = `${namespaceUser}: USER ADMIN GET TEACHERS`;
export const USER_ADMIN_SET_CURRENT_TEACHER = `${namespaceUser}: USER ADMIN SET CURRENT TEACHER`;
export const USER_ADMIN_GET_SCHOOLS = `${namespaceUser}: USER ADMIN GET SCHOOLS`;
export const USER_ADMIN_SET_CURRENT_SCHOOL = `${namespaceUser}: USER ADMIN SET CURRENT SCHOOL`;

export const USER_GET_ALL_TEACHERS_FAILED = `${namespaceUser}: USER GET ALL TEACHERS FAILED`;
export const USER_SEND_TRAINING_EMAIL = `${namespaceUser}: USER SEND TRAINING EMAIL`;
export const USER_SEND_TRAINING_EMAIL_FAILED = `${namespaceUser}: USER SEND TRAINING EMAIL FAILED`;
export const USER_CONFIRM_ACCOUNTS = `${namespaceUser}: USER CONFIRM ACCOUNTS `;
export const USER_CONFIRM_ACCOUNTS_FAILED = `${namespaceUser}: USER CONFIRM ACCOUNTS FAILED`;
export const USER_SET_TEACHER_ID = `${namespaceUser}: USER SET TEACHER ID`;
export const USER_SET_SCHOOL_ID = `${namespaceUser}: USER SET SCHOOL ID`;
