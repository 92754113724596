import DashboardIcon from '@material-ui/icons/Dashboard';
import { CardGiftcard } from '@material-ui/icons';
import StarsIcon from '@material-ui/icons/Stars';
import BookIcon from '@material-ui/icons/MenuBook';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import { parentRoutes } from '../../../constants/routes';
import { SidebarItems } from '../../../types/sidebar/sidebar-type';
import store from '../../../redux/store';
import { mobileSideItems } from './navbar-mobile';
import { CalendarToday } from '@material-ui/icons';

export default (path: string): Array<SidebarItems> => {
  if (
    Object.values(parentRoutes)
      .map((item: string) => item)
      .includes(path)
  ) {
    const disableButton = () => {
      //@ts-ignore
      return !store.getState().class.currentClass.roster.length;
    };

    return [
      {
        title: 'My Players',
        icon: PersonIcon,
        to: parentRoutes.reportCard,
        disabled: disableButton()
      },
      {
        title: 'Report Card',
        icon: DashboardIcon,
        to: parentRoutes.homeScreen
      },
      {
        title: 'Assignments',
        icon: CalendarToday,
        to: parentRoutes.planner,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Give Rewards',
        icon: CardGiftcard,
        to: parentRoutes.rewardsPage,
        params: true,
        disabled: disableButton()
      },
      {
        title: 'Reports +',
        icon: AssessmentIcon,
        to: parentRoutes.reports,
        disabled: disableButton()
      },
      {
        title: 'Resource Hub',
        icon: BookIcon,
        to: parentRoutes.resourceHub,
        disabled: disableButton()
      },
      {
        title: 'Memberships',
        icon: StarsIcon,
        to: parentRoutes.membershipsAll,
        disabled: disableButton(),
        iconStyle: { fill: '#f8bb00' }
      },
      ...mobileSideItems
    ];
  }
  return [];
};
