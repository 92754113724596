import {
    ResourceState,
    resourceHubActions
  } from './resourceHub.types';

export const resourceDataReducer = (
    state: ResourceState,
    action: any
  ): any => {
    const { type, payload } = action;
    var newState;
    switch (type) {
        case resourceHubActions.UPDATE_ALL_RESOURCES: {
            newState = { ...initialState, all: payload};
            return Object.assign({}, state, newState);
        }
        case resourceHubActions.UPDATE_RESOURCE:{
            newState = { ...initialState, selected: payload[0]};
            return Object.assign({}, state, newState);
        }
        default: {
            return {...initialState, ...state}
        }
    }
}

export const initialState: Partial<ResourceState> = {
    all:[],
    selected:{
        _id: '',
        title: '',
        description: '',
        author:'',
        link: '',
        createdDate: '',
        thumbnail:'',
        type: '',
        userTypes: [],
        playlist: []
    }
};
