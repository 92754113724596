import React from 'react';
import { useSelector } from 'react-redux';
import Table from '../../components/table/Table';
import { Grid, Typography, Button } from '@material-ui/core';
import ReportToolTemplate, { getTableOptions } from './ReportToolTemplate';
import { trackViewLeaderboardReport } from '../../redux/actions/mixpanel-actions';
import FirstPlace from '../../assets/icons/1st.png';
import SecondPlace from '../../assets/icons/2nd.png';
import ThirdPlace from '../../assets/icons/3rd.png';
import ReportToolLeaderboardIcon from '../../assets/icons/report/leaderboard-icon.png';
import { Statuses, ReportTools } from '../../redux/features/ReportTool';

interface ReportToolLeaderboardProps {
  isWidget?: boolean;
}

const ReportToolLeaderboard: React.FC<ReportToolLeaderboardProps> = ({
  isWidget
}: ReportToolLeaderboardProps) => {
  const { data, status } = useSelector(
    (state: any) => state.reportTool[ReportTools.Leaderboard]
  );
  const reportToolHasLoaded = status === Statuses.Success;

  let columns = [
    {
      Header: 'Rank',
      accessor: 'rank',
      Cell: (row: any) => {
        const { rank } = row.row.original;
        let source;

        switch (rank) {
          case 1:
            source = FirstPlace;
            break;
          case 2:
            source = SecondPlace;
            break;
          case 3:
            source = ThirdPlace;
            break;
          default:
            source = '';
            break;
        }

        return (
          <Button
            disabled
            startIcon={
              rank <= 3 ? (
                <img src={source} alt="Trophy" height={20} />
              ) : (
                <div style={{ height: 20, width: 20 }} />
              )
            }
          >
            <Typography style={{ color: 'black' }}>{rank}</Typography>
          </Button>
        );
      }
    },
    {
      Header: 'Player',
      accessor: 'fullName'
    },
    {
      Header: 'Correct/Total',
      accessor: (row: any) => {
        return `${row.totalNumberOfCorrectAnswers} / ${row.totalNumberOfAnswers}`;
      }
    }
  ];
  if (!isWidget) {
    columns = [
      ...columns,
      {
        Header: 'Question Attempts',
        accessor: 'totalNumberOfAnswers'
      },
      {
        Header: 'Correct Answers',
        accessor: 'totalNumberOfCorrectAnswers'
      }
    ];
  }
  const body = reportToolHasLoaded && (
    <Grid item style={{ height: '100%' }}>
      <Table
        columns={columns}
        data={data}
        {...getTableOptions(isWidget)}
        elevation={0}
        overflowY={isWidget ? 'scroll' : undefined}
        height="100%"
      />
    </Grid>
  );
  return (
    <ReportToolTemplate
      isWidget={isWidget}
      reportToolState={[ReportTools.Leaderboard]}
      subTitle=""
      body={body}
      title={'Leaderboard'}
      tracker={trackViewLeaderboardReport}
      source={ReportToolLeaderboardIcon}
    />
  );
};

export default ReportToolLeaderboard;
