import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../redux/hooks';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#009DE0',
    position: 'relative',
    height: '80px',
    padding: 20
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    color: 'white',
    marginLeft: 20,
    fontSize: '16px'
  }
}));

export const SkillsReportBanner: React.FC<any> = () => {
  const styles = useStyles();
  const token = useSelector((state: any) => state.login.token);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const isPremium = useSelector(
    (state: any) => state.userData.premium.isPremium
  );
  const features = useAppSelector(state => state.featureFlag);
  if (!features.newDashboardFlag) {
    return null;
  }
  //Add this code if the date is finalaised for skills report switch
  // const accountCreatedDate = new Date(
  //   useSelector((state: any) => state.userData.createdDate)
  // );
  // const oldReportPauseDate = new Date('');
  //   if (accountCreatedDate > oldReportPauseDate) {
  //     return null;
  //   }

  const openNewDashboardSkillsReport = () => {
    window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${classCode}/reports/skills-report?accessToken=${token}`;
  };

  return (
    <Grid container className={styles.container}>
      <Grid item style={{ paddingTop: '10px' }}>
        <ErrorOutlineIcon style={{ color: '#FFFFFF' }}></ErrorOutlineIcon>
      </Grid>
      <Grid item className={styles.content} xs={10}>
        <span className={styles.text}>
          This skills report contains player data collected before the date that
          you upgraded to the new learning engine and reports.
        </span>
      </Grid>
      <Grid item xs={1} style={{ margin: '20px, 10px' }}>
        <Button
          variant="outlined"
          size="large"
          style={{ backgroundColor: '#fff', whiteSpace: 'nowrap' }}
          onClick={openNewDashboardSkillsReport}
        >
          {' '}
          View report
        </Button>
      </Grid>
    </Grid>
  );
};
