import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import colors from '../../styles/colors';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    border: `1px solid ${colors.border}`,
    borderRadius: 34,
    display: 'flex',
    padding: '7px 14px',
		'&:focus-within': {
			borderColor: colors.blue
		}
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

interface SearchBarProps {
  originalState: Array<any>;
  searchKey: string;
  onChange: (result: Array<any>) => void;
  getValue?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  styles?: {
    input?: React.CSSProperties;
    container?: React.CSSProperties;
  };
  applyFilterAfterUpdate?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string | null>(null);

  const handleSearchFilter = (value: string | null) => {
    if (value !== null) {
      return props.originalState.filter((item: any) =>
        item[props.searchKey].toLowerCase().includes(value.toLowerCase())
      );
    } else {
      return props.originalState;
    }
  };

  useEffect(() => {
    // Reapply filter if data changes
    // ex. data filter is updated
    if (props.applyFilterAfterUpdate) {
      const result = handleSearchFilter(search);
      props.onChange(result);
    }
  }, [props.originalState]);


  const handleSearchInput = (event: any) => {
    const { value } = event.target;
    setSearch(value);
    const result = handleSearchFilter(value);
    props.onChange(result);
    if (props.getValue) {
      props.getValue(value);
    }
  };

  return (
    <Paper
      elevation={0}
      className={classes.root}
      style={props.styles ? props.styles.container : {}}
    >
      <InputBase
        disabled={props.disabled}
        className={classes.input}
        placeholder={props.placeholder}
        name="searchInput"
        onChange={handleSearchInput}
        inputProps={{
          'aria-label': 'Search...',
          style: props.styles ? props.styles.input : {}
        }}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
