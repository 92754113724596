import React from 'react';
import { Redirect } from 'react-router';
import Login from '../views/login/Login';
import Logout from '../views/login/Logout';
import ForgotPassword from '../views/login/ForgotPassword';
import NoLayout from '../layouts/NoLayout';
import Register from '../views/register/Register';
import AppBarLayout from '../layouts/AppBarLayout';
import parentRoutes from './dashboard-routes/parent-routes';
import districtAdminRoutes from './dashboard-routes/district-admin-routes';
import teacherRoutes from './dashboard-routes/teacher-routes';
import schoolAdminRoutes from './dashboard-routes/school-admin-routes';
import errorRoutes from './error-routes';
import RegistrationOnBoardingStepper from '../views/common/RegistrationOnBoardingStepper';
import CleverRedirect from '../views/login/CleverRedirect';
import {
  commonRoutes,
  loginRoutes,
  subscriptionRoutes,
  checkoutRoutes
} from '../constants/routes';
import AdminApprovedView from '../views/common/AdminApprovedView';
import LoginSelection from '../views/login/LoginSelection';
import TestPrepDecriptionsView from '../views/common/TestPrepDescriptionsView';
import ContentPackDecriptionsView from '../views/common/ContentPackDecriptionsView';
import CreateContentRedirect from '../views/common/CreateContentRedirect';
import AdminDeniedView from '../views/common/AdminDeniedView';
import GoogleRedirect from '../views/login/GoogleRedirect';
import GetStartedView from '../views/common/GetStarted/GetStartedView';
import UpdateReferral from '../views/common/UpdateReferral';
import Cancellation from '../views/subscription/CancellationView';
import CancelMembershipEmailView from '../views/subscription/CancelMembershipEmailView';
import { Features } from '../feature-flag/Features';
import { TermsAndConditionPage } from '../views/common/TermsAndConditionPage';
import { CheckoutSuccessView } from '../features/checkout/pages/success/CheckoutSuccessView';
import { MembershipCheckoutView } from '../features/checkout/pages/cart/MembershipCheckoutView';
import EmptyNavBarLayout from '../layouts/EmptyNavBarLayout';
import { FeatureFlag } from '../feature-flag/featureFlag';
import MembershipCart from '../components/common/MembershipCart';
import UpgradeUpsellScreen from '../components/common/UpgradeUpsellScreen';
import PremiumFreeTrial from '../components/common/PremiumFreeTrial';
import { AutoAuthorize } from '../views/login/AutoAuthorize';


const routes = [
  {
    path: '/',
    layout: NoLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: commonRoutes.adminApproved,
    layout: AppBarLayout,
    component: AdminApprovedView
  },
  {
    path: commonRoutes.adminDenied,
    layout: AppBarLayout,
    component: AdminDeniedView
  },
  {
    path: loginRoutes.login,
    layout: NoLayout,
    component: Login
  },
  {
    path: loginRoutes.loginSelection,
    layout: NoLayout,
    component: LoginSelection
  },
  {
    path: loginRoutes.logout,
    layout: NoLayout,
    component: Logout
  },
  {
    path: loginRoutes.register,
    layout: NoLayout,
    component: Register
  },
  {
    path: loginRoutes.registerEducator,
    layout: NoLayout,
    component: Register
  },
  {
    path: loginRoutes.registerParent,
    layout: NoLayout,
    component: Register
  },
  {
    path: loginRoutes.registerSchool,
    layout: NoLayout,
    component: Register
  },
  {
    path: loginRoutes.forgotPassword,
    layout: NoLayout,
    component: ForgotPassword
  },
  {
    path: loginRoutes.authorize,
    layout: NoLayout,
    component: AutoAuthorize
  },
  {
    path: commonRoutes.clever,
    layout: NoLayout,
    component: CleverRedirect
  },
  {
    path: commonRoutes.googleClassroom,
    layout: AppBarLayout,
    component: GoogleRedirect
  },
  {
    path: commonRoutes.selectLocation,
    layout: AppBarLayout,
    component: RegistrationOnBoardingStepper
  },
  {
    path: commonRoutes.upgradeToPremium,
    layout: AppBarLayout,
    component: UpgradeUpsellScreen
  },
  {
    path: commonRoutes.premiumFreeTrial,
    layout: AppBarLayout,
    component: PremiumFreeTrial
  },
  {
    path: commonRoutes.membershipCart,
    layout: AppBarLayout,
    component: MembershipCart
  },
  {
    path: commonRoutes.adminVerification,
    layout: AppBarLayout,
    component: RegistrationOnBoardingStepper
  },
  {
    path: commonRoutes.testPreparationDescriptions,
    layout: AppBarLayout,
    component: TestPrepDecriptionsView
  },
  {
    path: commonRoutes.contentPackDescriptions,
    layout: AppBarLayout,
    component: ContentPackDecriptionsView
  },
  {
    path: commonRoutes.createContentRedirect,
    layout: AppBarLayout,
    component: CreateContentRedirect
  },
  {
    path: commonRoutes.getStarted,
    layout: AppBarLayout,
    component: GetStartedView
  },
  {
    path: commonRoutes.updateReferral,
    layout: AppBarLayout,
    component: UpdateReferral
  },
  {
    path: subscriptionRoutes.cancellation,
    layout: NoLayout,
    component: Cancellation
  },
  {
    path: subscriptionRoutes.cancellationEmail,
    layout: NoLayout,
    component: CancelMembershipEmailView
  },
  {
    path: commonRoutes.termsAndConditions,
    layout: NoLayout,
    component: TermsAndConditionPage
  }
];

let checkoutComponentRoutes: {
  path: string;
  layout: React.FC<{}>;
  component: () => JSX.Element;
}[] = [];

checkoutComponentRoutes = [
  {
    path: checkoutRoutes.checkout,
    layout: EmptyNavBarLayout,
    component: MembershipCheckoutView
  },
  {
    path: checkoutRoutes.success,
    layout: EmptyNavBarLayout,
    component: CheckoutSuccessView
  },
  {
    path: checkoutRoutes.membershipPurchase,
    layout: EmptyNavBarLayout,
    component: MembershipCheckoutView
  }
];
export default [
  ...routes,
  ...checkoutComponentRoutes,
  ...parentRoutes,
  ...districtAdminRoutes,
  ...teacherRoutes,
  ...schoolAdminRoutes,
  ...errorRoutes
];

export interface Routes {
  path: string;
  layout: any;
  component: any;
  featureName?: Features;
  beta?: boolean;
}
