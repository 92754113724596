import React from 'react';
import {
  DialogContent,
  DialogTitle,
  Fab,
  makeStyles,
  Typography,
  Divider
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { show as showModal, hide as hideModal } from 'redux-modal';
import {
  MODAL_ASSIGN_MY_CONTENT,
  MODAL_CREATE_ASSIGNMENT,
  MODAL_SUCCESS
} from '../../../constants/modals';
import { NoteAdd, MenuBook, Bookmark } from '@material-ui/icons';
import { authorizeECCP } from '../../../redux/actions/planner-actions';

const useStyles = makeStyles(() => ({
  optionSelect: {
    marginBottom: 20,
    width: '100%'
  },
  optionSelectMobile: {
    fontSize: 12,
    lineHeight: 1.2
  },
  optionIcon: {
    marginRight: 20
  }
}));

const ModalSelectAssignmentType: React.FC = (props: any) => {
  const { show, handleHide } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <ModalAbstract show={show} handleHide={handleHide} hideOnOutsideClick>
      <DialogTitle>What type of content you would like to assign?</DialogTitle>
      <DialogContent dividers>
        <Typography style={{ marginBottom: 20 }}>
          You can either assign the content you have already created or select
          among all the <em>Dreamscape</em> content.
        </Typography>
        <Fab
          variant="extended"
          color="primary"
          className={clsx(
            classes.optionSelect,
            isMobile && classes.optionSelectMobile
          )}
          onClick={() =>
            dispatch(showModal('modal', { type: MODAL_ASSIGN_MY_CONTENT }))
          }
        >
          <Bookmark className={classes.optionIcon} />
          Assign my Own Content
        </Fab>

        <Fab
          variant="extended"
          color="primary"
          className={clsx(
            classes.optionSelect,
            isMobile && classes.optionSelectMobile
          )}
          onClick={() =>
            dispatch(showModal('modal', { type: MODAL_CREATE_ASSIGNMENT }))
          }
        >
          <MenuBook className={classes.optionIcon} />
          Assign Dreamscape Content
        </Fab>
        {
          !props.pilotProgram  &&
            <>
              <Divider style={{ marginBottom: 20 }} />

              <Fab
                variant="extended"
                color="primary"
                className={clsx(
                  classes.optionSelect,
                  isMobile && classes.optionSelectMobile
                )}
                onClick={() => {
                  dispatch(
                    showModal('spinner', {
                      text:
                        'Authenticating to the Education Content Creation Platform'
                    })
                  );
                  dispatch(authorizeECCP());
                  setTimeout(() => {
                    dispatch(hideModal('spinner'));
                    dispatch(
                      showModal('modal', {
                        type: MODAL_SUCCESS,
                        text:
                          'You were redirected to the Education Content Creation Platform.'
                      })
                    );
                  }, 3000);
                }}
              >
                <NoteAdd className={classes.optionIcon} />
                Create Custom Content
              </Fab>
            </>

        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalSelectAssignmentType;
