import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import colors from '../../styles/colors';
import { getClass } from '../../redux/actions/class-actions';
import { getPlanner } from '../../redux/actions/planner-actions';
import { userTypes } from '../../constants/users';
import { isMobile } from 'react-device-detect';
import {
  teacherRoutes,
  districtAdminRoutes,
  schoolAdminRoutes
} from '../../constants/routes';
import store from '../../redux/store';
import { Debug } from '../../utils/debug';

const useStyles = makeStyles(() => ({
  select: {
    background: colors.appbarAlt,
    border: `2px solid ${colors.primaryHover}`,
    borderRadius: 34,
    display: 'inline-block',
    margin: '3px 0 0 20px',
    padding: '7px 25px',
    verticalAlign: 'top',
    '&:before, &:hover:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  icon: {
    fill: colors.primaryHover,
    right: 10
  }
}));

export const ClassSelectionDropdown: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const userData = useSelector((state: any) => state.userData);
  const { classes: allClasses, currentClass } = useSelector(
    (state: any) => state.class
  );

  useEffect(() => {
    // checks if the user type is not parent then based on route shows class selection on navbar or not
    if (!(userData.userType === userTypes.parent && currentClass.code)) {
      const visible =
        props.match.path.includes(':classCode') &&
        ![
          teacherRoutes.realTimeDashboard,
          schoolAdminRoutes.teacherRealTimeDashboard,
          districtAdminRoutes.teacherRealTimeDashboard,
          teacherRoutes.classAssignmentReport,
        ].includes(props.match.path);
      setVisible(visible);
    }
  }, [props.match.path, currentClass]);

  const dispatch = useDispatch();
  
  const handleChange = async (event: any) => {
    const classCode = event.target.value;
    let path = props.match.path.replace(':classCode', classCode);
    const hideSpinnerAfter = true;
    try {
      await dispatch(getClass(classCode, { hideSpinnerAfter }));
      dispatch(getPlanner(classCode));
      //@ts-ignore
      if (!store.getState().class.currentClass.roster.length) {
        switch (userData.userType) {
          case userTypes.districtAdmin:
            path = districtAdminRoutes.teacherClass;
            break;
          case userTypes.schoolAdmin:
            path = schoolAdminRoutes.teacherClass;
            break;
          default:
            // path = teacherRoutes.classDashboard;
            break;  
        }
        path = path.replace(':classCode', classCode);
      }
      return dispatch(push(path));
    } catch (error) {
      Debug.log(error);
    }
  };

  if (!visible || isMobile) {
    return null;
  }

  return (
    <Select
      style={{ marginTop: 10 }}
      onChange={handleChange}
      name="classSelection"
      value={currentClass.code}
      className={classes.select}
      inputProps={{
        classes: {
          icon: classes.icon
        }
      }}
    >
      {allClasses.map((item: any, index: number) => {
        return (
          <MenuItem
            key={index}
            selected={currentClass.code === item.code}
            value={item.code}
          >
            {item.name} {item.archived ? '(Archived)' : ''}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default withRouter(ClassSelectionDropdown);
