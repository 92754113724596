import React from "react";
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

/**
 * This flips the arrow up and down based on incoming props
 * @param open
 */
interface DropDownArrowProps {
  open: boolean;
  style?: React.CSSProperties;
}
const DropDownArrow: React.FC<DropDownArrowProps> = ({open, style}) => {
  if (open) {
    return (<ArrowDropUp style={style}/>);
  } else {
    return (
      <ArrowDropDown style={style} />
    )
  }
}

export default DropDownArrow;
