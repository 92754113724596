import { QuestionFormat, QuestionFormatNumber } from '../../../enums/QuestionFormat';
import { QuestionType } from '../../../enums/QuestionType';
import {
  AssignmentData,
  AssignmentError,
  AssignmentPassage,
  AssignmentQuestion,
  AssignmentQuestionType
} from '../pages/edit/state/assignment-edit.types';

/**
 * @description validates assignment question
 * @param question
 * @returns error object
 */
export function validateQuestion(
  question: AssignmentQuestion
): AssignmentError {
  const error: AssignmentError = {
    isError: false,
    errors: new Map()
  };
  if (!question || !question.answers) {
    return error;
  }
  /**
   * Handle drag and drop
   */
  if (question.format === QuestionFormatNumber.DragAndDrop) {
    const field = 'answers';
    let message = '';
    if (!(question.answers.length > 0)) {
      message = 'Drag and drop needs at-least 1 answer';
      error.isError = true;
      error.errors.set(field, { field, message });
    }
  } else {
    let isCorrectAvailable = false;
    let isAnswersEmpty = false;
    let isAnswerDuplicated = false;
    const answerSet = new Set();
    question.answers.forEach(answer => {
      if (answer.isCorrect) {
        isCorrectAvailable = true;
      }
      if (answer.text.trim().length === 0) {
        isAnswersEmpty = true;
      }
      if (answerSet.has(answer.text)) {
        isAnswerDuplicated = true;
      } else {
        answerSet.add(answer.text);
      }
    });
    if (!isCorrectAvailable && !(question.format === QuestionFormatNumber.Pair) && !(question.format === QuestionFormatNumber.Order)) {
      const field = 'answers';
      let message = 'There needs to be at-least 1 correct answer';
      if (question.format === 1) {
        message = 'There should be 1 correct answer';
      }
      error.isError = true;
      error.errors.set(field, { field, message });
    } else if (isAnswersEmpty) {
      const field = 'answers';
      let message = 'Answers cannot be empty';
      error.isError = true;
      error.errors.set(field, { field, message });
    } else if (isAnswerDuplicated) {
      const field = 'answers';
      let message = 'Answers cannot have duplicates';
      error.isError = true;
      error.errors.set(field, { field, message });
    }
  }

  // multiple choice
  if (question.format === 1) {
    const field = 'answers';
    let message = '';
    const correct = question.answers.filter(question => question.isCorrect);
    if (correct.length > 1) {
      message = 'Multiple choice can only have 1 correct answer';
      error.isError = true;
      error.errors.set(field, { field, message });
    }
  }

  if (
    !question.question ||
    question.question.replace(/<(.|\n)*?>/g, '').trim().length === 0
  ) {
    const field = 'question';
    let message = 'Question can not be empty';
    error.isError = true;
    error.errors.set(field, { field, message });
  }

  if (question.skillId < 1) {
    const field = 'skillId';
    let message = 'Skill must be selected';
    error.isError = true;
    error.errors.set(field, { field, message });
  }
  return error;
}

/**
 * @description validates passage
 * @param passage
 */
export function validatePassage(passage: AssignmentPassage) {
  const error: AssignmentError = {
    isError: false,
    errors: new Map()
  };
  if (passage.genreId < 1) {
    error.isError = true;
    error.errors.set('genreId', {
      field: 'genreId',
      message: 'Genre must be selected'
    });
  }

  if (passage.passageTitle.trim().length === 0) {
    error.isError = true;
    error.errors.set('passageTitle', {
      field: 'passageTitle',
      message: 'Passage title must be entered'
    });
  }

  if (passage.passage.trim().length === 0) {
    error.isError = true;
    error.errors.set('passage', {
      field: 'passage',
      message: 'Passage cannot be empty'
    });
  }

  return error;
}

/**
 * @description checks if assignment is valid to be assigned
 * @param filteredAssignmentData
 * @returns boolean
 */
export function isAssignmentValid(filteredAssignmentData: AssignmentData[]) {
  const assignment = filteredAssignmentData.find(assignmentData => {
    if (assignmentData.type === AssignmentQuestionType.Independent) {
      if (assignmentData?.error?.isError) {
        return true;
      }
    } else if (assignmentData.type === AssignmentQuestionType.Passage) {
      if (assignmentData?.error?.isError) {
        return true;
      }
      for (let i = 0; i < assignmentData.questions.length; i++) {
        const question = assignmentData.questions[i];
        if (question?.error?.isError) {
          return true;
        }
      }
    }
    return false;
  });
  return assignment == null;
}

/**
 * @description determines whether assignment is assignable
 * @param data
 */
export const isAssignmentAssignable = (data: AssignmentData[]) => {
  return (
    data.find(
      question =>
        question.type !== null &&
        question.isSynced === true &&
        !question.isDeleted
    ) || false
  );
};
