import store from '../store';
import { fetchApi } from './fetch-actions';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { USER_GENERATED_CONTENT_GET } from '../types/user-generated-content/user-generated-content-action-types';
import { Debug } from '../../utils/debug';

export const getUserGeneratedContent = () => {
  return (dispatch: any) => {
    const { userData }: any = store.getState();
    const { schoolId, districtId } = userData.mixpanel;
    dispatch(
      fetchApi({
        url: `v2.1/assignment/content/user-generated?teacherId=${userData._id}&schoolId=${schoolId}&districtId=${districtId}`,
        method: APIMethods.GET,
        endpoint: APIEndpoints.EDUCATION
      })
    )
      .then((response: any) => {
        dispatch({
          type: USER_GENERATED_CONTENT_GET,
          payload: response.data
        });
      })
      .catch((error: any) => Debug.log(error));
  };
};
