import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import color from '../../styles/colors';
import NotFoundIcon from '../../assets/errors/page-not-found.png';

const ErrorNotFound: React.FC = () => {
  const useStyles = makeStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.primary
    },
    errorText: {
      color: 'white'
    }
  });

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1 className={classes.errorText}>
        <img src={NotFoundIcon} alt="Not Found" />
        <span
          style={{
            position: 'absolute',
            top: '64%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '1.3em',
            textAlign: 'center',
            width: '100%'
          }}
        >
          Ooops, looks like this page doesn't exist!
          <br />
          <Button white style={{ marginTop: 40 }}>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              Click Here to go back to your dashboard
            </Link>
          </Button>
        </span>
      </h1>
    </div>
  );
};

export default ErrorNotFound;
