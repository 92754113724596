import React from 'react';
import FeatureGuard from '../FeatureGuard';
import { Routes } from '../../router/routes';
import useAuthentication from '../../hooks/useAuthentication';

const RouteGuard = (props: Routes) => {
  useAuthentication(props);
  return <FeatureGuard {...props} />;
};

export default RouteGuard;
