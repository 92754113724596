import React from 'react';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import educator from '../../assets/icons/registration/dreamscape_account_educators.png';
import student from '../../assets/icons/registration/dreamscape_account_students.png';
import parents from '../../assets/icons/registration/dreamscape_account_parents.png';
import admin from '../../assets/icons/registration/dreamscape_account_schools.png';
import Button from '../../components/button/Button';
import { isMobile } from 'react-device-detect';
import DreamscapeBanner from '../../components/ds-banner/DreamscapeBanner';

const LoginSelection: React.FC = () => {
  const gridStyle: React.CSSProperties = {
    textAlign: 'center',
    margin: isMobile ? '30px auto' : 0,
    padding: '0 20px'
  };
  return (
    <>
      <DreamscapeBanner title="Sign in to Dreamscape" />
      <Container fixed maxWidth="lg">
        <Grid container style={{ textAlign: 'center' }}>
          <Grid item xs={12} sm={6} md={3} style={gridStyle}>
            <img src={student} alt="student" />
            <br />
            <br />
            <Button
              primary
              loginpage
              fullWidth
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_WEBGL_LINK}`)
              }
            >
              Player
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={gridStyle}>
            <img src={parents} alt="parent" />
            <br />
            <br />
            <Link to="/login/parent" style={{ textDecoration: 'none' }}>
              <Button green loginpage fullWidth>
                Parent
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={gridStyle}>
            <img src={educator} alt="educator" />
            <br />
            <br />
            <Link to="/login/educator" style={{ textDecoration: 'none' }}>
              <Button blue loginpage fullWidth>
                Educator
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={gridStyle}>
            <img src={admin} alt="admin" />
            <br />
            <br />
            <Link to="/login/school" style={{ textDecoration: 'none' }}>
              <Button orange loginpage fullWidth>
                Admin
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LoginSelection;
