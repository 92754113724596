import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';
import { getUser } from '../../redux/actions/user-actions';
import { loginUserWithToken } from '../../redux/login/login-actions';
import {
  trackLoginGoogleAnalytics,
  trackRegister
} from '../../utils/google/google-analytics';
import { parseJwt } from '../../utils/jwt';
import { useLocation } from 'react-router';
import useQueryParams from '../../hooks/UseQueryParams';
import _ from 'lodash';
import mixpanel from '../../utils/mixpanel';
import { getRegistrationFlowValue } from '../../utils/registrations/getRegistrationFlowValue';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';

const CleverRedirect: React.FC = (props: any) => {
  props.show('spinner', { text: 'Loading your Dreamscape Dashboard' });
  const location = useLocation() as {
    search: string;
  };
  const dispatch = useDispatch();
  const query: { auth?: string; isRegister?: string } = useQueryParams();
  const featureFlag: any = useSelector((state: any) => state.featureFlag);
  const [auth, setAuth] = useState({ token: '' });

  useEffect(() => {
    console.log('DEBUG: entered useEffect in clever redirect');
    if (!featureFlag.ldFlagsInitialised) return;

    if (!auth.token) {
      const { search } = location;
      const browserQuery = search;
      let jwtToken = browserQuery.substring(1, browserQuery.length);

      if (query?.auth) {
        jwtToken = query.auth;
      }

      const token = `JWT ${jwtToken}`;
      setAuth({ token });

      if (query?.isRegister && query.isRegister === 'true') {
        const parsedToken = parseJwt(jwtToken);
        trackRegister(parsedToken._id, parsedToken.userType);
        if (
          !getLocalStorage('onboardingStartedFired') &&
          featureFlag.newRegistrationFlow
        ) {
          console.log(
            'DEBUG: Setting newRegistrationFlow value in local storage'
          );
          const registrationFlowValue = getRegistrationFlowValue();
          setLocalStorage('newRegistrationFlow', registrationFlowValue);

          if (!getLocalStorage('onboardingStartedFired')) {
            console.log('DEBUG: triggering Onboarding Started for clever');
            mixpanel.track('Onboarding Started ', undefined, true);
            setLocalStorage('onboardingStartedFired', 'true');
          }
        }
        if (
          featureFlag.newRegistrationFlow &&
          getLocalStorage('newRegistrationFlow') === 'B'
        ) {
          const redirectUrl = '/dashboard/free-trial?mandatory=false';

          dispatch(loginUserWithToken(token, redirectUrl, 'clever'));
        } else if (
          getLocalStorage('newRegistrationFlow') === 'A' &&
          featureFlag.newRegistrationFlow
        ) {
          dispatch(
            loginUserWithToken(token, '/dashboard/select-location', 'clever')
          );
        } else if (
          getLocalStorage('newRegistrationFlow') === 'C' &&
          featureFlag.newRegistrationFlow
        ) {
          dispatch(
            loginUserWithToken(
              token,
              '/dashboard/free-trial?mandatory=true',
              'clever'
            )
          );
        } else {
          console.log('DEBUG: newRegistrationFlow not avail in localstorage ');
          dispatch(loginUserWithToken(token));
        }
      } else {
        console.log(
          'DEBUG: clever redirect url query isRegister probably false',
          query
        );
        const parsedToken = parseJwt(jwtToken);
        trackLoginGoogleAnalytics(parsedToken._id, parsedToken.userType);
        dispatch(loginUserWithToken(token));
      }
    }
  }, [featureFlag, query]);
  return null;
};

const mapActionToProps = {
  getUser,
  goTo: push,
  show
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    router: state.router
  };
};

export default connect(mapStateToProps, mapActionToProps)(CleverRedirect);
