import {
  AssignmentEditAnswerActionTypes,
  AssignmentEditQuestionActionTypes,
  AssignmentEditQuestionBankActionTypes
} from './assignment-question-action.types';
import { AssignmentEditActionTypes } from '../assignment/assignment-action.types';

/**
 * @description middleware that sets edit state for when editing assignments
 * @param store
 */
const editMiddleware = (store: any) => (next: any) => (action: any) => {
  if (
    [
      AssignmentEditQuestionActionTypes.UPDATE_QUESTION,
      AssignmentEditQuestionActionTypes.UPDATE_QUESTION_FORMAT,
      AssignmentEditQuestionActionTypes.DELETE_QUESTION,
      AssignmentEditQuestionActionTypes.UPDATE_QUESTION_TYPE,
      AssignmentEditQuestionActionTypes.UPDATE_QUESTION_TEXT,
      AssignmentEditAnswerActionTypes.ADD_ANSWER,
      AssignmentEditAnswerActionTypes.DELETE_ANSWER,
      AssignmentEditAnswerActionTypes.UPDATE_ANSWER,
      AssignmentEditActionTypes.UPDATE,
      AssignmentEditQuestionBankActionTypes.ADD_QUESTION_BANK_QUESTIONS
    ].includes(action.type)
  ) {
    return next({
      ...action,
      autoSave: {
        isEdited: true
      }
    });
  }
  if (
    [
      AssignmentEditActionTypes.SAVED,
      AssignmentEditActionTypes.RETRIEVED_ASSIGNMENT
    ].includes(action.type)
  ) {
    return next({
      ...action,
      autoSave: {
        isEdited: false
      }
    });
  }
  return next(action);
};
export default editMiddleware;
