import { API_GET, API_METHODS } from '../api-constants';
import {
  LOCATION_GET_DISTRICTS,
  LOCATION_GET_SCHOOLS
} from '../types/location/location-action-types';
import { generateApi } from '../util/api-promisfy';
import { fetchApi } from './fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { push } from 'connected-react-router';
import { userTypes } from '../../constants/users';
import { Debug } from '../../utils/debug';
import { submitRegistrationToPardot } from './pardot-actions';
import store from '../store';
import { getUser } from './user-actions';
import { USER_ADMIN_SET_ADMIN_VERIFICATION_DATA } from '../types/user/user-action-type';

export const getDistricts = (promisify?: boolean) => {
  const action = {
    type: API_GET,
    payload: {
      url: 'districts/imported',
      method: API_METHODS.GET
    },
    handler: LOCATION_GET_DISTRICTS
  };
  if (promisify) {
    return generateApi({ ...action.payload });
  }
  return action;
};

export const getSchools = (
  countryID: string,
  regionID: string,
  promisify?: boolean
) => {
  /**
   * @todo change url in back-end to URL Encoded format.
   * Currently this endpoint receives a JSON.stringify which is weird
   * Front-end solution:
   *  @example
   * 	const params = new URLSearchParams({ countryID, regionID });
   */
  const params = JSON.stringify({
    imported: true,
    countryID,
    regionID
  });
  const action = {
    type: API_GET,
    payload: {
      url: `school/${params}`,
      method: API_METHODS.GET
    },
    handler: LOCATION_GET_SCHOOLS
  };
  if (promisify) {
    return generateApi({ ...action.payload });
  }
  return action;
};

export const setSchool = (
  country: string,
  province: string,
  district: string,
  school: string,
  schoolCode?: string
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'user/updateLocation',
        method: APIMethods.POST,
        spinnerMessage: 'Setting your School',
        hideSpinnerAfter: true,
        data: {
          country,
          province,
          district,
          school,
          updateSubscription: false,
          isDreamscape: true,
          registrationStep: null
        }
      })
    )
      .then((response: any) => {
        const { token }: any = store.getState().login;
        let redirect = false;
        const user: any = store.getState().userData;
        if (
          user.userType !== userTypes.schoolAdmin ||
          user.userType !== userTypes.districtAdmin
        ) {
          redirect = true;
        }
        dispatch(getUser(token, redirect));

        dispatch({
          type: USER_ADMIN_SET_ADMIN_VERIFICATION_DATA,
          payload: {
            country,
            province,
            district,
            school,
            schoolCode
          }
        });

        const {
          firstName,
          lastName,
          email,
          userType,
          specialty: position,
          referrer: referral
        }: any = store.getState().userData;

        dispatch(
          submitRegistrationToPardot({
            firstName,
            lastName,
            email,
            userType,
            position: position[0],
            referral: `${referral[0].type} - ${referral[0].text}`,
            school,
            schoolCode,
            district,
            country,
            province
          })
        );
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const setDistrict = (
  country: string,
  province: string,
  district: string
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'user/updateDistrict',
        method: APIMethods.POST,
        spinnerMessage: 'Setting your District',
        hideSpinnerAfter: true,
        data: {
          country,
          province,
          district
        }
      })
    )
      .then((response: any) => {
        const { token }: any = store.getState().login;
        dispatch(getUser(token));
        dispatch({
          type: USER_ADMIN_SET_ADMIN_VERIFICATION_DATA,
          payload: { country, province, district }
        });

        const {
          firstName,
          lastName,
          email,
          userType,
          position,
          referral
        }: any = store.getState().userData;
        dispatch(
          submitRegistrationToPardot({
            firstName,
            lastName,
            email,
            userType,
            position,
            referral,
            district,
            country,
            province
          })
        );
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};
