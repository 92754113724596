import React from 'react';
// @ts-ignore
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './global.scss';
import './themes/override.scss';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import ErrorBoundary from './ErrorBoundary';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

/**
 * This initializes the app with launch darkly
 * a third party feature flag management tool
 */
const AppWithLaunchDarkly = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
  user: {
    key: 'db63f31b-1091-4838-ab04-59e006fcef9b',
    anonymous: true
  },
  options: {
    streaming: true
  }
})(App);

const app = (
  <Provider store={store}>
    <ErrorBoundary>
      <AppWithLaunchDarkly />
    </ErrorBoundary>
  </Provider>
);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
