import React from 'react';
import ModalAbstract from './ModalAbstract';
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Close, HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { playerNounLowerCase } from '../../../utils/userNoun';
import Button from '../../button/Button';
import { useDispatch } from 'react-redux';
import { fetchApi } from '../../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../../types/fetch/fetch-types';
import { AsyncPromiseHelper } from '../../../utils/async-await-helper/AsyncPromiseHelper';
import { show as reduxModal } from 'redux-modal';
import { getClass } from '../../../redux/actions/class-actions';
import ModalDefault from './ModalDefault';
import { useAppSelector } from '../../../redux/hooks';
const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  }
});
const ModalPlacementTestReassignment = (props: any) => {
  const classes = useStyles();
  const features = useAppSelector(state => state.featureFlag);
  const { show, handleHide, users, callback, classCode } = props;
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = React.useState(false);
  const [placementTestUsers, setPlacementTestUsers] = React.useState([
    ...users.map((user: any) => ({ ...user, isSelected: false }))
  ]);
  const selectPlayer = (id: string) => {
    const selectedPlayers = [
      ...placementTestUsers.map((user: any) => ({
        ...user,
        isSelected: user._id === id ? !user.isSelected : user.isSelected
      }))
    ];
    setPlacementTestUsers(selectedPlayers);
  };

  async function handleReassign() {
    const selectedPlayers = placementTestUsers
      .filter((user: any) => user.isSelected)
      .map((user: any) => user.educationUserId);
    if (!selectedPlayers || selectedPlayers.length === 0) {
      return dispatch(
        reduxModal('snackbar', {
          variant: 'error',
          message: 'No user selected'
        })
      );
    }
    if (features.newDashboardFlag) {
      const [response, error] = await AsyncPromiseHelper(
        dispatch(
          fetchApi({
            url: 'v1/learners/assign-placement-test',
            method: APIMethods.PUT,
            endpoint: APIEndpoints.LE_API,
            data: {
              learnerIds: selectedPlayers
            }
          })
        )
      );
      if (error) {
        dispatch(
          reduxModal('snackbar', {
            variant: 'error',
            message: 'Error assigning placement test'
          })
        );
      }
      if (response) {
        dispatch(
          reduxModal('snackbar', {
            variant: 'success',
            message: 'Placement test assigned'
          })
        );
        await dispatch(getClass(classCode, { showSpinner: false }));
        if (callback) {
          callback();
        }
        handleHide();
      }
    } else if (!features.newDashboardFlag) {
      const [response, error] = await AsyncPromiseHelper(
        dispatch(
          fetchApi({
            url: 'v2.1/assignment/placement-test/assign',
            method: APIMethods.POST,
            endpoint: APIEndpoints.EDUCATION,
            data: {
              players: selectedPlayers
            }
          })
        )
      );
      if (error) {
        dispatch(
          reduxModal('snackbar', {
            variant: 'error',
            message: 'Error assigning placement test'
          })
        );
      }
      if (response) {
        dispatch(
          reduxModal('snackbar', {
            variant: 'success',
            message: 'Placement test assigned'
          })
        );
        await dispatch(getClass(classCode, { showSpinner: false }));
        if (callback) {
          callback();
        }
        handleHide();
      }
    }
  }

  const handleHelpIcon = () => {
    setShowInfo(!showInfo);
  };

  return (
    <ModalAbstract
      show={show}
      hideOnOutsideClick={true}
      handleHide={handleHide}
    >
      {showInfo ? (
        <>
          <ModalDefault
            show={showInfo}
            handleHide={handleHelpIcon}
            children={() => (
              <span>
                By clicking on re-assign, your player will undergo another
                diagnostic test and place them in an appropriate reading level
                once again. This is only recommended if your player has been
                away from Dreamscape for a period of time. Reassigning the
                placement test will show the latest placement test results in
                your dashboard.
              </span>
            )}
          />
        </>
      ) : (
        <>
          <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
            <b style={{ paddingRight: '20px' }}>Reassign Placement Test</b>
            <IconButton onClick={handleHelpIcon}>
              <HelpOutline />
            </IconButton>
            <IconButton className={classes.closeButton} onClick={handleHide}>
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography>
              Select {playerNounLowerCase}(s) you want to assign the placement
              test.
            </Typography>
            <List>
              {placementTestUsers.map((user: any) => {
                return (
                  <MenuItem disabled={user.isActive} key={user._id}>
                    <Checkbox
                      disabled={user.isActive}
                      color={'primary'}
                      checked={Boolean(user.isSelected)}
                      onClick={() => selectPlayer(user._id)}
                    />
                    {user.fullname}
                    {/* {features.newDashboardFlag? user.} */}
                    {user.isActive && ' (Ongoing placement test not complete)'}
                  </MenuItem>
                );
              })}
            </List>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Box textAlign={'right'}>
              <Button primary onClick={handleReassign}>
                Reassign
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </ModalAbstract>
  );
};

export default ModalPlacementTestReassignment;
