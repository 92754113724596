import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  image: {
    position: 'absolute',
    left: -15,
    top: -43,
    transform: 'scaleX(-1)'
  },
  imageMobile: {
    left: -33,
    top: -28,
    transform: 'none',
    width: 90
  }
}));

interface ChuckIconProps {
  image: any;
  onClick?: () => void;
}

const ChuckIcon: React.FC<ChuckIconProps> = (props: ChuckIconProps) => {
  const classes = useStyles({});

  return (
    <img
      src={props.image}
      onClick={props.onClick}
      className={clsx(classes.image, (window.screen.height < 1100 || isMobile) && classes.imageMobile)}
    />
  );
};

export default ChuckIcon;
