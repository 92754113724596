import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { show as showModal } from 'redux-modal';
import { MODAL_CREATE_TEST_PREP } from '../../../constants/modals';
import { trackTestPrepFeedback } from '../../../redux/actions/mixpanel-actions';
import Button from '../../button/Button';

enum Content {
  INPUT,
  THANKS
}

const ModalTestPrepFeedback: React.FC = (props: any) => {
  const { show } = props;
  const [value, setValue] = useState('');
  const [content, setContent] = useState<Content>(Content.INPUT);
  const dispatch = useDispatch();

  const renderContent = () => {
    if (content === Content.INPUT) {
      return (
        <>
          <Typography>
            What Test Prep would you be interested in assigning?
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            style={{ marginTop: 10 }}
            onChange={(event: any) => setValue(event.target.value)}
          />
        </>
      );
    }
    return (
      <Typography>
        Thanks for your feedback! In the meantime while we work on this, please
        review our General Test Prep and assign if appropriate
      </Typography>
    );
  };

  const handleHide = () => {
    dispatch(
      showModal('modal', {
        type: MODAL_CREATE_TEST_PREP,
        selectedTestPrepId: 3
      })
    );
  };

  return (
    <div>
      <Dialog open={show} onClose={handleHide}>
        <DialogTitle>Don't see your region?</DialogTitle>
        <DialogContent dividers style={{ padding: 20, textAlign: 'center' }}>
          <DialogContentText>{renderContent()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHide}>
            {content === Content.INPUT ? 'Cancel' : 'Close'}
          </Button>

          {content === Content.INPUT ? (
            <Button
              primary
              disabled={value.trim() === ''}
              onClick={() => {
                dispatch(trackTestPrepFeedback(value));
                setContent(Content.THANKS);
              }}
            >
              Submit
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalTestPrepFeedback;
