import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import {
  createAssignmentV2,
  getAssignmentContent,
  getTestPreps
} from '../../../../../redux/actions/planner-actions';
import { hide as hideModal, show as showModal } from 'redux-modal/lib/actions';
import {
  MODAL_ASSIGNMENT_OPTIONS,
  MODAL_TEST_PREP_FEEDBACK
} from '../../../../../constants/modals';
import Button from '../../../../../components/button/Button';
import colors from '../../../../../styles/colors';
import PackSelection from '../../../../../components/popups/modals/common/PackSelection';
import { commonRoutes } from '../../../../../constants/routes';
import clsx from 'clsx';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ModalAbstract from '../../../../../components/popups/modals/ModalAbstract';
import CloseIcon from '@material-ui/icons/Close';
import ClassSelection from '../../ClassSelection';
import { useAppSelector } from '../../../../../redux/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  rowGrid: {
    marginBottom: 20
  },
  footerInfo: {
    bottom: 18,
    fontSize: 13,
    left: 25,
    position: 'absolute'
  },
  footerInfoMobile: {
    bottom: 0,
    display: 'block',
    left: 0,
    padding: '10px 0 10px 20px',
    position: 'relative',
    textAlign: 'left',
    width: '100%'
  },
  selectAllButton: {
    margin: '10px 0'
  },
  input: {
    margin: '10px 0'
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

enum Steps {
  selectType,
  selectQuestions,
  selectStudents,
  selectDate,
  done
}

interface TestPrep {
  id?: number;
  selectedContentId?: number;
  name: string;
  grades: Array<number>;
  title: string;
  type: string;
  grade: number;
}

interface ModalCreateTestPrepProps {
  show: any;
  folderId?: number;
  handleHide: any;
  selectedTestPrepId?: number;
  assignmentType?: string;
}

const initialTestPrepState = {
  grades: [],
  grade: 0,
  name: '',
  title: '',
  type: ''
};

const CreateTestPrepModal: React.FC<ModalCreateTestPrepProps> = (
  props: ModalCreateTestPrepProps
) => {
  const [testPreps, setTestPreps] = useState<Array<any>>([]);
  const classes = useStyles();
  const { show, handleHide, selectedTestPrepId } = props;
  const { currentClass } = useSelector((state: any) => state.class);
  const teacherClasses = useAppSelector(state => state.class.classes);
  const filteredTeacherClasses = teacherClasses.filter(
    (teacherClass: any) =>
      !teacherClass.archived && teacherClass.roster.length > 0
  );
  const [newTestPrep, setNewTestPrep] = useState<TestPrep>(
    initialTestPrepState
  );
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    loading: false,
    typeOptions: [{ id: 0, name: '', selected: false }],
    selectedClassesOptions: {
      classes: [],
      allStudents: false,
      allClasses: false,
      sameTime: false
    },
    students: {
      data: currentClass.roster.map((student: any) => {
        student.selected = false;
        return student;
      }),
      allSelected: false,
      studentsLoaded: false
    },
    // DateRangerPicker state
    focusedInput: null,
    startDate: moment().toDate(),
    endDate: moment()
      .add(7, 'days')
      .toDate()
  });
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.selectType);
  const [error, setError] = useState({
    loading: '',
    assignmentData: '',
    contentSelection: '',
    studentSelection: ''
  });
  const dispatch = useDispatch();
  const footerStyle: React.CSSProperties = isMobile
    ? { width: '100%', textAlign: 'right' }
    : {};

  useEffect(() => {
    const fetchTestPreps = async () => {
      const testPrepsResponse: any = await dispatch(
        getTestPreps(currentClass.code)
      );
      const testPrepsData = testPrepsResponse.data.map((testPrep: any) => ({
        ...testPrep,
        selectedContentId: 0,
        grades: testPrep.grades.sort(),
        title: 'All Assessments',
        type: 'Test Prep',
        grade: 3
      }));
      setTestPreps(testPrepsData);
      setNewTestPrep(testPrepsData[0]);
      setIsLoading(false);
    };
    fetchTestPreps();
  }, []);

  useEffect(() => {
    if (selectedTestPrepId) {
      let initialTestPrep: any = testPreps[0];
      const selectedTestPrep = testPreps.filter(
        testPrep => testPrep.id === selectedTestPrepId
      );
      if (selectedTestPrep) {
        initialTestPrep = selectedTestPrep[0];
      }
      if (initialTestPrep) {
        setNewTestPrep(initialTestPrep);
      }
    }
  }, [testPreps]);

  useEffect(() => {
    const selectedStudents = state.students.data.filter(
      (item: any) => item.selected === true
    );
    const selectedOptions = state.typeOptions.filter(
      item => item.selected === true
    );
    const errorState: any = {};
    if (selectedStudents.length) {
      errorState.studentSelection = '';
    }
    if (
      newTestPrep !== null &&
      newTestPrep.title.trim() !== '' &&
      moment(state.startDate).isValid() &&
      moment(state.endDate).isValid()
    ) {
      errorState.assignmentData = '';
    }
    if (
      error.contentSelection !== errorState.contentSelection ||
      error.studentSelection !== errorState.studentSelection ||
      error.assignmentData !== errorState.assignmentData
    ) {
      setError({
        ...error,
        ...errorState
      });
    }
  }, [state]);

  useEffect(() => {
    // when selecting the 'Assign All Test Preps' options, set end date to 4 weeks ahead
    if (newTestPrep.selectedContentId === -1) {
      setState({
        ...state,
        endDate: moment(state.startDate)
          .add(4, 'weeks')
          .toDate()
      });
    }
  }, [newTestPrep.selectedContentId, state.startDate]);

  if (isLoading) {
    return <CircularProgress color="primary" />;
  }

  const handleCheckStudent = (_id: string, event: any) => {
    const data = state.students.data.map((student: any) => {
      if (student._id === _id) {
        student = {
          ...student,
          selected: event.target.checked
        };
      }
      return student;
    });
    const newState = {
      students: {
        ...state.students,
        data
      }
    };
    const unselected = state.students.data.filter(
      (student: any) => student.selected === false
    );
    if (event.target.checked === false) {
      newState.students.allSelected = false;
    } else if (!unselected.length) {
      newState.students.allSelected = true;
    }
    setState({
      ...state,
      ...newState
    });
  };

  const handleSetContent = () => {
    let contentSelectionError;
    if (!newTestPrep.selectedContentId) {
      contentSelectionError = 'Please, select one option to assign';
    }
    if (contentSelectionError) {
      setError({
        ...error,
        contentSelection: contentSelectionError
      });
      return false;
    }
  };

  const handleSetStudents = () => {
    let selectedStudents = 0;
    state.selectedClassesOptions.classes.forEach((currentClass: any) => {
      const currentClassRoster = currentClass.roster.filter(
        (player: any) => player.isSelected
      );
      selectedStudents += currentClassRoster.length;
    });
    if (selectedStudents === 0) {
      setError({
        ...error,
        studentSelection: 'Please, select at least one player'
      });
      return false;
    }
  };

  const handleSetDate = async () => {
    let assignmentDataError;
    const { title, grade } = newTestPrep;
    const { startDate, students, endDate } = state;
    if (!title.trim()) {
      assignmentDataError = 'Please, give a name to your assignment';
    }
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      assignmentDataError = 'Please, select a valid date range';
    }
    if (moment(endDate) < moment(startDate)) {
      assignmentDataError = 'End Date must be greater or equal Start Date';
    }
    if (assignmentDataError) {
      setError({
        ...error,
        assignmentData: assignmentDataError
      });
      return false;
    }
    const assignmentRequestData = {
      title,
      classes: state.selectedClassesOptions.classes
        .filter((currentClass: any) => currentClass.isSelected)
        .map((currentClass: any) => {
          return {
            classCode: currentClass.code,
            userIds: currentClass.roster
              .filter((player: any) => player.isSelected)
              .map((player: any) => player.educationUserId),
            startDate: currentClass.startDate,
            endDate: currentClass.endDate
          };
        })
        .filter(mappedClass => mappedClass.userIds.length > 0),
      content: {
        type: 'TEST PREP',
        grade
      },
      folderId: props.folderId
    };
    let assignmentData: Array<any> = [
      {
        ...assignmentRequestData,
        content: {
          ...assignmentRequestData.content,
          typeId: newTestPrep.selectedContentId
        }
      }
    ];
    // -1 equals to All Test Prep options were selected
    if (newTestPrep.selectedContentId === -1) {
      assignmentData = [];
      // remove first option which is 'Assign all'
      state.typeOptions.slice(1).forEach((item: any) => {
        assignmentData.push({
          ...assignmentRequestData,
          title: `${assignmentRequestData.title} (${item.name})`,
          content: {
            ...assignmentRequestData.content,
            typeId: item.id
          }
        });
      });
    }
    dispatch(createAssignmentV2(assignmentData));
  };

  const setApiLoadingError = (error: any) => {
    setError({
      ...error,
      loading: `There was an error processing your request, please contact support if the
			problem persists.`
    });
  };

  const handleSelectAllClick = () => {
    const { allSelected } = state.students;
    const data = state.students.data.map((student: any) => ({
      ...student,
      selected: !allSelected
    }));
    setState({
      ...state,
      students: {
        ...state.students,
        data,
        allSelected: !allSelected
      }
    });
  };

  const handleCheckOption = (event: any) => {
    const selectedContentId = parseInt(
      (event.target as HTMLInputElement).value
    );
    const content = state.typeOptions.filter(
      (item: any) => item.id === selectedContentId
    );
    let title = content[0].name;
    if (content[0].id === -1) {
      title = 'All Assessments';
    }
    setNewTestPrep({
      ...newTestPrep,
      selectedContentId,
      title
    });
  };

  const handleTextCapitalization = (text: string) => {
    return `${text.charAt(0)}${text.substring(1, text.length).toLowerCase()}`;
  };

  const handleLoadContent = async () => {
    try {
      setState({ ...state, loading: true });
      const { type, grade, id } = newTestPrep;
      const availableContent = await dispatch(
        getAssignmentContent({
          classCode: currentClass.code,
          type: type.toUpperCase(),
          grade,
          typeId: id
        })
      );
      const typeOptions = availableContent.data.map((item: any) => item);
      typeOptions.unshift({
        id: -1,
        name: 'Assign All Test Preps',
        description: 'Recommended: 4 weeks before test administration'
      });
      setState({
        ...state,
        typeOptions,
        loading: false
      });
      setNewTestPrep({
        ...newTestPrep,
        selectedContentId: typeOptions[0].id
      });
    } catch (error) {
      setApiLoadingError(error);
    }
  };

  const handleSelecType = (event: any) => {
    const { value: id } = event.target;
    const newTestPrepObj = testPreps.filter(
      (testPrep: any) => testPrep.id === id
    );
    setNewTestPrep(newTestPrepObj[0]);
  };

  const handleSelectGrade = (event: any) => {
    setNewTestPrep({
      ...newTestPrep,
      grade: event.target.value
    });
  };

  const handleOpenAssignmentOptionsModal = () => {
    dispatch(showModal('modal', { type: MODAL_ASSIGNMENT_OPTIONS }));
  };

  const renderNextButton = (action: any, nextStep: Steps, text?: string) => {
    text = text || 'Next';
    if (state.loading) {
      return null;
    }
    return (
      <Button
        primary
        onClick={async () => {
          const response = await action();
          if (response !== false) {
            if (nextStep === Steps.done) {
              return dispatch(hideModal('modal'));
            }
            setCurrentStep(nextStep);
          }
        }}
      >
        {text}
      </Button>
    );
  };

  const renderBackButton = (previousStep: Steps) => {
    if (state.loading) {
      return null;
    }
    return (
      <Button
        onClick={() => setCurrentStep(previousStep)}
        style={{ marginRight: '5px' }}
        className="button--small"
      >
        Back
      </Button>
    );
  };

  const renderWarningMessage = () => {
    // show warning message only if 'Assign All Test Preps' is selected
    if (newTestPrep.selectedContentId === -1) {
      const daysInAWeek = 7;
      const recommendedWeeks = 4;
      const minimumRecommendedDays = daysInAWeek * recommendedWeeks;
      if (
        moment(state.endDate).diff(moment(state.startDate), 'days') <
        minimumRecommendedDays
      ) {
        return (
          <Typography style={{ color: colors.error, padding: '0 15px' }}>
            Please note, 4 weeks will allow most players to complete all 4 test
            prep assessments. When players complete the assessment, the game
            reverts players to our adaptive curriculum so they will continue
            practicing in their zone of proximal development. If reducing the
            end date of the assessment, please allow for ample class time for
            players to complete the assessment.
          </Typography>
        );
      }
    }
    return null;
  };

  let content = null;
  let buttons = null;
  switch (currentStep) {
    case Steps.selectType:
      content = (
        <>
          <Grid xs={12} className={classes.rowGrid}>
            <Typography component="h3">
              Select Region and Grade for Test Prep
            </Typography>
          </Grid>

          <Grid xs={12} className={classes.rowGrid}>
            <InputLabel>Region</InputLabel>
            <Select
              fullWidth
              name="type"
              onChange={handleSelecType}
              value={newTestPrep.id}
            >
              {testPreps.map(item => (
                <MenuItem value={item.id}>
                  {item.name} ({item.standardsName})
                </MenuItem>
              ))}
            </Select>
            <Typography
              style={{
                marginTop: 10,
                cursor: 'pointer',
                color: colors.primary
              }}
              onClick={() =>
                dispatch(showModal('modal', { type: MODAL_TEST_PREP_FEEDBACK }))
              }
            >
              Don't see your region here?
            </Typography>
          </Grid>

          <Grid xs={12} className={classes.rowGrid}>
            <InputLabel>Grade</InputLabel>
            <Select
              fullWidth
              name="grade"
              onChange={handleSelectGrade}
              value={newTestPrep.grade}
            >
              {newTestPrep.grades.map((grade: number) => {
                return <MenuItem value={grade}>{grade}</MenuItem>;
              })}
            </Select>
          </Grid>
        </>
      );
      buttons = (
        <div style={footerStyle}>
          {renderNextButton(handleLoadContent, Steps.selectQuestions)}
        </div>
      );
      break;
    case Steps.selectQuestions:
      const { type } = newTestPrep;
      content = (
        <div>
          <p>
            Select the {handleTextCapitalization(type)} you would like to
            assign.
          </p>
          <div className="dashboard__modal-option-wrapper">
            <PackSelection
              descriptionURL={`${commonRoutes.testPreparationDescriptions}?descriptionId=`}
              options={state.typeOptions}
              handleCheckOption={handleCheckOption}
              selectedValue={newTestPrep.selectedContentId}
            />
          </div>
        </div>
      );
      let info = '0 Test Prep to be assigned';
      if (
        newTestPrep.selectedContentId &&
        newTestPrep.selectedContentId === -1
      ) {
        info = 'All Test Preps will be assigned';
      } else if (
        newTestPrep.selectedContentId &&
        newTestPrep.selectedContentId > 0
      ) {
        info = '1 Test Prep to be assigned';
      }
      if (error.contentSelection) {
        info = error.contentSelection;
      }
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          >
            {info}
          </Typography>
          {renderBackButton(Steps.selectType)}
          {renderNextButton(() => handleSetContent(), Steps.selectStudents)}
        </div>
      );
      break;
    case Steps.selectStudents:
      const classLength = filteredTeacherClasses.length;
      if (!classLength) {
        content = (
          <div>
            <p>You have no classes, create classes and add players.</p>
          </div>
        );
      } else {
        const handleState = (selectedClassesOptions: any) => {
          setState({ ...state, selectedClassesOptions });
        };
        content = (
          <ClassSelection
            userClasses={filteredTeacherClasses.filter(
              (teacherClass: { roster: string | any[] }) =>
                teacherClass.roster.length > 0
            )}
            handleState={handleState}
          />
        );
        let selectedStudents = 0;
        state.selectedClassesOptions.classes.forEach((currentClass: any) => {
          const currentClassRoster = currentClass.roster.filter(
            (player: any) => player.isSelected
          );
          selectedStudents += currentClassRoster.length;
        });
        let info = `${selectedStudents} players selected`;
        if (error.studentSelection) {
          info = error.studentSelection;
        }
        buttons = (
          <div style={footerStyle}>
            <Typography
              component="span"
              className={clsx(
                classes.footerInfo,
                isMobile && classes.footerInfoMobile
              )}
            >
              {info}
            </Typography>
            {renderBackButton(Steps.selectQuestions)}
            {renderNextButton(handleSetStudents, Steps.selectDate)}
          </div>
        );
      }
      break;
    case Steps.selectDate:
      content = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid spacing={4}>
            <TextField
              fullWidth
              name="name"
              variant="outlined"
              label="Give a name to your assignment"
              value={newTestPrep.title}
              className={classes.input}
              onChange={(event: any) =>
                setNewTestPrep({
                  ...newTestPrep,
                  title: event.target.value
                })
              }
            />
          </Grid>
        </MuiPickersUtilsProvider>
      );
      let assignmentInfo = '';
      if (error.assignmentData) {
        assignmentInfo = error.assignmentData;
      }
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          >
            {assignmentInfo}
          </Typography>
          {renderBackButton(Steps.selectStudents)}
          {renderNextButton(handleSetDate, Steps.done, 'Done')}
        </div>
      );
      break;
    default:
      content = null;
  }

  let errorMessage = null;
  if (error.loading) {
    content = null;
    buttons = null;
    errorMessage = <p>{error.loading}</p>;
  }

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Assign Test Prep
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            if (props.assignmentType) {
              handleOpenAssignmentOptionsModal();
            } else {
              handleHide();
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ minWidth: '400px' }}>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {errorMessage}
        {buttons}
      </DialogActions>
    </ModalAbstract>
  );
};

export default CreateTestPrepModal;
