import React from 'react';
import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DSLogo from '../../assets/shoelace-logo.png';
import colors from '../../styles/colors';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: colors.appbar,
    height: 64,
    margin: 0
  },
  title: {
    display: 'inline-block',
    fontSize: 25,
    marginLeft: 20,
    marginTop: -7,
    textAlign: 'left'
  },
  logo: {
    height: '49px',
    maxWidth: 'auto',
    width: 'auto'
  }
}));

interface DreamscapeBannerProps {
  title: string;
}

const DreamscapeBanner: React.FC<DreamscapeBannerProps> = (
  props: DreamscapeBannerProps
) => {
  const classes = useStyles();
  return (
    <AppBar
      position="static"
      className={classes.appBar}
      style={{ height: isMobile ? 55 : '' }}
    >
      <Toolbar>
        <img src={DSLogo} alt="logo" className={classes.logo} />
        {isMobile ? null : (
          <Typography variant="h3" className={classes.title} color="inherit">
            {props.title}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default DreamscapeBanner;
