import React, { Suspense } from 'react';
import { Routes } from '../router/routes';
import { useAppSelector } from '../redux/hooks';
import { Features } from '../feature-flag/Features';
import { isBetaUser } from '../utils/beta';
import Forbidden from '../components/forbidden';
import LazyLoaderScreen from '../features/lazy-loading/components/lazy-loader-screen';
import LazyLayoutLoaderScreen from '../features/lazy-loading/components/lazy-layout-loader-screen';

const FeatureGuard = (props: Routes) => {
  const { featureName } = props;
  const features = useAppSelector(state => state.featureFlag);
  const userData = useAppSelector(state => state.userData);
  if (
    !features.userGeneratedContent?.hasAccess &&
    !isBetaUser(userData.email) &&
    featureName === Features.USER_GENERATED_CONTENT
  ) {
    return (
      <props.layout>
        <Forbidden />
      </props.layout>
    );
  } else {
    return (
      <Suspense fallback={<LazyLayoutLoaderScreen />}>
        <props.layout>
          <Suspense fallback={<LazyLoaderScreen />}>
            <props.component />
          </Suspense>
        </props.layout>
      </Suspense>
    );
  }
};

export default FeatureGuard;
