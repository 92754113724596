import React, { useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import DSLogo from '../../assets/shoelace-logo.png';
import LoginForm from './LoginForm';
import colors from '../../styles/colors';
import { hide } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { userTypesDashboardRoute } from '../../constants/users';
import { useLocation, useRouteMatch } from 'react-router';
import { isValidJwt } from '../../utils/jwt';
import { loginRoutes } from '../../constants/routes';
import mixpanel from '../../utils/mixpanel';
import store, { RootState } from '../../redux/store';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  }
}));

interface LocationState {
  redirectTo?: string;
}

const Login: React.FC = () => {
  const userData = useSelector((state: any) => state.userData);
  const currentClass = useSelector(
    (state: any) => state.class.currentClass.code
  );
  const dispatch = useDispatch();
  const match: any = useRouteMatch();
  const classes = useStyles();
  const location = useLocation<LocationState>();
  useEffect(() => {
    dispatch(hide('spinner'));
  }, []);

  const getRedirectPath = (redirectTo: string) => {
    if (redirectTo.includes('assignment-tool')) {
      const splittedPath = redirectTo.split('/assignment-tool/');
      return `${splittedPath[0]}/assignment-tool/${currentClass}`;
    }
    return redirectTo;
  };

  if (userData.userType && isValidJwt(userData.token)) {
    if (location.state?.redirectTo) {
      const redirectTo = getRedirectPath(location.state.redirectTo);
      dispatch(
        push(redirectTo, {
          // Ideally, there should be no need to pass "redirectTo" again,
          // But getUser function redirects the user back to dashboard
          // To avoid that, this "redirectTo" is needed
          // TODO: Refactor to avoid this redirectTo
          redirectTo: redirectTo
        })
      );
    } else {
      dispatch(push(userTypesDashboardRoute[userData.userType]));
    }
    return null;
  }

  return (
    // <Grid container>
    //   <div className={classes.logoWrapper}>
    //     <img
    //       src={DSLogo}
    //       style={{ maxWidth: 300, marginTop: '10px' }}
    //       alt="logo"
    //     />
    //   </div>
    //   <LoginForm />
    //   <Grid item container justifyContent="center" alignItems="flex-end">
    //     <span style={{ color: '#999', marginBottom: '40px' }}>
    //       Don't have an account? &nbsp;
    //       <Link
    //         to={loginRoutes.registerEducator}
    //         title="Sign Up!"
    //         style={{
    //           color: colors.primary,
    //           fontWeight: 600,
    //           textDecoration: 'none'
    //         }}
    //       >
    //         Sign Up.
    //       </Link>
    //     </span>
    //   </Grid>
    // </Grid>
    <Box
      sx={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <CircularProgress style={{ marginBottom: '20px' }} />
      <Typography variant="h4" style={{ color: '#999' }}>
        Redirecting to Login Page...
      </Typography>
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        If you are not automatically redirected, Please
        <a
          href={`${process.env.REACT_APP_NEW_DASHBOARD_URL}/login`}
          style={{ margin: '0 5px' }}
        >
          click here
        </a>
        to login.
      </Typography>
    </Box>
  );
};

export default Login;
