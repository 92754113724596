import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { hide, show } from 'redux-modal';
import { push } from 'connected-react-router';
import colors from '../../styles/colors';
import borders from '../../styles/borders';
import Button from '../../components/button/Button';
import { getPlanner } from '../../redux/actions/planner-actions';
import {
  MODAL_CREATE_ASSIGNMENT,
  MODAL_CREATE_TEST_PREP,
  MODAL_SELECT_ASSIGNMENT_TYPE
} from '../../constants/modals';
import View from '../../components/view/View';
import { userRouteGetter } from '../../utils/user-route-getter';
import ChuckContainer from '../../components/chuck/ChuckContainer';
import {
  trackViewAssignmentTool,
  trackViewPlanner
} from '../../redux/actions/mixpanel-actions';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import mixpanel from '../../utils/mixpanel';
import { userTypes } from '../../constants/users';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: colors.white,
    borderRadius: borders.borderRadius,
    height: '100%',
    padding: 20,
    margin: '0 0 30px',
    width: '100%'
  },
  calendar: {
    heigth: '100%',
    width: '100%'
  },
  button: {
    position: 'absolute',
    top: 30
  }
}));

const PlannerView: React.FC<any> = (props: any) => {
  const localizer = momentLocalizer(moment);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const features = useAppSelector(state => state.featureFlag);
  const { currentClass } = useAppSelector(state => state.class);
  const url = window.location.href;
  const classCode = url.split('/')[url.split('/').length - 1].split('?')[0];
  const isUserHaveAccessToUserGeneratedContent =
    features.userGeneratedContent?.hasAccess;
  const [planner, setPlanner] = useState(
    isUserHaveAccessToUserGeneratedContent ? [] : props.planner
  );
  const history = useHistory();
  const oldDashboardAccessConfig = useAppSelector(
    state => state.featureFlag.oldDashboardAccessConfig
  );
  const token = useAppSelector(state => state.login.token);

  useEffect(() => {
    dispatch(trackViewPlanner());
    if (isUserHaveAccessToUserGeneratedContent) {
      fetchAssignmentsReportByClassCode(currentClass.code || classCode);
    } else {
      setPlanner(props.planner);
    }
  }, [props.planner, classCode]);

  const handleClickCreateAssignment = () => {
    if (oldDashboardAccessConfig.redirectAssigmentCreation) {
      window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${currentClass.code}/assignments/library?accessToken=${token}`;
    } else {
      const type =
        userData.userCanCreateContent === true
          ? MODAL_SELECT_ASSIGNMENT_TYPE
          : MODAL_CREATE_ASSIGNMENT;
      props.show('modal', { type, pilotProgram: userData.pilotProgram });
    }
  };
  const handleClickCreateTestPrep = () => {
    props.show('modal', {
      type: MODAL_CREATE_TEST_PREP
    });
    mixpanel.track('Assign Test Prep Button Click');
  };

  const userType: string = userRouteGetter(userData.userType);

  async function fetchAssignmentsReportByClassCode(classCode: string) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/user-generated/report/class/${classCode}`,
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.GET
        })
      );
      const { assignments } = response.data;
      if (assignments) {
        setPlanner(assignments);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const handleSelectEvent = (event: any) => {
    if (oldDashboardAccessConfig.redirectAssignmentReports) {
      window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/groups/${currentClass.code}/assignments/${event._id}?accessToken=${token}`;
    } else {
      if (event.isUserGenerated) {
        history.push(
          `/dashboard/${userType}/assignment-tool/report/${event.id}/${event.classCode}?isUserGenerated=true`
        );
      } else {
        history.push(
          `/dashboard/${userType}/assignment-tool/report/${event._id}/${event.classCode}`
        );
      }
    }
    dispatch(trackViewAssignmentTool());
  };

  // Handle setting calendar to certain date
  const params = new URL(document.location.toString()).searchParams;
  let dateParam = Number(params.get('defaultDate')) || '';
  let formattedDateParam = new Date(dateParam);
  const defaultDate =
    formattedDateParam instanceof Date && !isNaN(formattedDateParam.valueOf())
      ? formattedDateParam
      : new Date();

  return (
    <View
      title="Assignment Calendar"
      renderButtons={() => (
        <>
          {userData.userType !== userTypes.parent &&
            !oldDashboardAccessConfig.redirectAssigmentCreation && (
              <Button
                blue
                style={{ marginRight: 10 }}
                onClick={handleClickCreateTestPrep}
              >
                Assign Test Prep
              </Button>
            )}
          <Button primary onClick={handleClickCreateAssignment}>
            Create Assignment
          </Button>
        </>
      )}
    >
      <ChuckContainer />
      <Grid
        container
        spacing={4}
        className={classes.wrapper}
        style={{
          height: '80vh'
        }}
      >
        <Calendar
          popup
          localizer={localizer}
          views={{ month: true }}
          defaultDate={defaultDate}
          className={classes.calendar}
          events={planner}
          eventPropGetter={(event: any) => ({
            style: { background: event.color }
          })}
          onNavigate={(date: Date) => {
            history.replace({ search: `?defaultDate=${date.getTime()}` });
          }}
          onSelectEvent={event => handleSelectEvent(event)}
        />
      </Grid>
    </View>
  );
};

const mapStateToProps = (state: any) => ({
  planner: state.planner,
  class: state.class
});

const mapActionsToProps = {
  getPlanner,
  show,
  hide,
  push
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(PlannerView));
