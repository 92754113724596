import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import { MODAL_ASSIGN_REWARDS } from '../../../constants/modals';
import Button from '../../../components/button/Button';
import View from '../../../components/view/View';
import ChuckContainer from '../../../components/chuck/ChuckContainer';
import Grid from '@material-ui/core/Grid';
import SearchBar from '../../../components/searchbar/SearchBar';
import Table from '../../../components/table/Table';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { teacherRoutes } from '../../../constants/routes';
import {
  teacherNavigateToPremiumPlanPage,
  userNavigateWithClassCode
} from '../../../utils/user-route-getter';
import { fetchApi } from '../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../types/fetch/fetch-types';
import { AxiosResponse } from 'axios';
import { LinearProgress, Tooltip } from '@material-ui/core';
import {
  trackRewardPageViewed,
  trackRewardUpgradeMembershipButtonClicked
} from '../../../redux/actions/mixpanel-actions';
import colors from '../../../styles/colors';
import store, { RootState } from '../../../../src/redux/store';

const useStyles = makeStyles((theme: Theme) => ({
  highlight: {
    fontWeight: 'bold',
    color: colors.primary
  }
}));

const RewardsPage: React.FC<any> = (props: any) => {
  const styles = useStyles();
  const currentClass = useSelector((state: any) => state.class.currentClass);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const userData = useSelector((state: any) => state.userData);
  const isPremiumUser = userData.premium && userData.premium.isPremium;
  const [rewardStudents, setRewardStudents] = React.useState([]);
  const [availableRewards, setAvailableRewards] = React.useState(undefined);
  const [rewardsUsed, setRewardsUsed] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const { featureFlag } = store.getState() as RootState;
  const dispatch = useDispatch();
  useEffect(() => {
    fetchPlayers();
  }, [classCode]);
  useEffect(() => {
    fetchPlayers();
    dispatch(trackRewardPageViewed());
  }, []);

  function fetchPlayers() {
    dispatch(
      fetchApi({
        url: `rewards/players/${classCode}`,
        method: APIMethods.GET
      })
    )
      .then((response: AxiosResponse) => {
        const { players, numberOfRewardsAvailable } = response.data;
        setRewardStudents(players);
        setAvailableRewards(numberOfRewardsAvailable);
        setRewardsUsed(3 - numberOfRewardsAvailable);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  let columns = [
    {
      Header: 'Name',
      accessor: 'fullname',
      Cell: (row: any) => {
        const { firstname, lastname } = row.row.original;
        return `${firstname} ${lastname}`;
      }
      //   const { fullname, clever, google } = row.row.original;
      //   let cleverIcon = null;
      //   let googleIcon = null;
      //   if (clever) {
      //     cleverIcon = (
      //       <img
      //         src={CleverIcon}
      //         alt="Clever Account"
      //         style={{ margin: '2px 10px', width: 15, verticalAlign: 'top' }}
      //       />
      //     );
      //   }
      //   if (google) {
      //     googleIcon = (
      //       <img
      //         onClick={() =>
      //           dispatch(
      //             show('modal', {
      //               type: MODAL_GOOGLE_ACCOUNT_INFO,
      //               ...google
      //             })
      //           )
      //         }
      //         src={GoogleIcon}
      //         alt="Google Account"
      //         style={{
      //           margin: '2px 10px',
      //           width: 15,
      //           verticalAlign: 'top',
      //           cursor: 'pointer'
      //         }}
      //       />
      //     );
      //   }
      //   return (
      //     <>
      //       <span>{fullname}</span>
      //       {cleverIcon}
      //       {googleIcon}
      //     </>
      //   );
      // }
    },
    {
      Header: 'Username',
      accessor: 'username'
    },
    {
      Header: 'Rewards',
      Cell: (row: any) => {
        const data = row.row.original;
        const shouldDisableButton =
          (availableRewards !== undefined &&
            availableRewards === 0 &&
            !isPremiumUser) ||
          currentClass.archived;
        const rewardErrorMessage = currentClass.archived
          ? 'You cannot assign rewards in archived classes'
          : "You've reached your max limit for the month! Please upgrade to assign more rewards.";
        return (
          <>
            {/* TODO: Add "Hero" plan in place of "Premiumplus" when it's ready */}
            {(featureFlag.unlimitedRewards &&
              userData.premium.type === 'PremiumPlus') ||
            !data.isAssigned ? (
              <Tooltip title={shouldDisableButton ? rewardErrorMessage : ''}>
                <div>
                  <Button
                    primary
                    disabled={shouldDisableButton}
                    onClick={() => {
                      dispatch(
                        show('modal', {
                          type: MODAL_ASSIGN_REWARDS,
                          playerId: data._id,
                          classCode,
                          callback: fetchPlayers
                        })
                      );
                    }}
                  >
                    Assign Rewards
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <span>Player has already been assigned a reward this month.</span>
            )}
          </>
        );
      }
    }
  ];

  let title = 'Rewards';

  return (
    <View title={title}>
      {!isPremiumUser && availableRewards !== undefined ? (
        <Typography
          component="h2"
          variant="h6"
          style={{ marginTop: -15, marginBottom: 10 }}
        >
          You have used<span className={styles.highlight}> {rewardsUsed} </span>
          of your<span className={styles.highlight}> 3 </span>
          free monthly rewards. Access unlimited monthly rewards by
          {
            <Button
              primary
              marginLeft={10}
              marginRight={5}
              style={{ marginBottom: 5 }}
              onClick={() => {
                dispatch(trackRewardUpgradeMembershipButtonClicked());
                dispatch(
                  push(
                    teacherNavigateToPremiumPlanPage(
                      teacherRoutes.teacherPremiumPlanClass
                    )
                  )
                );
              }}
            >
              <Typography component="h2" variant="h6">
                upgrading
              </Typography>
            </Button>
          }{' '}
          to a Premium Plan.
        </Typography>
      ) : (
        ''
      )}
      <ChuckContainer />
      <Grid container spacing={3} justify="space-between">
        <Grid item lg={3} sm={12} xs={12}>
          <SearchBar
            placeholder="Search by Player Name"
            searchKey="fullname"
            originalState={currentClass.roster}
            onChange={(searchState: any) => setRewardStudents(searchState)}
          />
        </Grid>
      </Grid>
      <br />
      <Grid item lg={12}>
        {loading && <LinearProgress />}
        <Table columns={columns} data={rewardStudents} />
      </Grid>
    </View>
  );
};

export default React.memo(withRouter(RewardsPage));
