import React, { useState } from 'react';
import Button from '../../button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { validateEmail } from '../../../utils/email';
import { show } from 'redux-modal/lib/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generateApi } from '../../../redux/util/api-promisfy';
import { APIMethods } from '../../../types/fetch/fetch-types';
import CloseIcon from '@material-ui/icons/Close';

const ModalInviteAdmin: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const [errors, setError] = useState({
    isError: false,
    text: ""
  });
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    if (!validateEmail(evt.target.value)) {
      setError({isError: true, text: "email is invalid"});
    } else {
      setError({isError: false, text: ""});
    }
    setEmail(evt.target.value);
  }
  function handleInvite() {
    if (!validateEmail(email)) {
      setError({isError: true, text: "email is invalid"});
      return;
    }
    setLoading(true);
    generateApi({url: `user/sendEmail`, method: APIMethods.POST, data: {
      emailFrom: userData.email,
      emailTo: email,
      firstName: userData.firstName,
      lastName: userData.lastName
    }})
    .then(() => {
      setLoading(false);
      dispatch(
        show('snackbar', { variant: 'success', message: 'Email sent' })
      );
      setEmail("");
    })
      .catch(() => {
        setLoading(false);
        dispatch(
          show('snackbar', { variant: 'error', message: 'Error sending email' })
        );
      });
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleHide}
        maxWidth="xs"
      >
        <DialogContent style={{ padding: 20, position: "relative" }} dividers={true}>
          <IconButton
            style={{position: "absolute", top: 10, right: 10}}
            aria-label="close"
            onClick={() => props.handleHide()}
          >
            <CloseIcon />
          </IconButton>
          <Grid container justify={"center"} alignItems={"center"}>
            <Grid item xs={12} style={{textAlign: "center"}}>
              <h3>Invite Admin</h3>
            </Grid>
            <Grid item xs={12} style={{
              display: "flex",
              marginBottom: "20px",
              justifyContent: "center"}}>
              <FormControl error={errors.isError} fullWidth={true}>
                <TextField
                  fullWidth={true}
                  value={email}
                  label="Email"
                  name="email"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {errors.isError ? errors.text : null}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{textAlign: "center"}}>
              <p>
                Invite Admins to get started with Dreamscape so they can organize free Dreamscape trainings, and free Dremscape tournaments, for your school, all for free!
              </p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          {
            loading ?
              <CircularProgress />
                :
              <Button onClick={handleInvite} primary>
                Invite!
              </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalInviteAdmin;
