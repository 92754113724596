import {
  getSummerNotificationState,
  setSummerNotificationState,
  SummerNotificationState
} from '../utils';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { has } from 'lodash';
import TeacherFeatureFlagContext from '../../../layouts/teacher/TeacherFeatureFlagContext';
import moment from 'moment';

interface ShowSummerNotificationResponse {
  showDot: boolean;
  showBanner: boolean;
  hideSummerNotification: () => void;
  hideSummerNotificationBanner: () => void;
}

const shouldShowSummerNotification = (
  summerNotificationState: SummerNotificationState,
  classCode?: string,
  studentsCount = 0
): [boolean, boolean] => {
  if (!studentsCount || !classCode || !validPromotionPeriod())
    return [false, false];

  if (has(summerNotificationState, classCode)) {
    const classState = summerNotificationState[classCode];
    return [
      has(classState, 'dot') ? classState.dot : true,
      has(classState, 'banner') ? classState.banner : true
    ];
  }

  return [true, true];
};

const validPromotionPeriod = () => {
  const endDate = new Date('2022-06-30');
  return moment().isBefore(endDate);
};

const useShowSummerNotification = (): ShowSummerNotificationResponse => {
  const { flags, updateFlags } = useContext(TeacherFeatureFlagContext);

  const summerNotificationState = getSummerNotificationState();
  const students: unknown[] | undefined = useSelector(
    (state: any) => state.class?.currentClass?.roster
  );
  const params = useParams<{ classCode?: string }>();
  const classCode = params.classCode;
  const [showDotDefault, showBannerDefault] = shouldShowSummerNotification(
    summerNotificationState,
    classCode,
    students?.length
  );

  const [showDot, setShowDot] = useState(showDotDefault);
  const [showBanner, setShowBanner] = useState(showBannerDefault);

  useEffect(() => {
    setShowDot(showDotDefault);
    setShowBanner(showBannerDefault);
    updateFlags({
      ...flags,
      summerNotification: { dot: showDotDefault, banner: showBannerDefault }
    });
  }, [classCode, showBannerDefault, showDotDefault]);

  const updateSummerNotificationState = (
    dot: boolean,
    banner: boolean
  ): void => {
    if (!classCode) return;

    summerNotificationState[classCode] = {
      dot,
      banner
    };
    setSummerNotificationState(summerNotificationState);
    setShowDot(dot);
    setShowBanner(banner);
  };

  const hideSummerNotification = () =>
    updateSummerNotificationState(false, false);

  const hideSummerNotificationBanner = () =>
    updateSummerNotificationState(true, false);

  return {
    showDot,
    showBanner,
    hideSummerNotification,
    hideSummerNotificationBanner
  };
};

export default useShowSummerNotification;
