import React from 'react';
import {
  Grid,
  Button,
  IconButton,
  Typography,
  Hidden
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import dashboardBannerImage from '../../assets/dashboard-banner-image-student.png';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPDF } from '../../redux/actions/class-actions';
import mixpanel from '../../utils/mixpanel';
import { show } from 'redux-modal';
import { getPlayerLoginsInfo, setPlayerLoginsInfo } from '../../utils/user';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#009DE0',
    position: 'relative',
    padding: '0  40px 0 20px'
  },
  text: {
    color: '#fff',
    fontSize: '14px'
  }
}));

export const DownloadPlayerLoginsBanner: React.FC<{
  setBannerClose: (P: boolean) => void;
}> = ({ setBannerClose }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const currentClass = useSelector((state: any) => state.class.currentClass);
  const userData = useSelector((state: any) => state.userData);

  const onSuccessPdfDownload = () => {
    dispatch(
      show('snackbar', {
        variant: 'success',
        message: 'Your logins are successfully downloaded!'
      })
    );
    handleBannerClose('Player logins downloaded(banner)');
  };

  const handlePlayerLogins = () => {
    mixpanel.track('Player logins clicked (banner)', {
      userEmail: userData.email,
      userId: userData._id,
      classCode: currentClass.code
    });
    dispatch(downloadPDF(userData, currentClass, onSuccessPdfDownload));
  };

  const handleBannerClose = (trackMessage: string) => {
    mixpanel.track(trackMessage);
    const playerLoginsInfoString = getPlayerLoginsInfo();
    if (playerLoginsInfoString !== null) {
      const playerLoginsInfo = JSON.parse(playerLoginsInfoString);
      const classArr = Object.keys(playerLoginsInfo);

      const res = classArr.some((item: any) => {
        if (item === currentClass.code) {
          setPlayerLoginsInfo(
            JSON.stringify({
              ...playerLoginsInfo,
              [item]: {
                ...playerLoginsInfo[item],
                banner: false
              }
            })
          );
        }
        return false;
      });
      setBannerClose(res);
    }
  };
  if (userData.userType == 'Teacher') {
    return (
      <Grid container className={styles.container} alignItems="center">
        <Grid
          item
          xs={4}
          md={8}
          sm={8}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img
              src={dashboardBannerImage}
              alt="Player Logins"
              height={60}
              style={{ transform: 'scaleX(-1)' }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid item xs={6} sm={10} md={10} style={{ padding: '0 20px' }}>
              <Typography component="p" className={styles.text}>
                The fun doesn’t have to end! Send a copy of your player's logins
                home to continue the fun after school.
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          item
          xs={8}
          sm={4}
          md={4}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Grid
            item
            xs={8}
            sm={10}
            md={10}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="outlined"
              size="large"
              startIcon={<GetAppIcon />}
              style={{ backgroundColor: '#fff', whiteSpace: 'nowrap' }}
              onClick={handlePlayerLogins}
            >
              Player Logins
            </Button>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <IconButton
              onClick={() => handleBannerClose('Dismiss player logins banner')}
            >
              <CloseIcon style={{ color: '#fff' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
};
