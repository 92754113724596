import React from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const NoLayout: React.FC = ({ children }) => {
  return (
    <Grid container style={isMobile ? {} : { height: '100vh' }}>
      {children}
    </Grid>
  );
};

export default NoLayout;
