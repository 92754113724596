import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme, Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import colors from '../../../styles/colors';
import ReactHTMLParser from 'react-html-parser';
import { show as modalShow } from 'redux-modal';
import { userTypes } from '../../../constants/users';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRightAlt, Lock, Warning } from '@material-ui/icons';
import { MODAL_PARENT_MEMBERSHIP } from '../../../constants/modals';
import { QuestionFormat } from '../../../enums/QuestionFormat';

const useStyles = makeStyles((theme: Theme) => ({
  td: {
    border: '1px solid #dcdcdc',
    padding: 15,
    textAlign: 'center'
  },
  th: {
    padding: 15
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  answerFormat: {
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    textAlign: 'left'
  },
  questionText: {
    '& img': {
      maxWidth: '100%'
    }
  }
}));

const ModalQuestionBreakdown: React.FC<any> = (props: any) => {
  const { show, handleHide } = props;
  const classes = useStyles();
  const { question, users } = props;
  const dispatch = useDispatch();
  const { userType } = useSelector((state: any) => state.userData);

  const renderPassage = () => {
    if (question.text && (question.questionType !== 'Open-Ended' && question.questionType !== 'OpenEnded')) {
      return (
        <>
          <Typography component="p" style={{ marginTop: 0 }}>
            <strong>{question.passageTitle}</strong>
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: question.text }}
            style={{ marginBottom: 20 }}
          />
        </>
      );
    }
    return null;
  };

  const handleShowMembershipPopup = () => {
    dispatch(modalShow("modal", {
      type: MODAL_PARENT_MEMBERSHIP,
      origin: props.origin || "Origin not passed"
    }));
  }
  const renderAnswersTable = () => {
    return (
      <table style={{ width: '100%', borderSpacing: 0 }}>
        <tr>
          <th className={classes.th}>Player name</th>
          <th className={classes.th}>Answer</th>
          <th className={classes.th}>
            {(question.questionType === 'Open-Ended' || question.questionType === 'OpenEnded') ? 'Complete' : 'Correct'}
          </th>
        </tr>
        {question.answers.map((item: any) => {
          const user = users.filter(
            (user: any) => user.educationUserId === item.userId
          )[0];
          item = {
            ...item,
            ...user
          };
          let background = '';
          let correct = "Didn't answer";
          let answer = '-';
          let fontColor = colors.white;
          const isParentNonMember = userType === userTypes.parent && !item.isMember
          if (isParentNonMember) {
            fontColor = '#0000008a';
            correct = "Only available for members";
            answer = correct;
          } else if (item.isCorrect !== null) {
            answer = JSON.parse(item.answer)
              .join(', ')
              .replace(/\n/g, '<br/>');
            if (item.isCorrect === 1 || item.isCorrect === true) {
              background = colors.third;
              correct =
                (question.questionType === 'Open-Ended' || question.questionType === 'OpenEnded') ? 'Complete' : 'Correct';
            } else {
              background = colors.secondary;
              correct =
                (question.questionType === 'Open-Ended' || question.questionType === 'OpenEnded')
                  ? 'Incomplete'
                  : 'Incorrect';
            }
          }
          return (
            <tr>
              <td className={classes.td}>
                {item.firstname} {item.lastname}
              </td>
              <td className={classes.td}>{ReactHTMLParser(answer)}</td>
              <td
                className={classes.td}
                style={{ background, color: fontColor, display: "flex", alignItems: "center" }}
              >
                {correct}
                {
                  isParentNonMember &&
                  <Tooltip
                    title="This player is not a member. Click here to learn more"
                    onClick={handleShowMembershipPopup}
                  >
                    <Warning style={{
                      color: colors.secondary,
                      cursor: 'pointer',
                      fontSize: 20,
                      marginLeft: 10
                    }} />
                  </Tooltip>

                }
              </td>
            </tr>
          );
        })}
      </table>
    );
  };

  const renderContent = () => {
    if (!question.answers.length) {
      return (
        <Typography component="p">
          No one has answered this question yet
        </Typography>
      );
    }

    return (
      <>
        {renderPassage()}
        <Typography
          component="p"
          className={classes.questionText}
          dangerouslySetInnerHTML={{ __html: question.question }}
        ></Typography>
        {question.potentialAnswers ? (
          <>
            {question.questionType === QuestionFormat.Pair ?
              (<div>
                <br />
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <h5>Selection List</h5>
                    {(JSON.parse(question.potentialAnswers)[0]).map((selection: any) => (
                      <li>{selection}</li>
                    ))}
                  </div>
                  <div style={{ flex: 3 }}>
                    <h5>Pairing List</h5>
                    {(JSON.parse(question.potentialAnswers)[1]).map((answer: any) => (
                      <li>{answer}</li>
                    ))}
                  </div>
                </div>
                <br />
              </div>)
              : (
                <ul style={{ margin: '15px 30px' }}>
                  {JSON.parse(question.potentialAnswers).map((answer: any) => (
                    <li>{answer}</li>
                  ))}
                </ul>
              )}
            <Typography component="p">
              <strong>Correct Answers: </strong>
              {question.questionType === QuestionFormat.Pair
                ? <ul style={{ margin: '15px 30px' }}>
                  {JSON.parse(question.correctAnswers).map((answer: any) => (
                    <li>
                      <div
                        className={classes.answerFormat}
                      >
                        {answer[0]} &nbsp; <ArrowRightAlt fontSize='small' /> &nbsp; {answer[1]}
                      </div>
                    </li>
                  ))}
                </ul>
                : <ul style={{ margin: '15px 30px' }}>
                  {question.correctAnswers && JSON.parse(question.correctAnswers).map((answer: any) => (
                    <li>{answer}</li>
                  ))}
                </ul>}
            </Typography>
          </>
        ) : null}
        {question.skillName ? (
          <Typography component="p">
            <strong>Skill: </strong>
            {question.skillName}
          </Typography>
        ) : null}
        {question.genreName && (question.questionType !== 'Open-Ended' && question.questionType !== 'OpenEnded') ? (
          <p>
            <strong>Genre: </strong>
            {question.genreName}
          </p>
        ) : null}
        <p>
          <strong>Question Type: </strong> {question.questionType}
        </p>
        <br />
        {renderAnswersTable()}
      </>
    );
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Question Breakdown
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{renderContent()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalQuestionBreakdown;
