/**
 * @description tracks purchase on google analytics
 * @param transactionId
 * @param price
 * @param productName
 * @param coupon
 */
import { UserType } from '../../enums/UserType';

export function trackPurchase(
  transactionId: string | number,
  price: number,
  productName: string,
  coupon: string | number,
  memberships?: string,
  membershipsPrice?: number,
  currencyCode?: string
) {
  let revenue: number = parseInt(price.toString());
  const products = [
    {
      name: productName,
      price: price,
      quantity: 1
    }
  ];

  if (memberships && membershipsPrice) {
    products.push({
      name: 'Educator Bulk Memberships',
      price: membershipsPrice / parseInt(memberships),
      quantity: parseInt(memberships)
    });

    revenue += membershipsPrice;
  }

  // @ts-ignore
  if (window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({
      event: 'transaction',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            revenue,
            coupon
          },
          products
        },
        currencyCode
      }
    });
  }
}

/**
 * @description tracks purchase on google analytics
 * @param transactionId
 * @param price
 * @param productName
 * @param coupon
 */
export function plansViewed(
  transactionId: string | number,
  price: number,
  productName: string,
  coupon: string | number
) {
  // @ts-ignore
  if (window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({
      event: 'transaction',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            revenue: price,
            // 'tax':'INSERT TAX AMOUNT HERE',
            coupon
          },
          products: [
            {
              name: productName,
              price: price,
              quantity: 1
            }
          ]
        }
      }
    });
  }
}

/**
 * @description google analytics impressions
 * @param products
 */
export function trackPremiumPlansProductImpressions(products: any) {
  try {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'productImpression',
      ecommerce: {
        impressions: products.map((plan: any, index: number) => {
          return {
            name: plan.title,
            price: plan.price,
            list: 'Educator Plans',
            position: index + 1
          };
        })
      }
    });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description tracks buy click premium plan
 * @param plan
 */
export function trackBuyClickPremiumPlan(plan: string, price: string | number) {
  try {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: [
            {
              name: plan,
              price,
              quantity: 1
            }
          ]
        }
      }
    });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description tracks register
 * @param userId
 */
export function trackRegister(userId: string | number, accountType: UserType) {
  const userAccountType = getAccountType(accountType);
  try {
    // @ts-ignore
    window.dataLayer.push({
      event: 'register',
      userId,
      accountType: userAccountType
    });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description segments account type
 * @param accountType
 */
function getAccountType(accountType: UserType) {
  let userAccountType: string = accountType;
  switch (accountType) {
    case UserType.Teacher:
      userAccountType = 'Educator';
      break;
    case UserType.Player:
      userAccountType = 'Student';
      break;
    default:
      userAccountType = userAccountType;
  }
  return userAccountType;
}

/**
 * @description tracks google analytics
 * @param userId
 */
export function trackLoginGoogleAnalytics(
  userId: string,
  accountType: UserType
) {
  const userAccountType = getAccountType(accountType);
  try {
    // @ts-ignore
    window.dataLayer.push({
      event: 'login',
      userId,
      accountType: userAccountType
    });
  } catch (err) {
    console.error('setUserIdOnGoogleAnalytics()', err);
  }
}
