import {
  CLEVER_GET_STUDENTS,
  CLEVER_SELECT_STUDENT,
  CLEVER_REQUEST_FAILED,
  CLEVER_GET_STUDENTS_IN_PROGRESS
} from '../types/clever/clever-action-types';
import { CleverStudent } from '../../types/clever/clever-student';

export const cleverReducer = (
  state: any = {
    students: [],
    error: false,
    inProgress: false
  },
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case CLEVER_GET_STUDENTS_IN_PROGRESS:
      return {
        ...state,
        inProgress: true
      };
    case CLEVER_GET_STUDENTS:
      return {
        ...state,
        inProgress: false,
        students: payload.map((student: CleverStudent) => ({
          ...student,
          selected: true
        }))
      };
    case CLEVER_SELECT_STUDENT:
      return {
        ...state,
        students: state.students.map((student: CleverStudent) => {
          if (student.id === payload.id) {
            student.selected = !student.selected;
          }
          return student;
        })
      };
    case CLEVER_REQUEST_FAILED:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
};
