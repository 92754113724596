import { USER_GET_SUCCESS } from '../types/user/user-action-type';
import mixpanel from '../../utils/mixpanel';

/**
 * @description returns google cookie id
 */
function googleCookie() {
  // @ts-ignore
  return document.cookie
    .match(/_ga=(.+?);/)[1]
    .split('.')
    .slice(-2)
    .join('.');
}
/**
 * @description middleware for login
 * @param store
 */
export const loginMiddleware = (store: any) => (next: any) => (action: any) => {
  if ([USER_GET_SUCCESS].includes(action?.type)) {
    const userData = action.payload;
    setClientIdOnMixpanel(userData);
  }
  next(action);
};

/**
 * @description sets client id on mixpanel using google cookie
 * @param userData
 */
function setClientIdOnMixpanel(userData: any) {
  try {
    // const clientId = googleCookie();
    if (userData && userData._id) {
      mixpanel.identify(userData._id);
      mixpanel.people.set('clientId', userData._id);
    }
  } catch (err) {
    console.error('setClientIdOnMixpanel()', err);
  }
}
