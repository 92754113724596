import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ModalAbstract from '../ModalAbstract';
import Button from '../../../button/Button';
import moment from 'moment';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../../types/fetch/fetch-types';
import { cancellationReasons } from '../../../../enums/CancellationReasons';

interface ModalCancelMembershipProps {
  show: () => void;
  handleHide: () => void;
  membershipData: any;
  token: string;
  onSuccess: () => any;
}

const ModalCancelMembership: React.FC<ModalCancelMembershipProps> = (
  props: ModalCancelMembershipProps
) => {
  const { show, handleHide, membershipData, token } = props;
  const [isCancelled, setIsCancelled] = useState(false);
  const dispatch = useDispatch();

  const handleCancelSubscriptions = async () => {
    dispatch(
      fetchApi({
        url: 'sw/canceltoken/cancel',
        data: {
          cancellationReason,
          membershipData,
          token
        },
        method: APIMethods.POST,
        hideSpinnerAfter: true,
        spinnerMessage: 'Cancelling subscription',
        errorMessage: 'There was an issue cancelling your subscriptions'
      })
    )
      .then(() => {
        props.onSuccess();
        setIsCancelled(true);
      })
      .catch((err: any) => {
        props.handleHide();
      });
  };

  const [cancellationReason, setCancellationReason] = useState(
    cancellationReasons[0]
  );

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        {isCancelled ? 'We’ll miss you!' : 'We’re sorry to see you go!'}
      </DialogTitle>
      <DialogContent>
        <Grid container justify={'center'} alignItems={'center'}>
          {isCancelled ? (
            <Grid item xs={12}>
              <b>Okay, here’s what to expect…</b>
              <ul style={{ marginLeft: '20px' }}>
                <li>
                  Monthly memberships will continue for the month you have paid
                  for, and not renew for the next month
                </li>
                <li>
                  Yearly memberships will continue for the year that you paid
                  for, and not renew for the next year
                </li>
              </ul>
              <br />
              <b>For your information:</b>
              <ul style={{ marginLeft: '20px' }}>
                <li>
                  Monthly Dreamscape memberships are billed once per month on an
                  on-going basis until cancellation
                </li>
                <li>
                  Annual Dreamscape memberships are billed once per year on an
                  on-going basis until cancellation
                </li>
                <li>Refunds will not be issued</li>
              </ul>
              <br />
              <b>
                NOTE: The player is welcome to continue playing Dreamscape
                without the membership features, for free, forever.
              </b>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                {membershipData.map((membership: any) => {
                  return (
                    <div>
                      <b>{moment(membership.expiryDate).format('ll')}</b>
                      &nbsp;
                      <span>{membership.type}</span>
                    </div>
                  );
                })}
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ marginBottom: 10 }}>
                  Your subscription(s) are paid for until the Next Billing Date
                  listed above.
                  <br />
                  <br />
                  Upon confirming subscription cancellation below, you will not
                  have the ability to access{' '}
                  <a
                    target="_blank"
                    href="https://www.playdreamscape.com/membership/#what-to-expect"
                  >
                    membership benefits
                  </a>{' '}
                  such as rewards, new worlds and exclusive avatars beyond the
                  Next Billing Date.
                  <br />
                  <br />
                  At the end of your subscription, your member items will remain
                  in your account but you will not be able to upgrade them.
                  Before you go, would you mind letting us know why? Your
                  feedback will help us make Dreamscape better for other people.
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  value={cancellationReason}
                  onChange={(event: any) =>
                    setCancellationReason(event.target.value)
                  }
                >
                  {cancellationReasons.map((reason, index) => (
                    <MenuItem value={reason} key={index}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        {!isCancelled && (
          <Button primary onClick={handleCancelSubscriptions}>
            Cancel Membership
          </Button>
        )}
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalCancelMembership;
