import React, { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Table,
  TableRow,
  TableCell,
  Button
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { show } from 'redux-modal';
import View from '../../components/view/View';
import ChartContainer from '../../components/chart/ChartContainer';
import { getPlayerReport } from '../../redux/actions/player-actions';
import { ChevronLeft } from '@material-ui/icons';
import { trackSelectPlayerReadingLevel } from '../../redux/actions/mixpanel-actions';
import { useHistory } from 'react-router-dom';
import { playerNounLowerCase, playerNounUppercase } from '../../utils/userNoun';

const useStyles = makeStyles(() => ({
  select: {
    marginBottom: 40
  }
}));

const ReadingLevelReportView: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(null);
  const history = useHistory();

  const playersOptions = props.class.currentClass.roster.map(
    (student: any) => ({
      label: `${student.fullname} (${student.username})`,
      value: student._id
    })
  );

  useEffect(() => {
    if (playersOptions.length) {
      handleLoadPlayer(playersOptions[0]);
      setSelectValue(playersOptions[0]);
    }
  }, [props.class.currentClass]);

  const handleLoadPlayer = (player: any) => {
    setSelectValue(player);
    dispatch(getPlayerReport(player.value));
    dispatch(trackSelectPlayerReadingLevel());
  };

  const renderChart = () => {
    if (!selectValue || !props.player) {
      return (
        <Typography align="center" component="p">
          No data to show
        </Typography>
      );
    }
    let chartData = props.player.readingLevel;
    if (!props.player.readingLevel.length) {
      chartData = [
        { date: 'Start', newReadingLevel: props.player.currentReadingLevel }
      ];
    }
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart margin={{ left: 20 }} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <Legend />
          <YAxis type="number" domain={[0, 8]} dataKey="newReadingLevel" />
          <XAxis type="category" dataKey="date" />
          <Tooltip />
          <Bar
            maxBarSize={150}
            dataKey="newReadingLevel"
            fill="#8884d8"
            name="Reading Level"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  let classData: any[] =
    props.class.currentClass.classReport.classReadingLevel || [];

  const length = classData.length;
  const barChartHeight = 250 + Math.abs(length / 5) * 100;
  return (
    <View
      title="Reading Level Report"
      renderBackButton={() => (
        <Button onClick={() => history.goBack()} style={{ marginRight: 10 }}>
          <ChevronLeft /> Back
        </Button>
      )}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ChartContainer
            title={`${playerNounUppercase}s' Current Reading Level`}
            helpText={
              <>
                <span style={{ marginBottom: 20 }}>
                  The {playerNounLowerCase} reading levels shown are grade level
                  equivalents. The passages in Dreamscape are levelled using a
                  balance of best-practice systems including Flesch-Kincaid and
                  others. A {playerNounLowerCase}’s reading level changes based
                  on the percentage of correct answers tied to a text of a
                  certain difficulty. Reading levels can range from 1.0 to 8.9.
                </span>
                <Table>
                  <TableRow>
                    <TableCell>
                      Percentage answers correct per passage:
                    </TableCell>
                    <TableCell>Reading level change</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>0-50%</TableCell>
                    <TableCell>Decrease by 0.1</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>50-75%</TableCell>
                    <TableCell>No change</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>75-100%</TableCell>
                    <TableCell>Increase by 0.1</TableCell>
                  </TableRow>
                </Table>
              </>
            }
          >
            <ResponsiveContainer height={barChartHeight}>
              <BarChart
                layout="vertical"
                width={730}
                margin={{ left: 20 }}
                data={classData}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 8]} dataKey="RCL" />
                <YAxis type="category" dataKey="name" />
                <Tooltip />
                <Bar layout="vertical" dataKey="RCL" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>

        <Grid item xs={12}>
          <ChartContainer
            title={`Individual ${playerNounUppercase}' Overall Reading Level`}
            helpText={`This chart shows the change in a ${playerNounLowerCase}’s reading level over time. The reading levels shown are grade level equivalents, and are calculated through the percentage of correct answers tied to a text of a certain difficulty. If a ${playerNounLowerCase} is set to a reading level that does not match their ability when first starting the game, the algorithm will adjust as they play. Therefore their reading level will be more accurate after a month of playing. If it starts decreasing after the first month, it might mean the ${playerNounLowerCase} has started guessing the answers without reading the passages.`}
          >
            <Select
              name="selectedStudent"
              placeholder="Select a player to view individual data"
              options={playersOptions}
              className={classes.select}
              onChange={handleLoadPlayer}
              value={selectValue}
            />
            {renderChart()}
          </ChartContainer>
        </Grid>
      </Grid>
    </View>
  );
};

const mapStateToProps = (state: any) => ({
  class: state.class,
  player: state.player
});

const mapActionsToProps = {
  show
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ReadingLevelReportView);
