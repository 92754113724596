import React, { useState, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid
} from '@material-ui/core';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import colors from '../../../styles/colors';

interface ModalSelectMonthYearProps {
  show: any;
  handleHide: any;
  description?: string;
  onConfirm: (selectedDate: Date) => void;
}

const ModalSelectMonthYear: React.FC<ModalSelectMonthYearProps> = (
  props: ModalSelectMonthYearProps
) => {
  const { show, handleHide, onConfirm, description } = props;
  const [state, setState] = useState({
    error: '',
    selectedDate: moment().toDate()
  });

  const handleConfirm = () => {
    onConfirm(state.selectedDate);
    handleHide();
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Select Month and Year</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={4}>
              {description && (
                <Grid item xs={12} spacing={4}>
                  <Typography component="p" style={{ marginTop: 10 }}>
                    {description}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} spacing={4}>
                <DatePicker
                  views={['year', 'month']}
                  margin="normal"
                  fullWidth
                  label="Month and Year"
                  format="MMM yyyy"
                  value={state.selectedDate}
                  minDate={new Date('2018-10-01')}
                  maxDate={new Date()}
                  allowKeyboardControl={false}
                  onChange={(selectedDate: any) =>
                    setState({ ...state, selectedDate })
                  }
                />
              </Grid>
              <Typography
                component="span"
                style={{ color: colors.error, fontSize: 13, padding: '0 16px' }}
              >
                {state.error}
              </Typography>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button primary disabled={state.error !== ''} onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalSelectMonthYear;
