import { Box, Grid, Hidden, IconButton, makeStyles } from '@material-ui/core';
import Sun from '../../../assets/summer-notification/sun.png';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React, { FC } from 'react';
import useShowSummerNotification from '../hooks/useShowSummerNotification';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  summerNotificationWrapper: {
    marginTop: '12px',
    marginBottom: '10px'
  },
  summerNotification: {
    background: '#CDF3FF',
    padding: '0 20px',
    [theme.breakpoints.up('sm')]: {
      padding: isMobileAndSmallHeight =>
        isMobileAndSmallHeight ? '0 80px 0 20px' : '0 20px'
    }
  },
  notificationImageWrapper: {
    padding: '0 12px !important'
  },
  notificationImage: {
    height: '100%',
    maxHeight: '75px',
    maxWidth: '100%',
    transform: 'rotateY(180deg)'
  },
  notificationText: {
    paddingRight: '10% !important',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '20% !important'
    }
  },
  notificationCloseWrapper: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
      flexGrow: 1,
      textAlign: 'right'
    }
  }
}));

export const SummerNotification: FC = () => {
  const classes = useStyles(window.screen.height < 1100 || isMobile);
  const {
    showBanner,
    hideSummerNotificationBanner
  } = useShowSummerNotification();

  return (
    <>
      {showBanner && (
        <Box className={classes.summerNotificationWrapper}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.summerNotification}
          >
            <Hidden smDown>
              <Grid item className={classes.notificationImageWrapper}>
                <img
                  src={Sun}
                  alt="Summer Notification"
                  className={classes.notificationImage}
                />
              </Grid>
            </Hidden>

            <Grid item md xs={12} className={classes.notificationText}>
              Get your class ready for the summer! Send their account info home
              so they can keep practicing their literacy skills. It’s easy,
              click the “Print logins” button to get started.
            </Grid>

            <Grid item className={classes.notificationCloseWrapper}>
              <IconButton onClick={hideSummerNotificationBanner}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
