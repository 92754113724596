import React from 'react';
import { useDispatch } from 'react-redux';
import { authorizeECCP } from '../../redux/actions/planner-actions';

const CreateContentRedirect: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(authorizeECCP());
  setTimeout(() => window.history.back(), 100);
  return null;
};

export default CreateContentRedirect;
