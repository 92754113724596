import React, { useState, useEffect } from 'react';
import {
  BarChart as Chart,
  Bar,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  AxisDomain,
  Line,
  ComposedChart
} from 'recharts';
import { Color } from 'csstype';
import { IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import colors from '../../styles/colors';

type ChartProps = {
  data: Array<any>;
  yDataKey: string;
  line?: {
    dataKey: string;
    fill: Color;
  };
  yAxisRange?: [AxisDomain, AxisDomain];
  dataLabel: string;
  xDataKey: string;
  xAxisAngle?: number;
  xAxisHeight?: number;
  fill?: Color;
  barSize?: number;
  strokeDasharray?: string;
  formatter?: (value: string) => void;
  tickFormatter?: any;
  width?: number;
  height?: number;
  heightSurface?: number;
  itemsPerPage?: number;
  renderBar?: () => void;
  margin?: {
    top?: number;
    bottom?: number;
    right?: number;
    left?: number;
  };
};

const useStyles = makeStyles(() => ({
  pageNumber: {
    padding: 10
  },
  pagination: {
    textAlign: 'right'
  },
  notFoundText: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  }
}));

const BarChart: React.FC<ChartProps> = (props: ChartProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState(props.data);
  const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage || 0);
  const [pages, setPages] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    // if (pages === 0) {
    if (props.data && props.data.length) {
      let numOfPages: number = 1;
      let itemsPerPage: number = 0;
      let paginate = false;
      if (window.screen.availWidth <= 758) {
        paginate = true;
        itemsPerPage = 3;
      } else if (props.itemsPerPage) {
        paginate = true;
        itemsPerPage = props.itemsPerPage;
      }
      if (paginate) {
        setItemsPerPage(itemsPerPage);
        setCurrentItems(props.data.slice(0, itemsPerPage));
        numOfPages = Math.ceil(props.data.length / itemsPerPage);
      }
      setPages(numOfPages);
    }
  }, [props.data]);

  const handleChangePage = (left?: boolean) => {
    let nextPage = currentPage + 1;
    let changePage = nextPage <= pages;
    if (left) {
      nextPage = currentPage - 1;
      changePage = nextPage <= pages && nextPage > 0;
    }
    if (changePage) {
      const startIndex = itemsPerPage * (nextPage - 1);
      setCurrentItems(props.data.slice(startIndex, itemsPerPage * nextPage));
      setCurrentPage(nextPage);
    }
  };

  const renderPagination = () => {
    if (pages > 1) {
      return (
        <div className={classes.pagination}>
          <IconButton onClick={() => handleChangePage(true)}>
            <ChevronLeft />
          </IconButton>
          <Typography component="span" className={classes.pageNumber}>
            {currentPage}
          </Typography>
          <IconButton onClick={() => handleChangePage()}>
            <ChevronRight />
          </IconButton>
        </div>
      );
    }
  };

  const renderBar = () => {
    if (props.renderBar) {
      return props.renderBar();
    }
    return (
      <Bar
        fill={props.fill}
        dataKey={props.yDataKey}
        name={props.dataLabel || props.yDataKey}
      />
    );
  };

  if (!props.data || !props.data.length) {
    return (
      <div style={{ height: '100%' }}>
        <Typography component="p" className={classes.notFoundText}>
          No data to show
        </Typography>
      </div>
    );
  }

  return (
    <>
      {renderPagination()}
      <ComposedChart
        data={currentItems}
        maxBarSize={props.barSize}
        margin={props.margin}
        width={props.width}
        height={props.height}
      >
        <CartesianGrid strokeDasharray={props.strokeDasharray} />
        <XAxis
          textAnchor="end"
          interval="preserveStartEnd"
          dataKey={props.xDataKey}
          angle={props.xAxisAngle || 0}
          height={props.xAxisHeight}
        />
        <YAxis
          width={40}
          domain={props.yAxisRange}
          tickFormatter={value => props.tickFormatter(value) || value}
        />
        <Tooltip />
        {renderBar()}
        {props.line && (
          <Line
            type="monotone"
            strokeWidth={2}
            dataKey={props.line.dataKey}
            stroke={props.line.fill}
          />
        )}
      </ComposedChart>
    </>
  );
};

export default BarChart;

BarChart.defaultProps = {
  fill: colors.primary,
  xAxisHeight: 30,
  width: 500,
  height: 300,
  heightSurface: 1420,
  tickFormatter: (value: string) => value,
  strokeDasharray: '3 3',
  margin: { top: 5, right: 0, left: 0, bottom: 5 }
};
