import Axios from 'axios';
import store from '../store';
/**
 * This method allows users to call and return a promise for an api request.
 * It's use case is to have an alternative to touching the redux store.
 * User can use this function to directly call an api and manually
 * handle the error and success of the api call
 * @param url of endpoint
 * @param method type for request
 * @param data any data to pass
 */

export function generateApi({
  url,
  method,
  data,
  isEducation = false
}: {
  url: string;
  method: string;
  data?: any;
  isEducation?: boolean;
}) {
  // Get token
  // const token = store.getState().userData.token;
  // Axios.defaults.headers.common = {
  //   Authorization: `${token}`
  // };
  if (isEducation) {
    //  Helps
    // Axios.defaults.headers.common = {
    //   Authorization: process.env.REACT_APP_EDUCATION_API_KEY
    // };
    // @ts-ignore
    return Axios[method](
      `${process.env.REACT_APP_EDUCATION_API_URL}${url}`,
      data
    );
  }
  // @ts-ignore
  return Axios[method](
    `${process.env.REACT_APP_DASHBOARD_API_URL}${url}`,
    data
  );
}
