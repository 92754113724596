import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Button from '../../../components/button/Button';

import laptop1 from '../../../assets/onboarding/laptop-1.gif';
import laptop2 from '../../../assets/onboarding/laptop-2.gif';
import laptop3 from '../../../assets/onboarding/laptop-3.gif';
import laptop4 from '../../../assets/onboarding/laptop-4.gif';
import laptop5 from '../../../assets/onboarding/laptop-5.gif';

const images = [laptop1, laptop2, laptop3, laptop4, laptop5];

const useStyles = makeStyles(() => ({
  imagesWrapper: {
    margin: '20px auto',
    overflow: 'hidden'
  },
  image: {
    cursor: 'pointer',
    maxWidth: '100%'
  },
  skipButton: {
    position: 'absolute',
    right: 20
  }
}));

interface HowToPlayProps {
  handleCompleteTutorial: () => void;
  handleSkipTutorial: () => void;
  completeTutorialLabel: string;
}

const HowToPlay: React.FC<HowToPlayProps> = (props: HowToPlayProps) => {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(0);

  const handleNextClick = () => {
    if (currentImage !== images.length - 1) {
      return setCurrentImage(currentImage + 1);
    }
    props.handleCompleteTutorial();
  };

  const handleBackClick = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  return (
    <>
      <Grid
        container
        className={clsx(classes.imagesWrapper, 'animated', 'fadeIn')}
      >
        <img
          onClick={handleNextClick}
          src={images[currentImage]}
          className={clsx(classes.image, 'animated', 'zoomIn')}
        />
      </Grid>
      <Button
        fontSize="1.1rem"
        marginRight={10}
        disabled={currentImage === 0}
        onClick={handleBackClick}
      >
        Back
      </Button>
      <Button primary fontSize="1.1rem" onClick={handleNextClick}>
        {currentImage === images.length - 1
          ? props.completeTutorialLabel
          : 'Next'}
      </Button>
      {currentImage < images.length - 1 && (
        <Button
          fontSize="1.1rem"
          className={classes.skipButton}
          onClick={props.handleSkipTutorial}
        >
          Skip
        </Button>
      )}
    </>
  );
};

export default HowToPlay;
