import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Button,
  Tabs,
  Tab,
  Paper,
  IconButton,
  Typography
} from '@material-ui/core';
import View from '../../../components/view/View';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, HelpOutline } from '@material-ui/icons';
import colors from '../../../styles/colors';
import ClassReport from './SkillsClassReport';
import PlayerReport from './SkillsPlayerReport';
import { show, hide } from 'redux-modal';
import {
  MODAL_DEFAULT,
  MODAL_PLAYER_BREAKDOWN_UPGRADE,
  MODAL_SKILLS_PLAYER_BREAKDOWN_UPGRADE
} from '../../../constants/modals';
import { useHistory } from 'react-router-dom';
import {
  playerNounLowerCase,
  playerNounUppercase
} from '../../../utils/userNoun';
import StarsIcon from '@material-ui/icons/Stars';
import { hide as hideModal, show as showModal } from 'redux-modal';
import store, { RootState } from '../../../redux/store';
import { SkillsReportBanner } from '../../../components/banner/SkillsReportBanner';

const useStyles = makeStyles(() => ({
  rowError: {
    background: colors.error,
    color: colors.white
  },
  rowSuccess: {
    background: colors.successDark,
    color: colors.white
  },
  helpButton: {
    position: 'absolute',
    right: 0,
    zIndex: 9
  },
  contentWrapper: {
    position: 'relative'
  },
  center: { textAlign: 'center' },
  accuracy: {
    margin: '-16px 30px',
    padding: 30,
    textAlign: 'center'
  },
  buttonUpgrade: {
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}));

enum TabValues {
  class,
  student
}

const SkillsView: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentClass } = useSelector((state: any) => state.class);
  const userData = useSelector((state: any) => state.userData);
  const history = useHistory();

  const [tabValue, setTabValue] = useState(TabValues.class);
  const [selectedUser, setSelectedUser] = useState({
    label: '',
    value: null
  });
  const { featureFlag } = store.getState() as RootState;

  const handleUpgradePopup = () => {
    const sendProps: any = {
      type: MODAL_PLAYER_BREAKDOWN_UPGRADE
    };
    dispatch(showModal('modal', sendProps));
  };
  const handlePlayerNameClick = (row: any) => {
    dispatch(hide('modal'));
    setSelectedUser({
      label: row.playerName,
      value: row.userId
    });
    setTabValue(TabValues.student);
  };
  const tabContent = () => {
    if (userData.userType === 'Parent') {
      return (
        <Tabs value="Player" textColor="primary" indicatorColor="primary">
          <Tab label={playerNounUppercase} />
        </Tabs>
      );
    } else {
      return (
        <Tabs
          value={tabValue}
          textColor="primary"
          indicatorColor="primary"
          onChange={(_: any, newValue: TabValues) => setTabValue(newValue)}
        >
          <Tab label="Class" />
          {!featureFlag.premiumReporting ? (
            <Tab label={playerNounUppercase} />
          ) : (
            ''
          )}
          {featureFlag.premiumReporting && !userData.premium.isPremium ? (
            <>
              {' '}
              <StarsIcon
                fontSize={'large'}
                style={{
                  fill: '#f8bb00',
                  position: 'relative',
                  left: '70px',
                  top: '15px',
                  width: '30px'
                }}
              />
              <Button
                // className={classes.buttonUpgrade}
                onClick={() => {
                  handleUpgradePopup();
                }}
              >
                <Tab label={playerNounUppercase} />
              </Button>
            </>
          ) : (
            ''
          )}
          {featureFlag.premiumReporting && userData.premium.isPremium ? (
            <Tab label={playerNounUppercase} />
          ) : (
            ''
          )}
        </Tabs>
      );
    }
  };
  const renderContent = () => {
    if (userData.userType === 'Parent') {
      return <PlayerReport selectedUser={selectedUser} />;
    } else {
      if (tabValue === TabValues.class) {
        return <ClassReport handlePlayerNameClick={handlePlayerNameClick} />;
      } else if (featureFlag.premiumReporting && userData.premium.isPremium) {
        return <PlayerReport selectedUser={selectedUser} />;
      } else if (featureFlag.premiumReporting && !userData.premium.isPremium) {
        const sendProps: any = {
          type: MODAL_SKILLS_PLAYER_BREAKDOWN_UPGRADE
        };
        dispatch(showModal('modal', sendProps));
      } else if (!featureFlag.premiumReporting) {
        return <PlayerReport selectedUser={selectedUser} />;
      }
    }
  };

  let participationTitle = '# of Players who Practiced Skill';
  let detailsTitle = 'Player Details';
  if (userData.userType === 'Parent') {
    participationTitle = `${playerNounUppercase} Participation`;
    detailsTitle = `${playerNounUppercase} Details`;
  }

  const Information = () => {
    return (
      <>
        <Typography>
          <b>Class / {playerNounUppercase}: </b>
          {`Select to alternate viewing skill data for your class and individual ${playerNounLowerCase} data.`}
        </Typography>
        <Typography>
          <b>Questions From: </b>
          {`Filter the skills by grade to know how your class is performing on
          skills at a certain grade level or higher.`}
        </Typography>
        <Typography>
          <b>Time Period: </b>
          {`Select a range of time to see the skill data from that period.`}
        </Typography>
        <Typography>
          <b>Questions Correct: </b>
          {`View the number of questions answered correctly in that skill.`}
        </Typography>
        <Typography>
          <b>{participationTitle}: </b>
          {`View the number of ${playerNounLowerCase}s who’ve answered one of more questions in that skill / the total number of ${playerNounLowerCase}s in a class.`}
        </Typography>
        <Typography>
          <b>Accuracy: </b>
          {` View the percentage of questions answered correctly in that skill.`}
        </Typography>
        <Typography>
          <b>{detailsTitle}: </b>
          {`View the ${playerNounLowerCase}s that contributed to the data shown for the skill.`}
        </Typography>
        <Typography>
          <b>Extra Practice: </b>
          {` Assign extra practice to one or more ${playerNounLowerCase}s using the assignment tool. Not all of our skills are featured in the assignment tool at this moment.`}
        </Typography>
      </>
    );
  };

  return (
    <>
      <SkillsReportBanner></SkillsReportBanner>
      <View
        title="Skills Report"
        renderBackButton={() => (
          <Button onClick={() => history.goBack()} style={{ marginRight: 10 }}>
            <ChevronLeft /> Back
          </Button>
        )}
      >
        <Grid container>
          <Paper className={classes.contentWrapper}>
            <IconButton
              className={classes.helpButton}
              onClick={() =>
                dispatch(
                  show('modal', { type: MODAL_DEFAULT, children: Information })
                )
              }
            >
              <HelpOutline />
            </IconButton>
            <Grid xs={12}>
              {tabContent()}
              <Grid container style={{ padding: 20 }}>
                {renderContent()}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </View>
    </>
  );
};

export default SkillsView;
