import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { AssignmentData } from './ModalCreateAssignment';

interface ModalAssignmentDescriptionProps {
  selectedData: AssignmentData | null;
}

const AssignmentDetailItem = (label: string, value: string | number) => (
  <Typography style={{ marginBottom: 15 }}>
    <Typography
      variant="inherit"
      component="span"
      style={{ fontWeight: 'bold' }}
    >
      {label}:
    </Typography>{' '}
    {value}
  </Typography>
);

const AssignmentDescription: React.FC<ModalAssignmentDescriptionProps> = (
  props: ModalAssignmentDescriptionProps
) => {
  const { selectedData } = props;

  return (
    <>
      {selectedData ? (
        <>
          {AssignmentDetailItem('Title', selectedData.title)}
          {AssignmentDetailItem('Description', selectedData.description)}
          {AssignmentDetailItem(
            'Most Appropriate For',
            selectedData.mostAppropriateFor
          )}
          {AssignmentDetailItem(
            'Estimated Completion Time',
            selectedData.estimatedCompletionTime
          )}
          {AssignmentDetailItem('Skills Covered', selectedData.standards)}
        </>
      ) : (
        <Typography>No data available.</Typography>
      )}
    </>
  );
};

export default AssignmentDescription;
