import React from 'react';
import mixpanel from '../../utils/mixpanel';
import { getLocalStorage } from '../../utils/localStorage';

enum CleverAssets {
  'signup' = 'https://files.readme.io/83379fd-0422c0f-button-signup-dark2x.png',
  'login' = 'https://files.readme.io/efb3f5d-LogInWithClever2x.png'
}

interface CleverButtonProps {
  type: 'signup' | 'login';
  style?: any;
}

const handleCleverClick = () => {
  mixpanel.track('Onboarding - Clever Sign-up Clicked', {
    registrationFlow: getLocalStorage('newRegistrationFlow')
  });
};

const CleverButton: React.FC<CleverButtonProps> = (
  props: CleverButtonProps
) => {
  return (
    <a
      style={{ ...props.style }}
      href={`https://clever.com/oauth/authorize?response_type=code&redirect_uri=${process.env.REACT_APP_CLEVER_REDIRECT_URL}&client_id=${process.env.REACT_APP_CLEVER_CLIENT_ID}`}
      onClick={handleCleverClick}
    >
      <img alt="Clever" src={CleverAssets[props.type]} style={{ width: 193 }} />
    </a>
  );
};

export default CleverButton;
