import React, { useEffect, useState } from 'react';
import { Box, Hidden } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core';

const LazyLayoutLoaderScreen = () => {
  const theme = useTheme();
  const [showLoading, setShowLoading] = useState(false);
  const waitBeforeShow = 50;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return (
    <>
      {showLoading && (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            style={{
              height: '65px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: theme.shadows[1],
              padding: '20px'
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ width: '300px', height: '40px' }}
            />
            <Hidden xsDown>
              <Skeleton
                animation="wave"
                variant="rect"
                style={{ width: '250px', height: '40px' }}
              />
            </Hidden>
          </Box>
          <Box
            style={{
              width: '260px',
              height: 'calc(100vh - 60px)',
              padding: '20px',
              borderRight: `1px solid ${theme.palette.grey[300]}`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ width: '200px', marginBottom: '20px', height: '35px' }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ width: '200px', marginBottom: '20px', height: '35px' }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ width: '200px', marginBottom: '20px', height: '35px' }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default LazyLayoutLoaderScreen;
