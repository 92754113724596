import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  Tooltip
} from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { hide, show } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  createAssignment,
  getAssignmentContent
} from '../../../redux/actions/planner-actions';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import PackSelection from './common/PackSelection';
import StudentSelection from './common/StudentSelection';
import { commonRoutes, teacherRoutes } from '../../../constants/routes';
import { userTypes } from '../../../constants/users';
import { push } from 'react-router-redux';
import {
  assignmentTypes,
  assignmentSteps
} from '../../../types/assignments/assignment-types';
import { MODAL_ASSIGNMENT_OPTIONS } from '../../../constants/modals';
import { reassignPlacementTestUpgradeButtonClicked } from '../../../redux/actions/mixpanel-actions';
import { userNavigateWithClassCode } from '../../../utils/user-route-getter';
import { sortBy } from 'lodash';
import SkillOrGenreSelection, {
  MixPanelProperties
} from './common/SkillOrGenreSelection';
import mixpanel from '../../../utils/mixpanel';
import colors from '../../../styles/colors';
import AssignmentDescription from './ModalAssignmentDescription';

const useStyles = makeStyles((theme: Theme) => ({
  rowGrid: {
    marginBottom: 20
  },
  footerInfo: {
    bottom: 18,
    fontSize: 13,
    left: 25,
    position: 'absolute'
  },
  footerInfoMobile: {
    bottom: 0,
    display: 'block',
    left: 0,
    padding: '10px 0 10px 20px',
    position: 'relative',
    textAlign: 'left',
    width: '100%'
  },
  selectAllButton: {
    margin: '10px 0'
  },
  input: {
    margin: '10px 0'
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

export interface AssignmentData {
  adminNotes: string;
  author: string;
  briefDescription: string;
  code: string;
  contentType: string;
  createdAt: Date;
  createdBy: string;
  description: string;
  estimatedCompletionTime: string;
  id: number;
  isDeleted: boolean;
  isEnabled: boolean;
  maxGrade: number;
  minGrade: number;
  mostAppropriateFor: string;
  numQuestions: number;
  ownerId: null;
  selected: boolean;
  skillsDescription: string;
  standards: string;
  title: string;
  type: string;
  updatedAt: Date;
  updatedBy: string;
}

const ModalCreateAssignment: React.FC<any> = (props: any) => {
  const { show, handleHide } = props;
  const planner = useSelector((state: any) => state.planner);
  const userData = useSelector((state: any) => state.userData);
  const { premium } = userData;
  const isPremiumUser = premium && premium.isPremium;
  const { currentClass } = useSelector((state: any) => state.class);
  const featureFlag = useSelector((state: any) => state.featureFlag);
  const classCode = currentClass.code;
  const [state, setState] = useState({
    loading: false,
    newAssignment: {
      type:
        props.assignmentType ||
        (featureFlag.newLeAssignments
          ? assignmentTypes.packs
          : assignmentTypes.genres),
      title: 'My new assignment',
      grade: 3
    },
    grades: [1, 2, 3, 4, 5, 6, 7, 8],
    steps: assignmentSteps,
    typeOptions: [
      { id: 0, name: '', max: 0, selected: false, quantity: 0, title: '' }
    ],
    students: {
      data: currentClass.roster.map((student: any) => {
        student.selected = false;
        return student;
      }),
      allSelected: false,
      studentsLoaded: false
    },
    // DateRangerPicker state
    focusedInput: null,
    startDate: moment().toDate(),
    endDate: moment()
      .add(7, 'days')
      .toDate()
  });
  const [selectedPackId, setSelectedPackId] = useState(0);
  const [
    selectedAssignmentData,
    setSelectedAssignmentData
  ] = useState<AssignmentData | null>(null);
  const [currentStep, setCurrentStep] = useState('SELECT TYPE');
  const [error, setError] = useState({
    loading: '',
    assignmentData: '',
    contentSelection: '',
    studentSelection: ''
  });
  const disableNextButton =
    state.newAssignment.type === assignmentTypes.premium && !isPremiumUser;
  const applyError = (errorObject: any, errorMessage: String) => {
    setError(errorObject);
    dispatch(
      show('snackbar', {
        variant: 'error',
        message: errorMessage,
        vertical: 'top',
        horizontal: 'right',
        autoHideDuration: 2000
      })
    );
  };
  const dispatch = useDispatch();
  const footerStyle: React.CSSProperties = isMobile
    ? { width: '100%', textAlign: 'right' }
    : { display: 'flex', flexDirection: 'row' };

  useEffect(() => {
    const selectedStudents = state.students.data.filter(
      (item: any) => item.selected === true
    );
    const selectedOptions = state.typeOptions.filter(
      item => item.selected === true
    );
    const emptyValues = selectedOptions.filter(item => {
      // return isNaN(item.quantity) || item.quantity === '' || item.quantity <= 0;
      return isNaN(item.quantity) || item.quantity <= 0;
    });
    const errorState: any = {};
    if (selectedStudents.length) {
      errorState.studentSelection = '';
    }
    if (selectedOptions.length && !emptyValues.length) {
      errorState.contentSelection = '';
    }
    if (
      state.newAssignment.title.trim() !== '' &&
      moment(state.startDate).isValid() &&
      moment(state.endDate).isValid()
    ) {
      errorState.assignmentData = '';
    }
    if (
      error.contentSelection !== errorState.contentSelection ||
      error.studentSelection !== errorState.studentSelection ||
      error.assignmentData !== errorState.assignmentData
    ) {
      setError({
        ...error,
        ...errorState
      });
    }
  }, [state]);

  useEffect(() => {
    let grades = [1, 2, 3, 4, 5, 6, 7, 8];
    let newAssignment = state.newAssignment;
    if (state.newAssignment.type === assignmentTypes.packs) {
      grades = [2, 3, 4, 5, 6];
      if (!grades.includes(newAssignment.grade)) {
        newAssignment.grade = grades[0];
      }
    }
    setState({
      ...state,
      grades,
      newAssignment
    });
  }, [state.newAssignment.type]);

  const handleCheckStudent = (_id: string, event: any) => {
    const data = state.students.data.map((student: any) => {
      if (student._id === _id) {
        student = {
          ...student,
          selected: event.target.checked
        };
      }
      return student;
    });
    const newState = {
      students: {
        ...state.students,
        data
      }
    };
    const unselected = state.students.data.filter(
      (student: any) => student.selected === false
    );
    if (event.target.checked === false) {
      newState.students.allSelected = false;
    } else if (!unselected.length) {
      newState.students.allSelected = true;
    }
    setState({
      ...state,
      ...newState
    });
  };

  const handleChangeNumOfQuestions = (event: any) => {
    let { name, value } = event.target;
    const typeOptions = state.typeOptions.map((item: any) => {
      if (item.name === name) {
        value = value < 0 ? 0 : value;
        if (value > item.max) {
          value = item.quantity;
        }
        item.quantity = value;
        item.selected = true;
      }
      return item;
    });
    setState({
      ...state,
      typeOptions
    });
  };

  const handleSetQuestions = (contentType: string) => {
    let contentSelectionError;
    const selectedOptions = state.typeOptions.filter(
      item => item.selected === true
    );
    const emptyValues = selectedOptions.filter(item => {
      return isNaN(item.quantity) || item.quantity <= 0;
    });
    if (emptyValues.length) {
      contentSelectionError = `Please, type a valid number of ${contentType} for the options you checked.`;
    }
    if (!selectedOptions.length) {
      contentSelectionError = 'Please, select at least one option to assign';
    }
    if (contentSelectionError) {
      applyError(
        {
          ...error,
          contentSelection: contentSelectionError
        },
        contentSelectionError
      );
      return false;
    }
  };

  const handleSetStudents = () => {
    const selectedStudents = state.students.data.filter(
      (item: any) => item.selected === true
    );
    if (!selectedStudents.length) {
      const errorMessage = 'Please, select at least one player';
      applyError(
        {
          ...error,
          studentSelection: errorMessage
        },
        errorMessage
      );
      return false;
    }
  };

  const handleSetDate = async () => {
    let assignmentDataError;
    const { title, type, grade } = state.newAssignment;
    const { startDate, students, endDate } = state;
    if (!title.trim()) {
      assignmentDataError = 'Please, give a name to your assignment';
    }
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      assignmentDataError = 'Please, select a valid date range';
    }
    if (moment(endDate) < moment(startDate)) {
      assignmentDataError = 'End Date must be greater or equal Start Date';
    }
    if (assignmentDataError) {
      applyError(
        {
          ...error,
          assignmentData: assignmentDataError
        },
        assignmentDataError
      );
      return false;
    }
    const selectedUsers = students.data.filter((item: any) => item.selected);
    const userIds = selectedUsers.map((item: any) => item.educationUserId);
    const options = state.typeOptions
      .filter((item: any) => item.selected && item.quantity)
      .map(item => ({
        ...item,
        grade
      }));
    let content: any = {
      type: type.toUpperCase(),
      grade,
      options
    };

    let createAssignmentOptions: any = {};
    if (userData.userType !== userTypes.parent && !planner.length) {
      createAssignmentOptions = { redirect: true };
    }
    if (
      state.newAssignment.type === assignmentTypes.packs ||
      state.newAssignment.type === assignmentTypes.premium ||
      (featureFlag.newLeAssignments &&
        (state.newAssignment.type === assignmentTypes.skills ||
          state.newAssignment.type === assignmentTypes.decodables ||
          state.newAssignment.type === assignmentTypes.genres))
    ) {
      content.typeId = selectedPackId;
      createAssignmentOptions.selectedOption = state.typeOptions.filter(
        o => o.id === selectedPackId
      )[0];
    }
    const assignmentData = [
      {
        title,
        classCode: props.class.currentClass.code,
        startDate,
        endDate,
        userIds,
        content,
        folderId: props.folderId || 0
      }
    ];

    dispatch(createAssignment(assignmentData, createAssignmentOptions));
  };

  const setApiLoadingError = (error: any) => {
    const errorMessage = `There was an error processing your request, please contact support if the
			problem persists.`;
    applyError(
      {
        ...error,
        loading: errorMessage
      },
      errorMessage
    );
  };

  const handleSelectAllClick = () => {
    const { allSelected } = state.students;
    const data = state.students.data.map((student: any) => ({
      ...student,
      selected: !allSelected
    }));
    setState({
      ...state,
      students: {
        ...state.students,
        data,
        allSelected: !allSelected
      }
    });
  };

  const handleCheckOption = (event: any) => {
    let { name, checked } = event.target;
    const typeOptions = state.typeOptions.map((item: any) => {
      if (item.name === name) {
        item.selected = checked;
      }
      return item;
    });
    setState({
      ...state,
      typeOptions
    });
  };

  const handleTextCapitalization = (text: string) => {
    return `${text.charAt(0)}${text.substring(1, text.length).toLowerCase()}`;
  };

  const handleLoadContent = async () => {
    try {
      setState({ ...state, loading: true });
      const { type, grade } = state.newAssignment;
      const availableContent = await dispatch(
        getAssignmentContent({
          classCode: currentClass.code,
          type: type.toUpperCase(),
          grade,
          region:
            type === assignmentTypes.packs ? userData.location.region : null
        })
      );
      let typeOptions = [];
      if (availableContent.data.length) {
        typeOptions = availableContent.data.map((item: any) => {
          return {
            ...item,
            selected: false
          };
        });
        if (state.newAssignment.type === assignmentTypes.packs) {
          setSelectedPackId(typeOptions[0].id);
        }
        if (state.newAssignment.type === assignmentTypes.premium) {
          typeOptions = sortBy(typeOptions, ['contentType']);
          if (isPremiumUser) {
            setSelectedPackId(typeOptions[0].id);
          } else {
            const availableAssignment = availableContent.data.find(
              (item: any) => {
                return item.contentType !== 'Premium';
              }
            );
            setSelectedPackId(availableAssignment.id);
          }
        }
      }
      setState({
        ...state,
        typeOptions,
        loading: false
      });
    } catch (error) {
      console.log(error);
      setApiLoadingError(error);
    }
  };

  const handleSelecType = (event: any) => {
    setState({
      ...state,
      newAssignment: {
        ...state.newAssignment,
        type: event.target.value
      }
    });
  };

  const handleSelectGrade = (event: any) => {
    setState({
      ...state,
      newAssignment: {
        ...state.newAssignment,
        grade: event.target.value
      }
    });
  };

  const redirect = () => {
    dispatch(hide('modal'));
    dispatch(reassignPlacementTestUpgradeButtonClicked(classCode));
    dispatch(
      push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
    );
  };

  const handleOpenAssignmentOptionsModal = () => {
    dispatch(props.show('modal', { type: MODAL_ASSIGNMENT_OPTIONS }));
  };

  const renderNextButton = (action: any, nextStep: string, text?: string) => {
    text = text || 'Next';
    if (state.loading) {
      return null;
    }
    // if (disableNextButton && currentStep === 'SELECT PACK') {
    //   return (
    //     <Tooltip title={'This assignment type requires a premium subscription'}>
    //       <div>
    //         <Button
    //           primary
    //           disabled
    //           onClick={async () => {
    //             const response = await action();
    //             if (response !== false) {
    //               if (nextStep === steps.done) {
    //                 return dispatch(hide('modal'));
    //               }
    //               setCurrentStep(nextStep);
    //             }
    //           }}
    //         >
    //           {text}
    //         </Button>
    //       </div>
    //     </Tooltip>
    //   );
    // } else {
    return (
      <Button
        primary
        onClick={async () => {
          const response = await action();
          if (response !== false) {
            if (nextStep === steps.done) {
              return dispatch(hide('modal'));
            }
            setCurrentStep(nextStep);
          }
        }}
      >
        {text}
      </Button>
    );
    // }
  };

  const renderBackButton = (previousStep: string) => {
    if (state.loading) {
      return null;
    }
    return (
      <Button
        onClick={() => setCurrentStep(previousStep)}
        style={{ marginRight: '5px' }}
        className="button--small"
      >
        Back
      </Button>
    );
  };

  const renderQuestionSelection = (contentType: string) => {
    return state.typeOptions.map((item: any) => {
      const containerStyle: React.CSSProperties = isMobile
        ? { marginTop: 10, borderBottom: '1px solid #dcdcdc' }
        : {};
      const checkBoxStyle: React.CSSProperties = isMobile
        ? { paddingBottom: 0 }
        : {};
      return (
        <>
          <Grid container spacing={4} style={containerStyle}>
            <Grid
              container
              item
              md={8}
              xs={12}
              alignItems="center"
              style={checkBoxStyle}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.selected}
                    name={item.name}
                    onChange={(event: any) => handleCheckOption(event)}
                    value="checkedB"
                    color="primary"
                  />
                }
                label={`${item.name} (${item.max} ${contentType} available)`}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                name={item.name}
                variant="outlined"
                value={item.quantity}
                label={`Number of ${contentType}`}
                InputLabelProps={{
                  style: { fontSize: 13 }
                }}
                onChange={handleChangeNumOfQuestions}
              />
            </Grid>
          </Grid>
        </>
      );
    });
  };

  /**
   * setAssignmentTitle.
   *
   * @param {string} title
   */
  const setAssignmentTitle = (assignmentTitle: string) => {
    setState({
      ...state,
      newAssignment: {
        ...state.newAssignment,
        title: assignmentTitle
      }
    });
  };

  const selectNextOrPrevStep = (newAssignment: {
    type: assignmentTypes;
    title: string;
    grade: number;
  }) => {
    if (
      newAssignment.type === assignmentTypes.packs ||
      newAssignment.type === assignmentTypes.premium
    ) {
      return steps.selectPack;
    } else if (
      featureFlag.newLeAssignments &&
      (newAssignment.type === assignmentTypes.skills ||
        newAssignment.type === assignmentTypes.decodables ||
        newAssignment.type === assignmentTypes.genres)
    ) {
      return steps.selectSkillsOrGenres;
    } else {
      return steps.selectQuestions;
    }
  };

  const renderAssignButton = (assignment: any) => {
    const assignmentProperties: MixPanelProperties = {
      type: state.newAssignment.type,
      gradeSelected: state.newAssignment.grade
    };
    if (state.newAssignment.type === assignmentTypes.skills) {
      assignmentProperties.topic = assignment.skillsDescription;
    }
    if (
      isPremiumUser ||
      (!isPremiumUser && assignment.contentType === 'Core')
    ) {
      const title =
        state.newAssignment.type === assignmentTypes.genres
          ? assignment.title
          : `${assignment.skillsDescription} - ${assignment.title}`;
      return (
        <Button
          primary
          style={{ padding: 5 }}
          onClick={() => {
            setAssignmentTitle(title);
            setSelectedPackId(assignment.id);
            setCurrentStep(assignmentSteps.selectStudents);
          }}
        >
          Assign
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            background: colors.appbarAlt,
            border: `1px solid ${colors.primaryHover}`,
            color: colors.primaryHover,
            padding: 5
          }}
          onClick={() => {
            mixpanel.track(
              'Unlock Premium Assignment Clicked',
              assignmentProperties
            );
            dispatch(hide('modal'));
            dispatch(
              push(
                userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass)
              )
            );
          }}
        >
          Unlock
        </Button>
      );
    }
  };

  const { steps } = state;
  let loading = null;
  let content = null;
  let buttons = null;
  let title = 'Create Assignment';
  const classes = useStyles();
  switch (currentStep) {
    case steps.selectType:
      content = (
        <>
          <Grid xs={12} className={classes.rowGrid}>
            <Typography component="h3">
              Select the type of assignment and the grade
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.rowGrid}>
            <InputLabel>Type</InputLabel>
            <Select
              fullWidth
              name="type"
              onChange={handleSelecType}
              value={state.newAssignment.type}
            >
              {!featureFlag.newLeAssignments && (
                <MenuItem value={assignmentTypes.genres}>
                  Genre Passages
                </MenuItem>
              )}
              {userData.userType !== userTypes.parent &&
                !featureFlag.newLeAssignments && (
                  <MenuItem value={assignmentTypes.skills}>
                    Word Study/Vocab Skills
                  </MenuItem>
                )}

              {userData.userType !== userTypes.parent && (
                <MenuItem value={assignmentTypes.premium}>
                  {/* {!isPremiumUser ? (
                    <span>
                      <StarsIcon
                        style={{
                          fill: '#f8bb00',
                          position: 'relative',
                          top: '5px',
                          right: '2px'
                        }}
                      />{' '}
                      Charts and Graphs{' '}
                    </span>
                  ) : ( */}
                  <span> Charts and Graphs </span>
                  {/* )} */}
                </MenuItem>
              )}
              {featureFlag.newLeAssignments && (
                <MenuItem value={assignmentTypes.decodables}>
                  Decodables
                </MenuItem>
              )}
              {featureFlag.newLeAssignments && (
                <MenuItem value={assignmentTypes.genres}>Genres</MenuItem>
              )}
              {featureFlag.newLeAssignments && (
                <MenuItem value={assignmentTypes.skills}>Skills</MenuItem>
              )}
              <MenuItem value={assignmentTypes.packs}>Themed Content</MenuItem>
            </Select>
          </Grid>

          <Grid xs={12} className={classes.rowGrid}>
            <InputLabel>Grade</InputLabel>
            <Select
              fullWidth
              name="grade"
              onChange={handleSelectGrade}
              value={state.newAssignment.grade}
            >
              {state.grades.map((grade: number) => {
                return <MenuItem value={grade}>{grade}</MenuItem>;
              })}
            </Select>
          </Grid>
        </>
      );
      const nextStep = selectNextOrPrevStep(state.newAssignment);
      buttons = (
        <div style={footerStyle}>
          {renderNextButton(handleLoadContent, nextStep)}
        </div>
      );
      break;
    case steps.decodablesAssignmentDescription:
      content = (
        <>
          <AssignmentDescription selectedData={selectedAssignmentData} />
        </>
      );
      const prevStep =
        state.newAssignment.type === assignmentTypes.decodables
          ? steps.selectSkillsOrGenres
          : steps.selectPack;
      buttons = (
        <div style={footerStyle}>
          {renderBackButton(prevStep)}
          {renderAssignButton(selectedAssignmentData)}
        </div>
      );
      break;
    case steps.selectQuestions:
      const { type } = state.newAssignment;
      const contentType =
        type === assignmentTypes.skills ? 'questions' : 'passages';
      if (state.typeOptions.length === 0) {
        content = (
          <p>There is no content available for your selection at the moment.</p>
        );
      } else {
        content = (
          <div>
            <p>
              Select the {handleTextCapitalization(type)} you would like to
              assign and the number of {contentType} for each
              {` ${type.substring(0, type.length - 1).toLowerCase()}`}.
            </p>
            <div className="dashboard__modal-option-wrapper">
              {renderQuestionSelection(contentType)}
            </div>
          </div>
        );
      }
      const selectedItems = state.typeOptions.filter(item => {
        return item.selected === true;
      });
      const quantity =
        selectedItems.reduce((acc: any, item: any) => {
          // const quantityToSum = isNaN(item.quantity) || item.quantity === '' ?
          const quantityToSum = isNaN(item.quantity) ? 0 : item.quantity;
          return parseInt(acc, 10) + parseInt(quantityToSum, 10);
        }, 0) || 0;
      let info = `${
        selectedItems.length
      } ${type.toLowerCase()} and ${quantity} ${contentType}
          to be assigned`;
      if (error.contentSelection) {
        info = error.contentSelection;
      }
      if (state.typeOptions.length === 0) {
        info = '';
      }
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          >
            {info}
          </Typography>
          {renderBackButton(steps.selectType)}
          {renderNextButton(
            () => handleSetQuestions(contentType),
            steps.selectStudents
          )}
        </div>
      );
      break;
    case steps.selectPack:
      if (!state.typeOptions.length) {
        content = (
          <Grid xs={12} className={classes.rowGrid}>
            <Typography component="h3">
              No content available for selected grade.
            </Typography>
          </Grid>
        );
      } else {
        content = (
          <>
            <PackSelection
              disableOptions={disableNextButton}
              selectedValue={selectedPackId}
              options={state.typeOptions}
              descriptionURL={
                state.newAssignment.type != assignmentTypes.premium
                  ? `${commonRoutes.contentPackDescriptions}?descriptionId=`
                  : ''
              }
              isPremiumUser={isPremiumUser}
              handleCheckOption={(event: any) => {
                const selectedContentId = parseInt(
                  (event.target as HTMLInputElement).value
                );
                setSelectedPackId(selectedContentId);
                const selectedPack = state.typeOptions.find(
                  e => e.id === selectedContentId
                );
                const assignmentTitle = selectedPack
                  ? selectedPack.title || selectedPack.name
                  : state.newAssignment.title;
                setAssignmentTitle(assignmentTitle);
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                bottom: -15
              }}
            >
              {disableNextButton ? (
                <Button
                  primary
                  style={{ marginRight: '15px' }}
                  onClick={redirect}
                >
                  Upgrade to Premium
                </Button>
              ) : (
                ''
              )}
            </div>
          </>
        );
      }
      // let info = '';
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          >
            {/* {info} */}
          </Typography>
          {renderBackButton(steps.selectType)}
          {state.typeOptions.length > 0 &&
            renderNextButton(() => {}, steps.selectStudents)}
        </div>
      );
      break;
    case steps.selectSkillsOrGenres:
      if (!state.typeOptions.length) {
        content = (
          <Grid xs={12} className={classes.rowGrid}>
            <Typography component="h3">
              No content available for selected grade.
            </Typography>
          </Grid>
        );
      } else {
        content = (
          <SkillOrGenreSelection
            type={state.newAssignment.type}
            options={state.typeOptions}
            isPremiumUser={isPremiumUser}
            setTitle={setAssignmentTitle}
            setId={setSelectedPackId}
            setNextStep={setCurrentStep}
            grade={state.newAssignment.grade}
            setDecodablesAssignmentData={setSelectedAssignmentData}
          />
        );
      }
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          ></Typography>
          {renderBackButton(steps.selectType)}
        </div>
      );
      break;
    case steps.selectStudents:
      const studentsLength = state.students.data.length;
      if (!studentsLength) {
        content = (
          <div>
            <p>
              Your class doesn't have any players yet. Add players before
              creating your assignment.
            </p>
          </div>
        );
      } else {
        content = (
          <StudentSelection
            description="Select the players who will receive this assigment:"
            students={state.students.data}
            allSelected={state.students.allSelected}
            handleCheckStudent={handleCheckStudent}
            handleSelectAllClick={handleSelectAllClick}
            classes={{ selectAllButton: classes.selectAllButton }}
          />
        );
        const selectedStudents = state.students.data.filter(
          (item: any) => item.selected === true
        );
        let info = `${selectedStudents.length} players selected`;
        if (error.studentSelection) {
          info = error.studentSelection;
        }
        const prevStep = selectNextOrPrevStep(state.newAssignment);
        buttons = (
          <div style={footerStyle}>
            <Typography
              component="span"
              className={clsx(
                classes.footerInfo,
                isMobile && classes.footerInfoMobile
              )}
            >
              {info}
            </Typography>
            {renderBackButton(prevStep)}
            {renderNextButton(handleSetStudents, steps.selectDate)}
          </div>
        );
      }
      break;
    case steps.selectDate:
      // const numberOfMonths = isMobile ? 1 : 2;
      content = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid spacing={4}>
            <TextField
              fullWidth
              name="name"
              variant="outlined"
              label="Give a name to your assignment"
              value={state.newAssignment.title}
              className={classes.input}
              onChange={(event: any) => setAssignmentTitle(event.target.value)}
            />
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} spacing={4}>
              <Typography component="p" style={{ marginTop: 10 }}>
                Select the date to start and end the assignment:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} spacing={4}>
              <Typography component="p">Start Date:</Typography>
              <DatePicker
                margin="normal"
                label="Start Date"
                format="MM/dd/yyyy"
                value={state.startDate}
                minDate={new Date()}
                allowKeyboardControl={false}
                onChange={(startDate: any) => setState({ ...state, startDate })}
              />
            </Grid>

            <Grid item xs={12} md={6} spacing={4}>
              <Typography component="p">End Date:</Typography>
              <DatePicker
                margin="normal"
                label="End Date"
                format="MM/dd/yyyy"
                value={state.endDate}
                minDate={state.startDate}
                allowKeyboardControl={false}
                minDateMessage="End Date must be greater or equal Start Date"
                onChange={(endDate: any) => setState({ ...state, endDate })}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      );
      let assignmentInfo = '';
      if (error.assignmentData) {
        assignmentInfo = error.assignmentData;
      }
      buttons = (
        <div style={footerStyle}>
          <Typography
            component="span"
            className={clsx(
              classes.footerInfo,
              isMobile && classes.footerInfoMobile
            )}
          >
            {assignmentInfo}
          </Typography>
          {renderBackButton(steps.selectStudents)}
          {renderNextButton(handleSetDate, steps.done, 'Done')}
        </div>
      );
      break;
    default:
      loading = null;
      content = null;
  }
  if (state.loading) {
    props.show('spinner', { text: 'Loading data' });
    content = null;
  } else {
    props.hide('spinner');
  }
  let errorMessage = null;
  if (error.loading) {
    title = '';
    content = null;
    buttons = null;
    loading = null;
    errorMessage = <p>{error.loading}</p>;
  }

  return (
    <ModalAbstract show={show} handleHide={handleHide} maxWidth="md">
      <DialogTitle style={{ marginRight: 40 }}>
        Create Assignment
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            if (props.assignmentType) {
              handleOpenAssignmentOptionsModal();
            } else {
              props.hide('modal');
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {errorMessage}
        {buttons}
      </DialogActions>
    </ModalAbstract>
  );
};

const mapActionToProps = {
  show,
  hide
};

const mapStateToProps = (state: any) => ({
  class: state.class
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(ModalCreateAssignment);
