import React, { useState, useEffect } from 'react';
import {
  DialogContent,
  DialogTitle,
  makeStyles,
  IconButton,
  Typography,
  Theme
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { hide } from 'redux-modal';

import ModalAbstract from './ModalAbstract';
import { SkillResponse } from '../../../types/reports/skill-report-types';
import { IClassRoster } from '../../../types/state/class-type';
import SkillsTable from '../../../views/teacher/SkillComprehensionReportView/SkillsTable';
import { FilterState } from '../../../views/teacher/SkillComprehensionReportView/SkillsFilters';
import SearchBar from '../../searchbar/SearchBar';
import { buildReport } from '../../../views/teacher/SkillComprehensionReportView/SkillReportHelpers';
import {
  playerNounLowerCase,
  playerNounUppercase
} from '../../../utils/userNoun';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

interface ModalSkillReportProps {
  show: any;
  handleHide: any;
  filter: FilterState;
  data: Array<SkillResponse>;
  classRoster: Array<IClassRoster>;
  skillId: number;
  handlePlayerNameClick?: (row: any) => void;
}

const ModalSkillReport: React.FC<ModalSkillReportProps> = (
  props: ModalSkillReportProps
) => {
  const [originalState, setOriginalState] = useState<Array<SkillResponse>>([]);
  const [tableState, setTableState] = useState<Array<SkillResponse>>([]);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const filteredReport = props.data.filter(
      item => item.skillId === props.skillId
    );
    const report = buildReport(filteredReport, {
      grade: props.filter.grade,
      groupKey: 'userId'
    }).map(item => {
      const { userId } = item;
      const player = props.classRoster.filter(
        item => parseInt(item.educationUserId) === userId
      )[0];
      const playerName = `${player.firstname} ${player.lastname}. (${player.username})`;
      return {
        ...item,
        playerName
      };
    });
    setOriginalState(report);
  }, []);

  useEffect(() => {
    setTableState(originalState);
  }, [originalState]);

  const renderTable = () => {
    if (tableState.length > 0) {
      return (
        <SkillsTable
          data={tableState}
          listType="player"
          handlePlayerNameClick={props.handlePlayerNameClick}
        />
      );
    }
    return (
      <Typography style={{ padding: 20, marginTop: 20, textAlign: 'center' }}>
        No {playerNounLowerCase}s found with that name.
      </Typography>
    );
  };

  return (
    <ModalAbstract
      show={props.show}
      handleHide={props.handleHide}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {playerNounUppercase} Details
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => dispatch(hide('modal'))}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SearchBar
          searchKey="playerName"
          originalState={originalState}
          placeholder="Search by Player Name"
          onChange={(searchState: any) => setTableState(searchState)}
        />
        {renderTable()}
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalSkillReport;
