import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

const labelStyle: React.CSSProperties = {
  fontWeight: 600,
  marginRight: 10
};

const wrapperStyle: React.CSSProperties = {
  marginBottom: 10
};

const ModalGoogleAccountInfo: React.FC = (props: any) => {
  const { firstname, lastname, email, show, handleHide } = props;
  return (
    <div>
      <Dialog open={show} onClose={handleHide}>
        <DialogTitle>Google account information</DialogTitle>
        <DialogContent dividers style={{ padding: 20 }}>
          <DialogContentText>
            <div style={wrapperStyle}>
              <Typography component="span" style={labelStyle}>
                First name:
              </Typography>
              <Typography component="span">{firstname}</Typography>
            </div>
            <div style={wrapperStyle}>
              <Typography component="span" style={labelStyle}>
                Last name:
              </Typography>
              <Typography component="span">{lastname}</Typography>
            </div>
            <div style={wrapperStyle}>
              <Typography component="span" style={labelStyle}>
                E-mail:
              </Typography>
              <Typography component="span">{email}</Typography>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHide}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalGoogleAccountInfo;
