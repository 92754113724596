import React from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { connectModal, InjectedProps } from 'redux-modal';

const variantIcon: any = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const useStyles2 = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

function SnackbarSwitch(type: string, message: string, handleClose: any) {
  const classes = useStyles2();
  switch (type) {
    case 'success':
      return (
        <SnackbarCreator
          variant="success"
          onClose={handleClose}
          className={classes.margin}
          message={message}
        />
      );
    case 'error':
      return (
        <SnackbarCreator
          variant="error"
          className={classes.margin}
          message={message}
        />
      );
    case 'warning':
      return (
        <SnackbarCreator
          variant="warning"
          className={classes.margin}
          message={message}
        />
      );
    case 'info':
      return (
        <SnackbarCreator
          variant="info"
          className={classes.margin}
          message={message}
        />
      );
    default:
      return (
        <SnackbarCreator
          variant="info"
          className={classes.margin}
          message="This is an information message!"
        />
      );
  }
}

function SnackbarCreator(props: any) {
  const classes: any = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const variants: string = variant;
  const Icon: any = variantIcon[variants];
  return (
    <SnackbarContent
      className={clsx(classes[variants], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

const SnackbarNotification: React.FC<InjectedProps> = (props: any) => {
  const {
    variant,
    message,
    horizontal,
    vertical,
    autoHideDuration,
    handleHide,
    show
  } = props;
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: vertical || 'top',
          horizontal: horizontal || 'right'
        }}
        open={show}
        autoHideDuration={autoHideDuration || 1500}
        onClose={handleHide}
      >
        {SnackbarSwitch(variant, message, handleHide)}
      </Snackbar>
    </div>
  );
};

export default connectModal({ name: 'snackbar' })(SnackbarNotification);
