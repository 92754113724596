import React from 'react';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../redux/actions/user-actions';

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(userLogout());
  return null;
};

export default Logout;
