import React from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { CheckCircleOutlined, GetApp } from '@material-ui/icons';
import Button from '../../../../components/button/Button';
import { Codes } from './Codes';
import useQueryParams from '../../../../hooks/UseQueryParams';
import { fetchApi } from '../../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../../types/fetch/fetch-types';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { PlayerMembers } from './PlayerMembers';
import { show } from 'redux-modal';
import { useAppSelector } from '../../../../redux/hooks';
import { GoogleTrackingCheckout } from '../cart/google-tracking';
import FriendsIcon from '../../../../assets/checkout-page/friends.png';

const useStyles = makeStyles({
  spacing: {
    marginTop: '0.5rem'
  },
  players: {
    marginTop: '1rem'
  },
  card: {
    width: '100%',
    marginTop: '1rem',
    borderRadius: '40px',
    border: '1px solid #FFF',
    position: 'relative',
    textAlign: 'center'
  }
});
export const CheckoutSuccessView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQueryParams();
  const { sessionId, isOneBuyLink } = query;
  const isBuyLink = Boolean(isOneBuyLink);
  const userData = useAppSelector(state => state.userData);
  const isUserLoggedIn = userData && userData._id;
  const createAccountLink = `${process.env.REACT_APP_DASHBOARD_URL}register/parent`;
  const loginLink = `${process.env.REACT_APP_DASHBOARD_URL}login`;
  const [session, setSession] = React.useState<{
    isLoading: boolean;
    codes: any[];
    subscription: {
      id: string;
    };
    customer: { email: string };
    players: { id: string; username: string; name: string }[];
  }>({
    codes: [],
    isLoading: false,
    customer: {
      email: ''
    },
    subscription: {
      id: ''
    },
    players: []
  });
  React.useEffect(() => {
    if (sessionId) {
      setSession({ ...(session as any), isLoading: true });
      dispatch(
        fetchApi({
          url: `stripe/checkout/session/${sessionId}`,
          method: APIMethods.GET
        })
      )
        .then((response: AxiosResponse) => {
          const {
            codes,
            customer,
            players,
            subscription,
            plan
          } = response.data;
          if (codes && customer) {
            setSession({
              codes,
              isLoading: false,
              players,
              subscription,
              customer
            });
          }
          if (plan) {
            const { quantity, name, price } = plan;
            GoogleTrackingCheckout.trackPurchase(
              subscription.stripeId,
              name,
              price,
              quantity
            );
          }
        })
        .catch((err: any) => {
          console.error(err);
          setSession({
            ...(session as any),
            isLoading: false
          });
          dispatch(
            show('snackbar', {
              variant: 'error',
              message: 'Unable to get purchase information'
            })
          );
        });
    }
  }, []);
  const isCodesAvailable = session && session.codes && session.codes.length > 0;
  const membershipPlayers = session && session.players;

  function congratsMessagePlayers() {
    let message = 'Congratulations! Below ';
    const players = membershipPlayers;
    if (players.length === 1) {
      message += 'is ';
    } else if (players.length > 1) {
      message += 'are ';
    }
    return message;
  }
  const message =
    !isCodesAvailable &&
    membershipPlayers &&
    membershipPlayers.length > 0 &&
    congratsMessagePlayers();

  const downloadCodes = () => {
    dispatch(
      fetchApi({
        url: `stripe/checkout/codes/${session.subscription.id}`,
        method: APIMethods.GET,
        responseType: 'arraybuffer'
      })
    )
      .then((res: AxiosResponse) => {
        const { data } = res;
        const url = window.URL.createObjectURL(
          new Blob([data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'codes.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((err: any) => console.log(err));
  };
  const finishLink = `${loginLink}?isMembershipFlow=true&email=${session &&
    session.customer &&
    session.customer.email}`;
  const theme = useTheme();
  const sizeAboveMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid
      container
      justifyContent={'center'}
      style={{
        padding: '1rem',
        background: '#027F5F',
        minHeight: '100%',
        position: 'relative'
      }}
    >
      <Grid
        item
        style={{
          marginBottom: 40,
          minHeight: '100%',
          width: sizeAboveMobileScreen ? '80%' : '95%',
          maxWidth: '996px',
          flexDirection: 'column',
          flexWrap: 'unset',
          alignItems: 'center'
        }}
        className={classes.spacing}
      >
        <Box textAlign="center" style={{ wordBreak: 'break-word' }}>
          <Typography
            variant={'h3'}
            style={{
              fontFamily: "'Archivo', sans-serif",
              fontStyle: 'italic',
              fontWeight: '900',
              color: '#FDFEFE',
              textTransform: 'uppercase',
              fontSize: sizeAboveMobileScreen ? '60px' : '40px'
            }}
          >
            Purchase{' '}
            <Typography
              variant={'h3'}
              component={'span'}
              style={{
                fontFamily: "'Archivo', sans-serif",
                fontStyle: 'italic',
                textTransform: 'uppercase',
                fontWeight: '900',
                color: '#FFBB31',
                fontSize: sizeAboveMobileScreen ? '60px' : '40px'
              }}
            >
              Complete!
            </Typography>
          </Typography>
          {!isCodesAvailable && !session.isLoading && (
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{ color: '#fff', fontWeight: 600, fontSize: '24px' }}
                >
                  {message}
                  <Typography
                    component={'span'}
                    style={{
                      color: '#FFBB31',
                      fontWeight: 600,
                      fontSize: '24px'
                    }}
                  >
                    {' '}
                    the list of new members.
                  </Typography>
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 600, fontSize: '24px' }}
                >
                  To view the applied membership, please log back into the game.
                </Typography>
              </Grid>
            </Grid>
          )}
          {isCodesAvailable && (
            <Typography
              variant="body2"
              style={{ color: '#fff', fontWeight: 700 }}
            >
              Here's your
              <Typography
                variant="body2"
                component={'span'}
                style={{ color: '#FFBB31', fontWeight: 700 }}
              >
                &nbsp;membership code.
              </Typography>
              We've also sent it to your email.
            </Typography>
          )}
        </Box>
        <Box
          className={classes.card}
          p={{ xs: 1, sm: 4, md: 6 }}

          // pt={{ xs: 2, sm: 4, md: 4 }}
          // pb={{ xs: 4, sm: 6, md: 6 }}
          // pl={{ xs: 4, sm: 6, md: 6 }}
          // pr={{ xs: 4, sm: 6, md: 6 }}
        >
          {session.isLoading && (
            <Grid item xs={12}>
              <CircularProgress color={'primary'} />
            </Grid>
          )}
          {!session.isLoading && membershipPlayers && (
            <Grid className={classes.players} item xs={12}>
              <PlayerMembers players={membershipPlayers} />
            </Grid>
          )}
          {!session.isLoading && isCodesAvailable && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                  <Codes codes={session.codes} />
                </Grid>
                <Button
                  onClick={downloadCodes}
                  size="small"
                  style={{
                    background: '#FFBB31',
                    color: '#FFF',
                    boxShadow: '-6px 14px 6px 0px #0B5B47',
                    fontWeight: 700,
                    width: '300px',
                    fontSize: '18px',
                    marginTop: '1rem'
                  }}
                  primary
                >
                  Download Code &nbsp; <GetApp />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{ color: '#fff', fontWeight: 700 }}
                >
                  Check your email for the next steps to redeem your membership.
                  <Typography variant="body1" component={'span'}>
                    &nbsp;
                    {session.players && session.players.length === 0 && (
                      <Link
                        href={finishLink}
                        style={{ color: '#FFBB31', fontWeight: 700 }}
                      >
                        Finish
                      </Link>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          )}
          {!session.isLoading && session.players && session.players.length > 0 && (
            <Grid
              item
              xs={12}
              className={classes.spacing}
              style={{
                textAlign: 'center',
                position: 'absolute',
                bottom: '-1.4rem',
                left: 0,
                right: 0
              }}
            >
              <Link style={{ color: '#fff' }} href={finishLink}>
                <Button
                  size="large"
                  style={{
                    background: '#FFBB31',
                    color: '#FFF',
                    boxShadow: '-6px 14px 6px 0px #0B5B47',
                    fontWeight: 900,
                    width: '300px',
                    fontSize: '18px'
                  }}
                >
                  Finish
                </Button>
              </Link>
            </Grid>
          )}
        </Box>
        {!isUserLoggedIn && (
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Typography variant="body1" style={{ color: '#fff' }}>
              Are you ready to celebrate your player's learning?{' '}
              <Link
                href={`${createAccountLink}?isMembershipFlow=true&email=${session &&
                  session.customer &&
                  session.customer.email}`}
                style={{ color: '#FFBB31' }}
              >
                Create a Parent Account
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box
        style={{
          position: 'absolute',
          bottom: '0',
          right: '0'
        }}
        display={{ xs: 'none', sm: 'none', lg: 'block', xl: 'block' }}
      >
        <img
          src={FriendsIcon}
          alt="friends"
          style={{
            width: '28vw',
            objectFit: 'cover'
          }}
        />
      </Box>
    </Grid>
  );
};
