import { createTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import colors from '../styles/colors';
import { SkeletonClassKey } from '@material-ui/lab/Skeleton';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiSkeleton: SkeletonClassKey;
  }
}

export const borderRadius = 34;
export const defaultTextStyle: any = {
  fontFamily: 'Lato',
  fontWeight: 600,
  textTransform: 'none'
};

export default createTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: green
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    h1: {
      fontSize: 56,
      fontFamily: 'Lato',
      fontWeight: 600,
      letterSpacing: '-0.4'
    },
    h2: {
      fontSize: 52,
      fontFamily: 'Lato',
      fontWeight: 600,
      letterSpacing: 0
    },
    h3: {
      fontSize: 40,
      fontFamily: 'Lato',
      fontWeight: 600,
      letterSpacing: '0.6px'
    },
    h4: {
      fontSize: 34,
      fontFamily: 'Lato',
      fontWeight: 600,
      letterSpacing: '0.1px'
    }
  },

  overrides: {
    MuiButton: {
      text: {
        padding: '10px 21px'
      },
      root: {
        ...defaultTextStyle,
        borderRadius
      }
    },

    MuiOutlinedInput: {
      root: {
        fontWeight: 300,
        overflow: 'hidden'
      },
      notchedOutline: {
        backgroundColor: '#fff',
        borderRadius
      },
      input: {
        zIndex: 1000
      }
    },

    MuiInputBase: {
      root: {
        borderRadius
      }
    },

    MuiCheckbox: {
      root: {
        borderRadius: 5,
        padding: 1,
        margin: '0 8px'
      }
    },

    MuiRadio: {
      root: {
        padding: 1,
        margin: '0 8px'
      }
    },

    MuiFab: {
      primary: {
        ...defaultTextStyle,
        color: '#fff'
      }
    },

    MuiTab: {
      root: {
        ...defaultTextStyle
      }
    },

    MuiTableCell: {
      head: {
        fontWeight: 600
      }
    },

    MuiPaper: {
      elevation1: {
        boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.1)'
      }
    },

    MuiBadge: {
      root: {
        alignItems: 'center'
      }
    },

    MuiSkeleton: {
      root: {
        borderRadius: '20px'
      }
    }
  },

  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});
