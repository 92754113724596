import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Button from '../../button/Button';
import ModalAbstract from './ModalAbstract';
import { editClass } from '../../../redux/actions/class-actions';

const ModalEditClass = (props: any) => {
  const { show, handleHide, id, match, className: currentClassName } = props;
  const [className, setClassName] = useState(currentClassName);
  const [errors, setErrors] = useState({
    isError: false,
    errorText: ''
  });
  const dispatch = useDispatch();

  const handleChange = (evt: any) => {
    setClassName(evt.target.value);
  };

  const handleEditClass = () => {
    if (className.length === 0) {
      return setErrors({
        isError: true,
        errorText: 'Please enter a class name'
      });
    }

    const editClassRequest = {
      id,
      data: {
        name: className
      }
    };
    handleHide();
    return dispatch(
      editClass(editClassRequest, match.params.id ? match.params.id : '')
    );
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Edit Class</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl error={errors.isError}>
            <TextField
              value={className}
              label="Class Name"
              name="className"
              onChange={handleChange}
            />
            <FormHelperText>
              {errors.isError ? errors.errorText : null}
            </FormHelperText>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button onClick={handleEditClass} primary>
          Confirm
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalEditClass;
