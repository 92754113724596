import React from 'react';
import { makeStyles } from '@material-ui/core';
import color from '../../styles/colors';

export const ErrorCrash: React.FC = () => {
  const useStyles = makeStyles({
    container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.primary
    },
    errorText: {
      color: 'white'
    }
  });

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1 className={classes.errorText}>
        Ooops! looks like something is wrong.
      </h1>
    </div>
  );
};
