import {
  LOCATION_GET_DISTRICTS,
  LOCATION_GET_SCHOOLS,
  LOCATION_SET_COUNTRY_PROVINCE
} from '../types/location/location-action-types';
import {
  District,
  LocationReducerState
} from '../types/location/location-reducer-types';

export const locationSelectionReducer = (
  state: LocationReducerState = {
    country: '',
    province: '',
    schools: [],
    districts: []
  },
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case LOCATION_SET_COUNTRY_PROVINCE:
      return {
        ...state,
        country: payload.country,
        province: payload.province
      };
    case LOCATION_GET_DISTRICTS:
      const districts: Location[] = payload.data.data
        .filter(
          (district: District) =>
            district.countryID === state.country &&
            district.regionID === state.province
        )
        .map((district: District) => ({
          label: district.name,
          value: district._id
        }));
      return {
        ...state,
        districts
      };
    case LOCATION_GET_SCHOOLS:
      return {
        ...state,
        schools: payload.data.data
      };
    default:
      return state;
  }
};
