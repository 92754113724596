import {
  PLAYER_REGISTER,
  PLAYER_REGISTER_FAILED,
  PLAYER_UDPATE,
  PLAYER_UPDATE_FAILED,
  PLAYER_GET_REPORT
} from '../types/player/player-action-types';
import PlayerState from '../../types/state/player-type';

export const playerReducer = (
  state: PlayerState = {
    currentReadingLevel: 0,
    questions: {},
    readingLevel: [],
    skills: {
      total: [],
      struggling: []
    }
  },
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case PLAYER_UDPATE:
      return state;
    case PLAYER_UPDATE_FAILED:
      return state;
    case PLAYER_REGISTER:
      return state;
    case PLAYER_REGISTER_FAILED:
      return state;
    case PLAYER_GET_REPORT:
      return payload;
    default:
      return state;
  }
};
