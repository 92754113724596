import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ExternalMembershipIcon from '../../../../assets/checkout-page/membership_icon.png';

const useStyles = makeStyles({
  root: {
    padding: '0 10px',
    maxHeight: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar-thumb': {
      background: '#FFBB31'
    }
  },
  card: {
    width: '100%',
    padding: '30px 40px',
    margin: '20px 5px',
    borderRadius: '26px',
    backgroundColor: '#fff',
    flexWrap: 'nowrap',
    '@media (max-width: 600px)': {
      padding: '15px 30px',
      flexWrap: 'wrap'
    }
  },
  chip: {
    marginTop: '5px',
    width: '100px',
    backgroundColor: 'rgba(248, 187, 0, 0.3)'
  }
});

export const PlayerMembers = (props: any) => {
  const classes = useStyles();
  const { players } = props;
  const p = [...players];
  function getProfileImage(index: number) {
    const randomIndex = index + 1;
    const randomizedIndex =
      randomIndex > 9 ? `0${randomIndex % 10}` : `0${randomIndex}`;
    return `${process.env.REACT_APP_DREAMSCAPE_CDN}/members_profile_pictures/member_prof_${randomizedIndex}.png`;
  }
  return (
    <Grid container className={classes.root} justifyContent={'center'}>
      {p.map((player: { name: string; username: string }, index: number) => {
        return (
          <Grid
            container
            className={classes.card}
            style={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Grid container item style={{ flexGrow: 1 }}>
              <Grid item>
                <img
                  height={'70px'}
                  width={'70px'}
                  src={getProfileImage(index)}
                  alt="membership profile"
                  style={{ paddingRight: '10px', paddingTop: '5px' }}
                />
              </Grid>
              <Grid item xs={7} sm={9} md={9}>
                <Box
                  height={'100%'}
                  width={'100%'}
                  textAlign={'left'}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                >
                  <Typography
                    style={{
                      color: '#027F5F',
                      fontWeight: '700',
                      fontSize: '24px'
                    }}
                  >
                    {player.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#027F5F',
                      fontWeight: '600',
                      fontSize: '16px'
                    }}
                  >
                    username: {player.username}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              item
              style={{
                flexGrow: 1,
                width: 'auto',
                alignItems: 'center',
                flexWrap: 'nowrap'
              }}
            >
              <Grid item>
                <img
                  height={'70px'}
                  width={'70px'}
                  src={ExternalMembershipIcon}
                  alt="membership profile"
                  style={{ paddingRight: '10px' }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    color: '#FFBB31',
                    fontWeight: '700',
                    textTransform: 'uppercase'
                  }}
                >
                  Member
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
