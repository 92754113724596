import {
  PLANNER_MODIFY,
  PLANNER_REMOVE,
  PLANNER_SET,
  PLANNER_UPDATE,
  PLANNER_UPDATE_MULTIPLE
} from '../types/planner/planner-action-types';
import { hide, show } from 'redux-modal';
import { fetchApi } from '../actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';
import {
  trackAssignmentCreated,
  trackAssignmentDeleted,
  trackPrintAssignment,
  trackTestPrepCreated
} from './mixpanel-actions';
import { updateTodoListItem } from './class-actions';
import store from '../store';
import { push } from 'connected-react-router';
import { userRouteGetter } from '../../utils/user-route-getter';
import { MODAL_ASSIGNMENT_CREATED } from '../../constants/modals';
import { AxiosResponse } from 'axios';

export const getPlanner = (classCode: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/assignment/classCode/${classCode}`,
        method: APIMethods.GET
      })
    ).then((response: any) => {
      dispatch({
        type: PLANNER_SET,
        payload: response
      });
    });
  };
};

interface AssignmentContent {
  classCode: string;
  type: string;
  grade: number;
  typeId?: number;
  region?: string;
}
export const getAssignmentContent = (content: AssignmentContent): any => {
  const requestParams = new URLSearchParams({
    classCode: content.classCode,
    type: content.type,
    grade: content.grade.toString(),
    typeId: content.typeId ? content.typeId.toString() : '',
    region: content.region ? content.region.toString() : ''
  });
  return fetchApi({
    url: `v2.1/assignment/content?${requestParams.toString()}`,
    method: APIMethods.GET,
    endpoint: APIEndpoints.EDUCATION,
    spinnerMessage: 'Loading Data',
    hideSpinnerAfter: true
  });
};

/**
 * @description updates user generated assignment
 * @param groupReference
 */
export const updateUserGeneratedAssignment = (
  groupReference: string,
  endDate: Date
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `v1/user-generated/group/${groupReference}`,
        endpoint: APIEndpoints.EDUCATION,
        method: APIMethods.PATCH,
        data: { endDate },
        spinnerMessage: 'Updating Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Updated Successfully',
        errorMessage:
          'There was a problem updating your assignment, please contact our support team if the problem persists.'
      })
    )
      .then((response: AxiosResponse) => {
        const { data } = response;
        dispatch({
          type: PLANNER_MODIFY,
          payload: { ...data, id: groupReference, isUserGenerated: true }
        });
        dispatch(hide('modal'));
      })
      .catch((error: any) => Debug.log(error));
  };
};

export const updateAssignmentV2 = (
  id: any,
  title: string,
  startDate: string,
  endDate: string
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `v2.1/assignment/${id}`,
        endpoint: APIEndpoints.EDUCATION,
        method: APIMethods.PUT,
        data: { id, title, startDate, endDate },
        spinnerMessage: 'Updating Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Updated Successfully',
        errorMessage:
          'There was a problem updating your assignment, please contact our support team if the problem persists.'
      })
    )
      .then((response: AxiosResponse) => {
        dispatch({
          type: PLANNER_MODIFY,
          payload: { title, id, startDate, endDate }
        });
        dispatch(hide('modal'));
      })
      .catch((error: any) => Debug.log(error));
  };
};

export const updateAssignment = (
  id: any,
  title: string,
  startDate: string,
  endDate: string
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/assignment/${id}`,
        method: APIMethods.PUT,
        data: { id, title, startDate, endDate },
        spinnerMessage: 'Updating Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Updated Successfully',
        errorMessage:
          'There was a problem updating your assignment, please contact our support team if the problem persists.'
      })
    )
      .then((response: any) => {
        const data = JSON.parse(response.config.data);
        dispatch({
          type: PLANNER_MODIFY,
          payload: data
        });
        dispatch(hide('modal'));
      })
      .catch((error: any) => Debug.log(error));
  };
};

export const updateAssignmentNewLE = (
  id: any,
  title: string,
  startDate: string,
  endDate: string
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        endpoint: APIEndpoints.LE_API,
        url: `v1/assignments/${id}`,
        method: APIMethods.PUT,
        data: { assignmentGivenName: title, startDate, endDate },
        spinnerMessage: 'Updating Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Updated Successfully',
        errorMessage:
          'There was a problem updating your assignment, please contact our support team if the problem persists.'
      })
    )
      .then((response: any) => {
        const data = JSON.parse(response.config.data);
        dispatch({
          type: PLANNER_MODIFY,
          payload: data
        });
        dispatch(hide('modal'));
      })
      .catch((error: any) => Debug.log(error));
  };
};

interface CreateAssignmentOptions {
  redirect: boolean;
  testPrepOptions: any;
  region: any;
  allTestPrepsSelected: any;
  selectedOption?: any
}

export const createAssignmentV2 = (
  assignmentsArray: Array<any>,
  options?: CreateAssignmentOptions
) => {
  const { userData }: any = store.getState();

  return (dispatch: any) => {
    assignmentsArray.forEach(assignmentData => {
      dispatch(
        fetchApi({
          url: 'v3/assignment',
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.POST,
          data: {
            ...assignmentData,
            dashboardUserId:
              userData?.features?.userGeneratedContent?.dashboardUserId
          },
          spinnerMessage: 'Creating Assignment',
          hideSpinnerAfter: true,
          notificationSuccess: 'Assignment Created Successfully',
          errorMessage:
            'There was a problem creating your assignment, please contact our support team if the problem persists.'
        })
      )
        .then((assignmentResponse: any) => {
          dispatch({
            type: PLANNER_UPDATE_MULTIPLE,
            payload: assignmentResponse.data.assignments
          });
          /**
           * Need to ask about whether this should be refactored.
           */
          // dispatch(updateTodoListItem('createAssignment'));
          /**
           * Need to change and fix this up
           */
          if (assignmentData.content.type == 'TEST PREP') {
            trackTestPrepCreated(
              assignmentData,
              options?.testPrepOptions,
              options?.region,
              options?.allTestPrepsSelected
            );
          } else {
            trackAssignmentCreated(assignmentData);
          }
          dispatch(show('modal', { type: MODAL_ASSIGNMENT_CREATED }));
        })
        .catch((error: any) => Debug.log(error));
    });
  };
};

export const createAssignment = (
  assignmentsArray: Array<any>,
  options?: CreateAssignmentOptions
) => {
  const { userData, planner }: any = store.getState();

  return (dispatch: any) => {
    assignmentsArray.forEach(assignmentData => {
      dispatch(
        fetchApi({
          url: 'sw/assignment',
          method: APIMethods.POST,
          data: {
            ...assignmentData,
            dashboardUserId:
              userData?.features?.userGeneratedContent?.dashboardUserId
          },
          spinnerMessage: 'Creating Assignment',
          hideSpinnerAfter: true,
          notificationSuccess: 'Assignment Created Successfully',
          errorMessage:
            'There was a problem creating your assignment, please contact our support team if the problem persists.'
        })
      )
        .then((assignmentResponse: any) => {
          dispatch({
            type: PLANNER_UPDATE,
            payload: assignmentResponse.data
          });
          dispatch(updateTodoListItem('createAssignment'));
          if (assignmentData.content.type == 'TEST PREP') {
            trackTestPrepCreated(
              assignmentData,
              options?.testPrepOptions,
              options?.region,
              options?.allTestPrepsSelected
            );
          } else {
            trackAssignmentCreated(assignmentData, options?.selectedOption);
          }
          if (options && options.redirect) {
            const queryParams = '?showTutorial=true';
            const assignment = assignmentResponse.data;
            const userRoute = userRouteGetter(userData.userType);
            dispatch(
              push(
                `/dashboard/${userRoute}/assignment-tool/report/${assignment.data.educationAssignmentId}/${assignment.data.classCode}${queryParams}`
              )
            );
          } else {
            dispatch(show('modal', { type: MODAL_ASSIGNMENT_CREATED }));
          }
        })
        .catch((error: any) => Debug.log(error));
    });
  };
};

export const getPlannerReport = (
  assignmentId: string,
  isUserGenerated = false
) => {
  let url = `sw/assignment/${assignmentId}/report`;
  if (isUserGenerated) {
    url += '?isUserGenerated=true';
  }
  return fetchApi({
    url,
    method: APIMethods.GET,
    spinnerMessage: 'Loading report for assignment',
    hideSpinnerAfter: true
  });
};

export const getPlannerReportV2 = (
  assignmentId: string,
  isUserGenerated = false,
  classCode: string
) => {
  let url = `sw/assignment/${assignmentId}/report/v2?classCode=${classCode}`;
  if (isUserGenerated) {
    url += '&isUserGenerated=true';
  }
  return fetchApi({
    url,
    method: APIMethods.GET,
    spinnerMessage: 'Loading report for assignment',
    hideSpinnerAfter: true
  });
};

/**
 * @description disables user generated assignment
 * @param groupReference
 */
export const deleteUserGeneratedAssignment = (groupReference: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `v1/user-generated/group/${groupReference}`,
        endpoint: APIEndpoints.EDUCATION,
        method: APIMethods.DELETE,
        spinnerMessage: 'Deleting Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Removed!',
        errorMessage:
          'There was an error deleting your assignment. Please, contact our support team if the problem persists.'
      })
    )
      .then(() => {
        dispatch({
          type: PLANNER_REMOVE,
          payload: { id: groupReference, isUserGenerated: true }
        });
        dispatch(trackAssignmentDeleted());
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const deleteAssignmentV2 = (assignmentId: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `v2.1/assignment/${assignmentId}`,
        endpoint: APIEndpoints.EDUCATION,
        method: APIMethods.DELETE,
        spinnerMessage: 'Deleting Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Removed!',
        errorMessage:
          'There was an error deleting your assignment. Please, contact our support team if the problem persists.'
      })
    )
      .then(() => {
        dispatch({
          type: PLANNER_REMOVE,
          payload: { _id: assignmentId }
        });
        dispatch(trackAssignmentDeleted());
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const deleteAssignment = (assignmentId: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `sw/assignment/${assignmentId}`,
        method: APIMethods.DELETE,
        spinnerMessage: 'Deleting Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Removed!',
        errorMessage:
          'There was an error deleting your assignment. Please, contact our support team if the problem persists.'
      })
    )
      .then(() => {
        dispatch({
          type: PLANNER_REMOVE,
          payload: { _id: assignmentId }
        });
        dispatch(trackAssignmentDeleted());
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const deleteAssignmentNewLE = (assignmentId: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        endpoint: APIEndpoints.LE_API,
        url: `v1/assignments/${assignmentId}`,
        method: APIMethods.DELETE,
        spinnerMessage: 'Deleting Assignment',
        hideSpinnerAfter: true,
        notificationSuccess: 'Assignment Removed!',
        errorMessage:
          'There was an error deleting your assignment. Please, contact our support team if the problem persists.'
      })
    )
      .then(() => {
        dispatch({
          type: PLANNER_REMOVE,
          payload: { _id: assignmentId }
        });
        dispatch(trackAssignmentDeleted());
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const printReport = (
  assignmentId: string,
  fileName: string,
  isUserGenerated = false
) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: `pdf/assignment/${assignmentId}?isUserGenerated=${isUserGenerated}`,
        method: APIMethods.GET,
        responseType: 'blob',
        hideSpinnerAfter: true,
        spinnerMessage: 'Generating your PDF file',
        errorMessage:
          'There was a problem generating your PDF file. Please, try again later'
      })
    )
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();
        dispatch(trackPrintAssignment());
      })
      .catch((error: any) => {
        Debug.log(error);
      });
  };
};

export const authorizeECCP = () => {
  return (dispatch: any) => {
    const { login }: any = store.getState();
    const token = login.token.replace('JWT ', '');
    window.open(
      `${process.env.REACT_APP_ECCP_URL}/dashboard-auth?token=${token}`,
      '_blank'
    );
  };
};

export const getTestPreps = (classCode: string) => {
  return fetchApi({
    url: `v2.1/assignment/test-preps?classCode=${classCode}`,
    method: APIMethods.GET,
    endpoint: APIEndpoints.EDUCATION,
    spinnerMessage: 'Loading Data',
    hideSpinnerAfter: true
  });
};
