import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { hide as hideModal, show as showModal } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector, useDispatch } from 'react-redux';
import membershipPurchaseSuccessIcon from '../../../assets/membership/planUpgradeSuccessImage.png';

import { MODAL_TEACHER_ASSIGN_MEMBERSHIPS } from '../../../constants/modals';
import { useAppSelector } from '../../../redux/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  }
}));

type ModalUpgradeSuccessProps = {
  show: () => void;
  handleHide: () => void;
  oldPlan: string;
  newPlan: string;
  membershipCount: string;
};

const ModalUpgradeSuccess: React.FC<ModalUpgradeSuccessProps> = (
  props: ModalUpgradeSuccessProps
) => {
  const userData = useSelector((state: any) => state.userData);
  const { premium } = userData;

  const styles = useStyles();
  const dispatch = useDispatch();
  const { show, handleHide } = props;
  const membershipData = useAppSelector(state => state.members);
  const { nonMembers } = membershipData;
  const [unarchivedUsers, setUnarchivedUsers] = useState('');
  const classData = useAppSelector(state => state.class);
  const { classes } = classData;
  const archivedClasses = classes.filter((a: any) => a.archived == true);

  useEffect(() => {
    const archivedUsers: string[] = [];
    if (archivedClasses) {
      const archivedUsersData = archivedClasses.map((item: any) => item.roster);
      archivedUsersData.forEach((element: any) => {
        element.forEach((item: any) => {
          archivedUsers.push(item.userId);
        });
      });
    }
    const unarchivedNonMembersips = nonMembers.filter(function(obj: any) {
      return !archivedUsers.some(function(obj2: any) {
        return obj._id == obj2;
      });
    });
    setUnarchivedUsers(unarchivedNonMembersips);
  }, [classes, membershipData]);

  const handleContinue = () => {
    props.handleHide();
    if (props.membershipCount == '0') {
      props.handleHide();
    } else if (unarchivedUsers.length > 0) {
      const sendProps: any = {
        type: MODAL_TEACHER_ASSIGN_MEMBERSHIPS
      };
      dispatch(showModal('modal', sendProps));
    }
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        Your new plan has been activated!
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleHide}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Congratulations! You have successfully upgraded to a {props.newPlan}!
          Enjoy all of the great benefits for you and your players.
        </Typography>
        <br />
        <img
          src={membershipPurchaseSuccessIcon}
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%'
          }}
        ></img>
        <br />
        <Divider></Divider>
        <br />
        <Button
          style={{
            backgroundColor: '#3FD2D9',
            color: 'white',
            marginTop: '2px',
            fontSize: '1.1rem',
            position: 'relative',
            left: 470
          }}
          onClick={handleContinue}
        >
          Ok
        </Button>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalUpgradeSuccess;
