import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from '@material-ui/core';
import ModalAbstract from './ModalAbstract';
import { updateUserInformation } from '../../../redux/actions/user-actions';
import Button from '../../button/Button';
import { hide } from 'redux-modal';

const ModalPasswordChange = (props: any) => {
  const dispatch = useDispatch();
  const { show, handleHide } = props;
  const userData = useSelector((state: any) => state.userData);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');
  function handlePasswordChange(evt: any) {
    setPassword(evt.target.value);
  }
  function updatePassword() {
    if (password.length > 0) {
      dispatch(
        updateUserInformation({
          ...userData,
          password
        })
      );
      dispatch(hide('modal'));
    } else {
      setError(true);
    }
  }
  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Change password</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            <FormControl error={error}>
              <TextField
                type="password"
                value={password}
                label="Enter New Password"
                name="password"
                onChange={handlePasswordChange}
              />
              <FormHelperText>
                {error && 'Please enter password'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button onClick={updatePassword} primary>
          Confirm
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalPasswordChange;
