import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Button from '../../../components/button/Button';

import onboarding1 from '../../../assets/onboarding/onboarding1.gif';
import onboarding2 from '../../../assets/onboarding/onboarding2.gif';
import onboarding3 from '../../../assets/onboarding/onboarding3.gif';

const images = [onboarding1, onboarding2, onboarding3];

const useStyles = makeStyles(() => ({
  imagesWrapper: {
    margin: '0 auto 20px',
    overflow: 'hidden'
  },
  image: {
    maxWidth: '100%'
  }
}));

interface WelcomeProps {
  handleNextClick: () => void;
  userType: string;
}

const Welcome: React.FC<WelcomeProps> = (props: WelcomeProps) => {
  const classes = useStyles();
  const studentType = 'players';

  return (
    <>
      <Typography component="p" style={{ margin: 20 }}>
        Where {studentType} learn reading skills while playing an engaging
        building game.
      </Typography>
      <Grid
        container
        className={clsx(classes.imagesWrapper, 'animated', 'fadeIn')}
      >
        <Grid item xs={4} md={4}>
          <img
            src={images[0]}
            className={clsx(classes.image, 'animated', 'slideInLeft')}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <img
            src={images[1]}
            className={clsx(classes.image, 'animated', 'fadeInDown')}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <img
            src={images[2]}
            className={clsx(classes.image, 'animated', 'slideInRight')}
          />
        </Grid>
      </Grid>

      <Button primary fontSize="1.1rem" onClick={props.handleNextClick}>
        Show me more
      </Button>
    </>
  );
};

export default Welcome;
