import moment from 'moment';
import { createContext } from 'react';
import store from '../../redux/store';
import { Plan } from '../../features/premium/pages/premium-plans/Plan';
import { classMissionDateRange, classMissionDates } from '../../constants/global-constants';

interface TeacherFeatureFlags {
  classMissionTrials: boolean;
  classMissionTrialsExtended: boolean;
  isMayPromotionActive: boolean;
  summerNotification: { dot: boolean; banner: boolean };
  isMissionMonday: boolean;
  isPriceChangePrep: boolean;
  isMissionMondayPrepRange: string | null;
}

interface TeacherFeatureFlagContextI {
  flags: TeacherFeatureFlags;
  updateFlags: (f: TeacherFeatureFlags) => void;
}

/**
 * Initializes feature flags around the May intiatives.
 */
export const defaultTeacherFeatureFlags: TeacherFeatureFlags = {
  classMissionTrials: checkClassMissionTrials(),
  classMissionTrialsExtended: checkClassMissionTrialsExtended(),
  isMayPromotionActive: checkMayPromotion(),
  summerNotification: { dot: false, banner: false },
  isMissionMonday: checkMissionMonday(),
  isPriceChangePrep: checkPriceChangePrep(),
  isMissionMondayPrepRange: missionMondayPrepRange()
};

export const defaultTeacherFeatureFlagValue = {
  flags: defaultTeacherFeatureFlags,
  updateFlags: () => void 0
};

const TeacherFeatureFlagContext = createContext<TeacherFeatureFlagContextI>(
  defaultTeacherFeatureFlagValue
);
export default TeacherFeatureFlagContext;

export function checkClassMissionTrials() {
  const startDate = moment(new Date('2022/05/08')).startOf('day');
  const endDate = moment(new Date('2022/05/14')).endOf('day');
  if (moment().isBetween(startDate, endDate)) {
    return true;
  }
  return false;
}

export function checkClassMissionTrialsExtended() {
  const startDate = moment(new Date('2022/05/15')).startOf('day');
  const endDate = moment(new Date('2022/05/21')).endOf('day');
  if (moment().isBetween(startDate, endDate)) {
    return true;
  }
  return false;
}

export function checkMayPromotion() {
  const userData: any = store.getState().userData;
  // @TODO update 4 to 5 to mark the may period
  const startDate = moment(new Date('2022/05/01')).startOf('day');
  const endDate = moment(new Date('2022/05/31')).endOf('day');
  if (
    moment().isBetween(startDate, endDate) &&
    (!userData.premium ||
      !userData.premium.isPremium ||
      (userData.premium && userData.premium.type === Plan.Premium))
  ) {
    return true;
  }
  return false;
}

export function checkPriceChangePrep() {
  const userData: any = store.getState().userData;
  // @TODO update 4 to 5 to mark the may period
  const startDate = moment(new Date('2022/08/02')).startOf('day');
  const endDate = moment(new Date('2022/08/14')).endOf('day');
  if (
    moment().isBetween(startDate, endDate) &&
    (!userData.premium ||
      !userData.premium.isPremium ||
      (userData.premium && userData.premium.type === Plan.Premium))
  ) {
    return true;
  }
  return false;
}

export function premiumPriceChangeStart() {
  const startDate = moment(new Date('2022/08/15')).startOf('day');
  if (moment().isAfter(startDate)) {
    return true;
  }
  return false;
}

export function checkMissionMonday() {
  const now = moment().format('YYYY-MM-DD');
  if (classMissionDates.includes(now) || moment(now).isBetween(classMissionDateRange.from, classMissionDateRange.to, null, '[]')) {
    return true;
  }
  return false;
}

export function missionMondayPrepRange() {
  for(const date of classMissionDates) {
    if(moment().isSame(moment(date))) {
      return null; //return null for the date same as class mission date
    }
    if(moment().isBefore(moment(date).subtract(1, 'days').endOf('day')) ) {
      return moment(date).format("MMMM D, YYYY"); //format and return next class mission date
    }
  }
  return null
}
