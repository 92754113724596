import {
  NewServerMessage,
  ServerMessage
} from '../../types/realtime/realtime-types';
import {
  REAL_TIME_CONNECT,
  REAL_TIME_UPDATE_PLAYER,
  NEW_REAL_TIME_UPDATE_PLAYER,
  REAL_TIME_INITIALIZE_DATA,
  REAL_TIME_DISCONNECT
} from '../types/realtime/realtime-action-types';
import { IClassRoster } from '../../types/state/class-type';
import { fetchApi } from './fetch-actions';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import { Debug } from '../../utils/debug';

export const updateConnectionId = (connectionId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: REAL_TIME_CONNECT,
      payload: connectionId
    });
  };
};

export const disconnect = () => {
  return (dispatch: any) => {
    dispatch({ type: REAL_TIME_DISCONNECT });
  };
};

export const initialzeRealTimeData = (roster: Array<IClassRoster>) => {
  return (dispatch: any) => {
    dispatch({
      type: REAL_TIME_INITIALIZE_DATA,
      payload: roster.map(student => ({
        fullname: student.fullname,
        username: student.username,
        userId: student.educationUserId,
        correct: 0,
        incorrect: 0,
        totalQuestions: 0,
        correctPercentage: 0
      }))
    });
  };
};

export const updateRealTimeData = (playerData: ServerMessage) => {
  return (dispatch: any) => {
    dispatch({
      type: REAL_TIME_UPDATE_PLAYER,
      payload: playerData
    });
  };
};

export const newUpdateRealTimeData = (playerData: NewServerMessage) => {
  return (dispatch: any) => {
    if (playerData) {
      dispatch({
        type: NEW_REAL_TIME_UPDATE_PLAYER,
        payload: playerData
      });
    }
  };
};

export const retrieveRealTimeData = (roster: Array<IClassRoster>) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const userIds = roster.map(student => student.educationUserId);
      dispatch(
        fetchApi({
          url: '/retrieve-players',
          method: APIMethods.POST,
          endpoint: APIEndpoints.REALTIME,
          data: { userIds }
        })
      )
        .then((response: any) => {
          if (response.data.length) {
            response.data.forEach((playerData: ServerMessage) =>
              dispatch({
                type: REAL_TIME_UPDATE_PLAYER,
                payload: playerData
              })
            );
          }
          resolve();
        })
        .catch((error: any) => Debug.log(error));
    });
  };
};

export const retrieveOnlinePlayers = (roster: Array<IClassRoster>) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const userIds = roster.map(student => student.educationUserId);
      dispatch(
        fetchApi({
          url: 'sw/player/online',
          method: APIMethods.POST,
          data: { userIds }
        })
      )
        .then((response: any) => {
          if (response.data.length) {
            response.data.forEach((playerData: ServerMessage) =>
              dispatch({
                type: REAL_TIME_UPDATE_PLAYER,
                payload: playerData
              })
            );
          }
          resolve();
        })
        .catch((error: any) => Debug.log(error));
    });
  };
};
