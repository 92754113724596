import {
  USE_CANCELLATION_TOKEN,
  UPDATE_CANCELLATION_DATA,
  INVALID_CANCELLATION_TOKEN
} from './cancellation-token-action-types';
import { Debug } from '../../utils/debug';
import { fetchApi } from '../actions/fetch-actions';
import store from '../store';
import { APIMethods } from '../../types/fetch/fetch-types';

export const fetchMembershipByCancellationToken = (token: string) => {
  return (dispatch: any) => {
    dispatch(
      fetchApi({
        url: 'sw/canceltoken/' + token,
        method: APIMethods.GET,
        ignore401: true
      })
    )
      .then((response: any) => {
        dispatch({
          type: USE_CANCELLATION_TOKEN,
          payload: response.data
        });
      })
      .catch((error: any) => {
        dispatch({
          type: INVALID_CANCELLATION_TOKEN
        });
        Debug.log(error);
      });
  };
};

export const updateCancellationData = (id: string) => {
  let cancellationData: any = store.getState().cancellationToken;
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_CANCELLATION_DATA,
      payload: cancellationData.filter((row: any) => row._id !== id)
    });
  };
};
