//@ts-ignore

export class GoogleTrackingCheckout {
  static trackCheckout(name: string, price: number, quantity: number) {
    dataLayer({
      event: 'checkout',
      ecommerce: {
        actionField: { step: 1 },
        checkout: {
          products: [
            {
              name,
              price,
              quantity, // INCREMENT ON THE NUMBER OF STUDENTS ADDED
              list: 'Parent Plans'
            }
          ]
        }
      }
    });
  }
  static trackCart(name: string, price: number, quantity: number) {
    dataLayer({
      event: 'addToCart',
      ecommerce: {
        currencyCode: undefined,
        add: {
          products: [
            {
              name,
              price,
              quantity,
              list: 'Parent Plans'
            }
          ]
        }
      }
    });
  }

  static trackProductImpression() {
    dataLayer({ ecommerce: null });
    dataLayer({
      event: 'productImpression',
      ecommerce: {
        impressions: [
          {
            name: 'Monthly Plan',
            price: '8.99',
            list: 'Parent Plans',
            position: 1
          },
          {
            name: 'Yearly Plan',
            price: '59.99',
            list: 'Parent Plans',
            position: 2
          }
        ]
      }
    });
  }

  /**
   * @description tracks purchase
   * @param name
   * @param price
   * @param quantity
   */
  static trackPurchase(
    id: string,
    name: string,
    price: number,
    quantity: number
  ) {
    dataLayer({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id,
            revenue: price * quantity,
            tax: 0,
            shipping: 0
          },
          products: [
            {
              name,
              price,
              quantity,
              category: 'Parent Plans'
            }
          ]
        }
      }
    });
  }
}

function dataLayer(params: any) {
  if (checkEnv()) {
    // @ts-ignore
    window.dataLayer.push(params);
  }
}

function checkEnv() {
  return [
    'dashboard.playdreamscape.com',
    'membership.playdreamscape.com'
    // eslint-disable-next-line no-restricted-globals
  ].includes(location.host);
}
