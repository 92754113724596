import React from 'react';
import { FreeTrialWrapper } from './FreeTrialWrapper';
import classMissionIcon from '../../assets/membership/classMissionIcon.png';
import reportingIcon from '../../assets/membership/reporting.png';
import rewardIcon from '../../assets/membership/rewardIcon.png';
import testPrepIcon from '../../assets/membership/testPreptIcon.png';
import assignmentsIcon from '../../assets/membership/assignmentsIcon.png';
import placementIcon from '../../assets/membership/placementIcon.png';
import gamePlayIcon from '../../assets/membership/gamePlay.png';
import useQueryParams from '../../hooks/UseQueryParams';
import mixpanel from '../../utils/mixpanel';
import { useHistory } from 'react-router-dom';
import { triggerMixpanelEvent } from '../../redux/actions/user-actions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import { AxiosResponse } from 'axios';
import { hide } from 'redux-modal';

const premiumPropertiesArray = [
  {
    id: 0,
    imgUrl: assignmentsIcon,
    boldDescription: 'Unlimited',
    description: 'access to the Shoelace platform and all games'
  },
  {
    id: 1,
    imgUrl: reportingIcon,
    boldDescription: 'Boost',
    description: 'home and summer learning'
  },
  {
    id: 2,
    imgUrl: placementIcon,
    boldDescription: 'Comprehensive',
    description: 'player reporting'
  },
  {
    id: 3,
    imgUrl: classMissionIcon,
    boldDescription: 'Unlimited',
    description: 'Class Missions'
  },
  {
    id: 5,
    imgUrl: testPrepIcon,
    boldDescription: 'Premium',
    description: 'test prep and assignments'
  },
  {
    id: 6,
    imgUrl: gamePlayIcon,
    boldDescription: 'Exclusive',
    description: 'early access to new games'
  }
];

const PremiumFreeTrial: React.FC<any> = () => {
  const history = useHistory();
  const query = useQueryParams();
  const dispatch = useDispatch();

  const userData = useSelector((state: any) => state.userData);

  const buttonClickHandler = () => {
    if (query.mandatory === 'false') {
      dispatch(
        triggerMixpanelEvent(
          'Onboarding - Start Optional Trial Button Clicked',
          userData._id
        )
      );
    }
    if (query.mandatory === 'true') {
      dispatch(
        triggerMixpanelEvent(
          'Onboarding - Start Mandatory Trial Button Clicked',
          userData._id
        )
      );
    }
    dispatch(
      fetchApi({
        url: 'stripe/checkout',
        method: APIMethods.POST,
        data: {
          membershipType: 'Premium29',
          memberships: 0,
          isFreeTrial: true
        }
      })
    )
      .then((response: AxiosResponse) => {
        const { url } = response.data;
        window.location.href = url;
        dispatch(hide('spinner'));
      })
      .catch((err: any) => {
        console.log(err);
        console.log('error redirecting');
        dispatch(hide('spinner'));
      });
  };

  return (
    <FreeTrialWrapper
      headerText="Unlock Shoelace"
      isMandatoryFreeTrial={query.mandatory === 'true' ? true : false}
      isUpsellScreen={false}
      heading="Free 14-day trial then USD$29/year"
      subHeading="Just $2.42 per month"
      buttonText="Start your free trial"
      premiumPropertiesArray={premiumPropertiesArray}
      buttonClickHandler={buttonClickHandler}
    />
  );
};

export default PremiumFreeTrial;
