import React, { useContext, useState } from 'react';
import ModalAbstract from '../../../../../components/popups/modals/ModalAbstract';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../../styles/colors';
import { Button } from '../../button/Button';
import { Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { grades } from '../../../../../constants/global-constants';
import CustomInput from '../../input/CustomInput';
import { useDispatch } from 'react-redux';
import { AssignmentEditState } from '../../../pages/edit/state/assignment-edit.types';
import { AssignmentActions } from '../../../pages/edit/state/assignment/assignment.actions';

const useStyles = makeStyles({
  root: {
    color: colors.assignment.text
  },
  input: {
    marginBottom: '10px'
  },
  label: {
    display: 'block',
    marginBottom: '10px'
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

interface AddAssignmentModalProps {
  show: boolean;
  handleHide: (...args: any) => any;
  handleClose: (isAssignmentCreated: boolean) => any;
  handleSubmit: (name: string, grade: number) => any;
  isEdit: boolean;
  assignment: AssignmentEditState;
  title: string;
  duplicate?: boolean;
}

const AddAssignmentModal: React.FC<AddAssignmentModalProps> = (
  props: AddAssignmentModalProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    show,
    handleClose,
    handleHide,
    assignment,
    isEdit,
    title,
    duplicate
  } = props;
  const [isValid, setIsValid] = useState<boolean>(duplicate || false);
  const [assignmentEdit, setAssignmentEdit] = useState({
    name: assignment.name,
    grade: assignment.grade
  });
  const handleInput = (evt: any) => {
    evt.preventDefault();
    const name = evt.target.name;
    const value = evt.target.value;
    if (name === 'name') {
      value.trim();
    }
    const newAssignment = {
      ...assignmentEdit,
      [name]: value
    };
    handleValidation(newAssignment.name, newAssignment.grade);
    setAssignmentEdit(newAssignment);
    if (!duplicate) {
      dispatch(AssignmentActions.updateAssignment({ ...newAssignment }));
    }
  };

  const handleValidation = (name: string, grade: number): boolean => {
    let isDataValid = true;
    if (!name || name == '') {
      isDataValid = false;
    }
    if (!grade || !(grade > 0)) {
      isDataValid = false;
    }
    setIsValid(isDataValid);
    return isDataValid;
  };

  const handleSubmit = () => {
    // @TODO route to new assignment created with id or close modal
    if (handleValidation(assignmentEdit.name, assignmentEdit.grade)) {
      props.handleSubmit(assignmentEdit.name, assignmentEdit.grade);
      props.handleHide(true);
    }
  };

  const handleModalClose = (isAssignmentCreated: boolean) => {
    if (!duplicate) {
      handleClose(isAssignmentCreated);
    }
    dispatch(AssignmentActions.updateAssignment({ ...assignment }));
    handleHide();
  };

  const { name, grade } = assignmentEdit;
  let modalTitle = isEdit ? 'Update Assignment' : 'Create New Assignment';
  if (duplicate) {
    modalTitle = title;
  }
  return (
    <ModalAbstract show={show}>
      <DialogTitle
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <b style={{ marginRight: '4rem' }}>{modalTitle}</b>
        <IconButton
          onClick={() => handleModalClose(false)}
          className={classes.close}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.root} dividers>
        <form onSubmit={handleSubmit}>
          <div>
            <label className={classes.label} htmlFor="assignmentName">
              Name{' '}
            </label>
            <CustomInput
              id="assignmentName"
              className={classes.input}
              type="text"
              name={'name'}
              value={name}
              onChange={handleInput}
            />
          </div>

          <label className={classes.label} htmlFor="grade">
            Grade
          </label>
          <Select
            name="grade"
            id="grade"
            fullWidth={true}
            variant={'outlined'}
            value={grade}
            color={'primary'}
            onChange={handleInput}
            input={<CustomInput />}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={-1}>
              <em>- Select Grade -</em>
            </MenuItem>
            {grades.map((grade: number) => (
              <MenuItem key={grade} value={grade}>{`Grade ${grade}${
                grade == 8 ? '+' : ''
              }`}</MenuItem>
            ))}
          </Select>
        </form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          disabled={!isValid}
          color={colors.assignment.blue}
          textColor={'white'}
          onClick={handleSubmit}
          variant={'filled'}
        >
          {duplicate ? 'Duplicate' : isEdit ? 'Update' : 'Start'}
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default AddAssignmentModal;
