import React from 'react';
import { Grid, FormControlLabel, Button, Checkbox } from '@material-ui/core';

interface Student {
  id: string;
  firstname: string;
  lastname: string;
  selected?: boolean;
}

interface StudentSelectionProps {
  description: string;
  students: Array<Student>;
  allSelected: boolean;
  handleSelectAllClick: () => void;
  classes: {
    selectAllButton: any;
  };
  handleCheckStudent: (id: string, event: any) => void;
}

const StudentSelection: React.FC<StudentSelectionProps> = (
  props: StudentSelectionProps
) => {
  return (
    <div>
      <p>{props.description}</p>
      <Button
        onClick={() => props.handleSelectAllClick()}
        className={props.classes.selectAllButton}
      >
        {props.allSelected ? 'Unselect All' : 'Select All'}
      </Button>
      <div>
        {props.students.map((student: any) => (
          <>
            <Grid container spacing={1}>
              <Grid container item xs={12} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={student.selected}
                      onChange={(event: any) =>
                        props.handleCheckStudent(student._id, event)
                      }
                      value={student.selected}
                      color="primary"
                    />
                  }
                  label={`${student.firstname} ${student.lastname}`}
                />
              </Grid>
            </Grid>
          </>
        ))}
      </div>
    </div>
  );
};

export default StudentSelection;
