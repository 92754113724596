import React from 'react';
import AppStoreBadge from '../../assets/icons/app-store-badge.png';
import PlayStoreBadge from '../../assets/icons/google-play-badge.png';
import { makeStyles, Grid } from '@material-ui/core';
import Button from '../button/Button';
import { useDispatch } from 'react-redux';
import { trackPlatformLinkClick } from '../../redux/actions/mixpanel-actions';

interface GamePlatformLinksProps {
  style?: React.CSSProperties;
}

const useStyles = makeStyles(() => ({
  badge: {
    cursor: 'pointer',
    width: 110
  }
}));

const Links = {
  WebGL: process.env.REACT_APP_WEBGL_LINK,
  AppStore: process.env.REACT_APP_APP_STORE_LINK,
  PlayStore: process.env.REACT_APP_PLAY_STORE_LINK
};

const GamePlatformLinks: React.FC<GamePlatformLinksProps> = (
  props: GamePlatformLinksProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpenLink = (link: string) => {
    window.open(link, '_blank');
    const name = Object.keys(Links).filter((key: any) => {
      //@ts-ignore
      return Links[key] === link;
    })[0];
    dispatch(trackPlatformLinkClick({ link: name }));
  };

  return (
    <Grid container justify="center" style={props.style}>
      <Button blue onClick={() => handleOpenLink(Links.WebGL)}>
        Web
      </Button>
      <img
        src={AppStoreBadge}
        className={classes.badge}
        onClick={() => handleOpenLink(Links.AppStore)}
      />
      <img
        src={PlayStoreBadge}
        className={classes.badge}
        onClick={() => handleOpenLink(Links.PlayStore)}
      />
    </Grid>
  );
};

export default GamePlatformLinks;
