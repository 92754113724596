import {
  AssignmentEditAnswerActionTypes,
  AssignmentEditQuestionActionTypes,
  AssignmentEditQuestionBankActionTypes
} from './question/assignment-question-action.types';
import { AssignmentEditActionTypes } from './assignment/assignment-action.types';

export enum AssignmentQuestionType {
  Passage = 'Passage',
  Independent = 'Independent'
}

export type AssignmentEditState = {
  questions?: any;
  isValid: boolean;
  viewer?: boolean;
  id?: string | number;
  name: string;
  grade: number;
  description: string;
  data: AssignmentData[];
  shuffleQuestions: boolean;
  shuffleAnswers: boolean;
  skills: any[];
  groups: any[];
  genres: any[];
  questionTypes: any[];
  dashboardUserId: number;
  startDate: Date;
  isAssigned: boolean;
  status: AssignmentEditStatus;
  endDate: Date;
  updatedAt: Date;
  createdAt: Date;
  autoSave?: {
    saving?: boolean;
    isEdited?: boolean;
  };
  edit?: {
    saving: boolean;
  };
};

export type AssignmentEmptyData = {
  id: string;
  type: null | undefined;
};

export type AssignmentError = {
  isError: boolean;
  errors: Map<string, AssignmentErrorDetails>;
};

export type AssignmentErrorDetails = {
  message: string;
  field: string;
};

export type QuestionBankIndependentQuestion = {
  type: AssignmentQuestionType.Independent;
  id: string;
  question: string;
  answers: AssignmentQuestionAnswer[];
  feedbackIncorrect?: string;
  isEdited: boolean;
  format?: number;
  isFromQuestionBank: boolean;
  skillId?: number;
  error: AssignmentError;
  syncedId: number;
  updatedAt: Date;
  createdAt: Date;
  isDeleted?: boolean;
  isSynced?: boolean;
};

export type QuestionBankPassage = {
  id: string;
  type: AssignmentQuestionType.Passage;
  passage: string;
  error: AssignmentError;
  syncedId: number;
  passageTitle: string;
  isFromQuestionBank: boolean;
  genreId: number;
  questions: QuestionBankPassageQuestion[];
};

export type QuestionBankPassageQuestion = {
  isFromQuestionBank: boolean;
  isDeleted?: boolean;
  passageId?: number;
  updatedAt: Date;
  isEdited: boolean;
  syncedId: number;
  error: AssignmentError;
  createdAt: Date;
  id: string;
  question: string;
  answers: AssignmentQuestionAnswer[];
  format?: number;
  feedbackIncorrect?: string;
  skillId?: number;
  isSynced?: boolean;
};

export type AssignmentIndependentQuestion = {
  type: AssignmentQuestionType.Independent;
  id: string;
  question: string;
  answers: AssignmentQuestionAnswer[];
  feedbackIncorrect?: string;
  isEdited: boolean;
  format?: number;
  skillId?: number;
  error: AssignmentError;
  updatedAt: Date;
  createdAt: Date;
  isDeleted?: boolean;
  isSynced?: boolean;
};

export type AssignmentPassageQuestion = {
  syncedId: number;
  isDeleted?: boolean;
  passageId?: number;
  updatedAt: Date;
  isEdited: boolean;
  error: AssignmentError;
  createdAt: Date;
  id: string;
  question: string;
  answers: AssignmentQuestionAnswer[];
  format?: number;
  feedbackIncorrect?: string;
  skillId?: number;
  isSynced?: boolean;
};

export type AssignmentQuestion = {
  isEditMode?: boolean;
  isSynced?: boolean;
  isDeleted?: boolean;
  id: string;
  question: string;
  error: AssignmentError;
  answers: AssignmentQuestionAnswer[];
  format?: number;
  feedbackIncorrect?: string;
  skillId: number;
  questionType?: string;
};

export type AssignmentQuestionAnswer = {
  id: string;
  isCorrect: boolean;
  text: any;
  pair?: any;
};

export type AssignmentQuestionFormat =
  | 'Select All'
  | 'Drag and Drop'
  | 'Multiple Choice';

export type AssignmentPassage = {
  id: string;
  type: AssignmentQuestionType.Passage;
  passage: string;
  error: AssignmentError;
  passageTitle: string;
  genreId: number;
  questions: AssignmentPassageQuestion[];
  imgUrl: string;
};

export type AssignmentData =
  | AssignmentEditState
  | AssignmentIndependentQuestion
  | AssignmentPassage
  | any;

export type DispatchProps = {
  autoSave?: {
    saving: boolean;
    isEdited: boolean;
  };
  type:
    | AssignmentEditActionTypes
    | AssignmentEditQuestionActionTypes
    | AssignmentEditQuestionBankActionTypes
    | AssignmentEditAnswerActionTypes;
  payload: AssignmentData;
};

export enum AssignmentEditStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type IAssignmentEditContext = {
  assignmentEdit: AssignmentEditState;
  setAssignmentEdit: any;
};
