import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReportToolTemplate from './ReportToolTemplate';
import ReportToolQuestionsIcon from '../../assets/icons/report/questions-icon.png';
import UniversityIcon from '../../assets/icons/university-icon.png';
import HouseIcon from '../../assets/icons/report/large-house.png';
import { trackViewQuestionsReport } from '../../redux/actions/mixpanel-actions';
import { Grid, Typography, IconButton, Paper } from '@material-ui/core';
import colors from '../../styles/colors';
import moment from 'moment';
import { Statuses, ReportTools } from '../../redux/features/ReportTool';
import LineChart from '../chart/LineChart';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { ResponsiveContainer, Line } from 'recharts';
import { ZoomOutMap } from '@material-ui/icons';
import { show } from 'redux-modal';
import { MODAL_DEFAULT } from '../../constants/modals';
import { ClassActivityReportTable } from './ReportToolClassActivity';
import { useAppSelector } from '../../redux/hooks';

interface ReportToolQuestionsProps {
  isWidget?: boolean;
}

const textStyle = {
  textAlign: 'center' as const
};

export const ReportToolQuestionsGraph = (props: any) => {
  const dispatch = useDispatch();
  const features = useAppSelector(state => state.featureFlag);
  const data = props.data.map((item: any) => {
    return {
      date: features.newDashboardFlag
        ? new Date(item.date).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        : moment(item.createdAt)
            .utc()
            .format('ll'),
      total: item.totalNumberOfAnswers,
      correct: item.totalNumberOfCorrectAnswers,
      incorrect: item.totalNumberOfAnswers - item.totalNumberOfCorrectAnswers
    };
  });
  const chart = (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart margin={{ right: 30, bottom: 30 }} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <Legend />
        <YAxis type="number" dataKey="total" />
        <XAxis type="category" dataKey="date" />
        <Tooltip />
        <Bar maxBarSize={150} dataKey="correct" fill="#63DA8B" name="Correct" />
        <Bar
          maxBarSize={150}
          dataKey="incorrect"
          fill="#DA6398"
          name="Incorrect"
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <IconButton
          onClick={() =>
            dispatch(
              show('modal', {
                children: chart,
                fullWidth: true,
                maxWidth: 'xlg',
                type: MODAL_DEFAULT
              })
            )
          }
        >
          <ZoomOutMap />
        </IconButton>
      </div>
      {chart}
    </>
  );
};

const ReportToolQuestions: React.FC<ReportToolQuestionsProps> = ({
  isWidget
}: ReportToolQuestionsProps) => {
  let { data, startDate, endDate, status } = useSelector(
    (state: any) => state.reportTool[ReportTools.Questions]
  );
  const reportToolHasLoaded = status === Statuses.Success;
  const body =
    reportToolHasLoaded &&
    (isWidget ? (
      <>
        {data.map((statistics: any, key: number) => {
          const {
            locationWhereQuestionWasAnswered,
            totalNumberOfAnswers,
            totalNumberOfCorrectAnswers
          } = statistics;

          return (
            <>
              <Grid
                container
                item
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                key={`stat-${key}`}
              >
                <Grid
                  container
                  item
                  direction="column"
                  xs={4}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: 21,
                        fontWeight: 600,
                        marginBottom: 10
                      }}
                    >
                      {locationWhereQuestionWasAnswered}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src={
                        locationWhereQuestionWasAnswered === 'School'
                          ? UniversityIcon
                          : HouseIcon
                      }
                      alt="University Icon"
                      style={{ height: 75 }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={4}
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item style={textStyle}>
                    <Typography
                      variant="h3"
                      style={{ color: colors.third, fontWeight: 600 }}
                    >
                      {totalNumberOfCorrectAnswers || 0}
                    </Typography>
                    <Typography>Correct</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={4}
                  alignItems="center"
                >
                  <Grid item style={textStyle}>
                    <Typography
                      variant="h3"
                      style={{ color: colors.secondary, fontWeight: 600 }}
                    >
                      {totalNumberOfAnswers - totalNumberOfCorrectAnswers || 0}
                    </Typography>
                    <Typography>Incorrect</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {key === 0 && (
                <hr style={{ borderTop: '1px solid #dcdcdc', width: '100%' }} />
              )}
            </>
          );
        })}
      </>
    ) : null);

  return (
    <ReportToolTemplate
      title="Questions"
      reportToolState={[ReportTools.Questions]}
      reportURL="class-activity"
      subTitle="Last 7 Days"
      body={body}
      tracker={trackViewQuestionsReport}
      source={ReportToolQuestionsIcon}
      isWidget={isWidget}
    />
  );
};

export default ReportToolQuestions;
