import { SkillResponse } from '../../../types/reports/skill-report-types';

interface SkillReportBuilderOptions {
  grade: number;
  groupKey?: string;
}

export function buildReport(
  data: Array<SkillResponse>,
  options?: SkillReportBuilderOptions
) {
  let groupKey = 'skill';
  const reportData: any = {};
  if (options) {
    if (options.grade >= 1) {
      data = data.filter(item => {
        if (typeof item.gradeId === 'string') {
          const currentGrade = JSON.parse(item.gradeId);
          return currentGrade[0] === options.grade;
        } else {
          return item.gradeId === options.grade;
        }
      });
    }
    if (options.groupKey) {
      groupKey = options.groupKey;
    }
  }
  data.forEach(item => {
    const { accuracy, correct, incorrect, total, userId } = item;
    const key = item[groupKey];
    if (reportData[key]) {
      reportData[key].totalEntries += 1;
      reportData[key].correct += correct;
      reportData[key].incorrect += incorrect;
      reportData[key].accuracy += accuracy;
      reportData[key].total += total;
      if (!reportData[key].studentIds.includes(userId)) {
        reportData[key].studentIds.push(userId);
      }
    } else {
      reportData[key] = {
        ...item,
        totalEntries: 1,
        studentIds: [userId]
      };
    }
  });

  return Object.keys(reportData)
    .map((key: string) => {
      const item = reportData[key];
      return {
        ...item,
        accuracy: (item.correct / item.total) * 100
      };
    })
    .sort((a, b) => {
      if (a.accuracy > b.accuracy) return 1;
      if (a.accuracy < b.accuracy) return -1;
      return 0;
    });
}
