import React, { useState } from 'react';
import colors from '../../../styles/colors';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import Button from '../../../components/button/Button';
import AccountImage from '../../../assets/icons/account_icon.png';
import NoAccountImage from '../../../assets/icons/no_account_icon.png';

const useStyles = makeStyles(() => ({
  option: {
    alignItems: 'center',
    border: `2px solid ${colors.border}`,
    borderRadius: 4,
    cursor: 'pointer',
    margin: '20px auto',
    padding: 10,
    position: 'relative',
    textAlign: 'left',
    transition: '0.3s',
    width: '90%',

    '&:hover': {
      border: `2px solid ${colors.primary}`
    }
  },
  optionSelected: {
    border: `2px solid ${colors.primary}`
  },
  image: {
    display: 'block',
    margin: '0 auto',
    width: 70
  },
  checkIcon: {
    background: colors.primary,
    borderRadius: '50%',
    color: colors.white,
    height: 32,
    padding: 5,
    position: 'absolute',
    right: -10,
    top: -10,
    width: 32
  },
  optionTitle: {
    fontWeight: 500
  }
}));

enum Options {
  IHave,
  IDont
}

interface HaveAccountPromptProps {
  handleContinueClick: (selectedOption: any) => void;
}

const HaveAccountPrompt: React.FC<HaveAccountPromptProps> = (
  props: HaveAccountPromptProps
) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(Options.IHave);

  return (
    <>
      <Typography variant="h5" component="h3" style={isMobile ? { marginTop: 'auto', height: '100%' } : {marginBottom: 30 }}>
        Do you have a Dreamscape account?
      </Typography>

      <Grid
        container
        spacing={4}
        className={clsx(
          classes.option,
          selected === Options.IHave && classes.optionSelected
        )}
        onClick={() => setSelected(Options.IHave)}
      >
        {selected === Options.IHave && (
          <div className={classes.checkIcon}>
            <Check />
          </div>
        )}
        <Grid item md={3} container justify="center">
          <img
            src={AccountImage}
            className={classes.image}
            alt="I have an account"
          />
        </Grid>
        <Grid item md={8}>
          <Typography component="h5" className={classes.optionTitle}>
            I already have a Dreamscape account.
          </Typography>
          <Typography>
            Enable Google sign-in for an existing Dreamscape account.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        className={clsx(
          classes.option,
          selected === Options.IDont && classes.optionSelected
        )}
        onClick={() => setSelected(Options.IDont)}
      >
        {selected === Options.IDont && (
          <div className={classes.checkIcon}>
            <Check />
          </div>
        )}
        <Grid item md={3} container justify="center">
          <img
            src={NoAccountImage}
            className={classes.image}
            alt="I don't have an account"
          />
        </Grid>
        <Grid item md={8}>
          <Typography component="h5" className={classes.optionTitle}>
            I want a new Dreamscape account.
          </Typography>
          <Typography>
            Create a new Dreamscape account using your Google account.
          </Typography>
        </Grid>
      </Grid>

      <Button primary onClick={() => props.handleContinueClick(selected)}>
        Continue
      </Button>
    </>
  );
};

export default HaveAccountPrompt;
