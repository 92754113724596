export enum LocalStorage {
  MEMBERHSHIP_BANNER = 'user:membershipbanner',
  SALES_BANNER = 'user:salesbanner',
  TERMS = 'user:terms',
  SUMMER_NOTIFICATION = 'user:summer_notification',
  CLASS_MISSIONS_TRIAL = 'user:class_mission_trials',
  CLASS_MISSIONS_TRIAL_EXTENDED = 'user:class_mission_trials_extended',
  PLACEMENT_TEST_WARNING = 'user:placement_test_warning',
  PLAYER_LOGINS_INFO = 'user:player_logins_info',
  HIDE_NEW_LE_OPT_IN_POPUP = 'user:hide_new_le_opt_in_popup'
}
