import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '../button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';
import { MODAL_ADD_STUDENT, MODAL_CLEVER } from '../../constants/modals';
import { Grid } from '@material-ui/core';
import {
  downloadPDF,
  updateClassRegistationStep
} from '../../redux/actions/class-actions';
import { push } from 'connected-react-router';
import { teacherRoutes, parentRoutes } from '../../constants/routes';
import { userTypes } from '../../constants/users';
import { useRouteMatch } from 'react-router';
import AddStudent from '../../assets/icons/popups/onboarding_imgs_02.png';
import PlayDreamscpe from '../../assets/icons/popups/onboarding_imgs_03.png';
import ViewReports from '../../assets/icons/popups/onboarding_imgs_04.png';
import GamePlatformLinks from '../common/GamePlatformLinks';
import { playerNounUppercase } from '../../utils/userNoun';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  alternativeLabel: {
    top: '28px'
  },
  title: {
    fontWeigth: 500,
    alignment: 'center',
    padding: '10px 0px 15px 0px'
  },
  alignment: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '750px',
    marginBottom: '40px'
  },
  regButton: {
    marginTop: 15,
    padding: '5px 40px'
  }
}));

interface RegistrationProgressProps {
  currentClass: any;
}

enum Steps {
  addStudent = 0,
  playDreamscape = 1,
  viewReport = 2,
  complete
}

const RegistrationProgress: React.FC<RegistrationProgressProps> = (
  props: RegistrationProgressProps
) => {
  const { currentClass } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  let currentStep = Steps.addStudent;
  const match: any = useRouteMatch();

  const userData = useSelector((state: any) => state.userData);
  const { userType } = userData;

  let firstStepTitle = 'Add Players';
  let studentType = 'player';
  let studentTypePlural = 'players';
  let viewReportText = 'View Class Reports';
  if (userType === userTypes.parent) {
    firstStepTitle = 'Add Player';
    studentType = 'player';
    studentTypePlural = 'player';
    viewReportText = `View ${playerNounUppercase} Report`;
  }

  if (currentClass.registrationStep === 4) {
    return null;
  } else if (currentClass.activePlayers.length) {
    currentStep = Steps.viewReport;
  } else if (currentClass.roster.length) {
    currentStep = Steps.playDreamscape;
  }

  const getSteps = () => {
    return [firstStepTitle, 'Play Dreamscape', 'View Report'];
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case Steps.addStudent:
        return (
          <>
            <div className={classes.alignment}>
              <img
                src={AddStudent}
                alt={'Add Student'}
                style={{ width: '27%', minWidth: '130px' }}
              />
              <Typography component="p" className={classes.title}>
                Add a {studentType} and start playing!
              </Typography>
              <Typography component="p" style={{ textAlign: 'justify' }}>
                Once you add your {studentTypePlural}, give them Dreamscape play
                time and monitor their reading progress.
              </Typography>
              <Grid item container lg={12} justify="center">
                <Button
                  primary
                  onClick={() =>
                    dispatch(show('modal', { type: MODAL_ADD_STUDENT }))
                  }
                  className={classes.regButton}
                >
                  Add {studentType}
                </Button>
                {userData.clever ? (
                  <>
                    <Typography
                      component="span"
                      style={{ margin: '15px 10px 0' }}
                    >
                      or
                    </Typography>
                    <Button
                      primary
                      onClick={() =>
                        dispatch(
                          show('modal', {
                            type: MODAL_CLEVER,
                            classCode: match!.params.classCode
                          })
                        )
                      }
                      className={classes.regButton}
                    >
                      Sync with Clever
                    </Button>
                  </>
                ) : null}
              </Grid>
            </div>
          </>
        );
      case Steps.playDreamscape:
        return (
          <>
            <div className={classes.alignment}>
              <img
                src={PlayDreamscpe}
                alt={'Play dreamscape'}
                style={{ width: '28%', minWidth: '130px' }}
              />
              <Typography component="p" className={classes.title}>
                Ready, Set, Read!
              </Typography>

              <Typography
                component="p"
                style={{ textAlign: 'center', marginBottom: 10 }}
              >
                It's time for your {studentTypePlural} to log into Dreamscape
                using one of the following platforms:
                <GamePlatformLinks style={{ marginTop: 20 }} />
              </Typography>
              {userType !== userTypes.parent ? (
                <Grid item container lg={12} justify="center">
                  <Button
                    primary
                    onClick={() =>
                      dispatch(downloadPDF(userData, currentClass))
                    }
                    className={classes.regButton}
                  >
                    View and print your players' login codes
                  </Button>
                </Grid>
              ) : null}
            </div>
          </>
        );
      case Steps.viewReport:
        return (
          <div className={classes.alignment}>
            <img
              src={ViewReports}
              alt={'reports'}
              style={{ width: '28%', minWidth: '130px' }}
            />
            <Typography component="p" className={classes.title}>
              Data you can use!
            </Typography>
            <Typography component="p" style={{ textAlign: 'justify' }}>
              Now that your {studentTypePlural} have started playing, you can
              view their progress and use the data to inform classroom
              instruction.
            </Typography>
            <Grid item container lg={12} justify="center">
              <Button
                primary
                onClick={() => {
                  const route =
                    userType === userTypes.parent
                      ? parentRoutes.reports
                      : teacherRoutes.classReports;
                  dispatch(push(route));
                  dispatch(updateClassRegistationStep(4, currentClass.code));
                }}
                className={classes.regButton}
              >
                {viewReportText}
              </Button>
            </Grid>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  const steps = getSteps();
  return (
    <Paper>
      <div className={classes.root}>
        <Stepper alternativeLabel activeStep={currentStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps.map((label, index) => (
          <div
            key={index}
            style={{ display: currentStep === index ? 'block' : 'none' }}
          >
            <Typography
              style={{
                padding: '20px 15px 40px 15px'
              }}
              component="div"
            >
              {getStepContent(index)}
            </Typography>
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default RegistrationProgress;
