export const parentMenu = 'PARENT_MENU';
export const teacherMenu = 'TEACHER_MENU';
export const schoolAdminMenu = 'SCHOOL_MENU';
export const districtAdminMenu = 'DISTRICT_MENU';

export type menuTypes =
  | 'PARENT_MENU'
  | 'TEACHER_MENU'
  | 'SCHOOL_MENU'
  | 'DISTRICT_MENU'
  | undefined;
