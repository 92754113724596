import {
  JOB_QUEUE_UPDATE_ACTIVE,
  JOB_QUEUE_SHOULD_WATCH,
  JOB_QUEUE_ADD,
  JOB_QUEUE_RESET_JOBS_PROCESSED
} from '../types/job-queue/job-queue-action-types';
import JobQueueState from '../../types/state/job-queue-state';

const initialState: JobQueueState = {
  activeJobs: [],
  jobsProcessed: [],
  shouldWatchForUpdates: false
};

export const jobQueueReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case JOB_QUEUE_UPDATE_ACTIVE:
      return {
        ...state,
        activeJobs: payload
      };
    case JOB_QUEUE_ADD:
      return {
        ...state,
        activeJobs: [...state.activeJobs, payload],
        jobsProcessed: [...state.jobsProcessed, payload._id]
      };
    case JOB_QUEUE_SHOULD_WATCH:
      return {
        ...state,
        shouldWatchForUpdates: payload
      };
    case JOB_QUEUE_RESET_JOBS_PROCESSED:
      return {
        ...state,
        jobsProcessed: []
      };
    default:
      return state;
  }
};
