import { commonRoutes, teacherRoutes } from '../../constants/routes';
import { Features } from '../../feature-flag/Features';
import AppBarLayout from '../../layouts/AppBarLayout';
import { LazyLoadedModules, lazyLoadModule } from '../../features/lazy-loading';

const lazyLoadTeacherComponent = lazyLoadModule(
  LazyLoadedModules.TeacherModule
);

const lazyLoadClassComponent = lazyLoadModule(LazyLoadedModules.ClassModule);

const Account = lazyLoadTeacherComponent('Account');
const AssignmentEditView = lazyLoadTeacherComponent('AssignmentEditView');
const BillingView = lazyLoadTeacherComponent('BillingView');
const ClassMissionsGameLayout = lazyLoadTeacherComponent(
  'ClassMissionsGameLayout'
);
const MembershipPurchaseSuccess = lazyLoadTeacherComponent(
  'MembershipPurchaseSuccess'
);
const MyAssignmentsView = lazyLoadTeacherComponent('MyAssignmentsView');
const NoLayout = lazyLoadTeacherComponent('NoLayout');
const ResourceInfoView = lazyLoadTeacherComponent('ResourceInfoView');
const ResourceView = lazyLoadTeacherComponent('ResourceView');
const TeacherHome = lazyLoadTeacherComponent('TeacherHome');
const TeacherLayout = lazyLoadTeacherComponent('TeacherLayout');

const StudentsView = lazyLoadClassComponent('StudentsView');
const ClassView = lazyLoadClassComponent('ClassView');
const ReportsView = lazyLoadClassComponent('ReportsView');
const ReadingLevelReportView = lazyLoadClassComponent('ReadingLevelReportView');
const SkillComprehensionReportView = lazyLoadClassComponent(
  'SkillComprehensionReportView'
);
const PlannerReportView = lazyLoadClassComponent('PlannerReportView');
const MembershipView = lazyLoadClassComponent('MembershipView');
const PlannerView = lazyLoadClassComponent('PlannerView');
const AssignmentReportView = lazyLoadClassComponent('AssignmentReportView');
const RewardsPage = lazyLoadClassComponent('RewardsPage');
const PremiumPlanPage = lazyLoadClassComponent('PremiumPlanPage');
const ReportToolLeaderboard = lazyLoadClassComponent('ReportToolLeaderboard');
const LELeaderboardReportView = lazyLoadClassComponent(
  'LELeaderboardReportView'
);
const ReportToolQuestions = lazyLoadClassComponent('ReportToolQuestions');
const PlacementTestReportView = lazyLoadClassComponent(
  'PlacementTestReportView'
);
const ReportToolClassActivity = lazyLoadClassComponent(
  'ReportToolClassActivity'
);
const UserGeneratedAssignmentReportsView = lazyLoadClassComponent(
  'UserGeneratedAssignmentReportsView'
);
const ClassMissionsView = lazyLoadClassComponent('ClassMissionsView');
const ClassMissionReportView = lazyLoadClassComponent('ClassMissionReportView');
const MembershipCheckout = lazyLoadClassComponent('MembershipCheckout');

const RealTimeView = lazyLoadTeacherComponent('RealTimeWrapper');
const NewLEClassActivityReportView = lazyLoadClassComponent(
  'NewLEClassActivityReportView'
);
const ClassMissionsGameView = lazyLoadClassComponent(
  'ClassMissionsGameViewWrapper'
);

/**
 * @description slices up user generated routes
 * @param layout
 */
const userGeneratedContent = (layout: any) => {
  return [
    {
      path: teacherRoutes.userGeneratedAssignments,
      layout,
      beta: true,
      featureName: Features.USER_GENERATED_CONTENT,
      component: MyAssignmentsView
    },
    {
      path: teacherRoutes.userGeneratedAssignmentsEdit,
      layout,
      featureName: Features.USER_GENERATED_CONTENT,
      beta: true,
      component: AssignmentEditView
    },
    {
      path: teacherRoutes.userGeneratedAssignmentsEditNew,
      featureName: Features.USER_GENERATED_CONTENT,
      layout,
      beta: true,
      component: AssignmentEditView
    }
  ];
};

const resourceContent = (layout: any) => {
  return [
    {
      path: teacherRoutes.resourceHub,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceView
    },
    {
      path: teacherRoutes.resourceHubInfo,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceInfoView
    },
    {
      path: teacherRoutes.resourceHubClass,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceView
    },
    {
      path: teacherRoutes.resourceHubInfoClass,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceInfoView
    }
  ];
};

export default [
  {
    path: teacherRoutes.homeScreen,
    layout: TeacherLayout,
    component: TeacherHome
  },
  {
    path: commonRoutes.billingPage,
    layout: NoLayout,
    component: BillingView
  },
  {
    path: teacherRoutes.classDashboard,
    layout: TeacherLayout,
    component: ClassView
  },
  {
    path: teacherRoutes.classStudent,
    layout: TeacherLayout,
    component: StudentsView
  },
  {
    path: teacherRoutes.rewardsPage,
    layout: TeacherLayout,
    component: RewardsPage
  },
  {
    path: teacherRoutes.classReports,
    layout: TeacherLayout,
    component: ReportsView
  },
  {
    path: teacherRoutes.classReportsReadingLevel,
    layout: TeacherLayout,
    component: ReadingLevelReportView
  },
  {
    path: teacherRoutes.classReportsPlacementTest,
    layout: TeacherLayout,
    component: PlacementTestReportView
  },
  {
    path: teacherRoutes.classReportsSkills,
    layout: TeacherLayout,
    component: SkillComprehensionReportView
  },
  {
    path: teacherRoutes.classReportsAssignmentTool,
    layout: TeacherLayout,
    component: PlannerReportView
  },
  {
    path: teacherRoutes.assignmentReportClassView,
    layout: TeacherLayout,
    component: UserGeneratedAssignmentReportsView
  },
  {
    path: teacherRoutes.classPlanner,
    layout: TeacherLayout,
    component: PlannerView
  },
  {
    path: teacherRoutes.classAssignmentReport,
    layout: TeacherLayout,
    component: AssignmentReportView
  },
  {
    path: teacherRoutes.assignmentReportView,
    layout: TeacherLayout,
    component: UserGeneratedAssignmentReportsView
  },
  {
    path: teacherRoutes.assignmentRootReportView,
    layout: TeacherLayout,
    component: AssignmentReportView
  },
  {
    path: teacherRoutes.membershipsAll,
    layout: TeacherLayout,
    component: MembershipView
  },
  {
    path: teacherRoutes.membershipsClass,
    layout: TeacherLayout,
    component: MembershipView
  },
  {
    path: teacherRoutes.realTimeDashboard,
    layout: AppBarLayout,
    component: RealTimeView
  },
  {
    path: teacherRoutes.membershipPurchasedSuccess,
    layout: TeacherLayout,
    component: MembershipPurchaseSuccess
  },
  // {
  //   path: teacherRoutes.referForMembership,
  //   layout: TeacherLayout,
  //   component: ReferrForMembership
  // },
  {
    path: teacherRoutes.myAccount,
    layout: TeacherLayout,
    component: Account
  },
  {
    path: teacherRoutes.leaderboardReport,
    layout: TeacherLayout,
    component: ReportToolLeaderboard
  },
  {
    path: teacherRoutes.newLEleaderboardReport,
    layout: TeacherLayout,
    component: LELeaderboardReportView
  },
  {
    path: teacherRoutes.teacherPremiumPlan,
    layout: TeacherLayout,
    component: PremiumPlanPage
  },
  {
    path: teacherRoutes.teacherPremiumPlanClass,
    layout: TeacherLayout,
    component: PremiumPlanPage
  },
  {
    path: teacherRoutes.questionsAnsweredReport,
    layout: TeacherLayout,
    component: ReportToolQuestions
  },
  {
    path: teacherRoutes.classActivityReport,
    layout: TeacherLayout,
    component: ReportToolClassActivity
  },
  {
    path: teacherRoutes.newLEClassActivityReport,
    layout: TeacherLayout,
    component: NewLEClassActivityReportView
  },
  {
    path: teacherRoutes.classMissions,
    layout: TeacherLayout,
    component: ClassMissionsView
  },
  {
    path: teacherRoutes.classMissionsGame,
    layout: ClassMissionsGameLayout,
    component: ClassMissionsGameView
  },
  {
    path: teacherRoutes.classMissionReport,
    layout: TeacherLayout,
    component: ClassMissionReportView
  },
  {
    path: teacherRoutes.membershipCheckout,
    layout: TeacherLayout,
    component: MembershipCheckout
  },
  ...userGeneratedContent(TeacherLayout),
  ...resourceContent(TeacherLayout)
];
