import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/table/Table';
import { Grid, Typography, Button, Paper, Link } from '@material-ui/core';
import { getTableOptions } from './ReportToolTemplate';
import FirstPlace from '../../assets/icons/1st.png';
import SecondPlace from '../../assets/icons/2nd.png';
import ThirdPlace from '../../assets/icons/3rd.png';
import ReportToolLeaderboardIcon from '../../assets/icons/report/leaderboard-icon.png';
import { useAppSelector } from '../../redux/hooks';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import colours from '../../styles/colors';
import { push } from 'connected-react-router';
import { userTypesDashboardRoute } from '../../constants/users';
import moment from 'moment';

interface ReportToolLeaderboardProps {
  isWidget?: boolean;
}

const ReportToolNewLELeaderboard: React.FC<ReportToolLeaderboardProps> = ({
  isWidget
}: ReportToolLeaderboardProps) => {
  const { currentClass } = useSelector((state: any) => state.class);
  const playerIds: number[] = currentClass.roster.map(
    (user: any) => user.educationUserId
  );
  const features = useAppSelector(state => state.featureFlag);
  const [loading, setLoading] = React.useState(!features.newDashboardFlag);
  const [selectedOption, setSelectedOption] = useState('Last 7 Days');
  const timeFrames: any = {
    last7: {
      label: 'Last 7 Days',
      from: moment()
        .subtract(7, 'days')
        .startOf('day')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    }
  };
  const [prevSelection, setPrevSelection] = useState(timeFrames.last7);
  const [fromDate, setFromDate] = useState<Date | null>(prevSelection.from);
  const [toDate, setToDate] = useState<Date | null>(prevSelection.to);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const [questionCount, setQuestionCount] = useState<
    {
      userId: number;
      totalNumberOfAnswers: number;
      totalNumberOfCorrectAnswers: number;
      totalNumberOfInCorrectAnswers: number;
    }[]
  >([]);
  const userType = useSelector((state: any) => state.userData.userType);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch question attempts when fromDate and toDate are both set
    if (fromDate && toDate) {
      setLoading(true);
      const formattedFromDate = new Date(
        moment(fromDate).format('MMM DD, YYYY')
      );
      const formattedToDate = new Date(moment(toDate).format('MMM DD, YYYY'));
      const selection = `${formattedFromDate} - ${formattedToDate}`;
      setPrevSelection(selection);
      fetchQuestionAttempts(playerIds, fromDate, toDate)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [currentClass]);

  async function fetchQuestionAttempts(
    learnerIds: number[],
    from: Date | null = null,
    to: Date | null = null
  ) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `v1/learners/question-attempts`,
          method: APIMethods.POST,
          endpoint: APIEndpoints.LE_API,
          data: {
            learnerIds: learnerIds,
            from: from,
            to: to
          }
        })
      );
      const questionsData: any = response.data;
      if (questionsData) {
        // const attempts = questionsData.map((attempt: any) => ({
        //   ...attempt,
        //   attemptDate: new Date(attempt.attemptDate)
        // }));

        // // Filter the attempts that happened in the last 7 days.
        // const last7Days = Date.now() - 7 * 24 * 60 * 60 * 1000; // 7 days ago
        // const filteredAttempts = attempts.filter(
        //   (attempt: any) => attempt.attemptDate.getTime() > last7Days
        // );

        // Group the attempts by learnerId.
        const attemptsByLearner = questionsData.reduce(
          (acc: { [x: string]: any[] }, attempt: { learnerId: any }) => {
            const learnerId = attempt.learnerId;
            if (!acc[learnerId]) {
              acc[learnerId] = [];
            }
            acc[learnerId].push(attempt);
            return acc;
          },
          {}
        );

        // For each learnerId group, count the number of true and false attempts.
        const getQuestionCount: any = Object.keys(attemptsByLearner).map(
          learnerId => {
            const attempts = attemptsByLearner[learnerId];
            const totalNumberOfCorrectAnswers = attempts.filter(
              (attempt: { isCorrectAnswer: any }) => attempt.isCorrectAnswer
            ).length;
            const totalNumberOfInCorrectAnswers = attempts.filter(
              (attempt: { isCorrectAnswer: any }) => !attempt.isCorrectAnswer
            ).length;
            return {
              userId: parseInt(learnerId),
              totalNumberOfAnswers:
                totalNumberOfCorrectAnswers + totalNumberOfInCorrectAnswers,
              totalNumberOfCorrectAnswers,
              totalNumberOfInCorrectAnswers
            };
          }
        );
        getQuestionCount.sort(
          (a: any, b: any) =>
            b.totalNumberOfCorrectAnswers - a.totalNumberOfCorrectAnswers
        );
        getQuestionCount.forEach((result: any, index: any) => {
          result.rank = index + 1;
        });
        //adding player names to result
        const getPlayerNames: any = getQuestionCount.map((learner: any) => ({
          ...learner,
          fullName: currentClass.roster.find(
            (player: any) => player.educationUserId === learner.userId
          ).fullname
        }));
        setQuestionCount(getPlayerNames);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  let columns = [
    {
      Header: 'Rank',
      accessor: 'rank',
      Cell: (row: any) => {
        const { rank } = row.row.original;
        let source;

        switch (rank) {
          case 1:
            source = FirstPlace;
            break;
          case 2:
            source = SecondPlace;
            break;
          case 3:
            source = ThirdPlace;
            break;
          default:
            source = '';
            break;
        }

        return (
          <Button
            disabled
            startIcon={
              rank <= 3 ? (
                <img src={source} alt="Trophy" height={20} />
              ) : (
                <div style={{ height: 20, width: 20 }} />
              )
            }
          >
            <Typography style={{ color: 'black' }}>{rank}</Typography>
          </Button>
        );
      }
    },
    {
      Header: 'Player',
      accessor: 'fullName'
    },
    {
      Header: 'Correct/Total',
      accessor: (row: any) => {
        return `${row.totalNumberOfCorrectAnswers} / ${row.totalNumberOfAnswers}`;
      }
    }
  ];
  const viewReport = () => {
    return (dispatch: any) => {
      const path = `${userTypesDashboardRoute[userType]}/reports/LEleaderboard/${classCode}`;
      dispatch(push(path));
    };
  };

  return (
    <>
      <Paper style={{ padding: 12, position: 'relative', height: 380 }}>
        <Grid container item xs={6} style={{ paddingBottom: '15px' }}>
          <Grid item xs={12}>
            <Typography variant="h6">Leaderboard</Typography>
          </Grid>
        </Grid>

        <Grid item style={{ height: '100%' }}>
          {features.newDashboardFlag && questionCount.length > 0 ? (
            <Table
              columns={columns}
              data={questionCount.slice(0, 3)}
              {...getTableOptions(isWidget)}
              elevation={0}
              overflowY={isWidget ? 'scroll' : undefined}
            />
          ) : (
            <>
              <div style={{ textAlign: 'center', paddingTop: '35px' }}>
                <img
                  src={ReportToolLeaderboardIcon}
                  style={{ height: '120px', width: '120px' }}
                ></img>
              </div>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px',
                  paddingTop: '35px',
                  textAlign: 'center'
                }}
              >
                {currentClass.roster.length
                  ? 'There is no data from your class for the last 7 days! Select View Report to see data from other time periods'
                  : 'Add a player to see reports'}
              </Typography>
            </>
          )}
        </Grid>

        <div style={{ position: 'absolute', top: 12, right: 12 }}>
          <Link
            component="button"
            style={{ color: colours.primary, fontSize: 13, fontWeight: 600 }}
            onClick={() => {
              dispatch(viewReport());
            }}
          >
            Full Report
          </Link>
        </div>
      </Paper>
    </>
  );
};

export default ReportToolNewLELeaderboard;
