import {
  REGISTER_FAILED,
  REGISTER_RESET
} from '../types/register/register-action-type';

export const registrationReducer = (
  state: {
    errorMessage?: string;
  } = {},
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case REGISTER_FAILED:
      return {
        ...state,
        ...payload
      };
    case REGISTER_RESET:
      return {};
    default:
      return state;
  }
};
