import React, { createRef, useCallback } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartLegendOptions } from 'chart.js';

interface DoughnutChartProps {
  labels: Array<string>;
  values: Array<number>;
  background: Array<string>;
  hoverBackground?: Array<string>;
  handleTooltip?: (visible: boolean, tooltipModel: any) => void;
  legend: ChartLegendOptions;
  height: number;
}

const DoughnutChart: React.FC<DoughnutChartProps> = (
  props: DoughnutChartProps
) => {
  const chartRef: any = createRef();

  const handleTooltip = useCallback((tooltipModel: any) => {
    if (!props.handleTooltip) {
      return null;
    }
    if (tooltipModel.opacity === 0) {
      return props.handleTooltip!(false, tooltipModel);
    }
    return props.handleTooltip!(true, tooltipModel);
  }, []);

  return (
    <Doughnut
      ref={chartRef}
      height={props.height}
      data={{
        labels: props.labels,
        datasets: [
          {
            data: props.values,
            backgroundColor: props.background,
            hoverBackgroundColor: props.hoverBackground
          }
        ]
      }}
      options={{
        tooltips: {
          enabled: !props.handleTooltip,
          custom: handleTooltip
        },
        responsive: true,
        legend: props.legend
      }}
    />
  );
};

export default DoughnutChart;
