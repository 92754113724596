const namespaceClass = '[Class]';

export const CLASS_GET_ALL = `${namespaceClass} CLASS GET ALL SUCCESS`;
export const CLASS_GET_ALL_FAILED = `${namespaceClass} CLASS GET ALL FAILED`;
export const CLASS_GET_ONE = `${namespaceClass} CLASS GET ONE`;
export const CLASS_GET_ONE_IN_PROGRESS = `${namespaceClass} CLASS GET ON IN PROGRESS`;
export const CLASS_GET_ONE_SUCCESS = `${namespaceClass} CLASS GET ONE SUCCESS`;
export const CLASS_GET_ONE_FAILED = `${namespaceClass} CLASS GET ONE FAILED`;
export const CLASS_ADD_CLASS = `${namespaceClass} CLASS ADD CLASS`;
export const CLASS_ADD_CLASS_FAILED = `${namespaceClass} CLASS ADD CLASS FAILED`;
export const CLASS_PLAYER_ADDED = `${namespaceClass} CLASS PLAYER ADDED`;
export const CLASS_PLAYER_REMOVED = `${namespaceClass} CLASS PLAYER REMOVED`;
export const CLASS_UPDATE_PLAYER = `${namespaceClass} CLASS UPDATE PLAYER`;
export const CLASS_UPDATE_PLAYER_FAILED = `${namespaceClass} CLASS UPDATE PLAYER FAILED`;
export const CLASS_ARCHIVE_CLASS = `${namespaceClass} CLASS ARCHIVE CLASS`;
export const CLASS_EDIT_CLASS = `${namespaceClass} CLASS EDIT CLASS`;
export const CLASS_EDIT_CLASS_FAILED = `${namespaceClass} CLASS EDIT CLASS FAILED`;
export const CLASS_UPDATE_CLASS = `${namespaceClass} CLASS UPDATE CLASS`;
export const CLASS_UPDATE_CLASS_FAILED = `${namespaceClass} CLASS UPDATE CLASS FAILED`;
// placeholder for failed request

export const CLASS_FAILED = `${namespaceClass} CLASS FAILED`;
