import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  IconButton,
  Theme,
  Grid
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { show as showModal, hide } from 'redux-modal';
import { Close } from '@material-ui/icons';
import LEPopup from '../../../assets/LE-popup.png';
import mixpanel from '../../../utils/mixpanel';
import {
  fetchApi,
  NotificationVariant
} from '../../../redux/actions/fetch-actions';
import { APIMethods } from '../../../types/fetch/fetch-types';
import { USER_UPDATE_DATA } from '../../../redux/types/user/user-action-type';
import { newLETag } from '../../../constants/global-constants';
import { LocalStorage } from '../../../enums/LocalStorage';
import { useAppSelector } from '../../../redux/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: '20px'
  },
  submitButton: {
    backgroundColor: '#2ABB39',
    color: 'white',
    width: '183px',
    height: '44px'
  },
  description: {
    fontWeight: 400,
    fontSize: '16px'
  },
  image: {
    width: '100%',
    maxWidth: '364px',
    height: '156px',
    display: 'block'
  }
}));

const ModalNewLEOptIn: React.FC = (props: any) => {
  const userData = useSelector((state: any) => state.userData);
  let { show, handleHide, hideCloseButton } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const [shouldHide, setShouldHide] = useState<boolean>();
  const classData = useSelector((state: any) => state.class);
  const features = useAppSelector(state => state.featureFlag);

  useEffect(() => {
    if (!classData || !classData.classes.length || !features.newLeOptIn) {
      setShouldHide(true);
    } else {
      setShouldHide(false);
    }
  }, [classData, features.newLeOptIn]);

  if (shouldHide) {
    return null;
  }

  const handleOptInClick = () => {
    dispatch(
      fetchApi({
        method: APIMethods.POST,
        url: `user/newLEOptIn`
      })
    ).then(async (apiResponse: any) => {
      dispatch({
        type: USER_UPDATE_DATA,
        payload: {
          ...userData,
          featureFlagTags: newLETag,
          featureFlagDate: new Date()
        }
      });
      handleHide();
      dispatch(
        showModal('snackbar', {
          variant: NotificationVariant.success,
          message: 'You have upgraded to the new learning engine!',
          vertical: 'top',
          horizontal: 'right',
          autoHideDuration: 10000
        })
      );
      mixpanel.track('Close LEV3 Announcement Pop-up', {
        optedIn: true
      });
    });
  };

  return (
    <ModalAbstract maxWidth="lg" show={show} handleHide={handleHide}>
      <DialogTitle className={classes.title}>
        <span style={{ fontWeight: 600 }}>BIG Update!</span> Early Access to our
        NEW Learning Engine and Reports.
        {!hideCloseButton && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setShouldHide(true);
              localStorage.setItem(
                LocalStorage.HIDE_NEW_LE_OPT_IN_POPUP,
                'true'
              );
              mixpanel.track('Close LEV3 Announcement Pop-up', {
                optedIn: false
              });
              dispatch(hide('modal'));
            }}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          flexDirection: 'column'
        }}
        className={classes.container}
      >
        <Grid
          container
          style={{
            flexDirection: 'row'
          }}
          className={classes.container}
        >
          <img src={LEPopup} alt="LEPopup" className={classes.image} />
          <div style={{ margin: '25px' }}>
            <Typography className={classes.description}>
              Opt in* now and gain access to:
              {''}
            </Typography>
            <ul style={{ paddingLeft: 20, marginTop: 10 }}>
              <li className={classes.description}>4000+ NEW questions</li>
              <li className={classes.description}>
                <a
                  href="https://support.shoelacelearning.com/hc/en-us/articles/12999237351063"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    mixpanel.track('LEV3 Announcement Link Clicked', {
                      link: 'learning engine'
                    });
                  }}
                >
                  Improved learning engine algorithm
                </a>
              </li>
              <li className={classes.description}>
                <a
                  href="https://www.playdreamscape.com/shoelaces-reading-comprehension-skills/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    mixpanel.track('LEV3 Announcement Link Clicked', {
                      link: 'skills report'
                    });
                  }}
                >
                  NEW skill tier system
                </a>
                **
              </li>
              <li className={classes.description}>
                <a
                  href="https://www.playdreamscape.com/new-shoelace-dashboards/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    mixpanel.track('LEV3 Announcement Link Clicked', {
                      link: 'new Shoelace dashboard'
                    });
                  }}
                >
                  NEW skill report and RCL report
                </a>
              </li>
            </ul>
          </div>
        </Grid>
        <div
          style={{
            flexDirection: 'column',
            marginTop: 20
          }}
          className={classes.container}
        >
          <Button
            variant="contained"
            style={{ fontWeight: 700, fontSize: '16px' }}
            className={classes.submitButton}
            onClick={handleOptInClick}
          >
            Opt in now
          </Button>
        </div>
        <div style={{ textAlign: 'left', marginTop: '20px' }}>
          <Typography className={classes.description}>
            *All users will be moved to the new learning engine and dashboards
            in July 2023.{' '}
          </Typography>
          <Typography className={classes.description}>
            **This new system is significantly different from the old and will
            affect historical skill records.{' '}
            <a
              href="https://support.shoelacelearning.com/hc/en-us/articles/12999237351063"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                mixpanel.track('LEV3 Announcement Link Clicked', {
                  link: 'risks'
                });
              }}
            >
              {' '}
              Read more here
            </a>
          </Typography>
        </div>
      </DialogContent>
    </ModalAbstract>
  );
};

export default ModalNewLEOptIn;
