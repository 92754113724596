import React, { useState } from 'react';
import View from '../../../components/view/View';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import 'animate.css';
import clsx from 'clsx';
import colors from '../../../styles/colors';
import { USER_UPDATE_DATA } from '../../../redux/types/user/user-action-type';
import Welcome from './Welcome';
import HowToPlay from './HowToPlay';
import {
  trackShowMeMoreClick,
  trackCompleteGameTutorial,
  trackSkipGameTutorial
} from '../../../redux/actions/mixpanel-actions';
import { isMobile } from 'react-device-detect';
import { userTypesDashboardRoute } from '../../../constants/users';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '0 auto',
    maxWidth: '55%',
    padding: 20,
    position: 'relative'
  },
  paperMobile: {
    maxWidth: '100% !important'
  },
  welcome: {
    background: colors.primary,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: colors.white,
    fontWeight: 600,
    margin: '-20px -20px 20px',
    padding: 10
  }
}));

enum Steps {
  welcome,
  tutorial
}

const GetStartedView = () => {
  const [currentStep, setCurrentStep] = useState(Steps.welcome);
  const [tutorialCompleted, setTutorialCompleted] = useState(false);
  const userData = useSelector((state: any) => state.userData);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleCloseTutorial = () => {
    setTutorialCompleted(true);
    setTimeout(() => {
      dispatch({
        type: USER_UPDATE_DATA,
        payload: { getStarted: true }
      });
      dispatch(push(userTypesDashboardRoute[userData.userType]));
    }, 300);
  };

  const handleCompleteTutorial = () => {
    handleCloseTutorial();
    dispatch(trackCompleteGameTutorial());
  };

  const handleSkipTutorial = () => {
    handleCloseTutorial();
    dispatch(trackSkipGameTutorial());
  };

  const renderTitle = () => {
    return currentStep === Steps.welcome
      ? 'Welcome to Dreamscape!'
      : 'How does Dreamscape work?';
  };

  const renderCurrentStep = () => {
    if (currentStep === Steps.welcome) {
      return (
        <Welcome
          userType={userData.userType}
          handleNextClick={() => {
            setCurrentStep(Steps.tutorial);
            dispatch(trackShowMeMoreClick());
          }}
        />
      );
    }
    return (
      <HowToPlay
        completeTutorialLabel="I'm Ready"
        handleSkipTutorial={handleSkipTutorial}
        handleCompleteTutorial={handleCompleteTutorial}
      />
    );
  };

  return (
    <View flex style={{ height: '100vh', textAlign: 'center' }}>
      <Paper
        className={clsx(
          classes.paper,
          isMobile && classes.paperMobile,
          'animated',
          tutorialCompleted && 'zoomOut',
          currentStep === Steps.tutorial && 'zoomIn'
        )}
      >
        <Typography component="h3" variant="h5" className={classes.welcome}>
          {renderTitle()}
        </Typography>
        {renderCurrentStep()}
      </Paper>
    </View>
  );
};

export default GetStartedView;
