import React, { CSSProperties, PropsWithChildren } from 'react';
import { ButtonBase, Menu, MenuItem } from '@material-ui/core';

/**
 * @description Button component's for assignment feature
 */

export interface ButtonProps {
  variant?: 'filled' | 'outline';
  color?: string;
  textColor?: string;
  onClick?: (...args: any) => any;
  icon?: any;
  iconSuffix?: any;
  disabled?: boolean;
  iconColor?: string;
  menuItems?: { handler: () => any; title: string; icon: any }[];
  title?: string;
  style?: CSSProperties;
}

export const Button: React.FC<ButtonProps> = (
  props: PropsWithChildren<ButtonProps>
) => {
  const {
    children,
    style,
    variant,
    color,
    disabled,
    iconColor,
    textColor,
    iconSuffix,
    menuItems,
    onClick,
    icon,
    title
  } = props;

  let styles: CSSProperties = {
    boxSizing: 'border-box',
    color: textColor || 'black',
    borderRadius: '6px',
    cursor: 'pointer',
    padding: '.3rem .5rem',
    ...style
  };

  if (variant == 'outline') {
    styles = {
      ...styles,
      border: `1px solid ${color}`
    };
  } else if (variant == 'filled') {
    styles = {
      ...styles,
      border: `1px solid ${color}`,
      backgroundColor: `${color}`
    };
  }

  if (disabled) {
    styles.backgroundColor = 'grey';
    styles.color = 'white';
    styles.border = '1px solid grey';
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuItemsAvailable = menuItems && menuItems.length > 0;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: {
    currentTarget: React.SetStateAction<null>;
  }) => {
    if (isMenuItemsAvailable) {
      setAnchorEl(event.currentTarget);
    }
  };

  const Icon = icon && icon;
  const IconSuffix = iconSuffix && iconSuffix;
  return (
    <>
      <ButtonBase
        title={title}
        aria-controls={'button-menu'}
        aria-haspopup="true"
        onClick={onClick || handleClick}
        disabled={disabled}
        centerRipple={true}
        style={styles}
      >
        {icon && (
          <Icon
            style={{ fontSize: '1rem', marginRight: '3px', color: iconColor }}
          />
        )}
        {children}
        {iconSuffix && (
          <IconSuffix style={{ fontSize: '1.2rem', marginLeft: '3px' }} />
        )}
      </ButtonBase>
      {isMenuItemsAvailable && (
        <Menu
          id="button-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleClose}
        >
          {menuItems &&
            menuItems.map((menuItem: any) => {
              const MenuIcon = menuItem.icon && menuItem.icon;
              return (
                <MenuItem
                  style={{ color: 'grey' }}
                  key={menuItem.title}
                  onClick={menuItem.handler}
                >
                  <div style={{ marginRight: '10px' }}>
                    {menuItem.icon && <MenuIcon />}
                  </div>

                  {menuItem.title}
                </MenuItem>
              );
            })}
        </Menu>
      )}
    </>
  );
};
