import {
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_READ
} from '../types/notification/notification-action-types';
import { DashboardNotification } from '../../types/notification/notification-response-types';

export const notificationReducer = (
  state = [],
  { type, payload }: { type: string; payload: DashboardNotification }
) => {
  switch (type) {
    case NOTIFICATION_GET_SUCCESS:
      return payload;
    case NOTIFICATION_READ:
      return state.filter(
        (item: DashboardNotification) => item._id !== payload._id
      );
    default:
      return state;
  }
};
