/**
 * @description simplifies async await by doing a try catch and returning array of resolved or rejected values
 * @param promise
 * @returns rejected{[null, error]} or resolved{[T, null]}
 */
export async function AsyncPromiseHelper<T>(promise: any): Promise<[T, null]>;
export async function AsyncPromiseHelper<T>(
  promise: PromiseLike<any>
): Promise<[null, Error]>;
export async function AsyncPromiseHelper<T>(
  promise: PromiseLike<any>
): Promise<[null | T, Error | null]> {
  try {
    const result = await promise;
    return [result, null];
  } catch (err) {
    return [null, err];
  }
}
