import React, { useEffect, useState, Fragment } from 'react';
import { hide, show } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import { push } from 'connected-react-router';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import countryData from '../../utils/json/countries.json';
import { makeStyles } from '@material-ui/styles';
import { userTypes } from '../../constants/users';
import {
  getDistricts,
  getSchools,
  setSchool,
  setDistrict
} from '../../redux/actions/location-actions';
import {
  Location,
  District,
  School,
  Country
} from '../../redux/types/location/location-reducer-types';
import { Grid, TextField, Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import colors from '../../styles/colors';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIMethods } from '../../types/fetch/fetch-types';
import usePrevious from '../../hooks/UsePrevious';
import { User } from '../../types/user/user-type';
import { commonRoutes } from '../../constants/routes';

interface LocationSelectionProps {
  handleChangeStep?: (step: number) => void;
  handleSelectRegion?: (country: string, region: string) => void;
  buttonText?: string;
}

const useStyles = makeStyles({
  select: {
    margin: '30px auto',
    width: '60%'
  },
  fullWidth: {
    width: '100% !important'
  },
  textField: {
    margin: '30px auto 0',
    width: '60%'
  },
  input: {
    fontSize: 14,
    padding: 11
  },
  inputLabel: {
    fontSize: 14,
    transform: 'translate(14px, 12px) scale(1)'
  },
  anchor: {
    cursor: 'pointer',
    display: 'block',
    margin: '30px auto -25px auto',
    position: 'relative',
    textAlign: 'right',
    textDecoration: 'underline',
    width: '60%'
  },
  notOnTheList: {
    background: colors.lightBackground,
    color: colors.blueHover,
    padding: 10,
    position: 'absolute',
    width: '100%',

    '&>a': {
      margin: 0,
      textAlign: 'center',
      width: '100%'
    }
  },
  notOnTheListMobile: {
    bottom: 0,
    borderTop: '1px solid #dcdcdc',
    left: 0,
    position: 'fixed'
  },
  button: {
    display: 'block',
    margin: '30px auto 0'
  }
});

const LocationSelection: React.FC<LocationSelectionProps> = (
  props: LocationSelectionProps
) => {
  enum steps {
    firstStep = 0,
    secondStep = 1
  }
  const countries: Location[] = countryData.map(item => ({
    label: item.name,
    value: item.code
  }));
  const emptyDropdown: Location[] = [{ label: '', value: '' }];
  const { handleChangeStep } = props;
  const classes = useStyles();
  const userData: User = useSelector((state: any) => state.userData);
  const dispatch = useDispatch();
  const [loadingProvinceDropdown, setLoadingProvinceDropdown] = useState(false);
  const [state, setState] = useState({
    componentReady: false,
    districtsReady: false,
    step: steps.firstStep,
    countries,
    provinces: emptyDropdown,
    selectedCountry: '',
    selectedSchool: '',
    selectedSchoolCode: '',
    selectedDistrict: '',
    selectedDistrictId: '',
    selectedProvince: '',
    allSchools: [],
    allDistricts: [],
    schoolProfileLink: '',
    isSchoolOnTheList: true,
    isDistrictOnTheList: true,
    schools: emptyDropdown,
    districts: emptyDropdown,
    mainDashboardPage: ''
  });

  useEffect(() => {
    dispatch(hide('modal'));
    dispatch(hide('spinner'));
    if (!state.mainDashboardPage) {
      setState({
        ...state,
        mainDashboardPage: `/dashboard/${userData.userType
          .replace(' ', '-')
          .toLowerCase()}`
      });
    }
  }, []);

  const previousCountry = usePrevious(state.selectedCountry);
  useEffect(() => {
    if (state.selectedCountry !== previousCountry) {
      handleReloadProvinceDropdownData();
    }
  }, [state.selectedCountry]);

  const handleReloadProvinceDropdownData = () => {
    setLoadingProvinceDropdown(true);
    setTimeout(() => setLoadingProvinceDropdown(false), 0);
  };

  const handleSelectCountry = (location: Location) => {
    const country: Country[] = countryData.filter(
      (country: Country) => country.code === location.value
    );
    const provinces: Location[] = country[0].provinces.map(
      (province: string) => ({ label: province, value: province })
    );
    setState({
      ...state,
      provinces,
      selectedCountry: location.value
    });
  };

  const handleSelectProvince = (location: Location) => {
    setState({
      ...state,
      selectedProvince: location.value
    });
  };

  const handleNextStep = async () => {
    if (props.handleSelectRegion) {
      return props.handleSelectRegion(
        state.selectedCountry,
        state.selectedProvince
      );
    }
    const selectionType =
      userData.userType === userTypes.districtAdmin
        ? 'districts'
        : 'schools and districts';
    dispatch(
      show('spinner', {
        text: `Loading ${selectionType} for your location`
      })
    );
    const allDistricts: any = await getDistricts(true);
    const districts: Location[] = allDistricts.data
      .filter(
        (district: District) =>
          district.countryID === state.selectedCountry &&
          district.regionID === state.selectedProvince
      )
      .map((district: District) => ({
        label: district.name,
        value: district._id
      }));
    const allSchools = await getSchools(
      state.selectedCountry,
      state.selectedProvince,
      true
    );
    setState({
      ...state,
      districts,
      allSchools: allSchools.data,
      step: steps.secondStep
    });
    dispatch(hide('spinner'));
    if (handleChangeStep) {
      handleChangeStep(steps.secondStep);
    }
  };

  const handleSelectDistrict = (district: Location) => {
    const schools = state.allSchools.filter(
      (school: School) => school.districtID === district.value
    );
    setState({
      ...state,
      schools: schools.map((school: School) => ({
        label: school.name,
        value: school.name
      })),
      selectedDistrict: district.label,
      selectedDistrictId: district.value,
      selectedSchool: ''
    });
  };

  const handleSelectSchool = (school: Location) => {
    setState({
      ...state,
      selectedSchool: school.value
    });
  };

  const handleDistrictOnTheList = () => {
    setState({
      ...state,
      selectedSchool: '',
      selectedDistrict: '',
      selectedDistrictId: '',
      isDistrictOnTheList: !state.isDistrictOnTheList,
      isSchoolOnTheList: !state.isDistrictOnTheList
    });
  };

  const handleSchoolOnTheList = () => {
    setState({
      ...state,
      selectedSchool: '',
      isSchoolOnTheList: !state.isSchoolOnTheList
    });
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleSetLocation = async () => {
    const { userType } = userData;
    if (userType === userTypes.districtAdmin) {
      dispatch(
        setDistrict(
          state.selectedCountry,
          state.selectedProvince,
          state.selectedDistrict
        )
      );
    } else {
      let schoolCode = '';
      if (state.isSchoolOnTheList) {
        const selectedSchool: any = state.allSchools.filter(
          (school: any) => school.name === state.selectedSchool
        )[0];
        const fullSchool = await dispatch(
          fetchApi({
            url: `school/id/${selectedSchool._id}`,
            method: APIMethods.GET
          })
        );
        schoolCode = fullSchool.data.schoolCode;
      }
      dispatch(
        setSchool(
          state.selectedCountry,
          state.selectedProvince,
          state.selectedDistrict,
          state.selectedSchool,
          schoolCode
        )
      );
    }
    if (
      userType === userTypes.districtAdmin ||
      userType === userTypes.schoolAdmin
    ) {
      if (handleChangeStep) {
        dispatch(push(commonRoutes.adminVerification));
      }
    }
  };

  const sortArrayOfObjects = (
    array: Array<any>,
    property: string
  ): Array<any> => {
    array.sort((a: any, b: any) => {
      if (a[property] > b[property]) return 1;
      if (a[property] < b[property]) return -1;
      return 0;
    });
    return array;
  };

  const renderDistrictSelection = () => {
    const selectedDistrictValue = state.selectedDistrictId
      ? { value: state.selectedDistrictId, label: state.selectedDistrict }
      : null;
    const selectDistrictText = state.isDistrictOnTheList
      ? 'My district is not on the list'
      : 'Select district from the list';
    const districtOnTheListButton = (
      <a
        className={clsx(classes.anchor, isMobile && classes.fullWidth)}
        onClick={handleDistrictOnTheList}
      >
        {selectDistrictText}
      </a>
    );
    let districtSelection = (
      <>
        {isMobile ? districtOnTheListButton : null}
        <AsyncSelect
          defaultOptions
          name="selectedDistrict"
          placeholder="Select your district"
          className={clsx(classes.select, isMobile && classes.fullWidth)}
          onChange={(district: any) => handleSelectDistrict(district)}
          loadOptions={(inputValue: string, callback: any) =>
            handleLoadOptions(
              inputValue,
              sortArrayOfObjects(state.districts, 'label'),
              callback
            )
          }
          value={selectedDistrictValue}
          styles={{
            menu: provided => ({
              ...provided,
              paddingBottom: isMobile ? 0 : 40
            }),
            menuList: provided => ({ ...provided, height: 150 })
          }}
          components={
            isMobile
              ? {}
              : {
                  MenuList: props => (
                    <>
                      <Fragment>
                        <components.MenuList {...props}>
                          {props.children}
                        </components.MenuList>
                        <div className={classes.notOnTheList} style={{}}>
                          {districtOnTheListButton}
                        </div>
                      </Fragment>
                    </>
                  )
                }
          }
        />
      </>
    );
    if (!state.isDistrictOnTheList) {
      districtSelection = (
        <>
          {districtOnTheListButton}
          <TextField
            inputProps={{ className: classes.input }}
            InputLabelProps={{ className: classes.inputLabel }}
            className={clsx(classes.textField, isMobile && classes.fullWidth)}
            label="Please, type the name of your district"
            style={{ padding: '10px !important' }}
            error={!state.selectedDistrict}
            name="selectedDistrict"
            value={state.selectedDistrict}
            onChange={handleChangeInput}
            variant="outlined"
            fullWidth
          />
        </>
      );
    }
    return districtSelection;
  };

  const renderSchoolSelection = () => {
    if (
      !state.selectedDistrict ||
      userData.userType === userTypes.districtAdmin
    ) {
      return null;
    }
    const selectedSchoolValue = state.selectedSchool
      ? { value: state.selectedSchool, label: state.selectedSchool }
      : null;
    const selectSchoolText = state.isSchoolOnTheList
      ? 'My school is not on the list'
      : 'Select school from the list';
    let schoolOnTheListButton = state.selectedDistrict ? (
      <a
        className={clsx(classes.anchor, isMobile && classes.fullWidth)}
        onClick={handleSchoolOnTheList}
      >
        {selectSchoolText}
      </a>
    ) : null;
    let schoolSelection = (
      <>
        {isMobile ? schoolOnTheListButton : null}
        <AsyncSelect
          defaultOptions
          name="selectedSchool"
          placeholder="Select your school"
          className={clsx(classes.select, isMobile && classes.fullWidth)}
          isDisabled={!state.selectedDistrict}
          onChange={(school: any) => handleSelectSchool(school)}
          loadOptions={(inputValue: string, callback: any) =>
            handleLoadOptions(
              inputValue,
              sortArrayOfObjects(state.schools, 'label'),
              callback
            )
          }
          value={selectedSchoolValue}
          styles={{
            menu: provided => ({
              ...provided,
              paddingBottom: isMobile ? 0 : 40
            }),
            menuList: provided => ({ ...provided, height: 150 })
          }}
          components={
            isMobile
              ? {}
              : {
                  MenuList: props => (
                    <>
                      <Fragment>
                        <components.MenuList {...props}>
                          {props.children}
                        </components.MenuList>
                        <div className={classes.notOnTheList}>
                          {schoolOnTheListButton}
                        </div>
                      </Fragment>
                    </>
                  )
                }
          }
        />
      </>
    );
    if (!state.isDistrictOnTheList || !state.isSchoolOnTheList) {
      if (!state.isSchoolOnTheList && !state.isDistrictOnTheList) {
        schoolOnTheListButton = null;
      }
      schoolSelection = (
        <>
          {schoolOnTheListButton}
          <TextField
            inputProps={{ className: classes.input }}
            InputLabelProps={{ className: classes.inputLabel }}
            className={clsx(classes.textField, isMobile && classes.fullWidth)}
            label="Please, type the name of your school"
            style={{ padding: '10px !important' }}
            error={!state.selectedSchool}
            name="selectedSchool"
            value={state.selectedSchool}
            onChange={handleChangeInput}
            variant="outlined"
            fullWidth
          />
        </>
      );
    }
    return schoolSelection;
  };

  /**
   * This was implemented because react-select by default doesn't
   * sort the results in ascending order.
   * @link https://github.com/JedWatson/react-select/issues/3934
   */
  const handleFilterOptions = (
    inputValue: string,
    options: Array<Location>
  ) => {
    const startsWith: Array<Location> = [];
    const includes: Array<Location> = [];

    options.forEach(option => {
      const search = inputValue.toLowerCase().trim();
      const label = option.label.toLowerCase().trim();
      if (label.startsWith(search)) {
        startsWith.push(option);
      } else if (label.includes(search)) {
        includes.push(option);
      }
    });

    return [...startsWith.sort(), ...includes.sort()];
  };

  const handleLoadOptions = (
    inputValue: string,
    options: Array<Location>,
    callback: any
  ) => {
    setTimeout(() => {
      callback(handleFilterOptions(inputValue, options));
    }, 100);
  };

  const renderProvinceDropdown = () => {
    const placeholder = `Select your ${
      state.selectedCountry === 'USA' ? 'state' : 'province'
    }`;
    if (loadingProvinceDropdown) {
      return (
        <Select
          options={[]}
          placeholder={placeholder}
          className={clsx(classes.select, isMobile && classes.fullWidth)}
        />
      );
    }
    return (
      <AsyncSelect
        defaultOptions
        cacheOptions
        name="selectedProvince"
        placeholder={placeholder}
        className={clsx(classes.select, isMobile && classes.fullWidth)}
        onChange={(location: any) => handleSelectProvince(location)}
        loadOptions={(inputValue: string, callback: any) =>
          handleLoadOptions(inputValue, state.provinces, callback)
        }
      />
    );
  };

  const renderStep = () => {
    const selectionType =
      userData.userType === userTypes.districtAdmin ? 'District' : 'School';
    if (state.step === steps.firstStep) {
      return (
        <>
          <AsyncSelect
            defaultOptions
            name="selectedCountry"
            placeholder="Select your country"
            className={clsx(classes.select, isMobile && classes.fullWidth)}
            loadOptions={(inputValue: string, callback: any) =>
              handleLoadOptions(inputValue, state.countries, callback)
            }
            onChange={(location: any) => handleSelectCountry(location)}
          />
          {renderProvinceDropdown()}
          <Button
            primary
            disabled={state.selectedProvince === ''}
            onClick={handleNextStep}
          >
            {props.buttonText || 'Get Started!'}
          </Button>
        </>
      );
    } else if (state.step === steps.secondStep) {
      let buttonDisabled = true;
      if (userData.userType === userTypes.districtAdmin) {
        buttonDisabled = !state.selectedDistrict;
      } else {
        buttonDisabled = !state.selectedSchool || !state.selectedDistrict;
      }

      return (
        <>
          {renderDistrictSelection()}
          {renderSchoolSelection()}
          <Button
            primary
            disabled={buttonDisabled}
            onClick={handleSetLocation}
            className={classes.button}
          >
            Set Your {selectionType}!
          </Button>
        </>
      );
    }
  };

  return <>{renderStep()}</>;
};

export default LocationSelection;
