import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { Typography } from '@material-ui/core';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 6,
    left: '-90%'
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(82 203 255) 20%,rgb(147 223 254) 50%)'
    }
  },
  line: {
    // width: 550,
    height: 20,
    border: 0,
    backgroundImage:
      'linear-gradient(90deg, rgba(176,231,255,1) 0%, rgba(255,255,255,1) 80%)',
    borderRadius: 1
  }
})(StepConnector);

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 56,
    width: '100%'
  },
  stepLabel: {
    alignItems: 'start',
    '& .MuiStepLabel-label': {
      textAlign: 'left'
    }
  }
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    background: '#009de0',
    border: '3px solid #fff'
  },
  completed: {
    border: '3px solid #fff',
    background: '#009de0'
  }
});

function ColorlibStepIcon(props: any) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    ></div>
  );
}

export default function FreeTrialStepper({
  trialExpiry
}: {
  trialExpiry: string;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel connector={<ColorlibConnector />}>
        <Step active>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
            className={classes.stepLabel}
          >
            <Typography variant="h5">Today</Typography>
            <Typography style={{ fontSize: '12px' }}>
              Start using Shoelace in your classroom.
            </Typography>
            <Typography style={{ fontSize: '12px', fontWeight: '600' }}>
              Free for 14 days.
            </Typography>
          </StepLabel>
        </Step>
        <Step active>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
            className={classes.stepLabel}
          >
            <Typography variant="h5">{trialExpiry}</Typography>
            <Typography style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
              You'll be charged for a year of Shoelace.
            </Typography>
            <Typography style={{ fontSize: '12px' }}>
              Cancel anytime prior.
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
        </Step>
      </Stepper>
    </div>
  );
}
