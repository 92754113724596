import React, { useState, useEffect } from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import View from '../../components/view/View';
import BackgroundImg from '../../assets/select-location-bg.png';
import LocationSelection from '../../components/common/LocationSelection';
import { userTypes } from '../../constants/users';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { AdminVerification } from '../../components/common/AdminVerification';
import { useLocation } from 'react-router';
import { commonRoutes } from '../../constants/routes';
import { updateUserInformation } from '../../redux/actions/user-actions';
import useQueryParams from '../../hooks/UseQueryParams';
import mixpanel from '../../utils/mixpanel';
import { UserType } from '../../enums/UserType';
import { getLocalStorage } from '../../utils/localStorage';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '40px 20px 20px',
    position: 'relative',
    textAlign: 'center',
    width: 720
  },
  paperMobile: {
    padding: '40px 20px 20px',
    position: 'relative',
    textAlign: 'center',
    flex: 'auto',
    maxWidth: 720
  },
  title: {
    marginBottom: 10
  },
  topImage: {
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: -110,
    width: '80%'
  }
}));

const RegistrationOnBoardingStepper: React.FC = () => {
  const classes = useStyles();
  const userData = useSelector((state: any) => state.userData);
  const [step, setStep] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const query = useQueryParams();

  console.log('query', query);
  useEffect(() => {
    if (
      userData.token &&
      userData.userType === UserType.Teacher &&
      (userData.clever || userData.google) &&
      !userData.newRegistrationFlow
    ) {
      const mixpanelEventProps = {
        userType: userData.userType,
        registrationFlow: getLocalStorage('newRegistrationFlow')
      };

      mixpanel.track('Onboarding - Register', mixpanelEventProps, true, {
        send_immediately: true
      });
      dispatch(
        updateUserInformation({
          ...userData,
          newRegistrationFlow: getLocalStorage('newRegistrationFlow')
        })
      );
    }
  }, [userData.newRegistrationFlow, userData.clever, userData.google]);

  let adminVerificationRoute = false;
  if (location.pathname.includes(commonRoutes.adminVerification)) {
    adminVerificationRoute = true;
  }

  if (userData.userType === userTypes.parent) {
    return null;
  }
  let selectionType = 'school';
  if (userData.userType === userTypes.districtAdmin) {
    selectionType = 'district';
  }
  const texts = [
    {
      title: 'Welcome to Dreamscape!',
      headline1: 'Dreamscape is the most fun and engaging way to read!',
      headline2: `Select your country and region so we can link you to the appropriate ${selectionType}.`
    },
    {
      title: `Link to your ${selectionType}!`,
      headline1:
        'Receive prioritized help from our support staff by joining the other teachers in your school.',
      headline2: ''
    },
    {
      title: `Administrator Verification`,
      headline1: `As an administrator, you will have access to your ${selectionType}'s player data and account management tools.
       To enable access, please provide proof of identity. Examples can include a link to your ${selectionType}'s staff page, a picture
       of your badge, etc.`
    }
  ];

  const title = adminVerificationRoute ? texts[2].title : texts[step].title;
  const headline1 = adminVerificationRoute
    ? texts[2].headline1
    : texts[step].headline1;
  const headline2 = adminVerificationRoute
    ? texts[2].headline2
    : texts[step].headline2;

  return (
    <View flex style={{ height: '100vh' }}>
      <Paper className={isMobile ? classes.paperMobile : classes.paper}>
        {isMobile ? null : (
          <img
            src={BackgroundImg}
            alt="Squiggle Monsters"
            className={classes.topImage}
          />
        )}
        <Typography variant="h5" component="h3" className={classes.title}>
          {title}
        </Typography>
        <Typography component="p">{headline1}</Typography>
        <Typography component="p">{headline2}</Typography>
        {adminVerificationRoute ? (
          <AdminVerification selectionType={selectionType} />
        ) : (
          <LocationSelection
            handleChangeStep={(step: number) => setStep(step)}
          />
        )}
      </Paper>
    </View>
  );
};

export default RegistrationOnBoardingStepper;
