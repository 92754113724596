import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import { inviteParent } from '../../../redux/actions/user-actions';

const ModalSendInviteParent: React.FC = (props: any) => {
  const { show, handleHide, playerData } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    isError: false,
    errorText: ''
  });

  function validateEmail(emailString: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailString).toLowerCase());
  }

  const userData = useSelector((state: any) => state.userData);
  const classCode = useSelector((state: any) => state.class.currentClass.code);
  const handleChange = (evt: any) => {
    setErrors({ isError: false, errorText: '' });
    setEmail(evt.target.value);
  };
  const handleSendInvite = () => {
    if (!validateEmail(email)) {
      return setErrors({
        isError: true,
        errorText: 'Please, type a valid e-mail'
      });
    }
    const inviteData = {
      email,
      teacher: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email
      },
      player: { ...playerData }
    };
    return dispatch(inviteParent(inviteData, classCode));
  };

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>Send Parent Invite</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>Enter Parent Email:</p>
          <FormControl error={errors.isError}>
            <TextField
              onChange={handleChange}
              fullWidth
              name="email"
              autoComplete="new-email"
            />
            <FormHelperText>
              {errors.isError ? errors.errorText : null}
            </FormHelperText>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleHide}>Close</Button>
        <Button primary onClick={handleSendInvite}>
          Submit
        </Button>
      </DialogActions>
    </ModalAbstract>
  );
};

export default ModalSendInviteParent;
