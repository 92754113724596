import { fetchApi } from '../../redux/actions/fetch-actions';
import { APIEndpoints, APIMethods } from '../../types/fetch/fetch-types';
import { show } from 'redux-modal';
import store from '../../redux/store';

export class ClassMissionService {
  /**
   * @description pauses class mission
   * @param classMissionId
   */
  static pauseClassMission = (classMissionId: number) => {
    const dispatch = store.dispatch;
    dispatch(
      fetchApi({
        url: `class-missions/${classMissionId}`,
        endpoint: APIEndpoints.EDUCATION,
        data: {
          status: 'PAUSED'
        },
        method: APIMethods.PATCH
      })
    ).catch(() => {
      dispatch(
        show('snackbar', {
          variant: 'error',
          message: 'Error pausing class mission'
        })
      );
    });
  };
}
