import React, { useState, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Fab,
  makeStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  Theme
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { hide, show as showModal } from 'redux-modal';
import ModalAbstract from './ModalAbstract';
import Button from '../../button/Button';
import {
  getCleverStudents,
  selectCleverStudent,
  importCleverStudent,
  mergeCleverStudent
} from '../../../redux/actions/clever-actions';
import { Sync, Add, Close, KeyboardArrowLeft } from '@material-ui/icons';
import { CleverMergeStudentPayload } from '../../../types/clever/clever-merge-request-type';
import { CleverStudent } from '../../../types/clever/clever-student';
import Select from 'react-select';
import colors from '../../../styles/colors';
import { getClass } from '../../../redux/actions/class-actions';
import { trackCleverSync } from '../../../redux/actions/mixpanel-actions';
import { CleverImportStudentPayload } from '../../../types/clever/clever-import-request-type';
import { MODAL_DEFAULT } from '../../../constants/modals';

const useStyles = makeStyles((theme: Theme) => ({
  optionSelect: {
    marginBottom: 20,
    width: '100%'
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: 10,
    top: 10
  },
  columnTitle: {
    fontWeight: 500
  }
}));

const ModalClever: React.FC = (props: any) => {
  enum Steps {
    none,
    actionSelection,
    importStudents,
    mergeStudents,
    success
  }

  const { show, handleHide } = props;
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.actionSelection);
  const [studentsToMerge, setStudentsToMerge] = useState<
    CleverMergeStudentPayload[]
  >([]);
  const [mergeError, setMergeError] = useState<string | null>(null);
  const clever = useSelector((state: any) => state.clever);
  const currentClass = useSelector((state: any) => state.class);
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(getCleverStudents());
  }, []);

  const handleRefreshClass = () => {
    const hideSpinnerAfter = true;
    dispatch(getClass(props.classCode, { hideSpinnerAfter }));
  };

  const handleImportStudents = () => {
    dispatch(showModal('spinner', { text: 'Importing your students' }));
    const selectedStudents = clever.students.filter(
      (student: CleverStudent) => student.selected
    );
    Promise.all(
      selectedStudents.map(async (student: CleverImportStudentPayload) => {
        student.classCode = props.classCode;
        return dispatch(importCleverStudent(student));
      })
    )
      .then(() => {
        dispatch(
          showModal('modal', {
            type: MODAL_DEFAULT,
            title: 'Success',
            children: 'Great! Your students were imported successfully.'
          })
        );
        setCurrentStep(Steps.success);
        handleRefreshClass();
      })
      .catch((error: any) => {
        dispatch(
          showModal('modal', {
            type: MODAL_DEFAULT,
            title: 'Error importing students',
            children:
              'There was an error importing your students, please try again later.'
          })
        );
      })
      .finally(() => {
        if (currentStep === Steps.success) {
          dispatch(trackCleverSync());
        }
      });
  };

  const hasDuplicatedStudentsToMerge = () => {
    const valueArr = studentsToMerge
      .filter(item => item.playerId)
      .map(item => item.playerId);
    const hasDuplicate = valueArr.some((item, index) => {
      return valueArr.indexOf(item) !== index;
    });
    return hasDuplicate;
  };

  const handleMergeStudents = () => {
    const students = studentsToMerge.filter(item => {
      return item.playerId;
    });
    if (!students.length) {
      setMergeError('Please, select at least one student to proceed');
      return;
    }
    if (hasDuplicatedStudentsToMerge()) {
      setMergeError(`
				A Dreamscape account must be attached to only one Clever student.
				Please, remove the duplicated users from the dropdowns.`);
      return;
    }
    dispatch(
      showModal('spinner', {
        text: 'Merging your Clever students into existings Dreamscape accounts'
      })
    );
    studentsToMerge
      .filter(student => student.playerId)
      .forEach(student => dispatch(mergeCleverStudent(student)));
    setCurrentStep(Steps.success);
    handleRefreshClass();
  };

  const handleMergeDropdownChange = (
    selection: any,
    student: CleverStudent
  ) => {
    const mergeValue: CleverMergeStudentPayload = {
      grade: student.grade,
      id: student.id,
      name: student.name,
      playerId: selection.value,
      selected: true
    };
    setMergeError(null);
    const selectedStudent = studentsToMerge.filter(
      (studentToMerge: CleverMergeStudentPayload) =>
        student.id === studentToMerge.id
    );
    if (!selectedStudent.length) {
      setStudentsToMerge([...studentsToMerge, mergeValue]);
    } else {
      setStudentsToMerge(
        studentsToMerge.map((studentToMerge: CleverMergeStudentPayload) => {
          if (studentToMerge.id === selectedStudent[0].id) {
            return mergeValue;
          }
          return studentToMerge;
        })
      );
    }
  };

  const renderButtons = () => {
    switch (currentStep) {
      case Steps.success:
      case Steps.actionSelection:
        return null;
      case Steps.importStudents:
        const disabled =
          clever.students.filter((student: CleverStudent) => student.selected)
            .length === 0;
        return (
          <Button primary disabled={disabled} onClick={handleImportStudents}>
            Confirm
          </Button>
        );
      case Steps.mergeStudents:
        if (!clever.students.length) {
          return null;
        }
        return (
          <>
            <Typography
              component="p"
              style={{ color: colors.error, width: '100%' }}
            >
              {mergeError}
            </Typography>
            <Button primary disabled={false} onClick={handleMergeStudents}>
              Confirm
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const renderMergeDropdown = (student: CleverStudent) => {
    if (!student.playerId) {
      const options = currentClass.currentClass.roster
        .filter((classStudent: any) => !classStudent.clever)
        .map((classStudent: any) => ({
          value: classStudent._id,
          label: `${classStudent.fullname} (${classStudent.username})`
        }));
      return (
        <Select
          onChange={(selection: any) =>
            handleMergeDropdownChange(selection, student)
          }
          placeholder="Select a Dreamscape account"
          options={[{ label: "Don't merge", value: null }, ...options]}
        />
      );
    }
    return (
      <Typography component="p">
        This student already has a Dreamscape account. Please use the Import
        mode to add them to your class.
      </Typography>
    );
  };

  const renderStep = () => {
    if (clever.error) {
      return (
        <Typography component="p">
          There was an error authenticating you with Clever. Please logout,
          login and try again.
        </Typography>
      );
    }

    switch (currentStep) {
      case Steps.success:
        return (
          <>
            <Typography component="p">
              Great. Your students are synced!
            </Typography>
            <Typography component="p">
              Now your students can log in using their Clever account
            </Typography>
          </>
        );
      case Steps.actionSelection:
        return (
          <>
            <Typography component="p">
              We found {clever.students.length} students who are not synced with
              your Dreamscape account.
            </Typography>
            <Typography component="p" style={{ marginBottom: 20 }}>
              What would you like to do?
            </Typography>

            <Fab
              variant="extended"
              color="primary"
              className={classes.optionSelect}
              onClick={() => setCurrentStep(Steps.importStudents)}
            >
              <Add />
              Import my students from Clever to Dreamscape
            </Fab>

            <Fab
              variant="extended"
              color="primary"
              className={classes.optionSelect}
              onClick={() => setCurrentStep(Steps.mergeStudents)}
            >
              <Sync />
              Merge my Clever students in existing Dreamscape accounts
            </Fab>
          </>
        );
      case Steps.importStudents:
        return (
          <>
            <Typography component="p" style={{ marginBottom: 20 }}>
              Please, select the students you want to create Dreamscape
              accounts for:
            </Typography>
            <Grid container spacing={4}>
              <Grid item md={6} xs={6}>
                <Typography component="p" className={classes.columnTitle}>
                  Student name
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography component="p" className={classes.columnTitle}>
                  Grade
                </Typography>
              </Grid>
            </Grid>
            {clever.students.map((student: CleverStudent) => {
              return (
                <Grid container spacing={4}>
                  <Grid container item md={6} xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student.selected}
                          onChange={() =>
                            dispatch(selectCleverStudent(student.id))
                          }
                          value={student.id}
                          color="primary"
                        />
                      }
                      label={`${student.name.first} ${student.name.last}.`}
                    />
                  </Grid>
                  <Grid container item md={6} xs={6} alignItems="center">
                    <Typography component="p" align="center">
                      Grade {student.grade}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </>
        );
      case Steps.mergeStudents:
        if (!currentClass.currentClass.roster.length) {
          return (
            <Typography component="p">
              You don't have any students in your Dreamscape class to merge with
              your Clever students.
            </Typography>
          );
        }
        return (
          <>
            <Typography component="p" style={{ marginBottom: 20 }}>
              Please, select the Dreamscape account that belongs to your Clever
              students:
            </Typography>
            <Grid container spacing={4}>
              <Grid item md={6} xs={6}>
                <Typography component="p" className={classes.columnTitle}>
                  Clever Account
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography component="p" className={classes.columnTitle}>
                  Dreamscape Account
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={4} style={{ marginBottom: 40 }}>
              {clever.students.map((student: CleverStudent) => (
                <>
                  <Grid item md={6} xs={6}>
                    <Typography component="p">
                      {student.name.first} {student.name.last}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    {renderMergeDropdown(student)}
                  </Grid>
                </>
              ))}
            </Grid>
          </>
        );
    }
    return null;
  };

  const renderBackArrow = () => {
    if ([Steps.importStudents, Steps.mergeStudents].includes(currentStep)) {
      return (
        <IconButton
          aria-label="back"
          style={{ marginRight: 10 }}
          onClick={() => {
            setMergeError(null);
            setCurrentStep(Steps.actionSelection);
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
      );
    }
    return null;
  };

  if (clever.inProgress) {
    return null;
  }

  return (
    <ModalAbstract show={show} handleHide={handleHide}>
      <DialogTitle>
        {renderBackArrow()}
        Sync with Clever
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => dispatch(hide('modal'))}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {Array.isArray(clever.students) && !clever.students.length ? (
          <Typography component="p">All students are synced.</Typography>
        ) : (
          renderStep()
        )}
      </DialogContent>
      <DialogActions>{renderButtons()}</DialogActions>
    </ModalAbstract>
  );
};

export default ModalClever;
