import { Grid, makeStyles, Tab, Tabs, Divider, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import missionsLogo from '../../assets/missions/dreamscape_class_missions_logo.png';
import funFriday from '../../assets/missions/fun_friday.png';
import { NonMissionMondayBanner } from '../../components/banner/NonMissionMondayBanner';
import TeacherFeatureFlagContext from '../../layouts/teacher/TeacherFeatureFlagContext';
import ClassMissionsPlayView from './ClassMissionPlayView';
import { useDispatch } from 'react-redux';
import ClassMissionReportView from './ClassMissionsReportView';
import { trackClassMissionReport } from '../../redux/actions/mixpanel-actions';

const useStyles = makeStyles(theme => ({
  image: {
    maxHeight: 400,
    borderRadius: 30,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '50px'
  },
  paper: {
    width: '100%'
  },
  missionMondayImg: {
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      left: '50%'
    },
    width: 'auto',
    position: 'absolute',
    top: '10px',
    left: '58%'
  },
  align: {
    marginTop: 0
  }
}));

enum TabValues {
  play,
  reports
}

const ClassMissionsView: React.FC<any> = () => {
  const styles = useStyles();
  const userData = useSelector((state: any) => state.userData);
  const [tabValue, setTabValue] = useState(TabValues.play);
  const [flag, setFlag] = useState(true);
  let isPremiumUser = userData.premium && userData.premium.isPremium;
  let isClassMissionTrialUser = false;
  const { flags } = React.useContext(TeacherFeatureFlagContext);
  if (
    (flags.classMissionTrialsExtended || flags.isMissionMonday) &&
    !isPremiumUser
  ) {
    isClassMissionTrialUser = true;
  }

  const renderContent = () => {
    if (tabValue === TabValues.play) {
      return <ClassMissionsPlayView />;
    }
    trackClassMissionReport('Class Missions menu');
    return <ClassMissionReportView flag={flag} />;
  };

  return (
    <>
      <Paper className={styles.paper}>
        {!isPremiumUser && !flags.isMissionMonday && <NonMissionMondayBanner />}
        {/* <View> */}
        <div className={styles.image}>
          <img
            style={{
              maxHeight: '80%',
              maxWidth: '50%',
              position: 'relative'
            }}
            src={missionsLogo}
            alt="logo"
          />
          {/* {flags.isMissionMonday && !isPremiumUser && (
            <img
              src={funFriday}
              alt="mission monday"
              className={styles.missionMondayImg}
            />
          )} */}
        </div>
        <Grid xs={12}>
          <Tabs
            value={tabValue}
            textColor="primary"
            indicatorColor="primary"
            className="styles.align"
            onChange={(_: any, newValue: TabValues) => setTabValue(newValue)}
          >
            <Tab label="PLAY" />
            <Tab label="REPORTS" />
          </Tabs>
          <Divider />
          {/* <br /> */}
          <Grid container style={{ padding: 20 }}>
            {renderContent()}
          </Grid>
        </Grid>
        {/* </View> */}
      </Paper>
    </>
  );
};

export default ClassMissionsView;
