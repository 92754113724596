import {
  LazyLoadedModules,
  lazyLoadModule
} from './../../features/lazy-loading';
import { Features } from '../../feature-flag/Features';
import { parentRoutes } from '../../constants/routes';
import AppBarLayout from '../../layouts/AppBarLayout';

const lazyLoadComponent = lazyLoadModule(LazyLoadedModules.ParentModule);

const ParentLayout = lazyLoadComponent('ParentLayout');
const MembershipView = lazyLoadComponent('MembershipView');
const StudentsView = lazyLoadComponent('StudentsView');
const ReportsView = lazyLoadComponent('ReportsView');
const ReadingLevelReportView = lazyLoadComponent('ReadingLevelReportView');
const SkillComprehensionReportView = lazyLoadComponent(
  'SkillComprehensionReportView'
);
const Account = lazyLoadComponent('Account');
const RewardsPageParent = lazyLoadComponent('RewardsPageParent');
const ReportCard = lazyLoadComponent('ReportCard');
const PlannerView = lazyLoadComponent('PlannerView');
const AssignmentReportView = lazyLoadComponent('AssignmentReportView');
const UpdateLocation = lazyLoadComponent('UpdateLocation');
const QuestionsReportView = lazyLoadComponent('QuestionsReportView');
const PlannerReportView = lazyLoadComponent('PlannerReportView');
const ResourceView = lazyLoadComponent('ResourceView');
const ResourceInfoView = lazyLoadComponent('ResourceInfoView');

const resourceContent = (layout: any) => {
  return [
    {
      path: parentRoutes.resourceHub,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceView
    },
    {
      path: parentRoutes.resourceHubInfo,
      layout,
      beta: false,
      featureName: Features.RESOURCE_CONTENT,
      component: ResourceInfoView
    }
  ];
};

export default [
  {
    path: parentRoutes.reportCard,
    layout: ParentLayout,
    component: StudentsView
  },
  {
    path: parentRoutes.homeScreen,
    layout: ParentLayout,
    component: ReportCard
  },
  {
    path: parentRoutes.membershipsAll,
    layout: ParentLayout,
    component: MembershipView
  },
  {
    path: parentRoutes.planner,
    layout: ParentLayout,
    component: PlannerView
  },
  {
    path: parentRoutes.plannerReport,
    layout: ParentLayout,
    component: AssignmentReportView
  },
  {
    path: parentRoutes.reports,
    layout: ParentLayout,
    component: ReportsView
  },
  {
    path: parentRoutes.rewardsPage,
    layout: ParentLayout,
    component: RewardsPageParent
  },
  {
    path: parentRoutes.readingLevelReport,
    layout: ParentLayout,
    component: ReadingLevelReportView
  },
  {
    path: parentRoutes.skillComprehensionReport,
    layout: ParentLayout,
    component: SkillComprehensionReportView
  },
  {
    path: parentRoutes.updateLocation,
    layout: AppBarLayout,
    component: UpdateLocation
  },
  {
    path: parentRoutes.myAccount,
    layout: ParentLayout,
    component: Account
  },
  {
    path: parentRoutes.questionsReport,
    layout: ParentLayout,
    component: QuestionsReportView
  },
  {
    path: parentRoutes.classReportsAssignmentTool,
    layout: ParentLayout,
    component: PlannerReportView
  },

  ...resourceContent(ParentLayout)
];
